
export const columns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'produceWorkOrderNumber',
    label: '生产工单号',
    slotName: 'produceWorkOrderNumber',
    hiddenSearch: true,
    sortable: 'custom'
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    slotName: 'batchNumberDetail',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsSpecifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true,
    width: 340
  }
]
export const BarchColumns = [
  {
    prop: 'batchNumber',
    label: '产品批次码',
    hiddenSearch: true,
    sortable: false

  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsSpecifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true,
    width: 340
  },
  {
    prop: 'materialsUnit',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  }
]

export const WorkOrderInfoColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 200
  },
  {
    prop: 'produceWorkOrderNumber',
    label: '生产工单号',
    slotName: 'batchNumber',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'reportWorkQuantity',
    label: '报工数量',
    hiddenSearch: true,
    sortable: false
  }

]

export const QualityRecordColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 200
  },
  {
    prop: 'qualityNumber',
    label: '质检单号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'qualityInspectionName',
    label: '质检方案',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'id',
    label: '质检记录',
    slotName: 'look',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'inspectResult',
    label: '质检结果',
    slotName: 'inspectResult',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'userName',
    label: '操作人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'operateTime',
    label: '操作时间',
    sortable: false,
    hiddenSearch: true
  }
]
export const base = [
  {
    prop: 'batchNumber',
    label: '批次码',
    slotName: 'batchNumberDetail',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsSpecifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true,
    width: 340
  }
]
