let functionList = {}
export const getFunctionList = val => {
  functionList = val
}
// 项目列表
export const columns = [
    {
      prop: 'index',
      label: '序号',
      slotName: 'index',
      hiddenSearch: true,
      fixed: 'left',
      width: 60
    },
    {
      prop: 'name',
      label: '方案名称',
      sortable: false,
      hiddenSearch: true,
      slotName:'projectNumber',
      form: { tagName: 'el-input', required: true }
    },
    {
      prop: 'isDefault',
      label: '是否默认方案',
      sortable: false,
      hiddenSearch: true,
      slotName:'isDefault',
      minWidth: 120,
      form: { tagName: 'el-input', required: false }
    },
    {
      prop: 'isEnabled',
      label: '方案状态',
      hiddenSearch: true,
      sortable: false,
      slotName:'isEnabled',
       minWidth: 100
    },
    {
      prop: 'creator',
      label: '创建人',
      sortable: false,
      hiddenSearch: true,
      minWidth: 120,
      form: { tagName: 'el-input', required: false,props: { disabled: false } }
    },
    {
      prop: 'createTime',
      label: '创建时间',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'lastUpdateTime',
      label: '更新时间',
      hiddenSearch: true,
      sortable: false
    },
    {
      slotName: 'action',
      label: '操作',
      sortable: false,
      hiddenSearch: true,
      width: 220,
      fixed: 'right'
    }
  ]
