<template>
  <div class="procedure-detail-wrapper">
    <div class="procedure-detail-header flex-sbc">
      <div class="left" style="line-height: 32px;" @click="$router.push({ name: 'qualityInspectionOrder' })">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px">
        <span style="padding-left: 10px;cursor: pointer">{{ messages.add }}</span>
      </div>
      <div >
        <el-button v-if="baseFormData.showCheck" size="small" type="primary" @click="toCheck">审核</el-button>
        <el-button v-if="baseFormData.showApprove" size="small" type="primary" @click="toApprove">审批</el-button>

      </div>
    </div>
    <div class="procedure-detail-body">
      <div class="detail-card">
        <div class="card-tit">基本信息</div>
        <div class="card-bot">
          <el-row class="view-list" :gutter="24">
            <el-col
              v-for="(item, index) in baseFormData.inspectionType === 1 ? columns.base : columns.incomming"
              :key="item.prop"
              :span="item.prop === 'inspectionSchemeAttachments' ? 24 : 6"
              :style="{marginBottom: index !== 4 ? '20px' : 0, lineHeight: '22px' }"
            >
              <span class="bot-tis">{{ item.label }}：</span>
              <span
                v-if="item.prop === 'status'"
                class="faultStatus"
                :style="{background: qualityStatus[getValue(item)] ? qualityStatus[getValue(item)].background : 'rgba(236, 238, 242, .1)',
                         color: qualityStatus[getValue(item)] ? qualityStatus[getValue(item)].color : '#393D60;'}"
              >
                {{ qualityStatus[getValue(item)] ? qualityStatus[getValue(item)].name : '' }}
              </span>
              <span v-else-if="item.prop ==='inspectionSchemeAttachments'">
                <a v-for="(items,idx) in fixArrs(getValue(item))" :key="items.id" :href="items.url" target="_blank" style="color: #0091FF;">
                  {{ items.name }}
                  <span v-if="idx < fixArrs(getValue(item)).length -1">,</span>
                </a>
              </span>
              <span v-else class="bot-tis">{{ getValue(item) }}</span>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="detail-card detail-le" :style="{ width: 0 ? 'calc(100% - 332px)' : '100%'}">
        <div class="card-tit">检验结果</div>
        <div class="card-bot">
          <el-row class="view-list" :gutter="24">
            <el-col :span="3">
              <span class="bot-tis">合格数量：</span>
              <span class="bot-tis">{{ baseFormData.okCount }}</span>
            </el-col>
            <el-col :span="3">
              <span class="bot-tis">不合格数量：</span>
              <span class="bot-tis">{{ baseFormData.ngCount }}</span>
            </el-col>
            <el-col v-if="baseFormData.ngCount" :span="4">
              <div v-if="baseFormData.degradeCount">
                <span class="bot-tis">让步接收：</span>
                <span class="bot-tis">{{ baseFormData.degradeCount }}</span>
              </div>
              <div v-if="baseFormData.repairCount" style="padding-top: 5px">
                <span class="bot-tis">返修数量：</span>
                <span class="bot-tis">{{ baseFormData.repairCount }}</span>
              </div>
              <div v-if="baseFormData.scrapCount" style="padding-top: 5px">
                <span class="bot-tis">报废数量：</span>
                <span class="bot-tis">{{ baseFormData.scrapCount }}</span>
              </div>
            </el-col>
            <el-col :span="4">
              <span class="bot-tis">检验人：</span>
              <span class="bot-tis">{{ baseFormData.inspectorName }}</span>
            </el-col>
            <el-col v-if="baseFormData.startTime" :span="5">
              <span class="bot-tis">检验开始时间：</span>
              <span class="bot-tis">{{ formDateToMinutes(baseFormData.startTime) }}</span>
            </el-col>
            <el-col v-if="baseFormData.endTime" :span="5">
              <span class="bot-tis">检验结束时间：</span>
              <span class="bot-tis">{{ formDateToMinutes(baseFormData.endTime) }}</span>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="detail-card detail-le" :style="{ width: 0 ? 'calc(100% - 332px)' : '100%' }">
        <div class="card-tit">检验记录</div>
        <div class="card-bot">
          <inspection-record :base-form-data="baseFormData" />
        </div>
      </div>
      <!--      <div class="procedure-detail-detail">-->
      <!--        <div class="detail-card detail-le" :style="{ width: 0 ? 'calc(100% - 332px)' : '100%'}">-->
      <!--          <div class="card-tit">检验记录</div>-->
      <!--          <div class="card-bot">-->
      <!--            &lt;!&ndash; 检验记录 &ndash;&gt;-->
      <!--            <quality-table ref="qualityTable" :is-view="isView" :inspection-group="baseFormData.inspectionEntryList" />-->
      <!--            &lt;!&ndash;            <div class="upload-all">&ndash;&gt;-->
      <!--            &lt;!&ndash;              <div class="bot-li">&ndash;&gt;-->
      <!--            &lt;!&ndash;                <div class="li-tit">附件：</div>&ndash;&gt;-->
      <!--            &lt;!&ndash;                <div class="li-des">&ndash;&gt;-->
      <!--            &lt;!&ndash;                  <el-upload&ndash;&gt;-->
      <!--            &lt;!&ndash;                    ref="upload"&ndash;&gt;-->
      <!--            &lt;!&ndash;                    class="upload-demo"&ndash;&gt;-->
      <!--            &lt;!&ndash;                    :file-list="fileList"&ndash;&gt;-->
      <!--            &lt;!&ndash;                    v-bind="uploadProp"&ndash;&gt;-->
      <!--            &lt;!&ndash;                  >&ndash;&gt;-->
      <!--            &lt;!&ndash;                    <el-button size="small" type="primary" :disabled="params.isView">点击上传</el-button>&ndash;&gt;-->
      <!--            &lt;!&ndash;                    &lt;!&ndash; <div slot="tip" class="el-upload__tip">支持多个文件同时上传</div> &ndash;&gt;&ndash;&gt;-->
      <!--            &lt;!&ndash;                  </el-upload>&ndash;&gt;-->
      <!--            &lt;!&ndash;                </div>&ndash;&gt;-->
      <!--            &lt;!&ndash;              </div>&ndash;&gt;-->
      <!--            &lt;!&ndash;            </div>&ndash;&gt;-->
      <!--          </div>-->
      <!--          <div class="lines" />-->
      <!--        </div>-->
      <!--        <div v-if="0" class="detail-right">-->
      <!--          <div class="title">检验报告</div>-->
      <!--          &lt;!&ndash; 过程检 &ndash;&gt;-->
      <!--          <div v-if="baseFormData.inspectionType === 1" class="right-bot">-->
      <!--            <div class="bot-li">-->
      <!--              <div class="li-tit green">合格：</div>-->
      <!--              <div class="li-des">-->
      <!--                <el-input-number v-model="resultData.okCount" :min="0" :max="baseFormData.batchCount" controls-position="right" :step="1" :disabled="baseFormData.status === 2 || params.isView" />-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div class="bot-li">-->
      <!--              <div class="li-tit red">不合格：</div>-->
      <!--              <div class="li-des">-->
      <!--                <el-input-number v-model="ngCount" :min="0" :max="baseFormData.batchCount" controls-position="right" :step="1" :disabled="baseFormData.status === 2 || params.isView" />-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div v-if="isNum" class="bot-red">-->
      <!--              <div class="tit">处理意见</div>-->
      <!--              <div class="bot-li">-->
      <!--                <div class="li-tit">让步接收：</div>-->
      <!--                <div class="li-des">-->
      <!--                  <el-input-number v-model="resultData.degradeCount" :min="0" controls-position="right" :step="1" :disabled="baseFormData.status === 2 || params.isView" />-->
      <!--                </div>-->
      <!--              </div>-->
      <!--              <div class="bot-li">-->
      <!--                <div class="li-tit">返修：</div>-->
      <!--                <div class="li-des">-->
      <!--                  <el-input-number v-model="resultData.repairCount" :min="0" controls-position="right" :step="1" :disabled="baseFormData.status === 2 || params.isView" />-->
      <!--                </div>-->
      <!--              </div>-->
      <!--              <div class="bot-li">-->
      <!--                <div class="li-tit">报废：</div>-->
      <!--                <div class="li-des">-->
      <!--                  <el-input-number v-model="resultData.scrapCount" :min="0" controls-position="right" :step="1" :disabled="baseFormData.status === 2 || params.isView" />-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div class="bot-li">-->
      <!--              <div class="li-tit">检验人：</div>-->
      <!--              <div class="li-des">-->
      <!--                <el-select v-model="resultData.inspectorId" placeholder="请选择" clearable filterable :disabled="baseFormData.status === 2 || params.isView">-->
      <!--                  <el-option-->
      <!--                    v-for="item in reporterList"-->
      <!--                    :key="item.id"-->
      <!--                    :label="item.name"-->
      <!--                    :value="item.id"-->
      <!--                    @click.native="selectData(item)"-->
      <!--                  />-->
      <!--                </el-select>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          &lt;!&ndash; 首检 &ndash;&gt;-->
      <!--          <div v-if="baseFormData.inspectionType === 0" class="right-bot">-->
      <!--            <div v-if="baseFormData.status === 2" class="bot-li">-->
      <!--              <el-button class="btn" :class="currentIdx === 0 ? 'btn-red' : ''">不合格</el-button>-->
      <!--              <el-button class="btn" :class="currentIdx === 1 ? 'btn-green' : ''">合格</el-button>-->
      <!--            </div>-->
      <!--            <div v-else class="bot-li">-->
      <!--              <el-button class="btn" :class="currentIdx === 0 ? 'btn-red' : ''" @click="isAll(0)">不合格</el-button>-->
      <!--              <el-button class="btn" :class="currentIdx === 1 ? 'btn-green' : ''" @click="isAll(1)">合格</el-button>-->
      <!--            </div>-->
      <!--            <div v-if="isNum" class="bot-li">-->
      <!--              <div class="li-tit">不合格描述：</div>-->
      <!--              <el-input-->
      <!--                v-model="resultData.ngExplain"-->
      <!--                type="textarea"-->
      <!--                placeholder="请输入"-->
      <!--                maxlength="200"-->
      <!--                show-word-limit-->
      <!--                :autosize="{ minRows: 5}"-->
      <!--                :disabled="baseFormData.status === 2 || params.isView"-->
      <!--              />-->
      <!--            </div>-->
      <!--            <div class="bot-li">-->
      <!--              <div class="li-tit">检验人：</div>-->
      <!--              <div class="li-des">-->
      <!--                <el-select v-model="resultData.inspectorId" placeholder="请选择" clearable filterable :disabled="baseFormData.status === 2 || params.isView">-->
      <!--                  <el-option-->
      <!--                    v-for="item in reporterList"-->
      <!--                    :key="item.id"-->
      <!--                    :label="item.name"-->
      <!--                    :value="item.id"-->
      <!--                    @click.native="selectData(item)"-->
      <!--                  />-->
      <!--                </el-select>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div v-if="!params.isView && !isShow && baseFormData.status !== 2" class="right-footer">-->
      <!--            <el-button @click="submitForms(1)">提交</el-button>-->
      <!--            <el-button type="primary" @click="submitForms(0)">保存</el-button>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <!--    <div v-if="!params.isView" class="order-footer" :style="{ width: baseFormData.status !== 0 ? 'calc(100% - 362px)' : '100%'}">-->
    <!--      <div class="footer-le" />-->
    <!--      <div v-if="baseFormData.status === 0" class="footer-ri">-->
    <!--        <el-button type="primary" @click="startInspection">开始质检</el-button>-->
    <!--      </div>-->
    <!--      <div v-else class="footer-ri">-->
    <!--        <el-button @click="returnList">清空</el-button>-->
    <!--        <el-button type="primary" @click="save">确认</el-button>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="lines" />-->
  </div>
</template>

<script>
import { base, incomming } from './columns'
import api from '@/api/quality/inspection-orders'
import uapi from '@/api/sets/user/user'
import { getToken } from '@/utils/auth'
import { qualityStatus } from '@/config/options.config'
import dayjs from 'dayjs'
import InspectionRecord from '@/views/quality/quality-inspection-order/components/inspection-record'

export default {
  name: 'QualityInspectionOrderDetail',
  components: { InspectionRecord },
  data() {
    return {
      currentIdx: 1,
      isView: false,
      qualityStatus,
      isShow: false,
      ngCount: 0,
      resultData: {
        okCount: 0,
        degradeCount: 0,
        repairCount: 0,
        scrapCount: 0,
        inspectorId: '',
        inspectorName: '',
        ngExplain: ''
      },
      params: {
        isView: true
      },
      baseFormData: {
        status: 0
      },
      saveButton: false,
      buttonLoading: false,
      columns: {
        base,
        incomming
      },
      messages: {
        add: '检验'
      },
      reporterList: [],
      uploadProp: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: { token: getToken() },
        showFileList: true,
        onSuccess: this.fileUploadChange,
        onRemove: this.fileRemove,
        onError: this.fileUploadChange,
        onPreview: this.handlePreview
      },
      fileList: [],
      isNum: false
    }
  },
  watch: {
    ngCount(val) {
      if (this.baseFormData.inspectionType === 1) {
        this.isNum = val > 0
      }
      // this.resultData.okCount = this.baseFormData.batchCount - val
    },
    currentIdx(val) {
      if (this.baseFormData.inspectionType === 0) {
        this.isNum = val === 0
      }
    }
  },
  activated() {
    this.params = this.$route.query
    this.params.isView = this.params.isView === 'view'
    this.getDetail()
  },
  methods: {
    edit() {
      this.params.isView = false
      this.isView = false
    },
    handlePreview(file) {
      window.open(file.url)
    },
    async save() {
      const data = {
        id: this.params.id,
        inspectionEntryList: this.$refs.qualityTable.sumbits(),
        attachments: JSON.stringify(this.fileList)
      }
      console.log('保存数据=》', data)
      const res = await api.modifyInspectionOrder(data)
      if (res) {
        this.$message.success('确认成功')
        this.$router.push({ name: 'qualityInspectionOrder' })
      }
    },
    fixArrs(val) {
      return val ? JSON.parse(val) : ''
    },
    isAll(val) {
      this.isNum = val === 0
      this.currentIdx = val
    },
    selectData(item) {
      this.resultData.inspectorName = item.name
    },
    async getDetail() {
      const res = await api.getInspectionOrderDetailById(this.params.id)
      if (res) {
        this.baseFormData = res
        console.log('baseFormData:',this.baseFormData)
        this.isView = !!(res.status === 0 || this.params.isView)
        this.reporterList = res.userList
        this.resultData = this.baseFormData
        this.ngCount = res.ngCount
        this.currentIdx = res.result === 0 ? 0 : 1
        this.fileList = res.attachments ? JSON.parse(res.attachments) : []
      }
    },
    async startInspection() {
      const res = await api.startInspection(this.params.id)
      if (res) {
        this.getDetail()
      }
    },
    fileRemove(file) {
      const index = this.fileList.findIndex(item => item.id === file.id)
      this.fileList.splice(index, 1)
    },
    // 上传附件
    fileUploadChange(res, file) {
      console.log('ceshiimg', res)
      if (res.errorCode === 0) {
        this.fileList.push({
          uid: file.uid,
          id: file.uid,
          name: file.name,
          url: res.result
        })
        return this.$message.success('上传成功')
      }
      if (res.errorCode !== 0) {
        return this.$message.error(res.result)
      }
    },
    returnList() {
      this.$refs.qualityTable.clearData()
    },
    async submitForms(val) {
      let data = {}
      if (+this.baseFormData.inspectionType === 0) {
        data = Object.assign({}, {
          id: this.params.id,
          result: this.currentIdx,
          ngExplain: this.resultData.ngExplain ? this.resultData.ngExplain : '',
          isSubmit: val,
          inspectorId: this.resultData.inspectorId,
          inspectorName: this.resultData.inspectorName
        })
      }
      if (+this.baseFormData.inspectionType === 1) {
        data = Object.assign({}, this.resultData, {
          id: this.params.id,
          ngCount: this.ngCount,
          isSubmit: val
        })
      }
      console.log('ceshi=>', data)
      const res = await api.saveInspectionOrder(data)
      if (res) {
        const msg = +val !== 1 ? '保存' : '提交'
        this.$message.success(`${msg}成功`)
        this.isShow = val === 1
        this.getDetail()
      }
    },
    // 用户
    async getUser() {
      const res = await uapi.getUserList()
      if (res) {
        this.reporterList = res
      }
    },
    getValue(row) {
      const result = this.baseFormData[row.prop]
      if (row.form && row.form.options) {
        const current = row.form.options.find(item => item.id === result)
        if (row.prop === 'inspectionType') {
          return current && current.name + (this.baseFormData.isFullInspection ? '-全检' : '-抽检')
        }
        return current && current.name
      }
      return result
    },
    formDateToMinutes(date) {
      if (date) {
        return dayjs(date).format('YYYY.MM.DD HH:mm')
      }
      return date
    },
   async toCheck(){
      console.log('check')
      const data = [+this.params.id]
      const res = await api.batchCheck(data)
      if (res) {
        this.$message.success('审核成功')
        this.getDetail()
        history.back()
      }
    },
    async toApprove(){
      console.log('approve')
      const data = [+this.params.id]
      const res = await api.batchApprove(data)
      if (res) {
        this.$message.success('审批成功')
        this.getDetail()
        history.back()

      }
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep{
    .el-date-editor .el-range-separator,.el-date-editor .el-range__icon{
      line-height:26px;
    }
    .el-input-number{
      height:32px;
      line-height:32px;
      width:100%;
    }
    .el-input__inner, .el-input__icon, .el-button{
      height:32px;
      line-height:32px;
    }
    .el-button{
      width: 88px;
      padding: 0;
      margin: 0 15px;
    }
    .el-input-number.is-controls-right .el-input-number__increase, .el-input-number.is-controls-right .el-input-number__decrease{
      line-height:15px;
    }
    .el-upload__tip {
      display: inline-block;
    }
  }
.procedure-detail-wrapper {
  position: relative;
  height: 100%;

  .procedure-detail-header {
    width: 100%;
    padding: 10px 0;
    .left {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393D60;
    }
  }
  .upload-all {
    margin-top: 27px;
    .bot-li {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 20px;
      .li-tit {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #393D60;
        line-height: 32px;
      }
    }
  }
  .procedure-detail-body {
    height: calc(100% - 52px);
    overflow-y: auto;
    .detail-card {
      margin-bottom: 20px;
    }
    .procedure-detail-detail {
      display: flex;
      justify-content: space-between;
      .detail-le {
        width: calc(100% - 332px);
      }
      .detail-right {
        width: 320px;
        // height: 482px;
        // background: #EAEEFF;
        border-radius: 4px;
        .title {
          height: 48px;
          background: #607FFF;
          border-radius: 4px 4px 0px 0px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          padding-left: 22px;
          line-height: 48px;
        }
        .right-bot {
          background: #EAEEFF;
          padding: 20px 9px;
          border-radius: 0 0 4px 4px;
          .bot-red {
            height: 187px;
            background: #FFEAEA;
            margin-bottom: 14px;
            .tit {
              height: 24px;
              background: #E02020;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #FFFFFF;
              padding-left: 12px;
              line-height: 24px;
              margin-bottom: 12px;
            }
          }
          .bot-li {
            display: flex;
            justify-content: center;
            align-items: center;
            // height: 32px;
            margin-bottom: 20px;
            .btn {
              width: 157px;
              height: 38px;
              margin: 0 10px;

            }
            .btn-red {
              background: #FA6400;
              color: #fff;
            }
            .btn-green {
              background: #00AB29;
              color: #fff;
            }
            .li-tit {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #393D60;
              margin-right: 12px;
              width: 70px;
              text-align: right;
            }
            .green {
              color: #00AB29;
            }
            .red {
              color: #E02020;
            }
            .li-des {
              width: 150px;
              height: 32px;
            }
          }
        }
        .right-footer {
          display: flex;
          justify-content: flex-end;
          border-top: 1px solid #DDE3EB;
          padding: 13px 0;
          background: #EAEEFF;
          height: 60px;
        }
      }
    }
  }
  .order-footer{
    position: absolute;
    bottom: 0;
    left: 10px;
    height: 60px;
    width: calc(100% - 362px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    background:#fff;
    border-top:1px solid #DDE3EB;
    .footer-le{
      padding-left: 30px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393D60;
      span{
        margin-right:45px;
        display:inline-block;
      }
    }
    .footer-ri{
      margin-right:30px;
      display:flex;
      justify-content: center;
      align-items: center;
    }
  }
  .lines {
    height: 80px;
  }
}
.bot-tis {
  color: #393D60;
}
.faultStatus {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  height: 22px;
  line-height: 22px;
  border-radius: 4px;
  width: 50px;
  text-align: center;
  display: inline-block;
}
</style>
