var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    {
      attrs: {
        placement: "right",
        trigger: "hover",
        "popper-class": "pover-tips"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "noplan",
          attrs: { slot: "reference" },
          slot: "reference"
        },
        [
          !_vm.item.actualStartTime
            ? _c("div", {
                staticClass: "actul-common-color",
                style: {
                  left: _vm.getPositonOffset(_vm.item.planStartTime) + "px",
                  width:
                    _vm.getWidthAbout2Times(
                      _vm.item.planStartTime,
                      _vm.item.planEndTime
                    ) + "px"
                },
                on: { click: _vm.onClick }
              })
            : _vm._e(),
          _vm.item.actualStartTime &&
          _vm.compareActualDate(
            _vm.item.actualStartTime,
            _vm.item.planStartTime
          ) &&
          _vm.compareDate(_vm.item.planEndTime, _vm.currentDate)
            ? _c("div", { staticClass: "actul-time" }, [
                _c("div", {
                  staticClass: "actul-common-color",
                  style: {
                    left: _vm.getPositonOffset(_vm.item.planStartTime) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.item.planStartTime,
                        _vm.item.actualStartTime
                      ) -
                      6 +
                      "px"
                  },
                  on: { click: _vm.onClick }
                }),
                _c("div", {
                  staticClass: "actul-time-color",
                  style: {
                    left: _vm.getPositonOffset(_vm.item.actualStartTime) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.item.actualStartTime,
                        _vm.currentDate
                      ) + "px"
                  },
                  on: { click: _vm.viewWorkReport }
                }),
                _c("div", {
                  staticClass: "actul-common-color",
                  style: {
                    left: _vm.getPositonOffset(_vm.currentDate) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.currentDate,
                        _vm.item.planEndTime
                      ) + "px"
                  },
                  on: { click: _vm.onClick }
                })
              ])
            : _vm._e(),
          _vm.item.actualStartTime &&
          !_vm.compareDate(_vm.item.actualStartTime, _vm.item.planEndTime) &&
          _vm.compareDate(_vm.item.actualStartTime, _vm.item.planStartTime) &&
          !_vm.compareDate(_vm.item.planEndTime, _vm.currentDate)
            ? _c("div", { staticClass: "actul-time" }, [
                _c("div", {
                  staticClass: "actul-common-color",
                  style: {
                    left: _vm.getPositonOffset(_vm.item.planStartTime) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.item.planStartTime,
                        _vm.item.actualStartTime
                      ) + "px"
                  },
                  on: { click: _vm.onClick }
                }),
                _c("div", {
                  staticClass: "actul-time-color",
                  style: {
                    left: _vm.getPositonOffset(_vm.item.actualStartTime) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.item.actualStartTime,
                        _vm.item.planEndTime
                      ) + "px"
                  },
                  on: { click: _vm.viewWorkReport }
                }),
                _c("div", {
                  staticClass: "actul-end-color",
                  style: {
                    left: _vm.getPositonOffset(_vm.item.planEndTime) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.item.planEndTime,
                        _vm.currentDate
                      ) -
                      6 +
                      "px"
                  },
                  on: { click: _vm.viewWorkReport }
                })
              ])
            : _vm._e(),
          _vm.item.actualStartTime &&
          _vm.compareDate(_vm.item.actualStartTime, _vm.item.planEndTime) &&
          !_vm.compareDate(_vm.item.planEndTime, _vm.currentDate) &&
          !_vm.compareDate(_vm.item.planStartTime, _vm.currentDate)
            ? _c("div", { staticClass: "actul-time" }, [
                _c("div", {
                  staticClass: "actul-common-color",
                  style: {
                    left: _vm.getPositonOffset(_vm.item.planStartTime) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.item.planStartTime,
                        _vm.item.planEndTime
                      ) + "px"
                  },
                  on: { click: _vm.onClick }
                }),
                _c(
                  "div",
                  {
                    staticClass: "lines",
                    style: {
                      width:
                        _vm.getWidthAbout2Times(
                          _vm.item.planEndTime,
                          _vm.item.actualStartTime
                        ) + "px"
                    }
                  },
                  [
                    _c("span", { staticClass: "circle" }),
                    _c("span", { staticClass: "line" }),
                    _c("span", { staticClass: "circle" })
                  ]
                ),
                _c("div", {
                  staticClass: "actul-end-color",
                  style: {
                    left: _vm.getPositonOffset(_vm.item.actualStartTime) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.item.actualStartTime,
                        _vm.currentDate
                      ) -
                      6 +
                      "px"
                  },
                  on: { click: _vm.viewWorkReport }
                })
              ])
            : _vm._e(),
          _vm.item.actualStartTime &&
          _vm.compareDate(_vm.item.planStartTime, _vm.item.actualStartTime) &&
          _vm.compareDate(_vm.item.planStartTime, _vm.currentDate) &&
          _vm.compareDate(_vm.item.planEndTime, _vm.currentDate)
            ? _c("div", { staticClass: "actul-time aa" }, [
                _c("div", {
                  staticClass: "actul-time-color",
                  style: {
                    left: _vm.getPositonOffset(_vm.item.actualStartTime) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.item.actualStartTime,
                        _vm.currentDate
                      ) -
                      6 +
                      "px"
                  },
                  on: { click: _vm.viewWorkReport }
                }),
                _c(
                  "div",
                  {
                    staticClass: "lines",
                    style: {
                      width:
                        _vm.getWidthAbout2Times(
                          _vm.currentDate,
                          _vm.item.planStartTime
                        ) + "px"
                    }
                  },
                  [
                    _c("span", { staticClass: "circle" }),
                    _c("span", { staticClass: "line" }),
                    _c("span", { staticClass: "circle" })
                  ]
                ),
                _c("div", {
                  staticClass: "actul-common-color",
                  style: {
                    left: _vm.getPositonOffset(_vm.item.planStartTime) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.item.planStartTime,
                        _vm.item.planEndTime
                      ) + "px"
                  },
                  on: { click: _vm.onClick }
                })
              ])
            : _vm._e(),
          _vm.item.actualStartTime &&
          _vm.compareDate(_vm.item.planStartTime, _vm.item.actualStartTime) &&
          !_vm.compareDate(_vm.item.planStartTime, _vm.currentDate) &&
          _vm.compareDate(_vm.item.planEndTime, _vm.currentDate)
            ? _c("div", { staticClass: "actul-time" }, [
                _c("div", {
                  staticClass: "actul-time-color",
                  style: {
                    left: _vm.getPositonOffset(_vm.item.actualStartTime) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.item.actualStartTime,
                        _vm.currentDate
                      ) -
                      6 +
                      "px"
                  },
                  on: { click: _vm.viewWorkReport }
                }),
                _c("div", {
                  staticClass: "actul-common-color",
                  style: {
                    left: _vm.getPositonOffset(_vm.currentDate) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.currentDate,
                        _vm.item.planEndTime
                      ) + "px"
                  },
                  on: { click: _vm.onClick }
                })
              ])
            : _vm._e(),
          _vm.item.actualStartTime &&
          _vm.compareDate(_vm.item.planStartTime, _vm.item.actualStartTime) &&
          !_vm.compareDate(_vm.item.planStartTime, _vm.currentDate) &&
          _vm.compareDate(_vm.currentDate, _vm.item.planEndTime)
            ? _c("div", { staticClass: "actul-time cc" }, [
                _c("div", {
                  staticClass: "actul-time-color",
                  style: {
                    left: _vm.getPositonOffset(_vm.item.actualStartTime) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.item.actualStartTime,
                        _vm.item.planEndTime
                      ) + "px"
                  },
                  on: { click: _vm.viewWorkReport }
                }),
                _c("div", {
                  staticClass: "actul-end-color",
                  style: {
                    left: _vm.getPositonOffset(_vm.item.planEndTime) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.item.planEndTime,
                        _vm.currentDate
                      ) -
                      6 +
                      "px"
                  },
                  on: { click: _vm.viewWorkReport }
                })
              ])
            : _vm._e()
        ]
      ),
      _c("div", { staticClass: "detail" }, [
        _c("div", { staticClass: "li-right-top" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.item.planName))
          ]),
          _c("div", { staticClass: "tips" }, [
            _c(
              "div",
              {
                staticClass: "tip",
                style: {
                  color: "#fff",
                  background:
                    _vm.item.produceStatus === 0
                      ? "rgba(224, 32, 32, 1)"
                      : _vm.item.produceStatus === 1
                      ? "rgba(0, 171, 41, 1)"
                      : "rgba(96, 127, 255, 1)"
                }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.productionStatus[_vm.item.produceStatus].name) +
                    " "
                )
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "des-tit" }, [
          _vm._v("开始时间：" + _vm._s(_vm.item.planStartTime))
        ]),
        _c("div", { staticClass: "des-tit" }, [
          _vm._v("完成时间：" + _vm._s(_vm.item.planEndTime))
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }