var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderAdd" },
    [
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            ["modify", "addOrder", "copy"].includes(_vm.$route.query.type)
              ? _c(
                  "el-form",
                  {
                    ref: "ruleForm",
                    staticClass: "demo-form-inline",
                    attrs: {
                      "label-position": "right",
                      model: _vm.ruleForm,
                      rules: _vm.rules,
                      "label-width": "135px"
                    }
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "项目计划方案名称",
                                  prop: "name"
                                }
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.ruleForm.name,
                                    callback: function($$v) {
                                      _vm.$set(_vm.ruleForm, "name", $$v)
                                    },
                                    expression: "ruleForm.name"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "项目计划方案状态",
                                  prop: "isEnabled"
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: _vm.ruleForm.isEnabled,
                                      callback: function($$v) {
                                        _vm.$set(_vm.ruleForm, "isEnabled", $$v)
                                      },
                                      expression: "ruleForm.isEnabled"
                                    }
                                  },
                                  _vm._l(_vm.status, function(item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: item.id
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "备注" } },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.ruleForm.remark,
                                    callback: function($$v) {
                                      _vm.$set(_vm.ruleForm, "remark", $$v)
                                    },
                                    expression: "ruleForm.remark"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _c(
                  "el-form",
                  {
                    ref: "ruleForm",
                    staticClass: "demo-form-inline",
                    attrs: {
                      "label-position": "right",
                      model: _vm.ruleForm,
                      rules: _vm.rules,
                      "label-width": "140px"
                    }
                  },
                  [
                    _c(
                      "el-row",
                      {
                        staticStyle: { "flex-flow": "row wrap" },
                        attrs: { type: "flex", justify: "start" }
                      },
                      _vm._l(_vm.detailBaseFields, function(item) {
                        return _c(
                          "el-col",
                          { key: item.key, attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: item.name } },
                              [
                                _c("div", [
                                  _vm._v(_vm._s(_vm.getDetailVal(item.key)))
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
          ],
          1
        )
      ]),
      this.type !== "view"
        ? _c(
            "div",
            { staticStyle: { margin: "10px 0", "font-size": "14px" } },
            [_vm._v(" 节点信息： ")]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "detail-card", staticStyle: { padding: "10px" } },
        [
          _c(
            "div",
            { staticClass: "bottom" },
            [
              _vm.type != "addOrder"
                ? _c("FunctionHeader", {
                    ref: "functionHeader",
                    attrs: {
                      "search-title": "请输入节点名称",
                      tags: _vm.functionHeader.tags
                    },
                    on: {
                      search: function($event) {
                        return _vm.searchTable()
                      }
                    },
                    model: {
                      value: _vm.functionHeader.searchVal,
                      callback: function($$v) {
                        _vm.$set(_vm.functionHeader, "searchVal", $$v)
                      },
                      expression: "functionHeader.searchVal"
                    }
                  })
                : _vm._e(),
              _c("MTable", {
                ref: "mTable",
                attrs: {
                  "default-expand-all": "",
                  "is-tree-data": true,
                  height: _vm.height,
                  columns: _vm.getColumns,
                  data: _vm.materialsList,
                  "show-page": false,
                  showAppend: _vm.type === "view" ? false : true,
                  onlyKey: "treeId",
                  "tree-props": {
                    children: "children",
                    hasChildren: "hasChildren"
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "index",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [
                        _vm._v(_vm._s(!row.parentId ? row.sort + 1 : ""))
                      ])
                    }
                  },
                  {
                    key: "nodeAttribute",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [
                        row.nodeAttribute != null
                          ? _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.planNodeAttribute[row.nodeAttribute].name
                                )
                              )
                            ])
                          : _vm._e()
                      ])
                    }
                  },
                  {
                    key: "planName",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "span",
                        { staticStyle: { display: "inline-block" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center"
                              }
                            },
                            [
                              _c("div", [_vm._v(_vm._s(row.planName))]),
                              _vm.type !== "view" && !row.isTaskNode
                                ? _c("img", {
                                    staticClass: "num-img",
                                    attrs: {
                                      src: require("@/assets/plans/add.png")
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.addTaskNode(row)
                                      }
                                    }
                                  })
                                : _vm._e()
                            ]
                          )
                        ]
                      )
                    }
                  },
                  {
                    key: "isTaskNode",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [
                        _c("span", [
                          _vm._v(_vm._s(row.isTaskNode ? "是" : "否"))
                        ])
                      ])
                    }
                  },
                  {
                    key: "startTimeAttribute",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [
                        row.startTimeAttribute != null
                          ? _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.startFinishTimeAttribute[
                                    row.startTimeAttribute
                                  ].name
                                )
                              )
                            ])
                          : _vm._e()
                      ])
                    }
                  },
                  {
                    key: "finishTimeAttribute",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [
                        row.finishTimeAttribute != null
                          ? _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.startFinishTimeAttribute[
                                    row.finishTimeAttribute
                                  ].name
                                )
                              )
                            ])
                          : _vm._e()
                      ])
                    }
                  },
                  {
                    key: "action",
                    fn: function(ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return _c(
                        "div",
                        {},
                        [
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.modify(row, $index)
                                  }
                                }
                              },
                              [_vm._v("修改")]
                            ),
                            _c("el-divider", {
                              attrs: { direction: "vertical" }
                            }),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  disabled: row.children.length ? true : false
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.del(row, $index)
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          ]
                        ],
                        2
                      )
                    }
                  }
                ])
              })
            ],
            1
          )
        ]
      ),
      _c("div", { staticClass: "order-footer" }, [
        _c("div"),
        _c(
          "div",
          { staticClass: "footer-ri" },
          [
            _c("el-button", { on: { click: _vm.returnList } }, [
              _vm._v(_vm._s(this.type !== "view" ? "取 消" : "返 回"))
            ]),
            this.type !== "view"
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", loading: _vm.loading },
                    on: { click: _vm.save }
                  },
                  [_vm._v("确 定")]
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _c("div", { staticClass: "lines" }),
      _c(
        "MDialog",
        {
          attrs: { title: _vm.planNodetitles[_vm.currentType] },
          on: { onOk: _vm.submitForm },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "MFormBuilder",
            {
              ref: "formBuild",
              attrs: {
                "form-data": _vm.formData,
                "form-list": _vm.addPlanFormList
              }
            },
            [
              _c(
                "div",
                {
                  attrs: { slot: "processFlowClassName" },
                  slot: "processFlowClassName"
                },
                [
                  _c("CURDSelect", {
                    attrs: {
                      "default-value": _vm.formData.planTypeId,
                      "api-name": "PlanType"
                    },
                    on: { change: _vm.selectName }
                  })
                ],
                1
              ),
              _c("div", { attrs: { slot: "startTime" }, slot: "startTime" }, [
                _c(
                  "div",
                  { staticStyle: { display: "flex" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: "请选择",
                          clearable: "",
                          filterable: ""
                        },
                        on: { change: _vm.changeStartTimePlan },
                        model: {
                          value: _vm.startPlanId,
                          callback: function($$v) {
                            _vm.startPlanId = $$v
                          },
                          expression: "startPlanId"
                        }
                      },
                      _vm._l(_vm.planNodeTimeList, function(item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.fullName, value: item.id }
                        })
                      }),
                      1
                    ),
                    _c("el-input-number", {
                      staticClass: "min-input",
                      staticStyle: { "margin-left": "10px" },
                      attrs: { "controls-position": "right" },
                      model: {
                        value: _vm.startTimeBasis,
                        callback: function($$v) {
                          _vm.startTimeBasis = $$v
                        },
                        expression: "startTimeBasis"
                      }
                    }),
                    _vm._v("天 ")
                  ],
                  1
                )
              ]),
              _c("div", { attrs: { slot: "finishTime" }, slot: "finishTime" }, [
                _c(
                  "div",
                  { staticStyle: { display: "flex" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: "请选择",
                          clearable: "",
                          filterable: ""
                        },
                        on: { change: _vm.changeFinishTimePlan },
                        model: {
                          value: _vm.finishPlanId,
                          callback: function($$v) {
                            _vm.finishPlanId = $$v
                          },
                          expression: "finishPlanId"
                        }
                      },
                      _vm._l(_vm.planNodeTimeList, function(item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.fullName, value: item.id }
                        })
                      }),
                      1
                    ),
                    _c("el-input-number", {
                      staticClass: "min-input",
                      staticStyle: { "margin-left": "10px" },
                      attrs: { "controls-position": "right" },
                      model: {
                        value: _vm.finishTimeBasis,
                        callback: function($$v) {
                          _vm.finishTimeBasis = $$v
                        },
                        expression: "finishTimeBasis"
                      }
                    }),
                    _vm._v("天 ")
                  ],
                  1
                )
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }