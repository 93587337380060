import { startFinishTimeAttribute } from '@/config/options.config'
let functionList = {}
export const getFunctionList = val => {
  functionList = val
}
export const columns = [
  // {
  //   type: 'selection',
  //   fixed: 'left',
  //   width: 80,
  //   reserveSelection: true
  // },
  {
    prop: 'projectNumber',
    label: '项目编号',
    hiddenSearch: true,
    width: 120,
    sortable: false,
    slotName:'projectNumber'
  },
  {
    prop: 'projectName',
    label: '项目名称',
    hiddenSearch: true,
    sortable: false
  }
]
export const columnsAll = [
  {
    prop: 'priority',
    label: '计划优先级',
    hiddenSearch: true,
    sortable: false,
    slotName: 'priority',
    form: {
      index: 0,
      tagName: 'el-select',
      options: [],
      defaultValue: 1
    }
  },
  {
    prop: 'deliveryTime',
    label: '交期',
    hiddenSearch: true,
    sortable: false,
    form: {
      index: 1,
      key: 'deliveryTime',
      tagName: 'el-date-picker',
      props: {
        align: 'center',
        type: 'date',
        valueFormat: 'yyyy-MM-dd',
        format: 'yyyy-MM-dd'
      }
    }
  },
  {
    prop: 'processFlowName',
    label: '工艺',
    hiddenSearch: true,
    sortable: false,
    slotName: 'processFlowName',
    form: {
      index: 2,
      key: 'processFlowId',
      tagName: 'div',
      customSlots: ['process']
    }
  },
  {
    prop: 'areaName',
    label: '产线',
    hiddenSearch: true,
    sortable: false,
    form: {
      index: 3,
      key: 'areaId',
      tagName: 'el-select'
    }
  },
  {
    prop: 'planStartTime',
    label: '计划开始时间',
    hiddenSearch: true,
    sortable: false,
    form: {
      index: 4,
      key: 'planStartTime',
      tagName: 'el-date-picker',
      required: false,
      props: {
        align: 'center',
        type: 'datetime',
        valueFormat: 'yyyy-MM-dd HH:mm:ss',
        format: 'yyyy-MM-dd HH:mm'
      }
    }
  },
  {
    prop: 'planEndTime',
    label: '计划结束时间',
    hiddenSearch: true,
    sortable: false,
    form: {
      index: 5,
      key: 'planEndTime',
      tagName: 'el-date-picker',
      required: false,
      props: {
        align: 'center',
        type: 'datetime',
        valueFormat: 'yyyy-MM-dd HH:mm:ss',
        format: 'yyyy-MM-dd HH:mm'
      }
    }
  },
  {
    prop: 'remark',
    label: '备注',
    hiddenSearch: true,
    sortable: false,
    form: { index: 8, tagName: 'el-input', required: false, props: { type: 'textarea' }}
  }
]
// 已下发 修改计划节点
export const HavecolumnsAll = [
  {
    prop: 'startTimeAttribute',
    label: '开始时间属性',
    hiddenSearch: true,
    sortable: false,
    slotName:'startTimeAttribute',
    minWidth:100,
    form: {
      tagName: 'el-select',
      options: startFinishTimeAttribute,
      required: false,
      on: { change: (val) => { functionList.startTimeAttributeChange(val) } }
    }
  },
  {
    prop: 'startTime',
    label: '开始时间',
    hiddenSearch: true,
    sortable: false,
    form:{
      tagName: 'div',
      required: false,
      customSlots: ['startTime']
    }
  },
  {
    prop: 'finishTimeAttribute',
    label: '完成时间属性',
    hiddenSearch: true,
    sortable: false,
    slotName:'finishTimeAttribute',
    minWidth:100,
    form: {
      tagName: 'el-select',
      options: startFinishTimeAttribute, required: false,
      on: { change: (val) => { functionList.finishTimeAttributeChange(val) } }
    }
  },
  {
    prop: 'finishTime',
    label: '完成时间',
    hiddenSearch: true,
    sortable: false,
    form:{
      tagName: 'div',
      required: false,
      customSlots: ['finishTime']
    }
  }
]
//修改项目所有计划节点
export const projectNodesColumns = [
  {
    prop: 'planObjectName',
    label: '计划对象',
    hiddenSearch: true,
    sortable: false,
    minWidth:100
  },
  {
    prop: 'planName',
    label: '计划名称',
    hiddenSearch: true,
    sortable: false,
    minWidth:100
  },
  {
    prop: 'planTypeName',
    label: '计划类型',
    hiddenSearch: true,
    sortable: false,
    minWidth:100
  },
  {
    prop: 'startTimeAttribute',
    label: '开始时间属性',
    hiddenSearch: true,
    sortable: false,
    slotName:'startTimeAttribute',
    minWidth:100
  },
  {
    prop: 'startTime',
    label: '开始时间',
    hiddenSearch: true,
    sortable: false,
    slotName:'startTime'
  },
  {
    prop: 'finishTimeAttribute',
    label: '完成时间属性',
    hiddenSearch: true,
    sortable: false,
    slotName:'finishTimeAttribute',
    minWidth:100
  },
  {
    prop: 'finishTime',
    label: '完成时间',
    hiddenSearch: true,
    sortable: false,
    slotName:'finishTime'
  }
]
//调整项目所有计划节点
export const projectNodesAdjustColumns = [
  {
    prop: 'planObjectName',
    label: '计划对象',
    hiddenSearch: true,
    sortable: false,
    minWidth:100
  },
  {
    prop: 'planName',
    label: '计划名称',
    hiddenSearch: true,
    sortable: false,
    minWidth:100
  },
  {
    prop: 'planTypeName',
    label: '计划类型',
    hiddenSearch: true,
    sortable: false,
    minWidth:100
  },
  {
    prop: 'startTime',
    label: '开始时间',
    hiddenSearch: true,
    sortable: false,
    slotName:'startAdjustTime'
  },
  {
    prop: 'finishTime',
    label: '完成时间',
    hiddenSearch: true,
    sortable: false,
    slotName:'finishAdjustTime'
  },
  {
    prop: 'adjustReason',
    label: '调整原因',
    hiddenSearch: true,
    sortable: false,
    slotName:'adjustReason'
  }
]
//异常处理列表
export const projectAbnormalColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'abnormalOrderCode',
    label: '异常单号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'majorType',
    label: '专业',
    hiddenSearch: true,
    sortable: false,
    slotName:'majorType',
    minWidth:100
  },
  {
    prop: 'status',
    label: '状态',
    hiddenSearch: true,
    sortable: false,
    slotName:'status',
    minWidth:100
  },
  {
    prop: 'currentProcess',
    label: '当前节点',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'currentProcessUser',
    label: '当前节点责任人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'initiatorUser',
    label: '提出人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'abnormalTypeName',
    label: '异常类型',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'abnormalRemark',
    label: '异常现象',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'judgeResult',
    label: '判定结果',
    hiddenSearch: true,
    slotName: 'judgeResult',
    sortable: false
  },
  {
    prop: 'tempPolicy',
    label: '临时对策',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'reason',
    label: '原因分析',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'finalResult',
    label: '最终结果',
    hiddenSearch: true,
    slotName: 'finalResult',
    sortable: false
  }
]
//变更列表
export const projectECNColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'prePlanChangeNumber',
    label: '变更单号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'changeType',
    label: '变更类型',
    hiddenSearch: true,
    sortable: false,
    slotName:'changeType',
    minWidth:100
  },
  {
    prop: 'projectNumber',
    label: '项目编号',
    hiddenSearch: true,
    sortable: false,
    minWidth:100
  },
  {
    prop: 'projectName',
    label: '项目名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialName',
    label: '机台号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'status',
    label: '变更状态',
    hiddenSearch: true,
    slotName:'changeStatus',
    sortable: false
  },
  {
    prop: 'needOperate',
    label: '是否需要执行',
    hiddenSearch: true,
    slotName:'needOperate',
    sortable: false,
    minWidth:120
  },
  {
    prop: 'businessUnitName',
    label: '业务部门',
    hiddenSearch: true,
    sortable: false
  }
]
//物料差异表
export const projectMaterialsDiffColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'workOrderNumber',
    label: '工单编号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    hiddenSearch: true,
    sortable: false,
    minWidth:100
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'mesWorkOrderQuantity',
    label: 'MES工单数量',
    hiddenSearch: true,
    sortable: false,
    minWidth:120
  },
  {
    prop: 'topGpWorkOrderQuantity',
    label: 'TOPGP工单数量',
    hiddenSearch: true,
    sortable: false,
    minWidth:150
  },
  {
    prop: 'differenceQuantity',
    label: '差异数量',
    hiddenSearch: true,
    sortable: false,
    minWidth:100
  },
  {
    prop: 'projectNumber',
    label: '项目编号',
    hiddenSearch: true,
    sortable: false,
    minWidth:100
  },
  {
    prop: 'projectName',
    label: '项目名称',
    hiddenSearch: true,
    sortable: false
  }
]

