<template>
  <div />
</template>

<script>
import { getToken, setToken } from '@/utils/auth'
import baseSetting from '@/api/sets/base-setting'

import Hapi from '@/api/home'
export default {
  name: 'ReHome',
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const token = getToken()
      const queryToken = to.query ? to.query.token : ''
      const name = to.query ? to.query.name : ''
      if (queryToken && queryToken !== token) {
        vm.getUserInfos(queryToken, to.params.memberCode, to.params.login, name, to.query)
      } else {
        vm.$router.push({ name: 'layout' })
      }
    })
  },
  methods: {
    // 根据token获取用户信息
    async getUserInfos(token, memberCode, login, name, query) {
      const res = await Hapi.getInfoByToken({
        token: token,
        memberCode: memberCode,
        login: login })
      if (res) {
        const code = res.memberCode === 'mubyte' ? 'mubai' : res.memberCode
        localStorage.setItem('memberCode', code)
        if (query.tipImgSrc) sessionStorage.setItem('tipImgSrc', query.tipImgSrc)
        sessionStorage.setItem('videoIndex', query.index)
        setToken(res.token)
        await this.$store.dispatch('user/getInfo')
        await this.$store.dispatch('user/priorityStatusList')
        if (code === 'lingyang2') {
          this.$router.push({ name: 'equipmentAccountList' })
          return false
        }
        if (code === 'lingyang3') {
          this.$router.push({ name: 'callSearch' })
          return false
        }
        if (name) {
          this.$router.push({ name: name })
        } else {
          this.$router.push({ name: 'layout' })
        }

      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
