<template>
  <div class="searchs">
    <!-- 顶部折叠搜索 -->
    <el-row>
      <el-col :md="18" :gutter="10">
        <el-row :gutter="10" type="flex" justify="start" style="flex-flow:row wrap">
          <el-col :md="5">
            <m-remote-select
              v-model="searchFormData.procedureId"
              class="input-prepend"
              :placeholder="'工序'"
              :interface-fun="wapi.getProcedureListPage"
              :base-condition="{ isEnabled: 1 }"
              :options.sync="getCurrentProcedureList"
              filterable
              clearable
              :label-name="'name'"
              :search-name="'name'"
              @on-change="searchData"
            />
          </el-col>
          <el-col :md="7">
            <SelectMultipleMaterialsNew ref="multipleMaterial" @typeChange="searchData" />
          </el-col>
          <el-col :md="5">
            <m-remote-select
              v-model="searchFormData.batchNumber"
              class="input-prepend"
              :placeholder="'批次码'"
              :interface-fun="bapi.getBatchNumberListPage"
              :base-condition="{ isEnabled: 1 }"
              :options.sync="batchNumberList"
              filterable
              clearable
              :label-name="'batchNumber'"
              :value-name="'batchNumber'"
              :search-name="'batchNumber'"
              @on-change="searchData"
            />
          </el-col>
          <el-col :md="6">
            <el-select v-model="searchFormData.inspectionSchemeId" placeholder="质检方案" filterable clearable @change="searchData">
              <el-option
                v-for="(item, index) in inspectionSchemeList"
                :key="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>

        </el-row>
        <el-row v-if="!show" type="flex" justify="start" style="flex-flow:row wrap" :gutter="10">
          <el-col :md="5">
            <el-select v-model="searchFormData.status" placeholder="处理状态" clearable filterable @change="searchData">
              <el-option
                v-for="item in statusList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :md="7">
            <el-date-picker
              v-model="searchFormData.endTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="提交开始时间"
              end-placeholder="结束时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              :picker-options="pickerOptions"
              clearable
              @change="searchData"
            />
          </el-col>
          <el-col :md="5">
            <el-select v-model="searchFormData.inspectorId" placeholder="检验人" clearable filterable @change="searchData">
              <el-option
                v-for="item in reporterList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
        </el-row>
      </el-col>
      <el-col :md="6" class="all-btns">
        <el-button size="small" type="primary" @click="searchData">查询</el-button>
        <el-button size="small" @click="resetData">重置</el-button>
        <div class="showMore" :style="{background: show ? '' : '#F8F8F8'}" @click="changeMore">
          <span>高级筛选</span>
          <i v-if="show" class="el-icon-caret-bottom icons" />
          <i v-else class="el-icon-caret-top" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { getUUid } from '@/utils'
import dayjs from 'dayjs'
import SelectMultipleMaterialsNew from '@/components/SelectMultipleMaterialsNew/SelectMultipleMaterialsNew'
import wapi from '@/api/information/production/procedure'
import bapi from '@/api/information/print-label/batch-number'

export default {
  components: { SelectMultipleMaterialsNew },
  props: {
    isHave: {
      type: Boolean,
      default: false
    },
    formOption: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      wapi,
      bapi,
      show: true,
      statusList: [{
        id: 0,
        name: '待处理'
      },
      {
        id: 1,
        name: '已处理'
      }],
      getCurrentProcedureList: [],
      batchNumberList: [],
      searchFormData: {
        procedureId: '',
        reporterId: '',
        inspectorId: '',
        createTime: [],
        endTime: [],
        batchNumber: '',
        inspectionSchemeId: ''
      },
      inspectionSchemeList: [],
      reporterList: [],
      selectKeyword: '0',
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            const end = dayjs().format('YYYY-MM-DD 23:59:59')
            const start = dayjs().format('YYYY-MM-DD 00:00:00')
            // start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end])
          }
        },
        {
          text: '昨天',
          onClick(picker) {
            const end = dayjs().subtract(1, 'day').format('YYYY-MM-DD 23:59:59')
            const start = dayjs().subtract(1, 'day').format('YYYY-MM-DD 00:00:00')
            // start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      }

    }
  },
  mounted() {
    this.reporterList = this.formOption.reporterList
    // this.getCurrentProcedureList = this.formOption.procedureOptions
    this.inspectionSchemeList = this.formOption.inspectionOptions
    this.batchNumberList = this.formOption.batchNumberOptions
  },
  methods: {
    changeMore() {
      this.show = !this.show
    },
    // 顶部查询
    searchData() {
      const obj = {}
      const searchDatas = []
      Object.keys(this.searchFormData).forEach(item => {
        if (Array.isArray(this.searchFormData[item])) {
          if (this.searchFormData[item].length > 0) {
            searchDatas.push({
              id: getUUid(),
              fieldName: item,
              fieldType: 'date',
              maxValue: `${dayjs(this.searchFormData[item][1]).format('YYYY-MM-DD')} 23:59:59`,
              minValue: `${dayjs(this.searchFormData[item][0]).format('YYYY-MM-DD')} 00:00:00`,
              operator: 'range'
            })
          }
        } else if (this.searchFormData[item] || this.searchFormData[item] === 0) {
          searchDatas.push({
            id: getUUid(),
            fieldName: item,
            fieldType: typeof this.searchFormData[item],
            fieldValue: this.searchFormData[item],
            operator: typeof this.searchFormData[item] === 'number' ? 'eq' : 'like'
          })
        }
      })
      let keyword
      if (this.$refs.multipleMaterial !== undefined) {
        keyword = this.$refs.multipleMaterial.keyword.code
      } else {
        keyword = ''
      }
      console.log('ceshi=>', searchDatas, this.selectKeyword, keyword)
      this.$emit('searchData', searchDatas, this.selectKeyword, keyword)
    },
    // 顶部重置
    resetData() {
      this.searchFormData = {
        procedureId: '',
        reporterId: '',
        inspectorId: '',
        createTime: [],
        endTime: [],
        batchNumber: '',
        inspectionSchemeId: ''
      }
      this.$refs.multipleMaterial.keyword = ''
      this.$emit('resetData')
    }
  }
}
</script>

<style scoped lang="scss">
 .searchs{
    padding-top: 10px;
    ::v-deep{
      .el-button-group .el-button{
        height: 32px;
      }

      .el-date-editor--datetimerange.el-input, .el-date-editor--datetimerange.el-input__inner{
        width: 100%;
      }
      .el-date-editor.el-input, .el-date-editor.el-input__inner{
        width: 100%;
      }
      .el-select{
        width:100%;
      }

    }
    ::v-deep{
      .el-col-md-5,.el-col-md-6{
        margin-bottom: 10px;
      }
   }
  .showMore{
    width: 88px;
    height: 40px;
    border-radius: 4px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #607FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
    .icons{
     color: #DDE3EB;
    }
    &:hover{
      background:#F8F8F8;
      i{ color: #607FFF;}
    }
  }
  .all-btns{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
  }
 }
</style>
