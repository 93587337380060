import { formatColumns } from '@/utils'
import { status } from '@/config/options.config'

export const base = [
  {
    prop: 'name',
    label: '方案名称',
    sortable: false,
    hiddenSearch: true,
    slotName: 'name',
    form: { tagName: 'el-input', props: { type: 'textarea' }, colSpan: 24 }
  },
  {
    prop: 'type',
    label: '业务类型',
    sortable: false,
    hiddenSearch: true,
    slotName:'type'
  },
  {
    prop: 'creator',
    label: '创建人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'createTime',
    label: '创建时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'lastUpdateTime',
    label: '最近更新时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'isEnabled',
    label: '检验方法状态',
    sortable: false,
    slotName: 'isEnabled',
    filters: formatColumns(status),
    width: 120,
    form: {
      tagName: 'el-select',
      options: status,
      defaultValue: 1
    }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' }
    }
  }
]
