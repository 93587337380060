var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "role-wrap" },
    [
      _c(
        "Split",
        {
          attrs: { offset: _vm.split.offset, height: _vm.splitHeight },
          on: {
            "update:offset": function($event) {
              return _vm.$set(_vm.split, "offset", $event)
            }
          }
        },
        [
          _c("template", { slot: "left" }, [
            _c(
              "div",
              { staticStyle: { padding: "10px" } },
              [
                _c("el-input", {
                  staticClass: "input-with-select",
                  attrs: { placeholder: "搜索计划编号", clearable: "" },
                  on: { input: _vm.onInput },
                  model: {
                    value: _vm.searchCode,
                    callback: function($$v) {
                      _vm.searchCode = $$v
                    },
                    expression: "searchCode"
                  }
                }),
                _vm._l(_vm.demandList, function(item) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      staticClass: "side-item",
                      style: {
                        color:
                          _vm.actItem.id === item.id ? "#8099ff" : "#606266"
                      },
                      on: {
                        click: function($event) {
                          return _vm.onSideClick(item)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(item.producePlanNumber))]
                  )
                })
              ],
              2
            )
          ]),
          _c("template", { slot: "right" }, [
            _c(
              "div",
              { staticClass: "orders" },
              [
                _c("SearchForm", {
                  ref: "searchForm",
                  staticStyle: { padding: "0" },
                  attrs: {
                    "form-list-extend": _vm.searchFormList,
                    "form-data": _vm.searchFormData
                  },
                  on: {
                    "update:formData": function(data) {
                      return (_vm.searchFormData = data)
                    },
                    search: _vm.reSearch
                  }
                }),
                _c("FunctionHeader", {
                  ref: "functionHeader",
                  staticClass: "func-header",
                  staticStyle: { padding: "10px 0 10px 0" },
                  attrs: {
                    "search-title": "请输入物料编号/名称",
                    tags: _vm.functionHeader.tags
                  },
                  on: {
                    search: function($event) {
                      return _vm.$refs.mTable.setTableData(true)
                    }
                  },
                  model: {
                    value: _vm.functionHeader.searchVal,
                    callback: function($$v) {
                      _vm.$set(_vm.functionHeader, "searchVal", $$v)
                    },
                    expression: "functionHeader.searchVal"
                  }
                }),
                _c("MTable", {
                  ref: "mTable",
                  staticClass: "table-left",
                  attrs: {
                    columns: _vm.columns,
                    height: _vm.height,
                    "highlight-current-row": "",
                    "set-data-method": _vm.getTableData
                  },
                  on: { "current-change": _vm.handleCurrentChange },
                  scopedSlots: _vm._u([
                    {
                      key: "index",
                      fn: function(ref) {
                        var $index = ref.$index
                        return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                      }
                    },
                    {
                      key: "materialsCode",
                      fn: function(ref) {
                        var row = ref.row
                        return _c("NewPageOpen", {
                          attrs: {
                            path: {
                              name: "materialsDetail",
                              query: { id: row.materialsId }
                            },
                            text: row.materialsCode
                          }
                        })
                      }
                    },
                    {
                      key: "procedureCode",
                      fn: function(ref) {
                        var row = ref.row
                        return _c("NewPageOpen", {
                          attrs: {
                            path: {
                              name: "procedureDetail",
                              query: { id: row.procedureId }
                            },
                            text: row.procedureCode
                          }
                        })
                      }
                    },
                    {
                      key: "lockedStock",
                      fn: function(ref) {
                        var row = ref.row
                        return _c("div", {}, [
                          _vm._v(_vm._s(row.lockedStock)),
                          _c("i", {
                            staticClass: "el-icon-edit",
                            staticStyle: { color: "#607FFF" },
                            on: {
                              click: function($event) {
                                return _vm.editStock(row)
                              }
                            }
                          })
                        ])
                      }
                    }
                  ])
                }),
                _c(
                  "el-dialog",
                  {
                    attrs: {
                      title: "编辑",
                      visible: _vm.editDialogVisible,
                      width: "500px"
                    },
                    on: {
                      "update:visible": function($event) {
                        _vm.editDialogVisible = $event
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticStyle: { width: "350px", margin: "auto" } },
                      [
                        _c("div", [
                          _vm._v(
                            "当前库存：" + _vm._s(_vm.editRow.currentStock)
                          )
                        ]),
                        _c("div", { staticStyle: { margin: "10px 0" } }, [
                          _vm._v(
                            "可用库存：" + _vm._s(_vm.editRow.availableStock)
                          )
                        ]),
                        _c(
                          "div",
                          [
                            _vm._v("占用库存： "),
                            _c("el-input-number", {
                              attrs: {
                                "controls-position": "right",
                                min: 0,
                                max: _vm.editRow.availableStock || 0,
                                step: 0.001
                              },
                              on: {
                                blur: function(e) {
                                  return _vm.onBlur(
                                    e,
                                    _vm.editRow,
                                    "lockedStock"
                                  )
                                }
                              },
                              model: {
                                value: _vm.editRow.lockedStock,
                                callback: function($$v) {
                                  _vm.$set(_vm.editRow, "lockedStock", $$v)
                                },
                                expression: "editRow.lockedStock"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "dialog-footer",
                        attrs: { slot: "footer" },
                        slot: "footer"
                      },
                      [
                        _c("el-button", { on: { click: _vm.cancelEdit } }, [
                          _vm._v("取 消")
                        ]),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.saveStock }
                          },
                          [_vm._v("确 定")]
                        )
                      ],
                      1
                    )
                  ]
                ),
                _c("WaitDemand", {
                  ref: "waitDemand",
                  on: { refresh: _vm.initPage }
                })
              ],
              1
            )
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }