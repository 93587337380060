import { formListColumns } from './columns'
import { transformColumnsToForm } from '@/utils'

const formList = transformColumnsToForm(formListColumns)
  formList.push({
    label: '附件',
    key: 'attachments',
    tagName: 'div',
    required: false,
    children: [{
      customSlots: ['drawing']
    }]
  })

export default formList
