<template>
  <div class="product-all">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane v-for="(item, index) in list" :key="index" :label="item.produceWorkOrderNumber" :name="item.produceWorkOrderNumber" />
    </el-tabs>
    <div v-if="info.reductionQuantity || info.divisionQuantity || info.repairQuantity" class="all-nums">
      <el-timeline :reverse="false">
        <el-timeline-item
          v-if="info.reductionQuantity"
          :color="'#607FFF'"
          class="eltimelinefocus"
        >
          <div class="txt">减产数量：<span>{{ info.reductionQuantity ? info.reductionQuantity : 0 }}</span></div>
          <div class="report-li-content">
            <div v-if="info.reductionRecordList && info.reductionRecordList.length > 0" class="report-params">
              <div class="params-li" @click="tapShow(1)">
                <span v-if="showChai" class="el-icon-arrow-up" />
                <span v-else class="el-icon-arrow-right" />
                <span>减产记录</span>
              </div>
              <MTable
                v-if="showChai"
                ref="mTable"
                :columns="reductionColumns"
                :data="info.reductionRecordList"
                :height="200"
                style="margin-right: 42px"
                :show-page="false"
              >
                <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
              </MTable>
            </div>
          </div>
        </el-timeline-item>
        <el-timeline-item
          v-if="info.divisionQuantity"
          :color="'#607FFF'"
          class="eltimelinefocus"
        >
          <div class="txt">拆单数量：<span>{{ info.divisionQuantity ? info.divisionQuantity : 0 }}</span></div>
          <div class="report-li-content">
            <div v-if="info.divisionRecordList && info.divisionRecordList.length > 0" class="report-params">
              <div class="params-li" @click="tapShow(2)">
                <span v-if="showDivision" class="el-icon-arrow-up" />
                <span v-else class="el-icon-arrow-right" />
                <span>拆单记录</span>
              </div>
              <MTable
                v-if="showDivision"
                ref="mTable"
                :columns="divisionColumns"
                :data="info.divisionRecordList"
                :height="200"
                style="margin-right: 42px"
                :show-page="false"
              >
                <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
                <div slot="newProduceWorkOrderNumber" slot-scope="{ row }">
                  <NewPageOpen :path="{ name: 'workDetail', query: {id: row.newProduceWorkOrderId}}" :text="row.newProduceWorkOrderNumber" />
                </div>
              </MTable>
            </div>
          </div>
        </el-timeline-item>
        <el-timeline-item
          v-if="info.repairQuantity"
          :color="'#607FFF'"
          class="eltimelinefocus"
        >
          <div class="txt">返修数量：<span>{{ info.repairQuantity ? info.repairQuantity : 0 }}</span></div>
          <div class="report-li-content">
            <div
              v-if="(info.repairRecordList && info.repairRecordList.length > 0) ||
                (info.inspectionRepairRecordList && info.inspectionRepairRecordList.length > 0) "
              class="report-params"
            >
              <div class="params-li" @click="tapShow(3)">
                <span v-if="showRepair" class="el-icon-arrow-up" />
                <span v-else class="el-icon-arrow-right" />
                <span>返修记录</span>
              </div>
              <MTable
                v-if="showRepair"
                ref="mTable"
                :columns="repairColumns"
                :data="fixAll(info)"
                :height="200"
                style="margin-right: 42px"
                :show-page="false"
              >
                <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
                <div slot="repairPlanNumber" slot-scope="{ row }">
                  <div v-if="row.repairType === '报工返修'">
                    {{ row.repairPlanNumber }}
                  </div>
                  <div v-else>
                    {{ fixRepair(row.planList) }}
                  </div>
                </div>
                <div slot="repairWorkOrderNumber" slot-scope="{ row }">
                  <div v-if="row.repairType === '报工返修'">
                    <NewPageOpen :path="{ name: 'workDetail', query: {id: row.repairWorkOrderId}}" :text="row.repairWorkOrderNumber" />
                  </div>
                  <div v-else>
                    <div v-for="item in row.planList && row.planList" :key="item.id">
                      <NewPageOpen v-if="item.produceWorkOrder" :path="{ name: 'workDetail', query: {id: item.produceWorkOrder.id}}" :text="item.produceWorkOrder.produceWorkOrderNumber" />
                    </div>
                  </div>
                </div>
              </MTable>
            </div>
          </div>
        </el-timeline-item>
      </el-timeline>
    </div>
    <div class="radio-all">
      <el-radio-group v-model="reverse" @change="changeReverse">
        <el-radio :label="true">倒序</el-radio>
        <el-radio :label="false">正序</el-radio>
      </el-radio-group>
      <div class="radio-ri">
        <div class="ri-tit" @click="selectTit">
          <span class="tit">{{ currentIdx ? '全部折叠' : '全部展开' }}</span>
          <span v-if="currentIdx" class="el-icon-arrow-down" />
          <span v-if="!currentIdx" class="el-icon-arrow-up" />
        </div>
        <el-radio-group v-model="show" @change="getTableDatas">
          <el-radio :label="0">显示全部</el-radio>
          <el-radio :label="1">只看报工记录</el-radio>
          <el-radio :label="2">只看质检记录</el-radio>
        </el-radio-group>
        <el-select v-model="currentProcedureIds" class="selec-produce" collapse-tags placeholder="请选择工序" filterable multiple clearable @change="searchData">
          <el-option
            v-for="item in getCurrentProcedureList"
            :key="item.procedureId"
            :label="item.procedureName"
            :value="item.procedureId"
          />
        </el-select>
      </div>
    </div>
    <div class="box">
      <div v-for="(item, index) in datas" :key="item.id" class="box-li">
        <div v-if="index !== datas.length -1" class="el-timeline-item__tail" />
        <div class="li-left">
          {{ item.procedureName }}
          <div class="des">({{ item.type === 1 ? '外协任务' : '自制任务' }})</div>
        </div>
        <div class="li-right">
          <div class="li-right-content">
            <div class="content-top">
              <div class="tit">进站时间：{{ item.arrivalTime }}</div>
              <div class="tit">出站时间：{{ item.departureTime }}</div>
              <div class="tit">状态：<span :style="{color: statusOrder[item.status].color, background: statusOrder[item.status].background }">{{ statusOrder[item.status].name }}</span></div>
            </div>
            <!-- 外协 -->
            <div v-if="item.outsourcingList && item.outsourcingList.length > 0" class="content-report">
              <div class="li-le">
                <div class="line" />
                <img src="@/assets/quality/record.png" class="img">
                <div class="title">外协记录</div>
              </div>
              <div class="li-ri">
                <div class="des">总数量：<span>{{ Number(item.inWarehouseQuantity) }}</span></div>
              </div>
            </div>
            <div class="report-li">
              <el-timeline :reverse="false">
                <el-timeline-item
                  v-for="outsource in item.outsourcingList"
                  :key="outsource.id"
                  :color="'#607FFF'"
                  class="eltimelinefocus"
                >
                  <div class="txt">{{ outsource.type === 0 ? '入库' : '出库' }}</div>
                  <div class="report-li-content">
                    <div class="content-tops">
                      <div class="txt">{{ outsource.type === 0 ? '入库' : '出库' }}时间：{{ outsource.operateTime }}</div>
                      <div class="txt">操作人：{{ outsource.operator }}</div>
                      <div class="txt">数量：<span>{{ outsource.quantity }}</span></div>
                    </div>

                  </div>
                </el-timeline-item>
              </el-timeline>
            </div>
            <!-- 报工 -->
            <div v-if="item.reportWorkList && item.reportWorkList.length > 0" class="content-report">
              <div class="li-le">
                <div class="line" />
                <img src="@/assets/quality/record.png" class="img">
                <div class="title">报工记录</div>
              </div>
              <div class="li-ri">
                <div class="des">总数量：<span>{{ item.reportWorkQuantity }}</span></div>
              </div>
            </div>
            <div class="report-li">
              <el-timeline :reverse="false">
                <el-timeline-item
                  v-for="(activity, idx) in item.reportWorkList"
                  :key="activity.id"
                  :color="'#607FFF'"
                  class="eltimelinefocus"
                >
                  <div class="txt">第{{ item.reportWorkList.length - idx }}次报工</div>
                  <div class="report-li-content">
                    <div class="content-tops">
                      <div class="txt">报工时间：{{ activity.operateTime }}</div>
                      <div class="txt">报工人：{{ activity.operator }}</div>
                      <div class="txt">报工数量：<span>{{ activity.reportWorkQuantity }}</span></div>
                    </div>
                    <div v-if="fixJson(activity.procedureDetails).length > 0" class="report-params">
                      <div class="params-li" @click="selectInspection(idx, index)">
                        <span v-if="activity.checked" class="el-icon-arrow-up" />
                        <span v-else class="el-icon-arrow-right" />
                        <span>工序上传参数</span>
                      </div>
                      <div v-if="activity.checked" class="params-content">
                        <div v-for="row in fixJson(activity.procedureDetails)" :key="row.name" class="params-card">
                          <div class="header flex-sbc">
                            <div>参数组标题：{{ row.name }}</div>
                          </div>
                          <div class="body">
                            <div v-for="item in row.fromData" :key="item.id">
                              <span v-if="item.type !== 4" class="bot-ti">{{ item.key }}：{{ fixData(item.value) }}</span>
                              <span v-else class="bot-ti">{{ item.key }}：
                                <a v-for="items in fixArr(item.value)" :key="items" :href="items" target="_blank" class="imgs">
                                  <img :src="items">
                                </a>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="activity.receiveRecordList && activity.receiveRecordList.length > 0" class="report-params">
                      <div class="params-li" @click="selectRecord(idx, index)">
                        <span v-if="activity.revieveChecked" class="el-icon-arrow-up" />
                        <span v-else class="el-icon-arrow-right" />
                        <span>投产记录</span>
                      </div>
                      <MTable
                        v-if="activity.revieveChecked"
                        ref="mTable"
                        :columns="columns"
                        :data="activity.receiveRecordList"
                        :height="300"
                        style="margin-right: 42px"
                        :show-page="false"
                      >
                        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
                      </MTable>
                    </div>
                  </div>
                </el-timeline-item>
              </el-timeline>
            </div>
            <!-- 报废 -->
            <div v-if="item.reportWasteRecordList && item.reportWasteRecordList.length > 0" class="content-report">
              <div class="li-le">
                <div class="line" />
                <img src="@/assets/quality/record.png" class="img">
                <div class="title">报废记录</div>
              </div>
              <div class="li-ri">
                <div class="des">总数量：<span>{{ item.wasteQuantity }}</span></div>
              </div>
            </div>
            <div v-if="item.reportWasteRecordList && item.reportWasteRecordList.length > 0" class="report-li">
              <MTable
                ref="mTable"
                :columns="reportWasteColumns"
                :data="item.reportWasteRecordList"
                :height="200"
                style="margin: 0 40px 20px 30px"
                :show-page="false"
              >
                <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
              </MTable>
            </div>
            <!-- 质检 -->
            <div v-if="item.inspectionSheetList && item.inspectionSheetList.length > 0" class="content-report">
              <div class="li-le">
                <div class="line organce" />
                <img src="@/assets/quality/record.png" class="img">
                <div class="title">质检记录</div>
              </div>
            </div>
            <div class="report-li">
              <el-timeline :reverse="false">
                <el-timeline-item
                  v-for="(qualty, idxs) in item.inspectionSheetList"
                  :key="idxs"
                  :color="'#607FFF'"
                  class="eltimelinefocus"
                >
                  <div class="txt">{{ qualty.inspectionType === 0 ? '首检' : '过程检' }}</div>
                  <div class="report-li-content">
                    <div class="content-tops">
                      <div class="txt">提交时间：{{ qualty.endTime }}</div>
                      <div class="txt">检验人：{{ qualty.inspectorName }}</div>
                      <div v-if="qualty.inspectionType === 1" class="txt">合格率：<span>{{ fixDatas(qualty.passRate) }}%</span></div>
                    </div>
                    <div class="content-tops">
                      <div class="txt">质检单号：{{ qualty.number }}</div>
                      <div v-if="qualty.inspectionType === 1" class="txt">检验结果：
                        <span class="suc">合格：{{ qualty.okCount }}</span>
                        <span class="fail">不合格：{{ qualty.ngCount }}（ 让步接收：{{ qualty.degradeCount }} ； 报废：{{ qualty.scrapCount }} ； 返修：{{ qualty.repairCount }} ）</span>
                      </div>
                      <div v-if="qualty.inspectionType === 0" class="txt">
                        <span v-if="qualty.result === 1" class="suc">首检合格</span>
                        <span v-if="qualty.result === 0" class="fail">首检不合格</span>
                        <span v-if="qualty.result === 0" style="color: #f00">不合格说明：{{ qualty.ngExplain }}</span>
                      </div>
                    </div>
                    <div class="report-params">
                      <div class="params-li" @click="selectQualityRecord(idxs, index)">
                        <span v-if="qualty.qualityChecked" class="el-icon-arrow-up" />
                        <span v-else class="el-icon-arrow-right" />
                        <span>检验记录</span>
                      </div>
                      <div v-if="qualty.qualityChecked">
                        <!-- <quality-table
                        v-if="qualty.inspectionEntryList && qualty.inspectionEntryList.length > 0"
                        ref="qualityTable"
                        :isView="isView"
                        :inspectionGroup="qualty.inspectionEntryList"
                        style="margin-right: 20px;"
                        ></quality-table> -->
                        <inspection-record
                          v-if="qualty.inspectionEntryList && qualty.inspectionEntryList.length > 0"
                          ref="qualityTable"
                          :base-form-data="qualty"
                          style="margin-right: 20px;"
                        />
                      </div>
                    </div>
                  </div>
                </el-timeline-item>
              </el-timeline>
            </div>
          </div>
          <div class="li-right-arrow" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/quality/batch-code-tracing'
import wapi from '@/api/production/operation-task'
import InspectionRecord from './inspection-record'
import { getUUid } from '@/utils'
import { Encrypt } from '@/utils/sercet'
import { reportWasteColumns, reductionColumns, divisionColumns, repairColumns } from './columns'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'

export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'ProductAll',
  components: { NewPageOpen, InspectionRecord },
  props: {
    baseData: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      reportWasteColumns,
      showDivision: false,
      showRepair: false,
      showChai: false,
      reductionColumns,
      divisionColumns,
      repairColumns,
      info: {},
      currentProcedureIds: [],
      getCurrentProcedureList: [],
      isFirst: true,
      showIndex: [],
      showIndexs: [],
      isView: true,
      batchNumber: '',
      produceWorkOrderId: undefined,
      activeName: '',
      list: [],
      reverse: true,
      show: 0,
      currentIdx: true,
      currentIdx: [],
      columns: [
        {
          prop: 'index',
          label: '序号',
          slotName: 'index',
          hiddenSearch: true,
          sortable: false,
          width: 80
        },
        {
          prop: 'batchNumber',
          label: '批次码',
          hiddenSearch: true,
          sortable: false
        },
        {
          prop: 'materialsCode',
          label: '物料编码',
          sortable: false,
          hiddenSearch: true
        },
        {
          prop: 'materialsName',
          label: '物料名称',
          sortable: false,
          hiddenSearch: true
        },
        {
          prop: 'materialsSpecifications',
          label: '规格',
          sortable: false,
          hiddenSearch: true,
          width: 300
        },
        {
          prop: 'materialsUnit',
          label: '单位',
          sortable: false,
          hiddenSearch: true
        },
        {
          prop: 'quantity',
          label: '投产数量',
          sortable: false,
          hiddenSearch: true
        }
      ],
      isShow: true,
      datas: [],
      initData: [],
      statusOrder: [{
        id: 1,
        name: '待开始',
        color: '#E02020',
        background: 'rgba(224, 32, 32, 0.1)'
      },
      {
        id: 2,
        name: '进行中',
        color: '#0091FF',
        background: 'rgba(0, 145, 255, 0.1)'
      },
      {
        id: 3,
        name: '已完成',
        color: '#9597AE',
        background: 'rgba(149, 151, 174, 0.1)'
      }]
    }
  },
  mounted() {
    console.log('baseData.workOrderInfoList--', this.baseData.workOrderInfoList)
    this.list = this.baseData ? this.baseData.workOrderInfoList : []
    this.activeName = this.baseData ? this.baseData.workOrderInfoList[0].produceWorkOrderNumber : ''
    this.batchNumber = this.baseData ? this.baseData.batchNumber.batchNumber : ''
    this.produceWorkOrderId = this.baseData ? this.baseData.workOrderInfoList[0].produceWorkOrderId : undefined
    this.datas = this.baseData ? this.baseData.workOrderInfoList[0].taskList : []
    this.info = this.baseData ? this.baseData.workOrderInfoList[0] : {}
    this.initData = this.datas
    this.getOperatorPages()
  },
  methods: {
    fixRepair(val) {
      if (val) return val.map(item => item.producePlanNumber).join()
    },
    fixAll(info) {
      const list = info.repairRecordList && info.repairRecordList.length > 0
        ? info.repairRecordList.map(item => {
          return {
            ...item,
            repairType: '报工返修'

          }
        }) : []
      const zList = info.inspectionRepairRecordList && info.inspectionRepairRecordList.length > 0
        ? info.inspectionRepairRecordList.map(item => {
          return {
            ...item,
            repairType: '质检返修',
            repairWorkQuantity: item.repairCount
          }
        }) : []
      return [...list, ...zList]
    },
    tapShow(val) {
      if (val === 1) {
        this.showChai = !this.showChai
      }
      if (val === 2) {
        this.showDivision = !this.showDivision
      }
      if (val === 3) {
        this.showRepair = !this.showRepair
      }
    },
    // 工序
    async getOperatorPages() {
      const search = [{
        id: getUUid(),
        fieldName: 'produceWorkOrderId',
        fieldType: 'number',
        fieldValue: this.produceWorkOrderId,
        operator: 'eq'
      },
      {
        id: getUUid(),
        fieldName: 'isArrival',
        fieldType: 'number',
        fieldValue: 1,
        operator: 'eq'
      }]
      const res = await wapi.getOrderTaskpageno({ searchVal: Encrypt(JSON.stringify([...search])) })
      if (res) {
        this.getCurrentProcedureList = res
      }
    },
    searchData() {
      const list = []
      if (this.currentProcedureIds && this.currentProcedureIds.length === 0) {
        this.datas = this.initData
        return
      }
      for (let i = 0; i < this.initData.length; i++) {
        if (this.currentProcedureIds.includes(this.initData[i].procedureId)) {
          list.push(this.initData[i])
        }
      }
      this.datas = list
    },
    fixDatas(val) {
      const number = String(val).replace(/^(.*\..{2}).*$/, '$1')
      return Number(number)
    },
    fixData(val) {
      if (val) {
        return val.split('^').join('；')
      }
    },
    fixArr(val) {
      if (val) {
        return val.split('^')
      }
    },
    selectInspection(idx, index) {
      const val = !this.datas[index].reportWorkList[idx].checked
      const list = Object.assign({}, this.datas[index].reportWorkList[idx], {
        checked: val
      })
      this.datas[index].reportWorkList.splice(idx, 1, list)
    },
    selectRecord(idx, index) {
      const val = !this.datas[index].reportWorkList[idx].revieveChecked
      const list = Object.assign({}, this.datas[index].reportWorkList[idx], {
        revieveChecked: val
      })
      this.datas[index].reportWorkList.splice(idx, 1, list)
    },
    selectQualityRecord(idx, index) {
      const val = !this.datas[index].inspectionSheetList[idx].qualityChecked
      const list = Object.assign({}, this.datas[index].inspectionSheetList[idx], {
        qualityChecked: val
      })
      this.datas[index].inspectionSheetList.splice(idx, 1, list)
    },
    fixJson(val) {
      if (val) {
        const value = JSON.parse(val)
        return value
      }
    },
    changeReverse() {
      this.getTableDatas()
    },
    async getTableDatas() {
      const res = await api.getProduceBatchNumberFullDeatailTrace({
        produceWorkOrderId: this.produceWorkOrderId,
        sort: this.reverse ? 0 : 1,
        type: this.show
      })
      if (res) {
        this.datas = res
        this.initData = res
      }
    },
    selectParams() {
      this.isShow = !this.isShow
    },
    async handleClick(tab, event) {
      console.log('ceshi==>', tab.index)
      this.produceWorkOrderId = this.list[+tab.index].produceWorkOrderId
      this.show = 0
      this.reverse = true
      await this.getOperatorPages()
      await this.getTableDatas()
      this.info = this.baseData.workOrderInfoList[tab.index]
    },
    selectTit() {
      this.currentIdx = !this.currentIdx
      for (let i = 0; i < this.datas.length; i++) {
        if (this.datas[i].reportWorkList && this.datas[i].reportWorkList.length > 0) {
          for (let j = 0; j < this.datas[i].reportWorkList.length; j++) {
            this.datas[i].reportWorkList[j].checked = !this.currentIdx
            this.datas[i].reportWorkList[j].revieveChecked = !this.currentIdx
          }
        }
        if (this.datas[i].inspectionSheetList && this.datas[i].inspectionSheetList.length > 0) {
          for (let k = 0; k < this.datas[i].inspectionSheetList.length; k++) {
            this.datas[i].inspectionSheetList[k].qualityChecked = !this.currentIdx
          }
        }
      }
      console.log('uiui=>', this.datas)
    }
  }
}
</script>

<style scoped lang="scss">
  .all-nums {
    background: #F6F7F9;
    padding: 20px;
    margin-bottom: 20px;
    .txt {
      line-height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393D60;
      font-weight: 600;
      margin-bottom: 5px;
      span {
        color: #607FFF;
      }
    }

    .report-li-content {

      .content-tops {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 72px;
        width: 100%;
        .suc {
          padding: 0 24px;
          // height: 30px;
          background: #E2F9E8;
          border-radius: 15px;
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #00AB29;
          line-height: 30px;
          margin-right: 10px;
        }
        .fail {
          padding: 0 20px;
          // height: 30px;
          background: #FFDEDE;
          border-radius: 15px;
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #E02020;
          line-height: 30px;
        }
      }
      .report-params {
        .params-li {
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #607FFF;
          margin-bottom: 10px;
          cursor: pointer;
          span {
            margin-right: 5px;
            display: inline-block;
          }
        }
        .params-content {
          width: 284px;
          // background: #FFFFFF;
          margin-bottom: 20px;
          .params-content-tit {
            width: 284px;
            height: 30px;
            background: #E5E7EA;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #393D60;
            line-height: 30px;
            padding-left: 17px;
          }
          .params-content-body {
            padding: 10px 17px;

            .params-content-li {
              height: 20px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #393D60;
              line-height: 20px;
              margin-bottom: 10px;
            }
          }
          .params-card {
            border: 1px solid #ECECF7;
            margin-bottom: 20px;
            width: 290px;
            .header {
              width: 100%;
              height: 48px;
              padding: 0 20px;
              background: #ECECF7;
            }
            .body {
              width: 100%;
              background: #FFFFFF;
              .bot-ti{
                // height: 36px;
                line-height:36px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #393D60;
                display: inline-block;
                margin-bottom: 16px;
                margin-left: 20px;
                word-break: break-all;
                .imgs{
                  width: 100px;
                  height: 100px;
                  display: inline-block;
                  margin-right: 10px;
                  img{
                  width: 100px;
                  height: 100px;
                  display: inline-block;
                  }
                }
              }
            }
          }
        }
      }
    }
    ::v-deep .el-timeline-item__tail {
      border-left: 1px dashed #607FFF !important;
    }
    ::v-deep {
      .el-timeline {
        .el-timeline-item {
          padding-bottom: 1px !important;
        }
      }
      .el-timeline-item__node--normal {
        width: 7px;
        height: 7px;
        left: 2px;
      }
      .eltimelinefocus {
        .el-timeline-item__node {
          border: 3px solid hsla(0, 0%, 90%, 0.9);
          background-clip: content-box;
          background: rgba(78, 151, 255, 1);
        }
        .el-timeline-item__node--normal {
          left: -2px;
          width: 14px;
          height: 14px;
        }
      }
      .timeline-name {
        font-size: 13px;
        color: rgba(0, 0, 0, 0.7);
      }
      .timeline-content {
        color: rgba(0, 0, 0, 0.5);
        font-size: 12px;
        margin-top: 4px;
        .timeline-content__idea {
          color: rgba(0, 0, 0, 0.7);
        }
      }
    }
  }
  ::v-deep {
    .el-timeline {
      padding-left: 26px;
    }
    .el-table th {
      background: #E5E7EA;
    }
    .el-input--suffix .el-input__inner {
      height: 32px;
      width: 150px;
    }
  }
  .selec-produce {
    margin-left: 20px;
    margin-top: -6px;

  }
  .product-all {
    .radio-all {
     display: flex;
     justify-content: space-between;
     margin-bottom: 20px;
     margin-top: 8px;
      .radio-ri {
        display: flex;
        justify-content: flex-end;
        .ri-tit {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #607FFF;
          margin-right: 60px;
          cursor: pointer;
        }
      }
    }
    .box {
      .box-li {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 22px;
        position: relative;
        .el-timeline-item__tail {
          position: absolute;
          top: 55px;
          left: 75px;
          height: 100%;
          border-left: 2px solid #607FFF;

        }
        .el-timeline-item__tail:before{
          position: absolute;
          content: "";
          width: 7px;
          height: 7px;
          border-radius: 100%;
          background: #FFFFFF;
          border: 1px solid #607FFF;
          top: -7px;
          left: -5px;
        }
        .el-timeline-item__tail:after {
          position: absolute;
          content: "";
          width: 7px;
          height: 7px;
          background: #FFFFFF;
          border: 1px solid #607FFF;
          bottom: 33px;
          left: -5px;
          border-radius: 100%;
        }
        .li-left {
          width: 154px;
          height: 48px;
          background: #EAEDFF;
          border-radius: 2px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #393D60;
          line-height: 20px;
          padding-top: 5px;
          margin-right: 18px;
          text-align: center;
          .des {
            font-size: 12px;
          }
        }
        .li-right {
          position: relative;
          background: #F6F7F9;
          width: calc(100% - 175px);
          .li-right-content {
            ::v-deep .el-timeline-item__tail {
              border-left: 1px dashed #607FFF !important;
            }
            .content-top {
              display: flex;
              justify-content: space-between;
              padding: 0 56px;
              .tit {
                line-height: 62px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #393D60;
                span {
                  width: 65px;
                  height: 22px;
                  background: rgba(96, 127, 255, .1);
                  border-radius: 4px;
                  font-size: 12px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #607FFF;
                  line-height: 22px;
                  display: inline-block;
                  text-align: center;
                }
              }
            }
            .content-report {
              display: flex;
              justify-content: space-between;
              align-items: center;
              height: 48px;
              background: #E5E7EA;
              margin-bottom: 20px;
              .li-le {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .line {
                  width: 4px;
                  height: 48px;
                  background: #607FFF;
                }
                .organce {
                  background: #FA6400;
                }
                .img {
                  width: 26px;
                  height: 29px;
                  display: block;
                  margin: 0 5px 0 15px;
                }
                .title {
                  font-size: 18px;
                  font-family: PingFangSC-Semibold, PingFang SC;
                  font-weight: 600;
                  color: #393D60;
                }
              }
              .li-ri {
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #393D60;
                margin-right: 72px;
                span {
                  color: #607FFF;
                }
              }
            }
            .txt {
              line-height: 20px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #393D60;
              font-weight: 600;
              margin-bottom: 5px;
              span {
                color: #607FFF;
              }
            }
            .report-li {
              ::v-deep {
                .el-timeline {
                  .el-timeline-item {
                    padding-bottom: 1px !important;
                  }
                }
                .el-timeline-item__node--normal {
                  width: 7px;
                  height: 7px;
                  left: 2px;
                }
                .eltimelinefocus {
                  .el-timeline-item__node {
                    border: 3px solid hsla(0, 0%, 90%, 0.9);
                    background-clip: content-box;
                    background: rgba(78, 151, 255, 1);
                  }
                  .el-timeline-item__node--normal {
                    left: -2px;
                    width: 14px;
                    height: 14px;
                  }
                }
                .timeline-name {
                  font-size: 13px;
                  color: rgba(0, 0, 0, 0.7);
                }
                .timeline-content {
                  color: rgba(0, 0, 0, 0.5);
                  font-size: 12px;
                  margin-top: 4px;
                  .timeline-content__idea {
                    color: rgba(0, 0, 0, 0.7);
                  }
                }
              }

            }
            .report-li-content {

              .content-tops {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-right: 72px;
                width: 100%;
                .suc {
                  padding: 0 24px;
                  // height: 30px;
                  background: #E2F9E8;
                  border-radius: 15px;
                  font-size: 14px;
                  font-family: PingFangSC-Semibold, PingFang SC;
                  font-weight: 600;
                  color: #00AB29;
                  line-height: 30px;
                  margin-right: 10px;
                }
                .fail {
                  padding: 0 20px;
                  // height: 30px;
                  background: #FFDEDE;
                  border-radius: 15px;
                  font-size: 14px;
                  font-family: PingFangSC-Semibold, PingFang SC;
                  font-weight: 600;
                  color: #E02020;
                  line-height: 30px;
                }
              }
              .report-params {
                .params-li {
                  height: 20px;
                  font-size: 14px;
                  font-family: PingFangSC-Semibold, PingFang SC;
                  font-weight: 600;
                  color: #607FFF;
                  margin-bottom: 10px;
                  cursor: pointer;
                  span {
                    margin-right: 5px;
                    display: inline-block;
                  }
                }
                .params-content {
                  width: 284px;
                  // background: #FFFFFF;
                  margin-bottom: 20px;
                  .params-content-tit {
                    width: 284px;
                    height: 30px;
                    background: #E5E7EA;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #393D60;
                    line-height: 30px;
                    padding-left: 17px;
                  }
                  .params-content-body {
                    padding: 10px 17px;

                    .params-content-li {
                      height: 20px;
                      font-size: 14px;
                      font-family: PingFangSC-Regular, PingFang SC;
                      font-weight: 400;
                      color: #393D60;
                      line-height: 20px;
                      margin-bottom: 10px;
                    }
                  }
                  .params-card {
                    border: 1px solid #ECECF7;
                    margin-bottom: 20px;
                    width: 290px;
                    .header {
                      width: 100%;
                      height: 48px;
                      padding: 0 20px;
                      background: #ECECF7;
                    }
                    .body {
                      width: 100%;
                      background: #FFFFFF;
                      .bot-ti{
                        // height: 36px;
                        line-height:36px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #393D60;
                        display: inline-block;
                        margin-bottom: 16px;
                        margin-left: 20px;
                        word-break: break-all;
                        .imgs{
                          width: 100px;
                          height: 100px;
                          display: inline-block;
                          margin-right: 10px;
                          img{
                          width: 100px;
                          height: 100px;
                          display: inline-block;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .li-right-arrow {
            position: absolute;
            top: 24px;
            left: -12px;
            border-color: transparent;
            border-style: solid;
            border-right-color: #F6F7F9;
            border-left-width: 0;
            width: 0;
            height: 0;
            border-width: 6px;

          }

        }
      }
    }

  }
</style>
