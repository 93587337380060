
export const reductionColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'reason',
    label: '减产原因',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'batchNumber',
    label: '减产批次码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'quantity',
    label: '减产数量',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'creator',
    label: '操作人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'createTime',
    label: '操作时间',
    sortable: false,
    hiddenSearch: true
  }
]
export const divisionColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'reason',
    label: '拆单原因',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'newBatchNumber',
    label: '拆单新批次码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'newProducePlanNumber',
    label: '拆单新计划号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'newProduceWorkOrderNumber',
    label: '拆单新工单号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'newProduceWorkOrderNumber'
  },
  {
    prop: 'quantity',
    label: '拆单数量',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'processFlowName',
    label: '工艺名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'creator',
    label: '操作人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'createTime',
    label: '操作时间',
    sortable: false,
    hiddenSearch: true
  }
]

export const repairColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'repairType',
    label: '返修类型',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'procedureName',
    label: '工序',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'repairWorkQuantity',
    label: '返修数量',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'repairWorkOrderNumber',
    label: '返修工单号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'repairWorkOrderNumber'
  },
  {
    prop: 'repairPlanNumber',
    label: '返修计划号',
    hiddenSearch: true,
    sortable: false,
    slotName: 'repairPlanNumber'
  }
]
export const reportWasteColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'reportWasteQuantity',
    label: '报废数量',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'wasteReason',
    label: '报废原因',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'operator',
    label: '操作人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'operateTime',
    label: '操作时间',
    sortable: false,
    hiddenSearch: true
  }
]
