var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "role-wrap" }, [
    _c(
      "div",
      { staticClass: "orders" },
      [
        _c(
          "div",
          { staticClass: "top" },
          [
            _c("SearchForm", {
              ref: "searchForm",
              staticStyle: { padding: "0" },
              attrs: {
                "form-list-extend": _vm.searchForm,
                "form-data": _vm.searchFormData
              },
              on: {
                "update:formData": function($event) {
                  _vm.searchFormData = $event
                },
                "update:form-data": function($event) {
                  _vm.searchFormData = $event
                },
                search: _vm.reSearch
              }
            })
          ],
          1
        ),
        _c("FunctionHeader", {
          ref: "functionHeader",
          staticClass: "func-header",
          staticStyle: { padding: "10px 0 10px 0" },
          attrs: {
            "search-title": "请输入物料编号/名称/规格",
            tags: _vm.functionHeader.tags
          },
          on: {
            search: function($event) {
              return _vm.$refs.mTable.setTableData(true)
            }
          },
          model: {
            value: _vm.functionHeader.searchVal,
            callback: function($$v) {
              _vm.$set(_vm.functionHeader, "searchVal", $$v)
            },
            expression: "functionHeader.searchVal"
          }
        }),
        _c("MTable", {
          ref: "mTable",
          staticClass: "table-left",
          attrs: {
            columns: _vm.columns,
            height: _vm.height,
            "set-data-method": _vm.getTableData,
            "row-class-name": _vm.tableRowClassName
          },
          on: {
            "selection-change": function(data) {
              return (_vm.selectData = data)
            }
          },
          scopedSlots: _vm._u([
            {
              key: "index",
              fn: function(ref) {
                var $index = ref.$index
                return _c("div", {}, [_vm._v(_vm._s($index + 1))])
              }
            },
            {
              key: "isKeyMaterials",
              fn: function(ref) {
                var row = ref.row
                return _c("div", {}, [
                  _vm._v(_vm._s(_vm.options[row.isKeyMaterials] || "-"))
                ])
              }
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }