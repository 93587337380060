var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderAdd" },
    [
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
        _c(
          "div",
          { staticClass: "card-bot", staticStyle: { padding: "15px" } },
          [
            _c(
              "div",
              [
                _c(
                  "el-row",
                  {
                    staticClass: "view-list",
                    staticStyle: {
                      padding: "10px 15px",
                      "flex-flow": "row wrap"
                    },
                    attrs: { type: "flex", justify: "start", gutter: 24 }
                  },
                  _vm._l(_vm.columns, function(item, index) {
                    return _c(
                      "el-col",
                      {
                        key: item.prop,
                        style: { marginBottom: index !== 4 ? "20px" : 0 },
                        attrs: { span: 6 }
                      },
                      [
                        _c("span", { staticClass: "bot-tis" }, [
                          _vm._v(_vm._s(item.label) + "：")
                        ]),
                        _c("span", { staticClass: "bot-tis" }, [
                          _vm._v(_vm._s(_vm.getValue(item)))
                        ])
                      ]
                    )
                  }),
                  1
                )
              ],
              1
            )
          ]
        )
      ]),
      _c(
        "div",
        { staticStyle: { display: "flex" } },
        [
          _c(
            "div",
            { staticStyle: { margin: "10px 0", "font-size": "14px" } },
            [_vm._v(" 选择项目计划方案模板： ")]
          ),
          _c(
            "el-select",
            {
              attrs: {
                placeholder: "请选择计划方案模板",
                filterable: "",
                remote: "",
                "reserve-keyword": "",
                "remote-method": _vm.remoteMethod,
                clearable: ""
              },
              on: { change: _vm.changeProjectPlan },
              model: {
                value: _vm.projectPlanId,
                callback: function($$v) {
                  _vm.projectPlanId = $$v
                },
                expression: "projectPlanId"
              }
            },
            _vm._l(_vm.projectPlanList, function(item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id }
              })
            }),
            1
          )
        ],
        1
      ),
      this.type !== "view"
        ? _c(
            "div",
            { staticStyle: { margin: "10px 0", "font-size": "14px" } },
            [_vm._v(" 节点信息： ")]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "detail-card", staticStyle: { padding: "10px" } },
        [
          _c(
            "div",
            { staticClass: "bottom" },
            [
              _vm.type != "modify"
                ? _c("FunctionHeader", {
                    ref: "functionHeader",
                    attrs: {
                      "search-title": "请输入计划名称",
                      tags: _vm.functionHeader.tags,
                      hiddenSearch: true
                    },
                    on: {
                      search: function($event) {
                        return _vm.searchTable()
                      }
                    },
                    model: {
                      value: _vm.functionHeader.searchVal,
                      callback: function($$v) {
                        _vm.$set(_vm.functionHeader, "searchVal", $$v)
                      },
                      expression: "functionHeader.searchVal"
                    }
                  })
                : _vm._e(),
              _c("MTable", {
                ref: "mTable",
                attrs: {
                  "default-expand-all": "",
                  "is-tree-data": true,
                  height: _vm.height,
                  columns: _vm.scheduleColumn,
                  data: _vm.materialsList,
                  "show-page": false,
                  showAppend: _vm.type === "view" ? false : true,
                  onlyKey: "treeId",
                  "tree-props": {
                    children: "children",
                    hasChildren: "hasChildren"
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "index",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [
                        _vm._v(_vm._s(!row.parentId ? row.sort + 1 : ""))
                      ])
                    }
                  },
                  {
                    key: "nodeAttribute",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [
                        row.nodeAttribute != null
                          ? _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.planNodeAttribute[row.nodeAttribute].name
                                )
                              )
                            ])
                          : _vm._e()
                      ])
                    }
                  },
                  {
                    key: "planName",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "span",
                        { staticStyle: { display: "inline-block" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center"
                              }
                            },
                            [
                              _c("div", [_vm._v(_vm._s(row.planName))]),
                              _vm.type !== "view" && !row.isTaskNode
                                ? _c("img", {
                                    staticClass: "num-img",
                                    attrs: {
                                      src: require("@/assets/plans/add.png")
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.addTaskNode(row)
                                      }
                                    }
                                  })
                                : _vm._e()
                            ]
                          )
                        ]
                      )
                    }
                  },
                  {
                    key: "isTaskNode",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [
                        _c("span", [
                          _vm._v(_vm._s(row.isTaskNode ? "是" : "否"))
                        ])
                      ])
                    }
                  },
                  {
                    key: "startTimeAttribute",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [
                        row.startTimeAttribute != null
                          ? _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.startFinishTimeAttribute[
                                    row.startTimeAttribute
                                  ].name
                                )
                              )
                            ])
                          : _vm._e()
                      ])
                    }
                  },
                  {
                    key: "finishTimeAttribute",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [
                        row.finishTimeAttribute != null
                          ? _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.startFinishTimeAttribute[
                                    row.finishTimeAttribute
                                  ].name
                                )
                              )
                            ])
                          : _vm._e()
                      ])
                    }
                  },
                  {
                    key: "startTime",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              row.startTimeAttribute != 2
                                ? row.startTime
                                  ? row.startTime
                                  : "-"
                                : "-"
                            )
                          )
                        ])
                      ])
                    }
                  },
                  {
                    key: "finishTime",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              row.finishTimeAttribute != 2
                                ? row.finishTime
                                  ? row.finishTime
                                  : "-"
                                : "-"
                            )
                          )
                        ])
                      ])
                    }
                  },
                  {
                    key: "action",
                    fn: function(ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return _c(
                        "div",
                        {},
                        [
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.modify(row, $index)
                                  }
                                }
                              },
                              [_vm._v("修改")]
                            ),
                            _c("el-divider", {
                              attrs: { direction: "vertical" }
                            }),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  disabled: row.children.length ? true : false
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.del(row, $index)
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          ]
                        ],
                        2
                      )
                    }
                  }
                ])
              })
            ],
            1
          )
        ]
      ),
      _c("div", { staticClass: "order-footer" }, [
        _c("div"),
        _c(
          "div",
          { staticClass: "footer-ri" },
          [
            _c("el-button", { on: { click: _vm.returnList } }, [
              _vm._v(_vm._s(this.type !== "view" ? "取 消" : "返 回"))
            ]),
            this.type !== "view"
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", loading: _vm.loading },
                    on: { click: _vm.save }
                  },
                  [_vm._v("确 定")]
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _c("div", { staticClass: "lines" }),
      _c("task-node-dialog", {
        attrs: {
          visible: _vm.showModify,
          formModifyData: _vm.formModifyData,
          nodeIndex: _vm.nodeIndex,
          tableData: _vm.materialsList
        },
        on: {
          "update:visible": function($event) {
            _vm.showModify = $event
          },
          submitForm: _vm.submitModify
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }