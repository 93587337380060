<template>
  <div class="orders">
    <div class="top">
      <SearchForm
        ref="searchForm"
        :form-list-extend="searchFormList"
        :form-data.sync="searchFormData"
        style="padding: 0"
        @search="()=>$refs.mTable.setTableData(true)"
      />
    </div>
    <FunctionHeader
      ref="functionHeader"
      v-model="functionHeader.searchVal"
      search-title="请输入采购订单编号"
      :tags="!selectIds.length ? functionHeader.tags :[]"
      style="padding: 10px 0 10px 0;"
      class="func-header"
      @search="$refs.mTable.setTableData(true)"
    >
      <div slot="all" v-show="selectIds.length">
        <el-button v-if="permission('Export')" size="small" type="primary"  @click="allMore('onExport')">批量导出</el-button>
        <el-button v-if="permission('Distribute')" type="primary" size="small" @click="allMore('onDistribute')">批量下发</el-button>
        <el-button v-if="permission('End')" type="primary" size="small" @click="allMore('onEnd')">批量完结</el-button>
        <el-button v-if="permission('Cancel')" type="primary" size="small" @click="allMore('onCancel')">批量取消</el-button>
        <el-button v-if="permission('Del')" type="primary" size="small" @click="allMore('onDel')">批量删除</el-button>
        <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
          已选
          <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
          / {{ total }}项
        </span>
          <el-button type="text" size="small" @click="resetSelectData">清空</el-button>
      </div>
    </FunctionHeader>
    <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData" @selection-change="selectionChange">
      <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
      <div slot="code" slot-scope="{ row }">
        <NewPageOpen :path="{ name: 'purchaseOrderDetail', query: {id: row.id}}" :text="row.code" />
      </div>
      <div slot="receivedStatus" slot-scope="{ row }">
        {{ receivedStatusMap[row.receivedStatus] }}
      </div>
      <div slot="orderStatus" slot-scope="{ row }" :style="{color:orderStatusColorMap[row.orderStatus]}">
        {{ orderStatusMap[row.orderStatus] }}
      </div>
      <div slot="action" slot-scope="{ row }">
        <el-tooltip :content="`当前采购订单${orderStatusMap[row.orderStatus]}，不可下发`" :disabled="['Waiting'].includes(row.orderStatus)" placement="top-end">
          <span class="mr-10">
            <el-button v-if="permission('Distribute')" type="text" :disabled="!['Waiting'].includes(row.orderStatus)" @click="onHandle([row.id],'commit')">下发</el-button>
          </span>
        </el-tooltip>
        <el-tooltip :content="`当前采购订单${orderStatusMap[row.orderStatus]}，不可完结`" :disabled="['Dispatched'].includes(row.orderStatus)" placement="top-end">
          <span class="mr-10">
            <el-button v-if="permission('End')" type="text" :disabled="!['Dispatched'].includes(row.orderStatus)" @click="onHandle([row.id],'finish')">完结</el-button>
          </span>
        </el-tooltip>
        <el-tooltip :content="`当前采购订单${orderStatusMap[row.orderStatus]}，不可取消`" :disabled="['Waiting','Dispatched'].includes(row.orderStatus)" placement="top-end">
          <span class="mr-10">
            <el-button v-if="permission('Cancel')" type="text" :disabled="!['Waiting','Dispatched'].includes(row.orderStatus)" @click="onHandle([row.id],'cancel')">取消</el-button>
          </span>
        </el-tooltip>
        <el-tooltip :content="`当前采购订单${orderStatusMap[row.orderStatus]}，不可编辑`" :disabled="['Waiting','Dispatched'].includes(row.orderStatus)" placement="top-end">
          <span class="mr-10">
            <el-button v-if="permission('Edit')" type="text" :disabled="!['Waiting','Dispatched'].includes(row.orderStatus)" @click="jump('edit',row.id)">编辑</el-button>
          </span>
        </el-tooltip>
        <el-tooltip :content="`当前采购订单${orderStatusMap[row.orderStatus]}，不可删除`" :disabled="['Waiting','Cancelled'].includes(row.orderStatus)" placement="top-end">
          <span class="mr-10">
            <el-button v-if="permission('Del')" :disabled="!['Waiting','Cancelled'].includes(row.orderStatus)" type="text" @click="onHandle([row.id],'del')">删除</el-button>
          </span>
        </el-tooltip>
      </div>
    </MTable>
  </div>
</template>
<script>
import api from '@/api/production/purchaseManage'
import FunctionHeader from '@/components/FunctionHeader'
import SearchForm from '@/components/SearchForm/SearchForm'
import { columnsAll, searchFormList, orderStatusMap, orderStatus, receivedStatus, receivedStatusMap, orderStatusColorMap } from './data'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
import dayjs from 'dayjs'

export default {
  name: 'PurchaseOrderList',
  components: { NewPageOpen, FunctionHeader, SearchForm },
  data() {
    return {
      orderStatusColorMap,
      orderStatusMap,
      orderStatus,
      receivedStatus,
      receivedStatusMap,
      searchFormList,
      searchFormData: {},
      functionHeader: {
        searchVal: '',
        exportName: 'exportCalculateListData',
        exportParams: {},
        tags: [
          {
            hidden: !this.permission('Add', true),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF', marginRight: '10px' },
            innerText: '新增',
            on: {
              click: () => this.jump('add')
            }
          }
        ]
      },
      columns: columnsAll,
      searchData: {},
      selectIds: [],
      supplierCandidatesParams: {
        page: 1,
        size: 10,
        search: ''
      },
      total:0,
      selectionDataList:[]
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 280 - 15
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.selectionDataList.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    }
  },

  created() {
    this.initPage()
  },

  methods: {
    selectionChange(data) {
      this.selectIds = data.map(item => item.id)
      this.selectionDataList = data
    },
    resetSelectData() {
      this.selectionDataList.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.selectionDataList = this.selectionDataList.filter((item) => item.selectable)
    },
    initPage() {
      // 绑定供应商下拉触底分页加载指令
      this.searchFormList[6].directives[0].value = this.supplierCandidatesLoadMore
      // 绑定供应商远程搜索
      this.searchFormList[6].props.remoteMethod = (val) => {
        this.searchFormList[6].children[0].options.length = 0
        this.supplierCandidatesParams = {
          page: 1,
          size: 10,
          search: val
        }
        this.getSupplierCandidates(this.supplierCandidatesParams)
      }
      // 获取创建人列表
      this.getUserOptions('createBy')
      // // 获取更新人列表
      this.getUserOptions('updateBy')
      // 获取供应商选项
      this.getSupplierCandidates(this.supplierCandidatesParams)
    },
    // 成品物料下拉触底分页加载
    supplierCandidatesLoadMore() {
      if (this.searchFormList[6].children[0].options.length === this.supplierCandidatesTotal) return
      this.supplierCandidatesParams.page++
      this.getSupplierCandidates(this.supplierCandidatesParams)
    },

    // 获取供应商选项
    async getSupplierCandidates(params) {
      const res = await api.supplierCandidates(params)
      if (res) {
        this.supplierCandidatesTotal = res.total
        this.searchFormList[6].children[0].options = res.records
      }
    },

    // 获取创建人列表||更新人
    async getUserOptions(userType) {
      const res = await api.getUsers({ userType })
      if (res) {
        userType === 'createBy' ? this.searchFormList[2].children[0].options = res : this.searchFormList[4].children[0].options = res
      }
    },

    // 新增
    jump(type, id) {
      this.$router.push({ path: 'purchase-order-add', query: { type, id }})
    },

    allMore(command) {
      const functions = {
        onEnd: () => this.onHandle(this.selectIds, 'finish'),
        onDel: () => this.onHandle(this.selectIds, 'del'),
        onCancel: () => this.onHandle(this.selectIds, 'cancel'),
        onDistribute: () => this.onHandle(this.selectIds, 'commit'),
        onExport: () => this.onExport(this.selectIds)
      }
      functions[command]()
    },

    async onExport(ids) {
      const res = await api.export({ ids: ids.join(',') })
      if (res && res.data.byteLength) {
        const urlP = window.URL.createObjectURL(
          new Blob([res.data], {
            type: 'application/octet-stream;charset=ISO8859-1'
          })
        )
        const fileName = `${dayjs().format('YYYYMMDDHHmmss')}.xls`
        this.downloadFile(urlP, fileName)
      } else {
        this.$message.error('导出失败，内容为空')
      }
    },

    downloadFile(urlP, name) {
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = urlP
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },

    // 操作
    async onHandle(data, type) {
      const map = {
        finish: { name: '结束', api: api.finish },
        del: { name: '删除', api: api.delete },
        cancel: { name: '取消', api: api.cancel },
        commit: { name: '下发', api: api.commit }
      }
      this.$confirm(`您确定要${map[type].name}吗?`, `${map[type].name}订单`, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await map[type].api({ ids: data })
        if (res) {
          this.$message.success('操作成功')
          this.$refs.mTable.tableRefs().clearSelection()
          this.$refs.mTable.setTableData()
        }
      })
    },

    // 列表
    async getTableData(condition, callback) {
      const { page } = condition
      const { receivedStatus, createDateRange, updateDateRange, orderStatus, createBy, updateBy, supplierId } = this.searchFormData
      console.log('this.searchFormData==', createDateRange, updateDateRange)
      const params = {
        search: this.functionHeader.searchVal,
        receivedStatus,
        createStartTime: createDateRange ? createDateRange[0] : '',
        createEndTime: createDateRange ? createDateRange[1] : '',
        updateStartTime: updateDateRange ? updateDateRange[0] : '',
        updateEndTime: updateDateRange ? updateDateRange[1] : '',
        orderStatus: orderStatus?.join(','),
        createBy: createBy?.join(','),
        updateBy: updateBy?.join(','),
        supplierId: supplierId?.join(',')
      }
      // 导出参数
      this.functionHeader.exportParams = params
      const res = await api.getSalesOrderList({
        page: page.currentPage,
        size: page.pageSize,
        ...params
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
        this.total = res.total
      }
      callback()
    }
  }
}
</script>

<style scoped lang="scss">
.mr-10 {
  margin-right: 6px;
}
.orders {
  padding: 10px;
  .faultStatuss {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    height: 22px;
    line-height: 22px;
    border-radius: 4px;
    padding: 0 5px;
    text-align: center;
    display: inline-block;
  }
  .li-btn {
    color: #607fff;
    font-weight: 500;
  }
  .color-btns {
    color: #c4c4c4;
  }
  .searchs {
    padding-left: 0;
    padding-top: 0;
  }
  .input-select {
    width: 100px;
    display: block;
  }
  .input-with-select {
    width: 100%;
  }
  .all-operator {
    height: 32px;
    margin-left: 10px;
    ::v-deep {
      .el-button--primary,
      .el-button-group .el-button {
        height: 32px;
      }
    }
  }
  .func-header {
    ::v-deep .el-input--suffix .el-input__inner {
      height: 32px;
    }
  }
}
::v-deep .customer-content div {
  width: 100%;
}
.tip-tit {
  height: 18px;
  line-height: 18px;
}
div.tooltip {
  display: inline-block;
  padding: 3px 5px;
  background: #eceef2;
  position: relative;
  border-radius: 3px;
  font-size: 12px;
  color: #393d60;
  margin-left: 10px;
}

div.tooltip:before,
div.tooltip:after {
  content: " ";
  height: 0;
  position: absolute;
  width: 0;
  border: 3px solid transparent;
}

div.tooltip:before {
  border-right-color: #eceef2;
  position: absolute;
  left: -6px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

div.tooltip:after {
  border-right-color: #eceef2;
  position: absolute;
  left: -6px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.params-card {
  border: 1px solid #ececf7;
  margin-bottom: 20px;
  .header {
    width: 100%;
    height: 48px;
    padding: 0 20px;
    background: #ececf7;
  }
  .body {
    width: 100%;
    .bot-ti {
      // height: 36px;
      line-height: 36px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;
      display: inline-block;
      margin-bottom: 16px;
      margin-left: 20px;
      word-break: break-all;
      .imgs {
        width: 100px;
        height: 100px;
        display: inline-block;
        margin-right: 10px;
        img {
          width: 100px;
          height: 100px;
          display: inline-block;
        }
      }
    }
  }
}
</style>
