var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "MDialog",
        {
          attrs: { title: "创建计划", width: "80%" },
          model: {
            value: _vm.modalShow,
            callback: function($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow"
          }
        },
        [
          _c(
            "div",
            { staticClass: "batchImport" },
            [
              _c(
                "el-steps",
                {
                  attrs: {
                    active: _vm.stepActive,
                    "finish-status": "success",
                    "process-status": "finish"
                  }
                },
                [
                  _c("el-step", {
                    attrs: { title: "确定工艺路线", description: "" }
                  }),
                  _c("el-step", {
                    attrs: { title: "生成计划", description: "" }
                  })
                ],
                1
              ),
              _vm.stepActive === 0
                ? _c("div", [
                    _vm.showProcess === 0
                      ? _c("div", { staticClass: "repair-detail" }, [
                          _c(
                            "div",
                            { staticClass: "repair-num" },
                            [
                              _c("div", { staticClass: "num-label" }, [
                                _vm._v("生产数量：")
                              ]),
                              _c("el-input-number", {
                                staticClass: "all-ipt",
                                attrs: {
                                  min: 1,
                                  max: _vm.baseFormData.scrapCount,
                                  "controls-position": "right",
                                  step: 1
                                },
                                model: {
                                  value: _vm.formData.quantity,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "quantity", $$v)
                                  },
                                  expression: "formData.quantity"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "repair-num" },
                            [
                              _c("div", { staticClass: "num-label" }, [
                                _vm._v("请选择生产工艺路线：")
                              ]),
                              _c(
                                "el-input",
                                {
                                  staticClass: "num-select",
                                  model: {
                                    value: _vm.formData.processFlowName,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "processFlowName",
                                        $$v
                                      )
                                    },
                                    expression: "formData.processFlowName"
                                  }
                                },
                                [
                                  _c(
                                    "template",
                                    { slot: "append" },
                                    [
                                      _c("el-button", {
                                        attrs: { icon: "el-icon-more" },
                                        on: { click: _vm.selectProcessFlows }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c("div", { staticClass: "repair-num" }, [
                            _c("div", { staticClass: "num-label" }),
                            _c("img", {
                              staticClass: "num-img",
                              attrs: { src: require("@/assets/plans/add.png") },
                              on: { click: _vm.addProcess }
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "num-tits",
                                on: { click: _vm.addProcess }
                              },
                              [_vm._v("新增生产工艺路线")]
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "repair-num" },
                            [
                              _c("div", { staticClass: "num-label" }),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.selectOld }
                                },
                                [_vm._v("从原工艺中选择")]
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm.showProcess === 1
                      ? _c(
                          "div",
                          { staticClass: "repair-detail" },
                          [
                            _c("div", { staticClass: "repair-tit" }, [
                              _vm._v("确认生产工艺路线图")
                            ]),
                            _c("process-flow", {
                              ref: "processFlow",
                              attrs: {
                                "area-id-list": _vm.areaIdList,
                                "read-only": _vm.saveButton,
                                "process-flow-details": _vm.processFlowVersion,
                                "produce-work-order-id": _vm.produceWorkOrderId
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm.stepActive === 1
                ? _c("div", [
                    _c("div", { staticClass: "repair-detail" }, [
                      _c("div", { staticClass: "repair-num" }, [
                        _c("div", { staticClass: "num-label num-width" }, [
                          _vm._v("生产新批次码：")
                        ]),
                        _c("div", { staticClass: "num-dec" }, [
                          _vm._v(_vm._s(_vm.formData.batchNumber))
                        ])
                      ]),
                      _c("div", { staticClass: "repair-num" }, [
                        _c("div", { staticClass: "num-label num-width" }, [
                          _vm._v("生产数量：")
                        ]),
                        _c("div", { staticClass: "num-dec colorNum" }, [
                          _vm._v(_vm._s(_vm.formData.quantity))
                        ])
                      ]),
                      _c(
                        "div",
                        { staticClass: "repair-num" },
                        [
                          _c("div", { staticClass: "num-label num-width" }, [
                            _c("span", { staticClass: "icon-color" }, [
                              _vm._v("*")
                            ]),
                            _vm._v("产线：")
                          ]),
                          _c(
                            "el-select",
                            {
                              staticClass: "num-select",
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.formData.areaId,
                                callback: function($$v) {
                                  _vm.$set(_vm.formData, "areaId", $$v)
                                },
                                expression: "formData.areaId"
                              }
                            },
                            _vm._l(_vm.areaIds, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "repair-num" },
                        [
                          _c("div", { staticClass: "num-label num-width" }, [
                            _c("span", { staticClass: "icon-color" }, [
                              _vm._v("*")
                            ]),
                            _vm._v("交期：")
                          ]),
                          _c("el-date-picker", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              type: "date",
                              placeholder: "请选择",
                              "value-format": "yyyy-MM-dd",
                              format: "yyyy-MM-dd"
                            },
                            model: {
                              value: _vm.formData.deliveryTime,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "deliveryTime", $$v)
                              },
                              expression: "formData.deliveryTime"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "repair-num" },
                        [
                          _c("div", { staticClass: "num-label num-width" }, [
                            _vm._v("计划开始时间：")
                          ]),
                          _c("el-date-picker", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              type: "datetime",
                              placeholder: "请选择开始时间",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              format: "yyyy-MM-dd HH:mm"
                            },
                            model: {
                              value: _vm.formData.planStartTime,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "planStartTime", $$v)
                              },
                              expression: "formData.planStartTime"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "repair-num" },
                        [
                          _c("div", { staticClass: "num-label num-width" }, [
                            _vm._v("计划结束时间：")
                          ]),
                          _c("el-date-picker", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              type: "datetime",
                              placeholder: "请选择结束时间",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              format: "yyyy-MM-dd HH:mm"
                            },
                            model: {
                              value: _vm.formData.planEndTime,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "planEndTime", $$v)
                              },
                              expression: "formData.planEndTime"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "mubai-dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _vm.stepActive !== 2
                ? _c("el-button", { on: { click: _vm.cancel } }, [
                    _vm._v(_vm._s(_vm.cancelText[_vm.stepActive]))
                  ])
                : _vm._e(),
              _vm.stepActive == 1
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "warning" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm(1)
                        }
                      }
                    },
                    [_vm._v("确认并下发")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.submitLoading,
                    disabled: _vm.uploading
                  },
                  on: {
                    click: function($event) {
                      return _vm.submitForm(0)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.confirmTextFun()))]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "从原工艺中选择", width: "80%" },
          on: { onOk: _vm.addProcessOldBtn },
          model: {
            value: _vm.repairShow,
            callback: function($$v) {
              _vm.repairShow = $$v
            },
            expression: "repairShow"
          }
        },
        [
          _c("div", { staticClass: "repair-detail" }, [
            _c(
              "div",
              { staticClass: "repair-num" },
              [
                _c("div", { staticClass: "num-label num-width" }, [
                  _c("span", { staticClass: "icon-color" }, [_vm._v("*")]),
                  _vm._v("工艺路线编码：")
                ]),
                _c("el-input", {
                  staticClass: "all-ipt",
                  model: {
                    value: _vm.formDatas.code,
                    callback: function($$v) {
                      _vm.$set(_vm.formDatas, "code", $$v)
                    },
                    expression: "formDatas.code"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "repair-num" },
              [
                _c("div", { staticClass: "num-label num-width" }, [
                  _c("span", { staticClass: "icon-color" }, [_vm._v("*")]),
                  _vm._v("工艺路线名称：")
                ]),
                _c("el-input", {
                  staticClass: "all-ipt",
                  model: {
                    value: _vm.formDatas.name,
                    callback: function($$v) {
                      _vm.$set(_vm.formDatas, "name", $$v)
                    },
                    expression: "formDatas.name"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "repair-num" }, [
              _c("div", { staticClass: "num-label num-width" }, [
                _vm._v("工艺路线类型：")
              ]),
              _c(
                "div",
                { staticClass: "num-dec colorNum all-ipt" },
                [
                  _c("CURDSelect", {
                    attrs: {
                      "default-value": _vm.formDatas.processFlowClassId,
                      "api-name": "ProcessType"
                    },
                    on: { change: _vm.selectName }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "repair-num" },
              [
                _c("div", { staticClass: "num-label num-width" }, [
                  _c("span", { staticClass: "icon-color" }, [_vm._v("*")]),
                  _vm._v("产线：")
                ]),
                _c(
                  "el-select",
                  {
                    staticClass: "num-select",
                    attrs: { placeholder: "请选择", multiple: "" },
                    model: {
                      value: _vm.haveAreaIdList,
                      callback: function($$v) {
                        _vm.haveAreaIdList = $$v
                      },
                      expression: "haveAreaIdList"
                    }
                  },
                  _vm._l(_vm.allAreaIds, function(item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "repair-num" },
              [
                _c("div", { staticClass: "num-label num-width" }, [
                  _c("span", { staticClass: "icon-color" }, [_vm._v("*")]),
                  _vm._v("版本：")
                ]),
                _c(
                  "el-select",
                  {
                    staticClass: "num-select",
                    attrs: { placeholder: "请选择" },
                    model: {
                      value: _vm.versionId,
                      callback: function($$v) {
                        _vm.versionId = $$v
                      },
                      expression: "versionId"
                    }
                  },
                  _vm._l(_vm.baseData.versionList, function(item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.version, value: item.id },
                      nativeOn: {
                        click: function($event) {
                          return _vm.selectVersion(item)
                        }
                      }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "repair-num" },
              [
                _c("div", { staticClass: "num-label num-width" }, [
                  _c("span", { staticClass: "icon-color" }, [_vm._v("*")]),
                  _vm._v("开始工序：")
                ]),
                _c(
                  "el-select",
                  {
                    staticClass: "num-select",
                    attrs: { placeholder: "请选择" },
                    model: {
                      value: _vm.formDatas.firstProduce,
                      callback: function($$v) {
                        _vm.$set(_vm.formDatas, "firstProduce", $$v)
                      },
                      expression: "formDatas.firstProduce"
                    }
                  },
                  _vm._l(_vm.firstProcedureList, function(item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: {
                        label: item.procedureName,
                        value: item.procedureUuid
                      }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "repair-num" },
              [
                _c("div", { staticClass: "num-label num-width" }, [
                  _c("span", { staticClass: "icon-color" }, [_vm._v("*")]),
                  _vm._v("结束工序：")
                ]),
                _c(
                  "el-select",
                  {
                    staticClass: "num-select",
                    attrs: { placeholder: "请选择", disabled: _vm.isEnd },
                    model: {
                      value: _vm.formDatas.endProduce,
                      callback: function($$v) {
                        _vm.$set(_vm.formDatas, "endProduce", $$v)
                      },
                      expression: "formDatas.endProduce"
                    }
                  },
                  _vm._l(_vm.endProcedureList, function(item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: {
                        label: item.procedureName,
                        value: item.procedureUuid
                      }
                    })
                  }),
                  1
                )
              ],
              1
            )
          ])
        ]
      ),
      _c("SelectProcessFlow", {
        ref: "processFlow",
        attrs: {
          visible: _vm.processFlowVisible,
          "select-process-and-version-ids": _vm.processIdAndVersionId,
          "materials-id": _vm.baseFormData.materialsId,
          "proce-type": _vm.proceType
        },
        on: {
          "update:visible": function($event) {
            _vm.processFlowVisible = $event
          },
          submit: _vm.selectFlow
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }