<template>
  <div>
    <MDialog v-model="modalShow" title="创建计划" width="80%">
      <div class="batchImport">
        <el-steps :active="stepActive" finish-status="success" process-status="finish">
          <el-step title="确定工艺路线" description="" />
          <el-step title="生成计划" description="" />
        </el-steps>
        <!-- 选择返修批次 -->
        <div v-if="stepActive === 0">
          <div v-if="showProcess === 0" class="repair-detail">
            <div class="repair-num">
              <div class="num-label">生产数量：</div>
              <el-input-number v-model="formData.quantity" :min="1" :max="baseFormData.scrapCount" controls-position="right" :step="1" class="all-ipt" />
            </div>
            <div class="repair-num">
              <div class="num-label">请选择生产工艺路线：</div>
              <el-input v-model="formData.processFlowName" class="num-select">
                <template slot="append">
                  <el-button icon="el-icon-more" @click="selectProcessFlows" />
                </template>
              </el-input>
            </div>
            <div class="repair-num">
              <div class="num-label" />
              <img src="@/assets/plans/add.png" class="num-img" @click="addProcess">
              <div class="num-tits" @click="addProcess">新增生产工艺路线</div>
            </div>
            <div class="repair-num">
              <div class="num-label" />
              <el-button type="primary" @click="selectOld">从原工艺中选择</el-button>
            </div>
          </div>
          <div v-if="showProcess === 1" class="repair-detail">
            <div class="repair-tit">确认生产工艺路线图</div>
            <process-flow ref="processFlow" :area-id-list="areaIdList" :read-only="saveButton" :process-flow-details="processFlowVersion" :produce-work-order-id="produceWorkOrderId" />
          </div>
        </div>

        <!-- 确认返修 -->
        <div v-if="stepActive === 1">
          <div class="repair-detail">
            <div class="repair-num">
              <div class="num-label num-width">生产新批次码：</div>
              <div class="num-dec">{{ formData.batchNumber }}</div>
            </div>
            <div class="repair-num">
              <div class="num-label num-width">生产数量：</div>
              <div class="num-dec colorNum">{{ formData.quantity }}</div>
            </div>
            <div class="repair-num">
              <div class="num-label num-width"><span class="icon-color">*</span>产线：</div>
              <el-select v-model="formData.areaId" placeholder="请选择" class="num-select">
                <el-option
                  v-for="item in areaIds"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </div>
            <div class="repair-num">
              <div class="num-label num-width"><span class="icon-color">*</span>交期：</div>
              <el-date-picker
                v-model="formData.deliveryTime"
                type="date"
                placeholder="请选择"
                style="width: 250px;"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
              />
            </div>
            <div class="repair-num">
              <div class="num-label num-width">计划开始时间：</div>
              <el-date-picker
                v-model="formData.planStartTime"
                style="width: 250px"
                type="datetime"
                placeholder="请选择开始时间"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm"
              />
            </div>
            <div class="repair-num">
              <div class="num-label num-width">计划结束时间：</div>
              <el-date-picker
                v-model="formData.planEndTime"
                style="width: 250px"
                type="datetime"
                placeholder="请选择结束时间"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm"
              />
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="mubai-dialog-footer">
        <el-button v-if="stepActive !== 2" @click="cancel">{{ cancelText[stepActive] }}</el-button>
        <el-button v-if="stepActive == 1" type="warning" @click="submitForm(1)">确认并下发</el-button>
        <el-button type="primary" :loading="submitLoading" :disabled="uploading" @click="submitForm(0)">{{ confirmTextFun() }}</el-button>
      </div>
    </MDialog>
    <MDialog v-model="repairShow" title="从原工艺中选择" width="80%" @onOk="addProcessOldBtn">
      <div class="repair-detail">
        <div class="repair-num">
          <div class="num-label num-width"><span class="icon-color">*</span>工艺路线编码：</div>
          <el-input v-model="formDatas.code" class="all-ipt" />
        </div>
        <div class="repair-num">
          <div class="num-label num-width"><span class="icon-color">*</span>工艺路线名称：</div>
          <el-input v-model="formDatas.name" class="all-ipt" />
        </div>
        <div class="repair-num">
          <div class="num-label num-width">工艺路线类型：</div>
          <div class="num-dec colorNum all-ipt">
            <CURDSelect
              :default-value="formDatas.processFlowClassId"
              api-name="ProcessType"
              @change="selectName"
            />
          </div>
        </div>
        <div class="repair-num">
          <div class="num-label num-width"><span class="icon-color">*</span>产线：</div>
          <el-select v-model="haveAreaIdList" placeholder="请选择" class="num-select" multiple>
            <el-option
              v-for="item in allAreaIds"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </div>
        <div class="repair-num">
          <div class="num-label num-width"><span class="icon-color">*</span>版本：</div>
          <el-select v-model="versionId" placeholder="请选择" class="num-select">
            <el-option
              v-for="item in baseData.versionList"
              :key="item.id"
              :label="item.version"
              :value="item.id"
              @click.native="selectVersion(item)"
            />
          </el-select>
        </div>
        <div class="repair-num">
          <div class="num-label num-width"><span class="icon-color">*</span>开始工序：</div>
          <el-select v-model="formDatas.firstProduce" placeholder="请选择" class="num-select">
            <el-option
              v-for="item in firstProcedureList"
              :key="item.id"
              :label="item.procedureName"
              :value="item.procedureUuid"
            />
          </el-select>
        </div>
        <div class="repair-num">
          <div class="num-label num-width"><span class="icon-color">*</span>结束工序：</div>
          <el-select v-model="formDatas.endProduce" placeholder="请选择" class="num-select" :disabled="isEnd">
            <el-option
              v-for="item in endProcedureList"
              :key="item.id"
              :label="item.procedureName"
              :value="item.procedureUuid"
            />
          </el-select>
        </div>
      </div>
    </MDialog>
    <SelectProcessFlow
      ref="processFlow"
      :visible.sync="processFlowVisible"
      :select-process-and-version-ids="processIdAndVersionId"
      :materials-id="baseFormData.materialsId"
      :proce-type="proceType"
      @submit="selectFlow"
    />
  </div>
</template>
<script>
import { stationColums } from './columns'
import SelectProcessFlow from '@/components/SelectProcessFlow/SelectProcessFlow'
import api from '@/api/quality/report-waste-record'
import PrintCode from '@/components/PrintCode/printCode'
import PrintBatchNumber from '@/project-custom/reagold/print-code/printBatchNumber'
import PrintBatchNumberKiKi from '@/project-custom/kiki/print-code/printBatchNumber'
import ProcessFlow from '@/components/OldProcessFlow/ProcessFlow'
import processApi from '@/api/information/production/process'
import orderApi from '@/api/production/orders'
import CURDSelect from '@/components/CURDSelect/CURDSelect'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
export default {
  name: 'RepairModel',
  components: { CURDSelect, PrintBatchNumberKiKi, SelectProcessFlow, PrintCode, PrintBatchNumber, ProcessFlow },
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    repairRecordId: {
      type: Number,
      default: null
    },
    oldProcessFlowId: {
      type: Number,
      default: null
    },
    produceWorkOrderId: {
      type: Number,
      default: null
    },
    baseFormData: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      isEnd: true,
      repairShow: false,
      commonId: 'print',
      proceType: 0,
      stationColums,
      areaIdList: [],
      formatReturnData: {},
      saveButton: false,
      showProcess: 0,
      batchNumber: '',
      checked: false,
      processFlowVisible: false,
      batchNumbers: [],
      processIdAndVersionId: [],
      areaIds: [],
      formData: {
        quantity: 1,
        batchNumber: '',
        processFlowId: '',
        processFlowName: '',
        processFlowVersionId: '',
        areaId: undefined,
        planStartTime: '',
        planEndTime: '',
        deliveryTime: ''
      },
      formDatas: {
        code: '',
        name: '',
        type: 1,
        processFlowClassId: undefined,
        processFlowClassName: '',
        firstProduce: undefined,
        endProduce: undefined
      },
      processFlowVersion: {},
      currentVersionId: undefined,
      modalShow: false,
      stepActive: 0,
      total: 0,
      loading: false,
      submitLoading: false,
      uploading: false,
      excelContent: [],
      cancelText: {
        0: '取 消',
        1: '上一步',
        3: '关 闭'
      },
      confirmText: {
        0: '下一步',
        1: '下一步',
        2: '确认'
      },
      total: 0,
      allproduce: [],
      processFlowClassId: undefined,
      processFlowClassName: '',
      procedureList: [],
      firstProcedureList: [],
      endProcedureList: [],
      allAreaIds: [],
      haveAreaIdList: [],
      baseData: {},
      versionId: undefined,
      newVersionList: {}
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 289 - 48 - 12 - 220
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    }
  },
  watch: {
    visible(val) {
      this.modalShow = val
      if (val) {
        this.stepActive = 0
        Object.assign(this.formData,
          {
            quantity: 1,
            batchNumber: '',
            processFlowId: '',
            processFlowName: '',
            processFlowVersionId: '',
            areaId: undefined,
            planStartTime: '',
            planEndTime: '',
            deliveryTime: ''
          }
        )
        this.showProcess = 0
        this.loading = false
        this.batchNumber = ''
      }
    },
    modalShow(val) {
      if (!val) {
        this.$emit('update:visible', false)
      }
    },
    'formDatas.firstProduce'(val) {
      if (val) {
        const idx = this.procedureList.findIndex(item => item.procedureUuid === val)
        this.endProcedureList = this.procedureList.filter((item, index) => index >= idx)
        console.log('过滤结束工序列表', val, this.endProcedureList)
        this.isEnd = false
        this.formDatas.endProduce = undefined
      }
    }
    // 'formDatas.endProduce'(val) {
    //   if (val) {
    //     this.firstProcedureList = res.processFlowVersion.procedureList

    //   }
    // }
  },
  mounted() {
    this.modalShow = this.visible
    this.getAreaList()
  },
  methods: {
    selectVersion(row) {
      const list = this.baseData.versionList.filter(item => item.id === row.id)
      this.procedureList = list[0].procedureList
      this.firstProcedureList = this.procedureList
      this.endProcedureList = this.procedureList
      this.formDatas.firstProduce = undefined
      this.formDatas.endProduce = undefined
      this.newVersionList = list[0]
    },
    // 获取产线
    async getAreaList() {
      const search = []
      search.push({
        id: getUUid(),
        fieldName: 'type',
        fieldType: 'number',
        fieldValue: 2,
        operator: 'eq'
      })
      search.push({
        id: getUUid(),
        fieldName: 'isEnabled',
        fieldType: 'number',
        fieldValue: 1,
        operator: 'eq'
      })
      const res = await processApi.getAreaList({
        searchVal: Encrypt(JSON.stringify(search))
      })
      if (res) {
        this.allAreaIds = res
      }
    },
    selectName(item) {
      this.formDatas.processFlowClassId = item.id,
      this.formDatas.processFlowClassName = item.name
    },
    async selectOld() {
      await this.getProcessDetailById(this.oldProcessFlowId)
      this.formDatas = {
        code: '',
        name: '',
        type: 1,
        processFlowClassId: undefined,
        processFlowClassName: '',
        firstProduce: undefined,
        endProduce: undefined
      }
      this.repairShow = true
    },
    // 保存原工艺
    async addProcessOldBtn(done) {
      done()
      if (this.formDatas.code === '' || this.formDatas.name === '' ||
       this.haveAreaIdList.length === 0 || this.formDatas.firstProduce === undefined ||
       this.formDatas.endProduce === undefined) {
        this.$message.error('请填写所有必填项')
        return false
      }
      const firseIdx = this.procedureList.findIndex(item => item.procedureUuid === this.formDatas.firstProduce)
      const endIdx = this.procedureList.findIndex(item => item.procedureUuid === this.formDatas.endProduce)
      const procedureList = this.procedureList.filter((item, index) => index >= firseIdx && index <= endIdx)
      const datass = this.procedureList.filter(item => item.procedureUuid === this.formDatas.firstProduce)

      const details = JSON.parse(this.newVersionList.details)
      const rect = details.filter(item => item.shape === 'rect') // 工序块
      const edge = details.filter(item => item.shape === 'edge') // 线
      // produceDetails
      const rectList = []
      const newIdList = procedureList.map(item => item.procedureUuid)
      const list = rect.map(item => {
        if (newIdList.includes(item.id)) {
          rectList.push(item)
        }
      })
      const arr = []
      newIdList.pop()
      const linefirseIdx = edge.map(item => {
        if (rectList.length > 1 && newIdList.includes(item.source.cell)) {
          arr.push(item)
        }
      })
      const newDetail = [...rectList, ...arr]
      // details
      const procedureDetails = JSON.parse(this.newVersionList.procedureDetails)
      const first = procedureDetails.filter(item => item.uuid === this.formDatas.firstProduce)[0]
      const last = procedureDetails.filter(item => item.uuid === this.formDatas.endProduce)[0]
      const arrs = [first]
      await this.fixArrs(first, last, arrs, procedureDetails)
      const start = [{
        id: '0',
        uuid: getUUid(),
        children: [{
          id: arrs[0].id,
          uuid: arrs[0].uuid
        }]
      }]
      const end = [{
        id: '-1',
        uuid: getUUid(),
        children: []
      }]
      arrs[arrs.length - 1].children = [{
        id: '-1',
        uuid: end[0].uuid
      }]
      const newProcedureDetails = [...start, ...arrs, ...end]
      console.log('过滤的工序列表', procedureList)
      console.log('过滤的图形', newDetail)
      console.log('过滤的后端数据', newProcedureDetails)
      const data = {
        procedureList: procedureList,
        areaIdList: this.haveAreaIdList,
        processFlow: {
          ...this.formDatas,
          processFlowVersion: {
            version: '版本1',
            procedureDetails: JSON.stringify(newProcedureDetails),
            details: JSON.stringify(newDetail),
            isMain: 1
          }
        }
      }
      const res = await processApi.addProcess(data)
      if (res) {
        this.formData.processFlowId = res
        await this.getProcessDetailById(res)
        this.repairShow = false
        await this.getBatchNumber()
        this.stepActive = 1
      }
    },
    fixArrs(firstProduce, lastProduce, produces, procedureDetails) {
      if (firstProduce.uuid === lastProduce.uuid) return
      const childProduce = procedureDetails.filter(item => item.uuid === firstProduce.children[0].uuid)[0]
      produces.push(childProduce)
      this.fixArrs(childProduce, lastProduce, produces, procedureDetails)
    },
    // 保存工艺
    async addProcessBtn() {
      this.formatReturnData = await this.$refs.processFlow.formatReturnData()
      const datas = this.$refs.processFlow.processFlow
      console.log(this.formatReturnData.procedureList, this.$refs.processFlow.haveAreaIdList, this.$refs.processFlow.processFlow)
      const data = {
        procedureList: this.formatReturnData.procedureList,
        areaIdList: this.$refs.processFlow.haveAreaIdList,
        processFlow: {
          ...datas,
          processFlowVersion: {
            version: '版本1',
            procedureDetails: JSON.stringify(this.formatReturnData.formatFlow),
            details: JSON.stringify(this.formatReturnData.originFlow),
            isMain: 1
          }
        }
      }
      const res = await processApi.addProcess(data)
      if (res) {
        this.formData.processFlowId = res
        this.getProcessDetailById(res)
      }
      return false
    },
    // id获取工艺详情
    async getProcessDetailById(id) {
      const res = await processApi.getProcessDetail({ id })
      if (res) {
        this.processFlowVersion = res.processFlowVersion
        this.currentVersionId = res.processFlowVersion.id
        this.areaIdList = res.areaIdList
        this.areaIds = res.areaList
        this.formData.areaId = this.areaIds[0].id
        console.log('产线id', this.areaIds)
        this.procedureList = res.versionList[0].procedureList
        this.firstProcedureList = this.procedureList
        this.endProcedureList = this.procedureList
        this.haveAreaIdList = res.areaIdList
        this.baseData = res
        this.versionId = res.versionList[0].id
        this.newVersionList = res.versionList[0]
      }
    },
    async addProcess() {
      await this.getProcessDetailById(this.oldProcessFlowId)
      this.showProcess = 1
    },
    goPlan() {
      this.$router.push({ name: 'planList' })
    },

    // 工艺弹窗
    selectProcessFlows() {
      if (this.processFlowId) {
        this.processIdAndVersionId = [this.processFlowId, this.processFlowVersionId]
        this.$refs.processFlow.selectProcessChange(this.processFlowId)
      }
      this.processFlowVisible = true
    },
    selectFlow(data) {
      this.areaIds = data.processData.areaList
      this.formData.processFlowName = data.processData.name
      this.formData.processFlowId = data.processFlowVersion.processFlowId
      this.formData.processFlowVersionId = data.processFlowVersion.id
      this.processFlowVisible = false
    },
    // 获取批次码
    async getBatchNumber() {
      const res = await api.getBatchNumber(this.repairRecordId)
      if (res) {
        this.formData.batchNumber = res
      }
    },
    async submitForm(val) {
      if (this.formData.quantity === '' || this.formData.quantity === 0) {
        this.$message.error('请选择返修数量')
        return false
      }
      if (this.stepActive === 0) {
        console.log('ceshi=?', this.showProcess)
        if (this.showProcess === 0 && !this.formData.processFlowId) {
          this.$message.error('请选择或新建工艺路线')
          return false
        }
        // 新建
        if (this.showProcess === 1) {
          await this.addProcessBtn()
          if (!this.formData.processFlowId) {
            return false
          }
          await this.getBatchNumber()
          this.stepActive = 1
          return true
        }
        // // 原工艺
        // if (this.showProcess === 2) {
        //   await this.addProcessOldBtn()
        //   if (!this.formData.processFlowId) {
        //     return false
        //   }
        //   this.repairShow = false
        //   await this.getBatchNumber()
        //   this.stepActive = 1
        //   return true
        // }
        await this.getBatchNumber()
        this.stepActive = 1
        this.formData.areaId = this.areaIds[0].id
        return true
      }
      if (this.stepActive === 1) {
        await this.getRepair(val)
        return true
      }
    },
    // 确认返修批次
    async getRepair(val) {
      if (this.formData.areaId === '' || this.formData.areaId === undefined) {
        this.$message.error('请选择产线')
        return false
      }
      if (this.formData.deliveryTime === '') {
        this.$message.error('请选择交期')
        return false
      }
      if ((this.formData.planStartTime && this.formData.planEndTime) && (this.compareDate(this.formData.planStartTime, this.formData.planEndTime))) {
        this.$message.error('开始时间不能大于或等于结束时间')
        return false
      }
      const data = Object.assign(this.formData, {
        isRelease: val === 1 ? 1 : 0,
        recordId: this.repairRecordId
      })
      const res = await api.addScrapOrder(data)
      if (res) {
        this.$message.success('确认成功')
        this.$emit('loadWord')
      }
    },
    compareDate(d1, d2) {
      return ((new Date(d1.replace(/\-/g, '\/'))) >= (new Date(d2.replace(/\-/g, '\/'))))
    },
    cancel() {
      if (this.stepActive === 0) {
        this.modalShow = false
        return true
      }
      if (this.stepActive === 1) {
        this.stepActive = 0
        this.loading = false
        return true
      }
      if (this.stepActive === 2) {
        this.stepActive = 1
        this.loading = false
        return true
      }
    },
    confirmTextFun() {
      return this.confirmText[this.stepActive]
    }
  }
}
</script>

<style lang="scss" scoped>
.repair-detail {
  background: #F6F7F9;
  border-radius: 4px;
  padding: 20px;
  margin-top: 25px;
  .all-ipt {
    width: 250px;
  }
  .repair-tit {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #4B4F77;
    line-height: 20px;
    margin-bottom: 15px;
  }
  .img-sucs {
    width: 279px;
    height: 279px;
    margin: 25px auto 7px auto;
    display: block;
  }
  .suc-tits {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #393D60;
    line-height: 20px;
    margin-top: -120px;
    text-align: center;
    margin-bottom: 98px;
  }
  .li-all {
    display: flex;
    justify-content: center;
    height: 32px;
    align-items: center;
    .li-le {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #41456B;
      line-height: 20px;
      margin-right: 20px;
    }
    .li-ri {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #607FFF;
      line-height: 20px;
      cursor: pointer;
    }
  }
  .repair-num {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 15px;
    .num-label {
      width: 160px;
      height: 32px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #41456B;
      line-height: 32px;
      text-align: right;
    }
    .icon-color {
      color: #f00;
      margin-right: 5px;
    }
    .num-dec {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #41456B;
      line-height: 32px;
      text-align: left;
      .spas {
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9597AE;
        line-height: 17px;
        display: block;
      }
    }
    .colorNum {
      color: #f00;
    }
    .num-width {
      width: 120px;
    }
    .num-select {
      width: 250px;
      // height: 32px;
      background: #FFFFFF;
      border-radius: 4px;
    }
    .num-btn {
      width: 88px;
      height: 32px;
      margin-left: 20px;
    }
    .num-img {
      width: 18px;
      height: 18px;
      display: inline-block;
      cursor: pointer
    }
    .num-tits {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #607FFF;
      line-height: 20px;
      margin-left: 10px;
      cursor: pointer
    }
  }
  .repair-nums {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #41456B;
    line-height: 20px;
    margin: 20px 0 27px 0;
  }
  .mul-num {
    padding: 20px;
    border-radius: 4px;
    border: 1px dashed #9597AE;
    .tit {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #41456B;
      line-height: 20px;
      margin-bottom: 10px;
    }
    .mul-check {
      .check-li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 15px;
        ::v-deep {
          .el-checkbox__label {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #41456B;
            line-height: 20px;
          }
        }
        .li-right {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .ri-tit {
            height: 32px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #41456B;
            line-height: 32px;
            width: 150px;
            text-align: right;
          }
        }
      }
    }
  }
}
.batchImport {
  padding: 10px;

  .down-template {
    background: #F6F7F9;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    margin: 30px 0 20px 0;

    .down-le {
      width: 6px;
      background: #607FFF;
      border-radius: 4px 0px 0px 4px;
      margin-right: 20px;
      padding: 20px 0;
    }

    .down-ri {
      padding: 20px 0;

      .down-tit {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #545982;
        line-height: 20px;
        margin-bottom: 2px;
      }

      .down-des {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9FA1B7;
        line-height: 20px;
      }

      .down-btn {
        margin-top: 16px;
        display: flex;
        align-items: center;
        cursor: pointer;

        .icon {
          width: 21px;
          height: 15px;
          color: #607FFF;;
        }

        .download {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #607FFF;
          line-height: 20px;
          margin-left: 8px;
        }

        .icons {
          color: #607FFF;
          display: inline-flex;
          align-items: center;

          ::v-deep .el-icon-upload {
            font-size: 22px;
            color: #607FFF;
          }
        }

        span {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #0091FF;
          line-height: 20px;
          margin-left: 8px;
        }
      }
    }
  }

  .check-success {
    margin-top: 30px;

    .suc-top {
      background: rgba(0, 171, 41, 0.02);
      border-radius: 4px;
      border: 1px solid #00AB29;
      padding: 20px 0 20px 25px;

      .tit {
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #545982;
        line-height: 22px;
      }

      .des {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #A9ABBF;
        line-height: 22px;
      }
    }

    .imgs {
      width: 264px;
      height: 166px;
      margin: 43px auto 7px auto;
      display: block;
    }

    .check-tip {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #A9ABBF;
      line-height: 20px;
      text-align: center;
    }
  }

  .check-error {
    margin-top: 30px;

    .err-top {
      background: rgba(224, 32, 32, 0.02);
      border-radius: 4px;
      border: 1px solid #E02020;
      padding: 20px 0 20px 25px;
      margin-bottom: 25px;

      .tit {
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #545982;
        line-height: 22px;
      }

      .des {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #A9ABBF;
        line-height: 22px;
      }
    }

    .font-color {
      color: #f00;
    }

    .font-wight {
      font-weight: 700;
    }

  }

  .suc-tit {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #393D60;
    line-height: 20px;
    text-align: center;
    margin-top: -50px;
  }

  .check-tips {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #A9ABBF;
    line-height: 20px;
    text-align: center;
  }

  .img-suc {
    width: 279px;
    height: 279px;
    margin: 125px auto 7px auto;
    display: block;
  }
}

.el-dialog__footer {
  padding: 0 !important;
  height: 57px;
}

.mubai-dialog-footer {
  position: absolute;
  height: 57px;
  width: 100%;
  border-top: 1px solid #DDE3EB;
  padding: 10px 20px;
  bottom: 0;
  background: #FFFFFF;

  button {
    padding: 10px 40px !important;

    &:first-child {
      background: #F8F8FB;
      color: #8A8CA5;
      transition: all .5s;

      &:hover {
        border-color: #dadaf6;
        background: #f0f0f8;
      }
    }

    &:last-child {
      background: $primary-color;
      color: #ffffff;
      transition: all .5s;

      &:hover {
        background: #7691fc;
      }
    }
  }
}

.center-content {
  text-align: center;
}
::v-deep {
  .has-error {
   background: rgba(224, 32, 32, 0.04);
  }
  .el-input-number{
    height:32px;
    line-height:32px;
    width: 100%;
  }

  .el-input__inner{
    height:32px;
    line-height:32px;
  }
  .el-input-number.is-controls-right .el-input-number__increase, .el-input-number.is-controls-right .el-input-number__decrease{
    line-height:15px;
  }
  .el-input__icon {
    line-height:32px;
  }
}
</style>
