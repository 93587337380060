<template>
  <div class="product-trac">
    <div class="procedure-detail-header">
      <div class="left" @click="$router.push({ name: 'productBatchCodeTracingList' })">
        <img src="@/assets/information/procedure/左滑@2x.png">
        <span>产品批次码详情</span>
      </div>
    </div>
    <div class="detail-card">
      <div class="card-tit">基本信息</div>
      <div class="card-bot">
        <el-row class="view-list" :gutter="24">
          <el-col
            v-for="(item, index) in base"
            :key="item.prop"
            :span="6"
            :style="{marginBottom: index !== 4 ? '20px' : 0 }"
          >
            <span class="bot-tis">{{ item.label }}：</span>
            <span class="bot-tis">{{ getValue(item) }}</span>
          </el-col>
        </el-row>
      </div>
    </div>

    <div v-if="baseData && baseData.workOrderInfoList" class="detail-card">
      <div class="card-tit">生产记录追溯</div>
      <div class="card-bot">
        <product-all v-if="baseData || baseData === ''" :base-data="baseData" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  base
} from './columns'
import api from '@/api/quality/batch-code-tracing'
import FunctionHeader from '@/components/FunctionHeader/index'
import ProductAll from './components/product-all'

export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'productBatchCodeTracingDetail',
  components: { FunctionHeader, ProductAll },
  data() {
    return {
      base,
      barchList: [],
      workOrderInfoList: [],
      qualityRecordList: [],
      functionHeader: {
        searchVal: ''
      },
      searchTitle: '请输入生产工单号',
      look: false,
      statue: {
        title: '',
        showdetail: false,
        drawer: false,
        islook: false
      },
      lookdata: [],
      direction: 'rtl',
      showindex: 0,
      baseData: {}
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 600
    }
  },
  activated() {
    this.batchNumber = this.$route.query.batchNumber
    this.getTableData()
  },
  deactivated() {
    this.baseData = {}
  },
  methods: {
    getValue(row) {
      const result = this.barchList[row.prop]
      if (row.form && row.form.options) {
        const current = row.form.options.find(item => item.id === result)
        return current && current.name
      }
      return result
    },
    async getTableData() {
      const res = await api.getProduceBatchNumberFullTrace({
        batchNumber: this.batchNumber
      })
      if (res) {
        const { batchNumber } = res
        this.barchList = batchNumber
        this.baseData = res
        console.log('8989', this.baseData)
      }
    },
    // jumpDetail(row) {
    //   this.$router.push({
    //     path: "/plans/works/work-detail",
    //     query: { id: row.produceWorkOrderId },
    //   })
    // },
    showindexs(index) {
      this.showindex = index
    },
    handleClick(item) {
      this.look = true
      item.qualityInspectionDetails = item.qualityInspectionDetails
        ? JSON.parse(item.qualityInspectionDetails)
        : []
      item.details = item.qualityInspectionDetails
      item.name = ''
      this.lookdata = this._.cloneDeep(item)
    }
  }
}
</script>

<style scoped lang="scss">
.procedure-detail-header{
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #41456B;
  line-height: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  .left{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  img{
    width: 8px;
    height: 12px;
    display: block;
    margin-right: 10px;
  }
}
.detail-card {
  margin-bottom: 20px;
}
.parmas {
  width: 897px;
  text-align: center;
  line-height: 45px;
  background: rgb(247, 249, 252);
}
.btns {
  width: 100%;
  text-align: left;
  text-indent: 40px;
  border-top: 1px solid #dedeee;
  background-color: rgb(247, 249, 252);
  position: relative;
    cursor: pointer;
    .tit{
      width: 150px;
      line-height: 20px;
      display: inline-block;
    }
  &:last-child {
    border-bottom: 0;
  }
}
.btn-active {
  background-color: #ffffff !important;
}
.bot-tis {
  color: #393D60;
}
</style>
