import dayjs from 'dayjs'
import { downloadColumns } from './columns'
import { transformColumnsToForm } from '@/utils'

const options = {
  shortcuts: [{
    text: '今天',
    onClick(picker) {
      const end = dayjs().format('YYYY-MM-DD 23:59:59')
      const start = dayjs().format('YYYY-MM-DD 00:00:00')
      // start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      picker.$emit('pick', [start, end])
    }
  },
  {
    text: '昨天',
    onClick(picker) {
      const end = dayjs().subtract(1, 'day').format('YYYY-MM-DD 23:59:59')
      const start = dayjs().subtract(1, 'day').format('YYYY-MM-DD 00:00:00')
      picker.$emit('pick', [start, end])
    }
  }, {
    text: '最近一周',
    onClick(picker) {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      picker.$emit('pick', [start, end])
    }
  }, {
    text: '最近一个月',
    onClick(picker) {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      picker.$emit('pick', [start, end])
    }
  }, {
    text: '最近三个月',
    onClick(picker) {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
      picker.$emit('pick', [start, end])
    }
  }]
}

export const searchFormList = [
  {
    colSpan: 5,
    key: 'planStartTime',
    tagName: 'el-date-picker',
    defaultValue: 1,
    props: {
      rangeSeparator: '至',
      startPlaceholder: '总装计划',
      endPlaceholder: '开始时间',
      align: 'center',
      type: 'daterange',
      pickerOptions: options,
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm:ss'
    },
    children: []
  },
  {
    colSpan: 5,
    key: 'planEndTime',
    tagName: 'el-date-picker',
    defaultValue: 1,
    props: {
      rangeSeparator: '至',
      startPlaceholder: '总装计划',
      endPlaceholder: '结束时间',
      align: 'center',
      type: 'daterange',
      pickerOptions: options,
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm:ss'
    },
    children: []
  },
  {
    colSpan: 5,
    key: 'actualStartTime',
    tagName: 'el-date-picker',
    defaultValue: 1,
    props: {
      rangeSeparator: '至',
      startPlaceholder: '总装实际',
      endPlaceholder: '开始时间',
      align: 'center',
      type: 'daterange',
      pickerOptions: options,
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm:ss'
    },
    children: []
  },
  {
    colSpan: 5,
    key: 'actualEndTime',
    tagName: 'el-date-picker',
    defaultValue: 1,
    props: {
      rangeSeparator: '至',
      startPlaceholder: '总装实际',
      endPlaceholder: '结束时间',
      align: 'center',
      type: 'daterange',
      pickerOptions: options,
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm:ss'
    },
    children: []
  }
]

export const formList = [
  ...transformColumnsToForm(downloadColumns),
]