<template>
  <div class="role-wrap">
    <Split :offset.sync="split.offset" :height="splitHeight">
      <template slot="left">
        <div style="padding:10px">
          <el-input
            v-model="searchCode"
            placeholder="搜索生产工单编号"
            class="input-with-select"
            clearable
            @input="onInput"
          />
          <div
            v-for="item in demandList"
            :key="item.id"
            class="side-item"
            :style="{color:actId===item.id?'#8099ff':'#606266'}"
            @click="onSideClick(item.id)"
          >
            {{ item.producePlanNumber }}</div>
        </div>
      </template>
      <template slot="right">
        <div class="orders">
          <div class="top">
            <SearchForm
              ref="searchForm"
              :form-list-extend="actId === 'all' ? searchFormList : searchFormListAll"
              :form-data="searchFormData"
              style="padding: 0"
              @update:formData="data=>searchFormData=data"
              @search="reSearch"
            />
          </div>
          <FunctionHeader
            ref="functionHeader"
            v-model="functionHeader.searchVal"
            search-title="请输入物料编号/名称"
            style="padding: 10px 0 10px 0;"
            class="func-header"
            @search="$refs.mTableLeft.setTableData(true)"
          />
          <div v-show="showTable" style="display:flex" class="table-box">
            <Split :offset.sync="tableOffset" :height="height">
              <template slot="left">
                <MTable
                  ref="mTableLeft"
                  :span-method="(params=>arraySpanMethod(params,columns))"
                  row-key="id"
                  :tree-props="{children: 'children'}"
                  :must-show-columns-setting="true"
                  class="table-left"
                  :columns="columns"
                  :height="height"
                  :set-data-method="getTableData"
                  @scroll.native="handleScroll"
                  @selection-change="data=>selectData = data"
                  @row-click="row=>$refs.mTableLeft.tableRefs().toggleRowExpansion(row)"
                  @expand-change="onExpand"
                >
                  <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
                  <NewPageOpen
                    slot="procedureCode"
                    slot-scope="{ row }"
                    :path="{ name: 'procedureDetail', query: {id: row.procedureId}}"
                    :text="row.procedureCode"
                  />
                  <div slot="materialsCode" slot-scope="{ row }">
                    <div v-if="row.children">
                      工序定位码：{{ row.procedureLocationCode }} | 工序编码：{{ row.procedureCode }} |
                      工序名称：{{ row.procedureName }} | 合计：{{ row.children.length }}
                    </div>
                    <NewPageOpen
                      v-else
                      :path="{ name: 'materialsDetail', query: {id: row.materialsId}}"
                      :text="row.materialsCode"
                    />
                  </div>
                  <div slot="lockedStock" slot-scope="{ row }">
                    {{ row.lockedStock===undefined?'-':row.lockedStock }}<i
                    v-if="actId!=='all'&&row.lockedStock!==undefined"
                    class="el-icon-edit"
                    style="color:#607FFF"
                    @click="editStock(row)"
                  /></div>
                </MTable>
              </template>
              <template slot="right">
                <MTable
                  ref="mTableRight"
                  :span-method="(params=>arraySpanMethod(params,columnsDate))"
                  row-key="id"
                  :tree-props="{children: 'children'}"
                  :data="itemTableData"
                  class="table-right"
                  :show-page="false"
                  :columns-setting="false"
                  :columns="columnsDate"
                  :height="height-39"
                >
                  <!-- @expand-change="(row,expanded)=>onExpand(row,expanded,'mTableLeft')" -->
                  <div
                    v-for="item in columnsDate"
                    :key="item.slotName"
                    :slot="item.slotName"
                    slot-scope="{row}"
                  >
                    <span v-if="row.children" />
                    <span
                      v-else
                    >{{ row[item.slotName]&&row[item.slotName].onPassageQuantity||'-' }}</span>
                    <el-tooltip effect="dark" content="请注意！该物料预计晚于开工时间到货，请及时调整。" placement="top">
                      <i
                        v-if="row[item.slotName]&&row[item.slotName].onPassageQuantity&&row[item.slotName].status===1"
                        class="el-icon-warning-outline"
                        style="color:#f78a24"
                      />
                    </el-tooltip>
                  </div>
                </MTable>
              </template>
            </Split>
          </div>

          <div v-show="!showTable" class="empty-box">
            <img src="@/assets/files-empty.png" alt="">
            <h3>暂无采购需求</h3>
            <p style="color:#bfbfbf">可通过修改工艺路线重新计算</p>
          </div>

          <el-dialog title="编辑" :visible.sync="editDialogVisible" width="500px">
            <div style="width:350px;margin:auto">
              <div>当前库存：{{ editRow.currentStock }}</div>
              <div style="margin:10px 0;">可用库存：{{ editRow.availableStock }}</div>
              <div>占用库存：
                <el-input-number
                  v-model="editRow.lockedStock"
                  controls-position="right"
                  :min="0"
                  :max="editRow.availableStock||0"
                  :step="0.001"
                  @blur="e=>onBlur(e,editRow,'lockedStock')"
                />
              </div>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="cancelEdit">取 消</el-button>
              <el-button type="primary" @click="saveStock">确 定</el-button>
            </span>
          </el-dialog>

        </div>
      </template>
    </Split>

  </div>
</template>
<script>
  import api from '@/api/production/project'
  import FunctionHeader from '@/components/FunctionHeader'
  import SearchForm from '@/components/SearchForm/SearchForm'
  import Split from '@/components/Split/Split'
  import { columnsAll, searchFormList, columnsItem, searchFormListAll } from './data'
  import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'

  export default {
    name: 'PurchasingDemand',
    components: { NewPageOpen, FunctionHeader, SearchForm, Split },
    data() {
      return {
        showTable: false,
        split: {
          offset: 14
        },
        tableOffset: 80,
        columnsDate: [],
        allDemandList: [],
        demandList: [],
        actId: null,
        editRow: {},
        editDialogVisible: false,
        searchCode: '',
        searchFormList,
        searchFormListAll,
        searchFormData: {},
        functionHeader: {
          searchVal: ''
        },
        columns: columnsAll,
        searchData: {},
        selectData: [],
        itemTableData: [],
        leftExpandRowKeys: {},
        rightExpandRowKeys: {}
      }
    },
    computed: {
      height() {
        return this.$store.state.app.contentHeight - 192 - 75
      },
      splitHeight() {
        return this.$store.state.app.contentHeight - 235 + 90
      },
      disable() {
        return !this.selectData.length
      }
    },

    created() {
      this.initPage()
    },
    mounted() {
      this.tableLeft = document.querySelectorAll('.el-table__body-wrapper')[0]
      this.tableRight = document.querySelectorAll('.el-table__body-wrapper')[1]
      this.tableLeft.addEventListener('scroll', this.handleScrollLeft)
      this.tableRight.addEventListener('scroll', this.handleScrollRight)

      const el = document.querySelector('.page-container')
      const newParent = document.querySelector('.horizontal')
      newParent.appendChild(el)
    },

    beforeDestroy() {
      this.tableLeft.removeEventListener('scroll', this.handleScrollLeft)
      this.tableRight.removeEventListener('scroll', this.handleScrollRight)
      this.tableLeft = this.tableRight = null
    },

    methods: {
      arraySpanMethod({ row, column, rowIndex, columnIndex }, columns) {
        if (row.children?.length) {
          if (columnIndex === 0) {
            return [1, columns.length]
          }
        }
      },
      onBlur(e, row, key, num = 3) {
        const valStr = e.target.value + ''
        const decimalLength = valStr.split('.')[1]?.length
        if (decimalLength > num) {
          e.target.value = valStr.slice(0, valStr.length - (decimalLength - num)) * 1
          row[key] = e.target.value
        }
      },
      cancelEdit() {
        this.editRow.lockedStock = this.currentStock
        this.editDialogVisible = false
      },
      editStock(editRow) {
        this.editRow = editRow
        this.currentStock = editRow.lockedStock
        this.editDialogVisible = true
      },
      async saveStock() {
        const { id: itemId, lockedStock } = this.editRow
        const res = await api.updateLockedStock({ planId: this.actId, itemId, lockedStock })
        if (res) {
          this.$message.success('操作成功')
          this.onSideClick(this.actId)
          this.editDialogVisible = false
        }
      },
      onSideClick(id) {
        this.actId = id
        this.$refs.mTableLeft.tableRefs().clearSelection()
        this.$refs.searchForm.reset()
      },
      onInput() {
        this.demandList = this.allDemandList.filter(item => item.producePlanNumber.toLowerCase().includes(this.searchCode.toLowerCase()) || item.id?.toString().includes(this.searchCode))
      },
      handleScrollLeft({ target }) {
        const scrollTop = target.scrollTop
        this.tableRight.scrollTop = scrollTop
      },
      handleScrollRight({ target }) {
        const scrollTop = target.scrollTop
        this.tableLeft.scrollTop = scrollTop
      },
      reSearch(data, val) {
        if (val === 1) {
          this.searchFormData = {}
        }
        this.$refs.mTableLeft.setTableData(true)
      },
      initPage() {
        // 获取计划
        this.getUserOptions()
        this.getMaterialProp()
      },

      // 获取物料属性
      async getMaterialProp() {
        const res = await api.materialsAttributes()
        if (res) {
          this.searchFormList[1].children[0].options = res
        }
      },
      // 获取计划
      async getUserOptions() {
        const res = await api.producePlanList()
        if (res) {
          this.allDemandList = [
            // {
            //   producePlanNumber: '全部', id: 'all'
            // },
            ...res
          ]
          this.demandList = this.allDemandList
          this.onSideClick(this.demandList[0]?.id)
        }
      },

      // 列表
      async getTableData(condition, callback) {
        if (!this.actId) {
          callback({
            total: 0,
            content: []
          })
          return
        }
        const { page } = condition
        const { procedure, specifications, materialsAttributeName } = this.searchFormData
        const params = {
          materials: this.functionHeader.searchVal,
          planId: this.actId,
          procedure,
          specifications,
          materialsAttributeName
        }
        this.columns = this.actId === 'all' ? columnsAll : columnsItem
        const API = this.actId === 'all' ? api.detail : api.singlePlanDetail
        // 导出参数
        this.functionHeader.exportParams = params
        const res = await API({
          page: page.currentPage,
          size: page.pageSize,
          ...params
        })
        if (res) {
          this.showTable = res.total
          this.leftTableData = this.formatData(res.records)
          callback({
            total: res.total,
            content: this.actId === 'all' ? res.records : this.leftTableData
          })
          const dataMap = {}
          res.records.forEach(i => {
            i.requireDayList?.forEach(j => {
              dataMap[j.requireDate] = true
            })
          })
          this.columnsDate = Object.keys(dataMap).sort().map(item => {
            return {
              slotName: item,
              label: item,
              sortable: false,
              hiddenSearch: true,
              minWidth: 120
            }
          })
          const itemTableData = res.records.map(item => {
            item.planId = this.actId
            const newItem = {
              procedureLocationCode: item.procedureLocationCode,
              id: item.id
            }
            item.requireDayList.forEach(k => { newItem[k.requireDate] = k })
            return newItem
          })
          this.itemTableData = this.actId === 'all' ? itemTableData : this.formatData(itemTableData)
        }
        callback()
      },
      formatData(data) {
        return data.reduce((acc, cur, idx) => {
          const existingItemIndex = acc.findIndex(item => item.procedureLocationCode === cur.procedureLocationCode)
          if (existingItemIndex !== -1) {
            acc[existingItemIndex].children.push({ ...cur })
          } else {
            acc.push({ id: cur.id + '-' + idx, procedureName: cur.procedureName, procedureCode: cur.procedureCode, procedureLocationCode: cur.procedureLocationCode, children: [{ ...cur }] })
          }
          return acc
        }, [])
      },
      // onExpand({ id }, expanded, table) {
      //   let expandRowKeys
      //   if (table === 'mTableRight') {
      //     this.rightExpandRowKeys[id] = expanded
      //     expandRowKeys = this.leftExpandRowKeys
      //   } else {
      //     this.leftExpandRowKeys[id] = expanded
      //     expandRowKeys = this.rightExpandRowKeys
      //   }
      //   if (expandRowKeys[id] === expanded) return// 避免左右循环联动
      //   const row = this[table === 'mTableRight' ? 'itemTableData' : 'leftTableData'].find(item => item.id === id)
      //   this.$refs[table].tableRefs().toggleRowExpansion(row)
      // }
      onExpand({ id }) {
        const row = this.itemTableData.find(item => item.id === id)
        this.$refs.mTableRight.tableRefs().toggleRowExpansion(row)
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep .left,
  ::v-deep .right {
    &::-webkit-scrollbar {
      width: 0px;
      height: 0;
    }
  }
  .side-item {
    height: 34px;
    padding: 10px;
    color: #606266;
    &:hover {
      background-color: #f4f7fa;
    }
  }
  ::v-deep .el-table {
    tr {
      height: 36px;
      .cell {
        display: flex;
        align-items: center;
      }
    }
  }
  ::v-deep .columns-setting {
    right: 20px;
    position: fixed;
    top: 185px;
  }
  ::v-deep .page-container {
    position: absolute;
    z-index: 999;
    bottom: 23px;
    right: 20px;
  }
  .table-box {
    ::v-deep .horizontal {
      .trigger-con {
        .split-line {
          height: 87%;
        }
      }
    }
  }
  .table-left {
    ::v-deep .el-table__body-wrapper {
      &::-webkit-scrollbar {
        width: 0px;
      }
    }
    ::v-deep .el-table__row--level-0 {
      .el-table_1_column_2,
      .el-table_1_column_3,
      .el-table_1_column_4,
      .el-table_1_column_5,
      .el-table_1_column_6,
      .el-table_1_column_7,
      .el-table_1_column_8,
      .el-table_1_column_9,
      .el-table_1_column_10,
      .el-table_1_column_11,
      .el-table_1_column_12,
      .el-table_1_column_13,
      .el-table_1_column_14 {
        display: none;
      }
    }
  }
  .orders {
    padding: 10px;
  }
  .table-right {
    ::v-deep .el-table {
      .el-table__expand-icon {
        display: none;
      }
      .el-table__indent {
        padding-left: 0 !important;
      }
      .el-table__placeholder {
        width: 0;
      }
    }
  }
  .empty-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30%;
  }
</style>
