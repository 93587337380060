var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "MDialog",
    {
      attrs: { width: "1000px", title: "查看报告", "show-footer": false },
      on: {
        close: function($event) {
          _vm.modalShow = false
        }
      },
      model: {
        value: _vm.modalShow,
        callback: function($$v) {
          _vm.modalShow = $$v
        },
        expression: "modalShow"
      }
    },
    [
      _c(
        "div",
        { staticStyle: { "font-size": "16px", "text-align": "center" } },
        [
          _c("h2", [_vm._v("检验报告")]),
          _c(
            "div",
            {
              staticStyle: {
                "font-size": "16px",
                transform: "scale(0.9)",
                "-webkit-transform": "scale(0.9)",
                "text-align": "left",
                "transform-origin": "0 50%",
                "text-decoration": "underline",
                color: "#393D60"
              }
            },
            [_vm._v("基本信息")]
          ),
          _c(
            "div",
            {
              staticStyle: {
                display: "inline-flex",
                "justify-content": "flex-start",
                "align-items": "center",
                "flex-flow": "row wrap",
                width: "100%",
                color: "#393D60"
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "33.3%",
                    display: "flex",
                    height: "30px",
                    "align-items": "center",
                    "justify-content": "flex-start"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "45%",
                        transform: "scale(0.9)",
                        "-webkit-transform": "scale(0.9)",
                        "font-size": "16px",
                        "text-align": "left"
                      }
                    },
                    [_vm._v("质检单号：")]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "55%",
                        transform: "scale(0.9)",
                        "-webkit-transform": "scale(0.9)",
                        "font-size": "16px",
                        "text-align": "left",
                        "transform-origin": "0 50%",
                        "word-break": "break-all"
                      }
                    },
                    [_vm._v(_vm._s(_vm.info.number))]
                  )
                ]
              ),
              _vm.info.inspectionType === 1
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        width: "33.3%",
                        display: "flex",
                        height: "30px",
                        "align-items": "center",
                        "justify-content": "flex-start"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "45%",
                            transform: "scale(0.9)",
                            "-webkit-transform": "scale(0.9)",
                            "font-size": "16px",
                            "text-align": "left"
                          }
                        },
                        [_vm._v("工序：")]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "55%",
                            transform: "scale(0.9)",
                            "-webkit-transform": "scale(0.9)",
                            "font-size": "16px",
                            "text-align": "left",
                            "transform-origin": "0 50%"
                          }
                        },
                        [_vm._v(_vm._s(_vm.info.procedureName))]
                      )
                    ]
                  )
                : _vm._e(),
              _vm.info.inspectionType === 1
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        width: "33.3%",
                        display: "flex",
                        height: "30px",
                        "align-items": "center",
                        "justify-content": "flex-start"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "45%",
                            transform: "scale(0.9)",
                            "-webkit-transform": "scale(0.9)",
                            "font-size": "16px",
                            "text-align": "left"
                          }
                        },
                        [_vm._v("生产工单号：")]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "55%",
                            transform: "scale(0.9)",
                            "-webkit-transform": "scale(0.9)",
                            "font-size": "16px",
                            "text-align": "left",
                            "transform-origin": "0 50%",
                            "word-break": "break-all"
                          }
                        },
                        [_vm._v(_vm._s(_vm.info.produceWorkOrderNumber))]
                      )
                    ]
                  )
                : _c(
                    "div",
                    {
                      staticStyle: {
                        width: "33.3%",
                        display: "flex",
                        height: "30px",
                        "align-items": "center",
                        "justify-content": "flex-start"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "45%",
                            transform: "scale(0.9)",
                            "-webkit-transform": "scale(0.9)",
                            "font-size": "16px",
                            "text-align": "left"
                          }
                        },
                        [_vm._v("相关单号：")]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "55%",
                            transform: "scale(0.9)",
                            "-webkit-transform": "scale(0.9)",
                            "font-size": "16px",
                            "text-align": "left",
                            "transform-origin": "0 50%",
                            "word-break": "break-all"
                          }
                        },
                        [_vm._v(_vm._s(_vm.info.relationNumber))]
                      )
                    ]
                  ),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "33.3%",
                    display: "flex",
                    height: "30px",
                    "align-items": "center",
                    "justify-content": "flex-start"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "45%",
                        transform: "scale(0.9)",
                        "-webkit-transform": "scale(0.9)",
                        "font-size": "16px",
                        "text-align": "left"
                      }
                    },
                    [_vm._v("物料编码：")]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "55%",
                        "word-break": "break-all",
                        transform: "scale(0.9)",
                        "-webkit-transform": "scale(0.9)",
                        "font-size": "16px",
                        "text-align": "left",
                        "transform-origin": "0 50%"
                      }
                    },
                    [_vm._v(_vm._s(_vm.info.materialCode))]
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "33.3%",
                    display: "flex",
                    height: "30px",
                    "align-items": "center",
                    "justify-content": "flex-start"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "45%",
                        transform: "scale(0.9)",
                        "-webkit-transform": "scale(0.9)",
                        "font-size": "16px",
                        "text-align": "left"
                      }
                    },
                    [_vm._v("物料名称：")]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "55%",
                        transform: "scale(0.9)",
                        "-webkit-transform": "scale(0.9)",
                        "font-size": "16px",
                        "text-align": "left",
                        "transform-origin": "0 50%"
                      }
                    },
                    [_vm._v(_vm._s(_vm.info.materialName))]
                  )
                ]
              ),
              _vm.info.inspectionType === 1
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        width: "33.3%",
                        display: "flex",
                        height: "30px",
                        "align-items": "center",
                        "justify-content": "flex-start"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "45%",
                            ransform: "scale(0.9)",
                            "-webkit-transform": "scale(0.9)",
                            "font-size": "16px",
                            "text-align": "left"
                          }
                        },
                        [_vm._v("生产订单号：")]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "55%",
                            transform: "scale(0.9)",
                            "-webkit-transform": "scale(0.9)",
                            "font-size": "16px",
                            "text-align": "left",
                            "transform-origin": "0 50%"
                          }
                        },
                        [_vm._v(_vm._s(_vm.info.produceOrderNumber))]
                      )
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "33.3%",
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "flex-start"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "45%",
                        transform: "scale(0.9)",
                        "-webkit-transform": "scale(0.9)",
                        "font-size": "16px",
                        "text-align": "left"
                      }
                    },
                    [_vm._v("物料规格：")]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "55%",
                        transform: "scale(0.9)",
                        "-webkit-transform": "scale(0.9)",
                        "font-size": "16px",
                        "text-align": "left",
                        "transform-origin": "0 50%"
                      }
                    },
                    [_vm._v(_vm._s(_vm.info.materialSpecifications))]
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "33.3%",
                    display: "flex",
                    height: "30px",
                    "align-items": "center",
                    "justify-content": "flex-start"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "45%",
                        transform: "scale(0.9)",
                        "-webkit-transform": "scale(0.9)",
                        "font-size": "16px",
                        "text-align": "left"
                      }
                    },
                    [_vm._v("质检方案：")]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "55%",
                        transform: "scale(0.9)",
                        "-webkit-transform": "scale(0.9)",
                        "font-size": "16px",
                        "text-align": "left",
                        "transform-origin": "0 50%"
                      }
                    },
                    [_vm._v(_vm._s(_vm.info.inspectionSchemeName))]
                  )
                ]
              ),
              _vm.info.inspectionType === 1
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        width: "33.3%",
                        display: "flex",
                        height: "30px",
                        "align-items": "center",
                        "justify-content": "flex-start"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "45%",
                            transform: "scale(0.9)",
                            "-webkit-transform": "scale(0.9)",
                            "font-size": "16px",
                            "text-align": "left"
                          }
                        },
                        [_vm._v("批次报工数量：")]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "55%",
                            transform: "scale(0.9)",
                            "-webkit-transform": "scale(0.9)",
                            "font-size": "16px",
                            "text-align": "left",
                            "transform-origin": "0 50%"
                          }
                        },
                        [_vm._v(_vm._s(_vm.info.batchCount))]
                      )
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    display: "flex",
                    height: "30px",
                    "justify-content": "flex-start",
                    "align-items": "center"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "15%",
                        transform: "scale(0.9)",
                        "-webkit-transform": "scale(0.9)",
                        "font-size": "16px",
                        "text-align": "left"
                      }
                    },
                    [_vm._v("批次码：")]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "85%",
                        transform: "scale(0.9)",
                        "-webkit-transform": "scale(0.9)",
                        "font-size": "16px",
                        "text-align": "left",
                        "word-break": "break-all",
                        "transform-origin": "0 50%"
                      }
                    },
                    [_vm._v(_vm._s(_vm.info.batchNumber))]
                  )
                ]
              )
            ]
          ),
          _c(
            "div",
            {
              staticStyle: {
                "font-size": "16px",
                transform: "scale(0.9)",
                "-webkit-transform": "scale(0.9)",
                "text-align": "left",
                "transform-origin": "0 50%",
                "text-decoration": "underline",
                "margin-top": "10px",
                color: "#393D60"
              }
            },
            [_vm._v("检验结论")]
          ),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "flex-start",
                "align-items": "center",
                "flex-flow": "row wrap",
                width: "100%",
                color: "#393D60"
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "25%",
                    display: "flex",
                    height: "30px",
                    "align-items": "center"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "50%",
                        transform: "scale(0.9)",
                        "-webkit-transform": "scale(0.9)",
                        "font-size": "16px",
                        "text-align": "left"
                      }
                    },
                    [_vm._v("合格数：")]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "50%",
                        transform: "scale(0.9)",
                        "-webkit-transform": "scale(0.9)",
                        "font-size": "16px",
                        "text-align": "left",
                        "transform-origin": "0 50%"
                      }
                    },
                    [_vm._v(_vm._s(_vm.info.okCount))]
                  )
                ]
              ),
              _vm.info.ngCount
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        width: "25%",
                        display: "flex",
                        height: "30px",
                        "align-items": "center"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "50%",
                            transform: "scale(0.9)",
                            "-webkit-transform": "scale(0.9)",
                            "font-size": "16px",
                            "text-align": "left"
                          }
                        },
                        [_vm._v("不合格数：")]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "50%",
                            transform: "scale(0.9)",
                            "-webkit-transform": "scale(0.9)",
                            "font-size": "16px",
                            "text-align": "left",
                            "transform-origin": "0 50%"
                          }
                        },
                        [_vm._v(_vm._s(_vm.info.ngCount))]
                      )
                    ]
                  )
                : _vm._e()
            ]
          ),
          _vm.info.ngCount
            ? _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "flex-start",
                    "align-items": "center",
                    "flex-flow": "row wrap",
                    width: "100%",
                    color: "#393D60"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "50%",
                        display: "flex",
                        height: "30px",
                        "align-items": "center"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "25%",
                            transform: "scale(0.9)",
                            "-webkit-transform": "scale(0.9)",
                            "font-size": "16px",
                            "text-align": "left"
                          }
                        },
                        [_vm._v("处理意见：")]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "75%",
                            transform: "scale(0.9)",
                            "-webkit-transform": "scale(0.9)",
                            "font-size": "16px",
                            "text-align": "left",
                            "transform-origin": "0 50%"
                          }
                        },
                        [
                          _vm.info.degradeCount
                            ? _c("span", [
                                _vm._v(
                                  "让步接收：" +
                                    _vm._s(_vm.info.degradeCount) +
                                    "；"
                                )
                              ])
                            : _vm._e(),
                          _vm.info.repairCount
                            ? _c("span", [
                                _vm._v(
                                  "返修：" + _vm._s(_vm.info.repairCount) + "；"
                                )
                              ])
                            : _vm._e(),
                          _vm.info.scrapCount
                            ? _c("span", [
                                _vm._v("报废：" + _vm._s(_vm.info.scrapCount))
                              ])
                            : _vm._e()
                        ]
                      )
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm.info.inspectionType === 0 && _vm.info.result === 0
            ? _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    display: "flex",
                    height: "30px",
                    "align-items": "center",
                    "justify-content": "flex-start",
                    color: "#393D60"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "15%",
                        "font-size": "16px",
                        "text-align": "left",
                        transform: "scale(0.9)",
                        "-webkit-transform": "scale(0.9)"
                      }
                    },
                    [_vm._v("不合格描述：")]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "85%",
                        transform: "scale(0.9)",
                        "-webkit-transform": "scale(0.9)",
                        "font-size": "16px",
                        "text-align": "left",
                        "transform-origin": "0 50%"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.info.ngExplain))]
                  )
                ]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "flex-start",
                "align-items": "center",
                "flex-flow": "row wrap",
                width: "100%",
                color: "#393D60"
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "25%",
                    display: "flex",
                    height: "30px",
                    "align-items": "center"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "50%",
                        transform: "scale(0.9)",
                        "-webkit-transform": "scale(0.9)",
                        "font-size": "16px",
                        "text-align": "left"
                      }
                    },
                    [_vm._v("检验人：")]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "50%",
                        transform: "scale(0.9)",
                        "-webkit-transform": "scale(0.9)",
                        "font-size": "16px",
                        "text-align": "left",
                        "transform-origin": "0 50%"
                      }
                    },
                    [_vm._v(_vm._s(_vm.info.inspectorName))]
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "50%",
                    display: "flex",
                    height: "30px",
                    "align-items": "center"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "50%",
                        transform: "scale(0.9)",
                        "-webkit-transform": "scale(0.9)",
                        "font-size": "16px",
                        "text-align": "left"
                      }
                    },
                    [_vm._v("完成时间：")]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "50%",
                        transform: "scale(0.9)",
                        "-webkit-transform": "scale(0.9)",
                        "font-size": "16px",
                        "text-align": "left",
                        "transform-origin": "0 50%"
                      }
                    },
                    [_vm._v(_vm._s(_vm.info.endTime))]
                  )
                ]
              )
            ]
          ),
          _c(
            "div",
            {
              staticStyle: {
                "font-size": "16px",
                transform: "scale(0.9)",
                "-webkit-transform": "scale(0.9)",
                "text-align": "left",
                "transform-origin": "0 50%",
                "text-decoration": "underline",
                "margin-top": "10px",
                color: "#393D60"
              }
            },
            [_vm._v("检验数据")]
          ),
          _c(
            "table",
            {
              staticStyle: {
                "border-collapse": "collapse",
                "font-family": "Arial, Helvetica, sans-serif",
                width: "100%"
              },
              attrs: { id: "customers" }
            },
            [
              _c("thead", [
                _c(
                  "tr",
                  {
                    staticStyle: {
                      height: "40px",
                      border: "1px solid #DDE3EB",
                      padding: "8px",
                      "text-align": "left",
                      background: "#F6F7F9"
                    }
                  },
                  [
                    _c(
                      "th",
                      {
                        staticStyle: {
                          "border-right": "1px solid #DDE3EB",
                          "font-size": "16px",
                          width: "80px",
                          background: "#F6F7F9"
                        }
                      },
                      [_vm._v("检验项目")]
                    ),
                    _c(
                      "th",
                      {
                        staticStyle: {
                          "border-right": "1px solid #DDE3EB",
                          "font-size": "16px",
                          width: "90px",
                          background: "#F6F7F9"
                        }
                      },
                      [_vm._v("检验条目")]
                    ),
                    _c(
                      "th",
                      {
                        staticStyle: {
                          "border-right": "1px solid #DDE3EB",
                          "font-size": "16px",
                          width: "120px",
                          background: "#F6F7F9"
                        },
                        attrs: { colspan: "3" }
                      },
                      [_vm._v("检验数据")]
                    ),
                    _c(
                      "th",
                      {
                        staticStyle: {
                          "border-right": "1px solid #DDE3EB",
                          "font-size": "16px",
                          background: "#F6F7F9"
                        }
                      },
                      [_vm._v("标准值")]
                    ),
                    _c(
                      "th",
                      {
                        staticStyle: {
                          "border-right": "1px solid #DDE3EB",
                          "font-size": "16px",
                          background: "#F6F7F9"
                        }
                      },
                      [_vm._v("上限")]
                    ),
                    _c(
                      "th",
                      {
                        staticStyle: {
                          "border-right": "1px solid #DDE3EB",
                          "font-size": "16px",
                          background: "#F6F7F9"
                        }
                      },
                      [_vm._v("下限")]
                    ),
                    _c(
                      "th",
                      {
                        staticStyle: {
                          "border-right": "1px solid #DDE3EB",
                          "font-size": "16px",
                          background: "#F6F7F9"
                        }
                      },
                      [_vm._v("检验方法")]
                    ),
                    _c(
                      "th",
                      {
                        staticStyle: {
                          "font-size": "16px",
                          background: "#F6F7F9",
                          "border-right": "1px solid #DDE3EB"
                        }
                      },
                      [_vm._v("检验标准")]
                    )
                  ]
                )
              ]),
              _c("tbody", { attrs: { id: "tbodys" } })
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }