/** write by luomingzhi */
import { formatColumns } from '@/utils'
import { status } from '@/config/options.config'

export const columns = [
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'name',
    label: '方案名称',
    sortable: false,
    hiddenSearch: true,
    slotName: 'name',
    width: 180,
    form: { tagName: 'el-input', colSpan: 6 }
  },
  {
    prop: 'type',
    label: '业务类型',
    sortable: false,
    hiddenSearch: true,
    slotName:'type',
    form: { tagName: 'el-select', options: [{name:'过程检',id:1 }, {name:'来料检',id:2}, {name:'出货检',id:3}],colSpan: 6 }
  },
  {
    prop: 'creator',
    label: '创建人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'createTime',
    label: '创建时间',
    sortable: false,
    hiddenSearch: true,
    width: 180
  },
  {
    prop: 'lastUpdateTime',
    label: '最近更新时间',
    sortable: false,
    hiddenSearch: true,
    width: 180
  },
  {
    prop: 'isEnabled',
    label: '方案状态',
    sortable: false,
    slotName: 'isEnabled',
    filters: formatColumns(status),
    width: 120,
    form: {
      colSpan: 6,
      tagName: 'el-select',
      options: status,
      defaultValue: 1
    }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: {
      colSpan: 6,
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' }
    }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 165
  }
]

export const inspectionItemsColumns = [
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'inspectionEntryName',
    label: '检验条目名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'inspectionProjectName',
    label: '所属检验项目',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'parameterType',
    label: '参数类型',
    sortable: false,
    hiddenSearch: true,
    slotName: 'parameterType'
  },
  {
    prop: 'defaultValue',
    label: '默认值',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'possibleValue',
    label: '可能值',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'unitName',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'inspectionMethodName',
    label: '检验方法',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'inspectionStandardName',
    label: '检验标准',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'upperLimit',
    label: '上偏差',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'lowerLimit',
    label: '下偏差',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'limitType',
    label: '数值类型',
    sortable: false,
    hiddenSearch: true,
    slotName: 'limitType'
  }
]

export default columns
