// 列表
export const columnsAll = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'status',
    label: '质检状态',
    hiddenSearch: true,
    sortable: false,
    slotName: 'status',
    width: 120
  },
  {
    prop: 'inspectionType',
    label: '质检类型',
    hiddenSearch: true,
    sortable: false,
    slotName: 'inspectionType'
  },
  {
    prop: 'number',
    label: '质检单号',
    hiddenSearch: true,
    sortable: 'custom',
    width: 160,
    slotName: 'number'
  },
  {
    prop: 'inspectionSchemeName',
    label: '质检方案',
    hiddenSearch: true,
    sortable: false,
    width: 180
  },
  {
    prop: 'procedureName',
    label: '工序',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'materialCode',
    label: '物料编码',
    hiddenSearch: true,
    sortable: false,
    width: 150
  },
  {
    prop: 'materialName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false,
    width: 150
  },
  {
    prop: 'materialSpecifications',
    label: '规格',
    hiddenSearch: true,
    sortable: false,
    width: 240
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'produceWorkOrderNumber',
    label: '关联生产工单号',
    hiddenSearch: true,
    sortable: 'custom',
    width: 180
  },
  {
    prop: 'reports',
    label: '检验报告',
    hiddenSearch: true,
    sortable: false,
    slotName: 'reports'
  },
  {
    prop: 'creator',
    label: '制单人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'createTime',
    label: '制单时间',
    hiddenSearch: true,
    sortable: 'custom',
    width: 180
  },
  {
    prop: 'inspectorName',
    label: '检验人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'startTime',
    label: '质检开始时间',
    hiddenSearch: true,
    sortable: 'custom',
    width: 180
  },
  {
    prop: 'endTime',
    label: '质检提交时间',
    hiddenSearch: true,
    sortable: 'custom',
    width: 180
  },
  {
    prop: 'batchCount',
    label: '批次数量',
    hiddenSearch: true,
    sortable: false,
    width: 160
  },
  {
    prop: 'passRate',
    label: '合格率',
    hiddenSearch: true,
    sortable: false,
    slotName: 'passRate'
  },
  {
    prop: 'allCount',
    label: '检验（合格/不合格）',
    hiddenSearch: true,
    sortable: false,
    slotName: 'allCount',
    width: 180
  },

  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 212
  }
]
export const incomming = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'status',
    label: '质检状态',
    hiddenSearch: true,
    sortable: false,
    slotName: 'status',
    width: 120
  },
  {
    prop: 'relationNumber',
    label: '相关单号',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'inspectionType',
    label: '质检类型',
    hiddenSearch: true,
    sortable: false,
    slotName: 'inspectionType'
  },
  {
    prop: 'number',
    label: '质检单号',
    hiddenSearch: true,
    sortable: 'custom',
    width: 160,
    slotName: 'number'
  },
  {
    prop: 'inspectionSchemeName',
    label: '质检方案',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialCode',
    label: '物料编码',
    hiddenSearch: true,
    sortable: false,
    width: 150
  },
  {
    prop: 'materialName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialSpecifications',
    label: '规格',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'reports',
    label: '检验报告',
    hiddenSearch: true,
    sortable: false,
    slotName: 'reports'
  },
  {
    prop: 'creator',
    label: '制单人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'createTime',
    label: '制单时间',
    hiddenSearch: true,
    sortable: 'custom'
  },
  {
    prop: 'inspectorName',
    label: '检验人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'startTime',
    label: '质检开始时间',
    hiddenSearch: true,
    sortable: 'custom'
  },
  {
    prop: 'endTime',
    label: '质检提交时间',
    hiddenSearch: true,
    sortable: 'custom'
  },
  {
    prop: 'batchCount',
    label: '批次数量',
    hiddenSearch: true,
    sortable: false,
    width: 160
  },
  {
    prop: 'passRate',
    label: '合格率',
    hiddenSearch: true,
    sortable: false,
    slotName: 'passRate'
  },
  {
    prop: 'allCount',
    label: '检验（合格/不合格）',
    hiddenSearch: true,
    sortable: false,
    slotName: 'allCount'
  },

  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 212
  }
]

