var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "searchs" },
    [
      _c("el-col", { attrs: { span: 6 } }, [
        _c("span", { staticClass: "bot-ti" }, [
          _vm._v("项目编码：" + _vm._s(_vm.detailData.projectNumber))
        ])
      ]),
      _c("el-col", { attrs: { span: 6 } }, [
        _c("span", { staticClass: "bot-ti" }, [
          _vm._v("项目名称：" + _vm._s(_vm.detailData.projectName))
        ])
      ]),
      _c("el-col", { attrs: { span: 6 } }, [
        _c("span", { staticClass: "bot-ti" }, [
          _vm._v("客户订单号：" + _vm._s(_vm.detailData.customerOrderNumber))
        ])
      ]),
      _c("el-col", { attrs: { span: 6 } }, [
        _c("span", { staticClass: "bot-ti" }, [
          _vm._v("客户：" + _vm._s(_vm.detailData.customerName))
        ])
      ]),
      _c("el-col", { attrs: { span: 6 } }, [
        _c("span", { staticClass: "bot-ti" }, [
          _vm._v("业务部门：" + _vm._s(_vm.detailData.businessUnitName))
        ])
      ]),
      _c("el-col", { attrs: { span: 6 } }, [
        _c("span", { staticClass: "bot-ti" }, [
          _vm._v(
            "执行部门：" +
              _vm._s(
                _vm.detailData.executiveUnit != null
                  ? _vm.executionDepartment[_vm.detailData.executiveUnit].name
                  : ""
              )
          )
        ])
      ]),
      _c("el-col", { attrs: { span: 12 } }, [
        _c("span", { staticClass: "bot-ti" }, [
          _vm._v("负责人：" + _vm._s(_vm.detailData.principalName))
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }