import { transformColumnsToForm } from '@/utils'
import { columnsAll, HavecolumnsAll } from './columns'
import { executionDepartment } from '@/views/plans/prod-plan-lists/data'
export const searchFormList = [
  {
    colSpan: 24,
    key: 'projectSearch',
    tagName: 'el-input',
    attrs: {
      placeholder: '项目编码/名称'
    },
  },
  {
    key: 'materialsSearch',
    tagName: 'el-input',
    colSpan: 24,
    attrs: {
      placeholder: '物料编号/名称/规格'
    }
  },
  {
    key: 'principalId',
    tagName: 'el-select',
    colSpan: 24,
    props: {
      placeholder: '负责人'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'userFullName',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    key: 'businessUnit',
    tagName: 'el-select',
    colSpan: 24,
    props: {
      placeholder: '业务部门'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    key: 'executiveUnit',
    tagName: 'el-select',
    colSpan: 24,
    props: {
      placeholder: '执行部门'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: executionDepartment
      }
    ]
  },
  {
    colSpan: 24,
    key: 'productionPlanStartTimeStart',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '生产计划开始时间',
      align: 'center',
      type: 'datetimerange',
      pickerOptions: [],
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm:ss'
    },
    children: []
  },
  {
    colSpan: 24,
    key: 'productionPlanFinishTimeStart',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '生产计划完成时间',
      align: 'center',
      type: 'datetimerange',
      pickerOptions: [],
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm:ss'
    },
    children: []
  },
  {
    colSpan: 24,
    key: 'assemblyPlanStartTimeStart',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '装配计划开始时间',
      align: 'center',
      type: 'datetimerange',
      pickerOptions: [],
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm:ss'
    },
    children: []
  },
  {
    colSpan: 24,
    key: 'assemblyPlanFinishTimeStart',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '装配计划完成时间',
      align: 'center',
      type: 'datetimerange',
      pickerOptions: [],
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm:ss'
    },
    children: []
  },
  {
    colSpan: 24,
    key: 'deliveryPlanStartTimeStart',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '发货计划开始时间',
      align: 'center',
      type: 'datetimerange',
      pickerOptions: [],
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm:ss'
    },
    children: []
  },
  {
    colSpan: 24,
    key: 'deliveryPlanFinishTimeStart',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '发货计划完成时间',
      align: 'center',
      type: 'datetimerange',
      pickerOptions: [],
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm:ss'
    },
    children: []
  }
]
export const dialogFormList = transformColumnsToForm(columnsAll)
export const haveDialogFormList = transformColumnsToForm(HavecolumnsAll)
export const adjustFormList = [
  {
      label: '开始时间',
      key: 'startTime',
      tagName: 'el-date-picker',
      required: false,
      props: {
        clearable: true
      },
      props: { type: 'datetime',valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm:ss',disabled: false }
    },
  {
    label: '完成时间',
    key: 'finishTime',
    tagName: 'el-date-picker',
    required: false,
    props: {
      clearable: true
    },
    props: { type: 'datetime',valueFormat: 'yyyy-MM-dd HH:mm:ss',
    format: 'yyyy-MM-dd HH:mm:ss',disabled: false }
  },
  {
    label: '调整原因',
    key: 'adjustReason',
    tagName: 'el-input',
    props: {
      type: 'textarea'
    }
  }
]
