var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "inspection-plan-detail-wrapper" }, [
    _c("div", { staticClass: "inspection-plan-detail-header flex-sbc" }, [
      _c(
        "div",
        {
          staticClass: "left",
          on: {
            click: function($event) {
              return _vm.$router.back()
            }
          }
        },
        [
          _c("img", {
            staticStyle: { width: "8px" },
            attrs: {
              src: require("@/assets/information/procedure/左滑@2x.png")
            }
          }),
          _c(
            "span",
            { staticStyle: { "padding-left": "10px", cursor: "pointer" } },
            [_vm._v("采购订单详情")]
          )
        ]
      )
    ]),
    _c("div", { staticClass: "inspection-plan-detail-body" }, [
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _c(
              "el-row",
              { staticClass: "view-list", attrs: { gutter: 24 } },
              [
                _vm._l(_vm.detailColumns, function(item, index) {
                  return [
                    item.label
                      ? _c(
                          "el-col",
                          {
                            key: item.key,
                            style: { marginBottom: index !== 4 ? "20px" : 0 },
                            attrs: { span: 6 }
                          },
                          [
                            _c("span", { staticStyle: { color: "#9597AE" } }, [
                              _vm._v(_vm._s(item.label) + "：")
                            ]),
                            _c("span", { staticClass: "bot-tis" }, [
                              _vm._v(_vm._s(_vm.getValue(item.key)))
                            ])
                          ]
                        )
                      : _vm._e()
                  ]
                })
              ],
              2
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("采购订单行")]),
        _c(
          "div",
          { staticClass: "card-bot card-bot2" },
          [
            _c("FunctionHeader", {
              ref: "functionHeader",
              staticStyle: { padding: "10px 0 10px 0", height: "40px" },
              attrs: { "hidden-search": true }
            }),
            _c("MTable", {
              attrs: {
                height: 500,
                columns: _vm.detailListColumns,
                "show-page": false,
                data: _vm.detail.orderItemList || [],
                "only-key": "uuid"
              },
              scopedSlots: _vm._u([
                {
                  key: "index",
                  fn: function(ref) {
                    var $index = ref.$index
                    return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                  }
                },
                {
                  key: "materialsCode",
                  fn: function(ref) {
                    var row = ref.row
                    return _c(
                      "div",
                      {},
                      [
                        _c("NewPageOpen", {
                          attrs: {
                            path: {
                              name: "materialsDetail",
                              query: { id: row.materialsId }
                            },
                            text: row.code
                          }
                        })
                      ],
                      1
                    )
                  }
                },
                {
                  key: "receivedProgress",
                  fn: function(ref) {
                    var row = ref.row
                    return _c("div", {}, [
                      _vm._v(" " + _vm._s(row.receivedProgress) + "% ")
                    ])
                  }
                }
              ])
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }