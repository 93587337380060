import { planNodeFormColumns } from './columns'
import { transformColumnsToForm } from '@/utils'
const newColumns = _.cloneDeep(planNodeFormColumns)

export const planNodeFormList = transformColumnsToForm(newColumns)
export const adjustFormList = [
    {
      label: '计划名称',
      key: 'planName',
      tagName: 'el-input',
      required: false,
      props: { disabled: true }
    },
    {
        label: '计划类型',
        key: 'planTypeName',
        tagName: 'el-input',
        required: false,
        props: { disabled: true }
      },
    {
        label: '开始时间',
        key: 'startTime',
        tagName: 'el-date-picker',
        required: false,
        props: {
          clearable: true
        },
        props: { type: 'datetime',valueFormat: 'yyyy-MM-dd HH:mm:ss',
        format: 'yyyy-MM-dd HH:mm:ss',disabled: false }
      },
    {
      label: '完成时间',
      key: 'finishTime',
      tagName: 'el-date-picker',
      required: false,
      props: {
        clearable: true
      },
      props: { type: 'datetime',valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm:ss',disabled: false }
    },
    {
      label: '调整原因',
      key: 'adjustReason',
      tagName: 'el-input',
      props: {
        type: 'textarea'
      }
    }
  ]
