var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "test" },
    _vm._l(_vm.getTimeScales(_vm.day), function(i) {
      return _c("span", { key: i }, [_vm._v(" " + _vm._s(i.format("HH:mm")))])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }