<template>
  <div class="role-wrap">
    <div class="orders">
      <div class="top">
        <SearchForm
          ref="searchForm"
          :form-list-extend="searchForm"
          style="padding: 0"
          :form-data.sync="searchFormData"
          @search="reSearch"
        />

      </div>
      <FunctionHeader
        ref="functionHeader"
        v-model="functionHeader.searchVal"
        search-title="请输入物料编号/名称/规格"
        style="padding: 10px 0 10px 0;"
        class="func-header"
        :tags="functionHeader.tags"
        @search="$refs.mTable.setTableData(true)"
      />

      <MTable
        ref="mTable"
        class="table-left"
        :columns="columns"
        :height="height"
        :set-data-method="getTableData"
        :row-class-name="tableRowClassName"
        @selection-change="data=>selectData = data"
      >
        <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
        <div slot="isKeyMaterials" slot-scope="{row }">{{ options[row.isKeyMaterials]||'-' }}</div>
      </MTable>

    </div>

  </div>
</template>
<script>
import api from '@/api/qualityManagement/waitingBoard'
import FunctionHeader from '@/components/FunctionHeader'
import SearchForm from '@/components/SearchForm/SearchForm'
import { columns, searchForm } from './data'
import dayjs from 'dayjs'

export default {
  name: 'WaitingBoard',
  components: { FunctionHeader, SearchForm },
  data() {
    return {
      api,
      searchForm,
      searchFormData: {},
      functionHeader: {
        searchVal: '',
        tags: [
          {
            hidden: !this.permission('Export'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF', marginRight: '10px' },
            innerText: '全部导出',
            on: {
              click: this.batchExport
            }
          }
        ]
      },
      columns,
      selectData: [],
      options: {
        1: '是',
        0: '否'
      }
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 220 - 65
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      this.$refs.mTable.setTableData(true)
    }, 5 * 60 * 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },

  methods: {
    tableRowClassName({ row }) {
      if (row.retentionTime > 24) {
        return 'warning'
      }
      return ''
    },
    async batchExport() {
      const res = await api.exportWaitingBoard(this.functionHeader.exportParams)
      if (res && res.data.byteLength) {
        const urlP = window.URL.createObjectURL(
          new Blob([res.data], {
            type: 'application/octet-stream;charset=ISO8859-1'
          })
        )
        const fileName = `待检看板-${dayjs().format('YYYYMMDDHHmmss')}.xls`
        this.downloadFile(urlP, fileName)
      } else {
        this.$message.error('导出失败，内容为空')
      }
    },
    downloadFile(urlP, name) {
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = urlP
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    reSearch(data, val) {
      if (val === 1) {
        this.searchFormData = {}
      }
      this.$refs.mTable.setTableData(true)
    },

    // 列表
    async getTableData(condition, callback) {
      const { page, order } = condition
      const { productionTaskCode, producePlanNumber, planTime, procedure, isKeyMaterials, receivedNumber, warehouseName, warehouseLocationName, requisitionSourceNumber, purchaseOrderNumber, inspectionTime } = this.searchFormData
      const params = {
        producePlanNumber,
        materials: this.functionHeader.searchVal,
        productionTaskCode,
        procedure: procedure,
        isKeyMaterials,
        receivedNumber,
        warehouseName,
        warehouseLocationName,
        requisitionSourceNumber,
        purchaseOrderNumber,
        inspectionStartTime: inspectionTime ? inspectionTime[0] : null,
        inspectionEndTime: inspectionTime ? inspectionTime[1] : null,
        planStartTime: planTime ? planTime[0] : null,
        planEndTime: planTime ? planTime[1] : null,
        sort: order
      }
      this.functionHeader.exportParams = params
      const res = await api.getWaitingBoardList({
        page: page.currentPage,
        size: page.pageSize,
        ...params
      })
      if (res) {
      // res.records.forEach(item => {
      //   item.planTime = [item.planStartTime, item.planEndTime]
      // })
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    }
  }
}
</script>

<style scoped lang="scss">
.orders {
  padding: 10px;
}
::v-deep .el-table {
  .warning {
    background: rgba(216, 84, 84, 0.504) ;
  }
}
</style>
