import { status } from '@/config/options.config'

export const columns = [
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    hiddenSearch: true,
    slotName: 'index',
    fixed: 'left',
    width: 50
  },
  {
    prop: 'planName',
    label: '节点名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'isDefault',
    label: '是否系统默认节点',
    sortable: false,
    hiddenSearch: true,
    slotName: 'isDefault',
    minWidth:150,
    form: {
      index: 4,
      tagName: 'el-select',
      options: status,
      defaultValue: 1
    }
  },
  {
    prop: 'planTypeName',
    label: '节点类型',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'isTaskNode',
    label: '是否任务节点',
    sortable: false,
    slotName: 'isTaskNode',
    hiddenSearch: true
  },
  {
    prop: 'startTimeAttribute',
    label: '开始时间属性',
    sortable: false,
    hiddenSearch: true,
    slotName: 'startTimeAttribute'
  },
  {
    prop: 'finishTimeAttribute',
    label: '结束时间属性',
    sortable: false,
    hiddenSearch: true,
    slotName: 'finishTimeAttribute'
  },
  {
    prop: 'principalDepartName',
    label: '默认负责部门',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'principalName',
    label: '默认负责人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 160
  }
]
export const addColumns = [
  {
    type: 'selection',
    minWidth: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    hiddenSearch: true,
    slotName: 'index',
    width: 50
  },
  {
    prop: 'planName',
    label: '节点名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'planTypeName',
    label: '节点类型',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'isTaskNode',
    label: '是否任务节点',
    sortable: false,
    slotName:'isTaskNode',
    hiddenSearch: true
  },
  {
    prop: 'startTimeAttribute',
    label: '开始时间属性',
    sortable: false,
    hiddenSearch: true,
    slotName: 'startTimeAttribute'
  },
  {
    prop: 'finishTimeAttribute',
    label: '结束时间属性',
    sortable: false,
    hiddenSearch: true,
    slotName: 'finishTimeAttribute'
  },
  {
    prop: 'principalDepartName',
    label: '默认负责部门',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'principalName',
    label: '默认负责人',
    sortable: false,
    hiddenSearch: true
  }
]