var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "account-detail-wrapper" },
    [
      _c("div", { staticClass: "account-detail-header flex-sbc" }, [
        _c(
          "div",
          {
            staticClass: "left",
            on: {
              click: function($event) {
                return _vm.back()
              }
            }
          },
          [
            _c("img", {
              staticStyle: { width: "8px" },
              attrs: {
                src: require("@/assets/information/procedure/左滑@2x.png")
              }
            }),
            _c(
              "span",
              { staticStyle: { "padding-left": "10px", cursor: "pointer" } },
              [_vm._v("项目详情")]
            )
          ]
        )
      ]),
      _c("div", { staticClass: "account-detail-body" }, [
        _c(
          "div",
          {
            staticClass: "detail-card",
            staticStyle: { "margin-bottom": "20px" }
          },
          [
            _c("div", { staticClass: "card-tit" }, [_vm._v("项目基本信息")]),
            _c(
              "div",
              { staticClass: "card-bot", staticStyle: { padding: "15px" } },
              [
                _c(
                  "div",
                  [
                    _c(
                      "el-row",
                      {
                        staticClass: "view-list",
                        staticStyle: {
                          padding: "10px 15px",
                          "flex-flow": "row wrap"
                        },
                        attrs: { type: "flex", justify: "start", gutter: 24 }
                      },
                      _vm._l(_vm.columns, function(item, index) {
                        return _c(
                          "el-col",
                          {
                            key: item.prop,
                            style: { marginBottom: index !== 4 ? "20px" : 0 },
                            attrs: { span: 6 }
                          },
                          [
                            _c("span", { staticClass: "bot-tis" }, [
                              _vm._v(_vm._s(item.label) + "：")
                            ]),
                            _c("span", { staticClass: "bot-tis" }, [
                              _vm._v(_vm._s(_vm.getValue(item)))
                            ])
                          ]
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              ]
            )
          ]
        ),
        _c(
          "div",
          { staticStyle: { background: "#FFFFFF" } },
          [
            _c(
              "el-tabs",
              {
                staticClass: "detail-tabs",
                attrs: { type: "border-card" },
                on: { "tab-click": _vm.tabClick },
                model: {
                  value: _vm.params.radio,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "radio", $$v)
                  },
                  expression: "params.radio"
                }
              },
              [
                _c("el-tab-pane", {
                  attrs: { label: "节点", name: "PlanNode" }
                }),
                _c("el-tab-pane", {
                  attrs: { label: "工单", name: "ProjectWork" }
                }),
                _c("el-tab-pane", {
                  attrs: { label: "变更", name: "ProjectECN" }
                }),
                _c("el-tab-pane", {
                  attrs: { label: "异常", name: "ProjectAbnormal" }
                }),
                _c("el-tab-pane", {
                  attrs: { label: "用料记录", name: "MaterialRecords" }
                }),
                _c("el-tab-pane", {
                  attrs: { label: "齐套分析", name: "CompleteAnalysis" }
                }),
                _c("el-tab-pane", {
                  attrs: { label: "项目工时", name: "WorkHourStatistics" }
                })
              ],
              1
            ),
            [
              _c(
                "div",
                { staticClass: "bottom", staticStyle: { padding: "15px" } },
                [
                  _c("FunctionHeader", {
                    attrs: {
                      tags: [],
                      "search-title": _vm.searchTitle[_vm.params.radio],
                      isDialog: true
                    },
                    on: {
                      search: function($event) {
                        return _vm.$refs.mTable.setTableData(true)
                      }
                    },
                    model: {
                      value: _vm.functionHeader.searchVal,
                      callback: function($$v) {
                        _vm.$set(_vm.functionHeader, "searchVal", $$v)
                      },
                      expression: "functionHeader.searchVal"
                    }
                  }),
                  _vm.params.radio === "PlanNode"
                    ? _c("MTable", {
                        ref: "mTable",
                        attrs: {
                          "columns-key": _vm.columnsKey,
                          columns: _vm.tableColumns[_vm.params.radio],
                          "columns-setting": true,
                          "set-data-method": _vm.getTableData,
                          "default-expand-all": false,
                          "is-tree-data": false,
                          "tree-props": {
                            children: "children",
                            hasChildren: "hasChildren"
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "index",
                              fn: function(ref) {
                                var row = ref.row
                                return _c("div", {}, [
                                  _vm._v(
                                    _vm._s(!row.parentId ? row.sort + 1 : "")
                                  )
                                ])
                              }
                            },
                            {
                              key: "productionProgress",
                              fn: function(ref) {
                                var row = ref.row
                                return _c(
                                  "div",
                                  {},
                                  [
                                    _c("el-progress", {
                                      attrs: { percentage: row.processPercent }
                                    })
                                  ],
                                  1
                                )
                              }
                            },
                            {
                              key: "nodeAttribute",
                              fn: function(ref) {
                                var row = ref.row
                                return _c("div", {}, [
                                  row.nodeAttribute != null
                                    ? _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.planNodeAttribute[
                                              row.nodeAttribute
                                            ].name
                                          )
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              }
                            },
                            {
                              key: "principalName",
                              fn: function(ref) {
                                var row = ref.row
                                return _c(
                                  "div",
                                  {},
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: row.principalName,
                                          placement: "top-end"
                                        }
                                      },
                                      [
                                        _c("span", { staticClass: "mr-10" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(row.principalName) +
                                              " "
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              }
                            },
                            {
                              key: "projectPlanNodeAction",
                              fn: function(ref) {
                                var row = ref.row
                                var $index = ref.$index
                                return _c(
                                  "div",
                                  {},
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              row.type === 0 && row.status != 0,
                                            expression:
                                              "row.type === 0 && row.status != 0"
                                          }
                                        ],
                                        attrs: { type: "text" },
                                        on: {
                                          click: function($event) {
                                            return _vm.viewPlanNodeDetail(
                                              row,
                                              $index
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("任务详情")]
                                    )
                                  ],
                                  1
                                )
                              }
                            }
                          ],
                          null,
                          false,
                          1939850397
                        )
                      })
                    : _c("MTable", {
                        ref: "mTable",
                        attrs: {
                          "columns-key": _vm.columnsKey,
                          columns: _vm.tableColumns[_vm.params.radio],
                          "columns-setting": true,
                          "set-data-method": _vm.getTableData
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "index",
                            fn: function(ref) {
                              var $index = ref.$index
                              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                            }
                          },
                          {
                            key: "faultStatus",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "faultStatus",
                                    style: {
                                      background:
                                        _vm.faultStatus[row.status - 1]
                                          .background,
                                      color:
                                        _vm.faultStatus[row.status - 1].color
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.faultStatus[row.status - 1].name
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            }
                          },
                          {
                            key: "workOrderStatus",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.workStatus[row.workOrderStatus + 1]
                                    ) +
                                    " "
                                )
                              ])
                            }
                          },
                          {
                            key: "changeType",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.changeType[row.changeType]) +
                                    " "
                                )
                              ])
                            }
                          },
                          {
                            key: "status",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.abnormalOrderStatus[row.status]
                                    ) +
                                    " "
                                )
                              ])
                            }
                          },
                          {
                            key: "needOperate",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.needOperate[row.needOperate]) +
                                    " "
                                )
                              ])
                            }
                          },
                          {
                            key: "majorType",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.majorType[row.majorType]) +
                                    " "
                                )
                              ])
                            }
                          },
                          {
                            key: "emergencyDegree",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.emergencyDegree[row.emergencyDegree]
                                    ) +
                                    " "
                                )
                              ])
                            }
                          },
                          {
                            key: "judgeResult",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.judgeResult[row.judgeResult]) +
                                    " "
                                )
                              ])
                            }
                          },
                          {
                            key: "finalResult",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.finalResult[row.finalResult]) +
                                    " "
                                )
                              ])
                            }
                          },
                          {
                            key: "abnormalOrderCode",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("NewPageOpen", {
                                attrs: {
                                  path: {
                                    path: "/call/exception-handle/detail",
                                    query: { id: row.id }
                                  },
                                  text: row.abnormalOrderCode
                                }
                              })
                            }
                          },
                          {
                            key: "prePlanChangeNumber",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("NewPageOpen", {
                                attrs: {
                                  path: {
                                    name: "ChangeManagementDetail",
                                    query: { id: row.id }
                                  },
                                  text: row.prePlanChangeNumber
                                }
                              })
                            }
                          },
                          {
                            key: "receiptType",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(
                                  _vm._s(_vm._f("receiptType")(row.receiptType))
                                )
                              ])
                            }
                          },
                          {
                            key: "directionType",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(
                                  _vm._s(
                                    row.directionType === 0
                                      ? "工单领料"
                                      : row.directionType === 1
                                      ? "工单退料"
                                      : "工单挪料"
                                  )
                                )
                              ])
                            }
                          },
                          {
                            key: "receiptStatus",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("receiptStatus")(row.receiptStatus)
                                  )
                                )
                              ])
                            }
                          },
                          {
                            key: "missMaterialsRate",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _c("div", [
                                  _vm._v(_vm._s(row.missMaterialsRate) + "%")
                                ])
                              ])
                            }
                          },
                          {
                            key: "type",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.workHoursTypeMaps[row.type] || "-"
                                    ) +
                                    " "
                                )
                              ])
                            }
                          },
                          {
                            key: "sourceType",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.sourceTypeMaps[row.sourceType] || "-"
                                    ) +
                                    " "
                                )
                              ])
                            }
                          },
                          {
                            key: "userType",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      ["正式", "外包"][row.userType] || "-"
                                    ) +
                                    " "
                                )
                              ])
                            }
                          },
                          {
                            key: "quantity",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                !row.isEdit
                                  ? _c("div", [_vm._v(_vm._s(row.quantity))])
                                  : _c("el-input", {
                                      model: {
                                        value: row.quantity,
                                        callback: function($$v) {
                                          _vm.$set(row, "quantity", $$v)
                                        },
                                        expression: "row.quantity"
                                      }
                                    })
                              ]
                            }
                          },
                          {
                            key: "errorCount",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _c(
                                  "div",
                                  { staticStyle: { color: "#e02020" } },
                                  [_vm._v(_vm._s(_vm.getErrorCount(row)))]
                                )
                              ])
                            }
                          },
                          {
                            key: "unMaintainCount",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _c(
                                  "div",
                                  { staticStyle: { color: "#e02020" } },
                                  [_vm._v(_vm._s(_vm.getUnMaintainCount(row)))]
                                )
                              ])
                            }
                          },
                          {
                            key: "principalName",
                            fn: function(ref) {
                              var row = ref.row
                              return _c(
                                "div",
                                {},
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        effect: "dark",
                                        content: row.principalName,
                                        placement: "top-end"
                                      }
                                    },
                                    [
                                      _c("span", { staticClass: "mr-10" }, [
                                        _vm._v(
                                          " " + _vm._s(row.principalName) + " "
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            }
                          },
                          {
                            key: "adjustRecord",
                            fn: function(ref) {
                              var row = ref.row
                              return _c(
                                "div",
                                {},
                                [
                                  row.nodeAttribute === 1 ||
                                  row.nodeAttribute === 3
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function($event) {
                                              return _vm.viewAdjustRecord(row)
                                            }
                                          }
                                        },
                                        [_vm._v("查看")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            }
                          },
                          {
                            key: "workAction",
                            fn: function(ref) {
                              var row = ref.row
                              var $index = ref.$index
                              return _c(
                                "div",
                                {},
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "text",
                                        disabled:
                                          row.workOrderStatus != -1 &&
                                          row.workOrderStatus != 0
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.viewMaterialsDetail(
                                            row,
                                            $index
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("物料详情")]
                                  )
                                ],
                                1
                              )
                            }
                          },
                          {
                            key: "projectEcnAction",
                            fn: function(ref) {
                              var row = ref.row
                              var $index = ref.$index
                              return _c(
                                "div",
                                {},
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.viewEcnChangeDetail(
                                            row,
                                            $index
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("变更详情")]
                                  )
                                ],
                                1
                              )
                            }
                          },
                          {
                            key: "projectAbnormalAction",
                            fn: function(ref) {
                              var row = ref.row
                              var $index = ref.$index
                              return _c(
                                "div",
                                {},
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.viewAbnormalDetail(
                                            row,
                                            $index
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("异常详情")]
                                  )
                                ],
                                1
                              )
                            }
                          }
                        ])
                      })
                ],
                1
              )
            ]
          ],
          2
        )
      ]),
      _c("select-materials", {
        attrs: {
          visible: _vm.showMaterials,
          "select-materials": _vm.selectedMaterialList,
          single: false
        },
        on: {
          "update:visible": function($event) {
            _vm.showMaterials = $event
          },
          submitForm: _vm.submitMaterials
        }
      }),
      _c(
        "MDialog",
        {
          attrs: { title: _vm.planNodetitles[_vm.currentType] },
          on: { onOk: _vm.submitForm },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "MFormBuilder",
            {
              ref: "formBuild",
              attrs: {
                "form-data": _vm.formData,
                "form-list": _vm.planNodeFormList
              }
            },
            [
              _c(
                "div",
                {
                  attrs: { slot: "processFlowClassName" },
                  slot: "processFlowClassName"
                },
                [
                  _c("CURDSelect", {
                    attrs: {
                      "default-value": _vm.formData.planTypeId,
                      "api-name": "PlanType"
                    },
                    on: { change: _vm.selectName }
                  })
                ],
                1
              ),
              _c(
                "div",
                { attrs: { slot: "startTime" }, slot: "startTime" },
                [
                  _c("el-date-picker", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showStartTime,
                        expression: "showStartTime"
                      }
                    ],
                    staticStyle: { width: "100%" },
                    attrs: {
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      format: "yyyy-MM-dd HH:mm:ss",
                      placeholder: "开始时间",
                      disabled: _vm.isChooseStartAttr
                    },
                    model: {
                      value: _vm.planStartTime,
                      callback: function($$v) {
                        _vm.planStartTime = $$v
                      },
                      expression: "planStartTime"
                    }
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.showStartTime,
                          expression: "!showStartTime"
                        }
                      ],
                      staticStyle: { display: "flex" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: "请选择",
                            clearable: "",
                            filterable: ""
                          },
                          on: { change: _vm.changeStartTimePlan },
                          model: {
                            value: _vm.startPlanId,
                            callback: function($$v) {
                              _vm.startPlanId = $$v
                            },
                            expression: "startPlanId"
                          }
                        },
                        _vm._l(_vm.planNodeTimeList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.fullName, value: item.id }
                          })
                        }),
                        1
                      ),
                      _c("el-input-number", {
                        staticClass: "min-input",
                        staticStyle: { "margin-left": "10px" },
                        attrs: { "controls-position": "right" },
                        model: {
                          value: _vm.startTimeBasis,
                          callback: function($$v) {
                            _vm.startTimeBasis = $$v
                          },
                          expression: "startTimeBasis"
                        }
                      }),
                      _vm._v("天 ")
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { attrs: { slot: "finishTime" }, slot: "finishTime" },
                [
                  _c("el-date-picker", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showFinishTime,
                        expression: "showFinishTime"
                      }
                    ],
                    staticStyle: { width: "100%" },
                    attrs: {
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      format: "yyyy-MM-dd HH:mm:ss",
                      defaultTime: "23:59:59",
                      placeholder: "完成时间",
                      disabled: _vm.isChooseFinishAttr
                    },
                    model: {
                      value: _vm.planFinishTime,
                      callback: function($$v) {
                        _vm.planFinishTime = $$v
                      },
                      expression: "planFinishTime"
                    }
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.showFinishTime,
                          expression: "!showFinishTime"
                        }
                      ],
                      staticStyle: { display: "flex" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: "请选择",
                            clearable: "",
                            filterable: ""
                          },
                          on: { change: _vm.changeFinishTimePlan },
                          model: {
                            value: _vm.finishPlanId,
                            callback: function($$v) {
                              _vm.finishPlanId = $$v
                            },
                            expression: "finishPlanId"
                          }
                        },
                        _vm._l(_vm.planNodeTimeList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.fullName, value: item.id }
                          })
                        }),
                        1
                      ),
                      _c("el-input-number", {
                        staticClass: "min-input",
                        staticStyle: { "margin-left": "10px" },
                        attrs: { "controls-position": "right" },
                        model: {
                          value: _vm.finishTimeBasis,
                          callback: function($$v) {
                            _vm.finishTimeBasis = $$v
                          },
                          expression: "finishTimeBasis"
                        }
                      }),
                      _vm._v("天 ")
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "调整计划节点" },
          on: { onOk: _vm.submitAdjustForm },
          model: {
            value: _vm.adjustVisible,
            callback: function($$v) {
              _vm.adjustVisible = $$v
            },
            expression: "adjustVisible"
          }
        },
        [
          _c("MFormBuilder", {
            ref: "adjustForm",
            attrs: {
              "form-data": _vm.formData,
              "form-list": _vm.adjustFormList
            }
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "调整记录", "show-footer": false },
          model: {
            value: _vm.viewAdjustRecordVisible,
            callback: function($$v) {
              _vm.viewAdjustRecordVisible = $$v
            },
            expression: "viewAdjustRecordVisible"
          }
        },
        [
          _c("MTable", {
            attrs: {
              columns: _vm.adjustRecordColumns,
              height: 500,
              data: _vm.viewAdjustRecordList,
              "show-page": false,
              "columns-setting": false
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }