var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderAdd" },
    [
      _c("div", { staticClass: "procedure-detail-header" }, [
        _c(
          "div",
          {
            staticClass: "left",
            on: {
              click: function($event) {
                return _vm.$router.push({ name: "QualityTaskList" })
              }
            }
          },
          [
            _c("img", {
              attrs: {
                src: require("@/assets/information/procedure/左滑@2x.png")
              }
            }),
            _c("span", [_vm._v("质检任务详情")])
          ]
        )
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("任务编号：" + _vm._s(_vm.detailData.code))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v(
                  "业务类型：" +
                    _vm._s(_vm.inspectionTypeNames[_vm.detailData.type])
                )
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v(
                  "质检方案：" + _vm._s(_vm.detailData.inspectionSchemeName)
                )
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v(
                  "质检状态：" +
                    _vm._s(
                      _vm.detailData.status || _vm.detailData.status == 0
                        ? _vm.qualityTaskStatus[_vm.detailData.status].name
                        : ""
                    )
                )
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("生产工单：" + _vm._s(_vm.detailData.producePlanNumber))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("生产任务：" + _vm._s(_vm.detailData.productionTaskCode))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("工序：" + _vm._s(_vm.detailData.procedureName))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("创建人：" + _vm._s(_vm.detailData.createUser))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("创建时间：" + _vm._s(_vm.detailData.createTime))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("任务开始时间：" + _vm._s(_vm.detailData.startTime))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("任务结束时间：" + _vm._s(_vm.detailData.endTime))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("执行人：" + _vm._s(_vm.detailData.executorName))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("结论判定： "),
                _c(
                  "span",
                  {
                    style: {
                      color:
                        _vm.detailData.result == 0
                          ? "#E02020"
                          : _vm.detailData.result == 1
                          ? "#00AB29"
                          : ""
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.detailData.result == 0
                            ? "不合格"
                            : _vm.detailData.result == 1
                            ? "合格"
                            : ""
                        ) +
                        " "
                    )
                  ]
                )
              ])
            ]),
            _vm.detailData.result == 0
              ? _c("el-col", { attrs: { span: 6 } }, [
                  _c("span", { staticClass: "bot-ti" }, [
                    _vm._v("处理意见：" + _vm._s(_vm.detailData.suggestion))
                  ])
                ])
              : _vm._e()
          ],
          1
        )
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("检验项记录")]),
        _c(
          "div",
          { staticClass: "tables" },
          [
            _vm.projectLength > 0
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "14px",
                        "padding-bottom": "10px"
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-arrow-left",
                        style: {
                          color: _vm.currentPage == 1 ? "#C0C4CC" : "#303133",
                          cursor:
                            _vm.currentPage === 1 ? "not-allowed" : "pointer"
                        },
                        on: {
                          click: function($event) {
                            return _vm.beforePage()
                          }
                        }
                      }),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            display: "inline-block",
                            width: "35px",
                            "text-align": "center"
                          }
                        },
                        [_vm._v(_vm._s(this.currentPage))]
                      ),
                      _c("i", {
                        staticClass: "el-icon-arrow-right",
                        style: {
                          color:
                            _vm.currentPage == _vm.projectLength
                              ? "#C0C4CC"
                              : "#303133",
                          cursor:
                            _vm.currentPage == _vm.projectLength
                              ? "not-allowed"
                              : "pointer"
                        },
                        on: {
                          click: function($event) {
                            return _vm.nextPage()
                          }
                        }
                      }),
                      _c("span", { staticStyle: { color: "#6B8AFF" } }, [
                        _vm._v(" 第" + _vm._s(_vm.currentPage) + "份 ")
                      ]),
                      _c("span", { staticStyle: { color: "#909399" } }, [
                        _vm._v("/共" + _vm._s(_vm.projectLength) + "份")
                      ])
                    ]
                  )
                ])
              : _vm._e(),
            _c("MTable", {
              ref: "mTable",
              attrs: {
                "show-page": false,
                height: 410,
                columns: _vm.columnsDetail,
                data: _vm.productList
              },
              scopedSlots: _vm._u([
                {
                  key: "index",
                  fn: function(ref) {
                    var $index = ref.$index
                    return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                  }
                },
                {
                  key: "attachments",
                  fn: function(ref) {
                    var row = ref.row
                    return _c("div", {}, [
                      row.attachments
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                color: "#6B8AFF",
                                cursor: "pointer"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.viewPicture(row)
                                }
                              }
                            },
                            [_vm._v("查看")]
                          )
                        : _c("div", [_vm._v("-")])
                    ])
                  }
                },
                {
                  key: "measuredValue",
                  fn: function(ref) {
                    var row = ref.row
                    return _c("div", {}, [
                      _c(
                        "span",
                        { style: { color: _vm.fixData(row) ? "#E02020" : "" } },
                        [_vm._v(_vm._s(_vm._f("fixValue")(row)))]
                      )
                    ])
                  }
                },
                {
                  key: "materialsCode",
                  fn: function(ref) {
                    var row = ref.row
                    return _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.viewMaterialsDetail(row.materialsId)
                          }
                        }
                      },
                      [_vm._v(_vm._s(row.materialsCode))]
                    )
                  }
                }
              ])
            })
          ],
          1
        )
      ]),
      _c(
        "MDialog",
        {
          attrs: {
            title: "现场图片",
            width: "600px",
            height: "500px",
            "show-footer": false
          },
          on: {
            onOk: function($event) {
              _vm.pictureVisible = false
            }
          },
          model: {
            value: _vm.pictureVisible,
            callback: function($$v) {
              _vm.pictureVisible = $$v
            },
            expression: "pictureVisible"
          }
        },
        _vm._l(_vm.formatArr(_vm.imgList), function(item) {
          return _c("el-image", {
            key: item,
            staticClass: "table-row-img",
            staticStyle: { "padding-left": "10px" },
            attrs: { src: item, "preview-src-list": _vm.formatArr(_vm.imgList) }
          })
        }),
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "工艺路线", "show-footer": false },
          on: {
            onOk: function($event) {
              _vm.processVisible = false
            }
          },
          model: {
            value: _vm.processVisible,
            callback: function($$v) {
              _vm.processVisible = $$v
            },
            expression: "processVisible"
          }
        },
        [
          _c("single-process-flow", {
            ref: "processFlow",
            attrs: {
              "current-procedure-names": _vm.currentProcedureName,
              "read-only": !_vm.saveButton,
              "all-work-order-task-list": _vm.allWorkTask ? _vm.allWorkTask : []
            }
          })
        ],
        1
      ),
      _c("edit-materials", { ref: "editMaterials" }),
      _c("select-work-order", {
        ref: "selectWorkOrder",
        attrs: { visible: _vm.showWorkOrder, "produce-order-id": _vm.id },
        on: {
          "update:visible": function($event) {
            _vm.showWorkOrder = $event
          },
          submitForm: _vm.submitWorkOrder
        }
      }),
      _c("select-plan-order", {
        ref: "selectPlanOrder",
        attrs: { visible: _vm.showPlanOrder, "produce-order-id": _vm.id },
        on: {
          "update:visible": function($event) {
            _vm.showPlanOrder = $event
          },
          submitForm: _vm.submitPlanOrder
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }