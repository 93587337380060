<template>
    <div class="name">
      <span style="display:inline-block;width:214px;">
    <span @click="selectLine(data)" style="color:#607FFF">{{ data.projectNumber }}</span>
    <span> / {{data.projectName}} </span>
      </span>
      <el-popover
          placement="right"
          visible-arrow="false"
          popper-class="popover"
          trigger="click">
          <div style="display:flex;text-align:center;vertical-align:center;cursor:pointer">
          <div class="slice-num" @click="viewAbnormalList" style="background-color: #FA6400;border-top-left-radius: 4px;border-bottom-left-radius: 4px;">{{data.pie.abnormalCount}}</div>
          <div class="slice-num" @click="viewECNList" style="background-color: #A5A7BF;">{{data.pie.ecnCount}}</div>
          <div class="slice-num" @click="viewMaterialsDiffList" style="background-color: #5C72CD;border-top-right-radius: 4px;border-bottom-right-radius: 4px;">{{data.pie.materialsDifferentCount}}</div>
          </div>
          <div slot="reference" class="pie-chart" v-if="data.pie.abnormalCount || data.pie.ecnCount || data.pie.materialsDifferentCount">
          <el-tooltip placement="top" popper-class="plan-tooltip" content="未关闭的异常流程">
              <div class="slice" :style="{display: !data.pie.abnormalCount ? 'none':'block'}"></div>
          </el-tooltip>
          <el-tooltip placement="right" popper-class="plan-tooltip" content="未执行的ECN">
        <div class="slice" :style="{display: !data.pie.ecnCount ? 'none':'block'}"></div>
      </el-tooltip>
      <el-tooltip placement="left-end" popper-class="plan-tooltip" content="工单用料差异">
        <div class="slice" :style="{display: !data.pie.materialsDifferentCount ? 'none':'block'}" ></div>
      </el-tooltip>
      </div>
        </el-popover>
    </div>
</template>

<script>
export default {
  name: 'TestLeft',
  props: {
    data: Object
  },
  methods: {
    selectLine(datas) {
      this.$emit('selectLine', datas)
    },
    viewAbnormalList(){
      this.$emit('viewAbnormalList', this.data.id)
    },
    viewECNList(){
      this.$emit('viewECNList', this.data.id)
    },
    viewMaterialsDiffList(){
      this.$emit('viewMaterialsDiffList', this.data.id)
    }
  }
}
</script>

<style scoped lang="scss">
.name {
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
  height: calc(100% + 1px);
  width: 100%;
  padding: 0 10px;
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  border-bottom: 1px solid #ececec;
  cursor: pointer;
  &:hover {
    background: rgba(230, 232, 239, 0.8);
  }
  span {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 500;
    color: #393D60;
    line-height: 20px;
    word-wrap: break-word;
  }

  .pie-chart {
  position: relative;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  // background-color: #f0f0f0;
  overflow: hidden;
}
.slice {
  position: absolute;
  width: 100%;
  height: 100%;
  clip-path: polygon(50% 50%, -140% 0, 100% 0);
}

.slice:nth-child(1) {
  background-color: #FA6400; /* 第一个扇形区域的颜色 */
  transform: rotate(0deg);
}

.slice:nth-child(2) {
  background-color: #A5A7BF; /* 第二个扇形区域的颜色 */
  transform: rotate(120deg);
}

.slice:nth-child(3) {
  background-color: #5C72CD; /* 第三个扇形区域的颜色 */
  transform: rotate(240deg);
}
}

.colorBar {
  width: 10px;
  height: 100%;
}

.carId {
  flex: 1;
}

.type {
  padding: 0 5px 0 0;
  font-size: 1.2rem;
}
</style>
<style lang="scss">
  .popover {
     padding: 0!important;
  }
  
.slice-num {
  color:#ffffff;
  min-width:50px;
  padding:8px;
}
  .popover[x-placement^=right] .popper__arrow::after{
    border-right-color: #FA6400 !important;
   }
  .plan-tooltip.is-dark {
    background-color: rgba(50, 50, 50, 0.7);
    color: #FFF;
  }
  .plan-tooltip[x-placement^=top] .popper__arrow{
    display:none
  }
  .plan-tooltip[x-placement^=right] .popper__arrow{
    display:none
  }
  .plan-tooltip[x-placement^=left-end] .popper__arrow{
    display:none
  }
</style>
