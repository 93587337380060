<template>
  <div class="materials-wrap common-tab">
    <el-radio-group v-model="params.radio" class="m-radio-group" @change="radioChange" style="display:none;">
      <el-radio-button label="DefectsItem">缺陷项</el-radio-button>
      <el-radio-button label="DefectsReason">缺陷原因</el-radio-button>
    </el-radio-group>
    <FunctionHeader
      v-model="functionHeader.searchVal"
      :tags="functionHeader.tags"
      :search-title="searchTitle"
      :radio="params.radio"
      :import-name="functionHeader.importName"
      :export-name="functionHeader.exportName"
      :columns-key="columnsKey"
      @search="$refs.mTable.setTableData(true)"
    />
    <MTable
      ref="mTable"
      :columns="columns[params.radio]"
      :height="height"
      :columns-key="columnsKey"
      :set-data-method="getTableData"
    >
      <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
      <div slot="isEnabled" slot-scope="{ row }" class="is-enabled">
        <div :style="{background: row.isEnabled ? '#24CAD7' : '#FFCC78'}" />
        <div>{{ row.isEnabled ? '启用' : '停用' }}</div>
      </div>
      <div slot="action" slot-scope="{ row }">
        <el-button v-if="permission(params.radio + 'Copy')" type="text" @click="modifyOrCopy(row, 'copy')">复制</el-button>
        <el-divider v-if="permission(params.radio + 'Copy')" direction="vertical" />
        <el-button v-if="permission(params.radio + 'Status')" type="text" @click="switchStatus(row, params.radio)">{{ row.isEnabled ? '停用' : '启用' }}</el-button>
        <el-divider v-if="permission(params.radio + 'Status')" direction="vertical" />
        <el-dropdown @command="(command) => { more(row, command) }">
          <span class="el-dropdown-link">
            更多<i class="el-icon-arrow-down el-icon--right" />
          </span>
          <el-dropdown-menu slot="dropdown" class="td-dropdown-menu">
            <el-dropdown-item v-if="permission(params.radio + 'Modify')" command="modify">修改</el-dropdown-item>
            <el-dropdown-item v-if="permission(params.radio + 'Del')" command="del">删除</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </MTable>

    <MDialog v-model="visible" :title="messages[currentType]" @onOk="submitForm">
      <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList[params.radio]" />
    </MDialog>
  </div>
</template>

<script>
/** write by luomingzhi */
import FunctionHeader from '@/components/FunctionHeader/index'
import columns from './columns'
import formList from './form-list'
import api from '@/api/quality/defects'
import conventionMixin from '@/mixin/conventional-page'

export default {
  name: 'defectsManage',
  components: { FunctionHeader },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      params: {
        radio: 'DefectsItem'
      },
      functionHeader: {
        searchVal: '',
        tags: [],
        importName: 'defectsItem',
        exportName: 'exportDefectsItem'
      },
      tags: [
        {
          hidden: !this.permission('DefectsItemAdd'),
          tagName: 'el-button',
          props: { type: 'primary', size: 'small' },
          style: { width: 'auto', background: '#607FFF' },
          innerText: '新增',
          on: {
            click: this.add
          }
        }
      ],
      columns,
      visible: false,
      formList: formList,
      formData: {},
      currentType: 'add',
      searchTitle: '请输入缺陷项名称'
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 10
    },
    columnsKey() {
      return this.$route.name + this.params.radio
    }
  },
  created(){
    this.params.radio = this.$route.meta.nameFlage ? this.$route.meta.nameFlage : 'DefectsItem'
  },
  mounted() {
    this.functionHeader.tags = this.tags
  },
  methods: {
    // 更多操作
    more(row, command) {
      const functions = {
        modify: () => { this.modifyOrCopy(row, command) },
        del: () => { this.del(row) }
      }
      functions[command]()
    },
    radioChange(label) {
      const flag = label === 'DefectsItem'
      Object.assign(this.functionHeader, {
        importName: flag ? 'defectsItem' : 'defectsReason',
        exportName: flag ? 'exportDefectsItem' : 'exportDefectsReason'
      })
      this.functionHeader.tags = this.tags
      switch (this.params.radio) {
        case 'DefectsItem':
          this.searchTitle = '请输入缺陷项名称'
          break
        case 'DefectsReason':
          this.searchTitle = '请输入缺陷原因名称'
          break
        default:
          break
      }
      if (this.functionHeader.tags[0]) {
        this.functionHeader.tags[0].hidden = !this.permission(label + 'Add')
      }
      this.$refs.mTable.setTableData(true)
    },
    createSetData(raw) {
      return {
        name: raw.name,
        isEnabled: raw.isEnabled,
        remark: raw.remark
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    }
  }
}
</script>

<style scoped lang="scss">
.materials-wrap {
  padding: 10px;
}
</style>
