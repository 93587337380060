import { startFinishTimeAttribute,projectDeliveryType} from '@/config/options.config'
import { executionDepartment } from '@/views/plans/prod-plan-lists/data'
let functionList = {}
export const getFunctionList = val => {
  functionList = val
}
export const columns = [
    {
      prop: 'projectNumber',
      label: '项目编号'
    },
    {
      prop: 'projectName',
      label: '项目名称'
    },
    {
      prop: 'customerOrderNumber',
      label: '客户订单号'
    },
    {
      prop: 'customerName',
      label: '客户'
    },
    {
      prop: 'customerPrincipalName',
      label: '客户负责人'
    },
    {
      prop: 'deliveryType',
      label: '交付类型',
      form: { options: projectDeliveryType }
    },
    {
      prop: 'deliveryDate',
      label: '交付日期'
    },
    {
      prop: 'principalName',
      label: '负责人'
    },
    {
      prop: 'businessUnitName',
      label: '业务部门'
    },
    {
      prop: 'executiveUnit',
      label: '执行部门',
      form: { options: executionDepartment }
    },
    {
      prop: 'projectApprovalNumber',
      label: '立项编号'
    },
  ]
    // 节点信息列表字段
    export const scheduleColumn = [
      {
        prop: 'index',
        label: '序号',
        slotName: 'index',
        hiddenSearch: true,
        fixed: 'left',
        type: '',
        width: 60
      },
      {
        prop: 'planName',
        label: '节点名称',
        sortable: false,
        hiddenSearch: true,
        slotName:'planName'
      },
      {
        prop: 'planTypeName',
        label: '节点类型',
        sortable: false,
        hiddenSearch: true
      },
      {
        prop: 'isTaskNode',
        label: '是否任务节点',
        slotName:'isTaskNode',
        sortable: false,
        hiddenSearch: true
      },
      {
        prop: 'startTimeAttribute',
        label: '开始时间属性',
        sortable: false,
        hiddenSearch: true,
        slotName: 'startTimeAttribute'
      },
      {
        prop: 'startTime',
        label: '计划开始时间',
        sortable: false,
        hiddenSearch: true,
        slotName:'startTime'
      },
      {
        prop: 'finishTimeAttribute',
        label: '结束时间属性',
        sortable: false,
        hiddenSearch: true,
        slotName: 'finishTimeAttribute'
      },
      {
        prop: 'finishTime',
        label: '计划结束时间',
        sortable: false,
        hiddenSearch: true,
        slotName:'finishTime'
      },
      {
        prop: 'principalDepartName',
        label: '负责部门',
        sortable: false,
        hiddenSearch: true
      },
      {
        prop: 'principalName',
        label: '负责人',
        sortable: false,
        hiddenSearch: true
      },
      {
        prop: 'remark',
        label: '备注',
        sortable: false,
        hiddenSearch: true
      },
      {
        slotName: 'action',
        label: '操作',
        fixed: 'right',
        sortable: false,
        hiddenSearch: true,
        width: 160
      }
    ]
// 详情基本信息字段
export const detailBaseFields = [
    {
      key: 'name',
      name: '项目计划方案名称：'
    },
    {
      key: 'isEnabled',
      name: '项目计划方案状态：'
    },
    {
      key: 'remark',
      name: '备注：'
    }
  ]
  // 方案新增节点字段
  export const productionTaskColumn = [
    {
      prop: 'index',
      label: '序号',
      slotName: 'index',
      hiddenSearch: true,
      fixed: 'left',
      width: 60
    },
    {
      prop: 'planObjectName',
      label: '计划对象',
      sortable: false,
      hiddenSearch: true,
      width: 120,
      form: {
        tagName: 'el-select',
        key:'planObjectId',
        options: [{id:0,name:'项目'}]
    }
    },
    {
      label: '计划名称',
      sortable: false,
      prop: 'planName',
      hiddenSearch: true,
      width: 120,
      form: { tagName: 'el-input', required: true}
    },
    {
      prop: 'planTypeName',
      label: '计划类型',
      sortable: false,
      hiddenSearch: true,
      width: 120,
      form: {
        colSpan:24,
        tagName: 'div',
        required: true,
        attrs:{class:'module-content'},
        customSlots: ['processFlowClassName']
      }
    },
    {
      prop: 'materialsCode',
      label: '节点属性',
      sortable: false,
      hiddenSearch: true,
      width: 120
    },
    {
      prop: 'startTimeAttribute',
      label: '开始时间属性',
      sortable: false,
      hiddenSearch: true,
      slotName:'startTimeAttribute',
      form: {
        tagName: 'el-select',
        options: startFinishTimeAttribute,
        required: true,
        on: { change: (val) => { functionList.startTimeAttributeChange(val) } }
      }
    },
    {
      prop: 'finishTimeAttribute',
      label: '完成时间属性',
      sortable: false,
      hiddenSearch: true,
      minWidth: 140,
      slotName:'finishTimeAttribute',
      form: {
        tagName: 'el-select',
        options: startFinishTimeAttribute, required: true,
        on: { change: (val) => { functionList.finishTimeAttributeChange(val) } }
      }
    },
    {
      prop: 'action',
      label: '操作',
      hiddenSearch: true,
      sortable: false,
      width: 120,
      slotName: 'action',
      fixed: 'right'
    }
  ]
  // 节点详情字段
  export const applyColumn = [
      {
        prop: 'index',
        label: '序号',
        slotName: 'index',
        hiddenSearch: true,
        fixed: 'left',
        type:'',
        width: 60
      },
      {
        prop: 'planObjectName',
        label: '计划对象',
        sortable: false,
        hiddenSearch: true,
        type:'',
        minWidth: 120
      },
      {
        label: '计划名称',
        sortable: false,
        prop: 'planName',
        hiddenSearch: true,
        slotName:'planName',
        minWidth: 120
      },
      {
        prop: 'planTypeName',
        label: '计划类型',
        sortable: false,
        hiddenSearch: true,
        minWidth: 120
      },
      {
        prop: 'nodeAttribute',
        label: '节点属性',
        sortable: false,
        hiddenSearch: true,
        slotName:'nodeAttribute',
        minWidth: 120
      },
      {
        prop: 'startTimeAttribute',
        label: '开始时间属性',
        sortable: false,
        hiddenSearch: true,
        slotName:'startTimeAttribute'
      },
      {
        prop: 'finishTimeAttribute',
        label: '完成时间属性',
        sortable: false,
        hiddenSearch: true,
        minWidth: 140,
        slotName:'finishTimeAttribute'
      },
      {
        prop: 'action',
        label: '操作',
        hiddenSearch: true,
        sortable: false,
        width: 120,
        slotName: 'action',
        fixed: 'right'
      }
    ]
export const logColumn = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    width: 60
  },
  {
    label: '变更时间',
    sortable: false,
    prop: 'lastUpdateTime',
    hiddenSearch: true,
    width: 160
  },
  {
    prop: 'adjustContent',
    label: '变更内容',
    sortable: false,
    slotName:'adjustContent',
    hiddenSearch: true
  },
  {
    prop: 'updater',
    label: '变更人',
    sortable: false,
    hiddenSearch: true,
    width: 120
  }
]
