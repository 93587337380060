<template>
  <div class="home-wrap">
    <template>
      <div v-loading="loading" class="container-top common-pad">
        <div class="order-left">
          <div class="left-tit">
            <div class="title">当前相关任务数</div>
            <div class="des">{{ orderInfo.doingCount }}</div>
          </div>
          <div class="left-cen">
            <div v-for="item in allStatus" :key="item.id" class="cen-li">
              <div class="tip" :style="{ background: item.color}" />
              <div class="tit">{{ item.name }}：</div>
              <div class="desc" :style="{ color: item.color}">{{ item.count }}</div>
            </div>
          </div>
          <div class="left-bot">
            <div class="tip" :style="{width: fixData(allStatus[0].count) + '%' }">
              <div>{{ fixData(allStatus[0].count) }}%</div>
              <span />
            </div>

            <div class="tit" :style="{width: fixData(allStatus[1].count) + '%' }">
              <div />
              <span />
              {{ fixData(allStatus[1].count) }}%
            </div>
            <div class="desc" :style="{width: fixData(allStatus[2].count) + '%' }">
              <div>{{ fixData(allStatus[2].count) }}%</div>
              <span />
            </div>
          </div>
        </div>
        <div v-loading="loading" class="order-cen">
          <div class="tit">相关项目开工情况</div>
          <ve-pie
            :data="chartProjectStart"
            :extend="pieChartProjectStart"
            width="100%"
            height="230px"
            style="margin: 0 auto"
          />
        </div>
        <div class="order-right">
          <div class="right-tit">个人完成任务情况</div>
          <div v-for="item in allOrderNums" :key="item.id" class="right-bot">
            <div class="tip">{{ item.name }}</div>
            <div class="des">{{ item.count }}</div>
          </div>
        </div>
      </div>
      <div class="container-center common-pad">
        <div class="work-left">
          <div class="common-tit">
            <div class="tit">任务执行数量统计</div>
            <el-radio-group v-model="params.radio[0]" class="m-radio-group m-radio-groups" @change="radioChange(0)">
              <el-radio-button v-for="item in allDays" :key="item.id" :label="item.label">{{ item.name }}</el-radio-button>
            </el-radio-group>
          </div>
          <div class="left-bot">
            <div class="bot-li">
              <div class="tit">已完成任务数量</div>
              <div class="count">{{ taskExecutionInfo.finishCount }}</div>
            </div>
            <div class="bot-li">
              <div class="tit">计划完成任务数量</div>
              <div class="count">{{ taskExecutionInfo.planFinishCount }}</div>
            </div>
            <div class="bot-li" style="background: rgba(0, 171, 41, 0.1);margin-right: 0">
              <img src="@/assets/home/inspect.png" class="tip">
              <div class="tit">达成率</div>
              <div class="count" style="color: #00B42E;">{{ taskExecutionInfo.finishRatio }}%</div>
            </div>
            <div class="bot-li">
              <div class="tit">已延期任务数量</div>
              <div class="count">{{ taskExecutionInfo.delayCount }}</div>
            </div>
            <div class="bot-li">
              <div class="tit">3天后延期任务数量</div>
              <div class="count">{{ taskExecutionInfo.delayCountFor3days }}</div>
            </div>
            <div class="bot-li" style="background: rgba(224, 32, 32, 0.1);margin-right: 0">
              <img src="@/assets/home/no-inspect.png" class="tip">
              <div class="tit">延期率</div>
              <div class="count" style="color: #E02020;">{{ taskExecutionInfo.delayRatio }}%</div>
            </div>
          </div>
        </div>
        <div v-loading="loading" class="work-center">
          <div class="all-title">今日待处理任务<span class="title-count">（{{ todayWaitingTaskList.length }}）</span></div>
          <MTable ref="mTable" :data="todayWaitingTaskList" :height="220" :columns="todayWaitingTaskColumns" :columns-setting="false" :show-page="false">
            <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
            <div slot="action" slot-scope="{ row }">
              <el-button type="text" @click="taskPlanNodeDetail(row)">详情</el-button>
            </div>
          </MTable>
        </div>
        <div v-loading="loading" class="work-right">
          <div class="all-title">本周新增任务<span class="title-count">（{{ thisWeekAddTaskList.length }}）</span></div>
          <MTable ref="mTable" :data="thisWeekAddTaskList" :height="220" :columns="thisWeekAddTaskColumns" :columns-setting="false" :show-page="false">
            <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
            <div slot="action" slot-scope="{ row }">
              <el-button type="text" @click="taskPlanNodeDetail(row)">详情</el-button>
            </div>
          </MTable>
        </div>
      </div>
      <!--<div class="container-footer common-pad">-->
        <div v-loading="loading" class="work-left">
          <div class="all-title">全部待处理任务<span class="title-count">（{{ allWaitingTaskList.length }}）</span></div>
          <MTable ref="mTable" :data="allWaitingTaskList" :height="220" :columns="allWaitingTaskColumns" :columns-setting="false" :show-page="false">
            <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
            <div slot="action" slot-scope="{ row }">
              <el-button type="text" @click="taskPlanNodeDetail(row)">详情</el-button>
            </div>
          </MTable>
        </div>
      <!--</div>-->
    </template>
  </div>
</template>

<script>
  import { todayWaitingTaskColumns, thisWeekAddTaskColumns, allWaitingTaskColumns } from './columns'
  import dayjs from 'dayjs'
  import api from '@/api/projectManagement/personalWorkbench'
  import { Encrypt } from '@/utils/sercet'
  import 'echarts/lib/component/legendScroll'
  const colorProjectStart = ['#97AABE', '#7CC46F', '#607FFF']
  export default {
    name: 'PersonalWorkbench',
    data() {
      return {
        isToken: false,
        columns: [
          {
        type: 'selection',
          width: 60,
          fixed: 'left'
      },
      {
        prop: 'index',
          label: '序号',
        slotName: 'index',
        hiddenSearch: true,
        sortable: false,
        width: 80
      },
    ],
        showLine: false,
        currentIdxs: [],
        checkeds: false,
        loading: false,
        todayWaitingTaskColumns,
        thisWeekAddTaskColumns,
        allWaitingTaskColumns,
        orderInfo: {},
        taskExecutionInfo: {},
        inspectInfos: {},
        inspectInfo: {},
        params: {
          radio: ['last', 'last', 'last']
        },
        todayWaitingTaskList: [],
        thisWeekAddTaskList: [],
        allWaitingTaskList: [],
        allStatus: [
          {
            id: 0,
            name: '延期风险',
            color: '#F7B500',
            count: 0
          },
          {
            id: 1,
            name: '进行中',
            color: '#607FFF',
            count: 0
          },
          {
            id: 2,
            name: '已完工',
            color: '#32C5FF',
            count: 0
          }
        ],
        allOrderNums: [
          {
            id: 0,
            name: '本日',
            count: 0
          },
          {
            id: 1,
            name: '本周',
            count: 0
          },
          {
            id: 2,
            name: '本月',
            count: 0
          },
          {
            id: 3,
            name: '本季度',
            count: 0
          },
          {
            id: 4,
            name: '本年',
            count: 0
          }
        ],
        allDays: [
          {
            id: 0,
            name: '昨日',
            label: 'last'
          },
          {
            id: 1,
            name: '本周',
            label: 'week'
          },
          {
            id: 2,
            name: '本月',
            label: 'month'
          },
          {
            id: 3,
            name: '本年',
            label: 'year'
          }
        ],
        startEndDate: {
          beginTime: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
          endTime: dayjs().subtract(1, 'day').format('YYYY-MM-DD')
        },
        sevenStartEndDate: {
          beginTime: dayjs().subtract(6, 'day').format('YYYY-MM-DD'),
          endTime: dayjs().format('YYYY-MM-DD')
        },
        chartProjectStart: {
          columns: [],
          rows: []
        },
        pieChartProjectStart: {
          color: colorProjectStart,
          legend: {
            show: true,
            type: 'scroll',
            orient: 'vertical',
            left: 0,
            textStyle: {
              width: 10,
              overflow: 'break'
            }
          },
          series: {
            radius: ['18%', '60%'],
            center: ['50%', '50%'],
            label: {
              width: 50,
              overflow: 'break'
            },
          },
          tooltip: {
            trigger: 'item'
            // appendToBody: true
          }
        },
      }
    },
    computed: {
      memberCode() {
        return sessionStorage.getItem('memberCode')
      },
      height() {
        return this.$store.state.app.contentHeight - 54 - 100 - 50 - 60 - 70
      }
    },
    watch: {
    },
    async mounted() {
      this.getProjectCount()
      this.getAlls()
    },
    methods: {
      getAlls() {
        this.getTaskExecutionCount()
        this.getTodayWaitingTaskList()
        this.getThisWeekAddTaskList()
        this.getAllWaitingTaskList()
      },

      fixData(val) {
        if (val) {
          return this.fixNum(((val / this.orderInfo.doingCount) * 100).toFixed(2))
        }
      },
      fixNum(val) {
        const number = String(val).replace(/^(.*\..{2}).*$/, '$1')
        return Number(number)
      },
      pieSetProjectStart(res) {
        this.chartProjectStart.columns = ['name', 'value']
        const data = [{
            name: '未开始',
            value: res.notStartCount,
            totalValue: res.projectStartTotalCount
          },
          {
            name: '进行中',
            value: res.underwayCount,
            totalValue: res.projectStartTotalCount
          },
          {
            name: '已结案',
            value: res.closeoutCount,
            totalValue: res.projectStartTotalCount
          }]
        this.chartProjectStart.rows = data
        const dataMap = {}
        this.chartProjectStart.rows.forEach(({ name, value, totalValue}) => {
          dataMap[name] = { name, value, totalValue }
        })
        this.pieChartProjectStart.legend.formatter = (key) => {
          const { name, value, totalValue } = dataMap[key]
          const arr = [name + " " + value + "/" + totalValue]
          return arr.join('')
        }
        this.loading = false
      },
      // 项目相关情况以及个人完成任务情况
      async getProjectCount() {
        const res = await api.getProjectCount()
        if (res) {
          this.orderInfo = res
          this.allOrderNums[0].count = res.doneCountToday
          this.allOrderNums[1].count = res.doneCountWeek
          this.allOrderNums[2].count = res.doneCountMonth
          this.allOrderNums[3].count = res.doneCountQuarter
          this.allOrderNums[4].count = res.doneCountYear
          this.allStatus[0].count = res.doingMap[0]
          this.allStatus[1].count = res.doingMap[1]
          this.allStatus[2].count = res.doingMap[2]
          this.pieSetProjectStart(res)
        }
      },
      // 任务执行数量
      async getTaskExecutionCount() {
        const res = await api.getTaskExecutionCount(
          Object.assign({}, this.startEndDate))
        if (res) {
          this.taskExecutionInfo = res
        }
      },
      // 今天待处理任务
      async getTodayWaitingTaskList() {
        const res = await api.getTodayWaitingTaskList()
        if (res) {
          const data = res.map(item => ({
              ...item,
            })
          )
          this.todayWaitingTaskList = data
        }
      },
      // 本周新增任务
      async getThisWeekAddTaskList() {
        const res = await api.getThisWeekAddTaskList()
        if (res) {
          const data = res.map(item => ({
              ...item,
            })
          )
          this.thisWeekAddTaskList = data
        }
      },
      // 全部待处理任务
      async getAllWaitingTaskList() {
        const res = await api.getAllWaitingTaskList()
        if (res) {
          const data = res.map(item => ({
              ...item,
            })
          )
          this.allWaitingTaskList = data
        }
      },

      // 任务详情跳转
      taskPlanNodeDetail(row) {
        this.$router.push({ name: 'ProjectTaskDetail',query:{id:row.id,type:'view'}})
      },

      radioChange(val) {
        if (this.params.radio[val] === 'last') {
          this.startEndDate = {
            beginTime: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
            endTime: dayjs().subtract(1, 'day').format('YYYY-MM-DD')
          }
        }
        if (this.params.radio[val] === 'week') {
          if (dayjs().day() === 0) {
            this.startEndDate = {
              beginTime: dayjs().subtract(6, 'day').format('YYYY-MM-DD'),
              endTime: dayjs().format('YYYY-MM-DD')
            }
          } else {
            this.startEndDate = {
              beginTime: dayjs().startOf('week').add(1, 'day').format('YYYY-MM-DD'),
              endTime: dayjs().endOf('week').add(1, 'day').format('YYYY-MM-DD')
            }
          }
        }
        if (this.params.radio[val] === 'month') {
          this.startEndDate = {
            beginTime: dayjs().startOf('month').format('YYYY-MM-DD'),
            endTime: dayjs().endOf('month').format('YYYY-MM-DD')
          }
        }
        if (this.params.radio[val] === 'year') {
          this.startEndDate = {
            beginTime: dayjs().startOf('year').format('YYYY-MM-DD'),
            endTime: dayjs().endOf('year').format('YYYY-MM-DD')
          }
        }
        if (val === 0) {
          this.getTaskExecutionCount()
          return
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .title-count {
    height: 25px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #41456B;
    line-height: 25px;
    margin-bottom: 12px;
    width: 100%;
  }
  .home-containter {
    width: 256px;
    height: 250px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    .img {
      width: 256px;
      height: 201px;
      display: block;
    }
    .home-font {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393D60;
      line-height: 20px;
      margin-top: 20px;
      display: inline-block;
    }
  }

  ::v-deep .m-radio-group .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    padding: 5px 15px;
  }
  ::v-deep .m-radio-groups .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    padding: 5px 8px;
  }
  ::v-deep {
    .m-radio-group .el-radio-button__inner {
      min-width: 70px;
    }
    .m-radio-group .el-radio-button {
      min-width: 80px;
    }
    .m-radio-groups .el-radio-button__inner {
      min-width: 50px;
      padding: 12px 10px;
    }
    .m-radio-groups .el-radio-button {
      min-width: 60px;
    }
    .app-main {
      padding-top: 0 !important;
    }
  }
  .all-lines {
    padding: 10px 0;
    display: flex;
    justify-content: flex-start;
    flex-flow: row wrap;
    // align-items: center;
    .checkAll {
      margin-right: 20px;
      margin-bottom: 10px;
    }
    .tag {
      height: 22px;
      padding: 0px 10px;
      border-radius: 4px;
      margin-right: 10px;
      font-size: 14px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #607FFF;
      line-height: 22px;
      border-radius: 11px;
      border: 1px solid #607FFF;
      margin-bottom: 10px;
      cursor: pointer;
      &:hover {
        .el-button:first-child,
        .el-button:last-child {
          display: block;
        }
      }

      .el-button:first-child {
        color: #9597AE;
        padding: 0 3px;
        display: none;
        position: relative;
        right: 5px;
      }

      .el-button:last-child {
        color: #000000;
        padding: 0 3px;
        display: none;
        position: relative;
        left: 5px;
      }
    }

  }
  .all-title {
    height: 25px;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #41456B;
    line-height: 25px;
    margin-bottom: 12px;
    width: 100%;
  }
  .common-pad {
    padding: 20px;
    border-radius: 4px;
  }
  .common-tit {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .tit {
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #4B4F77;
      line-height: 32px;
    }
    .m-radio-group {
      height: 32px;
    }
    .m-radio-groupss {
      height: 32px;
      width: 280px;
    }
  }
  .m-radio-groups {
    height: 32px;
    width: 250px;
  }
  .container-top {
    height: 290px;
    background: #FFFFFF;
    border-radius: 4px;
    display: flex;
    .order-left {
      flex: 1;
      border-right: 1px solid #DDE3EB;
      padding-right: 30px;
      .left-tit {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        height: 32px;
        .title {
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #41456B;
        }
        .des {
          padding: 0 20px;
          background: rgba(0, 171, 41, .1);
          border-radius: 4px;
          font-size: 24px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #00BC35;
        }
      }
      .left-cen {
        border-radius: 4px;
        .cen-li {
          height: 42px;
          display: flex;
          justify-content: space-between;
          background: #ECEEF2;
          align-items: center;
          border-bottom: 1px solid #DDE3EB;
          &:first-child {
            border-radius: 4px 4px 0 0;
            .tip {
              border-radius: 4px 0 0 0;
            }
          }
          &:last-child {
            border-bottom: none;
            border-radius: 0 0 4px 4px;
            .tip {
              border-radius: 0 0 0 4px;
            }
          }
          .tip {
            width: 7px;
            height: 42px;
            background: #F7B500;
            margin-right: 16px;
          }
          .tit {
            flex: 2;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #4B4F77;
          }
          .desc {
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #F7B500;
            padding-right: 20px;
          }
        }
      }
      .left-bot {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        margin-top: 22px;
        .tip {
          color: #F7B500;
          font-size: 10px;
          div {
            height: 20px;
          }
          span {
            display: inline-block;
            height: 23px;
            background: #F7B500;
            width: 100%;
          }
        }
        .tit {
          color: #607FFF;
          font-size: 10px;
          // word-break: break-all;
          div {
            height: 20px;
          }
          span {
            display: inline-block;
            height: 23px;
            background: #607FFF;
            width: 100%;
          }
        }
        .desc {
          color: #32C5FF;
          font-size: 10px;
          div {
            height: 20px;
          }
          span {
            display: inline-block;
            height: 23px;
            background: #32C5FF;
            width: 100%;
          }
        }
      }
    }
    .order-cen {
      flex: 1;
      border-right: 1px solid #DDE3EB;
      padding-left: 15px;
      .tit {
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #41456B;
        line-height: 25px;
        margin-bottom: 15px;
      }
    }
    .order-right {
      flex: 1;
      margin-left: 20px;
      .right-tit {
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #545982;
        line-height: 25px;
        margin-bottom: 12px;
      }
      .right-bot {
        height: 34px;
        background: #ECEEF2;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px;
        margin-bottom: 5px;
        &:nth-child(even) {
          background: #EBEEFA;
        }
        .tip {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #545982;
        }
        .des {
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #393D60;
        }
      }
    }
  }
  .container-bottom {
    height: 425px;
    display: flex;
    margin: 20px 0;
    padding: 0;
    .product-right {
      flex: 1;
      width: 48%;
      padding: 10px;
      background: #FFFFFF;
      .tit {
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #41456B;
        line-height: 25px;
        margin-bottom: 15px;
      }
    }
    .product-left {
      flex: 1;
      width: 48%;
      padding: 10px;
      margin-right: 20px;
      background: #FFFFFF;
      .tit {
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #41456B;
        line-height: 25px;
        margin-bottom: 15px;
      }
    }
  }
  .container-center {
    margin: 20px 0;
    display: flex;
    padding: 0;
    .work-left {
      background: #fff;
      flex: 1;
      width: 32%;
      padding: 10px;
      .left-bot {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        margin-top: 15px;
        word-break: break-all;
        .bot-li {
          width: 30.5%;
          height: 93px;
          background: #F6F7F9;
          border-radius: 4px;
          border: 1px solid #DDE3EB;
          padding-right: 10px;
          margin: 5px;
          position: relative;
          .tip {
            position: absolute;
            top: 7px;
            left: 7px;
            width: 32px;
            height: 32px;
            display: block;
          }
          // &:first-child {
          //   margin-left: 0;
          // }
          // &:last-child {
          //   margin-right: 0;
          // }
          .tit {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #393D60;
            text-align: right;
            padding-top: 16px;
            line-height: 20px;
          }
          .count {
            height: 33px;
            font-size: 24px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #41456B;
            text-align: right;
            margin-top: 5px;
            line-height: 22px;
          }
        }
      }
    }
    .work-center {
      flex: 1;
      width: 32%;
      margin: 0 20px;
      padding: 10px;
      background: #fff;
      .tit {
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #41456B;
        line-height: 25px;
        margin-bottom: 15px;
      }
    }
    .work-right {
      flex: 1;
      width: 32%;
      padding: 10px;
      background: #fff;
      .tit {
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #41456B;
        line-height: 25px;
        margin-bottom: 15px;
      }
    }
  }
  .container-footer {
    display: flex;
    min-height: 283px;
    padding: 0;
    .inspection-left {
      width: 32%;
      background: #fff;
      padding: 15px;
      .tit {
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #41456B;
        line-height: 25px;
        margin-bottom: 15px;
      }
    }
    .inspection-center {
      width: 32.4%;
      margin: 0 20px;
      padding: 10px;
      background: #fff;
    }
    .inspection-right {
      width: 32%;
      background: #fff;
      padding: 10px;
    }
    .left-bot {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      margin-top: 25px;
      .bot-li {
        flex: 1;
        height: 174px;
        background: #F6F7F9;
        border-radius: 4px;
        border: 1px solid #DDE3EB;
        padding-right: 10px;
        margin: 5px;
        position: relative;
        .tip {
          position: absolute;
          top: 7px;
          left: 7px;
          width: 32px;
          height: 32px;
          display: block;
        }
        // &:first-child {
        //   margin-left: 0;
        // }
        // &:last-child {
        //   margin-right: 0;
        // }
        .tit {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #393D60;
          text-align: right;
          padding-top: 60px;
          line-height: 20px;
        }
        .count {
          height: 33px;
          font-size: 24px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #41456B;
          text-align: right;
          line-height: 33px;
        }
      }
    }
  }
</style>
