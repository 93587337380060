<template>
  <div class="inspection-plan-detail-wrapper">
    <div class="inspection-plan-detail-header flex-sbc">
      <div class="left" @click="$router.back()">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px">
        <span style="padding-left: 10px;cursor: pointer">自动排程</span>
      </div>
    </div>
    <div class="inspection-plan-detail-body">
      <div class="detail-card">
        <div class="card-tit">基本信息</div>
        <div style="height:10px" />
        <el-steps :active="currentStatus" align-center finish-status="success">
          <el-step title="规则设置" description="设置自动排程的计算规则。" />
          <el-step title="排程运算" description="" />
          <el-step title="结果预览" description="预览排程结果，支持手动调节。" />
        </el-steps>
        <div class="card-bot">
          <div v-if="currentStatus===0">
            <br>注意：本排程是不考虑生产资源的无限产能排程。<br><br>
            规则：自动将净需求量>0的生产需求按工序分包成生产任务，并依据工序标准工时自动计算开工完工时间<br><br>
            开始时间：
            <el-date-picker
              v-model="planStartTime"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择计划开始时间"
            />
          </div>
          <div v-if="currentStatus===1&&isCalculate" style="text-align:center">
            计算中
            <el-progress :percentage="percentage" />
          </div>
          <MTable
            v-if="currentStatus===2"
            :height="500"
            :columns="scheduleColumn"
            :data="tableData"
            :show-page="false"
          >
            <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
            <NewPageOpen
              slot="materialsCode"
              slot-scope="{ row }"
              :path="{ name: 'materialsDetail', query: {id: row.materialsId}}"
              :text="row.materialsCode"
            />
            <template #planOutputQuantity="{ row }">
              <el-input-number
                v-model="row.planOutputQuantity"
                :controls="false"
                :min="1"
                :step="1"
                :step-strictly="true"
                :style="{width:'90px'}"
              />
            </template>
            <template #planTime="{ row }">
              <el-date-picker
                v-model="row.planTime"
                type="datetimerange"
                :clearable="false"
                range-separator="至"
                start-placeholder="计划开工时间"
                end-placeholder="完工时间"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                style="width:345px"
              />
            </template>
            <template #remark="{ row }">
              <el-input
                v-model="row.remark"
                type="textarea"
              />
            </template>
          </MTable>
        </div>
      </div>
    </div>
    <div class="order-footer">
      <div />
      <div class="footer-ri">
        <el-button @click="$router.back()">取 消 </el-button>
        <el-button type="primary" @click="onSave">确 定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/production/project'
import { addTaskForm, scheduleColumn } from './data'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
export default {
  name: 'PurchaseDetail',
  components: { NewPageOpen },
  data() {
    return {
      percentage: 0,
      currentStatus: 0,
      isCalculate: false,
      planStartTime: '',
      addTaskForm,
      scheduleColumn,
      detail: {},
      tableData: []
    }
  },
  created() {
    const { productionRequirementId, planStartTime } = this.$route.query
    this.productionRequirementId = productionRequirementId
    this.planStartTime = planStartTime
  },
  methods: {
    async onSave() {
      if (this.currentStatus === 0 && !this.isCalculate) {
        this.isCalculate = true
        this.currentStatus = 1
        const timer = setInterval(() => {
          if (this.percentage > 90) {
            return clearInterval(timer)
          }
          this.percentage++
        }, 20)
        const res = await api.autoCreate({ planStartTime: this.planStartTime, productionRequirementId: this.productionRequirementId })
        if (res) {
          res.forEach(item => {
            item.planTime = [item.planStartTime, item.planEndTime]
            this.$set(item, 'planOutputQuantity', item.planOutputQuantity)
          })
          this.tableData = res
          this.percentage = 100
          this.currentStatus = 2
        }
      } else {
        this.$confirm('确认要保存吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          const params = this.tableData.map(({ code, planOutputQuantity, productionRequirementItemId, remark, planTime }) => {
            return { code, planOutputQuantity, productionRequirementItemId, remark, planStartTime: planTime[0], planEndTime: planTime[1] }
          })
          const res = await api.saveAutoCreate(params)
          if (res) {
            this.$message.success('操作成功')
            this.$router.back()
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.order-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  background: #fff;
  border-top: 1px solid #dde3eb;
  .footer-le {
    padding-left: 30px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393d60;
    span {
      margin-right: 45px;
      display: inline-block;
    }
  }
  .footer-ri {
    margin-right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.inspection-plan-detail-wrapper {
  background-color: #f0f2f5;
  height: 100%;

  .inspection-plan-detail-header {
    width: 100%;
    padding: 10px 0;
  }

  .inspection-plan-detail-body {
    height: calc(100% - 20px);

    .detail-card {
      height: 100%;
    }
  }
  .function-header-wrap {
    padding-top: 0;
  }
}
</style>
