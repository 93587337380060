import { render, staticRenderFns } from "./waitingBoard.vue?vue&type=template&id=5d2bb700&scoped=true&"
import script from "./waitingBoard.vue?vue&type=script&lang=js&"
export * from "./waitingBoard.vue?vue&type=script&lang=js&"
import style0 from "./waitingBoard.vue?vue&type=style&index=0&id=5d2bb700&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d2bb700",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/mubai-mom-frontend-dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5d2bb700')) {
      api.createRecord('5d2bb700', component.options)
    } else {
      api.reload('5d2bb700', component.options)
    }
    module.hot.accept("./waitingBoard.vue?vue&type=template&id=5d2bb700&scoped=true&", function () {
      api.rerender('5d2bb700', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/qualityManagement/waitingBoard.vue"
export default component.exports