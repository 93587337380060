var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "unit-wrap common-tab" },
    [
      _c(
        "el-radio-group",
        {
          staticClass: "m-radio-group",
          on: { change: _vm.radioChange },
          model: {
            value: _vm.params.radio,
            callback: function($$v) {
              _vm.$set(_vm.params, "radio", $$v)
            },
            expression: "params.radio"
          }
        },
        [
          _c("el-radio-button", { attrs: { label: "TaskDetail" } }, [
            _vm._v("任务详情")
          ]),
          _c("el-radio-button", { attrs: { label: "ReportRecord" } }, [
            _vm._v("汇报记录")
          ]),
          _c(
            "div",
            {
              staticStyle: {
                padding: "8px 10px",
                position: "absolute",
                right: "42px",
                "border-radius": "4px"
              },
              style: {
                color: _vm.statusOptionsMap[_vm.baseFormData.status].color,
                background:
                  _vm.statusOptionsMap[_vm.baseFormData.status].background
              }
            },
            [
              _c("span", { staticStyle: { "font-size": "14px" } }, [
                _vm._v(
                  "状态: " +
                    _vm._s(
                      _vm.statusOptionsMap[_vm.baseFormData.status].label
                    ) +
                    " "
                )
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.params.radio === "TaskDetail",
              expression: "params.radio === 'TaskDetail'"
            }
          ]
        },
        [
          _c("div", { staticClass: "top", staticStyle: { padding: "10px" } }, [
            _c("div", { staticClass: "detail-wrappers" }, [
              _c("div", { staticClass: "detail-body" }, [
                _c("div", { staticClass: "detail-card" }, [
                  _c("div", { staticClass: "card-tit" }, [
                    _vm._v("任务基本信息")
                  ]),
                  _c(
                    "div",
                    { staticClass: "card-bot" },
                    [
                      _c(
                        "MFormBuilder",
                        {
                          ref: "baseForm",
                          attrs: {
                            "form-list": _vm.baseFormDetailList,
                            "form-data": _vm.baseFormData,
                            "label-position": "left"
                          }
                        },
                        _vm._l(_vm.baseFormDetailList, function(item) {
                          return _c(
                            "div",
                            {
                              key: item.key,
                              attrs: { slot: item.key },
                              slot: item.key
                            },
                            [
                              item.key === "planName"
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.baseFormData.planName))
                                  ])
                                : _vm._e(),
                              item.key === "planTypeName"
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.baseFormData.planTypeName)
                                    )
                                  ])
                                : _vm._e(),
                              item.key === "projectNumber"
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.baseFormData.projectNumber)
                                    )
                                  ])
                                : _vm._e(),
                              item.key === "projectName"
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.baseFormData.projectName))
                                  ])
                                : _vm._e(),
                              item.key === "startTime"
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.baseFormData.startTime))
                                  ])
                                : _vm._e(),
                              item.key === "finishTime"
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.baseFormData.finishTime))
                                  ])
                                : _vm._e(),
                              item.key === "actualStartTime"
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.baseFormData.actualStartTime)
                                    )
                                  ])
                                : _vm._e(),
                              item.key === "actualFinishTime"
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.baseFormData.actualFinishTime)
                                    )
                                  ])
                                : _vm._e(),
                              item.key === "lastUpdateTime"
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.baseFormData.lastUpdateTime)
                                    )
                                  ])
                                : _vm._e(),
                              item.key === "createTime"
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.baseFormData.createTime))
                                  ])
                                : _vm._e(),
                              item.key === "principalDepartName"
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.baseFormData.principalDepartName
                                      )
                                    )
                                  ])
                                : _vm._e(),
                              item.key === "principalName"
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.baseFormData.principalName) +
                                        " "
                                    )
                                  ])
                                : _vm._e(),
                              item.key === "processPercent"
                                ? _c(
                                    "span",
                                    {
                                      attrs: { slot: "processPercent" },
                                      slot: "processPercent"
                                    },
                                    [
                                      _c("el-progress", {
                                        attrs: {
                                          percentage:
                                            _vm.baseFormData.processPercent
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              item.key === "workHours"
                                ? _c("span", { staticClass: "el-progress" }, [
                                    _vm._v(_vm._s(_vm.baseFormData.workHours))
                                  ])
                                : _vm._e(),
                              item.key === "reportRemark"
                                ? _c("span", { staticClass: "el-progress" }, [
                                    _vm._v(
                                      _vm._s(_vm.baseFormData.reportRemark)
                                    )
                                  ])
                                : _vm._e(),
                              item.key === "relatedFiles"
                                ? _c("span", { staticClass: "el-progress" }, [
                                    _vm._v(
                                      _vm._s(_vm.baseFormData.relatedFiles)
                                    )
                                  ])
                                : item.key === "attachments"
                                ? _c(
                                    "div",
                                    _vm._l(_vm.attachments, function(item) {
                                      return _c(
                                        "el-link",
                                        {
                                          key: item.id,
                                          staticStyle: {
                                            "padding-left": "10px"
                                          },
                                          attrs: {
                                            href: item.url,
                                            target: "_blank"
                                          }
                                        },
                                        [_vm._v(" " + _vm._s(item.name) + " ")]
                                      )
                                    }),
                                    1
                                  )
                                : _vm._e()
                            ]
                          )
                        }),
                        0
                      )
                    ],
                    1
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "all-btns" },
                  [
                    _c(
                      "el-button",
                      { attrs: { size: "medium" }, on: { click: _vm.closeIn } },
                      [_vm._v("返回")]
                    ),
                    _vm.permission("Start") &&
                    _vm.allowOperation === true &&
                    _vm.status === 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "medium", type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.handle(_vm.baseFormData)
                              }
                            }
                          },
                          [_vm._v("开始任务")]
                        )
                      : _vm._e(),
                    _vm.permission("AddReport") &&
                    _vm.allowOperation === true &&
                    _vm.status === 2
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "medium", type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.report(_vm.baseFormData, "addReport")
                              }
                            }
                          },
                          [_vm._v("汇报")]
                        )
                      : _vm._e(),
                    _vm.permission("Finish") &&
                    _vm.allowOperation === true &&
                    _vm.status === 2
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "medium", type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.handle(_vm.baseFormData)
                              }
                            }
                          },
                          [_vm._v("完成任务")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            ])
          ])
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.params.radio === "ReportRecord",
              expression: "params.radio === 'ReportRecord'"
            }
          ]
        },
        [
          _c(
            "div",
            { staticClass: "top" },
            [
              _c("SearchForm", {
                ref: "searchForm",
                staticStyle: { padding: "10px" },
                attrs: {
                  "form-list-extend": _vm.reportSearchFormList,
                  "form-data": _vm.searchFormData,
                  "default-form": _vm.defaultForm
                },
                on: {
                  "update:formData": function(data) {
                    return (_vm.searchFormData = data)
                  },
                  search: _vm.reSearch
                }
              }),
              _c("MTable", {
                ref: "mTable",
                attrs: {
                  columns: _vm.columns[_vm.params.radio],
                  height: _vm.height,
                  "columns-key": _vm.columnsKey,
                  "set-data-method": _vm.getTableData,
                  "columns-setting": true
                },
                scopedSlots: _vm._u([
                  {
                    key: "index",
                    fn: function(ref) {
                      var $index = ref.$index
                      return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                    }
                  },
                  {
                    key: "processPercent",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {},
                        [
                          _c("el-progress", {
                            attrs: { percentage: row.processPercent }
                          })
                        ],
                        1
                      )
                    }
                  },
                  {
                    key: "relatedFiles",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {},
                        [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                effect: "dark",
                                content: row.relatedFiles,
                                placement: "top-end"
                              }
                            },
                            [
                              _c("span", { staticClass: "mr-10" }, [
                                _vm._v(_vm._s(row.relatedFiles))
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    }
                  },
                  {
                    key: "attachments",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {},
                        _vm._l(JSON.parse(row.attachments) || [], function(
                          item
                        ) {
                          return _c(
                            "el-link",
                            {
                              key: item.id,
                              staticStyle: { "padding-left": "10px" },
                              attrs: { href: item.url, target: "_blank" }
                            },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          )
                        }),
                        1
                      )
                    }
                  },
                  {
                    key: "action",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {},
                        [
                          [
                            _vm.permission("ReportDel")
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.del(row.id)
                                      }
                                    }
                                  },
                                  [_vm._v("删除")]
                                )
                              : _vm._e()
                          ]
                        ],
                        2
                      )
                    }
                  }
                ])
              })
            ],
            1
          )
        ]
      ),
      _c(
        "MDialog",
        {
          attrs: { title: _vm.titles[_vm.currentType] },
          on: { onOk: _vm.submitForm },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "MFormBuilder",
            {
              ref: "formBuild",
              attrs: {
                "form-data": _vm.reportFormData,
                "form-list": _vm.reportFormList
              }
            },
            [
              _c(
                "el-upload",
                _vm._b(
                  {
                    attrs: { slot: "drawing", "file-list": _vm.attachments2 },
                    slot: "drawing"
                  },
                  "el-upload",
                  _vm.uploadProps,
                  false
                ),
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary", size: "small" } },
                    [_vm._v("点击上传")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }