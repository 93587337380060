var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    {
      attrs: {
        placement: "right",
        trigger: "hover",
        "popper-class": "pover-project-tips"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "noplan",
          attrs: { slot: "reference" },
          slot: "reference"
        },
        [
          !_vm.item.actualStartTime
            ? _c("div", {
                staticClass: "actul-common-color",
                style: {
                  background: _vm.statusStyles[_vm.item.statusCode].background,
                  left: _vm.getPositonOffset(_vm.item.planStartTime) + "px",
                  width:
                    _vm.getWidthAbout2Times(
                      _vm.item.planStartTime,
                      _vm.item.planEndTime
                    ) + "px"
                }
              })
            : _vm._e(),
          _vm.item.actualStartTime &&
          _vm.compareActualDate(
            _vm.item.actualStartTime,
            _vm.item.planStartTime
          ) &&
          _vm.compareDate(_vm.item.planEndTime, _vm.currentDate)
            ? _c("div", { staticClass: "actul-time" }, [
                _c("div", {
                  staticClass: "actul-common-color",
                  style: {
                    background:
                      _vm.statusStyles[_vm.item.statusCode].background,
                    left: _vm.getPositonOffset(_vm.item.planStartTime) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.item.planStartTime,
                        _vm.item.actualStartTime
                      ) -
                      6 +
                      "px"
                  }
                }),
                _c("div", {
                  staticClass: "actul-time-color",
                  style: {
                    left: _vm.getPositonOffset(_vm.item.actualStartTime) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.item.actualStartTime,
                        _vm.currentDate
                      ) + "px"
                  }
                }),
                _c("div", {
                  staticClass: "actul-common-color",
                  style: {
                    background:
                      _vm.statusStyles[_vm.item.statusCode].background,
                    left: _vm.getPositonOffset(_vm.currentDate) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.currentDate,
                        _vm.item.planEndTime
                      ) + "px"
                  }
                })
              ])
            : _vm._e(),
          _vm.item.actualStartTime &&
          !_vm.compareDate(_vm.item.actualStartTime, _vm.item.planEndTime) &&
          _vm.compareDate(_vm.item.actualStartTime, _vm.item.planStartTime) &&
          !_vm.compareDate(_vm.item.planEndTime, _vm.currentDate)
            ? _c("div", { staticClass: "actul-time" }, [
                _c("div", {
                  staticClass: "actul-common-color",
                  style: {
                    background:
                      _vm.statusStyles[_vm.item.statusCode].background,
                    left: _vm.getPositonOffset(_vm.item.planStartTime) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.item.planStartTime,
                        _vm.item.actualStartTime
                      ) + "px"
                  }
                }),
                _c("div", {
                  staticClass: "actul-time-color",
                  style: {
                    left: _vm.getPositonOffset(_vm.item.actualStartTime) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.item.actualStartTime,
                        _vm.item.planEndTime
                      ) + "px"
                  }
                }),
                _c("div", {
                  staticClass: "actul-end-color",
                  style: {
                    left: _vm.getPositonOffset(_vm.item.planEndTime) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.item.planEndTime,
                        _vm.currentDate
                      ) -
                      6 +
                      "px"
                  }
                })
              ])
            : _vm._e(),
          _vm.item.actualStartTime &&
          _vm.compareDate(_vm.item.actualStartTime, _vm.item.planEndTime) &&
          !_vm.compareDate(_vm.item.planEndTime, _vm.currentDate) &&
          !_vm.compareDate(_vm.item.planStartTime, _vm.currentDate)
            ? _c("div", { staticClass: "actul-time" }, [
                _c("div", {
                  staticClass: "actul-common-color",
                  style: {
                    background:
                      _vm.statusStyles[_vm.item.statusCode].background,
                    left: _vm.getPositonOffset(_vm.item.planStartTime) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.item.planStartTime,
                        _vm.item.planEndTime
                      ) + "px"
                  }
                }),
                _c(
                  "div",
                  {
                    staticClass: "lines",
                    style: {
                      width:
                        _vm.getWidthAbout2Times(
                          _vm.item.planEndTime,
                          _vm.item.actualStartTime
                        ) + "px"
                    }
                  },
                  [
                    _c("span", { staticClass: "circle" }),
                    _c("span", { staticClass: "line" }),
                    _c("span", { staticClass: "circle" })
                  ]
                ),
                _c("div", {
                  staticClass: "actul-end-color",
                  style: {
                    left: _vm.getPositonOffset(_vm.item.actualStartTime) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.item.actualStartTime,
                        _vm.currentDate
                      ) -
                      6 +
                      "px"
                  }
                })
              ])
            : _vm._e(),
          _vm.item.actualStartTime &&
          _vm.compareDate(_vm.item.planStartTime, _vm.item.actualStartTime) &&
          _vm.compareDate(_vm.item.planStartTime, _vm.currentDate) &&
          _vm.compareDate(_vm.item.planEndTime, _vm.currentDate)
            ? _c("div", { staticClass: "actul-time aa" }, [
                _c("div", {
                  staticClass: "actul-time-color",
                  style: {
                    left: _vm.getPositonOffset(_vm.item.actualStartTime) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.item.actualStartTime,
                        _vm.currentDate
                      ) -
                      6 +
                      "px"
                  }
                }),
                _c(
                  "div",
                  {
                    staticClass: "lines",
                    style: {
                      width:
                        _vm.getWidthAbout2Times(
                          _vm.currentDate,
                          _vm.item.planStartTime
                        ) + "px"
                    }
                  },
                  [
                    _c("span", { staticClass: "circle" }),
                    _c("span", { staticClass: "line" }),
                    _c("span", { staticClass: "circle" })
                  ]
                ),
                _c("div", {
                  staticClass: "actul-common-color",
                  style: {
                    background:
                      _vm.statusStyles[_vm.item.statusCode].background,
                    left: _vm.getPositonOffset(_vm.item.planStartTime) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.item.planStartTime,
                        _vm.item.planEndTime
                      ) + "px"
                  }
                })
              ])
            : _vm._e(),
          _vm.item.actualStartTime &&
          _vm.compareDate(_vm.item.planStartTime, _vm.item.actualStartTime) &&
          !_vm.compareDate(_vm.item.planStartTime, _vm.currentDate) &&
          _vm.compareDate(_vm.item.planEndTime, _vm.currentDate)
            ? _c("div", { staticClass: "actul-time" }, [
                _c("div", {
                  staticClass: "actul-time-color",
                  style: {
                    left: _vm.getPositonOffset(_vm.item.actualStartTime) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.item.actualStartTime,
                        _vm.currentDate
                      ) -
                      6 +
                      "px"
                  }
                }),
                _c("div", {
                  staticClass: "actul-common-color",
                  style: {
                    background:
                      _vm.statusStyles[_vm.item.statusCode].background,
                    left: _vm.getPositonOffset(_vm.currentDate) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.currentDate,
                        _vm.item.planEndTime
                      ) + "px"
                  }
                })
              ])
            : _vm._e(),
          _vm.item.actualStartTime &&
          _vm.compareDate(_vm.item.planStartTime, _vm.item.actualStartTime) &&
          !_vm.compareDate(_vm.item.planStartTime, _vm.currentDate) &&
          _vm.compareDate(_vm.currentDate, _vm.item.planEndTime)
            ? _c("div", { staticClass: "actul-time cc" }, [
                _c("div", {
                  staticClass: "actul-time-color",
                  style: {
                    left: _vm.getPositonOffset(_vm.item.actualStartTime) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.item.actualStartTime,
                        _vm.item.planEndTime
                      ) + "px"
                  }
                }),
                _c("div", {
                  staticClass: "actul-end-color",
                  style: {
                    left: _vm.getPositonOffset(_vm.item.planEndTime) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.item.planEndTime,
                        _vm.currentDate
                      ) -
                      6 +
                      "px"
                  }
                })
              ])
            : _vm._e()
        ]
      ),
      _c("div", { staticClass: "detail" }, [
        _c("div", { staticClass: "li-right-top" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.item.type,
                  expression: "!item.type"
                }
              ],
              staticClass: "title"
            },
            [_vm._v("节点：" + _vm._s(_vm.item.planName))]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.item.type === 1,
                  expression: "item.type === 1"
                }
              ],
              staticClass: "title"
            },
            [_vm._v("工单编号：" + _vm._s(_vm.item.number))]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.item.type === 2,
                  expression: "item.type === 2"
                }
              ],
              staticClass: "title"
            },
            [_vm._v("工序任务：" + _vm._s(_vm.item.number))]
          ),
          _c("div", { staticClass: "tips" }, [
            _c(
              "div",
              {
                staticClass: "tip",
                style: {
                  color: _vm.statusStyles[_vm.item.statusCode].color,
                  background: _vm.statusStyles[_vm.item.statusCode].background
                }
              },
              [_vm._v(" " + _vm._s(_vm.item.statusName) + " ")]
            )
          ])
        ]),
        _c("div", { staticClass: "li-right-top" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.item.type,
                  expression: "!item.type"
                }
              ],
              staticClass: "des-tit"
            },
            [_vm._v("任务类型：" + _vm._s(_vm.item.planTypeName))]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.item.type,
                  expression: "item.type"
                }
              ],
              staticClass: "des-tit"
            },
            [_vm._v("产品：" + _vm._s(_vm.item.materialsName))]
          ),
          _c("div", { staticClass: "des-tit" }, [
            _vm._v(_vm._s(_vm.item.processPercent) + "%")
          ])
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.item.type,
                expression: "!item.type"
              }
            ],
            staticClass: "des-tit"
          },
          [_vm._v("负责部门：" + _vm._s(_vm.item.principalDepartName))]
        ),
        _c("div", { staticClass: "des-tit" }, [
          _vm._v("负责人：" + _vm._s(_vm.item.principalName))
        ]),
        _c("div", { staticClass: "des-tit" }, [
          _vm._v("计划开始时间：" + _vm._s(_vm.item.planStartTime))
        ]),
        _c("div", { staticClass: "des-tit" }, [
          _vm._v("计划完成时间：" + _vm._s(_vm.item.planEndTime))
        ]),
        _c("div", { staticClass: "des-tit" }, [
          _vm._v("实际开始时间：" + _vm._s(_vm.item.actualStartTime))
        ]),
        _c("div", { staticClass: "des-tit" }, [
          _vm._v("实际结束时间：" + _vm._s(_vm.item.actualEndTime))
        ]),
        _c("div", { staticClass: "des-tit" }, [
          _vm._v("备注：" + _vm._s(_vm.item.remark))
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.item.type,
                expression: "!item.type"
              }
            ],
            staticClass: "dec",
            style: {
              color: _vm.statusStyles[_vm.item.statusCode].color
            }
          },
          [
            _vm._v("提示： "),
            _c("span", [_vm._v(_vm._s(_vm.item.message) + " ")])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }