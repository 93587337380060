<template>
  <div class="package-wrap">
    <FunctionHeader
      v-model="functionHeader.searchVal"
      search-title="请输入质检方案名称"
      :tags="functionHeader.tags"
      @search="$refs.mTable.setTableData(true)"
    />
    <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData">
      <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
      <div slot="name" slot-scope="{ row }">
        <NewPageOpen v-if="isShowChild" :path="{name: 'QualityPlanDetail', query: { id: row.id, type: 'view' }}" :text="row.name" />
      </div>
      <!--      <el-button slot="items" slot-scope="{ row }" type="text" @click="viewItems(row)">查看</el-button>-->
      <div slot="isEnabled" slot-scope="{ row }" class="is-enabled">
        <div :style="{background: row.isEnabled ? '#24CAD7' : '#FFCC78'}" />
        <div>{{ row.isEnabled ? '启用' : '停用' }}</div>
      </div>
      <div slot="type" slot-scope="{ row }">
           {{ inspectionTypeNames[row.type] }}
          </div>
      <div slot="action" slot-scope="{ row }">
        <el-button v-if="permission('Copy')" type="text" @click="modifyOrCopy(row, 'copy')">复制</el-button>
        <el-divider v-if="permission('Copy')" direction="vertical" />
        <el-button v-if="permission('Status')" type="text" @click="switchStatus(row, params.radio)">{{ row.isEnabled ? '停用' : '启用' }}</el-button>
        <el-divider v-if="permission('Status')" direction="vertical" />
        <el-dropdown @command="(command) => { more(row, command) }">
          <span class="el-dropdown-link">
            更多<i class="el-icon-arrow-down el-icon--right" />
          </span>
          <el-dropdown-menu slot="dropdown" class="td-dropdown-menu">
            <el-dropdown-item v-if="permission('Modify')" command="modify">修改</el-dropdown-item>
            <el-dropdown-item v-if="permission('Del')" command="del">删除</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </MTable>

    <TableDialog title="查看检验条目" :show.sync="inspectionItemsVisible" :columns="inspectionItemsColumns" :table-data="inspectionItems" />
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import conventionMixin from '@/mixin/conventional-page'
import api from '@/api/quality/inspection-plan'
import columns, { inspectionItemsColumns } from './columns'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
import TableDialog from '@/components/TableDialog/TableDialog'
import fixCommonPage from '@/mixin/fix-common-page'

export default {
  name: 'QualityPlanList',
  components: { TableDialog, NewPageOpen, FunctionHeader },
  mixins: [conventionMixin, fixCommonPage],
  data() {
    return {
      api,
      params: {
        radio: 'InspectionPlan'
      },
      functionHeader: {
        searchVal: '',
        tags: [
          {
            hidden: !this.permission('Add'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: this.add
            }
          }
        ]
      },
      columns,
      visible: false,
      formData: {},
      currentType: 'add',
      inspectionItemsVisible: false,
      inspectionItems: [],
      inspectionItemsColumns,
      inspectionTypeNames: ['首检', '过程检', '来料检', '出货检'],
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 192
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'refresh') {
      this.$store.dispatch('tagsView/addCachedView', from)
    }
    next()
  },
  methods: {
    // 更多操作
    more(row, command) {
      const functions = {
        modify: () => { this.modifyOrCopy(row, command) },
        del: () => { this.del(row) }
      }
      functions[command]()
    },
    async viewItems(row) {
      /* 2021年12月31号 17:13 小罗写的最后一行代码！2022我来啦*/
      const res = await api.getInspectionPlanDetailById({ id: row.id })
      if (res) {
        const values = Object.values(res.inspectionEntryMap)
        this.inspectionItems = values.reduce((a, b) => a.concat(b), [])
        this.inspectionItemsVisible = true
      }
    },
    add() {
      this.$router.push({ name: 'QualityPlanDetail', query: { type: 'add' }})
    },
    modifyOrCopy(row, command) {
      const functions = {
        copy: () => { this.$router.push({ name: 'QualityPlanDetail', query: { id: row.id, type: 'copy' }}) },
        modify: () => { this.$router.push({ name: 'QualityPlanDetail', query: { id: row.id, type: 'modify' }}) },
        del: () => { this.del(row) }
      }
      functions[command]()
    }
  }
}
</script>

<style scoped lang="scss">
.package-wrap {
  padding: 10px;
  .function-header-wrap {
    padding-top: 0;
  }
}
</style>

