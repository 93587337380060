import { taskReportFormColumns } from './columns'
import { transformColumnsToForm } from '@/utils'
const newColumns = _.cloneDeep(taskReportFormColumns)
export const reportFormList = transformColumnsToForm(newColumns)

// 汇报搜索字段
export const reportSearchFormList = [
  {
    colSpan: 4,
    key: 'reportUserId',
    tagName: 'el-select',
    props: {
      placeholder: '汇报人'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'userFullName',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 8,
    key: 'reportTime',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '汇报时间的开始时间',
      endPlaceholder: '结束时间',
      align: 'center',
      type: 'daterange',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm:ss',
      defaultTime: ['00:00:00', '23:59:59']
    }
  },
]
