var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-trac" }, [
    _c("div", { staticClass: "procedure-detail-header" }, [
      _c(
        "div",
        {
          staticClass: "left",
          on: {
            click: function($event) {
              return _vm.$router.push({ name: "productBatchCodeTracingList" })
            }
          }
        },
        [
          _c("img", {
            attrs: {
              src: require("@/assets/information/procedure/左滑@2x.png")
            }
          }),
          _c("span", [_vm._v("产品批次码详情")])
        ]
      )
    ]),
    _c("div", { staticClass: "detail-card" }, [
      _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
      _c(
        "div",
        { staticClass: "card-bot" },
        [
          _c(
            "el-row",
            { staticClass: "view-list", attrs: { gutter: 24 } },
            _vm._l(_vm.base, function(item, index) {
              return _c(
                "el-col",
                {
                  key: item.prop,
                  style: { marginBottom: index !== 4 ? "20px" : 0 },
                  attrs: { span: 6 }
                },
                [
                  _c("span", { staticClass: "bot-tis" }, [
                    _vm._v(_vm._s(item.label) + "：")
                  ]),
                  _c("span", { staticClass: "bot-tis" }, [
                    _vm._v(_vm._s(_vm.getValue(item)))
                  ])
                ]
              )
            }),
            1
          )
        ],
        1
      )
    ]),
    _vm.baseData && _vm.baseData.workOrderInfoList
      ? _c("div", { staticClass: "detail-card" }, [
          _c("div", { staticClass: "card-tit" }, [_vm._v("生产记录追溯")]),
          _c(
            "div",
            { staticClass: "card-bot" },
            [
              _vm.baseData || _vm.baseData === ""
                ? _c("product-all", { attrs: { "base-data": _vm.baseData } })
                : _vm._e()
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }