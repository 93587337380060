// 列表
export const columnsAll = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'status',
    label: '处理状态',
    hiddenSearch: true,
    sortable: false,
    slotName: 'status'
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    hiddenSearch: true,
    sortable: false,
    width: 160
  },
  {
    prop: 'repairCount',
    label: '返修数量',
    hiddenSearch: true,
    sortable: 'custom',
    width: 140
  },
  {
    prop: 'planCount',
    label: '已计划数量',
    hiddenSearch: true,
    sortable: 'custom',
    width: 120
  },
  {
    prop: 'procedureName',
    label: '工序',
    hiddenSearch: true,
    sortable: false,
    width: 150
  },
  {
    prop: 'materialCode',
    label: '物料编码',
    hiddenSearch: true,
    sortable: false,
    width: 150
  },
  {
    prop: 'materialName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false,
    width: 150
  },
  {
    prop: 'materialSpecifications',
    label: '规格',
    hiddenSearch: true,
    sortable: false,
    width: 220
  },
  {
    prop: 'inspectionSchemeName',
    label: '质检方案',
    hiddenSearch: true,
    sortable: false,
    width: 180
  },
  {
    prop: 'inspectionSheetNumber',
    label: '质检单号',
    hiddenSearch: true,
    sortable: false,
    width: 180
  },
  {
    prop: 'produceWorkOrderNumber',
    label: '源单据号',
    hiddenSearch: true,
    sortable: false,
    width: 180
  },
  {
    prop: 'produceOrderNumber',
    label: '订单编号',
    hiddenSearch: true,
    sortable: false,
    width: 180
  },
  {
    prop: 'inspectorName',
    label: '检验人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'endTime',
    label: '检验提交时间',
    hiddenSearch: true,
    sortable: 'custom',
    width: 180
  },
  {
    prop: 'planList',
    label: '返修计划号',
    hiddenSearch: true,
    sortable: false,
    slotName: 'planList',
    width: 180
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 80
  }
]

