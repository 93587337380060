var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fault-wrap" },
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c("SearchForms", {
            ref: "searchForms",
            on: { resetData: _vm.resetData, searchData: _vm.searchTable }
          })
        ],
        1
      ),
      _c("el-divider", { staticClass: "e-divider" }),
      _c("FunctionHeader", {
        staticStyle: { padding: "10px 0 10px 0" },
        attrs: {
          "search-title": "请输入质检单号/源单据号",
          tags: _vm.functionHeader.tags,
          "export-name": _vm.functionHeader.exportName,
          "import-name": _vm.functionHeader.importName
        },
        on: {
          search: function($event) {
            return _vm.$refs.mTable.setTableData(true)
          }
        },
        model: {
          value: _vm.functionHeader.searchVal,
          callback: function($$v) {
            _vm.$set(_vm.functionHeader, "searchVal", $$v)
          },
          expression: "functionHeader.searchVal"
        }
      }),
      _c("MTable", {
        ref: "mTable",
        attrs: {
          columns: _vm.columns,
          height: _vm.height,
          "set-data-method": _vm.getTableData
        },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function(ref) {
              var row = ref.row
              var $index = ref.$index
              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
            }
          },
          {
            key: "status",
            fn: function(ref) {
              var row = ref.row
              return _c("div", {}, [
                _c(
                  "div",
                  {
                    staticClass: "faultStatuss",
                    style: {
                      color: _vm.repairStatus[row.status]
                        ? _vm.repairStatus[row.status].color
                        : "",
                      background: _vm.repairStatus[row.status]
                        ? _vm.repairStatus[row.status].background
                        : ""
                    }
                  },
                  [_vm._v(_vm._s(_vm.repairStatus[row.status].name))]
                )
              ])
            }
          },
          {
            key: "planList",
            fn: function(ref) {
              var row = ref.row
              return _c("div", {}, [
                _vm._v(" " + _vm._s(_vm.fixData(row.planList)) + " ")
              ])
            }
          },
          {
            key: "action",
            fn: function(ref) {
              var row = ref.row
              return _c("div", {}, [
                _vm.permission("check") && row.status === 2
                  ? _c("a", { staticClass: "li-btn color-btns" }, [
                      _vm._v("创建计划")
                    ])
                  : _c(
                      "a",
                      {
                        staticClass: "li-btn",
                        on: {
                          click: function($event) {
                            return _vm.getRepair(row)
                          }
                        }
                      },
                      [_vm._v("创建计划")]
                    )
              ])
            }
          }
        ])
      }),
      _c("PrintResult", { ref: "printCodes" }),
      _c("repair-model", {
        attrs: {
          visible: _vm.repairVisible,
          "repair-record-id": _vm.repairRecordId,
          "old-process-flow-id": _vm.oldProcessFlowId,
          "produce-work-order-id": _vm.produceWorkOrderId,
          "base-form-data": _vm.baseFormData,
          "materials-id": _vm.materialsId
        },
        on: {
          "update:visible": function($event) {
            _vm.repairVisible = $event
          },
          loadWord: _vm.loadWords
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }