<template>
  <el-dialog title="待定需求" :visible.sync="dialogVisible" width="70%">
    <div class="inspection-plan-detail-wrapper">
      <MTable
        :height="500"
        :columns="waitDemandColumns"
        :show-page="false"
        :data="tableData||[]"
        :only-key="'uuid'"
      >
        <div slot="itemNumber" slot-scope="{ $index }">{{ $index + 1 }}</div>
        <NewPageOpen
          slot="materialsCode"
          slot-scope="{ row }"
          :path="{ name: 'materialsDetail', query: {id: row.materialsId}}"
          :text="row.materialsCode"
        />
        <NewPageOpen
          slot="procedureCode"
          slot-scope="{ row }"
          :path="{ name: 'procedureDetail', query: {id: row.procedureId}}"
          :text="row.procedureCode"
        />
        <el-input-number
          slot="productionQuantity"
          v-model="row.productionQuantity"
          slot-scope="{ row }"
          :min="0"
          :max="row.undeterminedRequireQuantity-row.purchaseQuantity"
          controls-position="right"
          :step="0.01"
          @blur="row.productionQuantity=sliceVal(row.productionQuantity,2)"
        />
        <el-input-number
          slot="purchaseQuantity"
          v-model="row.purchaseQuantity"
          slot-scope="{ row }"
          :min="0"
          :max="row.undeterminedRequireQuantity-row.productionQuantity"
          controls-position="right"
          :step="0.01"
          @blur="row.purchaseQuantity=sliceVal(row.purchaseQuantity,2)"
        />
      </MTable>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button style="float: left;" @click="confirm(1)">全部生产</el-button>
      <el-button style="float: left;" @click="confirm(2)">全部采购</el-button>
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirm(3)">确 认</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { sliceVal } from '@/utils'
import api from '@/api/production/project'
import { waitDemandColumns } from './data'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
export default {
  name: 'WaitDemand',
  components: { NewPageOpen },
  props: {
  },
  data() {
    return {
      isExport: true,
      dialogVisible: false,
      waitDemandColumns,
      detail: {},
      tableData: []
    }
  },
  methods: {
    sliceVal,
    async init(producePlanId) {
      const res = await api.getWaitDemandList({ producePlanId })
      res?.forEach(item => {
        this.$set(item, 'productionQuantity', 0)
        this.$set(item, 'purchaseQuantity', 0)
      })
      this.tableData = res
      this.dialogVisible = true
    },
    async confirm(type) {
      switch (type) {
        case 1:// 全部生产
          this.tableData.forEach(item => {
            item.productionQuantity = item.undeterminedRequireQuantity
            item.purchaseQuantity = 0
          })
          return
        case 2:// 全部采购
          this.tableData.forEach(item => {
            item.purchaseQuantity = item.undeterminedRequireQuantity
            item.productionQuantity = 0
          })
          return

        default:// 确认请购
          {
            const params = this.tableData.map(item => {
              const { id: itemId, productionQuantity, purchaseQuantity } = item
              const param = { itemId, productionQuantity, purchaseQuantity }
              return param
            })
            const res = await api.waitDemandPurchase(params)
            if (res) {
              this.$emit('refresh')
              this.$message.success('操作成功')
              this.dialogVisible = false
            }
          }
          break
      }
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-dialog__header {
  background: #f8f8fb;
  padding: 20px;

  .el-dialog__title {
    font-size: 16px;
  }

  .el-dialog__close {
    font-size: 20px;
    line-height: 24px;
  }
}

::v-deep .el-dialog__footer {
  height: 57px;
  border-top: 1px solid #dde3eb;
  button {
    padding: 10px 15px !important;

  }
}
::v-deep .el-table {
  .cell {
    display: flex;
    align-items: center;
  }
}
</style>
