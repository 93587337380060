var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home-wrap" },
    [
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "container-top common-pad"
          },
          [
            _c("div", { staticClass: "order-left" }, [
              _c("div", { staticClass: "left-tit" }, [
                _c("div", { staticClass: "title" }, [_vm._v("当前相关任务数")]),
                _c("div", { staticClass: "des" }, [
                  _vm._v(_vm._s(_vm.orderInfo.doingCount))
                ])
              ]),
              _c(
                "div",
                { staticClass: "left-cen" },
                _vm._l(_vm.allStatus, function(item) {
                  return _c("div", { key: item.id, staticClass: "cen-li" }, [
                    _c("div", {
                      staticClass: "tip",
                      style: { background: item.color }
                    }),
                    _c("div", { staticClass: "tit" }, [
                      _vm._v(_vm._s(item.name) + "：")
                    ]),
                    _c(
                      "div",
                      { staticClass: "desc", style: { color: item.color } },
                      [_vm._v(_vm._s(item.count))]
                    )
                  ])
                }),
                0
              ),
              _c("div", { staticClass: "left-bot" }, [
                _c(
                  "div",
                  {
                    staticClass: "tip",
                    style: { width: _vm.fixData(_vm.allStatus[0].count) + "%" }
                  },
                  [
                    _c("div", [
                      _vm._v(_vm._s(_vm.fixData(_vm.allStatus[0].count)) + "%")
                    ]),
                    _c("span")
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "tit",
                    style: { width: _vm.fixData(_vm.allStatus[1].count) + "%" }
                  },
                  [
                    _c("div"),
                    _c("span"),
                    _vm._v(
                      " " + _vm._s(_vm.fixData(_vm.allStatus[1].count)) + "% "
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "desc",
                    style: { width: _vm.fixData(_vm.allStatus[2].count) + "%" }
                  },
                  [
                    _c("div", [
                      _vm._v(_vm._s(_vm.fixData(_vm.allStatus[2].count)) + "%")
                    ]),
                    _c("span")
                  ]
                )
              ])
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading"
                  }
                ],
                staticClass: "order-cen"
              },
              [
                _c("div", { staticClass: "tit" }, [_vm._v("相关项目开工情况")]),
                _c("ve-pie", {
                  staticStyle: { margin: "0 auto" },
                  attrs: {
                    data: _vm.chartProjectStart,
                    extend: _vm.pieChartProjectStart,
                    width: "100%",
                    height: "230px"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "order-right" },
              [
                _c("div", { staticClass: "right-tit" }, [
                  _vm._v("个人完成任务情况")
                ]),
                _vm._l(_vm.allOrderNums, function(item) {
                  return _c("div", { key: item.id, staticClass: "right-bot" }, [
                    _c("div", { staticClass: "tip" }, [
                      _vm._v(_vm._s(item.name))
                    ]),
                    _c("div", { staticClass: "des" }, [
                      _vm._v(_vm._s(item.count))
                    ])
                  ])
                })
              ],
              2
            )
          ]
        ),
        _c("div", { staticClass: "container-center common-pad" }, [
          _c("div", { staticClass: "work-left" }, [
            _c(
              "div",
              { staticClass: "common-tit" },
              [
                _c("div", { staticClass: "tit" }, [_vm._v("任务执行数量统计")]),
                _c(
                  "el-radio-group",
                  {
                    staticClass: "m-radio-group m-radio-groups",
                    on: {
                      change: function($event) {
                        return _vm.radioChange(0)
                      }
                    },
                    model: {
                      value: _vm.params.radio[0],
                      callback: function($$v) {
                        _vm.$set(_vm.params.radio, 0, $$v)
                      },
                      expression: "params.radio[0]"
                    }
                  },
                  _vm._l(_vm.allDays, function(item) {
                    return _c(
                      "el-radio-button",
                      { key: item.id, attrs: { label: item.label } },
                      [_vm._v(_vm._s(item.name))]
                    )
                  }),
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "left-bot" }, [
              _c("div", { staticClass: "bot-li" }, [
                _c("div", { staticClass: "tit" }, [_vm._v("已完成任务数量")]),
                _c("div", { staticClass: "count" }, [
                  _vm._v(_vm._s(_vm.taskExecutionInfo.finishCount))
                ])
              ]),
              _c("div", { staticClass: "bot-li" }, [
                _c("div", { staticClass: "tit" }, [_vm._v("计划完成任务数量")]),
                _c("div", { staticClass: "count" }, [
                  _vm._v(_vm._s(_vm.taskExecutionInfo.planFinishCount))
                ])
              ]),
              _c(
                "div",
                {
                  staticClass: "bot-li",
                  staticStyle: {
                    background: "rgba(0, 171, 41, 0.1)",
                    "margin-right": "0"
                  }
                },
                [
                  _c("img", {
                    staticClass: "tip",
                    attrs: { src: require("@/assets/home/inspect.png") }
                  }),
                  _c("div", { staticClass: "tit" }, [_vm._v("达成率")]),
                  _c(
                    "div",
                    { staticClass: "count", staticStyle: { color: "#00B42E" } },
                    [_vm._v(_vm._s(_vm.taskExecutionInfo.finishRatio) + "%")]
                  )
                ]
              ),
              _c("div", { staticClass: "bot-li" }, [
                _c("div", { staticClass: "tit" }, [_vm._v("已延期任务数量")]),
                _c("div", { staticClass: "count" }, [
                  _vm._v(_vm._s(_vm.taskExecutionInfo.delayCount))
                ])
              ]),
              _c("div", { staticClass: "bot-li" }, [
                _c("div", { staticClass: "tit" }, [
                  _vm._v("3天后延期任务数量")
                ]),
                _c("div", { staticClass: "count" }, [
                  _vm._v(_vm._s(_vm.taskExecutionInfo.delayCountFor3days))
                ])
              ]),
              _c(
                "div",
                {
                  staticClass: "bot-li",
                  staticStyle: {
                    background: "rgba(224, 32, 32, 0.1)",
                    "margin-right": "0"
                  }
                },
                [
                  _c("img", {
                    staticClass: "tip",
                    attrs: { src: require("@/assets/home/no-inspect.png") }
                  }),
                  _c("div", { staticClass: "tit" }, [_vm._v("延期率")]),
                  _c(
                    "div",
                    { staticClass: "count", staticStyle: { color: "#E02020" } },
                    [_vm._v(_vm._s(_vm.taskExecutionInfo.delayRatio) + "%")]
                  )
                ]
              )
            ])
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticClass: "work-center"
            },
            [
              _c("div", { staticClass: "all-title" }, [
                _vm._v("今日待处理任务"),
                _c("span", { staticClass: "title-count" }, [
                  _vm._v("（" + _vm._s(_vm.todayWaitingTaskList.length) + "）")
                ])
              ]),
              _c("MTable", {
                ref: "mTable",
                attrs: {
                  data: _vm.todayWaitingTaskList,
                  height: 220,
                  columns: _vm.todayWaitingTaskColumns,
                  "columns-setting": false,
                  "show-page": false
                },
                scopedSlots: _vm._u([
                  {
                    key: "index",
                    fn: function(ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                    }
                  },
                  {
                    key: "action",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {},
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.taskPlanNodeDetail(row)
                                }
                              }
                            },
                            [_vm._v("详情")]
                          )
                        ],
                        1
                      )
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticClass: "work-right"
            },
            [
              _c("div", { staticClass: "all-title" }, [
                _vm._v("本周新增任务"),
                _c("span", { staticClass: "title-count" }, [
                  _vm._v("（" + _vm._s(_vm.thisWeekAddTaskList.length) + "）")
                ])
              ]),
              _c("MTable", {
                ref: "mTable",
                attrs: {
                  data: _vm.thisWeekAddTaskList,
                  height: 220,
                  columns: _vm.thisWeekAddTaskColumns,
                  "columns-setting": false,
                  "show-page": false
                },
                scopedSlots: _vm._u([
                  {
                    key: "index",
                    fn: function(ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                    }
                  },
                  {
                    key: "action",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {},
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.taskPlanNodeDetail(row)
                                }
                              }
                            },
                            [_vm._v("详情")]
                          )
                        ],
                        1
                      )
                    }
                  }
                ])
              })
            ],
            1
          )
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "work-left"
          },
          [
            _c("div", { staticClass: "all-title" }, [
              _vm._v("全部待处理任务"),
              _c("span", { staticClass: "title-count" }, [
                _vm._v("（" + _vm._s(_vm.allWaitingTaskList.length) + "）")
              ])
            ]),
            _c("MTable", {
              ref: "mTable",
              attrs: {
                data: _vm.allWaitingTaskList,
                height: 220,
                columns: _vm.allWaitingTaskColumns,
                "columns-setting": false,
                "show-page": false
              },
              scopedSlots: _vm._u([
                {
                  key: "index",
                  fn: function(ref) {
                    var row = ref.row
                    var $index = ref.$index
                    return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                  }
                },
                {
                  key: "action",
                  fn: function(ref) {
                    var row = ref.row
                    return _c(
                      "div",
                      {},
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.taskPlanNodeDetail(row)
                              }
                            }
                          },
                          [_vm._v("详情")]
                        )
                      ],
                      1
                    )
                  }
                }
              ])
            })
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }