import { render, staticRenderFns } from "./test.vue?vue&type=template&id=460a884b&scoped=true&class=povers&"
import script from "./test.vue?vue&type=script&lang=js&"
export * from "./test.vue?vue&type=script&lang=js&"
import style0 from "./test.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./test.vue?vue&type=style&index=1&id=460a884b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "460a884b",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/mubai-mom-frontend-dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('460a884b')) {
      api.createRecord('460a884b', component.options)
    } else {
      api.reload('460a884b', component.options)
    }
    module.hot.accept("./test.vue?vue&type=template&id=460a884b&scoped=true&class=povers&", function () {
      api.rerender('460a884b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/project/MRP/projectManagement/project-work-bench/components/test.vue"
export default component.exports