import { startFinishTimeAttribute, projectDeliveryType } from '@/config/options.config'
import { executionDepartment } from '@/views/plans/prod-plan-lists/data'
let functionList = {}
export const getFunctionList = val => {
  functionList = val
}
export const columns = [
  {
    prop: 'projectNumber',
    label: '项目编号'
  },
  {
    prop: 'projectName',
    label: '项目名称'
  },
  {
    prop: 'customerOrderNumber',
    label: '客户订单号'
  },
  {
    prop: 'customerName',
    label: '客户'
  },
  {
    prop: 'customerPrincipalName',
    label: '客户负责人'
  },
  {
    prop: 'deliveryType',
    label: '交付类型',
    form: { options: projectDeliveryType }
  },
  {
    prop: 'deliveryDate',
    label: '交付日期'
  },
  {
    prop: 'principalName',
    label: '负责人'
  },
  {
    prop: 'businessUnitName',
    label: '业务部门'
  },
  // {
  //   prop: 'executiveUnit',
  //   label: '执行部门',
  //   form: { options: executionDepartment }
  // },
  {
    prop: 'projectApprovalNumber',
    label: '立项编号'
  },
]

export const faultColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    type:'',
    hiddenSearch: true
  },
  // {
  //   prop: 'planObjectName',
  //   label: '计划对象',
  //   hiddenSearch: true,
  //   sortable: false
  // },
  {
    prop: 'planName',
    label: '节点名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'planTypeName',
    label: '节点类型',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'nodeAttribute',
    label: '节点属性',
    hiddenSearch: true,
    sortable: false,
    slotName: "nodeAttribute"
  },
  {
    prop: 'productionProgress',
    label: '进度',
    hiddenSearch: true,
    sortable: false,
    slotName:'productionProgress'
  },
  {
    prop: 'startTime',
    label: '计划开始时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'finishTime',
    label: '计划完成时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'actualStartTime',
    label: '实际开始时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'actualEndTime',
    label: '实际完成时间',
    hiddenSearch: true,
    sortable: false
  },
  // {
  //   prop: 'originalStartTime',
  //   label: '原本开始时间',
  //   hiddenSearch: true,
  //   sortable: false
  // },
  // {
  //   prop: 'originalFinishTime',
  //   label: '原本完成时间',
  //   hiddenSearch: true,
  //   sortable: false
  // },
  // {
  //   prop: 'adjustRecord',
  //   label: '调整记录',
  //   hiddenSearch: true,
  //   sortable: false,
  //   slotName: 'adjustRecord'
  // },
  {
    prop: 'principalName',
    label: '负责人',
    hiddenSearch: true,
    sortable: false,
    slotName:'principalName'
  },
  {
    prop: 'principalDepartName',
    label: '负责部门',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'remark',
    label: '备注',
    hiddenSearch: true,
    sortable: false
  },
  {
    slotName: 'projectPlanNodeAction',
    label: '操作',
    sortable: false,
    hiddenSearch: true,
    fixed: 'right'
  }
]

export const planNodeFormColumns = [
  {
    prop: 'planObjectName',
    label: '计划对象',
    hiddenSearch: true,
    sortable: false,
      form: {
        tagName: 'el-select',
        key:'planObjectId',
        options: []
    }
  },
  {
    prop: 'planName',
    label: '计划名称',
    hiddenSearch: true,
    sortable: false,
    form: { tagName: 'el-input', required: true}
  },
  {
    prop: 'planTypeName',
    label: '计划类型',
    hiddenSearch: true,
    sortable: false,
    form: {
      tagName: 'div',
      customSlots: ['processFlowClassName']
    }
  },
  {
    prop: 'principalName',
    label: '负责人',
    hiddenSearch: true,
    sortable: false,
    slotName:'principalName',
    form: { key: 'principalId', tagName: 'el-select',
    optionsProps: {
      label: 'userFullName',
      value: 'id'
    },options: [], required: false,
    props: {
      multiple: true
     }}
  },
  {
    prop: 'startTimeAttribute',
    label: '开始时间属性',
    hiddenSearch: true,
    sortable: false,
    slotName:'startTimeAttribute',
    minWidth:100,
    form: {
      tagName: 'el-select',
      options: startFinishTimeAttribute,
      required: false,
      on: { change: (val) => { functionList.startTimeAttributeChange(val) } }
    }
  },
  {
    prop: 'startTime',
    label: '开始时间',
    hiddenSearch: true,
    sortable: false,
    form:{
      tagName: 'div',
      required: false,
      customSlots: ['startTime']
    }
  },
  {
    prop: 'finishTimeAttribute',
    label: '完成时间属性',
    hiddenSearch: true,
    sortable: false,
    slotName:'finishTimeAttribute',
    minWidth:100,
    form: {
      tagName: 'el-select',
      options: startFinishTimeAttribute, required: false,
      on: { change: (val) => { functionList.finishTimeAttributeChange(val) } }
    }
  },
  {
    prop: 'finishTime',
    label: '完成时间',
    hiddenSearch: true,
    sortable: false,
    form:{
      tagName: 'div',
      required: false,
      customSlots: ['finishTime']
    }
  },
  {
    prop: 'remark',
    label: '备注',
    hiddenSearch: true,
    sortable: false,
    form: { tagName: 'el-input', required: false}
  }
]

export const repairColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'specifications',
    label: '规格',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'quantity',
    label: '数量',
    hiddenSearch: true,
    sortable: false,
    slotName:'quantity',
    minWidth:100
  },
  {
    prop: 'mainUnitName',
    label: '主单位',
    hiddenSearch: true,
    sortable: false,
    minWidth:100
  },
  {
    prop: 'materialsClassName',
    label: '物料分类',
    hiddenSearch: true,
    sortable: false,
    minWidth:120
  },
  {
    prop: 'materialsTypeName',
    label: '物料类型',
    hiddenSearch: true,
    sortable: false,
    minWidth:120
  },
  {
    prop: 'materialsAttributeName',
    label: '物料属性',
    hiddenSearch: true,
    sortable: false,
    minWidth:120
  },
  {
    slotName: 'action',
    label: '操作',
    sortable: false,
    hiddenSearch: true,
    fixed: 'right'
  }
]

// 项目工单
export const workColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    hiddenSearch: true,
    sortable: false,
    width: 120,
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false,
    width: 200,
  },
  {
    prop: 'workOrderNumber',
    label: '工单编号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'mainUnitName',
    label: '单位',
    hiddenSearch: true,
    sortable: false,
    minWidth:80
  },
  {
    prop: 'grossQuantity',
    label: '毛需求',
    hiddenSearch: true,
    sortable: false,
    minWidth:80
  },
  {
    prop: 'lockedStock',
    label: '占用库存',
    hiddenSearch: true,
    sortable: false,
    minWidth:80
  },
  {
    prop: 'quantity',
    label: '数量',
    hiddenSearch: true,
    sortable: false,
    minWidth:80
  },
  {
    prop: 'materialsRequisitionTempo',
    label: '物料进度(%)',
    hiddenSearch: true,
    sortable: false,
    minWidth:80
  },
  {
    prop: 'productionTempo',
    label: '报工进度(%)',
    hiddenSearch: true,
    sortable: false,
    minWidth:80
  },
  {
    prop: 'workOrderStatus',
    label: '工单状态',
    hiddenSearch: true,
    sortable: false,
    slotName:'workOrderStatus',
    minWidth:100
  },
  // {
  //   prop: 'materialsDifferent',
  //   label: '物料差异',
  //   hiddenSearch: true,
  //   sortable: false,
  //   minWidth:100
  // },
  {
    prop: 'planStartTime',
    label: '计划开工时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'planEndTime',
    label: '计划完工时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'finishedQuantity',
    label: '完工数量',
    hiddenSearch: true,
    sortable: false,
    minWidth:80
  },
  {
    slotName: 'workAction',
    label: '操作',
    sortable: false,
    hiddenSearch: true,
    fixed: 'right'
  }
]

// 项目变更
export const ProjectEcnColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'prePlanChangeNumber',
    label: '变更单号',
    hiddenSearch: true,
    sortable: false,
    slotName: 'prePlanChangeNumber'
  },
  {
    prop: 'changeType',
    label: '变更类型',
    hiddenSearch: true,
    sortable: false,
    slotName: 'changeType'
  },
  // {
  //   prop: 'projectNumber',
  //   label: '项目编号',
  //   hiddenSearch: true,
  //   sortable: false
  // },
  // {
  //   prop: 'projectName',
  //   label: '项目名称',
  //   hiddenSearch: true,
  //   sortable: false
  // },
  // {
  //   prop: 'projectPrincipalName',
  //   label: '项目负责人',
  //   hiddenSearch: true,
  //   sortable: false
  // },
  {
    prop: 'materialName',
    label: '机台号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'status',
    label: '变更状态',
    hiddenSearch: true,
    sortable: false,
    slotName: 'status'
  },
  {
    prop: 'needOperate',
    label: '是否需要执行',
    hiddenSearch: true,
    sortable: false,
    slotName: 'needOperate'
  },
  {
    prop: 'businessUnitName',
    label: '业务部门',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'operateTime',
    label: '计划执行时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'creator',
    label: '创建人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'createTime',
    label: '创建时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    slotName: 'projectEcnAction',
    label: '操作',
    sortable: false,
    hiddenSearch: true,
    fixed: 'right'
  }
]

// 项目异常
export const projectAbnormalColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'abnormalOrderCode',
    label: '异常单号',
    hiddenSearch: true,
    sortable: false,
    slotName: 'abnormalOrderCode'
  },
  // {
  //   prop: 'businessUnitName',
  //   label: '业务部门',
  //   hiddenSearch: true,
  //   sortable: false
  // },
  // {
  //   prop: 'majorType',
  //   label: '专业',
  //   hiddenSearch: true,
  //   sortable: false,
  //   slotName: 'majorType'
  // },
  {
    prop: 'materialName',
    label: '机台名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'emergencyDegree',
    label: '紧急程度',
    hiddenSearch: true,
    sortable: false,
    slotName: 'emergencyDegree'
  },
  // {
  //   prop: 'currentProcess',
  //   label: '当前节点',
  //   hiddenSearch: true,
  //   sortable: false
  // },
  // {
  //   prop: 'currentProcessUser',
  //   label: '当前节点责任人',
  //   hiddenSearch: true,
  //   sortable: false
  // },
  {
    prop: 'status',
    label: '状态',
    hiddenSearch: true,
    sortable: false,
    slotName: 'status'
  },
  {
    prop: 'abnormalTypeName',
    label: '异常类型',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'abnormalRemark',
    label: '异常现象',
    hiddenSearch: true,
    sortable: false
  },
  // {
  //   prop: 'judgeResult',
  //   label: '判定结果',
  //   hiddenSearch: true,
  //   sortable: false,
  //   slotName: 'judgeResult'
  // },
  // {
  //   prop: 'dutyPowerName',
  //   label: '责任方',
  //   hiddenSearch: true,
  //   sortable: false
  // },
  // {
  //   prop: 'dutySupplierName',
  //   label: '归属供应商',
  //   hiddenSearch: true,
  //   sortable: false
  // },
  {
    prop: 'abnormalReasonName',
    label: '异常原因',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'reason',
    label: '原因分析',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'tempPolicy',
    label: '处理措施',
    hiddenSearch: true,
    sortable: false
  },
  // {
  //   prop: 'finalResult',
  //   label: '最终结果',
  //   hiddenSearch: true,
  //   sortable: false,
  //   slotName: 'finalResult'
  // },
  {
    prop: 'createUser',
    label: '创建人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'createTime',
    label: '创建时间',
    hiddenSearch: true,
    sortable: false
  },
  // {
  //   prop: 'initiatorDepart',
  //   label: '发起部门',
  //   hiddenSearch: true,
  //   sortable: false
  // },
  {
    slotName: 'projectAbnormalAction',
    label: '操作',
    sortable: false,
    hiddenSearch: true,
    fixed: 'right'
  }
]

// 调整记录
export const adjustRecordColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'planName',
    label: '计划名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'adjustBeforeStartTime',
    label: '调整前开始时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'adjustBeforeFinishTime',
    label: '调整前完成时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'adjustAfterStartTime',
    label: '调整后开始时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'adjustAfterFinishTime',
    label: '调整后完成时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'adjustReason',
    label: '调整原因',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'adjustUserName',
    label: '调整人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'adjustTime',
    label: '调整时间',
    hiddenSearch: true,
    sortable: false
  },
]
// 用料记录
export const materialRecordsColumns =[
    {
      prop: 'index',
      label: '序号',
      sortable: true,
      minWidth: 80,
      hiddenSearch: true,
      slotName: 'index'
    },
    {
      prop: 'code',
      label: '料单号',
      sortable: false,
      hiddenSearch: true,
      minWidth: 160
    },
    {
      prop: 'name',
      label: '料单名称',
      sortable: false,
      hiddenSearch: true,
      minWidth: 160
    },
    {
      prop: 'receiptType',
      label: '单据类型',
      sortable: false,
      slotName: 'receiptType',
      hiddenSearch: true
    },
    {
      prop: 'directionType',
      label: '方向',
      sortable: false,
      hiddenSearch: true,
      slotName: 'directionType',
      minWidth: 160
      },
    {
      prop: 'receiptStatus',
      label: '状态',
      sortable: false,
      slotName: 'receiptStatus',
      hiddenSearch: true,
      minWidth: 120
    },
    {
      prop: 'applyQuantity',
      label: '申请数量',
      sortable: false,
      hiddenSearch: true,
      minWidth: 100
    },
    {
      prop: 'receivedQuantity',
      label: '完成数量',
      sortable: false,
      hiddenSearch: true,
      minWidth: 100
    },
    {
      prop: 'notReceiveQuantity',
      label: '未完数量',
      sortable: false,
      hiddenSearch: true,
      minWidth: 100
    },
    {
      prop: 'requireDate',
      label: '时间',
      sortable: false,
      hiddenSearch: true,
      minWidth: 120
    },
    {
        prop: 'receiptNumber',
        label: '工单编号',
        sortable: false,
        hiddenSearch: true
      },
      {
        prop: 'receiptName',
        label: '工单名称',
        sortable: false,
        hiddenSearch: true
      },
    // {
    //   prop: 'projectNumber',
    //   label: '项目编号',
    //   sortable: false,
    //   hiddenSearch: true
    // },
    // {
    //   prop: 'projectName',
    //   label: '项目名称',
    //   hiddenSearch: true,
    //   sortable: false
    // },
    {
      prop: 'remark',
      label: '备注',
      sortable: false,
      hiddenSearch: true
    }
  ]
// 齐套分析
export const completeAnalysisColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    sortable: true,
    hiddenSearch: true,
    minWidth: 80
  },
  {
    prop: 'materialsTypeName',
    label: '物料类型',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    sortable: false,
    hiddenSearch: true,
    minWidth: 180
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true,
    minWidth: 180
  },
  {
      prop: 'specifications',
      label: '物料规格',
      sortable: false,
      hiddenSearch: true,
      minWidth: 120
    },
  {
    prop: 'mainUnitName',
    label: '主单位',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'requirementQuantity',
    label: '需求数量',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  // {
  //     prop: '',
  //     label: '',
  //     sortable: false,
  //     hiddenSearch: true,
  //     headerSlotName: 'headerSlot',
  //     slotName:'viewDetail',
  //     minWidth: 120
  //   },
  {
      prop: 'missMaterialsQuantity',
      label: '缺料数量',
      sortable: false,
      hiddenSearch: true,
      minWidth: 120
    },
    {
      prop: 'missMaterialsRate',
      label: '缺料率',
      sortable: false,
      hiddenSearch: true,
      minWidth: 120,
      slotName:'missMaterialsRate'
    },
    {
      prop: 'supplyQuantity',
      label: '供应数量',
      sortable: false,
      hiddenSearch: true,
      minWidth: 120
    },
    {
      prop: 'stockQuantity',
      label: '库存数量',
      sortable: false,
      hiddenSearch: true,
      minWidth: 120
    }
]
// 项目工时
export const workHourStatisticsColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    fixed: 'left',
    width: 60
  },
  {
    prop: 'producePlanNumber',
    label: '生产工单号',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  // {
  //   prop: 'projectName',
  //   label: '项目名称',
  //   sortable: false,
  //   hiddenSearch: true,
  //   width: 120
  // },
  {
    prop: 'materialsName',
    label: '设备名称',
    sortable: false,
    hiddenSearch: true,
    width: 200
  },
  {
    prop: 'userName',
    label: '人员',
    sortable: false,
    hiddenSearch: true,
    width: 200
  },
  {
    prop: 'planStartTime',
    label: '开始时间',
    sortable: false,
    hiddenSearch: true,
    width: 280
  },
  {
    prop: 'planEndTime',
    label: '结束时间',
    sortable: false,
    hiddenSearch: true,
    width: 280
  },
  {
    prop: 'workHours',
    label: '工时/h',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    label: '工序名称',
    sortable: false,
    prop: 'procedureName',
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'type',
    slotName: 'type',
    label: '工时类型',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'workHoursReasonName',
    label: '工时原因',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'sourceType',
    slotName: 'sourceType',
    label: '来源',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'userGroupName',
    label: '用户组',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'userType',
    slotName: 'userType',
    label: '员工类型',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'createAt',
    label: '创建时间',
    sortable: false,
    hiddenSearch: true,
    width: 180
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    width: 120
  }
]
