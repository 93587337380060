import { projectTaskStatusOptions } from './columns'

// 项目任务表单搜索字段
export const searchFormList = [
  {
    colSpan: 6,
    key: 'projectNumber',
    tagName: 'el-input',
    attrs: {
      placeholder: '所属项目编号'
    }
  },
  {
    colSpan: 6,
    key: 'projectName',
    tagName: 'el-input',
    attrs: {
      placeholder: '所属项目名称'
    }
  },
  {
    colSpan: 6,
    key: 'planTypeId',
    tagName: 'el-select',
    props: {
      placeholder: '任务类别'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 6,
    key: 'status',
    tagName: 'el-select',
    props: {
      multiple: true,
      placeholder: '任务状态'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: projectTaskStatusOptions
      }
    ]
  },
  {
    colSpan: 8,
    key: 'planStartTime',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '计划开始的开始时间',
      endPlaceholder: '结束时间',
      align: 'center',
      type: 'daterange',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm:ss',
      defaultTime: ['00:00:00', '23:59:59']
    }
  },
  {
    colSpan: 8,
    key: 'planEndTime',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '计划结束的开始时间',
      endPlaceholder: '结束时间',
      align: 'center',
      type: 'daterange',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm:ss',
      defaultTime: ['00:00:00', '23:59:59']
    }
  },
  {
    colSpan: 8,
    key: 'actualStartTime',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '实际开始的开始时间',
      endPlaceholder: '结束时间',
      align: 'center',
      type: 'daterange',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm:ss',
      defaultTime: ['00:00:00', '23:59:59']
    }
  },
  {
    colSpan: 8,
    key: 'actualEndTime',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '实际结束的开始时间',
      endPlaceholder: '结束时间',
      align: 'center',
      type: 'daterange',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm:ss',
      defaultTime: ['00:00:00', '23:59:59']
    }
  },
  {
    colSpan: 8,
    key: 'updateTime',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '更新时间的开始时间',
      endPlaceholder: '结束时间',
      align: 'center',
      type: 'daterange',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm:ss',
      defaultTime: ['00:00:00', '23:59:59']
    }
  },
  {
    colSpan: 8,
    key: 'createTime',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '创建时间的开始时间',
      endPlaceholder: '结束时间',
      align: 'center',
      type: 'daterange',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm:ss',
      defaultTime: ['00:00:00', '23:59:59']
    }
  }
]

