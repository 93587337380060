<template>
    <div class="unit-wrap common-tab">
      <el-radio-group v-model="params.radio" class="m-radio-group" @change="radioChange">
        <el-radio-button label="Organization">任务列表</el-radio-button>
        <el-radio-button label="OrganizationType">待我处理</el-radio-button>
        <el-radio-button label="initiated">我已处理</el-radio-button>
      </el-radio-group>
      <div class="top">
        <SearchForm
          ref="searchForm"
          :form-list-extend="searchFormList"
          :form-data="searchFormData"
          style="padding: 10px"
          :default-form="defaultForm[params.radio]"
          @update:formData="data=>searchFormData=data"
          @search="reSearch"
        />
        <FunctionHeader
          v-model="functionHeader.searchVal"
          search-title="请输入任务名称"
          :radio="params.radio"
          :tags="functionHeader[`${params.radio}Tags`]"
          :columns-key="columnsKey"
          @search="$refs.mTable.setTableData(true)"
        />
        <MTable
          ref="mTable"
          :columns="columns[params.radio]"
          :height="height"
          :columns-key="columnsKey"
          :set-data-method="getTableData"
          :columns-setting="true"
        >
          <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
          <div slot="status" slot-scope="{row }">{{ projectTaskStatusMap[row.status ] }}</div>
          <div slot="principalName" slot-scope="{row }">
            <el-tooltip effect="dark" :content="row.principalName"  placement="top-end">
              <span class="mr-10">
                {{ row.principalName }}
             </span>
            </el-tooltip>
          </div>
          <div slot="action" slot-scope="{ row }">
            <template>
              <el-button v-if="permission('Detail')" type="text" @click="viewDetail(row)">详情</el-button>
              <el-button v-if="permission('Start') && type === 1 && row.status === 1" type="text" @click="handle(row)">开始任务</el-button>
              <el-button v-if="permission('AddReport') && type === 1 && row.status === 2" type="text" @click="report(row, 'addReport')">汇报</el-button>
              <el-button v-if="permission('Finish') && type === 1 && row.status === 2" type="text" @click="handle(row)">完成任务</el-button>
            </template>
          </div>
        </MTable>
      </div>
      <MDialog v-model="visible" :title="titles[currentType]" @onOk="submitForm">
        <MFormBuilder ref="formBuild" :form-data="reportFormData" :form-list="reportFormList">
           <el-upload slot="drawing" :file-list="attachments" v-bind="uploadProps">
             <el-button type="primary" size="small">点击上传</el-button>
           </el-upload>
        </MFormBuilder>
      </MDialog>
    </div>
  </template>

  <script>
  import DelPopover from '@/components/DelPopover/index'
  import FunctionHeader from '@/components/FunctionHeader/index'
  import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
  import SearchForm from '@/components/SearchForm/SearchForm'
  import { Organization, OrganizationType, initiated, projectTaskStatusMap} from './columns'
  import { searchFormList } from './form-list'
  import { reportFormList } from './detail/form-list'
  import conventionMixin from '@/mixin/conventional-page'
  import api from '@/api/projectManagement/project'
  import { getToken } from '@/utils/auth'

  export default {
    name: 'ProjectTaskList',
    components: { NewPageOpen, DelPopover, FunctionHeader, SearchForm},
    mixins: [conventionMixin],
    filters: {},
    data() {
      return {
        api,
        searchFormList,
        reportFormList,
        projectTaskStatusMap,
        searchFormData: {},
        params: {
          radio: 'Organization'
        },
        columns: {
          Organization,
          OrganizationType,
          initiated
        },
        functionHeader: {
          searchVal: '',
          tags: [
          ],
          OrganizationTags: [
          ],
          OrganizationTypeTags: [
          ],
          initiatedTags:[]
        },
        currentType: 'addReport',
        titles: {
          addReport: '汇报',
        },
        visible: false,
        reportFormData: {},
        dialogFormList: [],
        type: 0,
        defaultForm: {
          Organization :{status: [1, 2]},
          OrganizationType: {status: [1, 2]},
          initiated: {status: [3]}
      },
        isPageLoaded:false,
        uploadProps: {
          action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
          headers: { token: getToken() },
          beforeUpload: this.beforeUpload,
          onSuccess: this.fileUploadChange,
          onError: this.fileUploadChange,
          onRemove: this.removeFile
        },
        attachments: [],
      }
    },
    computed: {
      height() {
        return this.$store.state.app.contentHeight - 245
      },
      searchKeyword() {
        return this.params.radio === 'Organization'
      },
      columnsKey() {
       return this.$route.name + this.params.radio
      },
    },
    created() {
      this.getPlanTypeList()
    },
    activated(){
    if(!this.isPageLoaded){
      this.$refs.mTable.setTableData()
    }
    this.isPageLoaded = false
    },
    mounted() {
      this.isPageLoaded = true
    },

    methods: {
      async getTableData(condition, callback) {
        const { page } = condition
        const {  planStartTime, planEndTime, actualStartTime, actualEndTime, createTime, updateTime, ...data } = this.searchFormData
        const params = {
          search: this.functionHeader.searchVal,
          planStartTimePre: planStartTime ? planStartTime[0] : null,
          planStartTimeSub: planStartTime ? planStartTime[1] : null,
          planEndTimePre: planEndTime ? planEndTime[0] : null,
          planEndTimeSub: planEndTime ? planEndTime[1] : null,
          actualStartTimePre: actualStartTime ? actualStartTime[0] : null,
          actualStartTimeSub: actualStartTime ? actualStartTime[1] : null,
          actualEndTimePre: actualEndTime ? actualEndTime[0] : null,
          actualEndTimeSub: actualEndTime ? actualEndTime[1] : null,
          updateTimePre: updateTime ? updateTime[0] : null,
          updateTimeSub: updateTime ? updateTime[1] : null,
          createTimePre: createTime ? createTime[0] : null,
          createTimeSub: createTime ? createTime[1] : null,
          type: this.type,
          ...data
        }
        const res = await api.getProjectTaskDispatchListPage({
            page: page.currentPage,
            size: page.pageSize,
          ...params
        })

        if (res) {
          callback({
            total: res.total,
            content: res.records
          })
        }
        callback()
      },
      async getPlanTypeList() {
        const res = await api.getPlanType()
        if (res) {
          this.searchFormList[2].children[0].options = res
        }
      },
      viewDetail(row){
        this.$router.push({ name: 'ProjectTaskDetail',query:{id:row.id,type:'view'}})
      },
      async getDetail(id) {
        const res = await api.getProjectTaskDetail({ id: id })
        if(res){
          this.attachments = JSON.parse(res.attachments) || []
          this.reportFormData = res;
          this.reportFormData.workHours = 0
          this.reportFormData.remark = res.reportRemark;
        }
      },
      report(row, command) {
        this.getDetail(row.id);
        this.currentType = command
        this.visible = true
      },
      handle(row) {
        let message = row.status === 1 ? "开始" : "完成";
        this.$confirm('是否' + message+ '该任务？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {

          const res = await api.projectTaskChangeStatus({ status: row.status + 1, id: row.id })
          if (res) {
            this.$message.success('操作成功')
            this.$refs.mTable.tableRefs().clearSelection()
            this.$refs.mTable.setTableData()
          }
        })
      },

      radioChange() {
        if(this.params.radio === 'Organization'){
            this.type = 0
        }else if(this.params.radio === 'OrganizationType'){
            this.type = 1
        }else{
            this.type = 2
        }
        this.$refs.mTable.setTableData(true)
      },
      reSearch(data, val) {
        if (val === 1) {
          this.searchFormData = {}
        }
        setTimeout(() => {
          this.$refs.mTable.setTableData(true)
        }, this.searchFormData ?.status?.length === 2 ? 300 : 0)
      },
      beforeUpload(file) {
        const isLt20M = file.size / 1024 / 1024 < 20
        if (!isLt20M) {
          this.$message.error('上传文件大小不能超过 20M!')
        }
        return isLt20M
      },
      fileUploadChange(res, file) {
        if (res.errorCode === 0) {
          this.$message.success('上传成功')
          this.attachments.push({
            id: file.uid,
            name: file.name,
            url: res.result
          })
        } else {
          this.$message.error(res.result)
        }
      },
      removeFile(file) {
        this.attachments = this.attachments.filter(item => item.uid !== file.uid)
      },
      createSetData(raw) {
        return {
          processPercent: raw.processPercent,
          workHours: raw.workHours,
          relatedFiles: raw.relatedFiles,
          remark: raw.remark,
          attachments: JSON.stringify(this.attachments),
          planNodeId: raw.id
        }
      },
      createResetData(raw) {
        const obj = {
          id: raw.id
        }
        return Object.assign(this.createSetData(raw), obj)
      },
      async submitForm(callback) {
        const { form } = this.$refs.formBuild
        this.$refs.formBuild.formRefs().validate(async(valid) => {
          if (valid) {
            const res = await this.api.addPlanNodeReport(this.createResetData(form))
            if (res) {
              this.visible = false
            }
            callback(true)
          } else {
            callback()
          }
        })
      },
    }
  }
  </script>

  <style scoped lang="scss">
  .unit-wrap {
    padding: 10px;
  }
  </style>
