
export const orderStatusMap = {
  Waiting: '待下发',
  Dispatched: '已下发',
  Completed: '已完结',
  Cancelled: '已取消'
}

export const orderStatusColorMap = {
  Waiting: 'gray',
  Dispatched: 'blue',
  Completed: 'green',
  Cancelled: 'red'
}

export const orderStatus = Object.keys(orderStatusMap).map(key => {
  return { key: key, name: orderStatusMap[key] }
})

export const receivedStatusMap = {
  None: '未收货',
  Part: '部分收货',
  All: '全部收货'
}

export const receivedStatus = Object.keys(receivedStatusMap).map(key => {
  return { key: key, name: receivedStatusMap[key] }
})

// 列表
export const columnsAll = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    fixed: 'left',
    minWidth: 80
  },
  {
    prop: 'code',
    label: '采购订单编号',
    hiddenSearch: true,
    sortable: false,
    slotName: 'code',
    fixed: 'left',
    minWidth: 150
  },
  {
    prop: 'supplierCode',
    label: '供应商编号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'supplierName',
    label: '供应商名称',
    hiddenSearch: true,
    sortable: false,
    minWidth: 100
  },
  {
    prop: 'orderStatus',
    label: '订单状态',
    hiddenSearch: true,
    sortable: false,
    slotName: 'orderStatus',
    minWidth: 100
  },
  {
    prop: 'receivedStatus',
    label: '收货状态',
    hiddenSearch: true,
    sortable: false,
    slotName: 'receivedStatus',
    minWidth: 150
  },
  {
    prop: 'createUser',
    label: '创建人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'createAt',
    label: '创建时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'updateUser',
    label: '更新人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'updateAt',
    label: '更新时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 210
  }
]

// 新增页面物料选择表格
export const columnsOrder = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'code',
    label: '物料编码',
    hiddenSearch: true,
    sortable: false,
    width: 150
  },
  {
    prop: 'name',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsClassName',
    label: '物料分类',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsTypeName',
    label: '物料类型',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsAttributeName',
    label: '物料属性',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'specifications',
    label: '规格',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'purchaseQuantity',
    label: '采购数量',
    hiddenSearch: true,
    sortable: false,
    slotName: 'purchaseQuantity'
  },
  {
    prop: 'mainUnitName',
    label: '单位',
    hiddenSearch: true,
    sortable: false,
    width: 100
  },
  {
    prop: 'wantReceiveTime',
    label: '合同到货时间',
    hiddenSearch: true,
    sortable: false,
    slotName: 'wantReceiveTime',
    width: 244
  },
  {
    prop: 'confirmReceiveTime',
    label: '供应商确认到货时间',
    hiddenSearch: true,
    sortable: false,
    slotName: 'confirmReceiveTime',
    width: 244
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 100
  }
]

export const detailColumns = [
  {
    label: '采购订单编号',
    key: 'code'
  }, {
    label: '供应商编码',
    key: 'supplierCode'
  }, {
    label: '供应商名称',
    key: 'supplierName'
  }, {
    label: '收货状态',
    key: 'receivedStatus'
  }, {
    label: '订单状态',
    key: 'orderStatus'
  }, {
    label: '创建人',
    key: 'createUser'
  }, {
    label: '创建时间',
    key: 'createAt'
  }, {
    label: '更新人',
    key: 'updateUser'
  }, {
    label: '更新时间',
    key: 'updateAt'
  }
]

export const detailListColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    fixed: 'left',
    minWidth: 80
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    hiddenSearch: true,
    sortable: false,
    slotName: 'materialsCode',
    minWidth: 150
  },
  {
    prop: 'name',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'materialsClassName',
    label: '物料分类',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsTypeName',
    label: '物料类型',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsAttributeName',
    label: '物料属性',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'specifications',
    label: '规格',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'purchaseQuantity',
    label: '采购数量',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'mainUnitName',
    label: '单位',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'wantReceiveTime',
    label: '合同到货时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'confirmReceiveTime',
    label: '供应商确认到货时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'receivedQuantity',
    label: '已到货数量',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'receivedProgress',
    label: '到货进度',
    hiddenSearch: true,
    sortable: false,
    slotName: 'receivedProgress',
    minWidth: 150
  }
]

export const searchFormList = [
  {
    colSpan: 4,
    key: 'receivedStatus',
    tagName: 'el-select',
    props: {
      placeholder: '收货状态',
      configFilter: true
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name', // 自定义label需要字段值与configLabel字段值一致
          value: 'key'
        },
        options: receivedStatus
      }
    ]
  },
  {
    colSpan: 4,
    key: 'orderStatus',
    tagName: 'el-select',
    props: {
      multiple: true,
      placeholder: '订单状态'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name', // 自定义label需要字段值与configLabel字段值一致
          value: 'key'
        },
        options: orderStatus
      }
    ]
  },
  {
    colSpan: 4,
    key: 'createBy',
    tagName: 'el-select',
    props: {
      multiple: true,
      placeholder: '创建人'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: [],
        apiName: ''
      }
    ]
  },
  {
    colSpan: 8,
    key: 'createDateRange',
    tagName: 'el-date-picker',
    defaultValue: 1,
    props: {
      rangeSeparator: '至',
      startPlaceholder: '创建日期开始时间',
      endPlaceholder: '结束日期',
      align: 'center',
      type: 'daterange',
      pickerOptions: [],
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      defaultTime: ['00:00:00', '23:59:59']
      // fullTimeVal: ['00:00:00', '23:59:59']
    }
  },
  {
    colSpan: 4,
    key: 'updateBy',
    tagName: 'el-select',
    props: {
      multiple: true,
      placeholder: '更新人'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: [],
        apiName: ''
      }
    ]
  },
  {
    colSpan: 8,
    key: 'updateDateRange',
    tagName: 'el-date-picker',
    defaultValue: 1,
    props: {
      rangeSeparator: '至',
      startPlaceholder: '更新日期开始时间',
      endPlaceholder: '结束日期',
      align: 'center',
      type: 'daterange',
      pickerOptions: [],
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      defaultTime: ['00:00:00', '23:59:59']
    }
  },
  {
    colSpan: 4,
    key: 'supplierId',
    tagName: 'el-select',
    props: {
      placeholder: '供应商',
      multiple: true,
      remote: true
    },
    directives: [
      { name: 'selectScrollEnd',
        value: () => {
          console.log('滚动触底了，可以请求下一页了')
        }
      }
    ],
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'fullName',
          value: 'id'
        },
        options: [],
        apiName: ''
      }
    ]
  }
]
