var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "unit-wrap common-tab" },
    [
      _c(
        "el-radio-group",
        {
          staticClass: "m-radio-group",
          on: { change: _vm.radioChange },
          model: {
            value: _vm.params.radio,
            callback: function($$v) {
              _vm.$set(_vm.params, "radio", $$v)
            },
            expression: "params.radio"
          }
        },
        [
          _c("el-radio-button", { attrs: { label: "Organization" } }, [
            _vm._v("任务列表")
          ]),
          _c("el-radio-button", { attrs: { label: "OrganizationType" } }, [
            _vm._v("待我处理")
          ]),
          _c("el-radio-button", { attrs: { label: "initiated" } }, [
            _vm._v("我已处理")
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "top" },
        [
          _c("SearchForm", {
            ref: "searchForm",
            staticStyle: { padding: "10px" },
            attrs: {
              "form-list-extend": _vm.searchFormList,
              "form-data": _vm.searchFormData,
              "default-form": _vm.defaultForm[_vm.params.radio]
            },
            on: {
              "update:formData": function(data) {
                return (_vm.searchFormData = data)
              },
              search: _vm.reSearch
            }
          }),
          _c("FunctionHeader", {
            attrs: {
              "search-title": "请输入任务名称",
              radio: _vm.params.radio,
              tags: _vm.functionHeader[_vm.params.radio + "Tags"],
              "columns-key": _vm.columnsKey
            },
            on: {
              search: function($event) {
                return _vm.$refs.mTable.setTableData(true)
              }
            },
            model: {
              value: _vm.functionHeader.searchVal,
              callback: function($$v) {
                _vm.$set(_vm.functionHeader, "searchVal", $$v)
              },
              expression: "functionHeader.searchVal"
            }
          }),
          _c("MTable", {
            ref: "mTable",
            attrs: {
              columns: _vm.columns[_vm.params.radio],
              height: _vm.height,
              "columns-key": _vm.columnsKey,
              "set-data-method": _vm.getTableData,
              "columns-setting": true
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "status",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(_vm._s(_vm.projectTaskStatusMap[row.status]))
                  ])
                }
              },
              {
                key: "principalName",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            content: row.principalName,
                            placement: "top-end"
                          }
                        },
                        [
                          _c("span", { staticClass: "mr-10" }, [
                            _vm._v(" " + _vm._s(row.principalName) + " ")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                }
              },
              {
                key: "action",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      [
                        _vm.permission("Detail")
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.viewDetail(row)
                                  }
                                }
                              },
                              [_vm._v("详情")]
                            )
                          : _vm._e(),
                        _vm.permission("Start") &&
                        _vm.type === 1 &&
                        row.status === 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.handle(row)
                                  }
                                }
                              },
                              [_vm._v("开始任务")]
                            )
                          : _vm._e(),
                        _vm.permission("AddReport") &&
                        _vm.type === 1 &&
                        row.status === 2
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.report(row, "addReport")
                                  }
                                }
                              },
                              [_vm._v("汇报")]
                            )
                          : _vm._e(),
                        _vm.permission("Finish") &&
                        _vm.type === 1 &&
                        row.status === 2
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.handle(row)
                                  }
                                }
                              },
                              [_vm._v("完成任务")]
                            )
                          : _vm._e()
                      ]
                    ],
                    2
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: _vm.titles[_vm.currentType] },
          on: { onOk: _vm.submitForm },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "MFormBuilder",
            {
              ref: "formBuild",
              attrs: {
                "form-data": _vm.reportFormData,
                "form-list": _vm.reportFormList
              }
            },
            [
              _c(
                "el-upload",
                _vm._b(
                  {
                    attrs: { slot: "drawing", "file-list": _vm.attachments },
                    slot: "drawing"
                  },
                  "el-upload",
                  _vm.uploadProps,
                  false
                ),
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary", size: "small" } },
                    [_vm._v("点击上传")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }