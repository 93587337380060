var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tracing" },
    [
      _c("FunctionHeader", {
        attrs: { "search-title": _vm.searchTitle },
        on: {
          search: function($event) {
            return _vm.$refs.mTable.setTableData(true)
          }
        },
        model: {
          value: _vm.functionHeader.searchVal,
          callback: function($$v) {
            _vm.$set(_vm.functionHeader, "searchVal", $$v)
          },
          expression: "functionHeader.searchVal"
        }
      }),
      _c("MTable", {
        ref: "mTable",
        attrs: {
          height: _vm.height,
          columns: _vm.columns,
          "set-data-method": _vm.getTableData
        },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function(ref) {
              var $index = ref.$index
              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
            }
          },
          {
            key: "produceWorkOrderNumber",
            fn: function(ref) {
              var row = ref.row
              return _c(
                "div",
                {},
                [
                  _vm.isShowChild
                    ? _c("NewPageOpen", {
                        attrs: {
                          path: {
                            name: "workDetail",
                            query: { id: row.produceWorkOrderId }
                          },
                          text: row.produceWorkOrderNumber
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            }
          },
          {
            key: "batchNumberDetail",
            fn: function(ref) {
              var row = ref.row
              return _c(
                "div",
                {},
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "productBatchCodeTracingDetail",
                          query: { batchNumber: row.batchNumber }
                        }
                      }
                    },
                    [
                      _c("el-button", { attrs: { type: "text" } }, [
                        _vm._v(" " + _vm._s(row.batchNumber) + " ")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }