export const projectTaskStatusOptions = [
  { name: '待开始', id: 1 },
  { name: '进行中', id: 2 },
  { name: '已完成', id: 3 }
]
export const projectTaskStatusMap = projectTaskStatusOptions.reduce((obj, item) => {
  obj[item.id] = item.name
  return obj
}, {})
export const Organization = [
    {
    prop: 'index',
    label: '序号',
    sortable: true,
    slotName: 'index',
    hiddenSearch: true,
    width: 80
    },
    {
      prop: 'planName',
      label: '任务名称',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'planTypeName',
      label: '任务类别',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'projectNumber',
      label: '所属项目编号',
      sortable: false,
      hiddenSearch: true,
    },
    {
      prop: 'projectName',
      label: '所属项目名称',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'startTime',
      label: '计划开始时间',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'finishTime',
      label: '计划结束时间',
      sortable: false,
      hiddenSearch: true,
    },
    {
      prop: 'actualStartTime',
      label: '实际开始时间',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'actualFinishTime',
      label: '实际结束时间',
      sortable: false,
      hiddenSearch: true,
    },
    {
      prop: 'principalName',
      label: '负责人',
      sortable: false,
      hiddenSearch: true,
      slotName:'principalName',
    },
    {
      prop: 'status',
      label: '状态',
      sortable: false,
      hiddenSearch: true,
      slotName:'status'
    },
    {
      prop: 'lastUpdateTime',
      label: '任务更新时间',
      sortable: false,
      hiddenSearch: true,
    },
    {
      prop: 'createTime',
      label: '任务创建时间',
      sortable: false,
      hiddenSearch: true,
    },
    {
      prop: 'remark',
      label: '备注',
      sortable: false,
      hiddenSearch: true
    },
    {
      slotName: 'action',
      label: '操作',
      fixed: 'right',
      sortable: false,
      hiddenSearch: true,
      width: 200
    }
  ]

  export const OrganizationType = [
    ...Organization
  ]
  export const initiated = [
    ...Organization
  ]


