<template>
    <div class="inspection-plan-detail-wrapper">
      <div class="inspection-plan-detail-body">
        <div class="detail-card">
          <!-- <div class="card-tit">创建项目</div> -->
          <div style="height:10px" />
          <el-steps :active="currentStatus" align-center finish-status="success">
            <el-step title="填写项目基本信息" description="录入项目编号/名称等基本信息" />
            <el-step title="选择关联或创建需求" description="将项目与销售、备库、预测需求关联" />
            <el-step title="设置节点" description="拆分项目中的WBS节点并指定负责" />
          </el-steps>
          <div class="card-bot">
            <div v-show="currentStatus===0">
                <MFormBuilder ref="baseForm" :form-list="formList" :form-data="baseFormData">
                  <div slot="deliveryDate">
                    <el-date-picker
                      v-show="!showTwoTime"
                      v-model="deliveryOneDate"
                      type="date"
                      value-format="yyyy-MM-dd"
                      format="yyyy-MM-dd"
                      style="width: 100%"
                      placeholder="请选择交付日期"
                      @change="chooseStartTime"
                      clearable
                    />
                    <el-date-picker
                    v-show="showTwoTime"
                          v-model="deliveryTwoDate"
                          type="daterange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          value-format="yyyy-MM-dd"
                          format="yyyy-MM-dd"
                          clearable
                          style="width: 100%"
                        @change="chooseStartTime"
                        />
                  </div>
            </MFormBuilder>
            </div>
            <div v-show="currentStatus===1" style="text-align:center">
               <ProjectRequirements @chooseRequirements="chooseRequirements"></ProjectRequirements>
            </div>
            <div v-show="currentStatus===2" >
              <div style="display:flex;">
              <div style="margin: 10px 0">
                选择项目计划方案模板：
              </div>
              <el-select v-model="projectPlanId" placeholder="请选择计划方案模板" filterable
              remote
              reserve-keyword
              :remote-method="remoteMethod"
              clearable
               @change="changeProjectPlan">
              <el-option
                v-for="item in projectPlanList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
            </div>
            <div style="margin: 10px 0">
                节点信息：
              </div>
            <MTable
              ref="mTable"
              default-expand-all
              :is-tree-data="true"
              :height="height"
              :columns="scheduleColumn"
              :data="tableData"
              :show-page="false"
              :showAppend="true"
              :onlyKey="'treeId'"
              :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
            >
              <div slot="index" slot-scope="{row }">{{!row.parentId ? row.sort + 1 : '' }}</div>
              <span slot="planName" slot-scope="{row,$index}" style="display: inline-block;">
                <div style="display:flex;align-items:center;">
                <div>{{ row.planName}}</div>
               <img v-if="!row.isTaskNode" src="@/assets/plans/add.png" class="num-img" @click="addTaskNode(row,$index)">
              </div>
            </span>
            <div slot="isTaskNode" slot-scope="{ row }" >
            <span>{{row.isTaskNode ? '是' : '否' }}</span>
          </div>
              <div slot="startTimeAttribute" slot-scope="{ row }">
                    <div v-if="row.startTimeAttribute != null">{{ startFinishTimeAttribute[row.startTimeAttribute].name }}</div>
                  </div>
                  <div slot="finishTimeAttribute" slot-scope="{ row }">
                    <div v-if="row.finishTimeAttribute != null">{{ startFinishTimeAttribute[row.finishTimeAttribute].name }}</div>
                  </div>
                  <div slot="startTime" slot-scope="{ row }">
                    <div>{{ row.startTimeAttribute !=2 ? row.startTime ? row.startTime :'-' : '-' }}</div>
                  </div>
                  <div slot="finishTime" slot-scope="{ row }">
                    <div>{{ row.finishTimeAttribute != 2 ? row.finishTime ? row.finishTime :'-' :'-'}}</div>
                  </div>
              <div slot="action" slot-scope="{ row,$index }">
            <template>
              <el-button type="text" @click="modify(row,$index)">修改</el-button>
              <el-divider direction="vertical" />
              <el-button type="text" @click="del(row,$index)" :disabled="row.children.length ? true: false">删除</el-button>
            </template>
          </div>
            </MTable>
            </div>
          </div>
        </div>
      </div>
      <div class="order-footer">
        <div />
        <div class="footer-ri">
          <el-button @click="returnList()">取 消 </el-button>
            <el-button type="default" v-show="currentStatus > 0" @click="beforeStep">上一步</el-button>
          <el-button type="primary" @click="onSave" v-if="currentStatus===0">下一步</el-button>
          <el-button type="primary" v-if="currentStatus===1 && isChoose" @click="onSave">确定</el-button>
          <el-button type="primary" v-if="currentStatus===1 && !isChoose" @click="onSave">跳过</el-button>
          <el-button type="primary" v-if="currentStatus===2" @click="onSave" :loading="loading">完成创建</el-button>
        </div>
      </div>
      <task-node-dialog
      :visible.sync="showModify"
      :formModifyData="formModifyData"
      :nodeIndex="nodeIndex"
      :tableData="tableData"
      @submitForm="submitModify"
    />
    </div>
  </template>

  <script>
  import api from '@/api/projectManagement/project'
  import { addTaskForm, scheduleColumn } from './data'
  import ProjectRequirements from '../components/project-requirements'
  import { formList } from '../form-list'
  import { getFunctionList } from '../columns'
  import processApi from '@/api/information/production/process'
  import {startFinishTimeAttribute } from '@/config/options.config'
  import TaskNodeDialog from '@/components/TaskNodeDialog/TaskNodeDialog'
  import sapi from '@/api/projectManagement/planScheme'
  export default {
    name: 'AddProject',
    components: { ProjectRequirements,TaskNodeDialog },
    data() {
      return {
        formList,
        baseFormData: {
          deliveryDate:''
        },
        startFinishTimeAttribute,
        currentStatus: 0,
        isCalculate: false,
        addTaskForm,
        scheduleColumn,
        detail: {},
        tableData: [],
        isChoose:false,
        projectPlanId:'',
        projectPlanList:[],
        customerParams:{ page: 1, size: 20, search: '',customerId:''},
        showModify:false,
        formModifyData:{},
        relateRequirementList:[],
        materialParams: { page: 1, size: 20, search: '',id:'' },
        loading: false,
        nodeIndex:0,
        showTwoTime:true,
        deliveryOneDate:'',
        deliveryTwoDate:[],
        pickerOptions: {
            disabledDate: (date) => {
              const selectedDate = new Date(date);
              const currentDate = new Date();
              return selectedDate.toDateString() !== currentDate.toDateString();
            }
          }
      }
    },
    computed: {
      height() {
        return this.$store.state.app.contentHeight - 235 - 78 - 60
      }
    },
    watch: {
  'customerParams.search'(val) {
    this.formList[3].children[0].options.length = 0
    this.customerParams.page = 1
    this.$nextTick(() => {
      this.getCustomerList(this.customerParams)
    })
  }
},
activated(){
      this.tableData = localStorage.getItem('taskNodeList') ? JSON.parse(localStorage.getItem('taskNodeList')):[]
      // this.formList[3].children[0].options = []
      // this.getBusinessUnitList()
      // this.getUserList()
      // this.getCustomerList(this.customerParams)
      // this.getFinishedMaterials(this.materialParams)
    },
    created() {
      this.tableData = localStorage.getItem('taskNodeList') ? JSON.parse(localStorage.getItem('taskNodeList')):[]
      this.formList[3].children[0].options = []
      this.getBusinessUnitList()
      this.getUserList()
      this.getCustomerList(this.customerParams)
      this.getFinishedMaterials(this.materialParams)
    },
    beforeMount() {
  getFunctionList({
    searchChange: this.searchChange
  })
},

    methods: {
          // 获取计划方案模板
    async getFinishedMaterials(params) {
      const res = await sapi.getPlanSchemeListPage(params)
      if (res) {
        this.materialsTotal = res.total
        this.projectPlanList.push(...res.records || [])
      }
    },
        // 远程搜索成品物料
        remoteMethod(val) {
      this.projectPlanList.length = 0
      this.materialParams = {
        page: 1,
        size: 5,
        search: val
      }
      this.getFinishedMaterials(this.materialParams)
    },
        // 成品物料下拉触底分页加载
        projectLoadMore() {
      //     console.log(456)
      // if (this.projectPlanList.length === this.materialsTotal) return
      // this.materialParams.page++
      // this.getFinishedMaterials(this.materialParams)
    },
      addTaskNode(row,index){
        localStorage.setItem('taskNodeList', JSON.stringify(this.tableData))
        this.$router.push({ name: 'AddTaskNodes',query:{type:'addChildren',parentIndex:row.treeId} })
      },
      modify(row,index){
        this.formModifyData = row
        this.nodeIndex = index
        this.showModify = true
      },
      submitModify(val){
       this.tableData =  this.updateNode(this.tableData,val.treeId,val);
       this.showModify = false
      },
      // 递归函数用于更新子节点
      updateNode(nodes, targetId, newData) {
        return nodes.map((item) => {
          if (item.treeId === targetId) {
            return { ...item, ...newData };
          } else if (item.children) {
            return { ...item, children: this.updateNode(item.children, targetId, newData) };
          }
          return item;
        })
     },
      del(node) {
      const parent = this.findParent(this.tableData, node);
      if (parent) {
        parent.children = parent.children.filter(child => child !== node);
      } else {
        this.tableData = this.tableData.filter(item => item !== node);
      }
      this.tableData = this.tableData.map((item,index) =>{
            return this.assignChecked(item,index);
          })
    },

    assignChecked(item,index) {
        if (item.children) {
          item.children = item.children.map((child,indexs) => {
            return this.assignChecked(child,indexs);
          });
        }
        return {
          ...item,
          sort:index
        };
      },
    findParent(data, node) {
      for (let item of data) {
        if (item.children && item.children.includes(node)) {
          return item;
        } else if (item.children) {
          const parent = this.findParent(item.children, node);
          if (parent) {
            return parent;
          }
        }
      }
      return null;
    },
        // 获取负责人
  async getUserList() {
    const res = await api.getResponsible()
    if (res) {
      this.formList[6].children[0].options = res
      this.principalLst = res
    }
  },
        // 获取业务部门
   async getBusinessUnitList() {
      const res = await api.getBusinessUnitList()
      if (res) {
        this.formList[7].children[0].options = res
      }
    },
      // 客户下拉触底分页加载
  customerLoadMore() {
    if (this.formList[3].children[0].options.length === this.customerTotal) return
      if (this.formList[3].children[0].options.length > this.customerTotal) return [...this.formList[3].children[0].options = new Set(this.formList[3].children[0].options)]
    this.customerParams.page++
    this.getCustomerList(this.customerParams)
  },
    // 获取客户
    async getCustomerList(params) {
    const res = await processApi.getCustomerListPage(params)
    if (res) {
      this.customerTotal = res.total
      this.formList[3].children[0].options = res.records || []
      // this.formList[3].children[0].options.push(...res.records || [])
    }
  },
  searchChange(val){
     this.showTwoTime = val ? true : false
     this.$refs.baseForm.setForm({
      deliveryDate: ''
      });
     this.deliveryOneDate = ''
     this.deliveryTwoDate = []
  },
  async chooseStartTime(val) {
      await this.$refs.baseForm.setForm({
        deliveryDate:val
      })
      this.$refs.baseForm.formRefs().validateField('deliveryDate')
    },
    showType(val){
    if(val) {
      this.formList[5].props = {
        rangeSeparator: '至',
        startPlaceholder: '开始日期',
        endPlaceholder: '结束日期',
        align: 'center',
        type: 'daterange',
        valueFormat: 'yyyy-MM-dd',
        format: 'yyyy-MM-dd',
        pickerOptions: []
      }
    }else{
      this.formList[5].props = {type: 'date',valueFormat: 'yyyy-MM-dd',
    format: 'yyyy-MM-dd'}
    }
  },
      async changeProjectPlan(val){
        if(this.tableData.length && val){
         this.$message.info('更改发案会覆盖之前的项目节点数据！')
      }
      if(val){
        await this.getProjectPlanDetail(val)
      }else{
        this.tableData = []
      }
      },
      async getProjectPlanDetail(val){
      if(val){
      const res = await sapi.getPlanSchemeNodeListPage({projectPlanId:val})
        if(res){
          this.tableData = res.map((item,index) =>{
            return this.rebulidTree(item,index);
          })
            let array = this.flattenArray(this.tableData)
            this.updateMaterialsList(array)
        }
      }else{
        this.tableData = []
      }
    },
    flattenArray(arr) {
        let result = []
        function flatten(arr) {
          arr.forEach(item => {
            result.push(item);
            if (item.children && item.children.length > 0) {
            flatten(item.children);
            }
          })
        }
        flatten(arr)
        return result
    },
    updateMaterialsList(materialsList) {
        materialsList.forEach((item, index) => {
            if (item.startTimeRelationPlanId) {
                const startTimeRelationIndex = materialsList.findIndex(material => material.id === item.startTimeRelationPlanId);
                if (startTimeRelationIndex !== -1) {
                    item.startTimeRelationPlanId = startTimeRelationIndex;
                }else if (item.children && item.children.length > 0) {

                  this.updateMaterialsList(item.children);
                }
            }
            if (item.finishTimeRelationPlanId) {
                const finishTimeRelationIndex = materialsList.findIndex(material => material.id === item.finishTimeRelationPlanId);
                if (finishTimeRelationIndex !== -1) {
                    item.finishTimeRelationPlanId = finishTimeRelationIndex;
                }else if (item.children && item.children.length > 0) {
                  this.updateMaterialsList(item.children);
                }
              }
        })
     },
          rebulidTree(item,index){
            item.children = item.children.map((child,indexs) => {
            return this.rebulidTree(child,indexs);
          });
        return {
          ...item,
          taskId:item.id,
          treeId:item.id + '-' +index
        };
      },
      chooseRequirements(val){
         this.isChoose = val.length ? true : false
         this.relateRequirementList = val
      },
      async onSave() {
        const { form } = this.$refs.baseForm
        if (this.currentStatus === 0) {
          this.$refs.baseForm.formRefs().validate(async(valid) => {
        if (valid) {
          this.currentStatus = 1
        }
      })

        }else if(this.currentStatus === 1){
          this.currentStatus = 2
        }else{
          this.loading = true
          const res = await api.addProject(this.createSetData(form))
          if(res){
            this.loading = false
            this.$store.dispatch('tagsView/delCachedView', this.$route)
            this.$router.back()
          }
          this.loading = false
        }
      },
      createSetData(raw){
       let transferTableData = this._.cloneDeep(this.tableData); // 克隆原始数据
        this.removeId(transferTableData)
        return {
      projectNumber:raw.projectNumber,
      projectName:raw.projectName,
      customerOrderNumber:raw.customerOrderNumber,
      customerId:raw.customerId,
      deliveryType:raw.deliveryType,
      deliveryStartDate:raw.deliveryType ? raw.deliveryDate[0] :raw.deliveryDate,
      deliveryEndDate:raw.deliveryType ? raw.deliveryDate[1] :raw.deliveryDate,
      principalId:raw.principalId ? raw.principalId.join(','):'',
      businessUnit:raw.businessUnit,
      executiveUnit:raw.executiveUnit,
      projectApprovalNumber:raw.projectApprovalNumber,
      projectPlanId:this.projectPlanId,
      relateRequirementList:this.relateRequirementList.map(item=>{
         return {
          requirementId:item.requirementId,
          requirementType:item.requirementType
         }
      }),
      planNodeList:transferTableData
    }

      },
      removeId(obj) {
    if (Array.isArray(obj)) {
        obj.forEach(item=> {
            delete item.id;
            if (item.children) {
                this.removeId(item.children);
            }
        });
    }
},
      beforeStep(){
        this.currentStatus--
      },
      returnList() {
        this.$store.dispatch('tagsView/delCachedView', this.$route)
        // this.$router.go(-1)
        this.$router.back()
      }
    }
  }
  </script>

  <style scoped lang="scss">
  .order-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    background: #fff;
    border-top: 1px solid #dde3eb;
    .footer-le {
      padding-left: 30px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;
      span {
        margin-right: 45px;
        display: inline-block;
      }
    }
    .footer-ri {
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .num-img {
        width: 14px;
        height: 14px;
        position:absolute;
        right:10px;
        cursor: pointer;
      }
  .inspection-plan-detail-wrapper {
    background-color: #f0f2f5;
    height: 100%;

    .inspection-plan-detail-header {
      width: 100%;
      padding: 10px 0;
    }


    .inspection-plan-detail-body {
      height: calc(100% - 60px);
    overflow-y: auto;

    .detail-card {
      margin-bottom: 20px;
    }
    }
    .function-header-wrap {
      padding-top: 0;
    }
  }
   ::v-deep .el-step__head.is-process {
    border-color: #607FFF !important;
    color: #FFFFFF !important;
    .el-step__icon{
      background: #607FFF;
    }
}
  </style>
