<template>
    <div class="orderAdd">
      <div class="detail-card">
        <div class="card-tit">基本信息</div>
        <div class="card-bot" style="padding: 15px">
          <div>
            <el-row type="flex" justify="start" class="view-list" style="padding: 10px 15px;flex-flow:row wrap" :gutter="24">
              <el-col
                v-for="(item, index) in columns"
                :key="item.prop"
                :span="6"
                :style="{ marginBottom: index !== 4 ? '20px' : 0 }"
              >
                <span class="bot-tis">{{ item.label }}：</span>
                <span class="bot-tis">{{ getValue(item) }}</span>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div style="display:flex;">
              <div style="margin: 10px 0;font-size: 14px;">
                选择项目计划方案模板：
              </div>
              <el-select v-model="projectPlanId" placeholder="请选择计划方案模板" filterable
              remote
              reserve-keyword
              :remote-method="remoteMethod"
              clearable
               @change="changeProjectPlan">
              <el-option
                v-for="item in projectPlanList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
            </div>
      <div v-if="this.type!=='view'" style="margin: 10px 0;font-size:14px;">
                节点信息：
              </div>
      <div class="detail-card" style="padding:10px">
          <div class="bottom">
                    <FunctionHeader
                    ref="functionHeader"
                    v-model="functionHeader.searchVal"
                    search-title="请输入计划名称"
                    :tags="functionHeader.tags"
                    @search="searchTable()"
                    :hiddenSearch="true"
                    v-if="type != 'modify'"
                    >
                    </FunctionHeader>
                    <MTable
              ref="mTable"
              default-expand-all
              :is-tree-data="true"
              :height="height"
              :columns="scheduleColumn"
              :data="materialsList"
              :show-page="false"
              :showAppend="type === 'view' ? false : true"
              :onlyKey="'treeId'"
              :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
            >
              <div slot="index" slot-scope="{row }">{{!row.parentId ? row.sort + 1 : '' }}</div>
              <div slot="nodeAttribute" slot-scope="{ row }">
                    <div v-if="row.nodeAttribute != null">{{ planNodeAttribute[row.nodeAttribute].name }}</div>
                  </div>
              <span slot="planName" slot-scope="{row}" style="display: inline-block;">
                <div style="display:flex;align-items:center;">
                <div>{{ row.planName}}</div>
               <img v-if="type !== 'view' && !row.isTaskNode" src="@/assets/plans/add.png" class="num-img" @click="addTaskNode(row)">
              </div>
            </span>
            <div slot="isTaskNode" slot-scope="{ row }" >
            <span>{{row.isTaskNode ? '是' : '否' }}</span>
            </div>
              <div slot="startTimeAttribute" slot-scope="{ row }">
                    <div v-if="row.startTimeAttribute != null">{{ startFinishTimeAttribute[row.startTimeAttribute].name }}</div>
                  </div>
                  <div slot="finishTimeAttribute" slot-scope="{ row }">
                    <div v-if="row.finishTimeAttribute != null">{{ startFinishTimeAttribute[row.finishTimeAttribute].name }}</div>
                  </div>
                  <div slot="startTime" slot-scope="{ row }">
                    <div>{{ row.startTimeAttribute !=2 ? row.startTime ? row.startTime :'-' : '-' }}</div>
                  </div>
                  <div slot="finishTime" slot-scope="{ row }">
                    <div>{{ row.finishTimeAttribute != 2 ? row.finishTime ? row.finishTime :'-' :'-'}}</div>
                  </div>
              <div slot="action" slot-scope="{ row,$index }">
            <template>
              <el-button type="text" @click="modify(row,$index)">修改</el-button>
              <el-divider direction="vertical" />
              <el-button type="text" @click="del(row,$index)" :disabled="row.children.length ? true: false">删除</el-button>
            </template>
          </div>
            </MTable>
          </div>
      </div>
      <div class="order-footer">
        <div />
        <div class="footer-ri">
          <el-button @click="returnList">{{ this.type!=='view'?'取 消':'返 回' }}</el-button>
          <el-button v-if="this.type!=='view'" type="primary" @click="save" :loading="loading">确 定</el-button>
        </div>
      </div>
      <div class="lines" />
    <!-- 新增项目节点 -->
    <task-node-dialog
      :visible.sync="showModify"
      :formModifyData="formModifyData"
      :nodeIndex="nodeIndex"
      :tableData="materialsList"
      @submitForm="submitModify"
    />
    </div>
  </template>
  <script>
  import papi from "@/api/projectManagement/project"
  import api from '@/api/projectManagement/project-work-bench'
  import {  columns, scheduleColumn } from './columns'
  import { docType,status,planNodeAttribute,startFinishTimeAttribute } from '@/config/options.config'
  import FunctionHeader from '@/components/FunctionHeader/index'
  import { planNodeFormList } from "./form-list"
  import CURDSelect from "@/components/CURDSelect/CURDSelect"
  import TaskNodeDialog from '@/components/TaskNodeDialog/TaskNodeDialog'
  import sapi from '@/api/projectManagement/planScheme'
  export default {
    name: 'AdjustProjectNodes',
    components: { FunctionHeader,CURDSelect,TaskNodeDialog },
    data() {
      return {
        api,
        columns,
        scheduleColumn,
        logVisible: false,
        showModify:false,
        formModifyData:{},
        visible:false,
        planNodeFormList,
        orderLists:[],
        projectList:[],
        docType,
        status,
        planNodeAttribute,
        startFinishTimeAttribute,
        ruleForm: {
          isEnabled:1,
          name: '',
          remark:''
        },
        materialsList:[],
        functionHeader: {
        searchVal: '',
        importName: '',
        exportName: 'exportApplyMaterialsList',
        exportParams: {},
        tags: []
      },
      planNodetitles: {
        add: "新增计划节点",
        modify: "修改计划节点"
      },
      formData: {
        planTypeId: "",
      },
      currentType:'add',
      startPlanId:'',
      finishPlanId:'',
      planNodeTimeList:[],
      startTimeBasis:0,
      finishTimeBasis:0,
      addPlanFormList:[],
      nodeIndex:0,
      startTimeRelationPlanId:'',
      finishTimeRelationPlanId:'',
      startTimeRelationTimeType:'',
      finishTimeRelationTimeType:'',
      planStartDateTime:'',
      planFinishDateTime:'',
      projectPlanNodeId:'',
      planNodesList:[],
      tableData:[],
      startTimeRelationPlanName:'',
      finishTimeRelationPlanName:'',
      loading:false,
      equipmentDetail: {},
      projectPlanId:'',
      projectPlanList:[],
      materialParams: { page: 1, size: 20, search: '',id:'' }
      }
    },
    beforeRouteLeave(to, from, next) {
     if(this.projectPlanId != null) localStorage.setItem('projectPlanId', this.projectPlanId )
    next()
  },
    async created() {
      this.type = this.$route.query.type
      this.getTableData()
      this.getFinishedMaterials(this.materialParams)
      switch (this.type) {
        case 'modify':
        case 'view':
        case 'copy':
          await this.getDetail()
          break
        case 'addOrder':
          break
        default:
          break
      }
    },
    beforeMount() {
    // getFunctionList({
    // })
  },
  computed: {
      height() {
        return this.$store.state.app.contentHeight - 235 - 88 - 60
      }
    },
    mounted(){
        // this.getTableData()
      this.addPlanFormList = this._.cloneDeep(this.planNodeFormList)
      this.addPlanFormList.splice(4, 0, {
        hidden: true,
        label: '开始时间',
        key: 'startTimeRelationPlanId',
        tagName: 'div',
        required: false,
        children: [{
        customSlots: ['startTime']
        }]
        })
    },
    methods: {
      // 获取计划方案模板
    async getFinishedMaterials(params) {
      const res = await sapi.getPlanSchemeListPage(params)
      if (res) {
        this.materialsTotal = res.total
        this.projectPlanList.push(...res.records || [])
      }
    },
    // 远程搜索成品物料
    remoteMethod(val) {
      this.projectPlanList.length = 0
      this.materialParams = {
        page: 1,
        size: 5,
        search: val
      }
      this.getFinishedMaterials(this.materialParams)
    },
        // 成品物料下拉触底分页加载
        projectLoadMore() {
       },
       async changeProjectPlan(val){
        if(this.materialsList.length && val){
         this.$message.info('更改发案会覆盖之前的项目节点数据！')
      }
      if(val){
        await this.getProjectPlanDetail(val)
      }else{
        this.materialsList = []
      }
      },
      async getProjectPlanDetail(val){
      if(val){
      const res = await sapi.getPlanSchemeNodeListPage({projectPlanId:val})
        if(res){
          this.materialsList = res.map((item,index) =>{
            return this.rebulidTree(item,index);
          })
          this.removeId(this.materialsList)
            let array = this.flattenArray(this.materialsList)
            this.updateMaterialsList(array)
        }
      }else{
        this.materialsList = []
      }
    },
    removeId(obj) {
    if (Array.isArray(obj)) {
        obj.forEach(item=> {
            delete item.id;
            if (item.children) {
                this.removeId(item.children);
            }
        });
    }
},
      async addTaskNode(row) {
        await this.$store.dispatch('tagsView/addCachedView', this.$route)
        localStorage.setItem('taskNodeList', JSON.stringify(this.materialsList));
        this.$router.push({ name: 'AddTaskNodes', query: { type: 'addChildren', parentIndex: row.treeId } });
},

     selectName(item) {
      this.$refs.formBuild.setForm({
        planTypeId: item.id,
        planTypeName: item.name,
      });
    },
    changeStartTimePlan(val){
      const currentRow = this.planNodeTimeList.find(item => item.id === val)
      if(currentRow){
        this.startTimeRelationPlanId = currentRow.planId
        this.startTimeRelationTimeType = currentRow.timeType
        this.planStartDateTime = currentRow.dateTime
        this.startTimeRelationPlanName = currentRow.planName
      }else{
          this.startTimeRelationPlanId = ''
          this.startTimeRelationTimeType = ''
          this.planStartDateTime = ''
          this.startTimeRelationPlanName = ''
        }
    },
    
    startTimeAttributeChange(val){
      if(val === 2){
        this.addPlanFormList[4].hidden = false
      }else{
        this.addPlanFormList[4].hidden = true
        }
    },
      finishTimeAttributeChange(val){
        if(val === 2){
            this.addPlanFormList[6].hidden = false
        }else{
            this.addPlanFormList[6].hidden = true
        }
  
      },
      changeFinishTimePlan(val){
        const currentRow = this.planNodeTimeList.find(item => item.id === val)
        if(currentRow){
          this.finishTimeRelationPlanId = currentRow.planId
          this.finishTimeRelationTimeType = currentRow.timeType
          this.planFinishDateTime = currentRow.dateTime
          this.finishTimeRelationPlanName = currentRow.planName
        }else{
          this.finishTimeRelationPlanId = ''
          this.finishTimeRelationTimeType = ''
          this.planFinishDateTime = ''
        }
     },
      searchTable(){
         this.getTableData()
      },
      async modify(row,index) {
        this.formModifyData =  this._.cloneDeep(row)
        this.nodeIndex = index
        this.showModify = true
    },
    submitModify(val){
       this.materialsList =  this.updateNode(this.materialsList,val.treeId,val);
       this.showModify = false
      },
        updateNode(nodes, targetId, newData) {
        return nodes.map((item) => {
          if (item.treeId === targetId) {
            return { ...item, ...newData };
          } else if (item.children) {
            return { ...item, children: this.updateNode(item.children, targetId, newData) };
          }
          return item;
        })
     },
      flattenArray(arr) {
        let result = []
        function flatten(arr) {
          arr.forEach(item => {
            result.push(item);
            if (item.children && item.children.length > 0) {
            flatten(item.children);
            }
          })
        }
        flatten(arr)
        return result
    },
      // 获取节点详情表
      async getTableData() {
          this.materialsList = localStorage.getItem('taskNodeList') ? JSON.parse(localStorage.getItem('taskNodeList')) : []
        const searchCondition = {
          enablePlanNodeAdjust:true,
          search: this.functionHeader.searchVal,
          projectId: this.$route.query.id,
        }
        this.functionHeader.exportParams = searchCondition
        if(this.type != 'addOrder' && !this.materialsList.length){
            const res = await api.getProjectWorkBenchDetail({
          ...searchCondition
        })
        if (res) {
          this.materialsList = res.map((item,index) =>{
            return this.rebulidTree(item,index);
          })
            let array = this.flattenArray(this.materialsList)
            this.updateMaterialsList(array)
        }
        }
      },
      updateMaterialsList(materialsList) {
        materialsList.forEach((item, index) => {
            if (item.startTimeRelationPlanId) {
                const startTimeRelationIndex = materialsList.findIndex(material => material.id === item.startTimeRelationPlanId);
                if (startTimeRelationIndex !== -1) {
                    item.startTimeRelationPlanId = startTimeRelationIndex;
                }else if (item.children && item.children.length > 0) {

                  this.updateMaterialsList(item.children);
                }
            }
            if (item.finishTimeRelationPlanId) {
                const finishTimeRelationIndex = materialsList.findIndex(material => material.id === item.finishTimeRelationPlanId);
                if (finishTimeRelationIndex !== -1) {
                    item.finishTimeRelationPlanId = finishTimeRelationIndex;
                }else if (item.children && item.children.length > 0) {
                  this.updateMaterialsList(item.children);
                }
              }
        })
     },
      rebulidTree(item,index){
                item.children = item.children.map((child,indexs) => {
                return this.rebulidTree(child,indexs);
            });
            return {
            ...item,
            taskId:item.id,
            treeId:item.id + '-' +index
            };

      },
      del(node){
        const parent = this.findParent(this.materialsList, node);
      if (parent) {
        parent.children = parent.children.filter(child => child !== node);
      } else {
        this.materialsList = this.materialsList.filter(item => item !== node);
      }
      this.materialsList = this.materialsList.map((item,index) =>{
            return this.assignChecked(item,index);
          })
    },
    assignChecked(item,index) {
        if (item.children) {
          item.children = item.children.map((child,indexs) => {
            return this.assignChecked(child,indexs);
          });
        }
        return {
          ...item,
          sort:index
        };
      },
    findParent(data, node) {
      for (let item of data) {
        if (item.children && item.children.includes(node)) {
          return item;
        } else if (item.children) {
          const parent = this.findParent(item.children, node);
          if (parent) {
            return parent;
          }
        }
      }
      return null;
    },
    // 获取计划方案基本信息值
    getValue(row) {
      const result = this.equipmentDetail[row.prop];
      if (row.form && row.form.options) {
        const current = row.form.options.find((item) => item.id === result);
        return current && current.name;
      }
      if (row.prop === "deliveryDate") {
        return this.equipmentDetail.deliveryStartDate + "~" + this.equipmentDetail.deliveryEndDate;
      }
      return result;
    },
      // 获取详情
      async getDetail() {
        const res = await papi.getProjectDetail({id:this.$route.query.id})
        if (res) {
          this.equipmentDetail = res
          this.projectPlanId = localStorage.getItem('projectPlanId')!== null ? localStorage.getItem('projectPlanId') === '' ? '' : localStorage.getItem('projectPlanId') * 1 : res.projectPlanId 
        }
      },
      save() {
        this.$confirm('若存在任务删除时，该任务和历史汇报的记录会被清空，是否确认保存？', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          const res = await api.adjustPlanNodes({
            projectId:this.$route.query.id,
            projectPlanId:this.projectPlanId,
            planNodeList:this.materialsList
          })
              if (res) {
                this.$router.push({ name: 'ProjectWorkBenchList'})
              }
        })
      },
      returnList() {
        this.$store.dispatch('tagsView/delCachedView', this.$route)
        this.$router.go(-1)
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
  ::v-deep .my-col {
    .el-form-item {
      margin-bottom: 2px;
      .el-form-item__label {
        padding-right: 0;
      }
    }
  }
  ::v-deep {
    .el-tag {
      margin-right: 10px;
      margin-bottom: 5px;
    }
    .el-form-item {
      margin-bottom: 20px;
    }
    .el-date-editor .el-range-separator,
    .el-date-editor .el-range__icon {
      line-height: 26px;
    }
    .el-input-number {
      height: 32px;
      line-height: 32px;
      width: 100%;
    }
    .el-input__inner {
      height: 32px;
      line-height: 32px;
    }
    .el-input-number.is-controls-right .el-input-number__increase,
    .el-input-number.is-controls-right .el-input-number__decrease {
      line-height: 15px;
    }
  }
  .orderAdd {
    width: 100%;
    height: 100%;
    .num-img {
        width: 14px;
        height: 14px;
        position:absolute;
        right:10px;
        cursor: pointer;
      }
    .input-new-tag {
      width: 130px;
    }
    .button-new-tag {
      height: 32px;
    }
    .detail-card {
      margin-bottom: 20px;
    }
    .lines {
      width: 100%;
      height: 20px;
      background: #f0f2f5;
    }
    // ::v-deep .el-select {
    //   width: 100%;
    // }
    .e-btn {
      margin-bottom: 10px;
    }
    .order-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 60px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 100;
      background: #fff;
      border-top: 1px solid #dde3eb;
      .footer-le {
        padding-left: 30px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #393d60;
        span {
          margin-right: 45px;
          display: inline-block;
        }
      }
      .footer-ri {
        margin-right: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  </style>
  