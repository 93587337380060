import { qualityInspectionType } from '@/config/options.config'

export const base = [
  {
    prop: 'number',
    label: '质检单号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'procedureName',
    label: '工序',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'produceWorkOrderNumber',
    label: '生产工单号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'status',
    label: '状态',
    sortable: false,
    hiddenSearch: true,
    slotName: 'status'
  },
  {
    prop: 'materialCode',
    label: '物料编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialSpecifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'produceOrderNumber',
    label: '生产订单号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'batchCount',
    label: '批次报工数量',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'inspectionType',
    label: '质检类型',
    sortable: false,
    hiddenSearch: true,
    form: { options: qualityInspectionType }
  },
  {
    prop: 'inspectionSchemeName',
    label: '质检方案',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'inspectionSchemeAttachments',
    label: '质检附件',
    sortable: false,
    slotName: 'inspectionSchemeAttachments',
    width: 120
  }
]

export const incomming = [
  {
    prop: 'number',
    label: '质检单号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'relationNumber',
    label: '相关单号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'status',
    label: '状态',
    sortable: false,
    hiddenSearch: true,
    slotName: 'status'
  },
  {
    prop: 'materialCode',
    label: '物料编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialSpecifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'inspectionType',
    label: '质检类型',
    sortable: false,
    hiddenSearch: true,
    form: { options: qualityInspectionType }
  },
  {
    prop: 'inspectionSchemeName',
    label: '质检方案',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'creator',
    label: '送检人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'inspectionSchemeAttachments',
    label: '质检附件',
    sortable: false,
    slotName: 'inspectionSchemeAttachments',
    width: 120
  }
]
