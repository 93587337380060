
export const searchFormList = [
  {
    colSpan: 4,
    key: 'specifications',
    tagName: 'el-input',
    defaultValue: 1,
    attrs: {
      placeholder: '规格'
    }
  },
  {
    colSpan: 4,
    key: 'materialsAttributeName',
    tagName: 'el-select',
    props: {
      placeholder: '物料属性',
      configFilter: true
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name', // 自定义label需要字段值与configLabel字段值一致
          value: 'name'
        },
        options: []
      }
    ]
  }
]
export const searchFormListAll = [
  {
    colSpan: 4,
    key: 'procedure',
    tagName: 'el-input',
    attrs: {
      placeholder: '工序定位码/编码/名称'
    }
  },
  ...searchFormList
]
// 列表
export const columnsAll = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    fixed: 'left',
    width: 60
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    sortable: false,
    slotName: 'materialsCode',
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'specifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'materialsAttributeName',
    label: '物料属性',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'mainUnitName',
    label: '单位',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'grossQuantity',
    label: '毛需求',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'currentStock',
    label: '当前库存',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'availableStock',
    label: '可用库存',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'lockedStock',
    slotName: 'lockedStock',
    label: '已占用库存',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'purchaseQuantity',
    label: '请购中数量',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'dueOutQuantity',
    label: '待发量',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'onPassageQuantity',
    label: '在途量',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'inWarehouseQuantity',
    label: '已入库量',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'netRequireQuantity',
    label: '净需求',
    hiddenSearch: true,
    sortable: false,
    width: 120,
    blankStr: 'kk'
  }
]
export const columnsItem = [
  // {
  //   prop: 'index',
  //   label: '序号',
  //   // slotName: 'index',
  //   hiddenSearch: true,
  //   fixed: 'left',
  //   width: 60
  // },
  // {
  //   label: '工序定位码',
  //   sortable: false,
  //   prop: 'procedureLocationCode',
  //   hiddenSearch: true,
  //   width: 120
  // },
  // {
  //   label: '工序名称',
  //   sortable: false,
  //   prop: 'procedureName',
  //   hiddenSearch: true,
  //   width: 120
  // },
  // {
  //   prop: 'procedureCode',
  //   label: '工序编码',
  //   sortable: false,
  //   slotName: 'procedureCode',
  //   hiddenSearch: true,
  //   width: 120
  // },
  ...columnsAll.slice(1)
]

export const demandColumns = [
  {
    prop: 'itemNumber',
    label: '项次',
    slotName: 'itemNumber',
    hiddenSearch: true,
    fixed: 'left',
    sortable: false,
    width: 60
  },
  {
    prop: 'producePlanNumber',
    label: '生产工单编号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'procedureLocationCode',
    label: '工序定位码',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'procedureName',
    label: '工序名称',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'procedureCode',
    label: '工序编码',
    sortable: false,
    slotName: 'procedureCode',
    hiddenSearch: true,
    minWidth: 100
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    hiddenSearch: true,
    sortable: false,
    slotName: 'materialsCode',
    minWidth: 100
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'specifications',
    label: '规格',
    hiddenSearch: true,
    sortable: false,
    minWidth: 100
  },
  {
    prop: 'netRequireQuantity',
    label: '净需求量',
    hiddenSearch: true,
    sortable: false,
    minWidth: 100
  },
  {
    prop: 'mainUnitName',
    label: '单位',
    hiddenSearch: true,
    sortable: false,
    minWidth: 80
  },
  {
    prop: 'requireDate',
    label: '需求日期',
    hiddenSearch: true,
    sortable: false,
    minWidth: 100
  },
  {
    label: '本次请购量',
    hiddenSearch: true,
    sortable: false,
    slotName: 'thisPurchaseQuantity',
    minWidth: 220
  },
  {
    label: '到库日期',
    hiddenSearch: true,
    sortable: false,
    slotName: 'arrivalWarehouseDate',
    minWidth: 320
  },
  {
    label: '到厂日期',
    hiddenSearch: true,
    sortable: false,
    slotName: 'arrivalFactoryDate',
    minWidth: 320
  },
  {
    label: '交货日期',
    hiddenSearch: true,
    sortable: false,
    slotName: 'deliveryDate',
    minWidth: 320
  },
  {
    label: '请购用途',
    hiddenSearch: true,
    sortable: false,
    slotName: 'requisitionUses',
    minWidth: 240
  }
]

// 物料请购列表
export const searchFormListMaterial = [
  {
    colSpan: 6,
    key: 'producePlanNumbers',
    tagName: 'div',
    domProps: { className: 'custom-sel' },
    children: [
      {
        slots: ['producePlanNumbers']
      }
    ]
  },
  {
    colSpan: 4,
    key: 'keyword',
    tagName: 'div',
    defaultValue: '',
    children: [
      {
        slots: ['materialsM']
      }
    ]
  },
  {
    colSpan: 6,
    key: 'procedureIds',
    tagName: 'div',
    domProps: { className: 'custom-sel' },
    children: [
      {
        slots: ['procedureIds']
      }
    ]
  },
  {
    colSpan: 8,
    key: 'requireTime',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '需求开始日期',
      endPlaceholder: '结束日期',
      align: 'center',
      type: 'daterange',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm',
      defaultTime: ['00:00:00', '23:59:59']
    }
  },
  {
    colSpan: 8,
    key: 'purchaseTime',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '最晚请购时间开始日期',
      endPlaceholder: '结束日期',
      align: 'center',
      type: 'daterange',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm',
      defaultTime: ['00:00:00', '23:59:59']
    }
  }
]
export const columnsFormListMaterial = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    fixed: 'left',
    width: 60
  },
  {
    prop: 'procedureLocationCode',
    label: '工序定位码',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'producePlanNumber',
    label: '生产工单编号',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'procedureName',
    label: '工序名称',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'procedureCode',
    label: '工序编码',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    sortable: false,
    slotName: 'materialsCode',
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'specifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'netRequireQuantity',
    label: '净需求',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'mainUnitName',
    label: '单位',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'requireDate',
    label: '需求日期',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    label: '请购提前期',
    headerSlotName: 'requisitionLeadTimeHeader',
    prop: 'requisitionLeadTime',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'latestRequisitionDate',
    label: '最晚请购日期',
    hiddenSearch: true,
    sortable: false,
    width: 120
  }
]

// 物料请购列表
export const searchFormListPurchase = [
  {
    colSpan: 8,
    key: 'createTime',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '创建开始日期',
      endPlaceholder: '结束日期',
      align: 'center',
      type: 'daterange',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm',
      defaultTime: ['00:00:00', '23:59:59']
    }
  },
  {
    colSpan: 4,
    key: 'createBy',
    tagName: 'el-select',
    props: {
      placeholder: '创建人',
      configFilter: true
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'fullName', // 自定义label需要字段值与configLabel字段值一致
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 8,
    key: 'updateTime',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '更新开始日期',
      endPlaceholder: '结束日期',
      align: 'center',
      type: 'daterange',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm',
      defaultTime: ['00:00:00', '23:59:59']
    }
  },
  {
    colSpan: 4,
    key: 'updateBy',
    tagName: 'el-select',
    props: {
      placeholder: '更新人',
      configFilter: true
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'fullName', // 自定义label需要字段值与configLabel字段值一致
          value: 'id'
        },
        options: []
      }
    ]
  }
]
export const columnsFormListPurchase = [
  {
    type: 'selection',
    width: 150,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    fixed: 'left',
    width: 160
  },
  {
    prop: 'code',
    label: '请购单编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'createAt',
    label: '创建时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'createPerson',
    label: '创建人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'updateAt',
    label: '更新时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'updatePerson',
    label: '更新人',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    sortable: false,
    hiddenSearch: true,
    width: 120
  }
]

export const purchaseDetailForm = [
  {
    name: '请购单编号',
    key: 'code'
  },
  {
    name: '创建人',
    key: 'createPerson'
  },
  {
    name: '创建时间',
    key: 'createAt'
  },
  {
    name: '更新人',
    key: 'updatePerson'
  },
  {
    name: '更新时间',
    key: 'updateAt'
  }
]

export const MRPSettingForm = [
  {
    name: '请购单平均处理时长',
    key: 'avgDuration',
    desc: '从请购到采购下发采购订单之间的平均时长',
    suffix: ' 天'
  }
]

export const searchFormListPurchaseException = [
  {
    colSpan: 4,
    key: 'userName',
    tagName: 'el-input',
    defaultValue: 1,
    attrs: {
      placeholder: '采购员/员工姓名'
    }
  },
  {
    colSpan: 4,
    key: 'supplier',
    tagName: 'el-input',
    defaultValue: 1,
    attrs: {
      placeholder: '供应商/厂商'
    }
  },
  {
    colSpan: 4,
    key: 'purchaseCode',
    tagName: 'el-input',
    defaultValue: 1,
    attrs: {
      placeholder: '采购单号'
    }
  },
  {
    colSpan: 8,
    key: 'arrivalFactoryDateTime',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '到厂开始日期',
      endPlaceholder: '结束日期',
      align: 'center',
      type: 'daterange',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm',
      defaultTime: ['00:00:00', '23:59:59']
    }
  },
  {
    colSpan: 8,
    key: 'startWorkDateTime',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '开工开始日期',
      endPlaceholder: '结束日期',
      align: 'center',
      type: 'daterange',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm',
      defaultTime: ['00:00:00', '23:59:59']
    }
  }
]
export const columnsFormListPurchaseException = [
  {
    type: 'selection',
    width: 150,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    fixed: 'left',
    width: 60
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    sortable: false,
    slotName: 'materialsCode',
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'specifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'mainUnitName',
    label: '单位',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'buyer',
    label: '采购员',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'userName',
    label: '员工姓名',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'supplier',
    label: '供应厂商',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'manufacture',
    label: '厂商简称',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'purchaseCode',
    label: '采购单号',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'onPassageQuantity',
    label: '在途量',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'arrivalFactoryDate',
    label: '到场日期',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'startWorkDate',
    label: '开工日期',
    hiddenSearch: true,
    sortable: false,
    width: 120
  }
]
