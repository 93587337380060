var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "role-wrap" },
    [
      _c(
        "Split",
        {
          attrs: { offset: _vm.split.offset, height: _vm.splitHeight },
          on: {
            "update:offset": function($event) {
              return _vm.$set(_vm.split, "offset", $event)
            }
          }
        },
        [
          _c("template", { slot: "left" }, [
            _c(
              "div",
              { staticStyle: { padding: "10px" } },
              [
                _c("el-input", {
                  staticClass: "input-with-select",
                  attrs: { placeholder: "搜索生产工单编号", clearable: "" },
                  on: { input: _vm.onInput },
                  model: {
                    value: _vm.searchCode,
                    callback: function($$v) {
                      _vm.searchCode = $$v
                    },
                    expression: "searchCode"
                  }
                }),
                _vm._l(_vm.demandList, function(item) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      staticClass: "side-item",
                      style: {
                        color: _vm.actId === item.id ? "#8099ff" : "#606266"
                      },
                      on: {
                        click: function($event) {
                          return _vm.onSideClick(item.id)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(item.producePlanNumber))]
                  )
                })
              ],
              2
            )
          ]),
          _c("template", { slot: "right" }, [
            _c(
              "div",
              { staticClass: "orders" },
              [
                _c(
                  "div",
                  { staticClass: "top" },
                  [
                    _c("SearchForm", {
                      ref: "searchForm",
                      staticStyle: { padding: "0" },
                      attrs: {
                        "form-list-extend":
                          _vm.actId === "all"
                            ? _vm.searchFormList
                            : _vm.searchFormListAll,
                        "form-data": _vm.searchFormData
                      },
                      on: {
                        "update:formData": function(data) {
                          return (_vm.searchFormData = data)
                        },
                        search: _vm.reSearch
                      }
                    })
                  ],
                  1
                ),
                _c("FunctionHeader", {
                  ref: "functionHeader",
                  staticClass: "func-header",
                  staticStyle: { padding: "10px 0 10px 0" },
                  attrs: { "search-title": "请输入物料编号/名称" },
                  on: {
                    search: function($event) {
                      return _vm.$refs.mTableLeft.setTableData(true)
                    }
                  },
                  model: {
                    value: _vm.functionHeader.searchVal,
                    callback: function($$v) {
                      _vm.$set(_vm.functionHeader, "searchVal", $$v)
                    },
                    expression: "functionHeader.searchVal"
                  }
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showTable,
                        expression: "showTable"
                      }
                    ],
                    staticClass: "table-box",
                    staticStyle: { display: "flex" }
                  },
                  [
                    _c(
                      "Split",
                      {
                        attrs: { offset: _vm.tableOffset, height: _vm.height },
                        on: {
                          "update:offset": function($event) {
                            _vm.tableOffset = $event
                          }
                        }
                      },
                      [
                        _c(
                          "template",
                          { slot: "left" },
                          [
                            _c("MTable", {
                              ref: "mTableLeft",
                              staticClass: "table-left",
                              attrs: {
                                "span-method": function(params) {
                                  return _vm.arraySpanMethod(
                                    params,
                                    _vm.columns
                                  )
                                },
                                "row-key": "id",
                                "tree-props": { children: "children" },
                                "must-show-columns-setting": true,
                                columns: _vm.columns,
                                height: _vm.height,
                                "set-data-method": _vm.getTableData
                              },
                              on: {
                                "selection-change": function(data) {
                                  return (_vm.selectData = data)
                                },
                                "row-click": function(row) {
                                  return _vm.$refs.mTableLeft
                                    .tableRefs()
                                    .toggleRowExpansion(row)
                                },
                                "expand-change": _vm.onExpand
                              },
                              nativeOn: {
                                scroll: function($event) {
                                  return _vm.handleScroll($event)
                                }
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "index",
                                  fn: function(ref) {
                                    var $index = ref.$index
                                    return _c("div", {}, [
                                      _vm._v(_vm._s($index + 1))
                                    ])
                                  }
                                },
                                {
                                  key: "procedureCode",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return _c("NewPageOpen", {
                                      attrs: {
                                        path: {
                                          name: "procedureDetail",
                                          query: { id: row.procedureId }
                                        },
                                        text: row.procedureCode
                                      }
                                    })
                                  }
                                },
                                {
                                  key: "materialsCode",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return _c(
                                      "div",
                                      {},
                                      [
                                        row.children
                                          ? _c("div", [
                                              _vm._v(
                                                " 工序定位码：" +
                                                  _vm._s(
                                                    row.procedureLocationCode
                                                  ) +
                                                  " | 工序编码：" +
                                                  _vm._s(row.procedureCode) +
                                                  " | 工序名称：" +
                                                  _vm._s(row.procedureName) +
                                                  " | 合计：" +
                                                  _vm._s(row.children.length) +
                                                  " "
                                              )
                                            ])
                                          : _c("NewPageOpen", {
                                              attrs: {
                                                path: {
                                                  name: "materialsDetail",
                                                  query: { id: row.materialsId }
                                                },
                                                text: row.materialsCode
                                              }
                                            })
                                      ],
                                      1
                                    )
                                  }
                                },
                                {
                                  key: "lockedStock",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return _c("div", {}, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            row.lockedStock === undefined
                                              ? "-"
                                              : row.lockedStock
                                          )
                                      ),
                                      _vm.actId !== "all" &&
                                      row.lockedStock !== undefined
                                        ? _c("i", {
                                            staticClass: "el-icon-edit",
                                            staticStyle: { color: "#607FFF" },
                                            on: {
                                              click: function($event) {
                                                return _vm.editStock(row)
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ])
                                  }
                                }
                              ])
                            })
                          ],
                          1
                        ),
                        _c(
                          "template",
                          { slot: "right" },
                          [
                            _c("MTable", {
                              ref: "mTableRight",
                              staticClass: "table-right",
                              attrs: {
                                "span-method": function(params) {
                                  return _vm.arraySpanMethod(
                                    params,
                                    _vm.columnsDate
                                  )
                                },
                                "row-key": "id",
                                "tree-props": { children: "children" },
                                data: _vm.itemTableData,
                                "show-page": false,
                                "columns-setting": false,
                                columns: _vm.columnsDate,
                                height: _vm.height - 39
                              },
                              scopedSlots: _vm._u(
                                [
                                  _vm._l(_vm.columnsDate, function(item) {
                                    return {
                                      key: item.slotName,
                                      fn: function(ref) {
                                        var row = ref.row
                                        return _c(
                                          "div",
                                          { key: item.slotName },
                                          [
                                            row.children
                                              ? _c("span")
                                              : _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      (row[item.slotName] &&
                                                        row[item.slotName]
                                                          .onPassageQuantity) ||
                                                        "-"
                                                    )
                                                  )
                                                ]),
                                            _c(
                                              "el-tooltip",
                                              {
                                                attrs: {
                                                  effect: "dark",
                                                  content:
                                                    "请注意！该物料预计晚于开工时间到货，请及时调整。",
                                                  placement: "top"
                                                }
                                              },
                                              [
                                                row[item.slotName] &&
                                                row[item.slotName]
                                                  .onPassageQuantity &&
                                                row[item.slotName].status === 1
                                                  ? _c("i", {
                                                      staticClass:
                                                        "el-icon-warning-outline",
                                                      staticStyle: {
                                                        color: "#f78a24"
                                                      }
                                                    })
                                                  : _vm._e()
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      }
                                    }
                                  })
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.showTable,
                        expression: "!showTable"
                      }
                    ],
                    staticClass: "empty-box"
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/files-empty.png"),
                        alt: ""
                      }
                    }),
                    _c("h3", [_vm._v("暂无采购需求")]),
                    _c("p", { staticStyle: { color: "#bfbfbf" } }, [
                      _vm._v("可通过修改工艺路线重新计算")
                    ])
                  ]
                ),
                _c(
                  "el-dialog",
                  {
                    attrs: {
                      title: "编辑",
                      visible: _vm.editDialogVisible,
                      width: "500px"
                    },
                    on: {
                      "update:visible": function($event) {
                        _vm.editDialogVisible = $event
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticStyle: { width: "350px", margin: "auto" } },
                      [
                        _c("div", [
                          _vm._v(
                            "当前库存：" + _vm._s(_vm.editRow.currentStock)
                          )
                        ]),
                        _c("div", { staticStyle: { margin: "10px 0" } }, [
                          _vm._v(
                            "可用库存：" + _vm._s(_vm.editRow.availableStock)
                          )
                        ]),
                        _c(
                          "div",
                          [
                            _vm._v("占用库存： "),
                            _c("el-input-number", {
                              attrs: {
                                "controls-position": "right",
                                min: 0,
                                max: _vm.editRow.availableStock || 0,
                                step: 0.001
                              },
                              on: {
                                blur: function(e) {
                                  return _vm.onBlur(
                                    e,
                                    _vm.editRow,
                                    "lockedStock"
                                  )
                                }
                              },
                              model: {
                                value: _vm.editRow.lockedStock,
                                callback: function($$v) {
                                  _vm.$set(_vm.editRow, "lockedStock", $$v)
                                },
                                expression: "editRow.lockedStock"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "dialog-footer",
                        attrs: { slot: "footer" },
                        slot: "footer"
                      },
                      [
                        _c("el-button", { on: { click: _vm.cancelEdit } }, [
                          _vm._v("取 消")
                        ]),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.saveStock }
                          },
                          [_vm._v("确 定")]
                        )
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }