<template>
    <div class="scrap-wrap">
      <div class="bottom">
        <FunctionHeader
          ref="functionHeader"
          v-model="functionHeader.searchVal"
          search-title="请输入方案名称"
          :tags="functionHeader.tags"
          :export-name="functionHeader.exportName"
          :export-params="functionHeader.exportParams"
          @search="$refs.mTable.setTableData(true)"
        >
        </FunctionHeader>
        <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData" @selection-change="selectionChange">
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          <NewPageOpen slot="projectNumber" slot-scope="{ row }" :path="getDetailPath(row, 'view')" :text="row.name" />
          <div slot="deliveryDate" slot-scope="{ row }" >
            <span>{{ row.deliveryStartDate }}~{{row.deliveryEndDate}}</span>
          </div>
           <div slot="businessUnit" slot-scope="{ row }" >
            <span>{{ row.businessUnit!=null ?divisionOptions[row.businessUnit - 1].name : '' }}</span>
          </div>
          <div slot="executiveUnit" slot-scope="{ row }" >
            <span>{{row.executiveUnit!=null ?executionDepartment[row.executiveUnit].name : '' }}</span>
          </div>
          <div slot="isEnabled" slot-scope="{ row }" >
            <span>{{row.isEnabled ?'启用': '停用' }}</span>
          </div>
          <div slot="isDefault" slot-scope="{ row }" >
            <span>{{row.isDefault ?'是': '否' }}</span>
          </div>
          <div slot="projectApprovalNumber" slot-scope="{ row }" >
            <span>{{row.projectApprovalNumber }}</span>
          </div>
          <div slot="auditStatus" slot-scope="{ row }" >
            <span>{{ checkStatus[row.auditStatus].name }}</span>
          </div>
          <div slot="action" slot-scope="{ row }">
            <el-button
              v-if="permission('Default')"
              type="text"
              @click="setDefault(row)"
              >{{row.isDefault ? '取消默认':'设为默认'}}</el-button
            >
            <el-divider v-if="permission('Default')" direction="vertical" />

            <el-button
              v-if="permission('Copy')"
              type="text"
              @click="copy(row)"
              >复制</el-button
            >
            <el-divider v-if="permission('Copy')" direction="vertical" />

            <el-button
              v-if="permission('Modify')"
              type="text"
              @click="modify(row)"
              >修改</el-button
            >
            <el-divider v-if="permission('Modify')" direction="vertical" />
            <el-button
              v-if="permission('Del')"
              type="text"
              @click="del(row.id)"
              >删除</el-button
            >
            <!-- <el-divider v-if="permission('Del')" direction="vertical" /> -->
            
          </div>
        </MTable>
      </div>
      <MDialog v-model="visible" :title="titles[currentType]" @onOk="submitForm">
      <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList" >
      </MFormBuilder>
    </MDialog>
    </div>
  </template>

  <script>
  import { columns,getFunctionList } from './columns'
  import api from '@/api/projectManagement/planScheme'
  import conventionMixin from '@/mixin/conventional-page'
  import batchExportFront from '@/mixin/batch-export-front'
  import FunctionHeader from '@/components/FunctionHeader/index'
  import { procedureClass } from '@/config/options.config'
  import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
  import { projectDeliveryType,checkStatus,projectStatus } from '@/config/options.config'
  import { divisionOptions, executionDepartment } from '@/views/plans/prod-plan-lists/data'
  import formList from './form-list'

  export default {
    name: 'ProjectSchemeList',
    components: { NewPageOpen, FunctionHeader },
    mixins: [conventionMixin, batchExportFront],
    data() {
      return {
        api,
        isProcess: false,
        projectNumber:'',
        procedureClass,
        projectDeliveryType,
        divisionOptions,
        executionDepartment,
        checkStatus,
        projectStatus,
        formList,
        params: {
          radio: 'PlanScheme'
        },
        columns,
        searchFormData: {},
        defaultSearchFormData: {
          procedureId: ''
        },
        titles: {
        add: '新增项目',
        modify: '修改项目'
      },
        searchFormatData: [],
        functionHeader: {
          searchVal: '',
          tags: [
          {
            hidden: !this.permission('Add'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: this.add
            }
          }
          ],
          exportName: '',
          exportParams: {}
        },
        formData: {},
        currentType: 'add',
        visible: false,
        stationVisible: false,
        stationList: [],
        searchKeyword: true,
        defaultForm: { receiptType: 0 },
        isPageLoaded:false,
        materialParams: { status: 0, page: 1, size: 20, search: '',materialsId:'' },
        customerParams:{ page: 1, size: 20, search: '',customerId:''},
      }
    },
    beforeRouteLeave(to, from, next) {
      if (to.name !== 'refresh') {
        this.$store.dispatch('tagsView/addCachedView', from)
      }
      next()
    },
    computed: {
      height() {
        return this.$store.state.app.contentHeight - 235 - 10 + 35
      },
    },
    watch: {
    },
    created() {
      localStorage.removeItem('taskNodeList')
    },
    activated(){
      localStorage.removeItem('taskNodeList')
    if(!this.isPageLoaded){
      this.$refs.mTable.setTableData()
    }
    this.isPageLoaded = false
    },
    beforeMount() {
    getFunctionList({
      searchChange: this.searchChange
    })
  },
    mounted() {
      this.isPageLoaded = true
    },
    methods: {
      searchChange(val){
       this.showType(val)
       const { form } = this.$refs.formBuild
       form.deliveryDate = val ? []:''
    },
        async getTableData(condition, callback) {
        const { page, search, order } = condition

        const data = Object.assign({}, ...this.searchFormatData)
        const searchCondition = {
          search: this.functionHeader.searchVal,
          ...data
        }
        this.functionHeader.exportParams = searchCondition
        const res = await this.api[`get${this.params.radio}ListPage`]({
          page: page.currentPage,
          size: page.pageSize,
          ...searchCondition
        })
        if (res) {
          callback({
            total: res.total,
            content: res.records
          })
        }
        callback()
      },
      // 设置是否为默认方案
      async setDefault(row) {
        const res = await api.setPlanSchemeDefault({id:row.id,isDefault:row.isDefault===0 ? 1:0})
        if (res) {
          this.$message.success('操作成功')
          this.$refs.mTable.setTableData()
        }
    },
    del(id) {
        this.$confirm('您确定要删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          const res = await api.delPlanScheme({ id })
          if (res) {
            this.$message.success('删除成功')
            this.$refs.mTable.setTableData()
          }
        })
      },
      getDetailPath(row) {
        return { name: 'ProjectSchemeDetail', query: { type:'view',id: row.id}}
      },
    add() {
        this.$router.push({ name: 'ProjectSchemeDetail', query: { type:'addOrder' }})
    },
    modify(row){
      this.$router.push({ name: 'ProjectSchemeDetail', query: { type:'modify',id: row.id }})
    },
    // 复制
    copy(row){
      this.$router.push({ name: 'ProjectSchemeDetail', query: { type:'copy',id: row.id }})
    },
    showType(val){
      if(val) {
        this.formList[5].props = {
          rangeSeparator: '至',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          align: 'center',
          type: 'daterange',
          valueFormat: 'yyyy-MM-dd',
          format: 'yyyy-MM-dd',
          pickerOptions: []
        }
      }else{
        this.formList[5].props = {type: 'date',valueFormat: 'yyyy-MM-dd',
      format: 'yyyy-MM-dd'}
      }
    },
    createSetData(raw) {
      return {
        projectNumber:raw.projectNumber,
        projectName:raw.projectName,
        customerOrderNumber:raw.customerOrderNumber,
        customerId:raw.customerId,
        deliveryType:raw.deliveryType,
        deliveryStartDate:raw.deliveryType ? raw.deliveryDate[0] :raw.deliveryDate,
        deliveryEndDate:raw.deliveryType ? raw.deliveryDate[1] :raw.deliveryDate,
        principalId:raw.principalId ? raw.principalId.join(','):'',
        businessUnit:raw.businessUnit,
        executiveUnit:raw.executiveUnit,
        projectApprovalNumber:raw.projectApprovalNumber
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    },
      searchTable(data) {
        this.searchFormatData = data
        this.$refs.mTable.setTableData(true)
      }
    }
}
  </script>

  <style scoped lang="scss">
  .function-header-wrap {
    padding-top: 0;
  }

  .scrap-wrap {
    width: 100%;
    padding-top: 10px;
    .bottom {
      padding: 10px;
    }
    .m-radio-group {
      margin-left: 10px;
    }
  }
  .role-tags {
    margin: 5px;
    border-color: #dde3eb;
    color: #9597ae;
  }
  ::v-deep {
    .e-divider {
      margin: 0px 0 0 0;
    }
    .el-form-item__content {
      height:32px;
    }
  }
  </style>