<template>
  <div id="apps">
    <!-- 左边栏 -->
    <div class="app-right">
      <schedule-left
        v-if="showTip"
        :view="false"
        ref="schedules"
        :area-id="areaId"
        :area-name="areaName"
        @setMaterialsClass="setMaterialsClass"
        @nodeClick="nodeClick"
        :planTypeIds="planTypeIds"
        :planNames="planNames"
      />
    </div>
    <div
      id="containers-left"
      ref="myImg"
      :class="!fullscreen ? 'app-left-full' : showTip ? 'app-left' : 'app-width'"
    >
      <header v-if="showType === 1" class="top-bar">
        <div class="gatter-form">
          <div>
            <el-button type="primary" size="small" @click="batchIssue">全部下达</el-button>
            <el-button v-if="projectId" size="small" @click="exportProject">导出</el-button>
            <el-dropdown style="margin: 0 10px" @command="dropdownClick($event)">
              <el-button type="default" size="small">数据查询</el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item class="dropdown-item" command="userInfo">
                  <span>异常记录</span>
                </el-dropdown-item>
                <el-dropdown-item class="dropdown-item" command="changeRecords">
                  <span>变更历史</span>
                </el-dropdown-item>
                <el-dropdown-item class="dropdown-item" command="materialRecords">
                  <span>用料记录</span>
                </el-dropdown-item>
                <el-dropdown-item class="dropdown-item" command="completeAnalysis">
                  <span>齐套分析</span>
                </el-dropdown-item>
                <el-dropdown-item class="dropdown-item" command="workHourStatistics">
                  <span>工时统计</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown @command="setting($event)">
              <el-button type="default" size="small">设置</el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item class="dropdown-item" command="updateRequire">
                  <span>需求更新</span>
                </el-dropdown-item>
                <el-dropdown-item class="dropdown-item" command="batchAdjustNodes">
                  <span>节点调整</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div style="display: flex">
            <div class="today" @click="nowMonth">今天</div>
            <img src="@/assets/plans/left-arro.png" @click="addMonth(2)" />
            <div class="date-tit">{{ fixTime(commonDate) }}</div>
            <img src="@/assets/plans/right-arro.png" @click="addMonth(1)" />
          </div>
          <div style="display: flex; align-items: center">
            <div>
              <el-select v-model="scale" style="width: 250px" size="small" @change="selectScale">
                <el-option
                  v-for="item in scaleList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </div>
            <div
              class="tag-status"
              :style="{
                color: status === 2 ? '#00AB29' : status === 4 ? '#797B8F' : status === 5 ? '#E02020' : '',
                background:
                  status === 2 ? '#E2F9E8' : status === 4 ? '#F1F1F3' : status === 5 ? 'rgba(224,32,32,0.1)' : '',
              }"
            >
              <span style="font-size: 12px"
                >{{ status === 2 ? "进行中" : status === 4 ? "已结案" :  status === 5 ? "已停工" : '' }}
              </span>
            </div>
          </div>
        </div>
      </header>
      <div
        v-if="showType != 1"
        @click="returnList()"
        style="
          display: flex;
          justify-content: flex-start;
          padding: 10px 0 0 10px;
          position: relative;
        "
      >
        <el-button type="default" size="small">返回</el-button>
        <div style="position: absolute; left: 50%; transform: translateX(-50%)">
          {{ this.searchTitle }}
        </div>
      </div>
      <div v-if="showType === 1" ref="container" class="containers">
        <v-gantt-chart
          ref="treeGantt"
          :startTime="times[0]"
          :endTime="times[1]"
          :cellWidth="cellWidth"
          :cellHeight="cellHeight"
          :timeLines="timeLines"
          :titleHeight="titleHeight"
          :scale="scale"
          :titleWidth="titleWidth"
          showCurrentTime
          :hideHeader="hideHeader"
          :dataKey="dataKey"
          :arrayKeys="arrayKeys"
          :scrollToPostion="positionA"
          @scrollLeft="scrollLeftA"
          :datas="datasA"
          :datasB="datasB"
          :ganttType="'treeChart'"
          v-loading="loading"
          :scrollToTime="scrollToTime"
          @nodeClose="nodeClose"
          @nodeOpen="nodeOpen"
          @releaseNode="releaseNode"
          @viewLog="viewLog"
          @adjustNode="adjustNode"
          @onUrge="onUrge"
        >
          <template v-slot:block="{ data, item, getPositonOffset, getWidthAbout2Times }">
            <Test
              :data="data"
              :updateTimeLines="updateTimeLines"
              :cellHeight="cellHeight"
              :currentTime="currentTime"
              :item="item"
              @modifys="modify"
              :getPositonOffset="getPositonOffset"
              :getWidthAbout2Times="getWidthAbout2Times"
            />
          </template>

          <template v-slot:title>
            <div class="common-header">
              <div class="header-le">
                <el-popover
                  ref="popover"
                  v-model="showPopover"
                  :width="291"
                  :offset="20"
                  style="padding: 12px 12px 0"
                  @hide="show = true"
                >
                  <div class="popover-content-top">
                    <el-button @click="cancel">取消</el-button>
                    <el-button @click="saveColumns">确定</el-button>
                  </div>
                  <div class="popover-content-center">
                    <el-checkbox v-model="allChecked" @change="selectAllColumns">全选</el-checkbox>
                    <el-button class="invert" type="text" @click="invertSelectColumns">
                      <img
                        src="@/assets/table_filter_images/fanxuan@2x.png"
                        alt="反选"
                        width="13px"
                      />
                      <span>反选</span>
                    </el-button>
                  </div>
                  <div
                    class="popover-content-footer"
                    :style="{ maxHeight: popoverContentMaxHeight + 'px' }"
                  >
                    <el-checkbox-group v-model="checkedList" class="check-wrapper">
                      <el-checkbox
                        v-for="(item, index) in settingColumns"
                        :key="index"
                        :label="item.id"
                        >{{ item.projectName }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </div>
                </el-popover>
                <el-button ref="columnsSettingBtn" type="text" style="padding: 0">
                  <div class="showMore">
                    <span>节点</span>
                  </div>
                </el-button>
              </div>

              <div class="header-ri" @click="toggleFullScreen">
                <el-tooltip
                  v-if="fullscreen"
                  class="item"
                  effect="dark"
                  content="放大"
                  placement="top"
                >
                  <img
                    class="ri-img"
                    src="@/assets/narrow-amplification/amplification.png"
                    alt=""
                  />
                </el-tooltip>
                <el-tooltip
                  v-if="!fullscreen"
                  class="item"
                  effect="dark"
                  content="缩小"
                  placement="top"
                >
                  <img class="ri-img" src="@/assets/narrow-amplification/narrow.png" />
                </el-tooltip>
              </div>
            </div>
          </template>
        </v-gantt-chart>
        <div class="tip-img" @click="selectImg">
          <img v-if="showTip" src="@/assets/plans/spread.png" />
          <img v-else src="@/assets/plans/retract.png" alt="" />
        </div>
      </div>
      <div v-if="showType === 2">
        <ExceptionHandle ref="exceptionHandle" :projectId="projectId" />
      </div>
      <div v-if="showType === 3">
        <Change ref="change" :projectId="projectId" />
      </div>
      <div v-if="showType === 4">
        <MaterialsApply ref="materialsApply" :projectId="projectId" />
      </div>
      <div v-if="showType === 5">
        <MaterialsBalance ref="materialsBalance" :projectId="projectId" />
      </div>
      <div v-if="showType === 6">
        <WorkingHours ref="workingHours" :projectId="projectId" />
      </div>
    </div>

    <!-- 修改计划节点 -->
    <MDialog v-model="dialogVisible" title="修改计划" @onOk="dialogSubmit">
      <MFormBuilder ref="dialogFormBuild" :form-data="dialogFormData" :form-list="adjustFormList">
        <div slot="startTime">
          <el-date-picker
            v-show="showStartTime"
            v-model="params.planStartTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
            style="width: 100%"
            placeholder="开始时间"
            :disabled="isChooseStartAttr"
          />
          <div style="display: flex" v-show="!showStartTime">
            <el-select
              v-model="params.startPlanId"
              style="width: 100%"
              placeholder="请选择"
              @change="changeStartTimePlan"
              clearable
              filterable
            >
              <el-option
                v-for="item in planNodeTimeList"
                :key="item.id"
                :label="item.fullName"
                :value="item.id"
              />
            </el-select>
            <el-input-number
              style="margin-left: 10px"
              v-model="params.startTimeBasis"
              controls-position="right"
              class="min-input"
            />天
          </div>
        </div>
        <div slot="finishTime">
          <el-date-picker
            v-show="showFinishTime"
            v-model="params.planEndTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
            style="width: 100%"
            defaultTime="23:59:59"
            placeholder="完成时间"
            :disabled="isChooseFinishAttr"
          />
          <div style="display: flex" v-show="!showFinishTime">
            <el-select
              v-model="params.finishPlanId"
              style="width: 100%"
              placeholder="请选择"
              @change="changeFinishTimePlan"
              clearable
              filterable
            >
              <el-option
                v-for="item in planNodeTimeList"
                :key="item.id"
                :label="item.fullName"
                :value="item.id"
              />
            </el-select>
            <el-input-number
              style="margin-left: 10px"
              v-model="params.finishTimeBasis"
              controls-position="right"
              class="min-input"
            />天
          </div>
        </div>
      </MFormBuilder>
      <div style="color: #9a9a9a; padding-top: 10px">
        提示：手动调整计划时，会将原节点的计划开始结束时间的属性改为固定，即不会跟随其他节点的间隔来自动计算交期时间
      </div>
    </MDialog>
    <select-requirements
      :visible.sync="updateRequireVisible"
      :select-materials="selectedMaterialList"
      :projectId="projectId"
      @submitForm="submitRequirements"
    />

    <MDialog
      v-model="logVisible"
      @fullScreen="fullScreenChange"
      title="日志"
      :show-footer="false"
    >
      <MTable
        ref="logTable"
        :columns="logColumn"
        :height="dialogTableHeight"
        :set-data-method="getTableData"
      >
      <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
      <div slot="adjustContent" slot-scope="{row }">
        <el-tooltip effect="dark" :content="row.adjustContent"  placement="top-end">
          <span class="mr-10">
            {{ row.adjustContent }}
         </span>
        </el-tooltip>
      </div>
      </MTable>
    </MDialog>

    <MDialog
      v-model="adjustNodeVisible"
      @fullScreen="fullScreenChange"
      title="节点调整"
      @onOk="submitNodes"
    >
      <MTable
        ref="mTable"
        default-expand-all
        :is-tree-data="true"
        :height="height"
        :columns="scheduleColumn"
        :data="tableData"
        :show-page="false"
        :showAppend="true"
        :onlyKey="'treeId'"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <div slot="index" slot-scope="{ row }">{{ !row.parentId ? row.sort + 1 : "" }}</div>
        <span slot="planName" slot-scope="{ row, $index }" style="display: inline-block">
          <div style="display: flex; align-items: center">
            <div>{{ row.planName }}</div>
            <img src="@/assets/plans/add.png" class="num-img" @click="addTaskNode(row, $index)" />
          </div>
        </span>

        <div slot="startTimeAttribute" slot-scope="{ row }">
          <div v-if="row.startTimeAttribute != null">
            {{ startFinishTimeAttribute[row.startTimeAttribute].name }}
          </div>
        </div>
        <div slot="finishTimeAttribute" slot-scope="{ row }">
          <div v-if="row.finishTimeAttribute != null">
            {{ startFinishTimeAttribute[row.finishTimeAttribute].name }}
          </div>
        </div>
        <div slot="action" slot-scope="{ row, $index }">
          <template>
            <el-button type="text" @click="modify(row, $index)">修改</el-button>
            <el-divider direction="vertical" />
            <el-button
              type="text"
              @click="del(row, $index)"
              :disabled="row.children.length ? true : false"
              >删除</el-button
            >
          </template>
        </div>
      </MTable>
    </MDialog>
  </div>
</template>

  <script>
import Test from "./components/test.vue";
import TestTimeline from "./components/test-timeline.vue";
import ScheduleLeft from "./components/schedule-left.vue";
import dayjs from "dayjs";
import apis from "@/api/production/plans";
import { dialogFormList, haveDialogFormList, adjustFormList } from "./components/form-list";
import papi from "@/api/projectManagement/project";
import {
  getFunctionList,
  projectNodesColumns,
  projectNodesAdjustColumns,
  projectAbnormalColumns,
  projectMaterialsDiffColumns,
} from "./components/columns";
import {
  logColumn
} from "./columns";
import {
  startFinishTimeAttribute,
  majorTypeOptions,
  changeStatus,
  changeType,
} from "@/config/options.config";
import ExceptionHandle from "@/views/call/exception-handle/exception-handle.vue";
import Change from "@/views/changeManage/list.vue";
import MaterialsApply from "@/views/information/materials-apply/materials-apply.vue";
import MaterialsBalance from "@/views/information/materials-balance/materials-balance.vue";
import WorkingHours from "@/views/plans/prod-plan-lists/workingHours.vue";
import { handleStatusMap, judgeResultMap, finalResultMap } from "@/views/call/search/data";
import api from "@/api/projectManagement/project-work-bench";
import SelectRequirements from "@/views/project/MRP/projectManagement/components/SelectRequirements.vue";
import { scheduleColumn } from "@/views/project/MRP/projectManagement/detail/data";
const scaleList = `1440, 10080, 43200, 525600`.split(",").map((n) => {
  const value = parseInt(n);
  let label;
  if (value < 60) {
    label = value + "分钟";
  } else if (value >= 60 && value < 1440) {
    label = "小时视图";
  } else if (value >= 1440 && value < 10080) {
    label = "日视图";
  } else if (value >= 10080 && value < 43200) {
    label = "周视图";
  } else if (value >= 43200 && value < 525600) {
    label = "月视图";
  } else {
    label = "年视图";
  }
  return {
    value,
    label,
  };
});
export default {
  name: "ProjectWorkBench",
  components: {
    Test,
    TestTimeline,
    ScheduleLeft,
    ExceptionHandle,
    Change,
    MaterialsApply,
    MaterialsBalance,
    WorkingHours,
    SelectRequirements,
  },
  filters: {
    majorTypeOptions(val) {
      if (val == 0 || val) return majorTypeOptions.find((item) => item.id === val).name;
    },
  },
  data() {
    return {
      scheduleColumn,
      logColumn,
      showType: 1,
      searchTitle: "",
      commonDate: dayjs().format("YYYY-MM"),
      scrollTop: 0,
      dialogFormList,
      haveDialogFormList,
      adjustFormList,
      loading: false,
      processIdAndVersionId: [],
      startFinishTimeAttribute,
      majorTypeOptions,
      handleStatusMap,
      judgeResultMap,
      finalResultMap,
      changeStatus,
      changeType,
      fullDialogScreen: false,
      params: {
        processFlowName: "",
        processFlowId: undefined,
        processFlowVersionId: undefined,
        planStartTime: "",
        planEndTime: "",
        startPlanId: "",
        finishPlanId: "",
        startTimeBasis: "",
        finishTimeBasis: "",
      },
      isStatus: true,
      dialogVisible: false,
      isType: undefined,
      columnsBase: [],
      dialogFormData: {},
      projectNodesFormData: {},
      formDisabled: false,
      projectNodesColumns,
      projectNodesAdjustColumns,
      rules: {
        processFlowId: [{ required: true, message: "请选择工艺", trigger: "change" }],
        areaId: [{ required: true, message: "请选择产线", trigger: "change" }],
        deliveryTime: [{ required: true, message: "请选择交期", trigger: "change" }],
      },
      fullscreen: true,
      styleObject: {
        height: this.height,
      },
      param: "NoSchedule",
      areaId: null,
      areaName: "",
      scheduleList: [],
      showPopover: false,
      allChecked: false,
      popoverContentMaxHeight: 350,
      settingColumns: [],
      checkedList: [],
      showBtn: true,
      show: true,
      timeLines: [],
      currentTime: dayjs(),
      cellWidth: 40,
      cellHeight: 46,
      titleHeight: 40,
      titleWidth: 250,
      scale: 1440,
      times: [
        dayjs().startOf("month").hour(0).minute(0).toString(),
        dayjs().endOf("month").hour(23).minute(59).toString(),
      ],
      rowNum: 100,
      colNum: 10,
      datasA: [],
      datasB: [],
      dataKey: "id",
      scaleList: scaleList,
      scrollToTime: dayjs().hour(0).minute(0).toString(),
      scrollToPostion: { x: 10000, y: 10000 },
      hideHeader: false,
      hideSecondGantt: false,
      arrayKeys: ["gtArray"],
      scrollToY: 0,
      positionB: {},
      positionA: {},
      lineData: [],
      showTip: true,
      formOptions: {
        flowerOptions: [],
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(dayjs().set("hour", 8).minute(0));
              end.setTime(dayjs().set("hour", 20).minute(0));
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      blockWidth: this.$store.state.app.contentWidth - 265 - 247 - 266 - 39,
      currentDate: "",
      isHave: null,
      proceType: 0,
      planList: [],
      planTypeList: [],
      planTypeId: "",
      planId: "",
      modifyType: "modifyTime",
      projectType: "adjustTime",
      showFinishTime: true,
      showStartTime: true,
      isChooseFinishAttr: true,
      isChooseStartAttr: true,
      planNodeTimeList: [],
      newLineData: [],
      progressDetail: [],
      visibleProgress: false,
      startTimeRelationPlanId: "",
      finishTimeRelationPlanId: "",
      startTimeRelationTimeType: "",
      finishTimeRelationTimeType: "",
      planStartDateTime: "",
      planFinishDateTime: "",
      choosePlanNameList: [],
      choosePlanTypeList: [],
      planTypeIds: [],
      planNames: [],
      projectNodesVisible: false,
      modifyColumns: [],
      adjustTableData: [],
      modifyTableData: [],
      tableData: [],
      viewAbnormalVisible: false,
      viewAdjustRecordList: [],
      projectAbnormalColumns,
      projectMaterialsDiffColumns,
      projectId: "",
      viewECNVisible: false,
      viewMaterialsDiffVisible: false,
      split: {
        offset: 20,
      },
      status: "",
      updateRequireVisible: false,
      adjustNodeVisible: false,
      selectedMaterialList: [],
      logVisible:false,
      logData:[],
      planNodeId:''
    };
  },
  computed: {
    width() {
      return this.$store.state.app.contentWidth;
    },
    height() {
      return this.$store.state.app.contentHeight - 289 - 48 - 192;
    },
    priorityStatus() {
      return this.$store.state.user.priorityList;
    },
    currentSelectIdx() {
      return this.$store.state.user.currentSelectIdx;
    },
    dialogTableHeight() {
      return this.fullDialogScreen
        ? this.$store.state.app.contentHeight - 160 - 83 - 57 + 100
        : this.$store.state.app.contentHeight - 289 - 160 - 10 + 100;
    },
  },
  watch: {
    colNum: "getLineData",
    times: "getLineData",
    scrollToY(val) {
      // this.positionA = { x: val }
    },
    showTip(val) {
      if (val) {
        this.blockWidth = this.width - 265 - 247 - 266 - 39;
      } else {
        this.blockWidth = this.width - 265 - 247 - 39;
      }
      this.getScale();
    },
    fullscreen(val) {
      if (val) {
        this.blockWidth = this.width - 265 - 247 - 266 - 39;
        this.showTip = true;
      } else {
        this.blockWidth = this.width - 291 - 27;
      }
      this.getScale();
    },
    lineData: {
      handler(newValue, oldValue) {
        this.getLineLists();
      },
      deep: true,
    },
  },
  beforeMount() {
    getFunctionList({
      startTimeAttributeChange: this.startTimeAttributeChange,
      finishTimeAttributeChange: this.finishTimeAttributeChange,
    });
  },
  created() {
    this.$store.dispatch("user/currentSelectIdx", {});
    this.modifyColumns = this.projectNodesAdjustColumns;
  },
  mounted() {
    this.currentDate = dayjs().format("YYYY-MM-DD HH:mm:ss");
    // this.getData()
    this.getBusinessUnitList();
    this.getUserList();
  },
  methods: {
    async exportProject(){
      const params = { projectId: this.projectId }
      const res = await api.exportProject(params)
      if (res && res.data.byteLength) {
        const urlP = window.URL.createObjectURL(
            new Blob([res.data], {
              type: 'application/octet-stream;charset=ISO8859-1'
            })
        )
        const fileName = `项目工作台-${dayjs().format('YYYYMMDDHHmmss')}.xlsx`
        this.downloadFile(urlP, fileName)
      } else {
        this.$message.error('导出失败，内容为空')
      }
    },
    downloadFile(urlP, name) {
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = urlP
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    async getBusinessUnitList() {
      const res = await papi.getBusinessUnitList();
      if (res) {
        this.adjustFormList[0].children[0].options = res;
      }
    },
    async getUserList() {
      const res = await papi.getResponsible();
      if (res) {
        this.adjustFormList[1].children[0].options = res;
      }
    },
    async batchIssue() {
      const res = await papi.projectTaskChangeStatus({ projectId: this.projectId });
      if (res) {
        this.$message.success("下达成功");
        this.getData();
      }
    },
    async dropdownClick(name) {
      // 异常记录
      if (name === "userInfo") {
        this.showType = 2;
        this.searchTitle = "异常处理记录";
        // this.$nextTick(() => {
        // this.$refs.exceptionHandle.$refs.mTable.setTableData(true)
        // })
      }
      if (name === "changeRecords") {
        this.showType = 3;
        this.searchTitle = "变更历史";
      }
      if (name === "materialRecords") {
        this.showType = 4;
        this.searchTitle = "用料记录";
      }
      if (name === "completeAnalysis") {
        this.showType = 5;
        this.searchTitle = "齐套分析";
      }
      if (name === "workHourStatistics") {
        this.showType = 6;
        this.searchTitle = "工时统计";
      }
    },
    async setting(name) {
      if (name === "updateRequire") {
        await this.getProjectRelateList();
        this.updateRequireVisible = true;
      } else {
        localStorage.removeItem("projectPlanId");
        localStorage.removeItem("taskNodeList");
        this.$router.push({
          name: "AdjustProjectNodes",
          query: { type: "modify", id: this.projectId },
        });
      }
    },
    async getProjectRelateList() {
      const res = await api.getProjectRelateList({
        projectId: this.projectId,
      });
      if (res) {
        this.selectedMaterialList = res;
      }
    },
    async submitRequirements(val) {
      const res = await api.updateRelateRequirement({
        projectId: this.projectId,
        relateRequirementList: val.map((item) => {
          return {
            requirementId: item.requirementId,
            requirementType: item.requirementType,
          };
        }),
      });
      if (res) {
        this.$message.success("操作成功");
        this.updateRequireVisible = false;
        this.$refs.schedules.getMaterialsClassList();
      }
    },
    nodeClose(val) {
      this.settingColumns = val;
      this.lineData = val;
    },
    nodeOpen(val) {
      this.settingColumns = val;
      this.lineData = val;
    },
    async releaseNode(val) {
      const res = await papi.projectTaskChangeStatus({ status: 1, id: val.id });
      if (res) {
        this.$message.success("下达成功");
        this.getData();
      }
    },
    viewLog(val) {
      this.logVisible = true
      this.planNodeId = val.id
      if (this.$refs.logTable !== undefined) {
      this.$refs.logTable.setTableData()
      }
    },
    async onUrge(val){
      const res = await papi.planNodeUrging(val.id)
      if(res){
        this.$message.success(`${val.planName}节点已催办`)
      }
    },
       // 列表
       async getTableData(condition, callback) {
        const { page } = condition
        const res = await papi.getAdjustRecordListPage({
          page: page.currentPage,
          size: page.pageSize,
          id:this.planNodeId
        })
        if (res) {
          callback({
            total: res.total,
            content: res.records
          })
          this.total = res.total
        }
        callback()
      },
    adjustNode(val) {
      this.dialogFormData = this._.cloneDeep(val);
      this.dialogFormData.principalId = this.dialogFormData.principalId
        ? this.dialogFormData.principalId.split(",").map((item) => +item)
        : this.dialogFormData.principalId;
      this.dialogFormData.principalDepartId = this.dialogFormData.principalDepartId
        ? this.dialogFormData.principalDepartId.split(",").map((item) => +item)
        : this.dialogFormData.principalDepartId;
      this.dialogVisible = true;
    },
    fullScreenChange(val) {
      this.fullDialogScreen = val;
    },
    startTimeAttributeChange(val) {
      if (val || val === 0) {
        this.isChooseStartAttr = false;
        this.showStartTime = val === 2 ? false : true;
      } else {
        this.isChooseStartAttr = true;
        this.showStartTime = true;
      }
    },
    finishTimeAttributeChange(val) {
      if (val || val === 0) {
        this.isChooseFinishAttr = false;
        this.showFinishTime = val === 2 ? false : true;
      } else {
        this.isChooseFinishAttr = true;
        this.showFinishTime = true;
      }
    },
    startTimeProjectAttrChange(row, val) {
      if (val || val === 0) {
        this.$set(row, "isChooseStartAttr", false);
        this.$set(row, "showStartTime", val === 2 ? false : true);
      } else {
        this.$set(row, "isChooseStartAttr", true);
        this.$set(row, "showStartTime", true);
      }
    },
    finishTimeProjectAttrChange(row, val) {
      if (val || val === 0) {
        this.$set(row, "isChooseFinishAttr", false);
        this.$set(row, "showFinishTime", val === 2 ? false : true);
      } else {
        this.$set(row, "isChooseFinishAttr", true);
        this.$set(row, "showFinishTime", true);
      }
    },
    getScale() {
      const list = new Map([
        [60, 70],
        [1440, 70],
        [10080, parseInt(this.blockWidth / 5) < 120 ? 120 : parseInt(this.blockWidth / 5)],
        [43200, parseInt(this.blockWidth / 3)],
        [525600, this.blockWidth],
      ]);
      this.cellWidth = list.get(this.scale);
    },
    async selectScale() {
      await this.getScale();
      const totalTime = new Map([
        [
          60,
          [
            dayjs(this.commonDate).startOf("month").hour(0).minute(0).toString(),
            dayjs(this.commonDate).endOf("month").hour(23).minute(0).toString(),
          ],
        ],
        [
          1440,
          [
            dayjs(this.commonDate).startOf("month").hour(0).minute(0).toString(),
            dayjs(this.commonDate).endOf("month").hour(23).minute(59).toString(),
          ],
        ],
        [
          10080,
          [
            dayjs(this.commonDate).startOf("week").hour(0).minute(0).toString(),
            dayjs(this.commonDate).endOf("month").hour(23).minute(59).toString(),
          ],
        ],
        [
          43200,
          [
            dayjs(this.commonDate).startOf("month").hour(0).minute(0).toString(),
            dayjs(this.commonDate).add(2, "month").endOf("month").hour(23).minute(59).toString(),
          ],
        ],
        [
          525600,
          [
            dayjs(this.commonDate).startOf("year").hour(0).minute(0).toString(),
            dayjs(this.commonDate).endOf("year").hour(23).minute(59).toString(),
          ],
        ],
      ]);
      this.times = totalTime.get(this.scale);
    },
    choosePlanType(val) {
      this.planTypeIds = val;
    },
    choosePlanName(val) {
      this.planNames = val;
    },
    getMonths() {
      this.times = [
        dayjs(this.commonDate).startOf("month").hour(0).minute(0).toString(),
        dayjs(this.commonDate).add(2, "month").endOf("month").hour(23).minute(59).toString(),
      ];
    },
    getYear() {
      this.times = [
        dayjs(this.commonDate).startOf("year").hour(0).minute(0).toString(),
        dayjs(this.commonDate).endOf("year").hour(23).minute(59).toString(),
      ];
    },
    getweek() {
      this.times = [
        dayjs(this.commonDate).startOf("week").hour(0).minute(0).toString(),
        dayjs(this.commonDate).endOf("month").hour(23).minute(59).toString(),
      ];
    },
    getDays() {
      this.times = [
        dayjs(this.commonDate).startOf("month").hour(0).minute(0).toString(),
        dayjs(this.commonDate).endOf("month").hour(23).minute(59).toString(),
      ];
    },
    getCommon() {
      if (this.scale === 525600) {
        this.getYear();
        return false;
      }
      if (this.scale === 43200) {
        this.getMonths();
        return false;
      }
      if (this.scale === 10080) {
        this.getweek();
        return false;
      }
      if (this.scale === 1440) {
        this.getDays();
        return false;
      }
      this.getDays();
    },
    addMonth(type) {
      if (type === 1) {
        this.commonDate = dayjs(this.commonDate).add(1, "month").format("YYYY-MM");
      } else {
        this.commonDate = dayjs(this.commonDate).subtract(1, "month").format("YYYY-MM");
      }
      this.getCommon();
    },
    nowMonth() {
      this.commonDate = dayjs().format("YYYY-MM");
      this.getCommon();
      this.scrollToTime =
        this.scale === 60
          ? dayjs().subtract(3, "hour").minute(0).toString()
          : dayjs().hour(0).minute(0).toString();
    },
    fixTime(time) {
      return time.substring(0, 4) + "年" + time.substring(5, 7) + "月";
    },
    compareDate(d1, d2) {
      if (d1 && d2) {
        return new Date(d1.replace(/\-/g, "/")) >= new Date(d2.replace(/\-/g, "/"));
      }
      return false;
    },
    async getPlanNameList() {
      const res = await papi.getPlanNameList();
      if (res) {
        this.planList = res;
      }
    },
    async getPlanTypeList() {
      const res = await papi.getPlanType();
      if (res) {
        this.planTypeList = res;
      }
    },
    radioModifyChange() {},
    radioProjectChange(val) {
      this.modifyColumns =
        val === "modifyTime" ? this.projectNodesColumns : this.projectNodesAdjustColumns;
      this.tableData = val === "modifyTime" ? this.modifyTableData : this.adjustTableData;
    },
    changeStartTimePlan(val) {
      this.$forceUpdate();
      const currentRow = this.planNodeTimeList.find((item) => item.id === val);
      if (currentRow) {
        this.startTimeRelationPlanId = currentRow.planId;
        this.startTimeRelationTimeType = currentRow.timeType;
        this.planStartDateTime = currentRow.dateTime;
      } else {
        this.startTimeRelationPlanId = "";
        this.startTimeRelationTimeType = "";
        this.planStartDateTime = "";
      }
    },
    changeFinishTimePlan(val) {
      this.$forceUpdate();
      const currentRow = this.planNodeTimeList.find((item) => item.id === val);
      if (currentRow) {
        this.finishTimeRelationPlanId = currentRow.planId;
        this.finishTimeRelationTimeType = currentRow.timeType;
        this.planFinishDateTime = currentRow.dateTime;
      } else {
        this.finishTimeRelationPlanId = "";
        this.finishTimeRelationTimeType = "";
        this.planFinishDateTime = "";
      }
    },
    changeProjectStartTimePlan(row, val) {
      this.$forceUpdate();
      const currentRow = row.planNodeTimeList.find((item) => item.id === val);
      if (currentRow) {
        this.$set(row, "startTimeRelationPlanId", currentRow.planId);
        this.$set(row, "startTimeRelationTimeType", currentRow.timeType);
        this.$set(row, "planStartDateTime", currentRow.dateTime);

        // this.planStartDateTime = currentRow.dateTime
      } else {
        this.$set(row, "startTimeRelationPlanId", "");
        this.$set(row, "startTimeRelationTimeType", "");
        this.$set(row, "planStartDateTime", "");
      }
    },
    changeProjectFinishTimePlan(row, val) {
      this.$forceUpdate();
      const currentRow = row.planNodeTimeList.find((item) => item.id === val);
      if (currentRow) {
        this.$set(row, "finishTimeRelationPlanId", currentRow.planId);
        this.$set(row, "finishTimeRelationTimeType", currentRow.timeType);
        this.$set(row, "planFinishDateTime", currentRow.dateTime);
        // this.$set(row, "planStartDateTime", currentRow.dateTime)
        // this.planFinishDateTime = currentRow.dateTime
      } else {
        this.$set(row, "finishTimeRelationPlanId", "");
        this.$set(row, "finishTimeRelationTimeType", "");
        this.$set(row, "planFinishDateTime", "");
        // this.finishTimeRelationPlanId = ''
        // this.finishTimeRelationTimeType = ''
        // this.planFinishDateTime = ''
      }
    },
    selectImg() {
      if (!this.showTip && this.$refs.schedules !== undefined) {
        this.$refs.schedules.getScheduleList();
      }
      this.showTip = !this.showTip;
    },
    async selectLine(val) {
      await this.getAllPlanNodeTimeList(val);
      const datas = this._.cloneDeep(val);
      this.modifyColumns = this.projectNodesAdjustColumns;
      this.projectType = "adjustTime";
      this.projectNodesFormData = datas;
      this.tableData = datas.producePlanList;
      this.adjustTableData = datas.producePlanList;
      let arr = datas.producePlanList.map((item) => {
        return {
          ...item,
          showStartTime: item.startTimeAttribute === 2 ? false : true,
          showFinishTime: item.finishTimeAttribute === 2 ? false : true,
          isChooseFinishAttr:
            item.finishTimeAttribute || item.finishTimeAttribute === 0 ? false : true,
          isChooseStartAttr:
            item.startTimeAttribute || item.startTimeAttribute === 0 ? false : true,
          planNodeTimeList: this.getPlanNodesTimeList(item.id),
          startPlanId: item.startTimeRelationPlanId
            ? item.startTimeRelationPlanId + "-" + item.startTimeRelationTimeType
            : "",
          finishPlanId: item.finishTimeRelationPlanId
            ? item.finishTimeRelationPlanId + "-" + item.finishTimeRelationTimeType
            : "",
        };
      });
      this.modifyTableData = arr.map((item) => {
        return {
          ...item,
          planStartDateTime: item.planNodeTimeList.find((items) => items.id === item.startPlanId)
            ? item.planNodeTimeList.find((items) => items.id === item.startPlanId).dateTime
            : "",
          planFinishDateTime: item.planNodeTimeList.find((item) => item.id === item.finishPlanId)
            ? item.planNodeTimeList.find((item) => item.id === item.finishPlanId).dateTime
            : "",
        };
      });
      this.projectNodesVisible = true;
    },
    viewAbnormalList(val) {
      this.projectId = val;
      this.viewAbnormalVisible = true;
      this.$nextTick(() => {
        this.$refs.abnormalTable.setTableData();
      });
    },
    async getAbnormalTableData(condition, callback) {
      const { page, search, order } = condition;
      const res = await papi.getProjectAbnormalListPage({
        page: page.currentPage,
        size: page.pageSize,
        projectId: this.projectId,
        statusList: [0, 1, 2, 3, 4],
      });
      if (res) {
        callback({
          total: res.total,
          content: res.records,
        });
      }
      callback();
    },
    viewECNList(val) {
      this.projectId = val;
      this.viewECNVisible = true;
      this.$nextTick(() => {
        this.$refs.ecnTable.setTableData();
      });
    },
    async getECNTableData(condition, callback) {
      const { page, search, order } = condition;
      const res = await papi.getProjectECNListPage({
        page: page.currentPage,
        size: page.pageSize,
        projectId: this.projectId,
        needOperate: 0,
        statusList: [0, 1, 2, 3, 4],
      });
      if (res) {
        callback({
          total: res.total,
          content: res.records,
        });
      }
      callback();
    },
    viewMaterialsDiffList(val) {
      this.projectId = val;
      this.viewMaterialsDiffVisible = true;
      this.$nextTick(() => {
        this.$refs.materialsDiffTable.setTableData();
      });
    },
    async getMaterialsDiffTableData(condition, callback) {
      const { page, search, order } = condition;
      const res = await papi.getProjectMaterialsDifferenceListPage({
        page: page.currentPage,
        size: page.pageSize,
        projectId: this.projectId,
      });
      if (res) {
        callback({
          total: res.total,
          content: res.records,
        });
      }
      callback();
    },
    cancel() {
      this.showPopover = false;
      this.allChecked = false;
      this.show = false;
      setTimeout(this.resetSettingFields, 500);
    },
    async saveColumns() {
      await this.getLineData();
      this.showPopover = false;
      this.allChecked = false;
      this.show = false;
    },
    selectAllColumns(val) {
      this.checkedList = val ? this.settingColumns.map((item) => item.id) : [];
    },
    invertSelectColumns() {
      this.checkedList = this.settingColumns
        .filter((item) => this.checkedList.indexOf(item.id) === -1)
        .map((item) => item.id);
    },
    changeMore() {
      this.show = !this.show;
    },
    changeSpread() {
      this.showBtn = !this.showBtn;
      if (this.showBtn) {
        this.checkedList = [];
        this.getData();
        return false;
      }
      this.getLineData();
    },
    updateTimeLines(timeA, timeB) {
      this.timeLines = [
        {
          time: timeA,
          text: "自定义",
        },
        {
          time: timeB,
          text: "测试",
          color: "#747e80",
        },
      ];
    },
    scrollLeftA(val) {
      this.positionB = { x: val };
    },
    scrollLeftB(val) {
      this.positionA = { x: val };
    },
    nodeClick(val) {
      this.projectId = val.id;
      this.status = val.status;

      if (this.showType === 1) {
        this.getData();
      } else if (this.showType === 2) {
        this.$nextTick(() => {
          this.$refs.exceptionHandle.$refs.mTable.setTableData(true);
        });
      } else if (this.showType === 3) {
        this.$nextTick(() => {
          this.$refs.change.$refs.mTable.setTableData(true);
        });
      } else if (this.showType === 4) {
        this.$nextTick(() => {
          this.$refs.materialsApply.$refs.mTable.setTableData(true);
        });
      } else if (this.showType === 5) {
        this.$nextTick(() => {
          this.$refs.materialsBalance.$refs.mTable.setTableData(true);
        });
      } else if (this.showType === 6) {
        this.$nextTick(() => {
          this.$refs.workingHours.$refs.mTable.setTableData(true);
        });
      }
    },
    setMaterialsClass(id, status) {
      this.projectId = id;
      this.status = status;
      this.getData();
    },
    async getData() {
      const res = await api.getProjectWorkBenchDetail({ projectId: this.projectId });
      if (res) {
        this.datasB = this._.cloneDeep(res);
        let array = this._.cloneDeep(res);
        this.$nextTick(() => {
        let allNodes = this.$refs.treeGantt.$refs.leftBar.$refs.tree.store.nodesMap;
        for (let x in allNodes) {
          if (allNodes[x].expanded) {
            const index = array.findIndex((node) => node.id === allNodes[x].data.id);
            if (index != -1) {
              array.splice(index + 1, 0, ...allNodes[x].data.children);
            }
          }
        }
      })
        this.settingColumns = array;
        this.lineData = array;
        // this.getLineLists();
      }
    },
    flattenArray(arr) {
      let result = [];
      function flatten(arr) {
        arr.forEach((item) => {
          result.push(item);
          if (item.children && item.children.length > 0) {
            flatten(item.children);
          }
        });
      }
      flatten(arr);
      return result;
    },
    // 获取全部数据
    // async getData(val) {
    //   this.lineData = val
    //   this.settingColumns = val
    //   this.newLineData = val
    //   this.getLineLists()
    // },
    async getLineData() {
      this.getLineLists();
    },
    returnList() {
      this.showType = 1;
      this.getData();
    },
    // 根据产线返回计划
    async getLineLists() {
      this.loading = true;
      const obj = this.settingColumns.length > 0 ? this.settingColumns.map((item) => item.id) : [];
      const data = this.checkedList.length > 0 ? this.checkedList : obj;

      const params = {
        beginTime: dayjs(`${this.times[0]}`).format("YYYY-MM-DD HH:00"),
        endTime: dayjs(`${this.times[1]}`).format("YYYY-MM-DD HH:59"),
      };
      const res = await apis.getLineList(data, params);
      if (res) {
        const datas = this._.cloneDeep(this.lineData);

        const data = [];
        if (datas.length === 0) {
          this.loading = false;
          this.datasA = [];
          return false;
        }
        datas.map((item, index) => {
          res.map((items) => {
            if (item.id === items.areaId) {
              data.push({
                ...item,
                id: items.areaId,
                name: item.planName,
                planStartTime: this.fixStartDate(item.startTime, item.finishTime),
                planEndTime: this.fixEndDate(item.startTime, item.finishTime),
                isPlan: 1,
              });
            }
          });
        });
        let str = [];
        const strs = data.map((item) => {
          str = [item].map((items) => {
            return {
              start: new Date(items.planStartTime).toString(),
              end: new Date(items.planEndTime).toString(),
              ...item,
            };
          });
          return {
            ...item,
            gtArray: str,
          };
        });
        let currentAry = [];
        const start = dayjs(`${this.times[0]}`).format("YYYY-MM-DD HH:00");
        const end = dayjs(`${this.times[1]}`).format("YYYY-MM-DD HH:59");
        const timeData = strs.map((item) => {
          if (item.gtArray.length > 0) {
            currentAry = this.getTimes(item.gtArray)
          } else {
            currentAry = [];
          }
          return {
            ...item,
            // producePlanList: item.producePlanList,
            gtArray: currentAry,
          };
        });
        if (!this.showBtn) {
          this.datasA = timeData.filter((item) => item.gtArray.length !== 0);
          this.checkedList = this.datasA.length > 0 ? this.datasA.map((item) => item.id) : [];
        } else {
          this.datasA = timeData;
        }
        if (this.scale === 60) {
          this.scrollToTime = dayjs().subtract(3, "hour").minute(0).toString();
        } else {
          this.scrollToTime = dayjs().hour(0).minute(0).toString();
        }
        this.loading = false;
      }
    },
    fixEndDate(start, end) {
      if (start && !end) {
        return dayjs(new Date(new Date(start).setHours(23, 59, 59))).format("YYYY-MM-DD HH:mm:ss");
      } else {
        return end;
      }
    },
    fixStartDate(start, end) {
      if (!start && end) {
        return dayjs(new Date(new Date(end).setHours(0, 0, 0))).format("YYYY-MM-DD HH:mm:ss");
      } else {
        return start;
      }
    },
    compare(key) {
      return function (obj1, obj2) {
        if (obj1[key] < obj2[key]) {
          return -1;
        } else if (obj1[key] === obj2[key]) {
          return 0;
        } else {
          return 1;
        }
      };
    },
    getTimes(data) {
      let minTimes;
      let maxTimes;
      const newGtArray = data.map((item) => {
        if (item.actualStartTime) {
          // 实际开始时间小于计划开始时间 && 计划开始时间和计划结束时间都大于当前时间
          if (
            this.compareDate(item.planStartTime, item.actualStartTime) &&
            this.compareDate(item.planStartTime, this.currentDate) &&
            this.compareDate(item.planEndTime, this.currentDate)
          ) {
            minTimes = item.actualStartTime;
            maxTimes = item.planEndTime;
          }
          // 实际开始时间小于计划开始时间 && 计划开始时间小于当前时间 && 计划结束时间大于当前时间
          if (
            this.compareDate(item.planStartTime, item.actualStartTime) &&
            !this.compareDate(item.planStartTime, this.currentDate) &&
            this.compareDate(item.planEndTime, this.currentDate)
          ) {
            minTimes = item.actualStartTime;
            maxTimes = item.planEndTime;
          }
          // 实际开始时间小于计划开始时间 && 计划结束时间和计划开始时间都小于当前时间
          if (
            this.compareDate(item.planStartTime, item.actualStartTime) &&
            !this.compareDate(item.planStartTime, this.currentDate) &&
            this.compareDate(this.currentDate, item.planEndTime)
          ) {
            minTimes = item.actualStartTime;
            maxTimes = this.currentDate;
          }
          // 计划结束时间大于当前时间 && 实际开始时间大于计划开始时间
          if (
            this.compareDate(item.actualStartTime, item.planStartTime) &&
            this.compareDate(item.planEndTime, this.currentDate)
          ) {
            minTimes = item.planStartTime;
            maxTimes = item.planEndTime;
          }
          // 计划结束时间小于当前时间  && 实际开始时间大于计划开始时间
          if (
            !this.compareDate(item.actualStartTime, item.planEndTime) &&
            this.compareDate(item.actualStartTime, item.planStartTime) &&
            !this.compareDate(item.planEndTime, this.currentDate)
          ) {
            minTimes = item.planStartTime;
            maxTimes = this.currentDate;
          }
          // 实际开始时间大于计划结束时间和开始时间
          if (
            this.compareDate(item.actualStartTime, item.planEndTime) &&
            !this.compareDate(item.planEndTime, this.currentDate) &&
            !this.compareDate(item.planStartTime, this.currentDate)
          ) {
            minTimes = item.planStartTime;
            maxTimes = this.currentDate;
          }
        }
        if (!item.actualStartTime) {
          minTimes = item.planStartTime ? item.planStartTime : "";
          maxTimes = item.planEndTime ? item.planEndTime : "";
        }
        return {
          ...item,
          minTime: minTimes,
          maxTime: maxTimes,
        };
      });
      newGtArray.sort(this.compare("minTime"));
      return newGtArray;
    },
    // 原生js全屏
    screen() {
      // let element = document.documentElement;//设置后就是我们平时的整个页面全屏效果
      const element = document.getElementById("containers-left"); // 设置后就是   id==con_lf_top_div 的容器全屏
      if (!this.fullscreen) {
        if (
          document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.mozFullScreenElement ||
          document.msExitFullscreenElement
        ) {
          document.exitFullscreen();
        }
        if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
        if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        }
        if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
      if (this.fullscreen) {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        }
        if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        }
        if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        }
        if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = false;
    },
    // 全屏
    toggleFullScreen() {
      this.fullscreen = !this.fullscreen;
    },
    // 修改计划详情
    async modify(row) {
      this.dialogFormData = this._.cloneDeep(row);
      await this.getPlanNodeTimeList(row);
      if (
        this.dialogFormData.nodeAttribute === 1 ||
        this.dialogFormData.startTimeAttribute === 1 ||
        this.dialogFormData.finishTimeAttribute === 1
      ) {
        this.modifyType = "adjustTime";
      } else {
        this.modifyType = "modifyTime";
      }
      this.params = this._.cloneDeep(row);
      Object.assign(this.params, {
        planStartTime: row.startTime ? row.startTime : "",
        planEndTime: row.finishTime ? row.finishTime : "",
        startPlanId: row.startTimeRelationPlanId
          ? row.startTimeRelationPlanId + "-" + row.startTimeRelationTimeType
          : "",
        finishPlanId: row.finishTimeRelationPlanId
          ? row.finishTimeRelationPlanId + "-" + row.finishTimeRelationTimeType
          : "",
      });
      this.showStartTime = row.startTimeAttribute === 2 ? false : true;
      this.showFinishTime = row.finishTimeAttribute === 2 ? false : true;
      this.isChooseFinishAttr =
        row.finishTimeAttribute || row.finishTimeAttribute === 0 ? false : true;
      this.isChooseStartAttr =
        row.startTimeAttribute || row.startTimeAttribute === 0 ? false : true;
      this.planStartDateTime = this.planNodeTimeList.find(
        (item) => item.id === this.params.startPlanId
      )
        ? this.planNodeTimeList.find((item) => item.id === this.params.startPlanId).dateTime
        : "";
      this.planFinishDateTime = this.planNodeTimeList.find(
        (item) => item.id === this.params.finishPlanId
      )
        ? this.planNodeTimeList.find((item) => item.id === this.params.finishPlanId).dateTime
        : "";
      this.startTimeRelationPlanId = row.startTimeRelationPlanId;
      this.startTimeRelationTimeType = row.startTimeRelationTimeType;
      this.finishTimeRelationPlanId = row.finishTimeRelationPlanId;
      this.finishTimeRelationTimeType = row.finishTimeRelationTimeType;
      if (row.startTimeAttribute != 1) {
        this.adjustFormList[0].props.disabled = true;
      } else {
        this.adjustFormList[0].props.disabled = false;
      }
      if (row.finishTimeAttribute != 1) {
        this.adjustFormList[1].props.disabled = true;
      } else {
        this.adjustFormList[1].props.disabled = false;
      }
      this.dialogVisible = true;
    },
    async getPlanNodeTimeList(row) {
      const res = await papi.getPlanNodeTimeList({ projectId: row.projectId, planId: row.id });
      if (res) {
        this.planNodeTimeList = res;
      }
    },
    async getAllPlanNodeTimeList(row) {
      const res = await papi.getPlanNodeTimeList({ projectId: row.id });
      if (res) {
        this.planNodeTimeList = res;
      }
    },
    getPlanNodesTimeList(row) {
      let arr = this.planNodeTimeList.filter((item) => item.planId != row);
      return arr;
    },
    // 修改保存
    dialogSubmit(callback) {
      const { form } = this.$refs.dialogFormBuild;
      this.$refs.dialogFormBuild.formRefs().validate(async (valid) => {
        if (valid) {
          const res = await api.adjustSingPlanNode(this.createResetData(form));
          if (res) {
            this.$message.success("修改成功");
            this.dialogVisible = false;
            this.getData();
        }
        callback(true)
        } else {
          callback()
        }
      });
    },
    submitNodes(callback) {
      callback();
      this.adjustNodeVisible = false;
    },
    // 批量修改保存
    async dialogBatchSubmit(callback) {
      callback();
      let arr = this.projectType === "modifyTime" ? this.modifyTableData : this.adjustTableData;
      let api;
      api = this.projectType === "modifyTime" ? papi.batchModifyPlanNode : papi.batchAdjustPlanNode;
      const res = await api(
        this.projectType === "modifyTime"
          ? this.createBatchResetData(arr)
          : this.createBatchAdjustData(arr)
      );
      if (res) {
        this.$message.success("修改成功");
        this.projectNodesVisible = false;
        this.save();
      }
    },
    save() {
      this.$refs.schedules.$refs.mTable.setTableData(true);
    },
    createSetData(raw) {
      return {
        startTime: raw.startTime,
        finishTime: raw.finishTime,
        principalId: raw.principalId ? raw.principalId.join(",") : "",
        businessUnit: raw.principalDepartId ? raw.principalDepartId.join(",") : "",
        remark: raw.remark
      };
    },
    createAdjustData(raw) {
      return {
        planNodeId: raw.id,
        startTime: raw.startTime,
        finishTime: raw.finishTime,
        adjustReason: raw.adjustReason,
      };
    },
    createBatchResetData(arr) {
      return {
        list: arr.map((item) => {
          return {
            id: item.id,
            projectId: item.projectId,
            planName: item.planName,
            planTypeId: item.planTypeId,
            planObjectId: item.planObjectId,
            startTimeAttribute: item.startTimeAttribute,
            finishTimeAttribute: item.finishTimeAttribute,
            startTime: item.startTimeAttribute === 2 ? item.planStartDateTime : item.startTime,
            finishTime: item.finishTimeAttribute === 2 ? item.planFinishDateTime : item.finishTime,
            principalId: item.principalId,
            remark: item.remark,
            startTimeRelationPlanId:
              item.startTimeAttribute === 2 ? item.startTimeRelationPlanId : "",
            startTimeRelationTimeType:
              item.startTimeAttribute === 2 ? item.startTimeRelationTimeType : "",
            startTimeBasis: item.startTimeAttribute === 2 ? item.startTimeBasis : "",
            finishTimeRelationPlanId:
              item.finishTimeAttribute === 2 ? item.finishTimeRelationPlanId : "",
            finishTimeRelationTimeType:
              item.finishTimeAttribute === 2 ? item.finishTimeRelationTimeType : "",
            finishTimeBasis: item.finishTimeAttribute === 2 ? item.finishTimeBasis : "",
          };
        }),
      };
    },
    createBatchAdjustData(arr) {
      return {
        list: arr.map((item) => {
          return {
            planNodeId: item.id,
            startTime: item.startTime,
            finishTime: item.finishTime,
            adjustReason: item.adjustReason,
          };
        }),
      };
    },
    createResetData(row) {
      const obj = {
        id: row.id,
      };
      return Object.assign(this.createSetData(row), obj);
    },
    // 下发至工单
    async fixWorkOrder(row) {
      const ids = [];
      ids.push(row.id);
      const res = await apis.getPage(ids);
      if (res) {
        this.isStatus = false;
        this.$message.success("下发成功");
        this.$refs.schedules.getScheduleList();
      }
    },
  },
};
</script>

  <style scoped lang="scss">
.form-li {
  display: flex;
  justify-content: flex-end;
  .today {
    width: 60px;
    height: 31px;
    border-radius: 4px;
    border: 1px solid #a4b3c6;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393d60;
    line-height: 31px;
    text-align: center;
    margin-right: 6px;
    cursor: pointer;
  }
  img {
    width: 32px;
    height: 33px;
    display: block;
    margin: 0 12px;
    cursor: pointer;
  }
  .date-tit {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393d60;
    line-height: 33px;
    width: 110px;
    text-align: center;
  }
}
.schedule-title {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 700;
  color: #393d60;
  line-height: 33px;
}
.common-date {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  .today {
    width: 60px;
    height: 31px;
    border-radius: 4px;
    border: 1px solid #a4b3c6;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393d60;
    line-height: 31px;
    text-align: center;
    margin-right: 6px;
    cursor: pointer;
  }
  img {
    width: 32px;
    height: 33px;
    display: block;
    margin: 0 12px;
    cursor: pointer;
  }
  .date-tit {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393d60;
    line-height: 33px;
    width: 110px;
    text-align: center;
  }
}
body {
  font-size: 12px;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
#apps {
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
  height: 100%;
  background: #f0f2f5;
  position: relative;
}
.top-bar {
  .today {
    display: inline-block;
    width: 60px;
    height: 31px;
    border-radius: 4px;
    border: 1px solid #a4b3c6;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393d60;
    line-height: 31px;
    text-align: center;
    margin-right: 6px;
    cursor: pointer;
  }
  img {
    width: 32px;
    height: 33px;
    display: inline-block;
    margin: 0 12px;
    cursor: pointer;
  }
  .date-tit {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393d60;
    line-height: 33px;
    width: 110px;
    text-align: center;
    display: inline-block;
  }
  .tag-status {
    padding: 4px 10px;
    margin-left: 10px;
    border-radius: 4px;
  }
}
label {
  margin-left: 10px;
}
input {
  width: 40px;
  height: 20px;
  vertical-align: middle;
}
input[type="range"] {
  width: 100px;
}
::v-deep {
  .gantt-header-title {
    width: 291px !important;
  }
  .gantt-leftbar-wrapper {
    width: 290px !important;
  }
  // .gantt-leftbar-item {
  //   border-bottom: 1px solid #ececec;
  //   min-height:60px;
  // }
  // .gantt-block{
  //   background-size: 100px auto !important;
  //   min-height:60px;
  // }
  // .gantt-block-top-space{
  //   min-height:0;
  // }
  .gantt-timeline {
    margin-left: 0 !important;
  }
  .gantt-scroll-x {
    margin-left: 291px !important;
    width: calc(100% - 291px) !important;
  }
  .gantt-markline-area {
    margin-left: 291px !important;
  }
  .el-checkbox__label {
    font-size: 12px;
  }
  .el-popover,
  .el-popper {
    padding: 12px 12px 0;
  }
  .m-radio-group .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    width: 90%;
  }
}
// 修改计划
.edit-form {
  .form-btn {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    .li-btn {
      width: 88px;
      height: 32px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #607fff;
      cursor: pointer;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #607fff;
      line-height: 32px;
      text-align: center;
    }
  }
  .form-top {
    padding: 20px 20px 0;
    background: #f7f9fc;
    border-radius: 4px;
    display: flex;
    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #4a4f76;
    }
  }
}
// 左边头部
.common-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-right: 1px solid #dbe1e7;
  position: relative;
  .header-le {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 12px;
    .le-img {
      width: 30px;
      height: 30px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        width: 15px;
        height: 15px;
        display: block;
      }
      &:hover {
        background: #f8f8f8;
      }
    }
  }
  .header-ri {
    margin-right: 10px;
    cursor: pointer;
    position: absolute;
    right: 0;
    img {
      width: 15px;
      height: 15px;
      display: block;
    }
  }
}
.showMore {
  padding: 0 10px;
  height: 40px;
  border-radius: 4px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #607fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
  .icons {
    color: #dde3eb;
  }
  &:hover {
    background: #f8f8f8;
    i {
      color: #607fff;
    }
  }
}
// 筛选产线
.popover-content-top {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  button {
    padding: 8px 20px;
    border-radius: 4px;
    border-color: #dedeee;
    color: #8a8ca5;
    &:last-child {
      border-color: #607fff;
      color: #607fff;
    }
  }
}
.popover-content-center {
  width: calc(100% + 24px);
  position: relative;
  left: -12px;
  margin-top: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  background: #f8f8fb;
  height: 30px;
  button.invert {
    span {
      color: #8a8ca5;
    }
    img {
      position: relative;
      top: 2px;
      right: 10px;
    }
  }
}
.popover-content-footer {
  width: calc(100% + 24px);
  position: relative;
  left: -12px;
  max-height: 400px;
  overflow-y: scroll;
  .el-checkbox-group {
    // overflow: hidden;
    .el-checkbox {
      // width: 100%;
      padding: 8px 8px 8px 0px;
      cursor: pointer;
      // &:hover {
      //   // background: #ECF0F4;
      // }
    }
  }
}
.containers {
  height: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  .tip-img {
    width: 10px;
    height: 38px;
    display: block;
    position: absolute;
    top: calc(50%);
    left: -10px;
    cursor: pointer;
    img {
      width: 10px;
      height: 38px;
      display: block;
    }
  }
}
.app-left {
  width: calc(100% - 276px);
  background: #fff;
  padding: 0 10px;
}
.app-width {
  width: 100%;
  background: #fff;
  padding: 0 10px;
}
.app-left-full {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
  background: #fff;
  width: 100%;
  z-index: 2000;
  padding: 0 10px;
}
// 右边栏样式
.app-right {
  width: 266px;
  background: #f0f2f5;
  height: 100%;
  // height: calc(100% + 20px);
  overflow-y: hidden;
}
.el-slider {
  width: 100px;
}
/* 样式 */
.gatter-form {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  flex-wrap: wrap;
}
.detail-box {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  .item-box {
    margin-right: 14px;
    margin-bottom: 20px;
    .item {
      width: 121px;
      height: 38px;
      border-radius: 4px;
      font-size: 12px;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: 35px;
      position: relative;
      margin-bottom: 10px;
      padding: 0 5px;
    }
    .sign {
      position: absolute;
      top: 0;
      left: 0;
      width: 17px;
      height: 17px;
    }
    .progress-bg {
      width: 100%;
      height: 4px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: #a2b3e2;
      border-radius: 2px;
      .progress-bar {
        height: 4px;
        background: linear-gradient(90deg, #356c90 0%, #54aaff 100%);
        border-radius: 2px;
      }
      .apply-bar {
        height: 4px;
        background: linear-gradient(90deg, #44ec52 0%, #98e191 100%);
        border-radius: 2px;
      }
    }
    .item-finished {
      color: #4b8358;
      background: #eafbeb;
      border: 1px solid #a5dab2;
    }
    .item-running {
      color: #5b7faf;
      background: #ebf4ff;
      border: 1px solid #a4c9ed;
    }
    .item-wait {
      color: #868686;
      border: 1px solid #c4c4c4;
      background: #eeeeee;
    }
  }
}
</style>
