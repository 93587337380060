var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orders" },
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c("SearchForm", {
            ref: "searchForm",
            staticStyle: { padding: "0" },
            attrs: {
              "form-list-extend": _vm.searchFormList,
              "form-data": _vm.searchFormData
            },
            on: {
              "update:formData": function($event) {
                _vm.searchFormData = $event
              },
              "update:form-data": function($event) {
                _vm.searchFormData = $event
              },
              search: function() {
                return _vm.$refs.mTable.setTableData(true)
              }
            }
          })
        ],
        1
      ),
      _c(
        "FunctionHeader",
        {
          ref: "functionHeader",
          staticClass: "func-header",
          staticStyle: { padding: "10px 0 10px 0" },
          attrs: {
            "search-title": "请输入采购订单编号",
            tags: !_vm.selectIds.length ? _vm.functionHeader.tags : []
          },
          on: {
            search: function($event) {
              return _vm.$refs.mTable.setTableData(true)
            }
          },
          model: {
            value: _vm.functionHeader.searchVal,
            callback: function($$v) {
              _vm.$set(_vm.functionHeader, "searchVal", $$v)
            },
            expression: "functionHeader.searchVal"
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.selectIds.length,
                  expression: "selectIds.length"
                }
              ],
              attrs: { slot: "all" },
              slot: "all"
            },
            [
              _vm.permission("Export")
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.allMore("onExport")
                        }
                      }
                    },
                    [_vm._v("批量导出")]
                  )
                : _vm._e(),
              _vm.permission("Distribute")
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.allMore("onDistribute")
                        }
                      }
                    },
                    [_vm._v("批量下发")]
                  )
                : _vm._e(),
              _vm.permission("End")
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.allMore("onEnd")
                        }
                      }
                    },
                    [_vm._v("批量完结")]
                  )
                : _vm._e(),
              _vm.permission("Cancel")
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.allMore("onCancel")
                        }
                      }
                    },
                    [_vm._v("批量取消")]
                  )
                : _vm._e(),
              _vm.permission("Del")
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.allMore("onDel")
                        }
                      }
                    },
                    [_vm._v("批量删除")]
                  )
                : _vm._e(),
              _c(
                "span",
                {
                  staticStyle: {
                    margin: "0 10px",
                    display: "inline-block",
                    "font-size": "12px",
                    color: "#393D60"
                  }
                },
                [
                  _vm._v(" 已选 "),
                  _c("span", { staticStyle: { color: "#607FFF" } }, [
                    _vm._v(_vm._s(_vm.getSelectionDataLength))
                  ]),
                  _vm._v(" / " + _vm._s(_vm.total) + "项 ")
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "text", size: "small" },
                  on: { click: _vm.resetSelectData }
                },
                [_vm._v("清空")]
              )
            ],
            1
          )
        ]
      ),
      _c("MTable", {
        ref: "mTable",
        attrs: {
          columns: _vm.columns,
          height: _vm.height,
          "set-data-method": _vm.getTableData
        },
        on: { "selection-change": _vm.selectionChange },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function(ref) {
              var $index = ref.$index
              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
            }
          },
          {
            key: "code",
            fn: function(ref) {
              var row = ref.row
              return _c(
                "div",
                {},
                [
                  _c("NewPageOpen", {
                    attrs: {
                      path: {
                        name: "purchaseOrderDetail",
                        query: { id: row.id }
                      },
                      text: row.code
                    }
                  })
                ],
                1
              )
            }
          },
          {
            key: "receivedStatus",
            fn: function(ref) {
              var row = ref.row
              return _c("div", {}, [
                _vm._v(
                  " " + _vm._s(_vm.receivedStatusMap[row.receivedStatus]) + " "
                )
              ])
            }
          },
          {
            key: "orderStatus",
            fn: function(ref) {
              var row = ref.row
              return _c(
                "div",
                { style: { color: _vm.orderStatusColorMap[row.orderStatus] } },
                [
                  _vm._v(
                    " " + _vm._s(_vm.orderStatusMap[row.orderStatus]) + " "
                  )
                ]
              )
            }
          },
          {
            key: "action",
            fn: function(ref) {
              var row = ref.row
              return _c(
                "div",
                {},
                [
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        content:
                          "当前采购订单" +
                          _vm.orderStatusMap[row.orderStatus] +
                          "，不可下发",
                        disabled: ["Waiting"].includes(row.orderStatus),
                        placement: "top-end"
                      }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "mr-10" },
                        [
                          _vm.permission("Distribute")
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "text",
                                    disabled: !["Waiting"].includes(
                                      row.orderStatus
                                    )
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onHandle([row.id], "commit")
                                    }
                                  }
                                },
                                [_vm._v("下发")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        content:
                          "当前采购订单" +
                          _vm.orderStatusMap[row.orderStatus] +
                          "，不可完结",
                        disabled: ["Dispatched"].includes(row.orderStatus),
                        placement: "top-end"
                      }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "mr-10" },
                        [
                          _vm.permission("End")
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "text",
                                    disabled: !["Dispatched"].includes(
                                      row.orderStatus
                                    )
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onHandle([row.id], "finish")
                                    }
                                  }
                                },
                                [_vm._v("完结")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        content:
                          "当前采购订单" +
                          _vm.orderStatusMap[row.orderStatus] +
                          "，不可取消",
                        disabled: ["Waiting", "Dispatched"].includes(
                          row.orderStatus
                        ),
                        placement: "top-end"
                      }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "mr-10" },
                        [
                          _vm.permission("Cancel")
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "text",
                                    disabled: ![
                                      "Waiting",
                                      "Dispatched"
                                    ].includes(row.orderStatus)
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onHandle([row.id], "cancel")
                                    }
                                  }
                                },
                                [_vm._v("取消")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        content:
                          "当前采购订单" +
                          _vm.orderStatusMap[row.orderStatus] +
                          "，不可编辑",
                        disabled: ["Waiting", "Dispatched"].includes(
                          row.orderStatus
                        ),
                        placement: "top-end"
                      }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "mr-10" },
                        [
                          _vm.permission("Edit")
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "text",
                                    disabled: ![
                                      "Waiting",
                                      "Dispatched"
                                    ].includes(row.orderStatus)
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.jump("edit", row.id)
                                    }
                                  }
                                },
                                [_vm._v("编辑")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        content:
                          "当前采购订单" +
                          _vm.orderStatusMap[row.orderStatus] +
                          "，不可删除",
                        disabled: ["Waiting", "Cancelled"].includes(
                          row.orderStatus
                        ),
                        placement: "top-end"
                      }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "mr-10" },
                        [
                          _vm.permission("Del")
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    disabled: ![
                                      "Waiting",
                                      "Cancelled"
                                    ].includes(row.orderStatus),
                                    type: "text"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onHandle([row.id], "del")
                                    }
                                  }
                                },
                                [_vm._v("删除")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }