
export const MaterialColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    slotName: 'batchNumberDetail',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsSpecifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true,
    width: 340
  }
]
export const BarchColumns = [
  {
    prop: 'batchNumber',
    label: '原材料批次码',
    hiddenSearch: true,
    sortable: false

  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsSpecifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsUnit',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  }
]

export const ProductionColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 200
  },
  {
    prop: 'produceWorkOrderNumber',
    label: '生产工单号',
    slotName: 'orderNo',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'procedureName',
    label: '工序名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'procedureIndex',
    label: '工序执行顺序',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'quantity',
    label: '投产数量',
    sortable: false,
    hiddenSearch: true
  }
]

export const QualityRecordColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'qualityNumber',
    label: '质检单号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'qualityInspectionName',
    label: '质检方案',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'id',
    label: '质检记录',
    slotName: 'look',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'inspectResult',
    label: '质检结果',
    slotName: 'inspectResult',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'userName',
    label: '操作人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'operateTime',
    label: '操作时间',
    sortable: false,
    hiddenSearch: true
  }
]
