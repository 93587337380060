/** write by luomingzhi */
import { status } from '@/config/options.config'
import { formatColumns } from '@/utils'

// 缺陷项
const DefectsItem = [
  {
    prop: 'index',
    label: '序号',
    width: 80,
    slotName: 'index',
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '缺陷项名称',
    hiddenSearch: true,
    sortable: false,
    form: { tagName: 'el-input' }
  },
  {
    prop: 'creator',
    label: '创建人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'createTime',
    label: '创建时间',
    sortable: 'custom',
    hiddenSearch: true,
    width: 180
  },
  {
    prop: 'lastUpdateTime',
    label: '最近更新时间',
    sortable: 'custom',
    hiddenSearch: true,
    width: 180
  },
  {
    prop: 'isEnabled',
    label: '缺陷项状态',
    slotName: 'isEnabled',
    sortable: false,
    filters: formatColumns(status),
    form: {
      tagName: 'el-select',
      defaultValue: 1,
      options: status
    }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' }
    }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 165
  }
]
// 物料类型
const DefectsReason = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '缺陷原因',
    hiddenSearch: true,
    sortable: false,
    form: { tagName: 'el-input' }
  },
  {
    prop: 'creator',
    label: '创建人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'createTime',
    label: '创建时间',
    sortable: 'custom',
    hiddenSearch: true
  },
  {
    prop: 'lastUpdateTime',
    label: '最近更新时间',
    sortable: 'custom',
    hiddenSearch: true
  },
  {
    prop: 'isEnabled',
    label: '缺陷原因状态',
    slotName: 'isEnabled',
    sortable: false,
    filters: formatColumns(status),
    form: {
      tagName: 'el-select',
      defaultValue: 1,
      options: status
    }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' }
    }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 165
  }
]

export default {
  DefectsItem,
  DefectsReason
}
