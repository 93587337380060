<template>
  <div class="role-wrap">
    <div class="orders">
      <div class="top">
        <SearchForm
          ref="searchForm"
          :form-list-extend="taskFormList"
          :form-data="searchFormData"
          style="padding: 0"
          :default-form="defaultForm"
          @update:formData="data=>searchFormData=data"
          @search="reSearch"
        />

      </div>
      <FunctionHeader
        ref="functionHeader"
        v-model="functionHeader.searchVal"
        search-title="搜索工序定位码/编号/名称"
        style="padding: 10px 0 10px 0;"
        class="func-header"
        :tags="functionHeader.tags"
        @search="$refs.mTable.setTableData(true)"
    >
      <div slot="all" style="margin-left:10px">
        <el-button :disabled="!selectData.length" v-if="permission('BatchExport')" size="small" type="primary"  @click="batchExport()" >批量导出</el-button>
          </div>
          </FunctionHeader>
      <MTable
        ref="mTable"
        class="table-left"
        :columns="productionTaskColumn"
        :height="height"
        :set-data-method="getTableData"
        @selection-change="data=>selectData = data"
      >
        <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
        <div slot="productionStatus" slot-scope="{row }">
          {{ ['待开始','进行中','已完成'][row.productionStatus] }}
        </div>
        <div slot="assignStatus" slot-scope="{row }">
            {{ ['未派工','已派工'][row.assignStatus] }}
         </div>
        <NewPageOpen
          slot="materialsCode"
          slot-scope="{ row }"
          :path="{ name: 'materialsDetail', query: {id: row.materialsId,type:'view'}}"
          :text="row.materialsCode"
        />
        <NewPageOpen
          slot="procedureCode"
          slot-scope="{ row }"
          :path="{ name: 'procedureDetail', query: {id: row.procedureId,type:'view'}}"
          :text="row.procedureCode"
        />
        <template slot="planOutputQuantity" slot-scope="{ row }">
          <div>{{ row.planOutputQuantity }}</div>
          <!-- <el-input-number
            v-else
            v-model="row.planOutputQuantity"
            :clearable="false"
            :max="row.netRequireQuantity"
            :controls="false"
            :style="{border:row.isWarningPlanCount?'1px solid #F56C6C':'',borderRadius:'4px',width:'110px'}"
            @blur="e=>onBlur(e,row,'planOutputQuantity')"
          /> -->
        </template>
        <template slot="planTime" slot-scope="{ row }">
          <div v-if="!row.isEdit">{{ row.planTime[0] || row.planTime[1]  ? row.planTime.join(' 至 ') : '-' }}</div>
          <el-date-picker
            v-else
            v-model="row.planTime"
            type="daterange"
            :clearable="false"
            range-separator="至"
            start-placeholder="计划开工时间"
            end-placeholder="完工时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
          />
        </template>

        <template slot="remark" slot-scope="{ row }">
          <div v-if="!row.isEdit">{{ row.remark }}</div>
          <el-input v-else v-model="row.remark" />
        </template>

        <template slot="action" slot-scope="{ row }">
          <div>
            <span v-show="!row.isEdit">
              <el-button
                v-if="permission('Edit')"
                :disabled="row.productionStatus === 2 || hasEditData"
                type="text"
                @click="modify(row)"
              >编辑<i
                class="el-icon-edit"
              /></el-button>
              <!-- <el-button
                v-if="permission('Del')"
                type="text"
                @click="onDel([row.id])"
              >删除</el-button> -->
            </span>
            <span v-show="row.isEdit">
              <el-button type="text" @click="cancelModify(row)">取消</el-button>
              <el-button type="text" @click="onSave(row)">保存</el-button>
            </span>&nbsp;
            <el-button
              v-if="permission('Assign') "
              :type="assignProductionTask(row.userGroupList.length || row.userList.length?'已派工':'派工任务')"
              :disabled="row.productionStatus === 2"
              @click="assignClick(row, row.userGroupList.length|| row.userList.length)"
            >{{ row.userGroupList.length|| row.userList.length?'已派工':'派工任务' }}</el-button>

            <!-- <el-button
              v-if="+row.producePlanStatus!==1&&row.productionStatus === 2&& permission('Restart') "
              type="text"
              @click="restart(row)"
            >重启</el-button>
            <el-tooltip effect="dark" content="所属生产工单已完结，无法重启" placement="top">
              <el-button
                v-if="+row.producePlanStatus===1&&row.productionStatus === 2&& permission('Restart') "
                class="is-disabled"
                type="text"
              >重启</el-button>
            </el-tooltip> -->
          </div>
        </template>
      </MTable>
    </div>

    <!-- 指派任务-->
    <MDialog v-model="assignVisibleNo" title="派工任务" @onOk="submitUser">
      <div style="text-align:right;margin-bottom:10px">
        <el-button
          v-if="showAssign"
          size="small"
          type="primary"
          @click="cancelAssign(1)"
        >取消派工</el-button>
      </div>
      <SelectUserAndUserGroup
        ref="userAndUserGroup"
        v-model="transferSelectIds"
        :is-assign="false"
        :user-options="userOptions"
        :user-group-options="userGroupOptions"
      />
    </MDialog>
  </div>
</template>
<script>
import api from '@/api/production/project'
import taskApi from '@/api/production/operation-task'
import FunctionHeader from '@/components/FunctionHeader'
import SearchForm from '@/components/SearchForm/SearchForm'
import { productionTaskColumn, taskFormList } from './data'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
import SelectUserAndUserGroup from '@/components/SelectUserAndUserGroup/SelectUserAndUserGroup'
import dayjs from 'dayjs'

export default {
  name: 'ProductionTask',
  components: { NewPageOpen, FunctionHeader, SearchForm, SelectUserAndUserGroup },
  data() {
    return {
      api,
      taskFormList,
      searchFormData: {},
      functionHeader: {
        searchVal: '',
        tags: [
          {
            hidden: !this.permission('Assign'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small', disabled: true },
            innerText: '批量派工',
            on: {
              click: () => this.batchAssignProductionTask('batch')
            }
          },
          {
            hidden: !this.permission('Assign'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            innerText: '全部派工',
            on: {
              click: () => this.batchAssignProductionTask('all')
            }
          }
        ]
      },
      productionTaskColumn,
      selectData: [],
      userOptions: [],
      userGroupOptions: [],
      transferSelectIds: [],
      assignVisibleNo: false,
      showAssign: false,
      hasEditData:false,
      defaultForm: { productionStatus: [0, 1], assignStatus: [0] },
      assignProductionTask:function(val) {
        if (val == '已派工') {
            return 'success'
        } else {
            return 'danger'
        }
      }
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 220 - 65
    }
  },
  watch: {
    selectData(val) {
      this.functionHeader.tags[0].props.disabled = val.length < 2
    }
  },
mounted(){
},
  methods: {
      async batchExport() {
          const params = { productionTaskIds: this.selectData.map(item => item.id) }
          const res = await api.exportProductionTask(params)
          if (res && res.data.byteLength) {
            const urlP = window.URL.createObjectURL(
              new Blob([res.data], {
                type: 'application/octet-stream;charset=ISO8859-1'
              })
            )
            const fileName = `${dayjs().format('YYYYMMDDHHmmss')}.xls`
            this.downloadFile(urlP, fileName)
          } else {
            this.$message.error('导出失败，内容为空')
          }
      },
      downloadFile(urlP, name) {
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = urlP
          link.setAttribute('download', name)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        },
    modify(row){
      this.$set(row,'isEdit',true)
      this.hasEditData = true
    },
    cancelModify(row){
      this.$set(row,'isEdit',false)
      this.hasEditData = false
    },
    async batchAssignProductionTask(assignType) {
      this.assignType = assignType
      this.produceWorkOrderTaskIds = this.selectData.map(item => item.id)
      this.transferSelectIds = []
      await this.getUser()
      this.assignVisibleNo = true
    },

    // 重启
    async restart({ id: productionTaskId }) {
      const res = await taskApi.restartTask(productionTaskId)
      if (res) {
        this.$message.success('操作成功')
        this.$refs.mTable.setTableData()
      }
    },

    // 操作指派任务
    async assignClick(item, isAssign) {
      this.assignType = 'single'
      this.showAssign = isAssign
      this.produceWorkOrderTaskIds = [item.id]
      await this.getUser(item.id)
      const userKeys = item.userList ? item.userList.map(item => item.key) : []
      const groupKeys = item.userGroupList ? item.userGroupList.map(item => item.key) : []
      this.transferSelectIds = [...userKeys, ...groupKeys]
      this.assignVisibleNo = true
    },
    // 用户组
    async getUser(id) {
      const res = await taskApi.getUsers()
      if (res) {
        this.userOptions = res.userList
        this.userGroupOptions = res.userGroupList
      }
    },
    // 取消指派
    async cancelAssign(type) {
      const res = await taskApi.cancelAssign(this.produceWorkOrderTaskIds[0])
      if (res) {
        this.assignVisibleNo = false
        this.$refs.mTable.setTableData()
      }
    },
    // 指派提交
    async submitUser(done) {
      done()
      const { user: userIdList, userGroup: userGroupIdList } = this.$refs.userAndUserGroup.getFormatData()
      if (userIdList.length === 0 && userGroupIdList.length === 0) {
        this.$message.error('指派人列表不能为空')
        return false
      }
      let params = {
        userIdList,
        userGroupIdList
      }
      let apiName
      if (this.assignType === 'single') {
        params.productionTaskId = this.produceWorkOrderTaskIds[0]
        apiName = 'getTaskUse'
      } else if (this.assignType === 'batch') {
        params.productionTaskIds = this.produceWorkOrderTaskIds
        apiName = 'batchAssignProductionTask'
      } else {
        params = { ...params, ...this.allAssignParams }
        apiName = 'allAssignProductionTask'
      }
      const res = await taskApi[apiName](params)
      if (res) {
        this.$message.success('指派成功')
        this.$refs.mTable.setTableData()
        this.assignVisibleNo = false
        this.$refs.mTable.tableRefs().clearSelection()
      }
    },
    async onDel(ids) {
      this.$confirm('是否确认删除？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.delTask({ ids: ids.join(',') })
        if (res) this.$message.success('操作成功')
        this.$refs.mTable.setTableData(true)
      })
    },
    onSave(row) {
      this.$confirm('是否确认提交？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const { id, planOutputQuantity, remark, planTime } = row
        const params = {
          id,
          planOutputQuantity,
          remark,
          planStartTime: planTime ? planTime[0] : null,
          planEndTime: planTime ? planTime[1] : null
        }
        const res = await api.updateTask(params)
        if (res) {
          this.$message.success('操作成功')
          this.$refs.mTable.setTableData(true)
          this.$set(row, 'isEdit', false)
          delete row.isEdit
          this.hasEditData = false
        }
      })
    },
    onBlur(e, row, key, num = 3) {
      const valStr = e.target.value + ''
      const decimalLength = valStr.split('.')[1]?.length
      if (decimalLength > num) {
        e.target.value = valStr.slice(0, valStr.length - (decimalLength - num)) * 1
        row[key] = e.target.value
      }
    },
    reSearch(data, val) {
      if (val === 1) {
        this.searchFormData = {}
      }
      setTimeout(() => {
        this.$refs.mTable.setTableData(true)
      }, this.searchFormData?.productionStatus?.length === 2 ? 300 : 0)
    },

    // 列表
    async getTableData(condition, callback) {
      const { page } = condition
      const { code, producePlanNumber, materials, planStartTime, planEndTime, productionStatus, assignStatus } = this.searchFormData
      const params = {
        producePlanNumber,
        materials,
        code,
        productionStatus: productionStatus?.join(),
        assignStatus: assignStatus?.join(),
        procedure: this.functionHeader.searchVal,
        planStartStartTime: planStartTime ? planStartTime[0] : null,
        planStartEndTime: planStartTime ? planStartTime[1] : null,
        planEndStartTime: planEndTime ? planEndTime[0] : null,
        planEndEndTime: planEndTime ? planEndTime[1] : null
      }
      this.allAssignParams = params
      const res = await api.getTaskList({
        page: page.currentPage,
        size: page.pageSize,
        ...params
      })
      // this.notFirstSearch = true
      if (res) {
        res.records.forEach(item => {
          item.planTime = [item.planStartTime ? item.planStartTime : '', item.planEndTime ? item.planEndTime : '']
        })
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    }
  }
}
</script>

<style scoped lang="scss">
.orders {
  padding: 10px;
}
::v-deep .custom-sel {
  div {
    min-width: 100%;
  }
}
::v-deep .el-table {
  .cell {
    display: flex;
    align-items: center;
  }
}
</style>
