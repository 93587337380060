var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "de-container" },
        [
          _c("FunctionHeader", {
            attrs: {
              tags: _vm.functionHeader.tags,
              "search-title": _vm.searchTitle
            },
            on: {
              search: function($event) {
                return _vm.$refs.mTable.setTableData(true)
              }
            },
            model: {
              value: _vm.functionHeader.searchVal,
              callback: function($$v) {
                _vm.$set(_vm.functionHeader, "searchVal", $$v)
              },
              expression: "functionHeader.searchVal"
            }
          }),
          _c("MTable", {
            ref: "mTable",
            attrs: {
              columns: _vm.columns,
              "set-data-method": _vm.getTableData,
              "columns-key": _vm.columnsKey,
              height: _vm.height
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "action",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      row.status === 0 && _vm.permission("TransferScrap")
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.transferscrap(row)
                                }
                              }
                            },
                            [_vm._v("移至废品仓")]
                          )
                        : _vm._e(),
                      row.status === 1 && _vm.permission("TransferScrap")
                        ? _c(
                            "el-button",
                            { attrs: { type: "text", disabled: "" } },
                            [_vm._v("移至废品仓")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }
              }
            ])
          }),
          _c(
            "div",
            [
              _c(
                "MDialog",
                {
                  attrs: {
                    "append-to-body": true,
                    title: "新增",
                    width: "90%"
                  },
                  on: { onOk: _vm.submitunquality },
                  model: {
                    value: _vm.visible,
                    callback: function($$v) {
                      _vm.visible = $$v
                    },
                    expression: "visible"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "drawer-top" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "e-btn",
                          attrs: { type: "primary" },
                          on: { click: _vm.selectadd }
                        },
                        [_vm._v("选择添加物料")]
                      ),
                      _c("el-input", {
                        staticClass: "e-ipt",
                        attrs: { placeholder: "请扫/请输入批次码" },
                        on: { change: _vm.searchBatch },
                        model: {
                          value: _vm.searchForm.Batchcode,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "Batchcode", $$v)
                          },
                          expression: "searchForm.Batchcode"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "container" },
                    [
                      [
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { data: _vm.addunquality }
                          },
                          [
                            _c("el-table-column", {
                              attrs: { label: "序号", prop: "id", width: "80" }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "name",
                                label: "物料名称",
                                width: "150"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "规格",
                                prop: "specifications",
                                width: "120"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: { label: "批次码" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "all-tips" },
                                        [
                                          scope.row.isBatchManager === 1
                                            ? _c(
                                                "span",
                                                { staticClass: "coltip" },
                                                [_vm._v("*")]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "180px" },
                                              attrs: {
                                                placeholder: "请选择",
                                                filterable: ""
                                              },
                                              on: {
                                                focus: function($event) {
                                                  return _vm.getBatchNumber(
                                                    scope.row.id
                                                  )
                                                }
                                              },
                                              model: {
                                                value: scope.row.batchNumber,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "batchNumber",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.batchNumber"
                                              }
                                            },
                                            _vm._l(_vm.numberList, function(
                                              item
                                            ) {
                                              return _c("el-option", {
                                                key: item.batchNumber,
                                                attrs: {
                                                  label: item.batchNumber,
                                                  value: item.batchNumber
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            }),
                            _c("el-table-column", {
                              attrs: { label: "单位", prop: "mainUnitName" }
                            }),
                            _c("el-table-column", {
                              attrs: { label: "数量", width: "120" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "all-tips" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "coltip" },
                                            [_vm._v("*")]
                                          ),
                                          _c("el-input", {
                                            model: {
                                              value: scope.row.quantity,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "quantity",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.quantity"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            }),
                            _c("el-table-column", {
                              attrs: { label: "质检方案", width: "150" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "all-tips" },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "180px" },
                                              attrs: { placeholder: "请选择" },
                                              model: {
                                                value:
                                                  scope.row
                                                    .qualityInspectionPlanId,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "qualityInspectionPlanId",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.qualityInspectionPlanId"
                                              }
                                            },
                                            _vm._l(_vm.PlanList, function(
                                              item
                                            ) {
                                              return _c("el-option", {
                                                key: item.id,
                                                attrs: {
                                                  label: item.name,
                                                  value: item.id
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            }),
                            _c("el-table-column", {
                              attrs: { label: "操作" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: { color: "red" },
                                          attrs: { type: "text" },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleDetele(
                                                scope.row.id
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("删除")]
                                      )
                                    ]
                                  }
                                }
                              ])
                            })
                          ],
                          1
                        )
                      ]
                    ],
                    2
                  )
                ]
              ),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: "请输入物料名称",
                    visible: _vm.state.materianamestate,
                    width: "30%",
                    "before-close": _vm.handleClose
                  },
                  on: {
                    "update:visible": function($event) {
                      return _vm.$set(_vm.state, "materianamestate", $event)
                    }
                  }
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.materianames,
                      callback: function($$v) {
                        _vm.materianames = $$v
                      },
                      expression: "materianames"
                    }
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer"
                    },
                    [
                      _c("el-button", { on: { click: _vm.cancle } }, [
                        _vm._v("取 消")
                      ]),
                      _c(
                        "el-button",
                        { attrs: { type: "primary" }, on: { click: _vm.sure } },
                        [_vm._v("确 定")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("select-materials", {
        attrs: {
          visible: _vm.showMaterials,
          "select-materials": _vm.selectMaterialList
        },
        on: {
          "update:visible": function($event) {
            _vm.showMaterials = $event
          },
          submitForm: _vm.submitMaterials
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }