export const stationColums = [
  {
    prop: 'stationName',
    label: '工位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'baoQuantity',
    label: '报工数量',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'quantity',
    label: '返修数量',
    sortable: false,
    hiddenSearch: true,
    slotName: 'quantity'
  }
]

