var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "inspection-plan-detail-wrapper" }, [
    _c("div", { staticClass: "inspection-plan-detail-body" }, [
      _c("div", { staticClass: "detail-card" }, [
        _c(
          "div",
          {
            staticClass: "card-tit",
            staticStyle: { height: "48px", "line-height": "48px" }
          },
          [
            _vm._v("计算范围设置 "),
            _c(
              "el-button",
              {
                staticStyle: { float: "right", margin: "8px 8px 0 0" },
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.onSave }
              },
              [_vm._v("保存")]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _c(
              "el-radio-group",
              {
                staticStyle: { padding: "20px" },
                on: { change: _vm.changeType },
                model: {
                  value: _vm.type,
                  callback: function($$v) {
                    _vm.type = $$v
                  },
                  expression: "type"
                }
              },
              [
                _c("el-radio", { attrs: { label: 0 } }, [_vm._v("自制件")]),
                _c("el-radio", { attrs: { label: 1 } }, [_vm._v("采购件")])
              ],
              1
            )
          ],
          1
        ),
        _c("div", { staticClass: "card-tit" }, [
          _vm._v(_vm._s(_vm.type ? "采购设置" : "自制设置") + " ")
        ]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _c(
              "el-row",
              { staticClass: "view-list", attrs: { gutter: 24 } },
              [
                _c(
                  "el-form",
                  {
                    ref: "detail",
                    staticClass: "demo-ruleForm",
                    attrs: {
                      model: _vm.detail,
                      "label-width": "160px",
                      inline: "",
                      rules: _vm.rules
                    }
                  },
                  _vm._l(_vm.MRPSettingForm, function(item) {
                    return _c(
                      "el-col",
                      {
                        key: item.key,
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          color: "#9597AE"
                        },
                        attrs: { span: 10 }
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: item.name, prop: "name" } },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: item.desc,
                                  placement: "top"
                                }
                              },
                              [
                                item.desc
                                  ? _c("i", { staticClass: "el-icon-question" })
                                  : _vm._e()
                              ]
                            ),
                            _vm._v("： "),
                            _c("el-input-number", {
                              attrs: { min: 0, controls: false, precision: 0 },
                              model: {
                                value: _vm.detail[item.key],
                                callback: function($$v) {
                                  _vm.$set(_vm.detail, item.key, $$v)
                                },
                                expression: "detail[item.key]"
                              }
                            }),
                            _vm._v(" " + _vm._s(item.suffix) + " ")
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }