import { productionTaskColumn } from './columns'
import { transformColumnsToForm } from '@/utils'
const newColumns = _.cloneDeep(productionTaskColumn)

export const planNodeFormList = transformColumnsToForm(newColumns)
planNodeFormList.push({
    hidden: true,
    label: '完成时间',
    key: 'finishTimeRelationPlanId',
    tagName: 'div',
    required: false,
    children: [{
      customSlots: ['finishTime']
    }]
  },
  {
    label: '是否任务节点',
    key: 'isTaskNode',
    tagName: 'el-select',
    required: true,
    props: {
      disabled: true
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: [{id:1,name:'是'},{id:0,name:'否'}]
      }
    ]
  })