var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "apps" } },
    [
      _c(
        "div",
        {
          ref: "myImg",
          class: !_vm.fullscreen
            ? "app-left-full"
            : _vm.showTip
            ? "app-left"
            : "app-width",
          attrs: { id: "containers-left" }
        },
        [
          _c(
            "header",
            { staticClass: "top-bar" },
            [
              _c(
                "el-form",
                {
                  staticClass: "gatter-form",
                  attrs: { inline: true, size: "small" }
                },
                [
                  _c("div", { staticClass: "form-li" }, [
                    _c(
                      "div",
                      { staticClass: "common-date" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "250px" },
                                attrs: { size: "small" },
                                on: { change: _vm.selectScale },
                                model: {
                                  value: _vm.scale,
                                  callback: function($$v) {
                                    _vm.scale = $$v
                                  },
                                  expression: "scale"
                                }
                              },
                              _vm._l(_vm.scaleList, function(item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "today", on: { click: _vm.nowMonth } },
                          [_vm._v("今天")]
                        ),
                        _c("img", {
                          attrs: {
                            src: require("@/assets/plans/left-arro.png")
                          },
                          on: {
                            click: function($event) {
                              return _vm.addMonth(2)
                            }
                          }
                        }),
                        _c("div", { staticClass: "date-tit" }, [
                          _vm._v(_vm._s(_vm.fixTime(_vm.commonDate)))
                        ]),
                        _c("img", {
                          attrs: {
                            src: require("@/assets/plans/right-arro.png")
                          },
                          on: {
                            click: function($event) {
                              return _vm.addMonth(1)
                            }
                          }
                        }),
                        _c(
                          "el-form-item",
                          { attrs: { label: "" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  size: "small",
                                  placeholder: "请选择计划类型",
                                  filterable: "",
                                  clearable: "",
                                  multiple: ""
                                },
                                on: { change: _vm.choosePlanType },
                                model: {
                                  value: _vm.planTypeId,
                                  callback: function($$v) {
                                    _vm.planTypeId = $$v
                                  },
                                  expression: "planTypeId"
                                }
                              },
                              _vm._l(_vm.planTypeList, function(item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  size: "small",
                                  placeholder: "请选择计划",
                                  filterable: "",
                                  clearable: "",
                                  multiple: ""
                                },
                                on: { change: _vm.choosePlanName },
                                model: {
                                  value: _vm.planId,
                                  callback: function($$v) {
                                    _vm.planId = $$v
                                  },
                                  expression: "planId"
                                }
                              },
                              _vm._l(_vm.planList, function(item) {
                                return _c("el-option", {
                                  key: item.planName,
                                  attrs: {
                                    label: item.planName,
                                    value: item.planName
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            { ref: "container", staticClass: "containers" },
            [
              _c("v-gantt-chart", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading"
                  }
                ],
                attrs: {
                  startTime: _vm.times[0],
                  endTime: _vm.times[1],
                  cellWidth: _vm.cellWidth,
                  cellHeight: _vm.cellHeight,
                  timeLines: _vm.timeLines,
                  titleHeight: _vm.titleHeight,
                  scale: _vm.scale,
                  titleWidth: _vm.titleWidth,
                  showCurrentTime: "",
                  hideHeader: _vm.hideHeader,
                  dataKey: _vm.dataKey,
                  arrayKeys: _vm.arrayKeys,
                  scrollToPostion: _vm.positionA,
                  datas: _vm.datasA,
                  scrollToTime: _vm.scrollToTime
                },
                on: { scrollLeft: _vm.scrollLeftA },
                scopedSlots: _vm._u([
                  {
                    key: "block",
                    fn: function(ref) {
                      var data = ref.data
                      var item = ref.item
                      var getPositonOffset = ref.getPositonOffset
                      var getWidthAbout2Times = ref.getWidthAbout2Times
                      return [
                        _c("Test", {
                          attrs: {
                            data: data,
                            updateTimeLines: _vm.updateTimeLines,
                            cellHeight: _vm.cellHeight,
                            currentTime: _vm.currentTime,
                            item: item,
                            getPositonOffset: getPositonOffset,
                            getWidthAbout2Times: getWidthAbout2Times
                          },
                          on: {
                            modifys: _vm.modify,
                            viewWorkReport: _vm.viewWorkReport
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "title",
                    fn: function() {
                      return [
                        _c("div", { staticClass: "common-header" }, [
                          _c(
                            "div",
                            { staticClass: "header-le" },
                            [
                              _c(
                                "el-popover",
                                {
                                  ref: "popover",
                                  staticStyle: { padding: "12p 12px 0" },
                                  attrs: { width: 291, offset: 20 },
                                  on: {
                                    hide: function($event) {
                                      _vm.show = true
                                    }
                                  },
                                  model: {
                                    value: _vm.showPopover,
                                    callback: function($$v) {
                                      _vm.showPopover = $$v
                                    },
                                    expression: "showPopover"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "popover-content-top" },
                                    [
                                      _c(
                                        "el-button",
                                        { on: { click: _vm.cancel } },
                                        [_vm._v("取消")]
                                      ),
                                      _c(
                                        "el-button",
                                        { on: { click: _vm.saveColumns } },
                                        [_vm._v("确定")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "popover-content-center" },
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          on: { change: _vm.selectAllColumns },
                                          model: {
                                            value: _vm.allChecked,
                                            callback: function($$v) {
                                              _vm.allChecked = $$v
                                            },
                                            expression: "allChecked"
                                          }
                                        },
                                        [_vm._v("全选")]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "invert",
                                          attrs: { type: "text" },
                                          on: { click: _vm.invertSelectColumns }
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("@/assets/table_filter_images/fanxuan@2x.png"),
                                              alt: "反选",
                                              width: "13px"
                                            }
                                          }),
                                          _c("span", [_vm._v("反选")])
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "popover-content-footer",
                                      style: {
                                        maxHeight:
                                          _vm.popoverContentMaxHeight + "px"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-checkbox-group",
                                        {
                                          staticClass: "check-wrapper",
                                          model: {
                                            value: _vm.checkedList,
                                            callback: function($$v) {
                                              _vm.checkedList = $$v
                                            },
                                            expression: "checkedList"
                                          }
                                        },
                                        _vm._l(_vm.settingColumns, function(
                                          item,
                                          index
                                        ) {
                                          return _c(
                                            "el-checkbox",
                                            {
                                              key: index,
                                              attrs: { label: item.id }
                                            },
                                            [_vm._v(_vm._s(item.projectName))]
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "el-button",
                                {
                                  ref: "columnsSettingBtn",
                                  staticStyle: { padding: "0" },
                                  attrs: { type: "text" }
                                },
                                [
                                  _c("div", { staticClass: "showMore" }, [
                                    _c("span", [_vm._v("项目")])
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "header-ri",
                              on: { click: _vm.toggleFullScreen }
                            },
                            [
                              _vm.fullscreen
                                ? _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "放大",
                                        placement: "top"
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "ri-img",
                                        attrs: {
                                          src: require("@/assets/narrow-amplification/amplification.png"),
                                          alt: ""
                                        }
                                      })
                                    ]
                                  )
                                : _vm._e(),
                              !_vm.fullscreen
                                ? _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "缩小",
                                        placement: "top"
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "ri-img",
                                        attrs: {
                                          src: require("@/assets/narrow-amplification/narrow.png")
                                        }
                                      })
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ])
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "left",
                    fn: function(ref) {
                      var data = ref.data
                      return [
                        _c("TestLeft", {
                          attrs: { data: data },
                          on: {
                            selectLine: _vm.selectLine,
                            viewAbnormalList: _vm.viewAbnormalList,
                            viewECNList: _vm.viewECNList,
                            viewMaterialsDiffList: _vm.viewMaterialsDiffList
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c(
                "div",
                { staticClass: "tip-img", on: { click: _vm.selectImg } },
                [
                  _vm.showTip
                    ? _c("img", {
                        attrs: { src: require("@/assets/plans/spread.png") }
                      })
                    : _c("img", {
                        attrs: {
                          src: require("@/assets/plans/retract.png"),
                          alt: ""
                        }
                      })
                ]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "app-right" },
        [
          _vm.showTip
            ? _c("schedule-right", {
                ref: "schedules",
                attrs: {
                  "area-id": _vm.areaId,
                  "area-name": _vm.areaName,
                  planTypeIds: _vm.planTypeIds,
                  planNames: _vm.planNames
                },
                on: { modify: _vm.modify }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: {
            title: "修改计划节点",
            width: "80%",
            "show-footer": _vm.permission("Modify")
          },
          on: { onOk: _vm.dialogSubmit },
          model: {
            value: _vm.dialogVisible,
            callback: function($$v) {
              _vm.dialogVisible = $$v
            },
            expression: "dialogVisible"
          }
        },
        [
          _c(
            "div",
            { staticClass: "edit-form" },
            [
              _c(
                "div",
                { staticClass: "form-top" },
                [
                  _c("base-data", {
                    attrs: { "detail-data": _vm.dialogFormData }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { margin: "10px 0" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "m-radio-group right-radio",
                      on: { change: _vm.radioModifyChange },
                      model: {
                        value: _vm.modifyType,
                        callback: function($$v) {
                          _vm.modifyType = $$v
                        },
                        expression: "modifyType"
                      }
                    },
                    [
                      _vm.dialogFormData.nodeAttribute === 1 ||
                      _vm.dialogFormData.startTimeAttribute === 1 ||
                      _vm.dialogFormData.finishTimeAttribute === 1
                        ? _c(
                            "el-radio-button",
                            { attrs: { label: "adjustTime" } },
                            [_vm._v("调整")]
                          )
                        : _vm._e(),
                      _c(
                        "el-radio-button",
                        { attrs: { label: "modifyTime" } },
                        [_vm._v("修改")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "MFormBuilder",
                {
                  ref: "dialogFormBuild",
                  attrs: {
                    "form-data": _vm.dialogFormData,
                    "form-list":
                      _vm.modifyType === "adjustTime"
                        ? _vm.adjustFormList
                        : _vm.haveDialogFormList,
                    "custom-rules": _vm.rules
                  }
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "startTime" }, slot: "startTime" },
                    [
                      _c("el-date-picker", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showStartTime,
                            expression: "showStartTime"
                          }
                        ],
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "datetime",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          format: "yyyy-MM-dd HH:mm:ss",
                          placeholder: "开始时间",
                          disabled: _vm.isChooseStartAttr
                        },
                        model: {
                          value: _vm.params.planStartTime,
                          callback: function($$v) {
                            _vm.$set(_vm.params, "planStartTime", $$v)
                          },
                          expression: "params.planStartTime"
                        }
                      }),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.showStartTime,
                              expression: "!showStartTime"
                            }
                          ],
                          staticStyle: { display: "flex" }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "请选择",
                                clearable: "",
                                filterable: ""
                              },
                              on: { change: _vm.changeStartTimePlan },
                              model: {
                                value: _vm.params.startPlanId,
                                callback: function($$v) {
                                  _vm.$set(_vm.params, "startPlanId", $$v)
                                },
                                expression: "params.startPlanId"
                              }
                            },
                            _vm._l(_vm.planNodeTimeList, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.fullName, value: item.id }
                              })
                            }),
                            1
                          ),
                          _c("el-input-number", {
                            staticClass: "min-input",
                            staticStyle: { "margin-left": "10px" },
                            attrs: { "controls-position": "right" },
                            model: {
                              value: _vm.params.startTimeBasis,
                              callback: function($$v) {
                                _vm.$set(_vm.params, "startTimeBasis", $$v)
                              },
                              expression: "params.startTimeBasis"
                            }
                          }),
                          _vm._v("天 ")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { attrs: { slot: "finishTime" }, slot: "finishTime" },
                    [
                      _c("el-date-picker", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showFinishTime,
                            expression: "showFinishTime"
                          }
                        ],
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "datetime",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          format: "yyyy-MM-dd HH:mm:ss",
                          defaultTime: "23:59:59",
                          placeholder: "完成时间",
                          disabled: _vm.isChooseFinishAttr
                        },
                        model: {
                          value: _vm.params.planEndTime,
                          callback: function($$v) {
                            _vm.$set(_vm.params, "planEndTime", $$v)
                          },
                          expression: "params.planEndTime"
                        }
                      }),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.showFinishTime,
                              expression: "!showFinishTime"
                            }
                          ],
                          staticStyle: { display: "flex" }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "请选择",
                                clearable: "",
                                filterable: ""
                              },
                              on: { change: _vm.changeFinishTimePlan },
                              model: {
                                value: _vm.params.finishPlanId,
                                callback: function($$v) {
                                  _vm.$set(_vm.params, "finishPlanId", $$v)
                                },
                                expression: "params.finishPlanId"
                              }
                            },
                            _vm._l(_vm.planNodeTimeList, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.fullName, value: item.id }
                              })
                            }),
                            1
                          ),
                          _c("el-input-number", {
                            staticClass: "min-input",
                            staticStyle: { "margin-left": "10px" },
                            attrs: { "controls-position": "right" },
                            model: {
                              value: _vm.params.finishTimeBasis,
                              callback: function($$v) {
                                _vm.$set(_vm.params, "finishTimeBasis", $$v)
                              },
                              expression: "params.finishTimeBasis"
                            }
                          }),
                          _vm._v("天 ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "MDialog",
        {
          attrs: {
            title: "报工进度详情",
            "show-footer": false,
            width: "870px"
          },
          on: {
            onOk: function($event) {
              _vm.processVisible = false
            }
          },
          model: {
            value: _vm.visibleProgress,
            callback: function($$v) {
              _vm.visibleProgress = $$v
            },
            expression: "visibleProgress"
          }
        },
        [
          _c(
            "div",
            { staticClass: "detail-box" },
            _vm._l(_vm.progressDetail, function(item, idx) {
              return _c(
                "div",
                { key: idx, staticClass: "item-box" },
                _vm._l(item, function(i) {
                  return _c(
                    "div",
                    {
                      key: i.procedureLocationCode,
                      staticClass: "item",
                      class: ["item-wait", "item-running", "item-finished"][
                        i.productionStatus
                      ]
                    },
                    [
                      i.productionStatus === 0
                        ? _c("img", {
                            staticClass: "sign",
                            attrs: {
                              src: require("@/assets/plans/progress-0.png")
                            }
                          })
                        : _vm._e(),
                      i.productionStatus === 1
                        ? _c("img", {
                            staticClass: "sign",
                            attrs: {
                              src: require("@/assets/plans/progress-1.png")
                            }
                          })
                        : _vm._e(),
                      i.productionStatus === 2
                        ? _c("img", {
                            staticClass: "sign",
                            attrs: {
                              src: require("@/assets/plans/progress-2.png")
                            }
                          })
                        : _vm._e(),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: i.procedureName,
                            placement: "top"
                          }
                        },
                        [_c("div", [_vm._v(_vm._s(i.procedureName))])]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "progress-bg",
                          staticStyle: { bottom: "5px !important" }
                        },
                        [
                          _c("div", {
                            staticClass: "progress-bar",
                            style: { width: i.productionTempo + "%" }
                          })
                        ]
                      ),
                      _c("div", { staticClass: "progress-bg" }, [
                        _c("div", {
                          staticClass: "apply-bar",
                          style: { width: i.materialsRequisitionTempo + "%" }
                        })
                      ])
                    ],
                    1
                  )
                }),
                0
              )
            }),
            0
          )
        ]
      ),
      _c(
        "MDialog",
        {
          attrs: {
            title: "修改计划节点",
            width: "80%",
            "show-footer": _vm.permission("Modify")
          },
          on: { onOk: _vm.dialogBatchSubmit },
          model: {
            value: _vm.projectNodesVisible,
            callback: function($$v) {
              _vm.projectNodesVisible = $$v
            },
            expression: "projectNodesVisible"
          }
        },
        [
          _c("div", { staticClass: "edit-form" }, [
            _c(
              "div",
              { staticClass: "form-top" },
              [
                _c("all-base-data", {
                  attrs: { "detail-data": _vm.projectNodesFormData }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { margin: "10px 0" } },
              [
                _c(
                  "el-radio-group",
                  {
                    staticClass: "m-radio-group right-radio",
                    on: { change: _vm.radioProjectChange },
                    model: {
                      value: _vm.projectType,
                      callback: function($$v) {
                        _vm.projectType = $$v
                      },
                      expression: "projectType"
                    }
                  },
                  [
                    _c("el-radio-button", { attrs: { label: "adjustTime" } }, [
                      _vm._v("调整")
                    ]),
                    _c("el-radio-button", { attrs: { label: "modifyTime" } }, [
                      _vm._v("修改")
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "list" },
              [
                _c("MTable", {
                  ref: "mTable",
                  attrs: {
                    columns: _vm.modifyColumns,
                    height: "400",
                    data: _vm.tableData,
                    "show-page": false
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "index",
                      fn: function(ref) {
                        var $index = ref.$index
                        return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                      }
                    },
                    {
                      key: "startTimeAttribute",
                      fn: function(ref) {
                        var row = ref.row
                        return _c(
                          "div",
                          {},
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "", clearable: "" },
                                on: {
                                  change: function(val) {
                                    return _vm.startTimeProjectAttrChange(
                                      row,
                                      val
                                    )
                                  }
                                },
                                model: {
                                  value: row.startTimeAttribute,
                                  callback: function($$v) {
                                    _vm.$set(row, "startTimeAttribute", $$v)
                                  },
                                  expression: "row.startTimeAttribute"
                                }
                              },
                              _vm._l(_vm.startFinishTimeAttribute, function(
                                item
                              ) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      }
                    },
                    {
                      key: "finishTimeAttribute",
                      fn: function(ref) {
                        var row = ref.row
                        return _c(
                          "div",
                          {},
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "", clearable: "" },
                                on: {
                                  change: function(val) {
                                    return _vm.finishTimeProjectAttrChange(
                                      row,
                                      val
                                    )
                                  }
                                },
                                model: {
                                  value: row.finishTimeAttribute,
                                  callback: function($$v) {
                                    _vm.$set(row, "finishTimeAttribute", $$v)
                                  },
                                  expression: "row.finishTimeAttribute"
                                }
                              },
                              _vm._l(_vm.startFinishTimeAttribute, function(
                                item
                              ) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      }
                    },
                    {
                      key: "startTime",
                      fn: function(ref) {
                        var row = ref.row
                        return _c(
                          "div",
                          {},
                          [
                            _c("el-date-picker", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: row.showStartTime,
                                  expression: "row.showStartTime"
                                }
                              ],
                              staticStyle: { width: "100%" },
                              attrs: {
                                type: "datetime",
                                placeholder: "开始时间",
                                "value-format": "yyyy-MM-dd HH:mm:ss",
                                format: "yyyy-MM-dd HH:mm:ss",
                                "default-time": "00:00:00",
                                disabled:
                                  row.startTimeAttribute != 1 &&
                                  _vm.projectType === "adjustTime",
                                clearable: ""
                              },
                              model: {
                                value: row.startTime,
                                callback: function($$v) {
                                  _vm.$set(row, "startTime", $$v)
                                },
                                expression: "row.startTime"
                              }
                            }),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !row.showStartTime,
                                    expression: "!row.showStartTime"
                                  }
                                ],
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center"
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "请选择",
                                      clearable: "",
                                      filterable: ""
                                    },
                                    on: {
                                      change: function(val) {
                                        return _vm.changeProjectStartTimePlan(
                                          row,
                                          val
                                        )
                                      }
                                    },
                                    model: {
                                      value: row.startPlanId,
                                      callback: function($$v) {
                                        _vm.$set(row, "startPlanId", $$v)
                                      },
                                      expression: "row.startPlanId"
                                    }
                                  },
                                  _vm._l(row.planNodeTimeList, function(item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.fullName,
                                        value: item.id
                                      }
                                    })
                                  }),
                                  1
                                ),
                                _c("el-input-number", {
                                  staticClass: "min-input",
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { "controls-position": "right" },
                                  model: {
                                    value: row.startTimeBasis,
                                    callback: function($$v) {
                                      _vm.$set(row, "startTimeBasis", $$v)
                                    },
                                    expression: "row.startTimeBasis"
                                  }
                                }),
                                _vm._v("天 ")
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }
                    },
                    {
                      key: "finishTime",
                      fn: function(ref) {
                        var row = ref.row
                        return _c(
                          "div",
                          {},
                          [
                            _c("el-date-picker", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: row.showFinishTime,
                                  expression: "row.showFinishTime"
                                }
                              ],
                              staticStyle: { width: "100%" },
                              attrs: {
                                type: "datetime",
                                placeholder: "完成时间",
                                "value-format": "yyyy-MM-dd HH:mm:ss",
                                format: "yyyy-MM-dd HH:mm:ss",
                                "default-time": "00:00:00",
                                disabled:
                                  row.isChooseFinishAttr ||
                                  (row.finishTimeAttribute != 1 &&
                                    _vm.projectType === "adjustTime"),
                                clearable: ""
                              },
                              model: {
                                value: row.finishTime,
                                callback: function($$v) {
                                  _vm.$set(row, "finishTime", $$v)
                                },
                                expression: "row.finishTime"
                              }
                            }),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !row.showFinishTime,
                                    expression: "!row.showFinishTime"
                                  }
                                ],
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center"
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "请选择",
                                      clearable: "",
                                      filterable: ""
                                    },
                                    on: {
                                      change: function(val) {
                                        return _vm.changeProjectFinishTimePlan(
                                          row,
                                          val
                                        )
                                      }
                                    },
                                    model: {
                                      value: row.finishPlanId,
                                      callback: function($$v) {
                                        _vm.$set(row, "finishPlanId", $$v)
                                      },
                                      expression: "row.finishPlanId"
                                    }
                                  },
                                  _vm._l(row.planNodeTimeList, function(item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.fullName,
                                        value: item.id
                                      }
                                    })
                                  }),
                                  1
                                ),
                                _c("el-input-number", {
                                  staticClass: "min-input",
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { "controls-position": "right" },
                                  model: {
                                    value: row.finishTimeBasis,
                                    callback: function($$v) {
                                      _vm.$set(row, "finishTimeBasis", $$v)
                                    },
                                    expression: "row.finishTimeBasis"
                                  }
                                }),
                                _vm._v("天 ")
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }
                    },
                    {
                      key: "startAdjustTime",
                      fn: function(ref) {
                        var row = ref.row
                        return _c(
                          "div",
                          {},
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                type: "datetime",
                                placeholder: "开始时间",
                                "value-format": "yyyy-MM-dd HH:mm:ss",
                                format: "yyyy-MM-dd HH:mm:ss",
                                "default-time": "00:00:00",
                                disabled:
                                  row.startTimeAttribute != 1 &&
                                  _vm.projectType === "adjustTime",
                                clearable: ""
                              },
                              model: {
                                value: row.startTime,
                                callback: function($$v) {
                                  _vm.$set(row, "startTime", $$v)
                                },
                                expression: "row.startTime"
                              }
                            })
                          ],
                          1
                        )
                      }
                    },
                    {
                      key: "finishAdjustTime",
                      fn: function(ref) {
                        var row = ref.row
                        return _c(
                          "div",
                          {},
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                type: "datetime",
                                placeholder: "完成时间",
                                "value-format": "yyyy-MM-dd HH:mm:ss",
                                format: "yyyy-MM-dd HH:mm:ss",
                                "default-time": "00:00:00",
                                disabled:
                                  row.finishTimeAttribute != 1 &&
                                  _vm.projectType === "adjustTime",
                                clearable: ""
                              },
                              model: {
                                value: row.finishTime,
                                callback: function($$v) {
                                  _vm.$set(row, "finishTime", $$v)
                                },
                                expression: "row.finishTime"
                              }
                            })
                          ],
                          1
                        )
                      }
                    },
                    {
                      key: "adjustReason",
                      fn: function(ref) {
                        var row = ref.row
                        return _c(
                          "div",
                          {},
                          [
                            _c("el-input", {
                              attrs: { placeholder: "" },
                              model: {
                                value: row.adjustReason,
                                callback: function($$v) {
                                  _vm.$set(row, "adjustReason", $$v)
                                },
                                expression: "row.adjustReason"
                              }
                            })
                          ],
                          1
                        )
                      }
                    }
                  ])
                })
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "异常处理", "show-footer": false },
          on: { fullScreen: _vm.fullScreenChange },
          model: {
            value: _vm.viewAbnormalVisible,
            callback: function($$v) {
              _vm.viewAbnormalVisible = $$v
            },
            expression: "viewAbnormalVisible"
          }
        },
        [
          _c("MTable", {
            ref: "abnormalTable",
            attrs: {
              columns: _vm.projectAbnormalColumns,
              height: _vm.dialogTableHeight,
              "set-data-method": _vm.getAbnormalTableData,
              "columns-setting": false
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "majorType",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(_vm._s(_vm._f("majorTypeOptions")(row.majorType)))
                  ])
                }
              },
              {
                key: "status",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(_vm._s(_vm.handleStatusMap[row.status]))
                  ])
                }
              },
              {
                key: "judgeResult",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(_vm._s(_vm.judgeResultMap[row.judgeResult]))
                  ])
                }
              },
              {
                key: "finalResult",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(_vm._s(_vm.finalResultMap[row.finalResult]))
                  ])
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "变更列表", "show-footer": false },
          on: { fullScreen: _vm.fullScreenChange },
          model: {
            value: _vm.viewECNVisible,
            callback: function($$v) {
              _vm.viewECNVisible = $$v
            },
            expression: "viewECNVisible"
          }
        },
        [
          _c("MTable", {
            ref: "ecnTable",
            attrs: {
              columns: _vm.projectECNColumns,
              height: _vm.dialogTableHeight,
              "set-data-method": _vm.getECNTableData,
              "columns-setting": false
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "needOperate",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _c("span", [
                      _vm._v(_vm._s(row.needOperate ? "不需要" : "需要"))
                    ])
                  ])
                }
              },
              {
                key: "changeStatus",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.changeStatus[row.status].name))
                    ])
                  ])
                }
              },
              {
                key: "changeType",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.changeType[row.changeType].name))
                    ])
                  ])
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "物料差异表", "show-footer": false },
          on: { fullScreen: _vm.fullScreenChange },
          model: {
            value: _vm.viewMaterialsDiffVisible,
            callback: function($$v) {
              _vm.viewMaterialsDiffVisible = $$v
            },
            expression: "viewMaterialsDiffVisible"
          }
        },
        [
          _c("MTable", {
            ref: "materialsDiffTable",
            attrs: {
              columns: _vm.projectMaterialsDiffColumns,
              height: _vm.dialogTableHeight,
              "set-data-method": _vm.getMaterialsDiffTableData,
              "columns-setting": false
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }