var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "inspection-record" }, [
    _c("div", { staticClass: "table-wrap" }, [
      _c(
        "table",
        { staticClass: "left-table" },
        [
          _c(
            "tr",
            [
              _c("td", { staticClass: "sticky", attrs: { colspan: "3" } }, [
                _vm._v("检验项目组")
              ]),
              _vm._l(_vm.inspectionList, function(item, index) {
                return _c(
                  "td",
                  {
                    key: index,
                    attrs: { colspan: item.inspectionItems.length }
                  },
                  [_vm._v(_vm._s(item.inspectionProjectName))]
                )
              })
            ],
            2
          ),
          _vm._l(_vm.keys, function(item, i) {
            return _c(
              "tr",
              { key: item },
              [
                _c("td", { staticClass: "sticky", attrs: { colspan: "3" } }, [
                  _vm._v(_vm._s(_vm.keysName[i]))
                ]),
                _vm._l(_vm.inspectionList, function(inspection, insIndex) {
                  return _vm._l(inspection.inspectionItems, function(
                    child,
                    childIndex
                  ) {
                    return _c("td", { key: childIndex + "_" + insIndex }, [
                      _c(
                        "div",
                        {
                          class: {
                            "common-item": item === "inspectionEntryName"
                          }
                        },
                        [_vm._v(_vm._s(child[item] || ""))]
                      )
                    ])
                  })
                })
              ],
              2
            )
          }),
          _vm.baseFormData.status !== 0
            ? [
                _vm._l(_vm.inspectionRecordList, function(item, index) {
                  return _vm._l(_vm.keys2, function(key, keyIndex) {
                    return _c(
                      "tr",
                      { key: index + "_1" + keyIndex },
                      [
                        index === 0 && keyIndex === 0
                          ? _c(
                              "td",
                              {
                                staticClass: "sticky",
                                attrs: {
                                  rowspan:
                                    _vm.inspectionRecordList.length *
                                    _vm.keys2.length
                                }
                              },
                              [_vm._v("检验数据")]
                            )
                          : _vm._e(),
                        keyIndex === 0
                          ? _c(
                              "td",
                              {
                                staticClass: "sticky sticky-2",
                                attrs: { rowspan: _vm.keys2.length }
                              },
                              [_vm._v("第" + _vm._s(item.index + 1) + "件")]
                            )
                          : _vm._e(),
                        _c("td", { staticClass: "sticky sticky-3" }, [
                          _vm._v(_vm._s(_vm.keys2Name[keyIndex]))
                        ]),
                        _vm._l(item.inspectionItems, function(
                          child,
                          childIndex
                        ) {
                          return _c(
                            "td",
                            { key: index + "_" + keyIndex + "_" + childIndex },
                            [
                              key === "result"
                                ? _c("div", [_vm._v(_vm._s(child[key]))])
                                : _vm._e(),
                              key === "resultCheck"
                                ? _c("div", [
                                    child[key] === 0
                                      ? _c(
                                          "span",
                                          { staticStyle: { color: "#D0021B" } },
                                          [_vm._v("次")]
                                        )
                                      : _vm._e(),
                                    child[key] === 1
                                      ? _c(
                                          "span",
                                          { staticStyle: { color: "#00AB29" } },
                                          [_vm._v("良")]
                                        )
                                      : _vm._e()
                                  ])
                                : _vm._e(),
                              key === "picture"
                                ? _c(
                                    "div",
                                    [
                                      child.picture && child.picture !== "[]"
                                        ? _c("el-image", {
                                            staticStyle: { height: "30px" },
                                            attrs: {
                                              src: _vm.fixImg(child.picture)[0],
                                              "preview-src-list": _vm.fixImg(
                                                child.picture
                                              )
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              key === "defectItemList"
                                ? _c(
                                    "div",
                                    _vm._l(child[key], function(defect) {
                                      return _c(
                                        "el-tag",
                                        {
                                          key: defect.id,
                                          staticStyle: {
                                            color: "#D0021B",
                                            background:
                                              "rgba(255, 156, 168, 0.2)",
                                            border:
                                              "1px solid rgba(255, 156, 168, 0.2)"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(defect.name) + " "
                                          )
                                        ]
                                      )
                                    }),
                                    1
                                  )
                                : _vm._e(),
                              key === "defectReasonList"
                                ? _c(
                                    "div",
                                    _vm._l(child[key], function(reason) {
                                      return _c("div", { key: reason.id }, [
                                        _vm._v(_vm._s(reason.name))
                                      ])
                                    }),
                                    0
                                  )
                                : _vm._e()
                            ]
                          )
                        })
                      ],
                      2
                    )
                  })
                })
              ]
            : _vm._e()
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }