var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "inspection-plan-detail-wrapper" }, [
    _c("div", { staticClass: "inspection-plan-detail-header flex-sbc" }, [
      _c(
        "div",
        {
          staticClass: "left",
          on: {
            click: function($event) {
              return _vm.$router.back()
            }
          }
        },
        [
          _c("img", {
            staticStyle: { width: "8px" },
            attrs: {
              src: require("@/assets/information/procedure/左滑@2x.png")
            }
          }),
          _c(
            "span",
            { staticStyle: { "padding-left": "10px", cursor: "pointer" } },
            [_vm._v("自动排程")]
          )
        ]
      )
    ]),
    _c("div", { staticClass: "inspection-plan-detail-body" }, [
      _c(
        "div",
        { staticClass: "detail-card" },
        [
          _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
          _c("div", { staticStyle: { height: "10px" } }),
          _c(
            "el-steps",
            {
              attrs: {
                active: _vm.currentStatus,
                "align-center": "",
                "finish-status": "success"
              }
            },
            [
              _c("el-step", {
                attrs: {
                  title: "规则设置",
                  description: "设置自动排程的计算规则。"
                }
              }),
              _c("el-step", { attrs: { title: "排程运算", description: "" } }),
              _c("el-step", {
                attrs: {
                  title: "结果预览",
                  description: "预览排程结果，支持手动调节。"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "card-bot" },
            [
              _vm.currentStatus === 0
                ? _c(
                    "div",
                    [
                      _c("br"),
                      _vm._v("注意：本排程是不考虑生产资源的无限产能排程。"),
                      _c("br"),
                      _c("br"),
                      _vm._v(
                        " 规则：自动将净需求量>0的生产需求按工序分包成生产任务，并依据工序标准工时自动计算开工完工时间"
                      ),
                      _c("br"),
                      _c("br"),
                      _vm._v(" 开始时间： "),
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          format: "yyyy-MM-dd HH:mm:ss",
                          placeholder: "选择计划开始时间"
                        },
                        model: {
                          value: _vm.planStartTime,
                          callback: function($$v) {
                            _vm.planStartTime = $$v
                          },
                          expression: "planStartTime"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.currentStatus === 1 && _vm.isCalculate
                ? _c(
                    "div",
                    { staticStyle: { "text-align": "center" } },
                    [
                      _vm._v(" 计算中 "),
                      _c("el-progress", {
                        attrs: { percentage: _vm.percentage }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.currentStatus === 2
                ? _c("MTable", {
                    attrs: {
                      height: 500,
                      columns: _vm.scheduleColumn,
                      data: _vm.tableData,
                      "show-page": false
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "index",
                          fn: function(ref) {
                            var $index = ref.$index
                            return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                          }
                        },
                        {
                          key: "materialsCode",
                          fn: function(ref) {
                            var row = ref.row
                            return _c("NewPageOpen", {
                              attrs: {
                                path: {
                                  name: "materialsDetail",
                                  query: { id: row.materialsId }
                                },
                                text: row.materialsCode
                              }
                            })
                          }
                        },
                        {
                          key: "planOutputQuantity",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c("el-input-number", {
                                style: { width: "90px" },
                                attrs: {
                                  controls: false,
                                  min: 1,
                                  step: 1,
                                  "step-strictly": true
                                },
                                model: {
                                  value: row.planOutputQuantity,
                                  callback: function($$v) {
                                    _vm.$set(row, "planOutputQuantity", $$v)
                                  },
                                  expression: "row.planOutputQuantity"
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "planTime",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c("el-date-picker", {
                                staticStyle: { width: "345px" },
                                attrs: {
                                  type: "datetimerange",
                                  clearable: false,
                                  "range-separator": "至",
                                  "start-placeholder": "计划开工时间",
                                  "end-placeholder": "完工时间",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  format: "yyyy-MM-dd HH:mm:ss"
                                },
                                model: {
                                  value: row.planTime,
                                  callback: function($$v) {
                                    _vm.$set(row, "planTime", $$v)
                                  },
                                  expression: "row.planTime"
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "remark",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c("el-input", {
                                attrs: { type: "textarea" },
                                model: {
                                  value: row.remark,
                                  callback: function($$v) {
                                    _vm.$set(row, "remark", $$v)
                                  },
                                  expression: "row.remark"
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      867201316
                    )
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ]),
    _c("div", { staticClass: "order-footer" }, [
      _c("div"),
      _c(
        "div",
        { staticClass: "footer-ri" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.$router.back()
                }
              }
            },
            [_vm._v("取 消 ")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onSave } },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }