<template>
  <div class="tracing">
    <FunctionHeader
      v-model="functionHeader.searchVal"
      :search-title="searchTitle"
      @search="$refs.mTable.setTableData(true)"
    />
    <MTable
      ref="mTable"
      :height="height"
      :columns="columns"
      :set-data-method="getTableData"
    >
      <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
      <div slot="produceWorkOrderNumber" slot-scope="{ row }">
        <NewPageOpen v-if="isShowChild" :path="{ name: 'workDetail', query: {id: row.produceWorkOrderId}}" :text="row.produceWorkOrderNumber" />
      </div>
      <div slot="batchNumberDetail" slot-scope="{ row }">
        <router-link
          :to="{
            name: 'productBatchCodeTracingDetail',
            query: { batchNumber: row.batchNumber },
          }"
        >
          <el-button type="text">
            {{ row.batchNumber }}
          </el-button>
        </router-link>
      </div>
    </MTable>
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import api from '@/api/production/operation-task'
import { columns } from './columns'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
import fixCommonPage from '@/mixin/fix-common-page'

export default {
  name: 'ProductBatchCodeTracingList',
  components: {
    FunctionHeader,
    NewPageOpen
  },
  mixins: [fixCommonPage],
  data() {
    return {
      columns,
      functionHeader: {
        searchVal: ''
      },
      searchTitle: '工单号/批次码/物料名称/编码/规格'
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 192
    }
  },
  methods: {
    async getTableData(condition, callback) {
      const { page, order } = condition
      const res = await api.getBatchNumberWorkOrder({
        page: page.currentPage,
        limit: page.pageSize,
        sort: order,
        searchKeyword: this.functionHeader.searchVal
      })
      if (res) {
        console.log(res)
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    jumpDetail(row) {
      this.$router.push({
        name: 'rmbctDetail',
        params: { id: row.batchNumber }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tracing {
  padding: 0 10px 10px 10px;
}
</style>
