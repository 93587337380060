import { columns } from './columns'
import { transformColumnsToForm } from '@/utils'
let functionList = {}
export const getPlanFunctionList = val => {
  functionList = val
}
export const formList = transformColumnsToForm(columns)
// formList.push(  
//   {
//   label: '项目计划节点方案',
//   key: 'projectPlanId',
//   tagName: 'el-select',
//   props: {
//     placeholder: '项目计划节点方案',
//     configFilter: true,
//     remote: true,
//     remoteMethod: (val) => {
//       console.log('远程搜', val)
//     }
//   },
//   directives: [
//     { name: 'selectScrollEnd',
//       value: () => {
//         console.log('滚动触底了，可以请求下一页了')
//       }
//     }
//   ],
//   children: [
//     {
//       tagName: 'el-option',
//       props: {
//         label: 'name', // 自定义label需要字段值与configLabel字段值一致
//         value: 'id'
//       },
//       options: []
//     }
//   ],
//   on: {
//       change: (val) => { functionList.onRecordTypeChange(val) }
//     }
// },
// {

//   label: '',
//   key: 'finishTime',
//   tagName: 'div',
//   required: false,
//   children: [{
//     customSlots: ['finishTime']
//   }]
// },
// {
//   colSpan:24,
//   label: '',
//   key: 'nodeTable',
//   tagName: 'div',
//   required: false,
//   children: [{
//     customSlots: ['nodeTable']
//   }]
// })



