var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "MDialog",
    {
      attrs: { width: "1000px", "append-to-body": true, title: "需求更新" },
      on: { onOk: _vm.submitForm, fullScreen: _vm.fullScreenChange },
      model: {
        value: _vm.modalShow,
        callback: function($$v) {
          _vm.modalShow = $$v
        },
        expression: "modalShow"
      }
    },
    [
      _c(
        "Split",
        {
          attrs: { offset: _vm.split.offset, height: _vm.splitHeight },
          on: {
            "update:offset": function($event) {
              return _vm.$set(_vm.split, "offset", $event)
            }
          }
        },
        [
          _c(
            "template",
            { slot: "left" },
            [
              _c("RequirementsClass", {
                attrs: { view: true },
                on: { nodeClick: _vm.setMaterialsClassId }
              })
            ],
            1
          ),
          _c("template", { slot: "right" }, [
            _c(
              "div",
              { staticClass: "materials-info" },
              [
                _c(
                  "FunctionHeader",
                  {
                    ref: "functionHeader",
                    attrs: {
                      "search-title": "单据编码",
                      tags:
                        _vm.materialsClassId === 0 || _vm.materialsClassId === 1
                          ? _vm.functionHeader.tags
                          : []
                    },
                    on: {
                      search: function($event) {
                        return _vm.$refs.mTable.setTableData(true)
                      }
                    },
                    model: {
                      value: _vm.functionHeader.searchVal,
                      callback: function($$v) {
                        _vm.$set(_vm.functionHeader, "searchVal", $$v)
                      },
                      expression: "functionHeader.searchVal"
                    }
                  },
                  [
                    _c(
                      "div",
                      { attrs: { slot: "all" }, slot: "all" },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              margin: "0 10px",
                              display: "inline-block",
                              "font-size": "12px",
                              color: "#393D60"
                            }
                          },
                          [
                            _vm._v(" 已选 "),
                            _c("span", { staticStyle: { color: "#607FFF" } }, [
                              _vm._v(_vm._s(_vm.getSelectionDataLength))
                            ]),
                            _vm._v(" / " + _vm._s(_vm.total) + "项 ")
                          ]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-top": "-4px" },
                            attrs: { type: "text", size: "small" },
                            on: { click: _vm.resetData }
                          },
                          [_vm._v("清空")]
                        )
                      ],
                      1
                    )
                  ]
                ),
                _c("MTable", {
                  ref: "mTable",
                  attrs: {
                    columns: _vm.columns,
                    height: _vm.height,
                    "set-data-method": _vm.getTableData,
                    onlyKey: "requirementCode"
                  },
                  on: { "selection-change": _vm.selectionChange },
                  scopedSlots: _vm._u([
                    {
                      key: "index",
                      fn: function(ref) {
                        var $index = ref.$index
                        return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                      }
                    },
                    {
                      key: "requirementType",
                      fn: function(ref) {
                        var row = ref.row
                        return _c("div", {}, [
                          _vm._v(
                            " " +
                              _vm._s(
                                row.requirementType
                                  ? row.requirementType === 1
                                    ? "备库需求"
                                    : "预测需求"
                                  : "销售订单"
                              ) +
                              " "
                          )
                        ])
                      }
                    },
                    {
                      key: "deliveryType",
                      fn: function(ref) {
                        var row = ref.row
                        return _c("div", {}, [
                          _vm._v(
                            " " +
                              _vm._s(
                                row.deliveryType ? "分批交付" : "统一交付"
                              ) +
                              " "
                          )
                        ])
                      }
                    }
                  ])
                })
              ],
              1
            )
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }