import { projectDeliveryType } from '@/config/options.config'
import { divisionOptions, executionDepartment } from '@/views/plans/prod-plan-lists/data'

let functionList = {}
export const getFunctionList = val => {
  functionList = val
}
// 项目列表
export const columns = [
    {
      prop: 'index',
      label: '序号',
      slotName: 'index',
      hiddenSearch: true,
      fixed: 'left',
      width: 60
    },
    {
      prop: 'projectNumber',
      label: '项目编号',
      sortable: false,
      hiddenSearch: true,
      slotName:'projectNumber',
      minWidth: 120,
      form: { index: 0, tagName: 'el-input', required: true,props: { disabled: false } }
      // form: {
      //   key: 'projectNumber',
      //   tagName: 'div',
      //   customSlots: ['projectNumber'],
      //   required: true }
    },
    {
      prop: 'projectName',
      label: '项目名称',
      sortable: false,
      hiddenSearch: true,
      form: { index: 1, tagName: 'el-input', required: true }
    },
    {
      prop: 'customerOrderNumber',
      label: '客户订单号',
      sortable: false,
      hiddenSearch: true,
      form: { index: 2, tagName: 'el-input', required: false }
    },
    {
      prop: 'customerName',
      label: '客户',
      sortable: false,
      hiddenSearch: true,
      directives: [
        {
          name: 'selectScrollEnd',
          value: () => {
            console.log('滚动触底了，可以请求下一页了')
          }
        }
      ],
      form: {
        index: 3,
        required: false,
        tagName: 'el-select',
        key: 'customerId',
        options: [],
        colSpan: 12,
        optionsProps: {
          label: 'name',
          value: 'id'
        },
        props: {
          remote: true,
          remoteMethod: (val) => {
            console.log('远程搜', val)
          }
        },
    }
      // form: { key: 'customerId', tagName: 'el-select', options: [], required: false }
    },
    {
      prop: 'customerPrincipalName',
      label: '客户负责人',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'projectStatus',
      label: '项目状态',
      hiddenSearch: true,
      sortable: false,
       minWidth: 100,
       fixed: 'left',
       slotName:'projectStatus'
    },
    {
      prop: 'deliveryType',
      label: '交付类型',
      hiddenSearch: true,
      sortable: false,
      minWidth: 100,
      slotName:'deliveryType',
      form: {
        index: 4,
        tagName: 'el-select',
         options: projectDeliveryType, required: true,
         on: { change: (val) => { functionList.searchChange(val) } }
}
    },
    // {
    //   prop: 'deliverySchedule',
    //   label: '发货进度',
    //   hiddenSearch: true,
    //   sortable: false,
    //   minWidth: 100
    // },
    {
      prop: 'deliveryDate',
      label: '交付日期',
      hiddenSearch: true,
      sortable: false,
      slotName:'deliveryDate',
      form: {
        index: 5,
        tagName: 'div',
        required: true,
        customSlots: ['deliveryDate']
      }
    },
    {
      prop: 'principalName',
      label: '负责人',
      hiddenSearch: true,
      sortable: false,
      slotName:'principalName',
      form: { index: 6, key: 'principalId', tagName: 'el-select',
      optionsProps: {
        label: 'userFullName',
        value: 'id'
      },options: [], required: false,
      props: {
        multiple: true
       }}
    },
    {
      prop: 'businessUnitName',
      label: '业务部门',
      hiddenSearch: true,
      sortable: false,
      form: { index: 7, key: 'businessUnit', tagName: 'el-select',
        optionsProps: {
          label: 'name',
          value: 'id'
        },options: [], required: true,
        props: {}
      }
    },
    // {
    //   prop: 'executiveUnit',
    //   label: '执行部门',
    //   hiddenSearch: true,
    //   sortable: false,
    //   minWidth: 100,
    //   slotName:'executiveUnit',
    //   form: {
    //     index: 8,
    //     tagName: 'el-select',
    //     options: executionDepartment,
    //     required: false
    //   }
    // },
    {
      prop: 'projectApprovalNumber',
      label: '立项编号',
      sortable: false,
      hiddenSearch: true,
      slotName:'projectApprovalNumber',
      minWidth: 120,
      form: { index: 9, tagName: 'el-input', required: false,props: { disabled: false } }
    },
    {
      prop: 'auditStatus',
      label: '审核',
      hiddenSearch: true,
      sortable: false,
      minWidth: 100,
      slotName:'auditStatus'
    },
    {
      prop: 'effectiveDate',
      label: '创建时间',
      hiddenSearch: true,
      sortable: false
    },
    {
      slotName: 'action',
      label: '操作',
      sortable: false,
      hiddenSearch: true,
      width: 280,
      fixed: 'right'
    }
  ]
  // 项目列表
export const nodeColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    fixed: 'left',
    width: 60
  },
  {
    prop: 'planObjectName',
    label: '计划对象',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'planName',
    label: '计划名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'planTypeName',
    label: '计划类型',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'startTime',
    label: '开始时间',
    sortable: false,
    hiddenSearch: true,
    slotName:'startTime'
  },
  {
    prop: 'finishTime',
    label: '完成时间',
    hiddenSearch: true,
    sortable: false,
    slotName:'finishTime'
  },
  {
    prop: 'principalName',
    label: '负责人',
    hiddenSearch: true,
    sortable: false,
     minWidth: 100,
     slotName:'principalName'
  }
]
