var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "name" },
    [
      _c("span", { staticStyle: { display: "inline-block", width: "214px" } }, [
        _c(
          "span",
          {
            staticStyle: { color: "#607FFF" },
            on: {
              click: function($event) {
                return _vm.selectLine(_vm.data)
              }
            }
          },
          [_vm._v(_vm._s(_vm.data.projectNumber))]
        ),
        _c("span", [_vm._v(" / " + _vm._s(_vm.data.projectName) + " ")])
      ]),
      _c(
        "el-popover",
        {
          attrs: {
            placement: "right",
            "visible-arrow": "false",
            "popper-class": "popover",
            trigger: "click"
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "text-align": "center",
                "vertical-align": "center",
                cursor: "pointer"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "slice-num",
                  staticStyle: {
                    "background-color": "#FA6400",
                    "border-top-left-radius": "4px",
                    "border-bottom-left-radius": "4px"
                  },
                  on: { click: _vm.viewAbnormalList }
                },
                [_vm._v(_vm._s(_vm.data.pie.abnormalCount))]
              ),
              _c(
                "div",
                {
                  staticClass: "slice-num",
                  staticStyle: { "background-color": "#A5A7BF" },
                  on: { click: _vm.viewECNList }
                },
                [_vm._v(_vm._s(_vm.data.pie.ecnCount))]
              ),
              _c(
                "div",
                {
                  staticClass: "slice-num",
                  staticStyle: {
                    "background-color": "#5C72CD",
                    "border-top-right-radius": "4px",
                    "border-bottom-right-radius": "4px"
                  },
                  on: { click: _vm.viewMaterialsDiffList }
                },
                [_vm._v(_vm._s(_vm.data.pie.materialsDifferentCount))]
              )
            ]
          ),
          _vm.data.pie.abnormalCount ||
          _vm.data.pie.ecnCount ||
          _vm.data.pie.materialsDifferentCount
            ? _c(
                "div",
                {
                  staticClass: "pie-chart",
                  attrs: { slot: "reference" },
                  slot: "reference"
                },
                [
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        placement: "top",
                        "popper-class": "plan-tooltip",
                        content: "未关闭的异常流程"
                      }
                    },
                    [
                      _c("div", {
                        staticClass: "slice",
                        style: {
                          display: !_vm.data.pie.abnormalCount
                            ? "none"
                            : "block"
                        }
                      })
                    ]
                  ),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        placement: "right",
                        "popper-class": "plan-tooltip",
                        content: "未执行的ECN"
                      }
                    },
                    [
                      _c("div", {
                        staticClass: "slice",
                        style: {
                          display: !_vm.data.pie.ecnCount ? "none" : "block"
                        }
                      })
                    ]
                  ),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        placement: "left-end",
                        "popper-class": "plan-tooltip",
                        content: "工单用料差异"
                      }
                    },
                    [
                      _c("div", {
                        staticClass: "slice",
                        style: {
                          display: !_vm.data.pie.materialsDifferentCount
                            ? "none"
                            : "block"
                        }
                      })
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }