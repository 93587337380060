<template>
    <div class="searchs">
      <!-- 基本信息数据 -->
      <el-col :span="6">
        <span class="bot-ti">项目编码：{{ detailData.projectNumber }}</span>
      </el-col>
      <el-col :span="6">
        <span class="bot-ti">项目名称：{{ detailData.projectName }}</span>
      </el-col>
      <el-col :span="6">
        <span class="bot-ti">客户订单号：{{ detailData.customerOrderNumber }}</span>
      </el-col>
      <el-col :span="6">
        <span class="bot-ti">客户：{{ detailData.customerName }}</span>
      </el-col>
      <el-col :span="6">
        <span class="bot-ti">业务部门：{{ detailData.businessUnitName }}</span>
      </el-col>
      <el-col :span="6">
        <span class="bot-ti">执行部门：{{ detailData.executiveUnit !=null ?executionDepartment[detailData.executiveUnit].name : ''  }}</span>
      </el-col>
      <el-col :span="12">
        <span class="bot-ti">负责人：{{ detailData.principalName }}</span>
      </el-col>
    </div>
  </template>
  <script>
  
   import { executionDepartment } from '@/views/plans/prod-plan-lists/data'
  export default {
    props: {
      detailData: {
        type: Object,
        default: () => ({})
      },
      isShow: {
        type: Boolean,
        default: false
      }
    },
    data(){
      return {
        executionDepartment
      }
    },
    methods: {
    }
  }
  </script>
  
  <style scoped lang="scss">
   .searchs{
    width: 100%;
     .bot-ti{
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #393D60;
        display:inline-block;
        margin-bottom:16px;
        word-break: break-all;
     }
   }
  </style>
  