<template>
  <div class="inspection-plan-detail-wrapper">
    <div class="inspection-plan-detail-body">
      <div class="detail-card">
        <div class="card-tit" style="height:48px;line-height:48px">计算范围设置
          <el-button
            type="primary"
            size="small"
            style="float:right;margin:8px 8px 0 0"
            @click="onSave"
          >保存</el-button>
        </div>
        <div class="card-bot" >
          <el-radio-group
          style="padding:20px"
            v-model="type"
            @change="changeType"
          >
            <el-radio :label="0">自制件</el-radio>
            <el-radio :label="1">采购件</el-radio>
          </el-radio-group>
        </div>
        <div class="card-tit">{{type ? '采购设置':'自制设置'}}
        </div>
        <div class="card-bot">
          <el-row class="view-list" :gutter="24">
            <el-form
              ref="detail"
              :model="detail"
              label-width="160px"
              class="demo-ruleForm"
              inline
              :rules="rules"
            >
              <el-col
                v-for="(item) in MRPSettingForm"
                :key="item.key"
                :span="10"
                style="display: flex;align-items:center;color: #9597AE;"
              >
                <el-form-item :label="item.name" prop="name">
                  <el-tooltip class="item" effect="dark" :content="item.desc" placement="top">
                    <i v-if="item.desc" class="el-icon-question" />
                  </el-tooltip>：
                  <el-input-number
                    v-model="detail[item.key]"
                    :min="0"
                    :controls="false"
                    :precision="0"
                  /> {{ item.suffix }}
                </el-form-item>
              </el-col>
            </el-form>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/production/project'
import { MRPSettingForm } from './data'
export default {
  name: 'MrpSetting',
  data() {
    return {
      rules: {
        // name: [
        //   { required: true, message: '请输入活动名称', trigger: 'blur' },
        //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        // ],
      },
      type:0,
      MRPSettingForm,
      detail:{
        avgDuration:0,
        materialsType:0
      }
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    changeType(){
      this.getDetail()
    },
    async getDetail() {
      const res = await api.requisitionOrderDurationView()
      if (res) this.detail = res[this.type]
      
    },
    async onSave() {
      const valid = await this.$refs.detail.validate()
      if (!valid) return
      const res = await api.requisitionOrderDurationSave({ avgDuration: this.detail.avgDuration,materialsType: this.detail.materialsType})
      if (res) {
        this.$message.success('操作成功')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.inspection-plan-detail-wrapper {
  background-color: #f0f2f5;
  height: 100%;

  .inspection-plan-detail-header {
    width: 100%;
    padding: 10px 0;
  }

  .inspection-plan-detail-body {
    height: calc(100% - 20px);

    .detail-card {
      margin-bottom: 20px;
    }
  }
  .function-header-wrap {
    padding-top: 0;
  }
}
.footer {
  position: absolute;
  bottom: 20px;
  text-align: right;
  width: 100%;
}
</style>
