<template>
  <div class="tracing">
    <FunctionHeader
      v-model="functionHeader.searchVal"
      :search-title="searchTitle"
      @search="$refs.mTable.setTableData(true)"
    />
    <MTable ref="mTable" :columns="columns" :set-data-method="getTableData" :height="height">
      <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
      <div slot="batchNumberDetail" slot-scope="{ row }">
        <router-link
          :to="{
            name: 'rawMaterialBatchCodeTracingDetail',
            query: { batchNumber: row.batchNumber },
          }"
        >
          <el-button type="text">
            {{ row.batchNumber }}
          </el-button>
        </router-link>
      </div>
    </MTable>
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import api from '@/api/information/print-label/batch-number'
import { MaterialColumns } from './columns'
export default {
  name: 'RawMaterialBatchCodeTracingList',
  components: {
    FunctionHeader
  },
  data() {
    return {
      columns: MaterialColumns,
      functionHeader: {
        searchVal: ''
      },
      searchTitle: '批次码/物料名称/编码'
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 192
    }
  },
  methods: {
    async getTableData(condition, callback) {
      const { page, order } = condition
      const res = await api.getBatchNumberListPage({
        page: page.currentPage,
        limit: page.pageSize,
        sort: order,
        searchKeyword: this.functionHeader.searchVal
      })
      if (res) {
        console.log(res)
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    }
  }
}
</script>

<style lang="scss" scoped>
.tracing {
  padding: 0 10px 10px 10px;
}
</style>
