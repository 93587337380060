import { productionTaskColumn } from './columns'
import { transformColumnsToForm } from '@/utils'
const newColumns = _.cloneDeep(productionTaskColumn)

export const planNodeFormList = transformColumnsToForm(newColumns)
planNodeFormList.push({
    hidden: true,
    label: '完成时间',
    key: 'finishTimeRelationPlanId',
    tagName: 'div',
    required: false,
    children: [{
      customSlots: ['finishTime']
    }]
  })