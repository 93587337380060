<template>
    <div class="searchs">
      <!-- 顶部折叠搜索 -->
      <el-row>
        <el-col :md="20" :gutter="10">
          <el-row :gutter="10" type="flex" justify="start" style="flex-flow:row wrap">
            <el-col v-if="showProcedure" :md="5">
              <el-input v-model="searchFormData.producePlanNumber" :placeholder="'生产工单编号'" clearable/>
            </el-col>
            <el-col v-if="showProcedure" :md="7">
              <el-input v-model="searchFormData.productionTaskCode" :placeholder="'生产任务编号'" clearable/>
            </el-col>
            <el-col :md="7">
                <SelectRemoteSearch
            ref="procedureIds"
            class="select"
            placeholder="工序名称/编码"
            multiple
            option-val="id"
            option-label="fullName"
            :api="api.procedureList"
            v-model="searchFormData.procedureIds"
            collapse-tags
            @change="searchData"
          />
            </el-col>
            <el-col :md="5">
                <el-input v-model="searchFormData.procedureLocationCode" :placeholder="'工序定位码'" clearable/>
            </el-col>
          </el-row>
          <el-row v-if="!show" type="flex" justify="start" style="flex-flow:row wrap" :gutter="10">
            <el-col :md="5">
              <el-select v-model="searchFormData.status" placeholder="任务状态" clearable filterable @change="searchData" >
                <el-option
                  v-for="item in statusList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-col>
            <el-col :md="7">
              <el-date-picker
                v-model="searchFormData.taskStartTime"
                type="datetimerange"
                range-separator="至"
                start-placeholder="任务开始开始时间"
                end-placeholder="结束时间"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
                :picker-options="pickerOptions"
                clearable
                @change="searchData"
              />
            </el-col>
            <el-col :md="7">
              <el-date-picker
                v-model="searchFormData.taskEndTime"
                type="datetimerange"
                range-separator="至"
                start-placeholder="任务结束开始时间"
                end-placeholder="结束时间"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
                :picker-options="pickerOptions"
                clearable
                @change="searchData"
              />
            </el-col>
            <el-col :md="5">
              <el-select v-model="searchFormData.createUserId" placeholder="创建人" clearable filterable @change="searchData">
                <el-option
                  v-for="item in reporterList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-col>
            <el-col :md="12">
              <el-date-picker
                v-model="searchFormData.createTime"
                type="datetimerange"
                range-separator="至"
                start-placeholder="创建开始时间"
                end-placeholder="结束时间"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
                :picker-options="pickerOptions"
                clearable
                @change="searchData"
              />
            </el-col>
            <el-col :md="7">
              <el-select v-model="searchFormData.executorUserId" placeholder="执行人" clearable filterable @change="searchData">
                <el-option
                  v-for="item in reporterList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-col>
            <el-col :md="5">
              <el-select v-model="searchFormData.result" placeholder="结论判定" filterable clearable @change="searchData">
                <el-option
                  v-for="(item, index) in judgeMentList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :md="4" class="all-btns">
          <el-button size="small" type="primary" @click="searchData">查询</el-button>
          <el-button size="small" @click="resetData">重置</el-button>
          <div class="showMore" :style="{background: show ? '' : '#F8F8F8'}" @click="changeMore">
            <span>高级筛选</span>
            <i v-if="show" class="el-icon-caret-bottom icons" />
            <i v-else class="el-icon-caret-top" />
          </div>
        </el-col>
      </el-row>
    </div>
  </template>
  <script>
  import api from '@/api/production/project'
  import dayjs from 'dayjs'
  import SelectMultipleMaterialsNew from '@/components/SelectMultipleMaterialsNew/SelectMultipleMaterialsNew'
  import wapi from '@/api/information/production/procedure'
  import bapi from '@/api/information/print-label/batch-number'
  import SelectRemoteSearch from '@/components/SelectRemoteSearch/index.vue'
  
  export default {
    components: { SelectMultipleMaterialsNew,SelectRemoteSearch },
    props: {
      isHave: {
        type: Boolean,
        default: false
      },
      showProcedure: {
        type: Boolean,
        default: false
      },
      formOption: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        api,
        wapi,
        bapi,
        show: true,
        getCurrentProcedureList: [],
        batchNumberList: [],
        searchFormData: {
            producePlanNumber: '',
            productionTaskCode: '',
          inspectorId: '',
          taskStartTime:[],
          taskEndTime:[],
          createTime: [],
          endTime: [],
          batchNumber: '',
          inspectionSchemeId: ''
        },
        inspectionSchemeList: [],
        reporterList: [],
        selectKeyword: '0',
        pickerOptions: {
          shortcuts: [{
            text: '今天',
            onClick(picker) {
              const end = dayjs().format('YYYY-MM-DD 23:59:59')
              const start = dayjs().format('YYYY-MM-DD 00:00:00')
              // start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const end = dayjs().subtract(1, 'day').format('YYYY-MM-DD 23:59:59')
              const start = dayjs().subtract(1, 'day').format('YYYY-MM-DD 00:00:00')
              // start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }]
        },
        judgeMentList:[{name:'合格',id:'0'},{name:'不合格',id:'1'}],
        statusList:[{name:'未开始',id:'0'},{name:'质检中',id:'1'},{name:'已结束',id:'2'}]
  
      }
    },
    mounted() {
      this.reporterList = this.formOption.reporterList
      // this.getCurrentProcedureList = this.formOption.procedureOptions
      this.inspectionSchemeList = this.formOption.inspectionOptions
      this.batchNumberList = this.formOption.batchNumberOptions
    },
    methods: {
      changeMore() {
        this.show = !this.show
      },
      // 顶部查询
      searchData() {
        let searchDatas = []
        console.log(this.searchFormData)
        Object.keys(this.searchFormData).forEach(item => {
            console.log(item)
          if (Array.isArray(this.searchFormData[item])) {
            if (this.searchFormData[item].length > 0) {
              if (item === 'createTime') {
                searchDatas.push({
                    createTimeStart:this.searchFormData[item][0],
                    createTimeEnd:this.searchFormData[item][1]
                })
              }else if(item === 'taskStartTime'){
                searchDatas.push({
                    startTimeStart:this.searchFormData[item][0],
                    startTimeEnd:this.searchFormData[item][1]
                })
              }else if(item === 'taskEndTime'){
                searchDatas.push({
                    endTimeStart:this.searchFormData[item][0],
                    endTimeEnd:this.searchFormData[item][1]
                })
              }else if(item === 'procedureIds'){
                searchDatas.push({
                    procedureIds:this.searchFormData[item]
                })
              }
            }
          } else if (this.searchFormData[item] || this.searchFormData[item] === 0) {
            searchDatas.push({
                  [item]: this.searchFormData[item]
              })
          }
        })
        console.log(searchDatas)
        this.$emit('searchData', searchDatas)
      },
      // 顶部重置
      resetData() {
        this.searchFormData = {
          procedureId: '',
          reporterId: '',
          inspectorId: '',
          createTime: [],
          endTime: [],
          batchNumber: '',
          inspectionSchemeId: ''
        }
        if (this.$refs.procedureIds) this.$refs.procedureIds.reset()
        this.$emit('resetData')
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
   .searchs{
      padding-top: 20px;
      ::v-deep{
        .el-button-group .el-button{
          height: 32px;
        }
  
        .el-date-editor--datetimerange.el-input, .el-date-editor--datetimerange.el-input__inner{
          width: 100%;
        }
        .el-date-editor.el-input, .el-date-editor.el-input__inner{
          width: 100%;
        }
        .el-select{
          width:100%;
        }
  
      }
      ::v-deep{
        .el-col-md-5,.el-col-md-6{
          margin-bottom: 10px;
        }
     }
    .showMore{
      width: 88px;
      height: 40px;
      border-radius: 4px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #607FFF;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      cursor: pointer;
      .icons{
       color: #DDE3EB;
      }
      &:hover{
        background:#F8F8F8;
        i{ color: #607FFF;}
      }
    }
    .all-btns{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
    }
   }
  </style>
  