var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fault-wrap common-tab" },
    [
      _c(
        "el-radio-group",
        {
          staticClass: "m-radio-group",
          staticStyle: { "margin-bottom": "10px", display: "none" },
          on: { change: _vm.radioChange },
          model: {
            value: _vm.params.radio,
            callback: function($$v) {
              _vm.$set(_vm.params, "radio", $$v)
            },
            expression: "params.radio"
          }
        },
        [
          _c("el-radio-button", { attrs: { label: "process" } }, [
            _vm._v("过程检")
          ]),
          _c("el-radio-button", { attrs: { label: "incomming" } }, [
            _vm._v(_vm._s(_vm.memberCode === "dmdz" ? "放行单" : "来料检"))
          ]),
          _c("el-radio-button", { attrs: { label: "outProduct" } }, [
            _vm._v("出货检")
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c(
            "div",
            { staticClass: "top" },
            [
              _c("SearchForms", {
                ref: "searchForms",
                attrs: {
                  "show-procedure": _vm.params.radio === "process",
                  "form-option": _vm.formOptions
                },
                on: { resetData: _vm.resetData, searchData: _vm.searchTable }
              })
            ],
            1
          ),
          _c("el-divider", { staticClass: "e-divider" }),
          _c(
            "FunctionHeader",
            {
              staticStyle: { padding: "10px 0 10px 0" },
              attrs: {
                "search-title": "请输入质检单号",
                tags: _vm.functionHeader.tags
              },
              on: {
                search: function($event) {
                  return _vm.$refs.mTable.setTableData(true)
                }
              },
              model: {
                value: _vm.functionHeader.searchVal,
                callback: function($$v) {
                  _vm.$set(_vm.functionHeader, "searchVal", $$v)
                },
                expression: "functionHeader.searchVal"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "all-select",
                  attrs: { slot: "all" },
                  slot: "all"
                },
                [
                  _c(
                    "div",
                    { staticClass: "checkAll" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: {
                            disabled: _vm.checkeds,
                            indeterminate: _vm.isIndeterminate
                          },
                          on: { change: _vm.selectCheck },
                          model: {
                            value: _vm.checkeds,
                            callback: function($$v) {
                              _vm.checkeds = $$v
                            },
                            expression: "checkeds"
                          }
                        },
                        [_vm._v("全选")]
                      )
                    ],
                    1
                  ),
                  _vm._l(_vm.allStatus, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: item.value,
                        staticClass: "all-li",
                        class: item.checked ? "active" : "",
                        on: {
                          click: function($event) {
                            return _vm.selectTab(item, index)
                          }
                        }
                      },
                      [_vm._v(_vm._s(item.label))]
                    )
                  })
                ],
                2
              )
            ]
          ),
          _c("MTable", {
            ref: "mTable",
            attrs: {
              columns:
                _vm.params.radio === "process" ? _vm.columnsAll : _vm.incomming,
              height: _vm.height,
              "set-data-method": _vm.getTableData
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "number",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      _vm.isShowChild
                        ? _c("NewPageOpen", {
                            attrs: {
                              path: {
                                name: "qualityInspectionOrderDetail",
                                query: { id: row.id, isView: "view" }
                              },
                              text: row.number
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                }
              },
              {
                key: "inspectionType",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(_vm._s(_vm.inspectionTypeNames[row.inspectionType]))
                  ])
                }
              },
              {
                key: "passRate",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [_vm._v(_vm._s(row.passRate) + "%")])
                }
              },
              {
                key: "passAndUnPass",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(_vm._s(row.okCount) + "/" + _vm._s(row.ngCount))
                  ])
                }
              },
              {
                key: "status",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _c("div", { staticClass: "faultStatusBox" }, [
                      _c(
                        "div",
                        {
                          staticClass: "faultStatus ",
                          style: {
                            background:
                              _vm.qualityStatus[row.status].background,
                            color: _vm.qualityStatus[row.status].color
                          }
                        },
                        [_vm._v(_vm._s(_vm.qualityStatus[row.status].name))]
                      ),
                      row.showCheck
                        ? _c("div", { staticClass: "faultStatus checkClass" }, [
                            _vm._v("待审核")
                          ])
                        : _vm._e(),
                      row.showApprove
                        ? _c(
                            "div",
                            { staticClass: "faultStatus approveClass" },
                            [_vm._v("待审批")]
                          )
                        : _vm._e()
                    ])
                  ])
                }
              },
              {
                key: "allCount",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _c("span", [
                      _vm._v(_vm._s(row.okCount) + "/" + _vm._s(row.ngCount))
                    ])
                  ])
                }
              },
              {
                key: "result",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(
                      _vm._s(
                        row.result === 1
                          ? "合格"
                          : row.result === 0
                          ? "不合格"
                          : ""
                      )
                    )
                  ])
                }
              },
              {
                key: "reports",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _c(
                      "a",
                      {
                        staticClass: "li-btn",
                        on: {
                          click: function($event) {
                            return _vm.views(row)
                          }
                        }
                      },
                      [_vm._v("查看")]
                    )
                  ])
                }
              },
              {
                key: "action",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    { staticStyle: { "z-index": "100" } },
                    [
                      _vm.permission("print")
                        ? _c(
                            "a",
                            {
                              staticClass: "li-btn",
                              on: {
                                click: function($event) {
                                  return _vm.printOrder(row)
                                }
                              }
                            },
                            [_vm._v("打印检验报告")]
                          )
                        : _vm._e(),
                      _vm.permission("print")
                        ? _c("el-divider", { attrs: { direction: "vertical" } })
                        : _vm._e(),
                      _vm.permission("del") &&
                      (row.status === 0 || row.deleteAuth)
                        ? _c("DelPopover", {
                            staticClass: "li-btn",
                            on: {
                              onOk: function(callback) {
                                return _vm.del(row, callback)
                              }
                            }
                          })
                        : _vm._e(),
                      _vm.permission("del") &&
                      row.status !== 0 &&
                      !row.deleteAuth
                        ? _c("a", { staticClass: "color-btns" }, [
                            _vm._v("删除")
                          ])
                        : _vm._e(),
                      _vm.permission("del")
                        ? _c("el-divider", { attrs: { direction: "vertical" } })
                        : _vm._e(),
                      _vm.permission("inspector") && row.status === 0
                        ? _c(
                            "a",
                            {
                              staticClass: "li-btn",
                              on: {
                                click: function($event) {
                                  return _vm.editInspector(row)
                                }
                              }
                            },
                            [_vm._v("质检员")]
                          )
                        : _c("a", { staticClass: "color-btns" }, [
                            _vm._v("质检员")
                          ])
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c("PrintResult", { ref: "printCodes" }),
      _c("view-result", {
        ref: "viewResult",
        attrs: { visible: _vm.visibles, "common-id": "commonId" },
        on: {
          "update:visible": function($event) {
            _vm.visibles = $event
          }
        }
      }),
      _c(
        "MDialog",
        {
          attrs: { title: "编辑质检人员" },
          on: { onOk: _vm.submitInspector },
          model: {
            value: _vm.showInspector,
            callback: function($$v) {
              _vm.showInspector = $$v
            },
            expression: "showInspector"
          }
        },
        [
          _c("div", { staticClass: "item-wrapper" }, [
            _c("div", { staticClass: "label" }, [_vm._v("质检人员：")]),
            _c(
              "div",
              { staticStyle: { width: "100%" } },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      placeholder: "请选择",
                      filterable: "",
                      multiple: "",
                      clearable: ""
                    },
                    model: {
                      value: _vm.inspectionSchemeInspector,
                      callback: function($$v) {
                        _vm.inspectionSchemeInspector = $$v
                      },
                      expression: "inspectionSchemeInspector"
                    }
                  },
                  _vm._l(_vm.userList, function(item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id }
                    })
                  }),
                  1
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }