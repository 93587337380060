let functionList = {}
export const getFunctionList = val => {
  functionList = val
}

export const columns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left'
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    slotName: 'batchNumberDetail',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'planStartTimeYx',
    label: '引线计划开始时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'planEndTimeYx',
    label: '引线计划结束时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'actualStartTimeYx',
    label: '引线实际开始时间',
    sortable: false,
    hiddenSearch: true,
    width: 200
  },
  {
    prop: 'actualEndTimeYx',
    label: '引线实际结束时间',
    sortable: false,
    hiddenSearch: true,
    width: 200
  },
  {
    prop: 'planStartTimeBz',
    label: '包纸计划开始时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'planEndTimeBz',
    label: '包纸计划结束时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'actualStartTimeBz',
    label: '包纸实际开始时间',
    sortable: false,
    hiddenSearch: true,
    width: 200
  },
  {
    prop: 'actualEndTimeBz',
    label: '包纸实际结束时间',
    sortable: false,
    hiddenSearch: true,
    width: 200
  },
  {
    prop: 'continueHourBz',
    label: '包纸持续时间（小时）',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'continueTimeBz',
    label: '包纸持续时间（天）',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'planStartTimeXq',
    label: '线圈计划开始时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'planEndTimeXq',
    label: '线圈计划结束时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'actualStartTimeXq',
    label: '线圈实际开始时间',
    sortable: false,
    hiddenSearch: true,
    width: 200
  },
  {
    prop: 'actualEndTimeXq',
    label: '线圈实际结束时间',
    sortable: false,
    hiddenSearch: true,
    width: 200
  },
  {
    prop: 'continueHourXq',
    label: '线圈持续时间（小时）',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'continueTimeXq',
    label: '线圈持续时间（天）',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'planStartTimeTx',
    label: '铁芯计划开始时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'planEndTimeTx',
    label: '铁芯计划结束时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'actualStartTimeTx',
    label: '铁芯实际开始时间',
    sortable: false,
    hiddenSearch: true,
    width: 200
  },
  {
    prop: 'actualEndTimeTx',
    label: '铁芯实际结束时间',
    sortable: false,
    hiddenSearch: true,
    width: 200
  },
  {
    prop: 'continueHourTx',
    label: '铁芯持续时间（小时）',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'continueTimeTx',
    label: '铁芯持续时间（天）',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'planStartTimeZz',
    label: '总装计划开始时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'planEndTimeZz',
    label: '总装计划结束时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'actualStartTimeZz',
    label: '总装实际开始时间',
    sortable: false,
    hiddenSearch: true,
    width: 200
  },
  {
    prop: 'actualEndTimeZz',
    label: '总装实际结束时间',
    sortable: false,
    hiddenSearch: true,
    width: 200
  },
  {
    prop: 'continueHourZz',
    label: '总装持续时间（小时）',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'continueTimeZz',
    label: '总装持续时间（天）',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'continueHour',
    label: '总持续时间（小时）',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'continueTime',
    label: '总持续时间（天）',
    sortable: false,
    hiddenSearch: true,
  }
]

export const downloadColumns = [
  {
    prop: "name",
    label: "模板名称",
    sortable: false,
    hiddenSearch: true,
    minWidth: 150,
    form: {
      tagName: "el-select",
      key: "name",
      colSpan: 24,
      props: {
        options: [],
        filterable: true
      },
      on: {
        change: (val) => {
          functionList.templateChange(val)
        }
      }
    }
  }
];