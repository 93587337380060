var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "raw-tracing" },
    [
      _c("div", { staticClass: "procedure-detail-header" }, [
        _c(
          "div",
          {
            staticClass: "left",
            on: {
              click: function($event) {
                return _vm.$router.push({ name: "rawMaterialBatchCodeTracing" })
              }
            }
          },
          [
            _c("img", {
              attrs: {
                src: require("@/assets/information/procedure/左滑@2x.png")
              }
            }),
            _c("span", [_vm._v("原材料追溯详情")])
          ]
        )
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
        _c("div", { staticClass: "card-bot" }, [
          _c(
            "div",
            { staticClass: "tables" },
            [
              _c("MTable", {
                ref: "mTable",
                attrs: {
                  "show-page": false,
                  height: 110,
                  columns: _vm.columns.BarchColumns,
                  data: _vm.barchList
                }
              })
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("投产记录")]),
        _c("div", { staticClass: "card-bot" }, [
          _c(
            "div",
            { staticClass: "tables" },
            [
              _c("FunctionHeader", {
                staticStyle: { "padding-top": "0" },
                attrs: {
                  "search-title": _vm.searchTitle,
                  "columns-setting": false
                },
                on: {
                  search: function($event) {
                    return _vm.getTableData()
                  }
                },
                model: {
                  value: _vm.functionHeader.searchVal,
                  callback: function($$v) {
                    _vm.$set(_vm.functionHeader, "searchVal", $$v)
                  },
                  expression: "functionHeader.searchVal"
                }
              }),
              _c("MTable", {
                ref: "mTable",
                attrs: {
                  height: _vm.height,
                  "show-page": false,
                  columns: _vm.columns.ProductionColumns,
                  data: _vm.productionList
                },
                scopedSlots: _vm._u([
                  {
                    key: "index",
                    fn: function(ref) {
                      var $index = ref.$index
                      return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                    }
                  },
                  {
                    key: "orderNo",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {},
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.jumpDetail(row)
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(row.produceWorkOrderNumber) + " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    }
                  }
                ])
              })
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("质检记录")]),
        _c("div", { staticClass: "card-bot" }, [
          _c(
            "div",
            { staticClass: "tables" },
            [
              _c("MTable", {
                ref: "mTable",
                attrs: {
                  height: _vm.height,
                  "show-page": false,
                  columns: _vm.columns.QualityRecordColumns,
                  data: _vm.qualityRecordList
                },
                scopedSlots: _vm._u([
                  {
                    key: "index",
                    fn: function(ref) {
                      var $index = ref.$index
                      return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                    }
                  },
                  {
                    key: "inspectResult",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [
                        _vm._v(_vm._s(["合格", "不合格"][row.inspectResult]))
                      ])
                    }
                  },
                  {
                    key: "look",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {},
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.handleClick(row)
                                }
                              }
                            },
                            [_vm._v("查看")]
                          )
                        ],
                        1
                      )
                    }
                  }
                ])
              })
            ],
            1
          )
        ])
      ]),
      _c("scan-quality-template", {
        attrs: { visible: _vm.look, datas: _vm.lookdata },
        on: {
          "update:visible": function($event) {
            _vm.look = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }