<template>
  <div class="searchs">
    <!-- 基本信息数据 -->
    <el-col :span="12">
      <span class="bot-ti">项目编码：{{ detailData.projectNumber }}</span>
    </el-col>
    <el-col :span="12">
      <span class="bot-ti">项目名称：{{ detailData.projectName }}</span>
    </el-col>
    <el-col :span="12">
      <span class="bot-ti">客户订单号：{{ detailData.customerOrderNumber }}</span>
    </el-col>
    <el-col :span="12">
      <span class="bot-ti">客户：{{ detailData.customName }}</span>
    </el-col>
    <el-col :span="12">
      <span class="bot-ti">业务部门：{{ detailData.businessUnitName }}</span>
    </el-col>
    <el-col :span="12">
      <span class="bot-ti">执行部门：{{ detailData.executiveUnitName }}</span>
    </el-col>
    <el-col :span="12">
      <span class="bot-ti">计划对象：{{ detailData.planObjectName }}</span>
    </el-col>
    <el-col :span="12">
      <span class="bot-ti">计划名称：{{ detailData.planName }}</span>
    </el-col>
    <el-col :span="12">
      <span class="bot-ti">计划类型：{{ detailData.planTypeName }}</span>
    </el-col>
    <el-col :span="12">
      <span class="bot-ti">负责人：{{ detailData.projectPrincipalName }}</span>
    </el-col>
  </div>
</template>
<script>
export default {
  props: {
    detailData: {
      type: Object,
      default: () => ({})
    },
    isShow: {
      type: Boolean,
      default: false
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
 .searchs{
   .bot-ti{
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393D60;
      display:inline-block;
      margin-bottom:16px;
      word-break: break-all;
   }
 }
</style>
