var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "materials-wrap common-tab" },
    [
      _c(
        "el-radio-group",
        {
          staticClass: "m-radio-group",
          staticStyle: { display: "none" },
          on: { change: _vm.radioChange },
          model: {
            value: _vm.params.radio,
            callback: function($$v) {
              _vm.$set(_vm.params, "radio", $$v)
            },
            expression: "params.radio"
          }
        },
        [
          _c("el-radio-button", { attrs: { label: "DefectsItem" } }, [
            _vm._v("缺陷项")
          ]),
          _c("el-radio-button", { attrs: { label: "DefectsReason" } }, [
            _vm._v("缺陷原因")
          ])
        ],
        1
      ),
      _c("FunctionHeader", {
        attrs: {
          tags: _vm.functionHeader.tags,
          "search-title": _vm.searchTitle,
          radio: _vm.params.radio,
          "import-name": _vm.functionHeader.importName,
          "export-name": _vm.functionHeader.exportName,
          "columns-key": _vm.columnsKey
        },
        on: {
          search: function($event) {
            return _vm.$refs.mTable.setTableData(true)
          }
        },
        model: {
          value: _vm.functionHeader.searchVal,
          callback: function($$v) {
            _vm.$set(_vm.functionHeader, "searchVal", $$v)
          },
          expression: "functionHeader.searchVal"
        }
      }),
      _c("MTable", {
        ref: "mTable",
        attrs: {
          columns: _vm.columns[_vm.params.radio],
          height: _vm.height,
          "columns-key": _vm.columnsKey,
          "set-data-method": _vm.getTableData
        },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function(ref) {
              var $index = ref.$index
              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
            }
          },
          {
            key: "isEnabled",
            fn: function(ref) {
              var row = ref.row
              return _c("div", { staticClass: "is-enabled" }, [
                _c("div", {
                  style: { background: row.isEnabled ? "#24CAD7" : "#FFCC78" }
                }),
                _c("div", [_vm._v(_vm._s(row.isEnabled ? "启用" : "停用"))])
              ])
            }
          },
          {
            key: "action",
            fn: function(ref) {
              var row = ref.row
              return _c(
                "div",
                {},
                [
                  _vm.permission(_vm.params.radio + "Copy")
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.modifyOrCopy(row, "copy")
                            }
                          }
                        },
                        [_vm._v("复制")]
                      )
                    : _vm._e(),
                  _vm.permission(_vm.params.radio + "Copy")
                    ? _c("el-divider", { attrs: { direction: "vertical" } })
                    : _vm._e(),
                  _vm.permission(_vm.params.radio + "Status")
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.switchStatus(row, _vm.params.radio)
                            }
                          }
                        },
                        [_vm._v(_vm._s(row.isEnabled ? "停用" : "启用"))]
                      )
                    : _vm._e(),
                  _vm.permission(_vm.params.radio + "Status")
                    ? _c("el-divider", { attrs: { direction: "vertical" } })
                    : _vm._e(),
                  _c(
                    "el-dropdown",
                    {
                      on: {
                        command: function(command) {
                          _vm.more(row, command)
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "el-dropdown-link" }, [
                        _vm._v(" 更多"),
                        _c("i", {
                          staticClass: "el-icon-arrow-down el-icon--right"
                        })
                      ]),
                      _c(
                        "el-dropdown-menu",
                        {
                          staticClass: "td-dropdown-menu",
                          attrs: { slot: "dropdown" },
                          slot: "dropdown"
                        },
                        [
                          _vm.permission(_vm.params.radio + "Modify")
                            ? _c(
                                "el-dropdown-item",
                                { attrs: { command: "modify" } },
                                [_vm._v("修改")]
                              )
                            : _vm._e(),
                          _vm.permission(_vm.params.radio + "Del")
                            ? _c(
                                "el-dropdown-item",
                                { attrs: { command: "del" } },
                                [_vm._v("删除")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }
          }
        ])
      }),
      _c(
        "MDialog",
        {
          attrs: { title: _vm.messages[_vm.currentType] },
          on: { onOk: _vm.submitForm },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c("MFormBuilder", {
            ref: "formBuild",
            attrs: {
              "form-data": _vm.formData,
              "form-list": _vm.formList[_vm.params.radio]
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }