<template>
  <div class="quality-inspection-project-wrapper">
    <el-tabs id="sortTabs" ref="sortTab" v-model="currentTabs" type="card" :closable="!readOnly" @tab-remove="removeTab">
      <el-tab-pane v-for="(item, index) in projectList" :key="index" :label="item.inspectionProjectName || '请选择'"
        :name="'tab' + index" />
    </el-tabs>
    <el-card v-for="(card, cardIndex) in projectList" v-show="('tab' + cardIndex) === currentTabs" :key="cardIndex"
      class="card-custom">
      <div class="top">
        <el-row :gutter="24" style="align-items: center">
          <el-col :span="18" style="display:inline-flex;align-items: center">
            <div class="required-div">检验组：</div>
            <el-select v-model="card.inspectionProjectName" size="small" :disabled="readOnly" filterable
              style="width: 250px" placeholder="请选择检验项" @change="(val) => { inspectionProjectChange(val, cardIndex) }">
              <el-option v-for="(item, index) in formOptions.inspectionProjectList" :key="index" :label="item.name"
                :value="item.name" />
            </el-select>
            <el-button v-show="!readOnly" type="text" size="small" style="margin-left: 10px"
              @click="addProjectGroupFront(cardIndex)">新增检验组</el-button>
          </el-col>
          <el-col v-if="!readOnly" :span="6" style="text-align: right">
            <el-button type="text" size="small" style="height: 32px;position: relative;top: -2px"
              @click="saveAs(cardIndex)">
              <img src="@/assets/statistics-board/保存@2x.png" width="14px" style="margin-right: 8px">
              <span style="position: relative;top: -3px">另存为</span>
            </el-button>
            <el-button type="text" size="small" style="height: 32px;position: relative;top: -2px"
              @click="delCard(cardIndex)">
              <img src="@/assets/l_c_M_images/垃圾桶.png" width="14px" style="margin-right: 8px">
              <span style="position: relative;top: -3px">删除</span>
            </el-button>
          </el-col>
          <el-col v-if="card.inspectionProjectName && !readOnly" :span="24">
            <el-button type="text" @click="showInspectionItems(cardIndex)">选择检验项</el-button>
            <el-button type="text" @click="addInspectionItems(cardIndex)">新增检验项</el-button>
          </el-col>
          <el-col v-if="card.columns.length > 1" class="table-custom" :span="24">
            <MTable ref="mTable" only-key="uuid" :columns="card.columns" max-height="600px" :show-page="false"
              :style="{ paddingTop: readOnly ? '15px' : 0 }" :data="card.inspectionItemList">
              <div v-if="!readOnly" slot="headerSlot" slot-scope="{ columnIndex }" class="right-icon">
                <el-button icon="el-icon-edit" type="text" style="padding: 0;font-size: 16px;margin-right: 8px"
                  @click="modifyInspectionItem(columnIndex, cardIndex)" />
                <img class="remove-img" src="@/assets/quality/sx-remove@2x.png" width="16px"
                  @click="removeColumns(cardIndex, columnIndex)">
              </div>
              <div slot="customRow" slot-scope="{ row, columnIndex }">
                <div v-if="row['检验项'] === '参数类型'">
                  <div>{{ row.data[columnIndex - 1].parameterType | parameterTypeName }}</div>
                  <div
                    v-if="(row.data[columnIndex - 1].possibleValue || !readOnly) && [2, 3].includes(row.data[columnIndex - 1].parameterType)">
                    <span style="color: #607FFF">可能值：</span>
                    <span style="color: #607FFF">
                      <el-select v-model="row.data[columnIndex - 1].possibleValue" size="small" multiple
                        :disabled="readOnly" allow-create filterable default-first-option placeholder="请输入可能值，敲击回车" />
                    </span>
                  </div>
                  <div
                    v-if="(![undefined, null, ''].includes(row.data[columnIndex - 1].defaultValue) || !readOnly) && row.data[columnIndex - 1].parameterType !== 4"
                    style="padding-top: 10px;display: inline-flex;align-items: center">
                    <span style="color: #607FFF">默认值：</span>
                    <span style="color: #607FFF">
                      <el-select v-if="[2, 3].includes(row.data[columnIndex - 1].parameterType)"
                        v-model="row.data[columnIndex - 1].defaultValue" size="small" :disabled="readOnly" filterable
                        :multiple="row.data[columnIndex - 1].parameterType === 3" placeholder="请输入默认值">
                        <el-option v-for="(item, index) in row.data[columnIndex - 1].possibleValue" :key="index"
                          :label="item" :value="item" />
                      </el-select>
                      <el-input type="textarea" v-if="[1].includes(row.data[columnIndex - 1].parameterType)"
                        v-model="row.data[columnIndex - 1].defaultValue" size="small" :disabled="readOnly"
                        placeholder="请输入默认值" style="width: 100%" />
                      <el-input-number v-if="[0].includes(row.data[columnIndex - 1].parameterType)"
                        v-model="row.data[columnIndex - 1].defaultValue" size="small" :disabled="readOnly"
                        controls-position="right" placeholder="请输入默认值" style="width: 100%" />
                    </span>
                  </div>
                </div>
                <div v-if="row['检验项'] === '检验方法'">{{ row.data[columnIndex - 1].inspectionMethod }}</div>
                <div v-if="row['检验项'] === '检验标准'">{{ row.data[columnIndex - 1].inspectionStandard }}</div>
                <div v-if="row['检验项'] === '标准值'">
                  <el-row
                    v-if="(![undefined, null].includes(row.data[columnIndex - 1].standardValue) || !readOnly) && row.data[columnIndex - 1].parameterType === 0"
                    :gutter="24">
                    <el-col :span="12">
                      <el-input-number v-model="card.inspectionItemList[0].data[columnIndex - 1].standardValue"
                        size="small" :disabled="readOnly" controls-position="right" style="width: 100%" />
                    </el-col>
                  </el-row>
                  <div v-else-if="row.data[columnIndex - 1].parameterType !== 0" class="td-block" />
                </div>
                <div v-if="row['检验项'] === '上偏差'">
                  <el-row
                    v-if="(![undefined, null].includes(row.data[columnIndex - 1].upperLimit) || !readOnly) && row.data[columnIndex - 1].parameterType === 0"
                    :gutter="24">
                    <el-col :span="12">
                      <el-input-number v-model="card.inspectionItemList[0].data[columnIndex - 1].upperLimit" size="small"
                        :min="0" :disabled="readOnly" controls-position="right" style="width: 100%" />
                    </el-col>
                    <el-col :span="12">
                      <el-select v-model="card.inspectionItemList[0].data[columnIndex - 1].limitType" size="small"
                        :disabled="readOnly" style="width: 100%" placeholder="请选择">
                        <el-option label="数值" :value="1" />
                        <el-option label="百分比" :value="0" />
                      </el-select>
                    </el-col>
                  </el-row>
                  <div v-else-if="row.data[columnIndex - 1].parameterType !== 0" class="td-block" />
                </div>
                <div v-if="row['检验项'] === '下偏差'">
                  <el-row
                    v-if="(![undefined, null].includes(row.data[columnIndex - 1].standardValue) || !readOnly) && row.data[columnIndex - 1].parameterType === 0"
                    :gutter="24">
                    <el-col :span="12">
                      <el-input-number v-model="card.inspectionItemList[0].data[columnIndex - 1].lowerLimit" size="small"
                        :min="0" :disabled="readOnly" controls-position="right" style="width: 100%" />
                    </el-col>
                    <el-col :span="12">
                      <el-select v-model="card.inspectionItemList[0].data[columnIndex - 1].limitType" size="small"
                        :disabled="readOnly" style="width: 100%" placeholder="请选择">
                        <el-option label="数值" :value="1" />
                        <el-option label="百分比" :value="0" />
                      </el-select>
                    </el-col>
                  </el-row>
                  <div v-else-if="row.data[columnIndex - 1].parameterType !== 0" class="td-block" />
                </div>
                <div v-if="row['检验项'] === '附件'">
                  <div v-for="(at, index) in getArray(row.data[columnIndex - 1].attachments)" :key="index">
                    <a :href="at.url" target="_blank" style="color: #607FFF">{{ at.name }}</a>
                    <img src="@/assets/quality/路径@2x.png" alt="" style="width: 12px;margin-left: 5px">
                  </div>
                </div>
                <div v-if="row['检验项'] === '备注'">{{ row.data[columnIndex - 1].remark }}</div>
              </div>
            </MTable>
          </el-col>
        </el-row>
      </div>
    </el-card>
    <div class="attachment" style="padding-top: 20px">
      <div v-if="fileList.length || !readOnly" style="display:flex;">
        <div style="padding-left: 10px;white-space: nowrap;">质检附件：</div>
        <el-upload v-if="!readOnly" ref="upload" :file-list="fileList" :disabled="readOnly" v-bind="uploadProps">
          <el-button type="text" class="icons" icon="el-icon-upload" style="padding: 0">上传文件</el-button>
        </el-upload>
        <div v-else>
          <div v-for="(row, index) in fileList" :key="index" class="files"
            style="display: flex;justify-content: space-between;align-items: center;width: 400px">
            <div style="max-width: 300px" class="ellipsis">{{ row.name }}</div>
            <div>
              <el-button type="text" style="padding: 0">
                <a :href="row.url" target="_blank">查看</a>
              </el-button>
              <el-divider direction="vertical" />
              <el-button style="padding: 0" type="text" @click="handleDownload(row)">
                下载
              </el-button>
            </div>
          </div>
        </div>
      </div>
      <div style="display:flex;align-items: center;padding-top: 20px">
        <div class="required-div" style="white-space: nowrap;">检验人员：</div>
        <el-select v-model="inspectionUserList" size="small" filterable :disabled="readOnly" style="width: 100%"
          :multiple="true" placeholder="请选择检验人员">
          <el-option v-for="item in formOptions.userList" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </div>
      <div  style="display:flex;align-items: center;padding-top: 20px">
        <div class="required-div" >是否开启审核：</div>
        <el-radio-group :disabled="readOnly" v-model="checkAndApproveInfo.isCheck" @change="changeCheckedValue">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </div>
      <div  v-if="checkAndApproveInfo.isCheck" style="display:flex;align-items: center;padding-top: 16px;padding-left: 8px">
        <div class="required-div" style="white-space: nowrap;">审核人员：</div>
        <el-select v-model="checkAndApproveInfo.checkedIDList" size="small" filterable :disabled="readOnly"
          style="width: 100%" :multiple="true" placeholder="请选择审核人员">
          <el-option v-for="item in formOptions.userList" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </div>
      <div  style="display:flex;align-items: center;padding-top: 20px">
        <div class="required-div" >是否开启审批：</div>
        <el-radio-group :disabled="readOnly" v-model="checkAndApproveInfo.isApproved" @change="changeApproverValue">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </div>
      <div  v-if="checkAndApproveInfo.isApproved" style="display:flex;align-items: center;padding-top: 16px;padding-left: 8px">
        <div class="required-div" style="white-space: nowrap;">审批人员：</div>
        <el-select v-model="checkAndApproveInfo.approverIdList" size="small" filterable :disabled="readOnly"
          style="width: 100%" :multiple="true" placeholder="请选择审批人员"   >
          <el-option v-for="item in formOptions.userList" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </div>
    </div>

    <MDialog v-model="visible" title="选择检验项" @onOk="submitItem">
      <MTable ref="mTable" :columns="inspectionItemColumns" :height="height" :data="inspectionItemList" :show-page="false"
        :columns-setting="false" @selection-change="inspectionItemChange">
        <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
        <div slot="inspectionEntryName" slot-scope="{ row }">{{ row.inspectionEntryName }}</div>
        <div slot="parameterType" slot-scope="{ row}">{{ row.parameterType | parameterTypeName }}</div>
        <div slot="defaultValue" slot-scope="{ row}">{{ getDPValue(row.defaultValue) }}</div>
        <div slot="possibleValue" slot-scope="{ row}">{{ getDPValue(row.possibleValue) }}</div>
        <div slot="isEnabled" slot-scope="{ row }" class="is-enabled">
          <div :style="{ background: row.isEnabled ? '#24CAD7' : '#FFCC78' }" />
          <div>{{ row.isEnabled ? '启用' : '停用' }}</div>
        </div>
      </MTable>
    </MDialog>
    <MDialog v-model="addItemVisible" :title="currentType === 'add' ? '新增检验项' : '修改检验项'" @onOk="submitFormItem">
      <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList">
        <el-upload slot="drawing" :file-list="itemfileList" v-bind="uploadProps2">
          <el-button type="primary" size="small">上传附件</el-button>
        </el-upload>
      </MFormBuilder>
    </MDialog>
    <MDialog v-model="groupVisible" width="400px" :title="addGroupType === 'end' ? '另存检验组' : '新增检验组'"
      @onOk="submitGroupForm">
      <el-input v-model="formData.inspectionProjectName" placeholder="请输入检验组名称" style="padding-right: 15px;" />
    </MDialog>
  </div>
</template>

<script>
import IPApi from '@/api/quality/inspection-project'
import userApi from '@/api/sets/user/user'
import ItemApi from '@/api/quality/inspection-items'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
import { inspectionItemType,inspectionNewItemType } from '@/config/options.config'
import { getToken } from '@/utils/auth'
import inspectionItemColumns from './columns'
import saveAs from 'file-saver'
import Sortable from '@/utils/plug/sortable.min'
import mixinJS from './mixin'
import baseSettingApi from '@/api/sets/base-setting'
// import $ from 'jquery'

export default {
  name: 'QualityInspectionProject',
  filters: {
    parameterTypeName(val) {
      return inspectionItemType.find(item => item.id === val).name
    },
    newParameterTypeName(val) {
      return inspectionNewItemType.find(item => item.id === val).name
    }
  },
  components: {},
  mixins: [mixinJS],
  props: {
    inspectionEntryMap: {
      type: Object,
      default: () => ({})
    },
    attachments: {
      type: String,
      default: ''
    },
    userList: {
      type: Array,
      default: () => ([])
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    baseFormData:{
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dragOptions: {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      },
      formOptions: {
        inspectionProjectList: [],
        userList: []
      },
      defaultColumns: [{
        prop: '检验项',
        label: '检验项',
        sortable: false,
        width: 80,
        fixed: 'left',
        hiddenSearch: true
      }],
      defaultKeys: ['参数类型', '检验方法', '检验标准', '标准值', '上偏差', '下偏差', '附件','备注'],
      projectList: [{
        columns: [],
        inspectionItemList: []
      }],
      currentIndex: 0,
      defaultSearch: {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }]
        ))
      },
      uploadProps: {
        multiple: true,
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: { token: getToken() },
        showFileList: true,
        onSuccess: this.fileUploadChange,
        onRemove: this.fileRemove,
        onError: this.fileUploadChange
      },
      fileList: [],
      inspectionUserList: [],
      inspectionItemList: [],
      inspectionItemColumns,
      selectInspectionItems: [],
      visible: false,
      currentTabs: 'tab0',
      sortableEl: null,
      checkAndApproveInfo: {
        isCheck: 0,  //0关闭  1开启审核
        checkedIDList: [],   //审核人员id
        isApproved: 0,    ///0关闭  1开启审批
        approverIdList: []   //审批人员id
      },
      isQualityValue:['合格','不合格']
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 54 - 100 - 50 - 60 - 70
    }
  },
  watch: {
    inspectionEntryMap: {
      handler: function (val) {
        this.setUserIds()
        if (val && Object.values(val).length) {
          const inspectionEntryMap = Object.values(val)
          this.fileList = this.attachments ? JSON.parse(this.attachments) : []
          this.checkAndApproveInfo={
        isCheck: this.baseFormData.isCheck,  //0关闭  1开启审核
        checkedIDList: this.baseFormData.checkedIDList ,   //审核人员id
        isApproved: this.baseFormData.isApproved,    //0关闭  1开启审批
        approverIdList: this.baseFormData.approverIdList   //审批人员id
      }
          inspectionEntryMap.sort((a, b) => a[0].sort - b[0].sort)
          this.projectList = inspectionEntryMap.map((item) => ({
            inspectionProjectName: item[0].inspectionProjectName,
            columns: [],
            inspectionItemList: []
          }))
          inspectionEntryMap.forEach(this.transferItemToTable)
        }
      },
      immediate: true,
      deep: true
    },
    readOnly: {
      handler: function (val) {
        if (!val) {
          this.$nextTick(this.setDragTab)
        } else {
          const addIcon = document.getElementsByClassName('tab-add-icon')
          if (addIcon[0]) {
            addIcon[0].remove()
          }
          if (this.sortableEl) {
            this.sortableEl.destroy()
          }
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.getInspectionItemList()
    this.getUserList()
    console.log('basef:',this.baseFormData)

  },
  methods: {
    changeCheckedValue(e){
      if(!e){
        this.checkAndApproveInfo.checkedIDList=[]
      }
    },
    changeApproverValue(e){
      if(!e){
        this.checkAndApproveInfo.approverIdList=[]
      }
    },
    async setUserIds() {
      if (this.userList.length) {
        this.inspectionUserList = this._.cloneDeep(this.userList.map(item => item.id || item))
      } else {
        const res = await baseSettingApi.getCode({ code: 'inspection_default_user' })
        if (res) {
          this.inspectionUserList = res.value ? JSON.parse(res.value) : []
        }
      }
      console.log('ins:',this.inspectionUserList)
    },
    async getUserList() {
      const res = await userApi.getUserList(this.defaultSearch)
      if (res) {
        this.formOptions.userList = res
      }
    },
    async getInspectionItemList() {
      const res = await IPApi.getInspectionProjectList(this.defaultSearch)
      if (res) {
        this.formOptions.inspectionProjectList = res
      }
    },
    async inspectionProjectChange(val, index) {
      const res = await ItemApi.getInspectionItemsList({
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }, {
            id: getUUid(),
            fieldName: 'inspectionProjectName',
            fieldType: 'string',
            fieldValue: val,
            operator: 'eq'
          }]
        ))
      })
      if (res) {
        if (res.length) {
          this.transferItemToTable(res, index)
        } else {
          this.projectList[index].columns = []
          this.projectList[index].inspectionItemList = []
          this.projectList[index].inspectionProjectName = val
        }
      }
    },
    setDragTab() {
      const s = this.$refs.sortTab.$el
      const el = s.querySelector('.el-tabs__nav')
      this.sortableEl = Sortable.create(el, {
        animation: 150,
        onEnd: (evt) => {
          this.projectList.splice(evt.newIndex, 0, this.projectList.splice(evt.oldIndex, 1)[0])
          const newArray = this.projectList.slice(0)
          this.projectList = []
          this.currentTabs = 'tab' + evt.newIndex
          this.$nextTick(function () {
            this.projectList = newArray
          })
        },
        onMove: (evt, originalEvent) => {
          // if (this.$refs.sortTab.$children[0]) {
          //   this.$refs.sortTab.$children[0].scrollNext()
          // }
          // const prev = document.querySelector('#sortTabs .el-tabs__nav-prev')
          // const next = document.querySelector('#sortTabs .el-tabs__nav-next')
          // console.log(originalEvent, $('#sortTabs .el-tabs__nav-prev').offset())
          // console.log(prev, next)
          // console.log(evt)
          // console.log(originalEvent)
        }
      })
      const navScroll = s.querySelector('.el-tabs__nav-scroll')
      const i = document.createElement('i')
      i.title = '添加检验组'
      i.onclick = this.addInspectionProject
      i.className = 'el-icon-circle-plus tab-add-icon'
      navScroll.appendChild(i)
    },
    removeTab(name) {
      const index = +name.split('tab')[1]
      this.projectList.splice(index, 1)
      if (this.currentTabs === name) {
        this.currentTabs = 'tab0'
      }
    },
    getArray(at) {
      try {
        return JSON.parse(at)
      } catch (err) {
        return []
      }
    },
    transferItemToTable(res, index) {
      const columns = []
      const inspectionItemList = []
      res.forEach(item => {
        item.possibleValue = item.possibleValue && !Array.isArray(item.possibleValue) ? JSON.parse(item.possibleValue) : item.possibleValue
        try {
          item.defaultValue = JSON.parse(item.defaultValue)
        } catch (e) {
          // 其他类型
        }
        columns.push({
          prop: item.name || item.inspectionEntryName,
          label: item.name || item.inspectionEntryName,
          sortable: false,
          minWidth: 310,
          headerSlotName: 'headerSlot',
          slotName: 'customRow',
          hiddenSearch: true
        })
      })
      const newArr = []
      res.forEach(item => {
        const newObj = {}
        Object.keys(item).forEach(key => {
          if (item[key] !== null) {
            newObj[key] = item[key]
          }
        })
        newArr.push(newObj)
      })
      this.defaultKeys.forEach(key => {
        inspectionItemList.push({ data: this._.cloneDeep(newArr), uuid: getUUid(), '检验项': key })
      })
      columns.unshift(this.defaultColumns[0])
      this.projectList[index].columns = columns
      this.projectList[index].inspectionItemList = inspectionItemList
      this.projectList[index].inspectionProjectName = res[0] ? res[0].inspectionProjectName : null
    },
    fileRemove(file) {
      const index = this.fileList.findIndex(item => item.id === file.id)
      this.fileList.splice(index, 1)
    },
    fileUploadChange(res, file) {
      if (res.errorCode === 0) {
        this.fileList.push({
          uid: file.uid,
          id: file.uid,
          name: file.name,
          url: res.result
        })
        return this.$message.success('上传成功')
      }
      if (res.errorCode !== 0) {
        return this.$message.error(res.result)
      }
    },
    addInspectionProject() {
      this.projectList.push({
        columns: [],
        inspectionItemList: []
      })
      this.currentTabs = 'tab' + (this.projectList.length - 1)
    },
    removeColumns(cardIndex, columnsIndex) {
      this.$confirm(`您确定要删除检验项（${this.projectList[cardIndex].columns[columnsIndex].label}）吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.projectList[cardIndex].columns.splice(columnsIndex, 1)
        this.projectList[cardIndex].inspectionItemList.forEach(item => {
          item.data.splice(columnsIndex - 1, 1)
        })
      })
    },
    getDPValue(value) {
      try {
        return JSON.parse(value).join(',')
      } catch (err) {
        if (Array.isArray(value)) return value.join(',')
        return value
      }
    },
    delCard(index) {
      this.$confirm(`您确定要删除此检验组吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.projectList.splice(index, 1)
      })
    },
    async showInspectionItems(index) {
      const res = await ItemApi.getInspectionItemsList({
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }, {
            id: getUUid(),
            fieldName: 'inspectionProjectName',
            fieldType: 'string',
            fieldValue: this.projectList[index].inspectionProjectName,
            operator: 'eq'
          }]
        ))
      })
      if (res) {
        if (res.length === 0) return this.$message.info('未配置检验项')
        this.inspectionItemList = this._.cloneDeep(res)
        this.currentIndex = index
        this.visible = true
        const selectIds = this.projectList[index].inspectionItemList[0].data.map(item => item.inspectionEntryId || item.id)
        // todo: 不同源 无法回显 明年解决
        setTimeout(() => {
          this.inspectionItemList.filter(item => selectIds.includes(item.id)).forEach(item => {
            this.$refs.mTable.tableRefs().toggleRowSelection(item, true)
          })
        }, 0)
      }
    },
    inspectionItemChange(val) {
      this.selectInspectionItems = val
    },
    submitItem(callback) {
      if (!this.selectInspectionItems.length) {
        callback()
        return this.$message.info('请至少选择一条检验组')
      }
      const currentInspectionItemsIds = this.projectList[this.currentIndex].inspectionItemList[0].data.map(item => item.id)
      const newInspectionItems = []
      this.selectInspectionItems.forEach(item => {
        const index = currentInspectionItemsIds.indexOf(item.id)
        if (index !== -1) {
          newInspectionItems.push(this.projectList[this.currentIndex].inspectionItemList[0].data[index])
        } else {
          newInspectionItems.push(item)
        }
      })
      this.transferItemToTable(newInspectionItems, this.currentIndex)
      this.visible = false
      callback()
    },
    handleDownload(file) {
      saveAs(file.url, file.name)
    },
    // 父组件取值使用
    transferDataToStandard() {
      const validProjectName = this.projectList.every(project => project.inspectionProjectName)
      if (!this.projectList.length || !validProjectName) {
        this.$message.info('请添加检验组')
        return false
      }
      if (!this.projectList[0].columns.length) {
        this.$message.info('请添加检验项')
        return false
      }
      const valid0 = this.projectList.every(project => project.inspectionItemList[0].data.length)
      if (!valid0) {
        this.$message.info('存在未设置检验项的检验组')
        return false
      }
      const valid = this.projectList.every(project => project.inspectionItemList[0].data.every(item => ![2, 3].includes(item.parameterType) || (item.possibleValue && item.possibleValue.length)))
      if (!valid) {
        this.$message.info('检验项中存在单选或多选的可能值未填写')
        return false
      }
      const valid2 = this.projectList.every(project => project.inspectionItemList[0].data.every(item => ![0].includes(item.parameterType) || (!item.upperLimit && item.upperLimit !== 0 && !item.lowerLimit && item.lowerLimit !== 0) || item.limitType || item.limitType === 0))
      if (!valid2) {
        this.$message.info('检验项中设置上下限必须选择数值类型')
        return false
      }
      if (!this.inspectionUserList.length) {
        this.$message.info('请选择检验人员')
        return false
      }
      if(this.checkAndApproveInfo.isCheck&&!this.checkAndApproveInfo.checkedIDList.length){
        this.$message.info('请选择审核人员')
        return false
      }
      if(this.checkAndApproveInfo.isApproved&&!this.checkAndApproveInfo.approverIdList.length){
        this.$message.info('请选择审批人员')
        return false
      }
      const projectObj = {}
      this.projectList.forEach((project, index) => {
        projectObj[`${index}-${project.inspectionProjectName}`] = project.inspectionItemList[0].data.map(item => ({
          standardValue: item.standardValue,
          upperLimit: item.upperLimit,
          lowerLimit: item.lowerLimit,
          limitType: item.limitType,
          inspectionEntryName: item.inspectionEntryName,
          inspectionEntryId: item.inspectionEntryId,
          inspectionProjectName: project.inspectionProjectName,
          parameterType: item.parameterType,
          defaultValue: Array.isArray(item.defaultValue) ? item.defaultValue.length ? JSON.stringify(item.defaultValue) : '' : item.defaultValue,
          possibleValue: [2, 3].includes(item.parameterType) ? JSON.stringify(item.possibleValue || []) : '',
          inspectionMethod: item.inspectionMethod,
          inspectionStandard: item.inspectionStandard,
          unitId:item.unitId,
          attachments: item.attachments,
          sort: index,
          remark:item.remark
        }))
      })
      return {
        inspectionEntryMap: projectObj,
        inspectorList: this.inspectionUserList,
        attachments: JSON.stringify(this.fileList),
        isCheck: this.checkAndApproveInfo.isCheck,  //0关闭  1开启审核
        checkedIDList: this.checkAndApproveInfo.checkedIDList,   //审核人员id
        isApproved: this.checkAndApproveInfo.isApproved,    ///0关闭  1开启审批
        approverIdList: this.checkAndApproveInfo.approverIdList   //审批人员id
      }
    }
  }
}
</script>

<style scoped lang="scss">
.quality-inspection-project-wrapper {
  .card-custom:not(:last-child) {
    margin-bottom: 10px;
  }

  .table-custom {
    ::v-deep {
      .el-table__row {
        td:first-child {
          background: #F6F7F9;
        }

        .td-block {
          background: #F6F7F9;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }
      }
    }
  }

  .right-icon {
    position: absolute;
    right: 10px;
    top: 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .remove-img {
    width: 16px;
  }

  .files:not(:last-child) {
    padding-bottom: 15px;
  }

  #sortTabs {
    position: relative;
    bottom: -1px;
  }

  ::v-deep {
    .tab-add-icon {
      font-size: 22px;
      color: #607FFF;
      position: relative;
      left: 10px;
      cursor: pointer;
    }

    .el-tabs__nav-scroll {
      display: flex;
      align-items: center;
    }

    .el-tabs__nav-wrap.is-scrollable .el-tabs__nav-scroll {
      display: block;
      align-items: unset;
    }

    .el-tabs__nav-wrap.is-scrollable .tab-add-icon {
      position: absolute;
      background: #FFFFFF;
      z-index: 99;
      left: calc(100% - 44px);
      height: 40px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      width: 30px;
      border-top: 1px solid rgb(240 242 245);
      border-right: 1px solid rgb(240 242 245);
      justify-content: center;
      border-radius: 0 8px 0 0;
      box-shadow: -4px -2px 6px 0px rgb(0 0 0 / 10%)
    }

    .blue-background-class {
      background: #607FFF !important;
    }

    .el-tabs__nav-prev,
    .el-tabs__nav-next {
      //padding-top: 8px;
    }

    .el-tabs__item {
      border-radius: 8px 8px 0 0;
      background: #F0F2F5;

      //box-shadow: 0 -1px 14px 0 rgba(0, 0, 0, 0.05)
      &:first-child {
        border-left: 1px solid rgb(240 242 245);
      }

      &:last-child {
        border-right: 1px solid rgb(240 242 245);
      }
    }

    .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
      color: #303133;
      background: #FFFFFF;
    }

    .el-tabs--card>.el-tabs__header {
      margin: 0;
    }

    .el-tabs--card>.el-tabs__header,
    .el-tabs--card>.el-tabs__header .el-tabs__nav {
      border: none;
    }

    .el-tabs--card>.el-tabs__header .el-tabs__item {
      border-top: 1px solid rgb(240 242 245);
      border-bottom: none;
    }

    .el-tabs__item:hover {
      color: #303133;
    }
  }
}
</style>
