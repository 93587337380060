<template>
  <div class="schedule-right">
    <div class="lines" />
    <div class="right-top">
      <div class="line-left">
        <div
        style="width: 200px"
      >
        <el-input
          v-model="functionHeader.searchVal"
          placeholder="请输入项目名称/编号/产品规格"
          clearable
          @change="inputChange"
          @keyup.enter.native="search()"
        >
        <i slot="suffix" class="el-input__icon el-icon-search" @click="search()"></i>
        </el-input>
      </div>
      </div>
      <div class="line-right">
        <el-popover
          ref="sortPopover"
          v-model="showSortPopover"
          :width="200"
          :offset="20"
          class="lines-pover"
          @hide="showSort = true"
        >
          <div
            v-for="item in sortList"
            :key="item.id"
            class="line-li"
            :style="{color: currentIdx === item.id ? '#607FFF' : '#41456B'}"
            @click="selectSorts(item)"
          >
            {{ item.name }}
            <img v-if="currentIdx === item.id" src="@/assets/plans/duigou.png" class="imgs" >
          </div>
        </el-popover>
        <div v-popover:sortPopover class="showMore" :style="{background: showSort ? '' : '#F8F8F8'}" @click="changeMore(1)">
          <span>排序</span>
          <i v-if="showSort" class="el-icon-caret-bottom icons" />
          <i v-else class="el-icon-caret-top" />
        </div>
        <el-popover
          ref="selectPopovers"
          v-model="showSelectPopover"
          :width="290"
          :offset="20"
          trigger="manual"
          @hide="showSelect = true"
          @show="setProcessId"
        >
          <SearchForm ref="serachForms" :button-span="24" :form-list-extend="searchFormList" :form-data="searchFormDatas"   @update:formData="data=>searchFormDatas=data" :searchType='1' class="searchs" @search="searchTable"/>
        </el-popover>
        <div v-popover:selectPopovers class="showMore" :style="{background: showSelect ? '' : '#F8F8F8'}" @click="changeMore(2)">
          <span>筛选</span>
          <i v-if="showSelect" class="el-icon-caret-bottom icons" />
          <i v-else class="el-icon-caret-top" />
        </div>
      </div>
    </div>
    <div class="right-bot">
      <el-radio-group v-model="params" class="m-radio-group right-radio" @change="radioChange">
        <el-radio-button label="NoSchedule">进行中<span class="li-span">{{ noNum }}</span></el-radio-button>
        <el-radio-button label="haveSchedule">未开始<span class="li-span">{{ haveNum }}</span></el-radio-button>
      </el-radio-group>
      <div class="list-all">
        <div id="lists" class="list" :style="{ height: heights + 'px'}">
          <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData" :layoutStyle="'prev, pager, next, jumper'" :pager-count="5"  @selection-change="selectionChange">
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          <NewPageOpen slot="projectNumber" slot-scope="{ row }" :path="getDetailPath(row, 'view')" :text="row.projectNumber" />
          <div slot="deliveryType" slot-scope="{ row }" >
            <span>{{ projectDeliveryType[row.deliveryType].name }}</span>
          </div>
          <div slot="deliveryDate" slot-scope="{ row }" >
            <span>{{ row.deliveryStartDate }}~{{row.deliveryEndDate}}</span>
          </div>
           <div slot="businessUnit" slot-scope="{ row }" >
            <span>{{ row.businessUnit!=null ?divisionOptions[row.businessUnit - 1].name : '' }}</span>
          </div>
          <div slot="principalName" slot-scope="{row }">
          <el-tooltip effect="dark" :content="row.principalName"  placement="top-end">
            <span class="mr-10">
              {{ row.principalName }}
           </span>
          </el-tooltip>
        </div>
          <div slot="executiveUnit" slot-scope="{ row }" >
            <span>{{row.executiveUnit!=null ?executionDepartment[row.executiveUnit].name : '' }}</span>
          </div>
          <div slot="projectApprovalNumber" slot-scope="{ row }" >
            <span>{{row.projectApprovalNumber }}</span>
          </div>
          <div slot="projectStatus" slot-scope="{ row }" >
            <span>{{ projectStatus[row.projectStatus].name }}</span>
          </div>
          <div slot="auditStatus" slot-scope="{ row }" >
            <span>{{ checkStatus[row.auditStatus].name }}</span>
          </div>
        </MTable>
          <div style="height:40px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchForm from '@/components/SearchForm/SearchForm'
import { searchFormList } from './form-list'
import apis from '@/api/production/plans'
import { getUUid } from '@/utils/index'
import { Encrypt } from '@/utils/sercet'
import { productionStatus } from '@/config/options.config'
import api from '@/api/projectManagement/project'
import { columns } from './columns'
import FunctionHeader from '@/components/FunctionHeader/index'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
export default {
  name: 'ScheduleRight',
  components: { NewPageOpen,SearchForm,FunctionHeader },
  props: {
    areaId: {
      type: Number,
      default: null
    },
    areaName: {
      type: String,
      default: null
    },
    planTypeIds:{
      type: Array,
        default: () => []
    },
    planNames:{
      type: Array,
        default: () => []
    }
  },
  data() {
    return {
      api,
      apis,
      orderLists: [],
      processId: '',
      unPlanProducePlanList: [],
      planProducePlanList: [],
      secList: {},
      columns,
      disabled: false,
      noNum: 0,
      haveNum: 0,
      showTip: true,
      searchFormList,
      searchFormDatas: {
        produceWorkOrderIds: '',
        produceOrderId: '',
        producePlanNumber: '',
        batchNumber: ''
      },
      productionStatus,
      params: 'NoSchedule',
      scheduleList: [],
      showSelect: true,
      showSort: true,
      showSortPopover: false,
      showSelectPopover: false,
      heights: '',
      sortList: [{
        id: 1,
        name: '按发货开始时间 正序',
        value: 'priority',
        sort: '+deliveryPlanStartTime'
      },
      {
        id: 2,
        name: '按发货开始时间 倒序',
        value: 'deliveryTime',
        sort: '-deliveryPlanStartTime'
      },
      {
        id: 3,
        name: '按发货结束时间 正序',
        value: 'deliveryTime',
        sort: '+deliveryPlanFinishTime'
      },
      {
        id: 4,
        name: '按发货结束时间 倒序',
        value: 'planStartTime',
        sort: '-deliveryPlanFinishTime'
      },
      {
        id: 5,
        name: '按生产开工开始时间 正序',
        value: 'planStartTime',
        sort: '+productionPlanStartTime'
      },
      {
        id: 6,
        name: '按生产开工开始时间 倒序',
        value: 'planEndTime',
        sort: '-productionPlanStartTime'
      },
      {
        id: 7,
        name: '按生产完工开始时间 正序',
        value: 'planEndTime',
        sort: '+productionPlanFinishTime'
      },
      {
        id: 8,
        name: '按生产完工开始时间 倒序',
        value: 'planEndTime',
        sort: '-productionPlanFinishTime'
      },
      {
        id: 9,
        name: '按装配开工开始时间 正序',
        value: 'planEndTime',
        sort: '+assemblyPlanStartTime'
      },
      {
        id: 10,
        name: '按装配开工开始时间 倒序',
        value: 'planEndTime',
        sort: '-assemblyPlanStartTime'
      },
      {
        id: 11,
        name: '按装配完工开始时间 正序',
        value: 'planEndTime',
        sort: '+assemblyPlanFinishTime'
      },
      {
        id: 12,
        name: '按装配完工开始时间 倒序',
        value: 'planEndTime',
        sort: '-assemblyPlanFinishTime'
      },
      {
        id: 13,
        name: '按项目编码 正序',
        value: 'planEndTime',
        sort: '+projectNumber'
      },
      {
        id: 14,
        name: '按项目编码 倒序',
        value: 'planEndTime',
        sort: '-projectNumber'
      },
      {
        id: 15,
        name: '按项目名称 正序',
        value: 'planEndTime',
        sort: '+projectName'
      },
      {
        id: 16,
        name: '按项目名称 倒序',
        value: 'planEndTime',
        sort: '-projectName'
      }],
      currentIdx: -1,
      currentSort: null,
      keyword: '',
      selectKeyword: '0',
      searchDatas: [],
      count: 0,
      formOptions: {
        processFlowList: []
      },
      produceWorkOrderId: undefined,
      searchFenData: [],
        selectionData:[],
        functionHeader: {
          searchVal: ''
        },
        searchFormatData:[]
    }
  },
  computed: {
      height() {
        return this.$store.state.app.contentHeight - 235 - 10 + 35 - 80 + 20
      }
  },
  watch: {
    areaId(val) {
      // if (val) {
      //   this.params = 'NoSchedule'
      //   this.currentIdx = -1
      //   this.$refs.serachForms.reset()
      // }
    },
    scheduleList() {
      this.heights = this.$store.state.app.contentHeight - 320 + 37
    },
    planTypeIds(val) {
      this.$refs.mTable.setTableData(true)
    },
    planNames(){
      this.$refs.mTable.setTableData(true)
    }
  },
  mounted() {
    this.getGunterProjectCount()
    this.processFlowList()
    this.getUserList()
    this.getBusinessUnitList()
    if (this.areaId) {
      this.getScheduleList()
    }
  },
  methods: {
      // 获取负责人
      async getUserList() {
      const res = await api.getResponsible()
      if (res) {
        this.searchFormList[2].children[0].options = res
      }
    },
      // 获取业务部门
      async getBusinessUnitList() {
        const res = await api.getBusinessUnitList()
        if (res) {
          this.searchFormList[3].children[0].options = res
        }
      },
    setProcessId() {
      this.processId = this.$refs.serachForms.form.processFlowId
    },
    getDetailPath(row) {
      return { name: 'ProjectManagementDetail', query: { id: row.id}}
    },
    selectionChange(data) {
      if (Array.isArray(data)) {
        this.selectionData = data
        this.$parent.getData(this.selectionData )
      }
    },
    modifys(row) {
      this.$emit('modify', { id: row.id, type: 1 })
    },
    search(){
      this.getGunterProjectCount()
      this.$refs.mTable.setTableData(true)
    },
    inputChange(val) {
      if (!val) this.search()
    },
    async getTableData(condition, callback) {
        const { page, search, order } = condition
        const { productionPlanStartTimeStart, productionPlanStartTimeEnd,productionPlanFinishTimeStart,assemblyPlanStartTimeStart,assemblyPlanFinishTimeStart,
          deliveryPlanStartTimeStart,deliveryPlanFinishTimeStart,...data } = this.searchFormDatas
        const searchCondition = {
          search: this.functionHeader.searchVal,
          ...data,
          productionPlanStartTimeStart: productionPlanStartTimeStart? productionPlanStartTimeStart[0] : null,
          productionPlanStartTimeEnd: productionPlanStartTimeStart? productionPlanStartTimeStart[1] : null,
          productionPlanFinishTimeStart:productionPlanFinishTimeStart? productionPlanFinishTimeStart[0] : null,
          productionPlanFinishTimeEnd:productionPlanFinishTimeStart? productionPlanFinishTimeStart[1] : null,
          assemblyPlanStartTimeStart:assemblyPlanStartTimeStart? assemblyPlanStartTimeStart[0] : null,
          assemblyPlanStartTimeEnd:assemblyPlanStartTimeStart? assemblyPlanStartTimeStart[1] : null,
          assemblyPlanFinishTimeStart:assemblyPlanFinishTimeStart? assemblyPlanFinishTimeStart[0] : null,
          assemblyPlanFinishTimeEnd:assemblyPlanFinishTimeStart? assemblyPlanFinishTimeStart[1] : null,
          deliveryPlanStartTimeStart:deliveryPlanStartTimeStart? deliveryPlanStartTimeStart[0] : null,
          deliveryPlanStartTimeEnd:deliveryPlanStartTimeStart? deliveryPlanStartTimeStart[1] : null,
          deliveryPlanFinishTimeStart:deliveryPlanFinishTimeStart? deliveryPlanFinishTimeStart[0] : null,
          deliveryPlanFinishTimeEnd:deliveryPlanFinishTimeStart? deliveryPlanFinishTimeStart[1] : null,
          sort:this.currentSort,
          planTypeIds:this.planTypeIds,
          planNames:this.planNames
        }
        const res = await api.getGunterProjectListPage({
          page: page.currentPage,
          size: page.pageSize,
          gunterChartStatus:this.params === 'NoSchedule' ? 1 :0,
          ...searchCondition
        })
        if (res) {
          let a = res.records.map(item=>{
              return {
                ...item,
                planNodeList:item.planNodeList? item.planNodeList.map(obj=>{
                  return {
                    ...obj,
                    produceStatus:this.params === 'NoSchedule' ? 1 :0,
                    isPlan:1
                  }
                }):[]
              }
            })
          callback({
            total: res.total,
            content: a
          })
          this.$parent.getData(a)
        }
        callback()
      },
      async getGunterProjectCount(){
        const { productionPlanStartTimeStart, productionPlanStartTimeEnd,productionPlanFinishTimeStart,assemblyPlanStartTimeStart,assemblyPlanFinishTimeStart,
          deliveryPlanStartTimeStart,deliveryPlanFinishTimeStart,...data } = this.searchFormDatas
        const searchCondition = {
          search: this.functionHeader.searchVal,
          ...data,
          productionPlanStartTimeStart: productionPlanStartTimeStart? productionPlanStartTimeStart[0] : null,
          productionPlanStartTimeEnd: productionPlanStartTimeStart? productionPlanStartTimeStart[1] : null,
          productionPlanFinishTimeStart:productionPlanFinishTimeStart? productionPlanFinishTimeStart[0] : null,
          productionPlanFinishTimeEnd:productionPlanFinishTimeStart? productionPlanFinishTimeStart[1] : null,
          assemblyPlanStartTimeStart:assemblyPlanStartTimeStart? assemblyPlanStartTimeStart[0] : null,
          assemblyPlanStartTimeEnd:assemblyPlanStartTimeStart? assemblyPlanStartTimeStart[1] : null,
          assemblyPlanFinishTimeStart:assemblyPlanFinishTimeStart? assemblyPlanFinishTimeStart[0] : null,
          assemblyPlanFinishTimeEnd:assemblyPlanFinishTimeStart? assemblyPlanFinishTimeStart[1] : null,
          deliveryPlanStartTimeStart:deliveryPlanStartTimeStart? deliveryPlanStartTimeStart[0] : null,
          deliveryPlanStartTimeEnd:deliveryPlanStartTimeStart? deliveryPlanStartTimeStart[1] : null,
          deliveryPlanFinishTimeStart:deliveryPlanFinishTimeStart? deliveryPlanFinishTimeStart[0] : null,
          deliveryPlanFinishTimeEnd:deliveryPlanFinishTimeStart? deliveryPlanFinishTimeStart[1] : null,
          sort:this.currentSort
        }
         const res = await api.getGunterProjectCount({...searchCondition})
         if(res){
          this.noNum = res.underway
          this.haveNum = res.notStart
         }
      },
    // 工艺列表
    async processFlowList() {
      const res = await apis.getFlowers()
      if (res) {
        this.formOptions.processFlowList = res
        // this.searchFormList[5].children[0].options = res
      }
    },
    processFlowChange(val) {
      this.$refs.serachForms.setForm({ processFlowId: val })
    },
    searchData(item) {
    },
    // 查询
    async searchTable(data, val) {
      if (val === 1) {
        this.searchFormDatas = {}
      }
      console.log('查询', data)
      this.searchFormatData = data
      await this.$refs.mTable.setTableData()
      await this.getGunterProjectCount()
      this.showSelectPopover = false
      this.showSelect = true
    },
    async selectSorts(val) {
      if(this.currentIdx === val.id){
        this.currentIdx = ''
        this.currentSort = ''
      }else{
        this.currentIdx = val.id
        this.currentSort = val.sort
      }
      await this.getGunterProjectCount()
      this.$refs.mTable.setTableData(true)
      this.showSortPopover = false
      this.showSort = true
    },
    radioChange() {
      this.$refs.mTable.setTableData(true)
    },
    changeMore(val) {
      if (val === 1) {
        this.showSort = !this.showSort
      }
      if (val === 2) {
        this.showSelect = !this.showSelect
        this.showSelectPopover = !this.showSelectPopover
      }
    },
    // 获取已排程和未排程
    async getScheduleList() {
      const search = []
      search.push({
        id: getUUid(),
        fieldName: 'status',
        fieldType: 'number',
        fieldValue: '0^1^2^3',
        operator: 'in'
      })
      search.push({
        id: getUUid(),
        fieldName: 'areaId',
        fieldType: 'number',
        fieldValue: this.areaId,
        operator: 'eq'
      })
      console.log('search=>', search, this.searchDatas, this.searchFenData)
      const res = await apis.getScheduleNums({
        materialsSearchType: this.selectKeyword,
        materialsSearchValue: this.keyword,
        sort: this.currentSort,
        produceWorkOrderIds: this.produceWorkOrderId,
        searchVal: Encrypt(JSON.stringify([...search, ...this.searchDatas, ...this.searchFenData]))
      })
      if (res) {
        this.count = res.count
        this.haveNum = res.planProducePlanList ? res.planProducePlanList.length : 0
        this.noNum = res.unPlanProducePlanList ? res.unPlanProducePlanList.length : 0
        this.unPlanProducePlanList = res.unPlanProducePlanList
        this.planProducePlanList = res.planProducePlanList
        this.radioChange()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.input-prepend {
  width: 264px;
}
::v-deep {
  .el-popover, .el-popper {
    width: 200px!important;
    padding: 0!important;
  }
  .el-form-item {
    margin-bottom: 10px;
  }
  .el-input__icon, .el-form-item__content, .el-range-editor.el-input__inner, .el-input--suffix .el-input__inner, .el-input-group--prepend .el-input__inner {
    height: 32px;
    line-height: 32px;
  }
  .el-date-editor .el-range-separator, .el-date-editor .el-range__icon{
    line-height: 25px;
  }
  .el-button--medium {
    padding: 8px 20px;
  }
  .el-col-24 {
    text-align: center;
  }
  .el-col-24:nth-child(5){
    text-align: left !important;
 }
  .el-input--suffix .el-input__inner {
    height: 32px!important;
  }
}
.searchs {
  padding: 0;
}
.line-li {
    height: 42px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #41456B;
    line-height: 42px;
    cursor: pointer;
    position: relative;
    &:hover {
      color: #607FFF;
      // background: #F9FAFC;
    }
    .imgs {
      width: 14px;
      height: 10px;
      display: block;
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -5px;
    }
}
.schedule-right {
  .lines {
    width: 100%;
    height: 20px;
    background: #fff;
  }
  .right-top {
    padding: 0 15px;
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #DDE3EB;
    margin-bottom: 10px;
    flex-wrap:wrap;
    .line-left {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393D60;
      width: 200px;
    }
    .line-right {
      display: flex;
      justify-content: flex-end;
      .lines-pover {
        padding: 10px 0 0;
      }
    }
  }
  .right-radio {
    width: 335px;
    margin-left: 15px;
  }
 .li-span {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #9597AE;
    margin-left: 6px;
  }
  .right-bot {
    height: 100%;
    .no-img {
      width: 298px;
      height: 189px;
      margin: 20px auto;
      display: block;
    }
    .m-radio-group .el-radio-button {
      width: 100%;
      margin-left: 0;
    }
    .list-all {
      overflow-y: scroll;
      height: 100%;
      margin-top: 10px;
    }
    .list {
      width: 100%;
      // height: 100%;
      background: #F0F2F5;
      padding-bottom: 20px;
      .list-li {
        width: 338px;
        // height: 175px;
        background: #FFFFFF;
        border-radius: 4px;
        display: flex;
        justify-content: flex-start;
        margin: 0 auto 10px auto;
        cursor: pointer;
        position: relative;
        &:last-child {
          margin-bottom: 10px;
        }
        .li-left {
          width: 6px;
          height: 100%;
          background: #A4B3C6;
          border-radius: 4px 0px 0px 4px;
          position: absolute;
          left: 0;
          top: 0;
        }
        .li-right {
          width: calc(100% - 6px);
          padding: 10px 5px 0 15px;
          .li-right-top {
            display: flex;
            justify-content: space-between;
            .title {
              width: 205px;
              font-size: 16px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #393D60;
              line-height: 22px;
            }
            .tips {
              position: relative;
            }
            .tip-img {
              position: absolute;
              top: 32px;
              width: 58px;
              height: 22px;
              display: block;
            }
            .tip {
              padding: 0 10px;
              height: 22px;
              line-height: 22px;
              border-radius: 4px;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #FA6400;
            }
          }
          .des {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #393D60;
            line-height: 20px;
            margin: 5px 0;
            height: 20px;
          }
          .des-title {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #393D60;
            line-height: 17px;
          }
          .des-tit {
            // height: 17px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #9597AE;
            line-height: 17px;
            margin: 5px 0;
          }
          .dec {
            // height: 35px;
            border-top: 1px solid #F0F2F5;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #9597AE;
            line-height: 24px;
          }
        }
      }
    }
  }
  .showMore {
    padding: 0 10px;
    height: 30px;
    border-radius: 4px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #607FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
    .icons {
      color: #DDE3EB;
    }
    &:hover {
      background:#F8F8F8;
      i {
        color: #607FFF;
      }
    }
  }
}
::v-deep input[classname=select-materials] {
  display: none;
}

::v-deep .el-input-group--prepend {
  width: 100%;
  height: 32px;

}

::v-deep .el-input-group__prepend {
  background: #FFFFFF;
  width: calc(100% - 56px);

  > div {
    width: calc(100% + 40px);
  }
  .input-prepend {
    width: 100%;
    color: #495060;
  }
}

::v-deep .el-input-group__append {
  width: 56px;
}
::v-deep .customer-content2 {
  div:first-child{
    display: block !important;
  }
}
</style>
