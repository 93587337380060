var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "inspection-plan-detail-wrapper" },
    [
      _c("div", { staticClass: "inspection-plan-detail-body" }, [
        _c(
          "div",
          { staticClass: "detail-card" },
          [
            _c("div", { staticStyle: { height: "10px" } }),
            _c(
              "el-steps",
              {
                attrs: {
                  active: _vm.currentStatus,
                  "align-center": "",
                  "finish-status": "success"
                }
              },
              [
                _c("el-step", {
                  attrs: {
                    title: "填写项目基本信息",
                    description: "录入项目编号/名称等基本信息"
                  }
                }),
                _c("el-step", {
                  attrs: {
                    title: "选择关联或创建需求",
                    description: "将项目与销售、备库、预测需求关联"
                  }
                }),
                _c("el-step", {
                  attrs: {
                    title: "设置节点",
                    description: "拆分项目中的WBS节点并指定负责"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "card-bot" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentStatus === 0,
                      expression: "currentStatus===0"
                    }
                  ]
                },
                [
                  _c(
                    "MFormBuilder",
                    {
                      ref: "baseForm",
                      attrs: {
                        "form-list": _vm.formList,
                        "form-data": _vm.baseFormData
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          attrs: { slot: "deliveryDate" },
                          slot: "deliveryDate"
                        },
                        [
                          _c("el-date-picker", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.showTwoTime,
                                expression: "!showTwoTime"
                              }
                            ],
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "date",
                              "value-format": "yyyy-MM-dd",
                              format: "yyyy-MM-dd",
                              placeholder: "请选择交付日期",
                              clearable: ""
                            },
                            on: { change: _vm.chooseStartTime },
                            model: {
                              value: _vm.deliveryOneDate,
                              callback: function($$v) {
                                _vm.deliveryOneDate = $$v
                              },
                              expression: "deliveryOneDate"
                            }
                          }),
                          _c("el-date-picker", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showTwoTime,
                                expression: "showTwoTime"
                              }
                            ],
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "daterange",
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              "value-format": "yyyy-MM-dd",
                              format: "yyyy-MM-dd",
                              clearable: ""
                            },
                            on: { change: _vm.chooseStartTime },
                            model: {
                              value: _vm.deliveryTwoDate,
                              callback: function($$v) {
                                _vm.deliveryTwoDate = $$v
                              },
                              expression: "deliveryTwoDate"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentStatus === 1,
                      expression: "currentStatus===1"
                    }
                  ],
                  staticStyle: { "text-align": "center" }
                },
                [
                  _c("ProjectRequirements", {
                    on: { chooseRequirements: _vm.chooseRequirements }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentStatus === 2,
                      expression: "currentStatus===2"
                    }
                  ]
                },
                [
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c("div", { staticStyle: { margin: "10px 0" } }, [
                        _vm._v(" 选择项目计划方案模板： ")
                      ]),
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择计划方案模板",
                            filterable: "",
                            remote: "",
                            "reserve-keyword": "",
                            "remote-method": _vm.remoteMethod,
                            clearable: ""
                          },
                          on: { change: _vm.changeProjectPlan },
                          model: {
                            value: _vm.projectPlanId,
                            callback: function($$v) {
                              _vm.projectPlanId = $$v
                            },
                            expression: "projectPlanId"
                          }
                        },
                        _vm._l(_vm.projectPlanList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c("div", { staticStyle: { margin: "10px 0" } }, [
                    _vm._v(" 节点信息： ")
                  ]),
                  _c("MTable", {
                    ref: "mTable",
                    attrs: {
                      "default-expand-all": "",
                      "is-tree-data": true,
                      height: _vm.height,
                      columns: _vm.scheduleColumn,
                      data: _vm.tableData,
                      "show-page": false,
                      showAppend: true,
                      onlyKey: "treeId",
                      "tree-props": {
                        children: "children",
                        hasChildren: "hasChildren"
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "index",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _vm._v(_vm._s(!row.parentId ? row.sort + 1 : ""))
                          ])
                        }
                      },
                      {
                        key: "planName",
                        fn: function(ref) {
                          var row = ref.row
                          var $index = ref.$index
                          return _c(
                            "span",
                            { staticStyle: { display: "inline-block" } },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center"
                                  }
                                },
                                [
                                  _c("div", [_vm._v(_vm._s(row.planName))]),
                                  !row.isTaskNode
                                    ? _c("img", {
                                        staticClass: "num-img",
                                        attrs: {
                                          src: require("@/assets/plans/add.png")
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.addTaskNode(row, $index)
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ]
                              )
                            ]
                          )
                        }
                      },
                      {
                        key: "isTaskNode",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _c("span", [
                              _vm._v(_vm._s(row.isTaskNode ? "是" : "否"))
                            ])
                          ])
                        }
                      },
                      {
                        key: "startTimeAttribute",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            row.startTimeAttribute != null
                              ? _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.startFinishTimeAttribute[
                                        row.startTimeAttribute
                                      ].name
                                    )
                                  )
                                ])
                              : _vm._e()
                          ])
                        }
                      },
                      {
                        key: "finishTimeAttribute",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            row.finishTimeAttribute != null
                              ? _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.startFinishTimeAttribute[
                                        row.finishTimeAttribute
                                      ].name
                                    )
                                  )
                                ])
                              : _vm._e()
                          ])
                        }
                      },
                      {
                        key: "startTime",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  row.startTimeAttribute != 2
                                    ? row.startTime
                                      ? row.startTime
                                      : "-"
                                    : "-"
                                )
                              )
                            ])
                          ])
                        }
                      },
                      {
                        key: "finishTime",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  row.finishTimeAttribute != 2
                                    ? row.finishTime
                                      ? row.finishTime
                                      : "-"
                                    : "-"
                                )
                              )
                            ])
                          ])
                        }
                      },
                      {
                        key: "action",
                        fn: function(ref) {
                          var row = ref.row
                          var $index = ref.$index
                          return _c(
                            "div",
                            {},
                            [
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.modify(row, $index)
                                      }
                                    }
                                  },
                                  [_vm._v("修改")]
                                ),
                                _c("el-divider", {
                                  attrs: { direction: "vertical" }
                                }),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "text",
                                      disabled: row.children.length
                                        ? true
                                        : false
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.del(row, $index)
                                      }
                                    }
                                  },
                                  [_vm._v("删除")]
                                )
                              ]
                            ],
                            2
                          )
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ])
          ],
          1
        )
      ]),
      _c("div", { staticClass: "order-footer" }, [
        _c("div"),
        _c(
          "div",
          { staticClass: "footer-ri" },
          [
            _c(
              "el-button",
              {
                on: {
                  click: function($event) {
                    return _vm.returnList()
                  }
                }
              },
              [_vm._v("取 消 ")]
            ),
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.currentStatus > 0,
                    expression: "currentStatus > 0"
                  }
                ],
                attrs: { type: "default" },
                on: { click: _vm.beforeStep }
              },
              [_vm._v("上一步")]
            ),
            _vm.currentStatus === 0
              ? _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.onSave } },
                  [_vm._v("下一步")]
                )
              : _vm._e(),
            _vm.currentStatus === 1 && _vm.isChoose
              ? _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.onSave } },
                  [_vm._v("确定")]
                )
              : _vm._e(),
            _vm.currentStatus === 1 && !_vm.isChoose
              ? _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.onSave } },
                  [_vm._v("跳过")]
                )
              : _vm._e(),
            _vm.currentStatus === 2
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", loading: _vm.loading },
                    on: { click: _vm.onSave }
                  },
                  [_vm._v("完成创建")]
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _c("task-node-dialog", {
        attrs: {
          visible: _vm.showModify,
          formModifyData: _vm.formModifyData,
          nodeIndex: _vm.nodeIndex,
          tableData: _vm.tableData
        },
        on: {
          "update:visible": function($event) {
            _vm.showModify = $event
          },
          submitForm: _vm.submitModify
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }