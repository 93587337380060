<template>
    <div class="model-wrap">
      <SearchForm
        ref="searchForm"
        :form-list-extend="searchFormList"
        :form-data.sync="searchFormData"
        style="padding: 0"
        @search="reSearch"
      />
      <FunctionHeader
        v-model="functionHeader.searchVal"
        search-title="请输入节点名称"
        :tags="functionHeader.tags"
        @search="$refs.mTable.setTableData(true)"
      />
      <MTable
        ref="mTable"
        :columns="columns"
        :height="height"
        :set-data-method="getTableData"  
      >
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        <div slot="isEnabled" slot-scope="{ row }" class="is-enabled">
          <div :style="{background: row.isEnabled ? '#24CAD7' : '#FFCC78'}" />
          <div>{{ row.isEnabled ? '启用' : '停用' }}</div>
        </div>
        <div slot="isDefault" slot-scope="{ row }" >
            <span>{{row.isDefault ?'是': '否' }}</span>
          </div>
          <div slot="isTaskNode" slot-scope="{ row }" >
            <span>{{row.isTaskNode ? '是' : '否' }}</span>
          </div>
        <div slot="startTimeAttribute" slot-scope="{ row }">
          <div v-if="row.startTimeAttribute != null">{{ startFinishTimeAttribute[row.startTimeAttribute].name }}</div>
        </div>
        <div slot="finishTimeAttribute" slot-scope="{ row }">
          <div v-if="row.finishTimeAttribute != null">{{ startFinishTimeAttribute[row.finishTimeAttribute].name }}</div>
        </div>
        <div slot="type" slot-scope="{ row }">{{ paramsTypeName[row.type] }}</div>
        <div slot="action" slot-scope="{ row }">
          <el-button v-if="permission('Copy')" type="text" @click="modifyOrCopy(row,'copy')">复制</el-button>
          <el-divider v-if="permission('Copy')" direction="vertical" />
          <el-button v-if="permission('Modify')" type="text" @click="modifyOrCopy(row,'modify')" :disabled="row.isDefault === 1">修改</el-button>
          <el-divider v-if="permission('Modify')" direction="vertical" />
          <el-button v-if="permission('Del')" type="text" @click="del(row)" :disabled="row.isDefault === 1">删除</el-button>
          <!-- <el-divider v-if="permission('Del')" direction="vertical" /> -->
          <!-- <el-divider v-if="permission('Copy')" direction="vertical" />
                <el-button
                  v-if="permission('Default')"
                  type="text"
                  @click="setDefault(row)"
                  >{{row.isDefault ? '取消默认':'设为默认'}}</el-button
                > -->
        </div>
      </MTable>
  
      <MDialog v-model="visible" :title="messages[currentType]" @onOk="submitForm">
        <MFormBuilder ref="formBuild" :form-data="formData" :form-list="templateFormList">
          <div slot="processFlowClassName">
          <CURDSelect
            :default-value="formData.planTypeId"
            api-name="PlanType"
            @change="selectName"
          />
        </div>
        <div slot="startTime">
          <el-date-picker v-show="showStartTime"
              v-model="planStartTime"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
              style="width:100%"
              placeholder="开始时间"
              :disabled="isChooseStartAttr"
              @change="chooseStartTime"
            />
        <div style="display:flex" v-show="!showStartTime">
          <el-select v-model="startPlanId" style="width:100%" placeholder="请选择" @change="changeStartTimePlan" clearable filterable>
              <el-option
                v-for="item in planNodeTimeList"
                :key="item.id"
                :label="item.fullName"
                :value="item.id"
              />
            </el-select>
            <el-input-number style="margin-left:10px;"
              v-model="startTimeBasis"
              controls-position="right"
              class="min-input"
            />天
        </div>
        </div>
        <div slot="finishTime">
          <el-date-picker  v-show="showFinishTime"
              v-model="planFinishTime"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
              style="width:100%"
              defaultTime='23:59:59'
              placeholder="完成时间"
              :disabled="isChooseFinishAttr"
              @change="chooseFinishTime"
            />
            <div style="display:flex" v-show="!showFinishTime">
          <el-select v-model="finishPlanId" style="width:100%" placeholder="请选择" @change="changeFinishTimePlan" clearable filterable>
              <el-option
                v-for="item in planNodeTimeList"
                :key="item.id"
                :label="item.fullName"
                :value="item.id"
              />
            </el-select>
            <el-input-number style="margin-left:10px;"
              v-model="finishTimeBasis"
              controls-position="right"
              class="min-input"
            />天
        </div>
        </div>
          </MFormBuilder>
      </MDialog>
  
    </div>
  </template>
  
  <script>
  import FunctionHeader from '@/components/FunctionHeader/index'
  import conventionMixin from '@/mixin/conventional-page'
  import api from '@/api/projectManagement/task-nodes'
  import { columns } from './columns'
  import {templateFormList,getFunctionList} from './form-list'
  import CURDSelect from "@/components/CURDSelect/CURDSelect";
  import papi from '@/api/projectManagement/project';
  import {startFinishTimeAttribute } from '@/config/options.config'
  import SearchForm from '@/components/SearchForm/SearchForm'
  export default {
    name: 'TaskNodes',
    components: { FunctionHeader,CURDSelect,SearchForm },
    mixins: [conventionMixin],
    data() {
      return {
        api,
        startFinishTimeAttribute,
        params: {
          radio: 'TaskNodes'
        },
        functionHeader: {
          searchVal: '',
          tags: [
            {
              hidden: !this.permission('Add'),
              tagName: 'el-button',
              props: { type: 'primary', size: 'small' },
              style: { width: 'auto', background: '#607FFF' },
              innerText: '新增',
              on: {
                click: this.add
              }
            }
          ],
          exportName: 'exportWasteReason',
          importName: 'wasteReason',
          exportParams: {}
        },
        columns,
        visible: false,
        templateFormList,
        formData: {
          planTypeId: ""
        },
        currentType: 'add',
        formOptions: {},
        searchFieldName: 'reason',
        showFinishTime:true,
        showStartTime:true,
        planStartTime:'',
        planFinishTime:'',
        planNodeTimeList:[],
        startTimeRelationPlanId:'',
        finishTimeRelationPlanId:'',
        startTimeBasis:0,
        finishTimeBasis:0,
        startPlanId:'',
        finishPlanId:'',
        startTimeRelationTimeType:'',
        finishTimeRelationTimeType:'',
        planStartDateTime:'',
        planFinishDateTime:'',
        isChooseFinishAttr:true,
        isChooseStartAttr:true,
        planNodeId:'',
        searchFormList:[
          {
            key: 'isTaskNode',
            tagName: 'el-select',
            colSpan: 6,
            props: {
              placeholder: '是否任务节点'
            },
            children: [
              {
                tagName: 'el-option',
                props: {
                  label: 'name',
                  value: 'id'
                },
                options: [{id:1,name:'是'},{id:0,name:'否'}]
              }
            ]
         }
        ],
        searchFormData: {}
      }
    },
    computed: {
      height() {
        return this.$store.state.app.contentHeight - 192 - 44 - 10
      }
    },
    beforeMount() {
    getFunctionList({
      startTimeAttributeChange: this.startTimeAttributeChange,
      finishTimeAttributeChange:this.finishTimeAttributeChange
    })
  },
    mounted(){
      this.getBusinessUnitList()
      this.getUserList()
    },
    methods: {
      reSearch(data, val) {
        if (val === 1) {
          this.searchFormData = {}
        }
        this.$refs.mTable.setTableData(true)
      },
      async getPlanNodeTimeList(){
      const res = await api.getPlanNodeTimeList({ planId: this.currentType === 'modify' ? this.planNodeId :''})
      if (res) {
        this.planNodeTimeList = res
      }
    },
      startTimeAttributeChange(val){
      if(val || val === 0){
        this.isChooseStartAttr = false
        this.showStartTime = val === 2 ? false : true
      }else{
          this.isChooseStartAttr = true
          this.showStartTime = true
        }
        this.$refs.formBuild.setForm({
            startTime: ''
      })
    },
      finishTimeAttributeChange(val){
        if(val || val === 0){
          this.isChooseFinishAttr = false
          this.showFinishTime = val === 2 ? false : true
        }else{
          this.isChooseFinishAttr = true
          this.showFinishTime = true
        }
        this.$refs.formBuild.setForm({
            finishTime: ''
      })
      },
      chooseStartTime(){
        this.$refs.formBuild.setForm({
            startTime: this.planStartTime
      })
    },
    chooseFinishTime(){
        this.$refs.formBuild.setForm({
        finishTime: this.planFinishTime,
      })
},
      changeStartTimePlan(val){
      const currentRow = this.planNodeTimeList.find(item => item.id === val)
      if(currentRow){
        this.startTimeRelationPlanId = currentRow.planId
        this.startTimeRelationTimeType = currentRow.timeType
        this.planStartDateTime = currentRow.dateTime
      }else{
          this.startTimeRelationPlanId = ''
          this.startTimeRelationTimeType = ''
          this.planStartDateTime = ''
        }
        this.$refs.formBuild.setForm({
            startTime: this.planStartDateTime
      })
    },
      changeFinishTimePlan(val){
        const currentRow = this.planNodeTimeList.find(item => item.id === val)
        if(currentRow){
          this.finishTimeRelationPlanId = currentRow.planId
          this.finishTimeRelationTimeType = currentRow.timeType
          this.planFinishDateTime = currentRow.dateTime
        }else{
          this.finishTimeRelationPlanId = ''
          this.finishTimeRelationTimeType = ''
          this.planFinishDateTime = ''
        }
        this.$refs.formBuild.setForm({
        finishTime: this.planFinishDateTime,
      })
     },
    startTimeAttributeChange(val){
      if(val || val === 0){
        this.isChooseStartAttr = false
        this.showStartTime = val === 2 ? false : true
      }else{
          this.isChooseStartAttr = true
          this.showStartTime = true
        }
    },
      finishTimeAttributeChange(val){
        if(val || val === 0){
          this.isChooseFinishAttr = false
          this.showFinishTime = val === 2 ? false : true
        }else{
          this.isChooseFinishAttr = true
          this.showFinishTime = true
        }

      },
      async getBusinessUnitList() {
      const res = await papi.getBusinessUnitList()
      if (res) {
        this.templateFormList[2].children[0].options = res
      }
    },
    async getTableData(condition, callback) {
        const { page, search, order } = condition
        const searchCondition = {
            search: this.functionHeader.searchVal,
            isTaskNode:this.searchFormData.isTaskNode
        }
        this.functionHeader.exportParams = searchCondition
        const res = await this.api[`get${this.params.radio}ListPage`]({
          page: page.currentPage,
          size: page.pageSize,
          ...searchCondition
        })
        if (res) {
          callback({
            total: res.total,
            content: res.records
          })
        }
        callback()
      },
      // 设置是否为默认方案
      async setDefault(row) {
        const res = await api.setTaskNodesDefault({id:row.id,isDefault:row.isDefault===0 ? 1:0})
        if (res) {
          this.$message.success('操作成功')
          this.$refs.mTable.setTableData()
        }
    },
      // 获取负责人
  async getUserList() {
    const res = await papi.getResponsible()
    if (res) {
      this.templateFormList[3].children[0].options = res
      this.principalLst = res
    }
  },
  selectName(item) {
      this.$refs.formBuild.setForm({
        planTypeId: item.id,
        planTypeName: item.name,
      });
    },
      // 更多操作
      more(row, command) {
        const functions = {
          copy: () => { this.modifyOrCopy(row, command) },
          modify: () => { this.modifyOrCopy(row, command) },
          del: () => { this.del(row) }
        }
        functions[command]()
      },
      add() {
        this.formData = {};
        this.planStartTime = ''
        this.planFinishTime = ''
        this.showStartTime = true
        this.showFinishTime = true
        this.startTimeBasis = 0
        this.finishTimeBasis = 0
        this.startPlanId = ''
        this.finishPlanId = ''
        this.isChooseStartAttr = true
        this.isChooseFinishAttr = true
        this.planNodeId = ''
        this.currentType = "add"
        this.getPlanNodeTimeList()
        this.templateFormList[6].props.disabled = false
        this.visible = true;
    },
      modifyOrCopy(row, command) {
        this.formData = this._.cloneDeep(row)
        this.formData.principalId = this.formData.principalId ? this.formData.principalId.split(',').map(item => +item) : this.formData.principalId
        this.formData.principalDepartId = this.formData.principalDepartId ? this.formData.principalDepartId.split(',').map(item => +item) : this.formData.principalDepartId
        this.planNodeId = row.id
        this.planStartTime = row.startTime
        this.planFinishTime = row.finishTime
        this.startTimeBasis = row.startTimeBasis
        this.finishTimeBasis = row.finishTimeBasis
        this.startPlanId = row.startTimeRelationPlanId ? row.startTimeRelationPlanId+'-'+row.startTimeRelationTimeType:''
        this.finishPlanId = row.finishTimeRelationPlanId? row.finishTimeRelationPlanId+'-'+row.finishTimeRelationTimeType:''
        this.planStartDateTime = this.planNodeTimeList.find(item => item.id === this.startPlanId) ? this.planNodeTimeList.find(item => item.id === this.startPlanId).dateTime :''
        this.startTimeRelationPlanId = row.startTimeRelationPlanId
        this.startTimeRelationTimeType = row.startTimeRelationTimeType
        this.finishTimeRelationPlanId = row.finishTimeRelationPlanId
        this.finishTimeRelationTimeType = row.finishTimeRelationTimeType
        this.planFinishDateTime = this.planNodeTimeList.find(item => item.id === this.finishPlanId) ? this.planNodeTimeList.find(item => item.id === this.finishPlanId).dateTime :''
        this.showStartTime = this.formData.startTimeAttribute === 2 ? false :true
        this.showFinishTime = this.formData.finishTimeAttribute === 2 ? false :true
        this.isChooseFinishAttr = this.formData.finishTimeAttribute || this.formData.finishTimeAttribute === 0 ? false :true
        this.isChooseStartAttr = this.formData.startTimeAttribute || this.formData.startTimeAttribute === 0 ? false :true
        this.currentType = command
        this.getPlanNodeTimeList()
        this.templateFormList[6].props.disabled = this.currentType === 'modify' ? true : false
        this.visible = true
    },
      createSetData(raw) {
        return {
        planName: raw.planName,
        planTypeId: raw.planTypeId,
        startTimeAttribute: raw.startTimeAttribute,
        finishTimeAttribute: raw.finishTimeAttribute,
        startTime: raw.startTimeAttribute === 2 ? this.planStartDateTime: this.planStartTime,
        finishTime: raw.finishTimeAttribute === 2 ? this.planFinishDateTime:this.planFinishTime,
        principalId:raw.principalId ? raw.principalId.join(','):'',
        remark: raw.remark,
        startTimeRelationPlanId:raw.startTimeAttribute === 2 ?this.startTimeRelationPlanId :'',
        startTimeRelationTimeType:raw.startTimeAttribute === 2 ?this.startTimeRelationTimeType :'',
        startTimeBasis:raw.startTimeAttribute === 2 ? this.startTimeBasis :'',
        finishTimeRelationPlanId:raw.finishTimeAttribute === 2 ?this.finishTimeRelationPlanId :'',
        finishTimeRelationTimeType:raw.finishTimeAttribute === 2 ?this.finishTimeRelationTimeType :'',
        finishTimeBasis:raw.finishTimeAttribute === 2 ? this.finishTimeBasis :'',
        principalDepartId:raw.principalDepartId ? raw.principalDepartId.join(','):'',
        isTaskNode:raw.isTaskNode
        }
      },
      createResetData(raw) {
        const obj = {
          id: raw.id
        }
        return Object.assign(this.createSetData(raw), obj)
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
  .model-wrap {
    padding: 10px;
    .module-content >div{
              height: 34px;
            }
  }
  </style>
  