<template>
  <div class="raw-tracing">
    <div class="procedure-detail-header">
      <div class="left" @click="$router.push({ name: 'rawMaterialBatchCodeTracing' })">
        <img src="@/assets/information/procedure/左滑@2x.png">
        <span>原材料追溯详情</span>
      </div>
    </div>
    <div class="detail-card">
      <div class="card-tit">基本信息</div>
      <div class="card-bot">
        <div class="tables">
          <MTable
            ref="mTable"
            :show-page="false"
            :height="110"
            :columns="columns.BarchColumns"
            :data="barchList"
          />
        </div>
      </div>
    </div>
    <div class="detail-card">
      <div class="card-tit">投产记录</div>
      <div class="card-bot">
        <div class="tables">
          <FunctionHeader
            v-model="functionHeader.searchVal"
            :search-title="searchTitle"
            style="padding-top: 0;"
            :columns-setting="false"
            @search="getTableData()"
          />
          <MTable
            ref="mTable"
            :height="height"
            :show-page="false"
            :columns="columns.ProductionColumns"
            :data="productionList"
          >
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
            <div slot="orderNo" slot-scope="{ row }">
              <el-button type="text" @click="jumpDetail(row)">
                {{ row.produceWorkOrderNumber }}
              </el-button>
            </div>
          </MTable>
        </div>
      </div>
    </div>
    <div class="detail-card">
      <div class="card-tit">质检记录</div>
      <div class="card-bot">
        <div class="tables">
          <MTable
            ref="mTable"
            :height="height"
            :show-page="false"
            :columns="columns.QualityRecordColumns"
            :data="qualityRecordList"
          >
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
            <div slot="inspectResult" slot-scope="{ row }">{{ ['合格','不合格'][row.inspectResult] }}</div>
            <div slot="look" slot-scope="{ row }">
              <el-button type="text" @click="handleClick(row)">查看</el-button>
            </div>
          </MTable>
        </div>
      </div>
    </div>
    <!-- 查看 -->
    <scan-quality-template :visible.sync="look" :datas="lookdata" />
  </div>
</template>

<script>
import {
  BarchColumns,
  ProductionColumns,
  QualityRecordColumns
} from './columns'
import api from '@/api/quality/batch-code-tracing'
import FunctionHeader from '@/components/FunctionHeader/index'
import ScanQualityTemplate from '@/components/ScanQualityTemplate/ScanQualityTemplate'

export default {
  name: 'RawMaterialBatchCodeTracingDetail',
  components: { FunctionHeader, ScanQualityTemplate },
  data() {
    return {
      batchNumber: '',
      columns: {
        BarchColumns,
        ProductionColumns,
        QualityRecordColumns
      },
      barchList: [],
      productionList: [],
      qualityRecordList: [],
      functionHeader: {
        searchVal: ''
      },
      searchTitle: '请输入生产工单号',
      look: false,
      statue: {
        title: '',
        showdetail: false,
        drawer: false,
        islook: false
      },
      lookdata: [],
      direction: 'rtl',
      showindex: 0
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 600
    }
  },
  activated() {
    this.batchNumber = this.$route.query.batchNumber
    this.getTableData()
  },
  methods: {
    async getTableData() {
      const res = await api.getSourceBatchNumberTrace({
        batchNumber: this.batchNumber,
        workOrderNumber: this.functionHeader.searchVal
      })
      if (res) {
        const { batchNumber, qualityRecordList, recordInfoList } = res
        this.barchList = [batchNumber]
        this.productionList = recordInfoList
        this.qualityRecordList = qualityRecordList
      }
    },
    jumpDetail(row) {
      this.$router.push({
        name: 'workDetail',
        query: { id: row.produceWorkOrderId }
      })
    },
    showindexs(index) {
      this.showindex = index
    },
    handleClick(item) {
      this.look = true
      item.qualityInspectionDetails = item.qualityInspectionDetails
        ? JSON.parse(item.qualityInspectionDetails)
        : []
      item.details = item.qualityInspectionDetails
      item.name = ''
      this.lookdata = this._.cloneDeep(item)
    }
  }
}
</script>

<style lang="scss" scoped>
.procedure-detail-header{
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #41456B;
  line-height: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  .left{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  img{
    width: 8px;
    height: 12px;
    display: block;
    margin-right: 10px;
  }
}
.detail-card {
  margin-bottom: 20px;
}
.parmas {
  width: 897px;
  text-align: center;
  line-height: 45px;
  background: rgb(247, 249, 252);
}
.btns {
  width: 100%;
  text-align: left;
  text-indent: 40px;
  border-top: 1px solid #dedeee;
  background-color: rgb(247, 249, 252);
  position: relative;
    cursor: pointer;
    .tit{
      width: 150px;
      line-height: 20px;
      display: inline-block;
    }
  &:last-child {
    border-bottom: 0;
  }
}
.btn-active {
  background-color: #ffffff !important;
}
</style>
