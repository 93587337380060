<template>
  <MDialog v-model="modalShow" width="1000px" title="查看报告" :show-footer="false" @close="modalShow = false">
    <div style="font-size: 16px;text-align: center;">
      <h2>检验报告</h2>
      <div
        style="font-size: 16px;transform: scale(0.9);
   -webkit-transform: scale(0.9);text-align: left;transform-origin: 0 50%;text-decoration:underline;color: #393D60;"
      >基本信息</div>
      <div style="display:inline-flex;justify-content:flex-start;align-items:center;flex-flow:row wrap;width:100%;color: #393D60;">
        <div style="width: 33.3%;display:flex;height:30px;align-items:center;justify-content:flex-start;">
          <div
            style="width:45%;transform: scale(0.9);
  -webkit-transform: scale(0.9);font-size: 16px;text-align: left;"
          >质检单号：</div>
          <div
            style="width:55%;transform: scale(0.9);
  -webkit-transform: scale(0.9);font-size: 16px;text-align:left;transform-origin: 0 50%;word-break:break-all; "
          >{{ info.number }}</div>
        </div>
        <div v-if="info.inspectionType === 1" style="width: 33.3%;display:flex;height:30px;align-items:center;justify-content:flex-start;">
          <div
            style="width:45%;transform: scale(0.9);
  -webkit-transform: scale(0.9);font-size: 16px;text-align: left;"
          >工序：</div>
          <div
            style="width:55%;transform: scale(0.9);
  -webkit-transform: scale(0.9);font-size: 16px;text-align:left;transform-origin: 0 50%"
          >{{ info.procedureName }}</div>
        </div>
        <div v-if="info.inspectionType === 1" style="width: 33.3%;display:flex;height:30px;align-items:center;justify-content:flex-start;">
          <div
            style="width:45%;transform: scale(0.9);
  -webkit-transform: scale(0.9);font-size: 16px;text-align: left;"
          >生产工单号：</div>
          <div
            style="width:55%;transform: scale(0.9);
  -webkit-transform: scale(0.9);font-size: 16px;text-align:left;transform-origin: 0 50%; word-break:break-all;"
          >{{ info.produceWorkOrderNumber }}</div>
        </div>
        <div v-else style="width: 33.3%;display:flex;height:30px;align-items:center;justify-content:flex-start;">
          <div
            style="width:45%;transform: scale(0.9);
  -webkit-transform: scale(0.9);font-size: 16px;text-align: left;"
          >相关单号：</div>
          <div
            style="width:55%;transform: scale(0.9);
  -webkit-transform: scale(0.9);font-size: 16px;text-align:left;transform-origin: 0 50%; word-break:break-all;"
          >{{ info.relationNumber }}</div>
        </div>
        <div style="width: 33.3%;display:flex;height:30px;align-items:center;justify-content:flex-start;">
          <div
            style="width:45%;transform: scale(0.9);
  -webkit-transform: scale(0.9);font-size: 16px;text-align: left;"
          >物料编码：</div>
          <div
            style="width:55%;word-break:break-all;transform: scale(0.9);
  -webkit-transform: scale(0.9);font-size: 16px;text-align:left;transform-origin: 0 50%"
          >{{ info.materialCode }}</div>
        </div>
        <div style="width: 33.3%;display:flex;height:30px;align-items:center;justify-content:flex-start;">
          <div
            style="width:45%;transform: scale(0.9);
  -webkit-transform: scale(0.9);font-size: 16px;text-align: left;"
          >物料名称：</div>
          <div
            style="width:55%;transform: scale(0.9);
  -webkit-transform: scale(0.9);font-size: 16px;text-align:left;transform-origin: 0 50%"
          >{{ info.materialName }}</div>
        </div>
        <div v-if="info.inspectionType === 1" style="width: 33.3%;display:flex;height:30px;align-items:center;justify-content:flex-start;">
          <div
            style="width:45%;ransform: scale(0.9);
  -webkit-transform: scale(0.9);font-size: 16px;text-align: left;"
          >生产订单号：</div>
          <div
            style="width:55%;transform: scale(0.9);
  -webkit-transform: scale(0.9);font-size: 16px;text-align:left;transform-origin: 0 50%"
          >{{ info.produceOrderNumber }}</div>
        </div>
        <div style="width: 33.3%;display:flex;align-items:center;justify-content:flex-start;">
          <div
            style="width:45%;transform: scale(0.9);
  -webkit-transform: scale(0.9);font-size: 16px;text-align: left;"
          >物料规格：</div>
          <div
            style="width:55%;transform: scale(0.9);
  -webkit-transform: scale(0.9);font-size: 16px;text-align:left;transform-origin: 0 50%"
          >{{ info.materialSpecifications }}</div>
        </div>
        <div style="width: 33.3%;display:flex;height:30px;align-items:center;justify-content:flex-start;">
          <div
            style="width:45%;transform: scale(0.9);
  -webkit-transform: scale(0.9);font-size: 16px;text-align: left;"
          >质检方案：</div>
          <div
            style="width:55%;transform: scale(0.9);
  -webkit-transform: scale(0.9);font-size: 16px;text-align:left;transform-origin: 0 50%"
          >{{ info.inspectionSchemeName }}</div>
        </div>
        <div v-if="info.inspectionType === 1" style="width: 33.3%;display:flex;height:30px;align-items:center;justify-content:flex-start;">
          <div
            style="width:45%;transform: scale(0.9);
  -webkit-transform: scale(0.9);font-size: 16px;text-align: left;"
          >批次报工数量：</div>
          <div
            style="width:55%;transform: scale(0.9);
  -webkit-transform: scale(0.9);font-size: 16px;text-align:left;transform-origin: 0 50%"
          >{{ info.batchCount }}</div>
        </div>
        <div style="width: 100%;display:flex;height:30px;justify-content:flex-start;align-items:center;">
          <div
            style="width:15%;transform: scale(0.9);
  -webkit-transform: scale(0.9);font-size: 16px;text-align: left;"
          >批次码：</div>
          <div
            style="width:85%;transform: scale(0.9);
  -webkit-transform: scale(0.9);font-size: 16px;text-align:left;word-break:break-all;
 transform-origin: 0 50%"
          >{{ info.batchNumber }}</div>
        </div>
      </div>
      <div
        style="font-size: 16px;transform: scale(0.9);
   -webkit-transform: scale(0.9);text-align: left;transform-origin: 0 50%;text-decoration:underline;margin-top: 10px;color: #393D60;"
      >检验结论</div>
      <div style="display:flex;justify-content:flex-start;align-items:center;flex-flow:row wrap;width:100%;color: #393D60;">
        <div style="width: 25%;display:flex;height:30px;align-items:center;">
          <div
            style="width:50%;transform: scale(0.9);
  -webkit-transform: scale(0.9);font-size: 16px;text-align: left;"
          >合格数：</div>
          <div
            style="width:50%;transform: scale(0.9);
  -webkit-transform: scale(0.9);font-size: 16px;text-align:left;transform-origin: 0 50%"
          >{{ info.okCount }}</div>
        </div>
        <div v-if="info.ngCount" style="width: 25%;display:flex;height:30px;align-items:center;">
          <div
            style="width:50%;transform: scale(0.9);
  -webkit-transform: scale(0.9);font-size: 16px;text-align: left;"
          >不合格数：</div>
          <div
            style="width:50%;transform: scale(0.9);
  -webkit-transform: scale(0.9);font-size: 16px;text-align:left;transform-origin: 0 50%"
          >{{ info.ngCount }}</div>
        </div>
      </div>
      <div v-if="info.ngCount" style="display:flex;justify-content:flex-start;align-items:center;flex-flow:row wrap;width:100%;color: #393D60;">
        <div style="width: 50%;display:flex;height:30px;align-items:center;">
          <div
            style="width:25%;transform: scale(0.9);
  -webkit-transform: scale(0.9);font-size: 16px;text-align: left;"
          >处理意见：</div>
          <div
            style="width:75%;transform: scale(0.9);
  -webkit-transform: scale(0.9);font-size: 16px;text-align:left;transform-origin: 0 50%"
          >
            <span v-if="info.degradeCount">让步接收：{{ info.degradeCount }}；</span><span v-if="info.repairCount">返修：{{ info.repairCount }}；</span><span v-if="info.scrapCount">报废：{{ info.scrapCount }}</span></div>
        </div>
      </div>
      <div v-if="info.inspectionType === 0 && info.result === 0" style="width: 100%;display:flex;height:30px;align-items:center;justify-content:flex-start;color: #393D60;">
        <div
          style="width:15%;font-size: 16px;text-align: left;transform: scale(0.9);
  -webkit-transform: scale(0.9);"
        >不合格描述：</div>
        <div
          style="width:85%;transform: scale(0.9);
-webkit-transform: scale(0.9);font-size: 16px;text-align:left;transform-origin: 0 50%"
        >
          {{ info.ngExplain }}</div>
      </div>
      <div style="display:flex;justify-content:flex-start;align-items:center;flex-flow:row wrap;width:100%;color: #393D60;">
        <div style="width: 25%;display:flex;height:30px;align-items:center;">
          <div
            style="width:50%;transform: scale(0.9);
  -webkit-transform: scale(0.9);font-size: 16px;text-align: left;"
          >检验人：</div>
          <div
            style="width:50%;transform: scale(0.9);
  -webkit-transform: scale(0.9);font-size: 16px;text-align:left;transform-origin: 0 50%"
          >{{ info.inspectorName }}</div>
        </div>
        <div style="width: 50%;display:flex;height:30px;align-items:center;">
          <div
            style="width:50%;transform: scale(0.9);
  -webkit-transform: scale(0.9);font-size: 16px;text-align: left;"
          >完成时间：</div>
          <div
            style="width:50%;transform: scale(0.9);
  -webkit-transform: scale(0.9);font-size: 16px;text-align:left;transform-origin: 0 50%"
          >{{ info.endTime }}</div>
        </div>
      </div>
      <div
        style="font-size: 16px;transform: scale(0.9);
   -webkit-transform: scale(0.9);text-align: left;transform-origin: 0 50%;text-decoration:underline;margin-top: 10px;color: #393D60;"
      >检验数据</div>
      <table id="customers" style="border-collapse: collapse;font-family: Arial, Helvetica, sans-serif; border-collapse: collapse;width: 100%;">
        <thead>
          <tr style="height:40px;border: 1px solid #DDE3EB;padding: 8px;text-align:left;background:#F6F7F9">
            <th style="border-right: 1px solid #DDE3EB;font-size: 16px;width:50px;background:#F6F7F9;width:80px;">检验项目</th>
            <th style="border-right: 1px solid #DDE3EB;font-size: 16px;width:80px;background:#F6F7F9;width:90px;">检验条目</th>
            <th style="border-right: 1px solid #DDE3EB;font-size: 16px;width:70px;background:#F6F7F9;width:120px;" colspan="3">检验数据</th>
            <th style="border-right: 1px solid #DDE3EB;font-size: 16px;background:#F6F7F9;">标准值</th>
            <th style="border-right: 1px solid #DDE3EB;font-size: 16px;background:#F6F7F9;">上限</th>
            <th style="border-right: 1px solid #DDE3EB;font-size: 16px;background:#F6F7F9;">下限</th>
            <th style="border-right: 1px solid #DDE3EB;font-size: 16px;background:#F6F7F9;">检验方法</th>
            <th style="font-size: 16px;background:#F6F7F9;border-right: 1px solid #DDE3EB;">检验标准</th>
          </tr>
        </thead>
        <tbody id="tbodys" />
      </table>
    </div>
  </MDialog>
</template>
<script>

export default {
  name: 'PrintCode',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    commonId: {
      type: String,
      default: 'tbody'
    }
  },
  data() {
    return {
      modalShow: false,
      info: {},
      taskData: [],
      commonIds: '',
      tbody: null,
      columnsSettings: [
        {
          prop: 'result',
          label: '检验值'
        },
        {
          prop: 'resultCheck',
          label: '结论'
        },
        {
          prop: 'defectItemList',
          label: '缺陷项'
        },
        {
          prop: 'defectReasonList',
          label: '缺陷原因'
        }
      ]
    }
  },
  watch: {
    visible(val) {
      this.modalShow = val
    },
    modalShow(val) {
      if (!val) {
        this.$emit('update:visible', false)
      }
    }
  },
  methods: {
    fixArr(val) {
      if (val) {
        const data = val.map(item => item.name)
        return data.join(',')
      }
    },
    fixResult(val) {
      if (val.result) {
        const list = JSON.parse(val.result)
        if (+val.parameterType === 0 || +val.parameterType === 1 || +val.parameterType === 2) {
          const arr = list.filter(item => item)
          return arr.length > 0 ? arr.join('，') : ''
        }
        if (+val.parameterType === 4) {
          const data = list.map(item => {
            return {
              name: item.map(items => items.name)
            }
          })
          return data.map(items => items.name).join('，')
        }
        if (+val.parameterType === 3) {
          const datas = list.every(item => item.length === 0)
          return datas ? '' : list.join('，')
        }
      }
    },
    fixData(list) {
      if (list.length > 1) {
        // 循环遍历
        for (let i = 0; i < list.length; i++) {
          // 循环开始行
          let start_row
          // 需合并的行数
          let rowspan = 1
          // 循环到最后一行时
          if (i === list.length - 1) {
            // 如果最后一行和倒数第二行属性不同，则rowspan=1；否则直接结束循环
            if (list[i].inspectionProjectName !== list[i - 1].inspectionProjectName) {
              list[i].rowspan = rowspan
            }
            break
          }
          // 内层循环记录rowspan的数量
          for (let j = i; j < list.length - 1; j++) {
            // 记录循环结束的行数
            start_row = j
            // 属性相同则rowspan+1；否则直接结束内循环
            if (list[j].inspectionProjectName === list[j + 1].inspectionProjectName) {
              rowspan++
            } else {
              break
            }
          }
          // 为数组添加rowspan属性
          list[i].rowspan = rowspan
          // 控制外循环从内循环结束的行数开始
          i = start_row
        }
      } else {
        list[0].rowspan = 0
      }
      return list
    },
    async view(row) {
      this.info = this._.cloneDeep(row)
      const inspectionRecordList = []
      const inspectionList = []
      if (!row.inspectionEntryMap) return false
      const inspectionEntry = Object.values(this.info.inspectionEntryMap)
      this.inspectionRecords = inspectionEntry
      inspectionEntry.forEach((item, index) => {
        const inspectionItems = item
        inspectionRecordList.push({
          index,
          inspectionItems
        })
        if (index === 0) {
          const inspectionProjectNames = Array.from(new Set(item.map(item => item.inspectionProjectName)))
          this.projectNames = inspectionProjectNames
          inspectionProjectNames.forEach(name => {
            inspectionList.push({
              inspectionProjectName: name,
              inspectionItems: item.filter(i => i.inspectionProjectName === name)
            })
          })
        }
      })
      this.$nextTick(() => {
        const tbody = document.querySelector('#tbodys')
        tbody.innerHTML = ''
        let html = ''
        const styles = `padding:8px;border-width:1px;border-style:solid;border-color:#DDE3EB;word-break:break-all;text-align:left;color:#393D60;font-size:16px;`
        inspectionList.forEach((inspection, inspectionIndex) => {
          inspection.inspectionItems.forEach((items, itemsIndex) => {
            inspectionRecordList.forEach((record, recordIndex) => {
              this.columnsSettings.forEach((columns, columnsIndex) => {
                html += '<tr>'
                if (itemsIndex === 0 && columnsIndex === 0 && recordIndex === 0) {
                  html += `<td style=${styles} rowspan="${inspection.inspectionItems.length * 4 * inspectionRecordList.length}">${inspection.inspectionProjectName}</td>`
                }
                if (columnsIndex === 0 && recordIndex === 0) {
                  html += `<td style=${styles} rowspan="${4 * inspectionRecordList.length}">${items.inspectionEntryName}</td>`
                }
                if (columnsIndex === 0) {
                  html += `<td style=${styles} rowspan="${4}">第${record.index + 1}件</td>`
                }
                const currentEntry = record.inspectionItems.find(a => a.inspectionEntryName === items.inspectionEntryName)
                html += `<td style=${styles}>${columns.label}</td>`
                if (columns.prop === 'defectItemList' || columns.prop === 'defectReasonList') {
                  html += `<td style=${styles}>${currentEntry[columns.prop] ? currentEntry[columns.prop].map(i => i.name).join(',') : ''}</td>`
                } else if (columns.prop === 'resultCheck') {
                  html += `<td style=${styles}>${currentEntry[columns.prop] === 0 ? '次' : currentEntry[columns.prop] === 1 ? '良' : ''}</td>`
                } else {
                  html += `<td style=${styles}>${currentEntry[columns.prop] || ''}</td>`
                }
                if (columnsIndex === 0 && recordIndex === 0) {
                  html += `<td style=${styles} rowspan="${4 * inspectionRecordList.length}">${items.standardValue || ''}</td>`
                  html += `<td style=${styles} rowspan="${4 * inspectionRecordList.length}">${items.upperDeviation || ''}</td>`
                  html += `<td style=${styles} rowspan="${4 * inspectionRecordList.length}">${items.lowerDeviation || ''}</td>`
                  html += `<td style=${styles} rowspan="${4 * inspectionRecordList.length}">${items.inspectionMethod || ''}</td>`
                  html += `<td style=${styles} rowspan="${4 * inspectionRecordList.length}">${items.inspectionStandard || ''}</td>`
                }
                html += '</tr>'
              })
            })
          })
        })
        tbody.innerHTML = html
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.mubai-modal-wrap .el-dialog__body {
  padding: 0 0 30px 20px;
}
.mubai-modal-wrap .el-dialog__header {
  padding: 20px 20px 0 20px;
}
