var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "procedure-detail-wrapper" }, [
    _c("div", { staticClass: "procedure-detail-header flex-sbc" }, [
      _c(
        "div",
        {
          staticClass: "left",
          staticStyle: { "line-height": "32px" },
          on: {
            click: function($event) {
              return _vm.$router.push({ name: "qualityInspectionOrder" })
            }
          }
        },
        [
          _c("img", {
            staticStyle: { width: "8px" },
            attrs: {
              src: require("@/assets/information/procedure/左滑@2x.png")
            }
          }),
          _c(
            "span",
            { staticStyle: { "padding-left": "10px", cursor: "pointer" } },
            [_vm._v(_vm._s(_vm.messages.add))]
          )
        ]
      ),
      _c(
        "div",
        [
          _vm.baseFormData.showCheck
            ? _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.toCheck }
                },
                [_vm._v("审核")]
              )
            : _vm._e(),
          _vm.baseFormData.showApprove
            ? _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.toApprove }
                },
                [_vm._v("审批")]
              )
            : _vm._e()
        ],
        1
      )
    ]),
    _c("div", { staticClass: "procedure-detail-body" }, [
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _c(
              "el-row",
              { staticClass: "view-list", attrs: { gutter: 24 } },
              _vm._l(
                _vm.baseFormData.inspectionType === 1
                  ? _vm.columns.base
                  : _vm.columns.incomming,
                function(item, index) {
                  return _c(
                    "el-col",
                    {
                      key: item.prop,
                      style: {
                        marginBottom: index !== 4 ? "20px" : 0,
                        lineHeight: "22px"
                      },
                      attrs: {
                        span:
                          item.prop === "inspectionSchemeAttachments" ? 24 : 6
                      }
                    },
                    [
                      _c("span", { staticClass: "bot-tis" }, [
                        _vm._v(_vm._s(item.label) + "：")
                      ]),
                      item.prop === "status"
                        ? _c(
                            "span",
                            {
                              staticClass: "faultStatus",
                              style: {
                                background: _vm.qualityStatus[
                                  _vm.getValue(item)
                                ]
                                  ? _vm.qualityStatus[_vm.getValue(item)]
                                      .background
                                  : "rgba(236, 238, 242, .1)",
                                color: _vm.qualityStatus[_vm.getValue(item)]
                                  ? _vm.qualityStatus[_vm.getValue(item)].color
                                  : "#393D60;"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.qualityStatus[_vm.getValue(item)]
                                      ? _vm.qualityStatus[_vm.getValue(item)]
                                          .name
                                      : ""
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : item.prop === "inspectionSchemeAttachments"
                        ? _c(
                            "span",
                            _vm._l(_vm.fixArrs(_vm.getValue(item)), function(
                              items,
                              idx
                            ) {
                              return _c(
                                "a",
                                {
                                  key: items.id,
                                  staticStyle: { color: "#0091FF" },
                                  attrs: { href: items.url, target: "_blank" }
                                },
                                [
                                  _vm._v(" " + _vm._s(items.name) + " "),
                                  idx <
                                  _vm.fixArrs(_vm.getValue(item)).length - 1
                                    ? _c("span", [_vm._v(",")])
                                    : _vm._e()
                                ]
                              )
                            }),
                            0
                          )
                        : _c("span", { staticClass: "bot-tis" }, [
                            _vm._v(_vm._s(_vm.getValue(item)))
                          ])
                    ]
                  )
                }
              ),
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          staticClass: "detail-card detail-le",
          style: { width: 0 ? "calc(100% - 332px)" : "100%" }
        },
        [
          _c("div", { staticClass: "card-tit" }, [_vm._v("检验结果")]),
          _c(
            "div",
            { staticClass: "card-bot" },
            [
              _c(
                "el-row",
                { staticClass: "view-list", attrs: { gutter: 24 } },
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("span", { staticClass: "bot-tis" }, [
                      _vm._v("合格数量：")
                    ]),
                    _c("span", { staticClass: "bot-tis" }, [
                      _vm._v(_vm._s(_vm.baseFormData.okCount))
                    ])
                  ]),
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("span", { staticClass: "bot-tis" }, [
                      _vm._v("不合格数量：")
                    ]),
                    _c("span", { staticClass: "bot-tis" }, [
                      _vm._v(_vm._s(_vm.baseFormData.ngCount))
                    ])
                  ]),
                  _vm.baseFormData.ngCount
                    ? _c("el-col", { attrs: { span: 4 } }, [
                        _vm.baseFormData.degradeCount
                          ? _c("div", [
                              _c("span", { staticClass: "bot-tis" }, [
                                _vm._v("让步接收：")
                              ]),
                              _c("span", { staticClass: "bot-tis" }, [
                                _vm._v(_vm._s(_vm.baseFormData.degradeCount))
                              ])
                            ])
                          : _vm._e(),
                        _vm.baseFormData.repairCount
                          ? _c(
                              "div",
                              { staticStyle: { "padding-top": "5px" } },
                              [
                                _c("span", { staticClass: "bot-tis" }, [
                                  _vm._v("返修数量：")
                                ]),
                                _c("span", { staticClass: "bot-tis" }, [
                                  _vm._v(_vm._s(_vm.baseFormData.repairCount))
                                ])
                              ]
                            )
                          : _vm._e(),
                        _vm.baseFormData.scrapCount
                          ? _c(
                              "div",
                              { staticStyle: { "padding-top": "5px" } },
                              [
                                _c("span", { staticClass: "bot-tis" }, [
                                  _vm._v("报废数量：")
                                ]),
                                _c("span", { staticClass: "bot-tis" }, [
                                  _vm._v(_vm._s(_vm.baseFormData.scrapCount))
                                ])
                              ]
                            )
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c("span", { staticClass: "bot-tis" }, [
                      _vm._v("检验人：")
                    ]),
                    _c("span", { staticClass: "bot-tis" }, [
                      _vm._v(_vm._s(_vm.baseFormData.inspectorName))
                    ])
                  ]),
                  _vm.baseFormData.startTime
                    ? _c("el-col", { attrs: { span: 5 } }, [
                        _c("span", { staticClass: "bot-tis" }, [
                          _vm._v("检验开始时间：")
                        ]),
                        _c("span", { staticClass: "bot-tis" }, [
                          _vm._v(
                            _vm._s(
                              _vm.formDateToMinutes(_vm.baseFormData.startTime)
                            )
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.baseFormData.endTime
                    ? _c("el-col", { attrs: { span: 5 } }, [
                        _c("span", { staticClass: "bot-tis" }, [
                          _vm._v("检验结束时间：")
                        ]),
                        _c("span", { staticClass: "bot-tis" }, [
                          _vm._v(
                            _vm._s(
                              _vm.formDateToMinutes(_vm.baseFormData.endTime)
                            )
                          )
                        ])
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "detail-card detail-le",
          style: { width: 0 ? "calc(100% - 332px)" : "100%" }
        },
        [
          _c("div", { staticClass: "card-tit" }, [_vm._v("检验记录")]),
          _c(
            "div",
            { staticClass: "card-bot" },
            [
              _c("inspection-record", {
                attrs: { "base-form-data": _vm.baseFormData }
              })
            ],
            1
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }