export const searchFormList = [
    {
      colSpan: 4,
      key: 'procedure',
      tagName: 'el-input',
      attrs: {
        placeholder: '工序定位码/编码/名称'
      }
    },
    {
      colSpan: 4,
      key: 'specifications',
      tagName: 'el-input',
      defaultValue: 1,
      attrs: {
        placeholder: '规格'
      }
    }
  ]
  
  // 列表
  export const columnsAll = [
    {
      prop: 'index',
      label: '序号',
      slotName: 'index',
      hiddenSearch: true,
      fixed: 'left',
      width: 60
    },
    {
      prop: 'procedureLocationCode',
      label: '工序定位码',
      sortable: false,
      hiddenSearch: true,
      width: 120
    },
    {
      label: '工序名称',
      sortable: false,
      prop: 'procedureName',
      hiddenSearch: true,
      width: 120
    },
    {
      prop: 'procedureCode',
      label: '工序编码',
      sortable: false,
      slotName: 'procedureCode',
      hiddenSearch: true,
      width: 120
    },
    {
      prop: 'materialsCode',
      label: '物料编码',
      sortable: false,
      slotName: 'materialsCode',
      hiddenSearch: true,
      width: 120
    },
    {
      prop: 'materialsName',
      label: '物料名称',
      sortable: false,
      hiddenSearch: true,
      width: 120
    },
    {
      prop: 'specifications',
      label: '规格',
      sortable: false,
      hiddenSearch: true,
      width: 120
    },
    {
      prop: 'mainUnitName',
      label: '单位',
      hiddenSearch: true,
      sortable: false,
      width: 120
    },
    {
      prop: 'grossQuantity',
      label: '毛需求',
      hiddenSearch: true,
      sortable: false,
      width: 120
    },
    {
      prop: 'currentStock',
      label: '当前库存',
      hiddenSearch: true,
      sortable: false,
      width: 120
    },
    {
      prop: 'availableStock',
      label: '可用库存',
      hiddenSearch: true,
      sortable: false,
      width: 120
    },
    {
      prop: 'lockedStock',
      slotName: 'lockedStock',
      label: '已占用库存',
      hiddenSearch: true,
      sortable: false,
      width: 120
    },
    {
      prop: 'reserveQuantity',
      label: '待制量',
      hiddenSearch: true,
      sortable: false,
      width: 120
    },
    {
      prop: 'makingQuantity',
      label: '在制量',
      hiddenSearch: true,
      sortable: false,
      width: 120
    },
    {
      prop: 'netRequireQuantity',
      label: '净需求',
      hiddenSearch: true,
      sortable: false,
      width: 120
    }
  ]
  
  // 新增生产任务表单字段
  export const addTaskForm = [
    {
      label: '生产任务号',
      key: 'code',
      tagName: 'el-input',
      colSpan: 5,
      required: true,
      attrs: {
        placeholder: '生产任务号',
        disabled: true
      }
    },
    {
      label: '所属生产工单',
      key: 'producePlanNumber',
      tagName: 'el-input',
      colSpan: 5,
      attrs: {
        disabled: true
      }
    },
    {
      label: '计划开始时间',
      key: 'planStartTime',
      tagName: 'el-input',
      colSpan: 5,
      attrs: {
        disabled: true
      }
    },
    {
      label: '计划结束时间',
      key: 'planEndTime',
      tagName: 'el-input',
      colSpan: 5,
      attrs: {
        disabled: true
      }
    },
    {
      label: '工序定位码',
      key: 'procedureLocationCode',
      tagName: 'el-input',
      colSpan: 5,
      attrs: {
        disabled: true
      }
    },
    {
      label: '工序编码',
      key: 'procedureCode',
      tagName: 'el-input',
      colSpan: 5,
      attrs: {
        disabled: true
      }
    },
    {
      label: '工序名称',
      key: 'procedureName',
      tagName: 'el-input',
      colSpan: 5,
      attrs: {
        disabled: true
      }
    },
    {
      label: '计划时间',
      key: 'planTime',
      colSpan: 5,
      tagName: 'el-date-picker',
      required: true,
      props: {
        rangeSeparator: '至',
        startPlaceholder: '开工日期',
        endPlaceholder: '完工日期',
        align: 'center',
        type: 'datetimerange',
        valueFormat: 'yyyy-MM-dd HH:mm:ss',
        format: 'yyyy-MM-dd HH:mm:ss',
        defaultTime: ['00:00:00', '23:59:59']
      }
    },
    {
      label: '备注',
      key: 'remark',
      tagName: 'el-input',
      colSpan: 5,
      props: { type: 'textarea', maxlength: 500 }
    }
  ]
  
  // 新增生产任务列表字段
  export const addTaskColumn = [
    {
      prop: 'materialsCode',
      label: '物料编码',
      sortable: false,
      slotName: 'materialsCode',
      hiddenSearch: true
    },
    {
      prop: 'materialsName',
      label: '物料名称',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'specifications',
      label: '规格',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'mainUnitName',
      label: '单位',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'netRequireQuantity',
      label: '净需求',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'planOutputQuantity',
      label: '计划产量',
      slotName: 'planOutputQuantity',
      hiddenSearch: true,
      sortable: false
    }
  ]
  
  // 节点信息列表字段
  export const scheduleColumn = [
    {
      prop: 'index',
      label: '序号',
      slotName: 'index',
      hiddenSearch: true,
      fixed: 'left',
      type: '',
      width: 60
    },
    {
      prop: 'planName',
      label: '节点名称',
      sortable: false,
      hiddenSearch: true,
      slotName:'planName'
    },
    {  
      prop: 'planTypeName',
      label: '节点类型',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'isTaskNode',
      label: '是否任务节点',
      sortable: false,
      slotName:'isTaskNode',
      hiddenSearch: true
    },
    {
      prop: 'startTimeAttribute',
      label: '开始时间属性',
      sortable: false,
      hiddenSearch: true,
      slotName: 'startTimeAttribute'
    },
    {
      prop: 'startTime',
      label: '计划开始时间',
      sortable: false,
      hiddenSearch: true,
      slotName:'startTime'
    },
    {
      prop: 'finishTimeAttribute',
      label: '结束时间属性',
      sortable: false,
      hiddenSearch: true,
      slotName: 'finishTimeAttribute'
    },
    {
      prop: 'finishTime',
      label: '计划结束时间',
      sortable: false,
      hiddenSearch: true,
      slotName:'finishTime'
    },
    {
      prop: 'principalDepartName',
      label: '负责部门',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'principalName',
      label: '负责人',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'remark',
      label: '备注',
      sortable: false,
      hiddenSearch: true
    },
    {
      slotName: 'action',
      label: '操作',
      fixed: 'right',
      sortable: false,
      hiddenSearch: true,
      width: 160
    }
  ]
    // 节点信息列表字段
    export const planNodesColumn = [
      {
        prop: 'index',
        label: '序号',
        slotName: 'index',
        hiddenSearch: true,
        fixed: 'left',
        type: '',
        width: 60
      },
      {
        prop: 'planName',
        label: '任务节点名称',
        sortable: false,
        hiddenSearch: true,
        slotName:'planName'
      },
      {
        prop: 'planTypeName',
        label: '任务节点类型',
        sortable: false,
        hiddenSearch: true
      },
      {
        prop: 'startTimeAttribute',
        label: '开始时间属性',
        sortable: false,
        hiddenSearch: true,
        slotName: 'startTimeAttribute'
      },
      {
        prop: 'finishTimeAttribute',
        label: '结束时间属性',
        sortable: false,
        hiddenSearch: true,
        slotName: 'finishTimeAttribute'
      },
      {
        prop: 'remark',
        label: '备注',
        sortable: false,
        hiddenSearch: true
      },
      {
        slotName: 'action',
        label: '操作',
        fixed: 'right',
        sortable: false,
        hiddenSearch: true,
        width: 160
      }
    ]
  // 生产任务表单字段
  export const taskFormList = [
    {
      colSpan: 4,
      key: 'code',
      tagName: 'el-input',
      attrs: {
        placeholder: '生产任务号'
      }
    },
    {
      colSpan: 4,
      key: 'producePlanNumber',
      tagName: 'el-input',
      attrs: {
        placeholder: '生产工单'
      }
    },
    {
      colSpan: 4,
      key: 'materials',
      tagName: 'el-input',
      attrs: {
        placeholder: '物料编号/名称'
      }
    },
    {
      colSpan: 4,
      key: 'productionStatus',
      tagName: 'el-select',
      props: {
        multiple: true,
        placeholder: '生产状态'
      },
      children: [
        {
          tagName: 'el-option',
          props: {
            label: 'name',
            value: 'id'
          },
          options: [
            {
              name: '待开始', id: 0
            },
            {
              name: '进行中', id: 1
            },
            {
              name: '已完成', id: 2
            }
          ]
        }
      ]
    },
    {
      colSpan: 4,
      key: 'assignStatus',
      tagName: 'el-select',
      props: {
        multiple: true,
        placeholder: '是否已派工'
      },
      children: [
        {
          tagName: 'el-option',
          props: {
            label: 'name',
            value: 'id'
          },
          options: [
            {
              name: '未派工', id: 0
            },
            {
              name: '已派工', id: 1
            }
          ]
        }
      ]
    },
    {
      colSpan: 8,
      key: 'planStartTime',
      tagName: 'el-date-picker',
      props: {
        rangeSeparator: '至',
        startPlaceholder: '计划开工开始时间',
        endPlaceholder: '结束时间',
        align: 'center',
        type: 'daterange',
        valueFormat: 'yyyy-MM-dd HH:mm:ss',
        format: 'yyyy-MM-dd HH:mm:ss',
        defaultTime: ['00:00:00', '23:59:59']
      }
    },
    {
      colSpan: 8,
      key: 'planEndTime',
      tagName: 'el-date-picker',
      props: {
        rangeSeparator: '至',
        startPlaceholder: '计划完工开始时间',
        endPlaceholder: '结束时间',
        align: 'center',
        type: 'daterange',
        valueFormat: 'yyyy-MM-dd HH:mm:ss',
        format: 'yyyy-MM-dd HH:mm:ss',
        defaultTime: ['00:00:00', '23:59:59']
      }
    }
  ]
  
  // 生产任务列表字段
  export const productionTaskColumn = [
    {
      type: 'selection',
      width: 50,
      fixed: 'left',
      reserveSelection: true
    },
    {
      prop: 'index',
      label: '序号',
      slotName: 'index',
      hiddenSearch: true,
      fixed: 'left',
      width: 60
    },
    {
      prop: 'code',
      label: '生产任务号',
      sortable: false,
      hiddenSearch: true,
      width: 120
    },
    {
      prop: 'productionStatus',
      label: '生产状态',
      sortable: false,
      hiddenSearch: true,
      slotName: 'productionStatus',
      width: 80
    },
    {
      prop: 'producePlanNumber',
      label: '所属生产工单',
      sortable: false,
      hiddenSearch: true,
      width: 120
    },
    // {
    //   prop: 'stockingRateKeyMaterials',
    //   label: '备料率-关键料',
    //   sortable: false,
    //   hiddenSearch: true,
    //   width: 120
    // },
    // {
    //   prop: 'stockingRateComprehensive',
    //   label: '备料率-综合',
    //   sortable: false,
    //   hiddenSearch: true,
    //   width: 120
    // },
    {
      prop: 'procedureLocationCode',
      label: '工序定位码',
      sortable: false,
      hiddenSearch: true,
      width: 120
    },
    {
      label: '工序名称',
      sortable: false,
      prop: 'procedureName',
      hiddenSearch: true,
      width: 120
    },
    {
      prop: 'procedureCode',
      label: '工序编码',
      sortable: false,
      slotName: 'procedureCode',
      hiddenSearch: true,
      width: 120
    },
    {
      prop: 'materialsCode',
      label: '物料编码',
      sortable: false,
      slotName: 'materialsCode',
      hiddenSearch: true,
      width: 120
    },
    {
      prop: 'materialsName',
      label: '物料名称',
      sortable: false,
      hiddenSearch: true,
      width: 120
    },
    {
      prop: 'specifications',
      label: '规格',
      sortable: false,
      hiddenSearch: true,
      width: 120
    },
    {
      prop: 'mainUnitName',
      label: '单位',
      hiddenSearch: true,
      sortable: false,
      width: 120
    },
    {
      prop: 'planOutputQuantity',
      slotName: 'planOutputQuantity',
      label: '计划产量',
      hiddenSearch: true,
      sortable: false,
      width: 140
    },
    {
      prop: 'planTime',
      slotName: 'planTime',
      label: '计划开工时间-完工时间',
      hiddenSearch: true,
      sortable: false,
      width: 350
    },
    {
      prop: 'grossQuantity',
      label: '备注',
      hiddenSearch: true,
      sortable: false,
      width: 120,
      slotName: 'remark'
    },
    {
      prop: 'action',
      label: '操作',
      hiddenSearch: true,
      sortable: false,
      width: 160,
      slotName: 'action',
      fixed: 'right'
    }
  ]
  
  // 待定需求列表
  export const waitDemandColumns = [
    {
      prop: 'procedureLocationCode',
      label: '工序定位码',
      hiddenSearch: true,
      sortable: false,
      minWidth: 140
    },
    {
      prop: 'procedureCode',
      label: '工序编码',
      sortable: false,
      slotName: 'procedureCode',
      hiddenSearch: true,
      minWidth: 100
    },
    {
      prop: 'undeterminedRequireQuantity',
      label: '待定需求量',
      hiddenSearch: true,
      sortable: false,
      minWidth: 100
    },
    {
      prop: 'availableStock',
      label: '可用库存',
      hiddenSearch: true,
      sortable: false,
      minWidth: 100
    },
    {
      prop: 'makingQuantity',
      label: '在制量',
      hiddenSearch: true,
      sortable: false,
      minWidth: 100
    },
    {
      props: 'productionQuantity',
      slotName: 'productionQuantity',
      label: '计划生产',
      hiddenSearch: true,
      sortable: false,
      minWidth: 140
    },
    {
      props: 'purchaseQuantity',
      slotName: 'purchaseQuantity',
      label: '计划采购',
      hiddenSearch: true,
      sortable: false,
      minWidth: 140
    },
    {
      prop: 'procedureName',
      label: '工序名称',
      hiddenSearch: true,
      sortable: false,
      minWidth: 150
    },
    {
      prop: 'materialsCode',
      label: '物料编号',
      hiddenSearch: true,
      sortable: false,
      slotName: 'materialsCode',
      minWidth: 100
    },
    {
      prop: 'materialsName',
      label: '物料名称',
      hiddenSearch: true,
      sortable: false,
      minWidth: 150
    },
    {
      prop: 'specifications',
      label: '规格',
      hiddenSearch: true,
      sortable: false,
      minWidth: 100
    },
    {
      prop: 'mainUnitName',
      label: '单位',
      hiddenSearch: true,
      sortable: false,
      minWidth: 80
    }
  ]
  