<template>
    <div class="materials-wrap">
      <div class="bottom">
        <Split :offset.sync="split.offset" :height="splitHeight">
          <template slot="left">
            <RequirementsClass @nodeClick="setMaterialsClassId" :view="true"/>
          </template>
          <template slot="right">
            <div class="materials-info">
              <FunctionHeader
                ref="functionHeader"
                v-model="functionHeader.searchVal"
                search-title="单据编码"
                :tags="(materialsClassId === 0 || materialsClassId === 1) ? functionHeader.tags : []"
                @search="$refs.mTable.setTableData(true)"
              >
              <div slot="all">
                <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
                  已选 
                  <span style="color:#607FFF">{{  exportSelectionIds.length }}</span>
                  / {{ total }}项
                </span>
        <el-button type="text" size="small" @click="resetSelectData" style="margin-top:-4px;">清空</el-button>
            </div>
              </FunctionHeader>
              <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData" :onlyKey="'requirementCode'" @selection-change="selectionChange">
                <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
                <div slot="requirementType" slot-scope="{ row }">
                  {{ row.requirementType ? row.requirementType === 1 ? '备库需求':'预测需求':'销售订单' }}
                </div>
                <div slot="deliveryType" slot-scope="{ row }">
                  {{ row.deliveryType ? '分批交付':'统一交付' }}
                </div>
              </MTable>
            </div>
          </template>
        </Split>
      </div>
    </div>
  </template>
  
  <script>
  import FunctionHeader from '@/components/FunctionHeader'
  import SearchForm from '@/components/SearchForm/SearchForm'
  import { columns } from './columns'
  import { searchFormList } from './form-list'
  import { getUUid } from '@/utils'
  import { Encrypt } from '@/utils/sercet'
  import Split from '@/components/Split/Split'
  import RequirementsClass from './RequirementsClass'
  import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
  import batchExportFront from '@/mixin/batch-export-front'
  import fixCommonPage from '@/mixin/fix-common-page'
  import api from '@/api/projectManagement/project'
  
  export default {
    name: 'MaterialsList',
    components: { NewPageOpen, RequirementsClass, Split, FunctionHeader, SearchForm },
    mixins: [batchExportFront, fixCommonPage],
    data() {
      return {
        functionHeader: {
          searchVal: '',
          tags: [
            {
              tagName: 'el-button',
              props: { type: 'primary', size: 'small' },
              style: { width: 'auto', background: '#607FFF' },
              innerText: '新增',
              on: {
                click: this.add
              }
            }
          ],
          importName: 'materials',
          exportName: 'exportMaterials',
          exportParams: {}
        },
        columns,
        searchFormList,
        searchFormData: {},
        searchFormatData: [],
        split: {
          offset: 15
        },
        materialsClassId: '',
        defaultSearch: {
          searchVal: Encrypt(JSON.stringify(
            [{
              id: getUUid(),
              fieldName: 'isEnabled',
              fieldType: 'number',
              fieldValue: 1,
              operator: 'eq'
            }]
          ))
        },
        total:0
      }
    },
    computed: {
      height() {
        return this.$store.state.app.contentHeight - 235 - 88 - 60
      },
      splitHeight() {
        return this.$store.state.app.contentHeight - 235 - 25 - 60
      }
    },
    beforeRouteLeave(to, from, next) {
      if (to.name !== 'refresh') {
        this.$store.dispatch('tagsView/addCachedView', from)
      }
      next()
    },
    // beforeMount() {
    //   getFunctionList({
    //     allCodeChange: this.allCodeChange
    //   })
    // },
    mounted() {
    },
    methods: {
        selectionChange(data) {
            this.exportSelectionList = data
            this.exportSelectionIds = data.map(item => item.id)
            this.$emit('chooseRequirements', data)
        },
      resetSelectData() {
        this.exportSelectionList.forEach(item => {
          if (!item.selectable) {
            this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
          }
        })
        this.exportSelectionList = this.exportSelectionList.filter((item) => item.selectable)
      },
      setMaterialsClassId(id) {
        this.materialsClassId = id
        this.$refs.mTable.setTableData(true)
      },
      // 获取物料数据
      async getTableData(condition, callback) {
        const { page } = condition
        const searchCondition = {
          requirementType: this.materialsClassId,
          search: this.functionHeader.searchVal,
        }
        this.functionHeader.exportParams = searchCondition
        const res = await api.getProjectRelateRequirementListPage({
          page: page.currentPage,
          size: page.pageSize,
          ...searchCondition
        })
        if (res) {
          callback({
            total: res.total,
            content: res.records
          })
          this.total = res.total
        }
        callback()
      },
      // 查询物料
      searchTable(data) {
        this.searchFormatData = data
        this.$refs.mTable.setTableData(true)
      },
     async add() {
        await this.$store.dispatch('tagsView/addCachedView', this.$route)
        if(this.materialsClassId === 0){
        localStorage.setItem('salesOrderAddType', 1)
         this.$router.push({ name: 'salesOrderAdd', query: { type:'add'}})
      }else{
        localStorage.setItem('reserveStockOrderAddType', 1)
        this.$router.push({ name: 'reserveStockOrderAdd', query: { type:'add'}})
      }
      },
      // 更多操作
      more(row, command) {
        const functions = {
          modify: () => {
            this.modifyOrCopy(row, command)
          },
          copy: () => {
            this.modifyOrCopy(row, command)
          },
          del: () => {
            this.del(row)
          },
          switch: () => {
            this.switch(row)
          }
        }
        functions[command]()
      },
      modifyOrCopy(row, command) {
        this.$router.push({ name: 'materialsDetail', query: { id: row.id, type: command }})
      },
      async batchDel() {
        const res = await api.delBatchMaterials(this.exportSelectionIds)
        if (res) {
          this.$message.success('删除成功')
          this.$refs.mTable.tableRefs().clearSelection()
          this.$refs.mTable.setTableData(true)
        }
      },
      // 删除物料
      del(row) {
        this.currentType = 'del'
        this.$confirm('您确定要删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          const res = await api.delMaterials({ id: row.id })
          if (res) {
            this.$message.success('删除成功')
            this.$refs.mTable.setTableData()
          }
        })
      },
      // 更改物料状态
      async switch(row) {
        const res = await api.switchStatus(row.isEnabled ? 'disable' : 'enable', 'materials', row.id)
        if (res) {
          this.$message.success(`${row.name}${row.isEnabled ? '停用' : '启用'}成功`)
          this.$refs.mTable.setTableData()
        }
      },
      viewDetail(row) {
        this.$message.info('敬请期待！')
      },
      onSuccess() {
        this.$refs.mTable.setTableData()
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
  .function-header-wrap {
    padding-top: 0;
  }
  
  .materials-wrap {
    width: 100%;
  
    .bottom {
      width: 100%;
      height: 100%;
      padding: 10px;
  
      .materials-info {
        padding: 10px;
      }
    }
  }
  .drawing-img {
    width: 50px;
    height: 48px;
    display: block;
    cursor: pointer;
    color: #fff;
  }
  ::v-deep {
    .params-card {
      .body {
        padding: 0 !important;
      }
      .params {
        width: 100% !important;
      }
      .el-form-item__label {
        padding: 0 12px 0 0 !important;
      }
    }
  }
  </style>  