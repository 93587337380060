<template>
  <div class="inspection-plan-detail-wrapper">
    <div class="inspection-plan-detail-header flex-sbc">
      <div class="left" @click="$router.back()">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px">
        <span style="padding-left: 10px;cursor: pointer">采购订单详情</span>
      </div>
    </div>
    <div class="inspection-plan-detail-body">
      <div class="detail-card">
        <div class="card-tit">基本信息</div>
        <div class="card-bot">
          <el-row class="view-list" :gutter="24">
            <!--            暂时这么处理距离问题-->
            <template v-for="(item, index) in detailColumns">
              <el-col
                v-if="item.label"
                :key="item.key"
                :span="6"
                :style="{marginBottom: index !== 4 ? '20px' : 0 }"
              >
                <span style="color: #9597AE;">{{ item.label }}：</span>
                <span class="bot-tis">{{ getValue(item.key) }}</span>
              </el-col>
            </template>
          </el-row>
        </div>
      </div>
      <div class="detail-card">
        <div class="card-tit">采购订单行</div>
        <div class="card-bot card-bot2">
          <FunctionHeader
            ref="functionHeader"
            style="padding: 10px 0 10px 0;height:40px"
            :hidden-search="true"
          />
          <MTable
            :height="500"
            :columns="detailListColumns"
            :show-page="false"
            :data="detail.orderItemList||[]"
            :only-key="'uuid'"
          >
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
            <div slot="materialsCode" slot-scope="{ row }">
              <NewPageOpen :path="{ name: 'materialsDetail', query: {id: row.materialsId}}" :text="row.code" />
            </div>
            <div slot="receivedProgress" slot-scope="{row}">
              {{ row.receivedProgress }}%
            </div>
          </MTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader'
import api from '@/api/production/purchaseManage'
import { detailColumns, detailListColumns, orderStatusMap, receivedStatusMap } from './data'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
export default {
  name: 'PurchaseOrderDetail',
  components: { NewPageOpen, FunctionHeader },
  data() {
    return {
      detailColumns,
      detailListColumns,
      detail: {},
      activeName: 'billOfGoodsList'
    }
  },
  created() {
    this.getDetail()
  },
  mounted() {
  },
  methods: {
    async getDetail() {
      const res = await api.viewDetail({ id: this.$route.query.id })
      if (res) {
        this.detail = res
      }
    },

    getValue(key) {
      const result = this.detail[key]
      if (key === 'receivedStatus') return receivedStatusMap[this.detail[key]]
      if (key === 'orderStatus') return orderStatusMap[this.detail[key]]
      return result
    }
  }
}
</script>

<style scoped lang="scss">
.inspection-plan-detail-wrapper {
  background-color: #f0f2f5;
  height: 100%;
  .card-bot2 {
    height: 520px;
  }

  .inspection-plan-detail-header {
    width: 100%;
    padding: 10px 0;
  }

  .inspection-plan-detail-body {
    height: calc(100% - 60px);
    overflow-y: auto;

    .detail-card {
      margin-bottom: 20px;
    }
  }
  .function-header-wrap {
    padding-top: 0;
  }
}
</style>
