<template>
    <div class="orderAdd">
      <div class="detail-card">
        <div class="card-tit">基本信息</div>
        <div class="card-bot">
          <el-form
            v-if="['modify','addOrder','copy'].includes($route.query.type)"
            ref="ruleForm"
            label-position="right"
            :model="ruleForm"
            :rules="rules"
            label-width="135px"
            class="demo-form-inline"
          >
            <el-row>
              <el-col :span="6">
                <el-form-item label="项目计划方案名称" prop="name">
                  <el-input
                    v-model="ruleForm.name"
                    placeholder=""
                  />
                </el-form-item>
              </el-col>
  
              <el-col :span="6">
                <el-form-item label="项目计划方案状态" prop="isEnabled">
                  <el-select v-model="ruleForm.isEnabled" placeholder="请选择">
                    <el-option
                      v-for="item in status"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="备注" >
                  <el-input
                    v-model="ruleForm.remark"
                    placeholder=""
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <el-form
            v-else
            ref="ruleForm"
            label-position="right"
            :model="ruleForm"
            :rules="rules"
            label-width="140px"
            class="demo-form-inline"
          >
            <!-- 详情 -->
            <el-row type="flex" justify="start" style="flex-flow:row wrap">
              <el-col v-for="item in detailBaseFields" :key="item.key" :span="6">
                <el-form-item :label="item.name">
                  <div>{{ getDetailVal(item.key) }}</div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <div v-if="this.type!=='view'" style="margin: 10px 0;font-size:14px;">
                节点信息：
              </div>
      <div class="detail-card" style="padding:10px">
          <div class="bottom">
                    <FunctionHeader
                    ref="functionHeader"
                    v-model="functionHeader.searchVal"
                    search-title="请输入节点名称"
                    :tags="functionHeader.tags"
                    @search="searchTable()"
                    v-if="type != 'addOrder'"
                    >
                    </FunctionHeader>
                    <MTable
              ref="mTable"
              default-expand-all
              :is-tree-data="true"
              :height="height"
              :columns="getColumns"
              :data="materialsList"
              :show-page="false"
              :showAppend="type === 'view' ? false : true"
              :onlyKey="'treeId'"
              :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
            >
              <div slot="index" slot-scope="{row }">{{!row.parentId ? row.sort + 1 : '' }}</div>
              <div slot="nodeAttribute" slot-scope="{ row }">
                    <div v-if="row.nodeAttribute != null">{{ planNodeAttribute[row.nodeAttribute].name }}</div>
                  </div>
              <span slot="planName" slot-scope="{row}" style="display: inline-block;">
                <div style="display:flex;align-items:center;">
                <div>{{ row.planName}}</div>
               <img v-if="type !== 'view' && !row.isTaskNode" src="@/assets/plans/add.png" class="num-img" @click="addTaskNode(row)">
              </div>
            </span>
            <div slot="isTaskNode" slot-scope="{ row }" >
            <span>{{row.isTaskNode ? '是' : '否' }}</span>
          </div>
              <div slot="startTimeAttribute" slot-scope="{ row }">
                    <div v-if="row.startTimeAttribute != null">{{ startFinishTimeAttribute[row.startTimeAttribute].name }}</div>
                  </div>
                  <div slot="finishTimeAttribute" slot-scope="{ row }">
                    <div v-if="row.finishTimeAttribute != null">{{ startFinishTimeAttribute[row.finishTimeAttribute].name }}</div>
                  </div>
              <div slot="action" slot-scope="{ row,$index }">
            <template>
              <el-button type="text" @click="modify(row,$index)">修改</el-button>
              <el-divider direction="vertical" />
              <el-button type="text" @click="del(row,$index)" :disabled="row.children.length ? true: false">删除</el-button>
            </template>
          </div>
            </MTable>
          </div>
      </div>
      <div class="order-footer">
        <div />
        <div class="footer-ri">
          <el-button @click="returnList">{{ this.type!=='view'?'取 消':'返 回' }}</el-button>
          <el-button v-if="this.type!=='view'" type="primary" @click="save" :loading="loading">确 定</el-button>
        </div>
      </div>
      <div class="lines" />
          <!-- 新增计划节点 -->
    <MDialog v-model="visible" :title="planNodetitles[currentType]" @onOk="submitForm">
      <MFormBuilder ref="formBuild" :form-data="formData" :form-list="addPlanFormList">
        <div slot="processFlowClassName">
          <CURDSelect
            :default-value="formData.planTypeId"
            api-name="PlanType"
            @change="selectName"
          />
        </div>
        <div slot="startTime">
        <div style="display:flex">
          <el-select v-model="startPlanId" style="width:100%" placeholder="请选择" @change="changeStartTimePlan" clearable filterable>
              <el-option
                v-for="item in planNodeTimeList"
                :key="item.id"
                :label="item.fullName"
                :value="item.id"
              />
            </el-select>
            <el-input-number style="margin-left:10px;"
              v-model="startTimeBasis"
              controls-position="right"
              class="min-input"
            />天
        </div>
        </div>
        <div slot="finishTime">
            <div style="display:flex">
          <el-select v-model="finishPlanId" style="width:100%" placeholder="请选择" @change="changeFinishTimePlan" clearable filterable>
              <el-option
                v-for="item in planNodeTimeList"
                :key="item.id"
                :label="item.fullName"
                :value="item.id"
              />
            </el-select>
            <el-input-number style="margin-left:10px;"
              v-model="finishTimeBasis"
              controls-position="right"
              class="min-input"
            />天
        </div>
        </div>
        
      </MFormBuilder>
    </MDialog>
    </div>
    
  </template>
  <script>
  import api from '@/api/projectManagement/planScheme'
  import { detailBaseFields, productionTaskColumn,applyColumn,getFunctionList } from './columns'
  import { base } from '@/views/information/production/process/detail/columns'
  import { docType,status,planNodeAttribute,startFinishTimeAttribute } from '@/config/options.config'
  import FunctionHeader from '@/components/FunctionHeader/index'
  import { planNodeFormList } from "./form-list"
  import CURDSelect from "@/components/CURDSelect/CURDSelect"
  import papi from '@/api/projectManagement/project'
  export default {
    name: 'ProjectSchemeDetail',
    components: { FunctionHeader,CURDSelect },
    data() {
      return {
        api,
        logVisible: false,
        visible:false,
        productionTaskColumn,
        planNodeFormList,
        orderLists:[],
        projectList:[],
        docType,
        status,
        planNodeAttribute,
        startFinishTimeAttribute,
        ruleForm: {
          isEnabled:1,
          name: '',
          remark:''
        },
        rules: {
          name: [
            { required: true, message: '请输入项目计划方案名称', trigger: 'blur' }
          ],
          isEnabled: [
            { required: true, message: '请选择方案状态', trigger: 'blur' }
          ]
        },
        baseFormData: {},
        columnsBase: base,
        detailBaseFields,
        materialsList:[],
        applyColumn,
        functionHeader: {
        searchVal: '',
        importName: '',
        exportName: 'exportApplyMaterialsList',
        exportParams: {},
        tags: []
      },
      expandedRows:[],
      selectMaterials:[],
      searchId:undefined,
      planNodetitles: {
        add: "新增计划节点",
        modify: "修改计划节点"
      },
      formData: {
        planTypeId: "",
      },
      currentType:'add',
      startPlanId:'',
      finishPlanId:'',
      planNodeTimeList:[],
      startTimeBasis:0,
      finishTimeBasis:0,
      addPlanFormList:[],
      nodeIndex:'',
      startTimeRelationPlanId:'',
      finishTimeRelationPlanId:'',
      startTimeRelationTimeType:'',
      finishTimeRelationTimeType:'',
      planStartDateTime:'',
      planFinishDateTime:'',
      projectPlanNodeId:'',
      planNodesList:[],
      tableData:[],
      startTimeRelationPlanName:'',
      finishTimeRelationPlanName:'',
      loading:false
      }
    },
    async created() {
      this.type = this.$route.query.type
      this.getTableData()
      this.getBusinessUnitList()
           this.getUserList()
      switch (this.type) {
        case 'modify':
        case 'view':
        case 'copy':
          await this.getDetail()
          break
        case 'addOrder':
          break
        default:
          break
      }
    },
    async activated() {
      this.type = this.$route.query.type  
      this.getTableData()
      switch (this.type) {
        case 'modify':
        case 'view':
        case 'copy':
          await this.getDetail()
          break
        case 'addOrder':
          break
        default:
          break
      }
    },
    beforeMount() {
    getFunctionList({
      startTimeAttributeChange: this.startTimeAttributeChange,
      finishTimeAttributeChange:this.finishTimeAttributeChange
    })
  },
  computed: {
      height() {
        return this.$store.state.app.contentHeight - 235 - 88 - 60
      },
    getColumns() {
      const newColumn = this._.cloneDeep(this.applyColumn)
      if(this.type === 'view'){
        newColumn.pop()
      }
      return newColumn
     }
    },
    mounted(){
        // this.getTableData()
      this.addPlanFormList = this._.cloneDeep(this.planNodeFormList)
      this.addPlanFormList.splice(5, 0, {
        hidden: true,
        label: '开始时间',
        key: 'startTimeRelationPlanId',
        tagName: 'div',
        required: false,
        children: [{
        customSlots: ['startTime']
        }]
        })
    },
    methods: {
     // 获取负责人
      async getUserList() {
        const res = await papi.getResponsible()
        if (res) {
          this.addPlanFormList[2].children[0].options = res
          // this.principalLst = res
        }
      },
     async getBusinessUnitList() {
      const res = await papi.getBusinessUnitList()
      if (res) {
        this.addPlanFormList[3].children[0].options = res
      }
    },
      async addTaskNode(row) {
        await this.$store.dispatch('tagsView/addCachedView', this.$route)
        localStorage.setItem('taskNodeList', JSON.stringify(this.materialsList));
        this.$router.push({ name: 'AddTaskNodes', query: { type: 'addChildren', parentIndex: row.treeId } });
},

     selectName(item) {
      this.$refs.formBuild.setForm({
        planTypeId: item.id,
        planTypeName: item.name,
      });
    },
    changeStartTimePlan(val){
      const currentRow = this.planNodeTimeList.find(item => item.id === val)
      if(currentRow){
        this.startTimeRelationPlanId = currentRow.planId
        this.startTimeRelationTimeType = currentRow.timeType
        this.planStartDateTime = currentRow.dateTime
        this.startTimeRelationPlanName = currentRow.planName
      }else{
          this.startTimeRelationPlanId = ''
          this.startTimeRelationTimeType = ''
          this.planStartDateTime = ''
          this.startTimeRelationPlanName = ''
        }
    },
    
    startTimeAttributeChange(val){
      if(val === 2){
        this.addPlanFormList[5].hidden = false
      }else{
        this.addPlanFormList[5].hidden = true
        }
    },
      finishTimeAttributeChange(val){
        if(val === 2){
            this.addPlanFormList[7].hidden = false
        }else{
            this.addPlanFormList[7].hidden = true
        }
      },
      changeFinishTimePlan(val){
        const currentRow = this.planNodeTimeList.find(item => item.id === val)
        if(currentRow){
          this.finishTimeRelationPlanId = currentRow.planId
          this.finishTimeRelationTimeType = currentRow.timeType
          this.planFinishDateTime = currentRow.dateTime
          this.finishTimeRelationPlanName = currentRow.planName
        }else{
          this.finishTimeRelationPlanId = ''
          this.finishTimeRelationTimeType = ''
          this.planFinishDateTime = ''
        }
     },
      searchTable(){
         this.getTableData()
      },
      async modify(row,index) {
      this.formData = this._.cloneDeep(row)
      this.projectPlanNodeId = row.id
      this.nodeIndex = index
      this.currentType = "modify"
      // if(this.type === 'modify'){
      //   await this.getNodeTimeTypeList()
      // }else{
        await this.getSelfNodeTimeTypeList(row.id)
      // }
      this.formData.planObjectId = 0
      this.formData.principalId = this.formData.principalId ? this.formData.principalId.split(',').map(item => +item) : this.formData.principalId
        this.formData.principalDepartId = this.formData.principalDepartId ? this.formData.principalDepartId.split(',').map(item => +item) : this.formData.principalDepartId
      this.startTimeBasis = row.startTimeBasis
      this.finishTimeBasis = row.finishTimeBasis
      this.startPlanId = row.startTimeRelationPlanId || row.startTimeRelationPlanId === 0 ? row.startTimeRelationPlanId+'-'+row.startTimeRelationTimeType:''
      this.finishPlanId =  row.finishTimeRelationPlanId || row.finishTimeRelationPlanId === 0 ? row.finishTimeRelationPlanId+'-'+row.finishTimeRelationTimeType:''
      this.planStartDateTime = this.planNodeTimeList.find(item => item.id === this.startPlanId) ? this.planNodeTimeList.find(item => item.id === this.startPlanId).dateTime :''
      this.startTimeRelationPlanName = this.planNodeTimeList.find(item => item.id === this.startPlanId) ? this.planNodeTimeList.find(item => item.id === this.startPlanId).planName :''
      this.startTimeRelationPlanId = row.startTimeRelationPlanId
      this.startTimeRelationTimeType = row.startTimeRelationTimeType
      this.finishTimeRelationPlanId = row.finishTimeRelationPlanId
      this.finishTimeRelationTimeType = row.finishTimeRelationTimeType
      this.planFinishDateTime = this.planNodeTimeList.find(item => item.id === this.finishPlanId) ? this.planNodeTimeList.find(item => item.id === this.finishPlanId).dateTime :''
      this.finishTimeRelationPlanName = this.planNodeTimeList.find(item => item.id === this.finishPlanId) ? this.planNodeTimeList.find(item => item.id === this.finishPlanId).planName :''
        if(this.formData.startTimeAttribute === 2){
            this.addPlanFormList[5].hidden = false
        }else{
            this.addPlanFormList[5].hidden = true
        }
        if(this.formData.finishTimeAttribute === 2){
            this.addPlanFormList[7].hidden = false
        }else{
            this.addPlanFormList[7].hidden = true
        }
      this.visible = true;
    },
        async submitForm(callback) {
            const { form } = this.$refs.formBuild
            Object.assign(form, {
                planObjectName: '项目',
                nodeAttribute:this.fixAttribute(form.startTimeAttribute,form.finishTimeAttribute),
                startTimeRelationPlanId:form.startTimeAttribute === 2 ?this.startTimeRelationPlanId :'',
                 startTimeRelationTimeType:form.startTimeAttribute === 2 ?this.startTimeRelationTimeType :'',
                startTimeBasis:form.startTimeAttribute === 2 ? this.startTimeBasis :'',
                finishTimeRelationPlanId:form.finishTimeAttribute === 2 ?this.finishTimeRelationPlanId :'',
                finishTimeRelationTimeType:form.finishTimeAttribute === 2 ?this.finishTimeRelationTimeType :'',
                finishTimeBasis:form.finishTimeAttribute === 2 ? this.finishTimeBasis :'',
                startTimeRelationPlanName:form.startTimeAttribute === 2 ?this.startTimeRelationPlanName :'',
                finishTimeRelationPlanName:form.finishTimeAttribute === 2 ? this.finishTimeRelationPlanName :'',
                principalId:form.principalId ? form.principalId.join(','):'',
                principalDepartId:form.principalDepartId ? form.principalDepartId.join(','):'',
                principalName: form.principalId ? this.getName(form.principalId) : "",
               principalDepartName: form.principalDepartId ? this.getDeName(form.principalDepartId) : "",
          })
          this.$refs.formBuild.formRefs().validate(async(valid) => {
            if (valid) {
                    if (this.currentType === 'add') {
                        this.materialsList.push(form)
                    } else {
                      this.materialsList =  this.updateNode(this.materialsList,form.treeId,form);
                    }
                    this.visible = false
              callback(true)
            } else {
              callback()
            }
          })
        },
        getName(raw) {
      let resultArray = [];
      this.addPlanFormList[2].children[0].options.map((item) => {
        if (raw.includes(item.id)) {
          resultArray.push(item.userFullName);
        }
      });
      return resultArray.join(",");
    },
    getDeName(raw) {
      let resultArray = [];
      this.addPlanFormList[3].children[0].options.map((item) => {
        if (raw.includes(item.id)) {
          resultArray.push(item.name);
        }
      });
      return resultArray.join(",");
    },
        updateNode(nodes, targetId, newData) {
        return nodes.map((item) => {
          if (item.treeId === targetId) {
            return { ...item, ...newData };
          } else if (item.children) {
            return { ...item, children: this.updateNode(item.children, targetId, newData) };
          }
          return item;
        })
     },
        createSetData(raw) {
        return {
          ...raw,
          projectPlanId: this.$route.query.id
        }
      },
      createResetData(raw) {
        const obj = {
          id: raw.id
        }
        return Object.assign(this.createSetData(raw), obj)
      },
        fixAttribute(val1,val2){
         if(val1 !=val2){
            return 3
         }else if(val1 === val2 && val1 === 0){
            return 0
         }
         else if(val1 === val2 && val1 === 1){
            return 1
         }
         else if(val1 === val2 && val1 === 2){
            return 2
         }
        },
      async getNodeTimeTypeList(){
          const res = await api.getNodeTimeTypeList({projectPlanId:this.type === 'modify' ? this.$route.query.id:'',projectPlanNodeId:this.projectPlanNodeId})
          if(res){
            this.planNodeTimeList = res
          }
      },
      async getSelfNodeTimeTypeList(id){
        let arr = this._.cloneDeep(this.materialsList)
        arr = this.flattenArray(arr)
        // 先平铺
            arr.forEach((item,index) => {
                item.planId = index;
                item.timeType = 0;
            });
            let arr2 = this._.cloneDeep(arr);
            arr2.forEach(item => {
                item.timeType = 1;
            });
            arr.push(...arr2);
        let data =  arr.map((item,index)=>{
            let fullName = item.planName+'-';
            if (item.timeType === 0) {
                fullName += '开始时间';
            } else {
                fullName += '完成时间';
            }
            return {
                    id:item.planId + '-'+item.timeType,
                    planId:item.planId,
                    timeType:item.timeType,
                    fullName,
                    planName:item.planName,
                    startTimeRelationPlanId:item.startTimeRelationPlanId,
                    finishTimeRelationPlanId:item.finishTimeRelationPlanId
             }
        })
        const groupedById = data.reduce((acc, obj) => {
        const key = obj.planId;
        acc[key] = [...(acc[key] || []), obj];
        return acc;
    }, {});

        const sortedKeys = Object.keys(groupedById).sort((a, b) => a - b);
        const arr3 = sortedKeys.reduce((acc, key) => acc.concat(groupedById[key]), []);
        this.planNodeTimeList = this.currentType === "modify" ? arr3.filter(item=>item.planId!=this.nodeIndex && item.startTimeRelationPlanId!==this.nodeIndex && item.finishTimeRelationPlanId!==this.nodeIndex ):arr3
      },
      flattenArray(arr) {
        let result = []
        function flatten(arr) {
          arr.forEach(item => {
            result.push(item);
            if (item.children && item.children.length > 0) {
            flatten(item.children);
            }
          })
        }
        flatten(arr)
        return result
    },
      // 获取节点详情表
      async getTableData() {
          this.materialsList = localStorage.getItem('taskNodeList') ? JSON.parse(localStorage.getItem('taskNodeList')) : []
        const searchCondition = {
          search: this.functionHeader.searchVal,
          projectPlanId: this.$route.query.id,
        }
        this.functionHeader.exportParams = searchCondition
        if(this.type != 'addOrder' && !this.materialsList.length){
            const res = await api.getPlanSchemeNodeListPage({
          ...searchCondition
        })
        if (res) {
          this.materialsList = res.map((item,index) =>{
            return this.rebulidTree(item,index);
          })
            let array = this.flattenArray(this.materialsList)
            this.updateMaterialsList(array)
        }
        }
      },
      updateMaterialsList(materialsList) {
        materialsList.forEach((item, index) => {
            if (item.startTimeRelationPlanId) {
                const startTimeRelationIndex = materialsList.findIndex(material => material.id === item.startTimeRelationPlanId);
                if (startTimeRelationIndex !== -1) {
                    item.startTimeRelationPlanId = startTimeRelationIndex;
                }else if (item.children && item.children.length > 0) {

                  this.updateMaterialsList(item.children);
                }
            }
            if (item.finishTimeRelationPlanId) {
                const finishTimeRelationIndex = materialsList.findIndex(material => material.id === item.finishTimeRelationPlanId);
                if (finishTimeRelationIndex !== -1) {
                    item.finishTimeRelationPlanId = finishTimeRelationIndex;
                }else if (item.children && item.children.length > 0) {
                  this.updateMaterialsList(item.children);
                }
              }
        })
     },
      rebulidTree(item,index){
            item.children = item.children.map((child,indexs) => {
            return this.rebulidTree(child,indexs);
          });
        return {
          ...item,
          taskId:item.id,
          treeId:item.id + '-' +index
        };
      },
      del(node){
        const parent = this.findParent(this.materialsList, node);
      if (parent) {
        parent.children = parent.children.filter(child => child !== node);
      } else {
        this.materialsList = this.materialsList.filter(item => item !== node);
      }
      this.materialsList = this.materialsList.map((item,index) =>{
            return this.assignChecked(item,index);
          })
    },
    assignChecked(item,index) {
        if (item.children) {
          item.children = item.children.map((child,indexs) => {
            return this.assignChecked(child,indexs);
          });
        }
        return {
          ...item,
          sort:index
        };
      },
    findParent(data, node) {
      for (let item of data) {
        if (item.children && item.children.includes(node)) {
          return item;
        } else if (item.children) {
          const parent = this.findParent(item.children, node);
          if (parent) {
            return parent;
          }
        }
      }
      return null;
    },
    // 获取计划方案基本信息值
      getDetailVal(key) {
        const result = this.ruleForm[key] === 0 || this.ruleForm[key] ? this.ruleForm[key]:'-'
        if (key === 'isEnabled') {
          const current = result ? '启用':'停用'
          return current
        }
      return result
      },
      // 获取详情
      async getDetail() {
        const res = await api.getPlanSchemeDetail({id:this.$route.query.id})
        if (res) {
            const {name,isEnabled,remark} = res
          this.ruleForm.name = name
          this.ruleForm.isEnabled = isEnabled
          this.ruleForm.remark = remark
        }
      },
      save() {
        this.$refs.ruleForm.validate(async(valid) => {
          if (valid) {
            this.loading = true
            let obj
            if(this.type === 'modify'){
              obj = {
                id:this.$route.query.id,
               ...this.ruleForm,
               planNodeList:this.materialsList
            }
            }else{
              let transferTableData = this._.cloneDeep(this.materialsList); // 克隆原始数据
              this.removeId(transferTableData)
                    obj = {
                    ...this.ruleForm,
                    planNodeList:transferTableData
            }
            }
            let papi
            papi = this.type === 'modify' ? api.modifyPlanScheme : api.addPlanScheme
            const res = await papi(obj)
            if (res) {
              this.loading = false
              this.$store.dispatch('tagsView/delCachedView', this.$route)
              this.$router.push({ name: 'ProjectSchemeList'})
            }
            this.loading = false
          } else {
            return false
          }
        })
      },
      removeId(obj) {
    if (Array.isArray(obj)) {
        obj.forEach(item=> {
            delete item.id;
            if (item.children) {
                this.removeId(item.children);
            }
        });
    }
},
      returnList() {
        this.$store.dispatch('tagsView/delCachedView', this.$route)
        this.$router.go(-1)
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
  ::v-deep .my-col {
    .el-form-item {
      margin-bottom: 2px;
      .el-form-item__label {
        padding-right: 0;
      }
    }
  }
  ::v-deep {
    .el-tag {
      margin-right: 10px;
      margin-bottom: 5px;
    }
    .el-form-item {
      margin-bottom: 20px;
    }
    .el-date-editor .el-range-separator,
    .el-date-editor .el-range__icon {
      line-height: 26px;
    }
    .el-input-number {
      height: 32px;
      line-height: 32px;
      width: 100%;
    }
    .el-input__inner {
      height: 32px;
      line-height: 32px;
    }
    .el-input-number.is-controls-right .el-input-number__increase,
    .el-input-number.is-controls-right .el-input-number__decrease {
      line-height: 15px;
    }
    .module-content >div{
      height:34px;
            }
  }
  .orderAdd {
    width: 100%;
    height: 100%;
    .num-img {
        width: 14px;
        height: 14px;
        position:absolute;
        right:10px;
        cursor: pointer;
      }
    .input-new-tag {
      width: 130px;
    }
    .button-new-tag {
      height: 32px;
    }
    .detail-card {
      margin-bottom: 20px;
    }
    .lines {
      width: 100%;
      height: 20px;
      background: #f0f2f5;
    }
    ::v-deep .el-select {
      width: 100%;
    }
    .e-btn {
      margin-bottom: 10px;
    }
    .order-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 60px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 100;
      background: #fff;
      border-top: 1px solid #dde3eb;
      .footer-le {
        padding-left: 30px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #393d60;
        span {
          margin-right: 45px;
          display: inline-block;
        }
      }
      .footer-ri {
        margin-right: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  </style>
  