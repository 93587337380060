import { startFinishTimeAttribute } from '@/config/options.config'
let functionList = {}
export const getFunctionList = val => {
  functionList = val
}
// 详情基本信息字段
export const detailBaseFields = [
    {
      key: 'name',
      name: '项目计划方案名称：'
    },
    {
      key: 'isEnabled',
      name: '项目计划方案状态：'
    },
    {
      key: 'remark',
      name: '备注：'
    }
  ]
  // 方案新增节点字段
  export const productionTaskColumn = [
    {
      prop: 'index',
      label: '序号',
      slotName: 'index',
      hiddenSearch: true,
      fixed: 'left',
      width: 60
    },
    // {
    //   prop: 'planObjectName',
    //   label: '计划对象',
    //   sortable: false,
    //   hiddenSearch: true,
    //   width: 120,
    //   form: {
    //     tagName: 'el-select',
    //     key:'planObjectId',
    //     options: [{id:0,name:'项目'}]
    // }
    // },
    {
      label: '节点名称',
      sortable: false,
      prop: 'planName',
      hiddenSearch: true,
      width: 120,
      form: { tagName: 'el-input', required: true}
    },
    {
      prop: 'planTypeName',
      label: '节点类型',
      sortable: false,
      hiddenSearch: true,
      width: 120,
      form: {
        colSpan:12,
        tagName: 'div',
        required: true,
        attrs:{class:'module-content'},
        customSlots: ['processFlowClassName']
      }
    },
    {
      prop: 'principalName',
      label: '负责人',
      hiddenSearch: true,
      sortable: false,
      slotName:'principalName',
      form: { key: 'principalId', tagName: 'el-select',
      optionsProps: {
        label: 'userFullName',
        value: 'id'
      },options: [], required: false,
      props: {
        multiple: true
       }}
    },
    {
      prop: 'principalName',
      label: '负责部门',
      hiddenSearch: true,
      sortable: false,
      slotName:'principalName',
      form: { key: 'principalDepartId', tagName: 'el-select',
      optionsProps: {
        label: 'name',
        value: 'id'
      },options: [], required: false,
      props: {
        multiple: true
       }}
    },
    {
      prop: 'materialsCode',
      label: '节点属性',
      sortable: false,
      hiddenSearch: true,
      width: 120
    },
    {
      prop: 'startTimeAttribute',
      label: '开始时间属性',
      sortable: false,
      hiddenSearch: true,
      slotName:'startTimeAttribute',
      form: {
        tagName: 'el-select',
        options: startFinishTimeAttribute,
        required: true,
        on: { change: (val) => { functionList.startTimeAttributeChange(val) } }
      }
    },
    {
      prop: 'finishTimeAttribute',
      label: '完成时间属性',
      sortable: false,
      hiddenSearch: true,
      minWidth: 140,
      slotName:'finishTimeAttribute',
      form: {
        tagName: 'el-select',
        options: startFinishTimeAttribute, required: true,
        on: { change: (val) => { functionList.finishTimeAttributeChange(val) } }
      }
    },
    {
      prop: 'action',
      label: '操作',
      hiddenSearch: true,
      sortable: false,
      width: 120,
      slotName: 'action',
      fixed: 'right'
    }
  ]
  // 节点详情字段
  export const applyColumn = [
      {
        prop: 'index',
        label: '序号',
        slotName: 'index',
        hiddenSearch: true,
        fixed: 'left',
        type:'',
        width: 60
      },
      // {
      //   prop: 'planObjectName',
      //   label: '计划对象',
      //   sortable: false,
      //   hiddenSearch: true,
      //   type:'',
      //   minWidth: 120
      // },
      {
        label: '节点名称',
        sortable: false,
        prop: 'planName',
        hiddenSearch: true,
        slotName:'planName',
        minWidth: 120
      },
      {
        prop: 'planTypeName',
        label: '节点类型',
        sortable: false,
        hiddenSearch: true,
        minWidth: 120
      },
      {
        prop: 'isTaskNode',
        label: '是否任务节点',
        sortable: false,
        hiddenSearch: true,
        slotName:'isTaskNode',
        minWidth: 120
      },
      {
        prop: 'principalName',
        label: '负责人',
        hiddenSearch: true,
        sortable: false,
        form: { key: 'principalId', tagName: 'el-select',
        optionsProps: {
          label: 'userFullName',
          value: 'id'
        },options: [], required: false,
        props: {
          multiple: true
         }}
      },
      {
        prop: 'principalDepartName',
        label: '负责部门',
        hiddenSearch: true,
        sortable: false,
        form: { key: 'principalDepartId', tagName: 'el-select',
        optionsProps: {
          label: 'name',
          value: 'id'
        },options: [], required: false,
        props: {
          multiple: true
         }}
      },
      {
        prop: 'nodeAttribute',
        label: '节点属性',
        sortable: false,
        hiddenSearch: true,
        slotName:'nodeAttribute',
        minWidth: 120
      },
      {
        prop: 'startTimeAttribute',
        label: '开始时间属性',
        sortable: false,
        hiddenSearch: true,
        slotName:'startTimeAttribute'
      },
      {
        prop: 'finishTimeAttribute',
        label: '完成时间属性',
        sortable: false,
        hiddenSearch: true,
        minWidth: 140,
        slotName:'finishTimeAttribute'
      },
      {
        prop: 'action',
        label: '操作',
        hiddenSearch: true,
        sortable: false,
        width: 120,
        slotName: 'action',
        fixed: 'right'
      }
    ]
  