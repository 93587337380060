var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: "待定需求", visible: _vm.dialogVisible, width: "70%" },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "inspection-plan-detail-wrapper" },
        [
          _c("MTable", {
            attrs: {
              height: 500,
              columns: _vm.waitDemandColumns,
              "show-page": false,
              data: _vm.tableData || [],
              "only-key": "uuid"
            },
            scopedSlots: _vm._u([
              {
                key: "itemNumber",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "materialsCode",
                fn: function(ref) {
                  var row = ref.row
                  return _c("NewPageOpen", {
                    attrs: {
                      path: {
                        name: "materialsDetail",
                        query: { id: row.materialsId }
                      },
                      text: row.materialsCode
                    }
                  })
                }
              },
              {
                key: "procedureCode",
                fn: function(ref) {
                  var row = ref.row
                  return _c("NewPageOpen", {
                    attrs: {
                      path: {
                        name: "procedureDetail",
                        query: { id: row.procedureId }
                      },
                      text: row.procedureCode
                    }
                  })
                }
              },
              {
                key: "productionQuantity",
                fn: function(ref) {
                  var row = ref.row
                  return _c("el-input-number", {
                    attrs: {
                      min: 0,
                      max:
                        row.undeterminedRequireQuantity - row.purchaseQuantity,
                      "controls-position": "right",
                      step: 0.01
                    },
                    on: {
                      blur: function($event) {
                        row.productionQuantity = _vm.sliceVal(
                          row.productionQuantity,
                          2
                        )
                      }
                    },
                    model: {
                      value: row.productionQuantity,
                      callback: function($$v) {
                        _vm.$set(row, "productionQuantity", $$v)
                      },
                      expression: "row.productionQuantity"
                    }
                  })
                }
              },
              {
                key: "purchaseQuantity",
                fn: function(ref) {
                  var row = ref.row
                  return _c("el-input-number", {
                    attrs: {
                      min: 0,
                      max:
                        row.undeterminedRequireQuantity -
                        row.productionQuantity,
                      "controls-position": "right",
                      step: 0.01
                    },
                    on: {
                      blur: function($event) {
                        row.purchaseQuantity = _vm.sliceVal(
                          row.purchaseQuantity,
                          2
                        )
                      }
                    },
                    model: {
                      value: row.purchaseQuantity,
                      callback: function($$v) {
                        _vm.$set(row, "purchaseQuantity", $$v)
                      },
                      expression: "row.purchaseQuantity"
                    }
                  })
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              staticStyle: { float: "left" },
              on: {
                click: function($event) {
                  return _vm.confirm(1)
                }
              }
            },
            [_vm._v("全部生产")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { float: "left" },
              on: {
                click: function($event) {
                  return _vm.confirm(2)
                }
              }
            },
            [_vm._v("全部采购")]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.confirm(3)
                }
              }
            },
            [_vm._v("确 认")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }