<template>
  <div class="inspection-plan-detail-wrapper">
    <div class="inspection-plan-detail-header flex-sbc">
      <div class="left" @click="$router.back()">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px">
        <span style="padding-left: 10px;cursor: pointer">新增生产任务</span>
      </div>
    </div>
    <div class="inspection-plan-detail-body">
      <div class="detail-card">
        <div class="card-tit">基本信息</div>
        <div class="card-bot">
          <MFormBuilder ref="baseForm" :form-list="addTaskForm" :form-data="detail" />
        </div>
      </div>
      <div class="detail-card table-box">
        <div class="card-tit">列表信息</div>
        <div class="card-bot ">
          <MTable :height="100" :columns="addTaskColumn" :data="tableData" :show-page="false">
            <NewPageOpen
              slot="materialsCode"
              slot-scope="{ row }"
              :path="{ name: 'materialsDetail', query: {id: row.materialsId}}"
              :text="row.materialsCode"
            />
            <template slot="planOutputQuantity" slot-scope="{ row }">
              <el-input-number
                v-model="row.planOutputQuantity"
                :max="row.netRequireQuantity"
                :controls="false"
                :style="{border:row.isWarningPlanCount?'1px solid #F56C6C':'',borderRadius:'4px',width:'110px',height: 'fit-content'}"
                :min="1"
                :step="1"
                :step-strictly="true"
              />
            </template>
          </MTable>
        </div>
      </div>
    </div>
    <div class="order-footer">
      <div />
      <div class="footer-ri">
        <el-button @click="$router.back()">取 消 </el-button>
        <el-button type="primary" @click="onSave">确 定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/production/project'
import { addTaskForm, addTaskColumn } from './data'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
export default {
  name: 'PurchaseDetail',
  components: { NewPageOpen },
  data() {
    return {
      addTaskForm,
      addTaskColumn,
      detail: {},
      tableData: []
    }
  },
  created() {
    this.detail = JSON.parse(this.$route.query.row)
    this.getCode()
    this.$set(this.detail, 'planOutputQuantity', this.detail.netRequireQuantity)
    this.tableData = [this.detail]
  },
  methods: {
    async getCode() {
      const res = await api.getCode()
      if (res) this.$set(this.detail, 'code', res)
    },
    async onSave() {
      const valid = await this.$refs.baseForm.formRefs().validate()
      if (!valid) return
      if (!this.tableData[0].planOutputQuantity) {
        this.$set(this.detail, 'isWarningPlanCount', true)
        return this.$message.warning('请计划产量')
      }
      this.$confirm('确认要保存吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const { form } = this.$refs.baseForm
        const { planOutputQuantity } = this.detail
        const { code, remark, planTime, id: productionRequirementItemId } = form
        const params = {
          code, planOutputQuantity, remark,
          productionRequirementItemId,
          planStartTime: planTime ? planTime[0] : null,
          planEndTime: planTime ? planTime[1] : null

        }
        const res = await api.createTask(params)
        if (res) {
          this.$message.success('操作成功')
          delete this.detail.isWarningPlanCount
          this.$router.back()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.order-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  background: #fff;
  border-top: 1px solid #dde3eb;
  .footer-le {
    padding-left: 30px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393d60;
    span {
      margin-right: 45px;
      display: inline-block;
    }
  }
  .footer-ri {
    margin-right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.inspection-plan-detail-wrapper {
  background-color: #f0f2f5;
  height: 100%;

  .inspection-plan-detail-header {
    width: 100%;
    padding: 10px 0;
  }

  .inspection-plan-detail-body {
    height: calc(100% - 20px);

    .detail-card {
      margin-bottom: 20px;
    }
  }
  .function-header-wrap {
    padding-top: 0;
  }
}
</style>
