<template>
  <div class="tracing">
    <div class="top">
      <SearchForm
        ref="searchForms"
        :form-list-extend="searchFormList"
        class="searchs"
        @search="searchTable"
      />
    </div>
    <FunctionHeader
      v-model="functionHeader.searchVal"
      search-title="请输入批次码"
      :tags="functionHeader.tags"
      style="padding: 10px 0 20px 0"
      @search="$refs.mTable.setTableData(true)"
    />
    <MTable
      ref="mTable"
      :height="height"
      :columns="columnsAll"
      :set-data-method="getTableData"
      @selection-change="selectionChange"
    >
      <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
      <div slot="batchNumberDetail" slot-scope="{ row }">
        <NewPageOpen :path="{ name: 'productBatchCodeTracingDetail', query: {batchNumber: row.batchNumber}}" :text="row.batchNumber" />
      </div>
      <div slot="action" slot-scope="{ row }">
        <el-button v-if="permission('Download')" :disabled="!row.continueTimeZz" type="text" @click="download(row)">下载</el-button>
      </div>
    </MTable>
    <MDialog v-model="visible" :append-to-body="true" title="下载模板" @onOk="downloadTemplate" width="500px">
      <MFormBuilder ref="baseForm" :form-list="formList" label-position="right" :form-data="baseFormData" />
    </MDialog>
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import api from '@/api/production/plans'
import { getUUid } from '@/utils'
import { Encrypt } from '@/utils/sercet'
import { searchFormList, formList } from './form-list'
import SearchForm from '@/components/SearchForm/SearchForm'
import { columns, getFunctionList } from "./columns"
import { export_json_to_excel2 } from '@/utils/plug/to-excel'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
import dayjs from "dayjs";

export default {
  name: 'FinishedProductTracing',
  components: {
    FunctionHeader,
    SearchForm,
    NewPageOpen
  },
  data() {
    return {
      nowBetNum:'',
      searchFormList,
      formList,
      columns,
      searchFormatData: [],
      functionHeader: {
        searchVal: '',
        tags: [
          {
            hidden: false,
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '批量导出',
            on: {
              click: this.batchExport
            }
          }
        ],
      },
      visible: false,
      baseFormData: {},
      batchNumber: '',
      templateList: [],
      templateDetail: {}
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 25
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    },
    columnsAll() {
      const newColumns = this._.cloneDeep(columns)
      if (this.memberCode === 'bbelc') {
        newColumns.push({
          slotName: 'action',
          label: '操作',
          fixed: 'right',
          hiddenSearch: true,
          sortable: false,
          width: 120
        })
      }
      return this.memberCode === 'bbelc' ? newColumns : columns
    }
  },
  beforeMount() {
    getFunctionList({
      templateChange: this.templateChange,
    });
  },
  methods: {
    templateChange(val) {
      if (val) {
        this.templateDetail = this.templateList.find(item => item.name === val)
      }
    },
    async download(row) {
      this.visible = true
      this.formList[0].children[0].options = []
      this.batchNumber = row.batchNumber
      const res = await api.getCustomerDownloadTemplate({ batchNumber: row.batchNumber })
      if (res) {
        this.nowBetNum = row.batchNumber
        this.formList[0].children[0].options = res.map(r => ({
          id: r.name,
          name: r.name
        }))
        this.templateList = res
      }
    },
    async downloadTemplate(callback) {
      this.$refs.baseForm.formRefs().validate(async(valid) => {
        if (valid) {
            const res = await api.getExportExcelDocument({
            path: this.templateDetail.url,
            fileName: this.templateDetail.name,
            // batchNumber: 'batch-add-3'
            batchNumber: this.nowBetNum
          })
          if (res && res.data.byteLength) {
            const urlP = window.URL.createObjectURL(
              new Blob([res.data], {
                type: "application/octet-stream;charset=ISO8859-1",
              })
            );
            const fileName = `${dayjs().format("YYYYMMDDHHmmss")}.xlsx`;
            this.downloadFile(urlP, fileName);
            this.visible = false
          } else {
            this.$message.error("下载失败，内容为空");
          }
          callback(true)
        } else {
          callback()
        }
      })
    },
    downloadFile (urlP, name) {
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = urlP;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    searchTable(data) {
      this.searchFormatData = data
      this.$refs.mTable.setTableData(true)
    },
    selectionChange(data) {
      this.selectData = data
    },
    batchExport() {
      const exportData = this._.cloneDeep(this.selectData)
      const params = {
        key: columns.filter(item => item.prop && item.prop !== 'index').map(item => item.prop),
        header: columns.filter(item => item.prop && item.prop !== 'index').map(item => item.label),
        merges: [],
        data: exportData,
        filename: '成品追溯列表'
      }
      export_json_to_excel2(params)
    },
    async getTableData(condition, callback) {
      const { page, order } = condition
      const search = [{
        id: getUUid(),
        fieldName: 'processFlowId',
        fieldType: 'number',
        fieldValue: 83,
        operator: 'eq'
      }]
      if (this.functionHeader.searchVal) {
        search.push({
          id: getUUid(),
          fieldName: 'batchNumber',
          fieldType: 'string',
          fieldValue: this.functionHeader.searchVal,
          operator: 'like'
        })
      }
      console.log('conso=》', search)
      const res = await api.getProducePlanForTracePage({
        page: page.currentPage,
        limit: page.pageSize,
        sort: order,
        searchVal: Encrypt(JSON.stringify([...search, ...this.searchFormatData]))
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    jumpDetail(row) {
      this.$router.push({
        name: 'rmbctDetail',
        params: { id: row.batchNumber }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tracing {
  padding: 0 10px 10px 10px;
}
</style>
