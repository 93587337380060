var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "product-all" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        _vm._l(_vm.list, function(item, index) {
          return _c("el-tab-pane", {
            key: index,
            attrs: {
              label: item.produceWorkOrderNumber,
              name: item.produceWorkOrderNumber
            }
          })
        }),
        1
      ),
      _vm.info.reductionQuantity ||
      _vm.info.divisionQuantity ||
      _vm.info.repairQuantity
        ? _c(
            "div",
            { staticClass: "all-nums" },
            [
              _c(
                "el-timeline",
                { attrs: { reverse: false } },
                [
                  _vm.info.reductionQuantity
                    ? _c(
                        "el-timeline-item",
                        {
                          staticClass: "eltimelinefocus",
                          attrs: { color: "#607FFF" }
                        },
                        [
                          _c("div", { staticClass: "txt" }, [
                            _vm._v("减产数量："),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.info.reductionQuantity
                                    ? _vm.info.reductionQuantity
                                    : 0
                                )
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "report-li-content" }, [
                            _vm.info.reductionRecordList &&
                            _vm.info.reductionRecordList.length > 0
                              ? _c(
                                  "div",
                                  { staticClass: "report-params" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "params-li",
                                        on: {
                                          click: function($event) {
                                            return _vm.tapShow(1)
                                          }
                                        }
                                      },
                                      [
                                        _vm.showChai
                                          ? _c("span", {
                                              staticClass: "el-icon-arrow-up"
                                            })
                                          : _c("span", {
                                              staticClass: "el-icon-arrow-right"
                                            }),
                                        _c("span", [_vm._v("减产记录")])
                                      ]
                                    ),
                                    _vm.showChai
                                      ? _c("MTable", {
                                          ref: "mTable",
                                          staticStyle: {
                                            "margin-right": "42px"
                                          },
                                          attrs: {
                                            columns: _vm.reductionColumns,
                                            data: _vm.info.reductionRecordList,
                                            height: 200,
                                            "show-page": false
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "index",
                                                fn: function(ref) {
                                                  var $index = ref.$index
                                                  return _c("div", {}, [
                                                    _vm._v(_vm._s($index + 1))
                                                  ])
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            442309118
                                          )
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e()
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm.info.divisionQuantity
                    ? _c(
                        "el-timeline-item",
                        {
                          staticClass: "eltimelinefocus",
                          attrs: { color: "#607FFF" }
                        },
                        [
                          _c("div", { staticClass: "txt" }, [
                            _vm._v("拆单数量："),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.info.divisionQuantity
                                    ? _vm.info.divisionQuantity
                                    : 0
                                )
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "report-li-content" }, [
                            _vm.info.divisionRecordList &&
                            _vm.info.divisionRecordList.length > 0
                              ? _c(
                                  "div",
                                  { staticClass: "report-params" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "params-li",
                                        on: {
                                          click: function($event) {
                                            return _vm.tapShow(2)
                                          }
                                        }
                                      },
                                      [
                                        _vm.showDivision
                                          ? _c("span", {
                                              staticClass: "el-icon-arrow-up"
                                            })
                                          : _c("span", {
                                              staticClass: "el-icon-arrow-right"
                                            }),
                                        _c("span", [_vm._v("拆单记录")])
                                      ]
                                    ),
                                    _vm.showDivision
                                      ? _c("MTable", {
                                          ref: "mTable",
                                          staticStyle: {
                                            "margin-right": "42px"
                                          },
                                          attrs: {
                                            columns: _vm.divisionColumns,
                                            data: _vm.info.divisionRecordList,
                                            height: 200,
                                            "show-page": false
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "index",
                                                fn: function(ref) {
                                                  var $index = ref.$index
                                                  return _c("div", {}, [
                                                    _vm._v(_vm._s($index + 1))
                                                  ])
                                                }
                                              },
                                              {
                                                key:
                                                  "newProduceWorkOrderNumber",
                                                fn: function(ref) {
                                                  var row = ref.row
                                                  return _c(
                                                    "div",
                                                    {},
                                                    [
                                                      _c("NewPageOpen", {
                                                        attrs: {
                                                          path: {
                                                            name: "workDetail",
                                                            query: {
                                                              id:
                                                                row.newProduceWorkOrderId
                                                            }
                                                          },
                                                          text:
                                                            row.newProduceWorkOrderNumber
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            3567742865
                                          )
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e()
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm.info.repairQuantity
                    ? _c(
                        "el-timeline-item",
                        {
                          staticClass: "eltimelinefocus",
                          attrs: { color: "#607FFF" }
                        },
                        [
                          _c("div", { staticClass: "txt" }, [
                            _vm._v("返修数量："),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.info.repairQuantity
                                    ? _vm.info.repairQuantity
                                    : 0
                                )
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "report-li-content" }, [
                            (_vm.info.repairRecordList &&
                              _vm.info.repairRecordList.length > 0) ||
                            (_vm.info.inspectionRepairRecordList &&
                              _vm.info.inspectionRepairRecordList.length > 0)
                              ? _c(
                                  "div",
                                  { staticClass: "report-params" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "params-li",
                                        on: {
                                          click: function($event) {
                                            return _vm.tapShow(3)
                                          }
                                        }
                                      },
                                      [
                                        _vm.showRepair
                                          ? _c("span", {
                                              staticClass: "el-icon-arrow-up"
                                            })
                                          : _c("span", {
                                              staticClass: "el-icon-arrow-right"
                                            }),
                                        _c("span", [_vm._v("返修记录")])
                                      ]
                                    ),
                                    _vm.showRepair
                                      ? _c("MTable", {
                                          ref: "mTable",
                                          staticStyle: {
                                            "margin-right": "42px"
                                          },
                                          attrs: {
                                            columns: _vm.repairColumns,
                                            data: _vm.fixAll(_vm.info),
                                            height: 200,
                                            "show-page": false
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "index",
                                                fn: function(ref) {
                                                  var $index = ref.$index
                                                  return _c("div", {}, [
                                                    _vm._v(_vm._s($index + 1))
                                                  ])
                                                }
                                              },
                                              {
                                                key: "repairPlanNumber",
                                                fn: function(ref) {
                                                  var row = ref.row
                                                  return _c("div", {}, [
                                                    row.repairType ===
                                                    "报工返修"
                                                      ? _c("div", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                row.repairPlanNumber
                                                              ) +
                                                              " "
                                                          )
                                                        ])
                                                      : _c("div", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.fixRepair(
                                                                  row.planList
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ])
                                                  ])
                                                }
                                              },
                                              {
                                                key: "repairWorkOrderNumber",
                                                fn: function(ref) {
                                                  var row = ref.row
                                                  return _c("div", {}, [
                                                    row.repairType ===
                                                    "报工返修"
                                                      ? _c(
                                                          "div",
                                                          [
                                                            _c("NewPageOpen", {
                                                              attrs: {
                                                                path: {
                                                                  name:
                                                                    "workDetail",
                                                                  query: {
                                                                    id:
                                                                      row.repairWorkOrderId
                                                                  }
                                                                },
                                                                text:
                                                                  row.repairWorkOrderNumber
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      : _c(
                                                          "div",
                                                          _vm._l(
                                                            row.planList &&
                                                              row.planList,
                                                            function(item) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key: item.id
                                                                },
                                                                [
                                                                  item.produceWorkOrder
                                                                    ? _c(
                                                                        "NewPageOpen",
                                                                        {
                                                                          attrs: {
                                                                            path: {
                                                                              name:
                                                                                "workDetail",
                                                                              query: {
                                                                                id:
                                                                                  item
                                                                                    .produceWorkOrder
                                                                                    .id
                                                                              }
                                                                            },
                                                                            text:
                                                                              item
                                                                                .produceWorkOrder
                                                                                .produceWorkOrderNumber
                                                                          }
                                                                        }
                                                                      )
                                                                    : _vm._e()
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        )
                                                  ])
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            2925464307
                                          )
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e()
                          ])
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "radio-all" },
        [
          _c(
            "el-radio-group",
            {
              on: { change: _vm.changeReverse },
              model: {
                value: _vm.reverse,
                callback: function($$v) {
                  _vm.reverse = $$v
                },
                expression: "reverse"
              }
            },
            [
              _c("el-radio", { attrs: { label: true } }, [_vm._v("倒序")]),
              _c("el-radio", { attrs: { label: false } }, [_vm._v("正序")])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "radio-ri" },
            [
              _c(
                "div",
                { staticClass: "ri-tit", on: { click: _vm.selectTit } },
                [
                  _c("span", { staticClass: "tit" }, [
                    _vm._v(_vm._s(_vm.currentIdx ? "全部折叠" : "全部展开"))
                  ]),
                  _vm.currentIdx
                    ? _c("span", { staticClass: "el-icon-arrow-down" })
                    : _vm._e(),
                  !_vm.currentIdx
                    ? _c("span", { staticClass: "el-icon-arrow-up" })
                    : _vm._e()
                ]
              ),
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.getTableDatas },
                  model: {
                    value: _vm.show,
                    callback: function($$v) {
                      _vm.show = $$v
                    },
                    expression: "show"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("显示全部")]),
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v("只看报工记录")
                  ]),
                  _c("el-radio", { attrs: { label: 2 } }, [
                    _vm._v("只看质检记录")
                  ])
                ],
                1
              ),
              _c(
                "el-select",
                {
                  staticClass: "selec-produce",
                  attrs: {
                    "collapse-tags": "",
                    placeholder: "请选择工序",
                    filterable: "",
                    multiple: "",
                    clearable: ""
                  },
                  on: { change: _vm.searchData },
                  model: {
                    value: _vm.currentProcedureIds,
                    callback: function($$v) {
                      _vm.currentProcedureIds = $$v
                    },
                    expression: "currentProcedureIds"
                  }
                },
                _vm._l(_vm.getCurrentProcedureList, function(item) {
                  return _c("el-option", {
                    key: item.procedureId,
                    attrs: {
                      label: item.procedureName,
                      value: item.procedureId
                    }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "box" },
        _vm._l(_vm.datas, function(item, index) {
          return _c("div", { key: item.id, staticClass: "box-li" }, [
            index !== _vm.datas.length - 1
              ? _c("div", { staticClass: "el-timeline-item__tail" })
              : _vm._e(),
            _c("div", { staticClass: "li-left" }, [
              _vm._v(" " + _vm._s(item.procedureName) + " "),
              _c("div", { staticClass: "des" }, [
                _vm._v(
                  "(" + _vm._s(item.type === 1 ? "外协任务" : "自制任务") + ")"
                )
              ])
            ]),
            _c("div", { staticClass: "li-right" }, [
              _c("div", { staticClass: "li-right-content" }, [
                _c("div", { staticClass: "content-top" }, [
                  _c("div", { staticClass: "tit" }, [
                    _vm._v("进站时间：" + _vm._s(item.arrivalTime))
                  ]),
                  _c("div", { staticClass: "tit" }, [
                    _vm._v("出站时间：" + _vm._s(item.departureTime))
                  ]),
                  _c("div", { staticClass: "tit" }, [
                    _vm._v("状态："),
                    _c(
                      "span",
                      {
                        style: {
                          color: _vm.statusOrder[item.status].color,
                          background: _vm.statusOrder[item.status].background
                        }
                      },
                      [_vm._v(_vm._s(_vm.statusOrder[item.status].name))]
                    )
                  ])
                ]),
                item.outsourcingList && item.outsourcingList.length > 0
                  ? _c("div", { staticClass: "content-report" }, [
                      _vm._m(0, true),
                      _c("div", { staticClass: "li-ri" }, [
                        _c("div", { staticClass: "des" }, [
                          _vm._v("总数量："),
                          _c("span", [
                            _vm._v(_vm._s(Number(item.inWarehouseQuantity)))
                          ])
                        ])
                      ])
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "report-li" },
                  [
                    _c(
                      "el-timeline",
                      { attrs: { reverse: false } },
                      _vm._l(item.outsourcingList, function(outsource) {
                        return _c(
                          "el-timeline-item",
                          {
                            key: outsource.id,
                            staticClass: "eltimelinefocus",
                            attrs: { color: "#607FFF" }
                          },
                          [
                            _c("div", { staticClass: "txt" }, [
                              _vm._v(
                                _vm._s(outsource.type === 0 ? "入库" : "出库")
                              )
                            ]),
                            _c("div", { staticClass: "report-li-content" }, [
                              _c("div", { staticClass: "content-tops" }, [
                                _c("div", { staticClass: "txt" }, [
                                  _vm._v(
                                    _vm._s(
                                      outsource.type === 0 ? "入库" : "出库"
                                    ) +
                                      "时间：" +
                                      _vm._s(outsource.operateTime)
                                  )
                                ]),
                                _c("div", { staticClass: "txt" }, [
                                  _vm._v(
                                    "操作人：" + _vm._s(outsource.operator)
                                  )
                                ]),
                                _c("div", { staticClass: "txt" }, [
                                  _vm._v("数量："),
                                  _c("span", [
                                    _vm._v(_vm._s(outsource.quantity))
                                  ])
                                ])
                              ])
                            ])
                          ]
                        )
                      }),
                      1
                    )
                  ],
                  1
                ),
                item.reportWorkList && item.reportWorkList.length > 0
                  ? _c("div", { staticClass: "content-report" }, [
                      _vm._m(1, true),
                      _c("div", { staticClass: "li-ri" }, [
                        _c("div", { staticClass: "des" }, [
                          _vm._v("总数量："),
                          _c("span", [_vm._v(_vm._s(item.reportWorkQuantity))])
                        ])
                      ])
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "report-li" },
                  [
                    _c(
                      "el-timeline",
                      { attrs: { reverse: false } },
                      _vm._l(item.reportWorkList, function(activity, idx) {
                        return _c(
                          "el-timeline-item",
                          {
                            key: activity.id,
                            staticClass: "eltimelinefocus",
                            attrs: { color: "#607FFF" }
                          },
                          [
                            _c("div", { staticClass: "txt" }, [
                              _vm._v(
                                "第" +
                                  _vm._s(item.reportWorkList.length - idx) +
                                  "次报工"
                              )
                            ]),
                            _c("div", { staticClass: "report-li-content" }, [
                              _c("div", { staticClass: "content-tops" }, [
                                _c("div", { staticClass: "txt" }, [
                                  _vm._v(
                                    "报工时间：" + _vm._s(activity.operateTime)
                                  )
                                ]),
                                _c("div", { staticClass: "txt" }, [
                                  _vm._v("报工人：" + _vm._s(activity.operator))
                                ]),
                                _c("div", { staticClass: "txt" }, [
                                  _vm._v("报工数量："),
                                  _c("span", [
                                    _vm._v(_vm._s(activity.reportWorkQuantity))
                                  ])
                                ])
                              ]),
                              _vm.fixJson(activity.procedureDetails).length > 0
                                ? _c("div", { staticClass: "report-params" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "params-li",
                                        on: {
                                          click: function($event) {
                                            return _vm.selectInspection(
                                              idx,
                                              index
                                            )
                                          }
                                        }
                                      },
                                      [
                                        activity.checked
                                          ? _c("span", {
                                              staticClass: "el-icon-arrow-up"
                                            })
                                          : _c("span", {
                                              staticClass: "el-icon-arrow-right"
                                            }),
                                        _c("span", [_vm._v("工序上传参数")])
                                      ]
                                    ),
                                    activity.checked
                                      ? _c(
                                          "div",
                                          { staticClass: "params-content" },
                                          _vm._l(
                                            _vm.fixJson(
                                              activity.procedureDetails
                                            ),
                                            function(row) {
                                              return _c(
                                                "div",
                                                {
                                                  key: row.name,
                                                  staticClass: "params-card"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "header flex-sbc"
                                                    },
                                                    [
                                                      _c("div", [
                                                        _vm._v(
                                                          "参数组标题：" +
                                                            _vm._s(row.name)
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "body" },
                                                    _vm._l(
                                                      row.fromData,
                                                      function(item) {
                                                        return _c(
                                                          "div",
                                                          { key: item.id },
                                                          [
                                                            item.type !== 4
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "bot-ti"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.key
                                                                      ) +
                                                                        "：" +
                                                                        _vm._s(
                                                                          _vm.fixData(
                                                                            item.value
                                                                          )
                                                                        )
                                                                    )
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "bot-ti"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.key
                                                                      ) + "： "
                                                                    ),
                                                                    _vm._l(
                                                                      _vm.fixArr(
                                                                        item.value
                                                                      ),
                                                                      function(
                                                                        items
                                                                      ) {
                                                                        return _c(
                                                                          "a",
                                                                          {
                                                                            key: items,
                                                                            staticClass:
                                                                              "imgs",
                                                                            attrs: {
                                                                              href: items,
                                                                              target:
                                                                                "_blank"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "img",
                                                                              {
                                                                                attrs: {
                                                                                  src: items
                                                                                }
                                                                              }
                                                                            )
                                                                          ]
                                                                        )
                                                                      }
                                                                    )
                                                                  ],
                                                                  2
                                                                )
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e()
                                  ])
                                : _vm._e(),
                              activity.receiveRecordList &&
                              activity.receiveRecordList.length > 0
                                ? _c(
                                    "div",
                                    { staticClass: "report-params" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "params-li",
                                          on: {
                                            click: function($event) {
                                              return _vm.selectRecord(
                                                idx,
                                                index
                                              )
                                            }
                                          }
                                        },
                                        [
                                          activity.revieveChecked
                                            ? _c("span", {
                                                staticClass: "el-icon-arrow-up"
                                              })
                                            : _c("span", {
                                                staticClass:
                                                  "el-icon-arrow-right"
                                              }),
                                          _c("span", [_vm._v("投产记录")])
                                        ]
                                      ),
                                      activity.revieveChecked
                                        ? _c("MTable", {
                                            ref: "mTable",
                                            refInFor: true,
                                            staticStyle: {
                                              "margin-right": "42px"
                                            },
                                            attrs: {
                                              columns: _vm.columns,
                                              data: activity.receiveRecordList,
                                              height: 300,
                                              "show-page": false
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "index",
                                                  fn: function(ref) {
                                                    var $index = ref.$index
                                                    return _c("div", {}, [
                                                      _vm._v(_vm._s($index + 1))
                                                    ])
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ])
                          ]
                        )
                      }),
                      1
                    )
                  ],
                  1
                ),
                item.reportWasteRecordList &&
                item.reportWasteRecordList.length > 0
                  ? _c("div", { staticClass: "content-report" }, [
                      _vm._m(2, true),
                      _c("div", { staticClass: "li-ri" }, [
                        _c("div", { staticClass: "des" }, [
                          _vm._v("总数量："),
                          _c("span", [_vm._v(_vm._s(item.wasteQuantity))])
                        ])
                      ])
                    ])
                  : _vm._e(),
                item.reportWasteRecordList &&
                item.reportWasteRecordList.length > 0
                  ? _c(
                      "div",
                      { staticClass: "report-li" },
                      [
                        _c("MTable", {
                          ref: "mTable",
                          refInFor: true,
                          staticStyle: { margin: "0 40px 20px 30px" },
                          attrs: {
                            columns: _vm.reportWasteColumns,
                            data: item.reportWasteRecordList,
                            height: 200,
                            "show-page": false
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "index",
                                fn: function(ref) {
                                  var $index = ref.$index
                                  return _c("div", {}, [
                                    _vm._v(_vm._s($index + 1))
                                  ])
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                item.inspectionSheetList && item.inspectionSheetList.length > 0
                  ? _c("div", { staticClass: "content-report" }, [
                      _vm._m(3, true)
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "report-li" },
                  [
                    _c(
                      "el-timeline",
                      { attrs: { reverse: false } },
                      _vm._l(item.inspectionSheetList, function(qualty, idxs) {
                        return _c(
                          "el-timeline-item",
                          {
                            key: idxs,
                            staticClass: "eltimelinefocus",
                            attrs: { color: "#607FFF" }
                          },
                          [
                            _c("div", { staticClass: "txt" }, [
                              _vm._v(
                                _vm._s(
                                  qualty.inspectionType === 0
                                    ? "首检"
                                    : "过程检"
                                )
                              )
                            ]),
                            _c("div", { staticClass: "report-li-content" }, [
                              _c("div", { staticClass: "content-tops" }, [
                                _c("div", { staticClass: "txt" }, [
                                  _vm._v("提交时间：" + _vm._s(qualty.endTime))
                                ]),
                                _c("div", { staticClass: "txt" }, [
                                  _vm._v(
                                    "检验人：" + _vm._s(qualty.inspectorName)
                                  )
                                ]),
                                qualty.inspectionType === 1
                                  ? _c("div", { staticClass: "txt" }, [
                                      _vm._v("合格率："),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.fixDatas(qualty.passRate)
                                          ) + "%"
                                        )
                                      ])
                                    ])
                                  : _vm._e()
                              ]),
                              _c("div", { staticClass: "content-tops" }, [
                                _c("div", { staticClass: "txt" }, [
                                  _vm._v("质检单号：" + _vm._s(qualty.number))
                                ]),
                                qualty.inspectionType === 1
                                  ? _c("div", { staticClass: "txt" }, [
                                      _vm._v("检验结果： "),
                                      _c("span", { staticClass: "suc" }, [
                                        _vm._v(
                                          "合格：" + _vm._s(qualty.okCount)
                                        )
                                      ]),
                                      _c("span", { staticClass: "fail" }, [
                                        _vm._v(
                                          "不合格：" +
                                            _vm._s(qualty.ngCount) +
                                            "（ 让步接收：" +
                                            _vm._s(qualty.degradeCount) +
                                            " ； 报废：" +
                                            _vm._s(qualty.scrapCount) +
                                            " ； 返修：" +
                                            _vm._s(qualty.repairCount) +
                                            " ）"
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                qualty.inspectionType === 0
                                  ? _c("div", { staticClass: "txt" }, [
                                      qualty.result === 1
                                        ? _c("span", { staticClass: "suc" }, [
                                            _vm._v("首检合格")
                                          ])
                                        : _vm._e(),
                                      qualty.result === 0
                                        ? _c("span", { staticClass: "fail" }, [
                                            _vm._v("首检不合格")
                                          ])
                                        : _vm._e(),
                                      qualty.result === 0
                                        ? _c(
                                            "span",
                                            { staticStyle: { color: "#f00" } },
                                            [
                                              _vm._v(
                                                "不合格说明：" +
                                                  _vm._s(qualty.ngExplain)
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ])
                                  : _vm._e()
                              ]),
                              _c("div", { staticClass: "report-params" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "params-li",
                                    on: {
                                      click: function($event) {
                                        return _vm.selectQualityRecord(
                                          idxs,
                                          index
                                        )
                                      }
                                    }
                                  },
                                  [
                                    qualty.qualityChecked
                                      ? _c("span", {
                                          staticClass: "el-icon-arrow-up"
                                        })
                                      : _c("span", {
                                          staticClass: "el-icon-arrow-right"
                                        }),
                                    _c("span", [_vm._v("检验记录")])
                                  ]
                                ),
                                qualty.qualityChecked
                                  ? _c(
                                      "div",
                                      [
                                        qualty.inspectionEntryList &&
                                        qualty.inspectionEntryList.length > 0
                                          ? _c("inspection-record", {
                                              ref: "qualityTable",
                                              refInFor: true,
                                              staticStyle: {
                                                "margin-right": "20px"
                                              },
                                              attrs: {
                                                "base-form-data": qualty
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ])
                            ])
                          ]
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "li-right-arrow" })
            ])
          ])
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "li-le" }, [
      _c("div", { staticClass: "line" }),
      _c("img", {
        staticClass: "img",
        attrs: { src: require("@/assets/quality/record.png") }
      }),
      _c("div", { staticClass: "title" }, [_vm._v("外协记录")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "li-le" }, [
      _c("div", { staticClass: "line" }),
      _c("img", {
        staticClass: "img",
        attrs: { src: require("@/assets/quality/record.png") }
      }),
      _c("div", { staticClass: "title" }, [_vm._v("报工记录")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "li-le" }, [
      _c("div", { staticClass: "line" }),
      _c("img", {
        staticClass: "img",
        attrs: { src: require("@/assets/quality/record.png") }
      }),
      _c("div", { staticClass: "title" }, [_vm._v("报废记录")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "li-le" }, [
      _c("div", { staticClass: "line organce" }),
      _c("img", {
        staticClass: "img",
        attrs: { src: require("@/assets/quality/record.png") }
      }),
      _c("div", { staticClass: "title" }, [_vm._v("质检记录")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }