import { whether } from '@/config/options.config'

// 任务详情
export const taskDetailColumns = [
  {
    colSpan: 12,
    key: 'planName',
    tagName: "div",
    label: '任务名称',
    required: false,
  },
  {
    colSpan: 12,
    key: 'planTypeName',
    tagName: 'el-date-picker',
    label: '任务类别',
    required: false
  },
  {
    colSpan: 12,
    key: 'projectNumber',
    tagName: "div",
    label: '所属项目编号',
    required: false,
  },
  {
    colSpan: 12,
    key: 'projectName',
    tagName: 'el-date-picker',
    label: '所属项目名称',
    required: false
  },
  {
    colSpan: 12,
    key: 'startTime',
    tagName: 'div',
    label: '计划开始时间',
    props: { type: 'datetime',valueFormat: 'yyyy-MM-dd HH:mm:ss' },
    required: false
  },
  {
    colSpan: 12,
    key: 'finishTime',
    tagName: 'el-date-picker',
    label: '计划结束时间',
    props: { type: 'datetime',valueFormat: 'yyyy-MM-dd HH:mm:ss' },
    required: false
  },
  {
    colSpan: 12,
    key: 'actualStartTime',
    tagName: 'div',
    label: '实际开始时间',
    props: { type: 'datetime',valueFormat: 'yyyy-MM-dd HH:mm:ss' },
    required: false
  },
  {
    colSpan: 12,
    key: 'actualFinishTime',
    tagName: 'el-date-picker',
    label: '实际结束时间',
    props: { type: 'datetime',valueFormat: 'yyyy-MM-dd HH:mm:ss' },
    required: false
  },
  {
    colSpan: 12,
    key: 'lastUpdateTime',
    tagName: 'div',
    label: '更新时间',
    props: { type: 'datetime',valueFormat: 'yyyy-MM-dd HH:mm:ss' },
    required: false
  },
  {
    colSpan: 12,
    key: 'createTime',
    tagName: 'el-date-picker',
    label: '创建时间',
    props: { type: 'datetime',valueFormat: 'yyyy-MM-dd HH:mm:ss' },
    required: false
  },
  {
    colSpan: 12,
    key: 'principalDepartName',
    tagName: 'div',
    label: '负责部门',
    required: false
  },
  {
    colSpan: 12,
    key: 'principalName',
    tagName: 'div',
    label: '负责人',
    required: false
  },
  {
    colSpan: 24,
    key: 'processPercent',
    tagName: 'div',
    label: '进度',
    required: false,
    slotName: 'processPercent',
  },
  {
    colSpan: 24,
    key: 'workHours',
    tagName: 'div',
    label: '累计工时(h)',
    required: false
  },
  {
    colSpan: 24,
    key: "relatedFiles",
    tagName: "div",
    label: "相关文件",
    required: false
  },
  {
    colSpan: 24,
    key: 'reportRemark',
    tagName: 'div',
    label: '备注',
    required: false
  },
  {
    colSpan: 24,
    label: "附件",
    key: "attachments",
    tagName: "div",
    required: false,
  }
]

// 汇报记录列表
export const reportRecordColumns = [
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'reportUserName',
    label: '汇报人',
    sortable: false,
    hiddenSearch: true,
    width: 150
  },
  {
    prop: 'createTime',
    label: '汇报时间',
    sortable: false,
    hiddenSearch: true,
    width: 150
  },
  {
    prop: 'processPercent',
    label: '汇报进度',
    sortable: false,
    hiddenSearch: true,
    slotName: 'processPercent',
    width: 220
  },
  {
    prop: 'workHours',
    label: '工时(h)',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'relatedFiles',
    label: '相关文件',
    sortable: false,
    hiddenSearch: true,
    slotName:'relatedFiles',
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    width: 150
  },
  {
    prop: 'attachments',
    label: '附件',
    sortable: false,
    hiddenSearch: true,
    slotName:'attachments',
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 40
  }
]

// 任务汇报字段
export const taskReportFormColumns = [
  {
    prop: 'processPercent',
    label: '进度(%)',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input-number', required: true, attrs: {min: 0, max: 100, 'controlsPosition': 'right'} }
  },
  {
    prop: 'workHours',
    label: '工时(h)',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input-number', required: true, attrs: {min: 0, 'controlsPosition': 'right'} }
  },
  {
    prop: 'relatedFiles',
    label: '相关文件',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input', required: false, props: { type: 'textarea', placeholder: '请填写文件链接' }}
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input', required: false, props: { type: 'textarea', placeholder: '请填写备注' } }
  },
  {
    prop: 'attachments',
    label: '附件',
    sortable: false,
    hiddenSearch: true,
    slotName: 'attachments',
    form: {
      colSpan: 24,
      tagName: 'div',
      required: false,
      customSlots: ['drawing']
    }
  }
]
