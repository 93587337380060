import { render, staticRenderFns } from "./RequirementsClass.vue?vue&type=template&id=eee0d5c4&scoped=true&"
import script from "./RequirementsClass.vue?vue&type=script&lang=js&"
export * from "./RequirementsClass.vue?vue&type=script&lang=js&"
import style0 from "./RequirementsClass.vue?vue&type=style&index=0&id=eee0d5c4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eee0d5c4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/mubai-mom-frontend-dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('eee0d5c4')) {
      api.createRecord('eee0d5c4', component.options)
    } else {
      api.reload('eee0d5c4', component.options)
    }
    module.hot.accept("./RequirementsClass.vue?vue&type=template&id=eee0d5c4&scoped=true&", function () {
      api.rerender('eee0d5c4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/project/MRP/projectManagement/task-nodes/components/RequirementsClass.vue"
export default component.exports