var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "procedure-detail-wrapper" }, [
    _c("div", { staticClass: "procedure-detail-header flex-sbc" }, [
      _c(
        "div",
        {
          staticClass: "left",
          on: {
            click: function($event) {
              return _vm.$router.push({ name: "QualityPlanList" })
            }
          }
        },
        [
          _c("img", {
            staticStyle: { width: "8px" },
            attrs: {
              src: require("@/assets/information/procedure/左滑@2x.png")
            }
          }),
          _c(
            "span",
            { staticStyle: { "padding-left": "10px", cursor: "pointer" } },
            [_vm._v(_vm._s(_vm.messages[_vm.params.type + "O"]))]
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "right" },
        [
          _vm.permission("qualityInspectionPlanListModify", false) ||
          _vm.saveButton
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.buttonLoading },
                  on: { click: _vm.switchOrSubmit }
                },
                [_vm._v(" " + _vm._s(_vm.saveButton ? "保存" : "编辑") + " ")]
              )
            : _vm._e()
        ],
        1
      )
    ]),
    _c("div", { staticClass: "procedure-detail-body" }, [
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _vm.saveButton
              ? _c("MFormBuilder", {
                  ref: "baseForm",
                  attrs: {
                    "form-list": _vm.baseFormList,
                    "form-data": _vm.baseFormData
                  }
                })
              : _c(
                  "el-row",
                  {
                    staticClass: "view-list",
                    staticStyle: { "flex-flow": "row wrap" },
                    attrs: { type: "flex", justify: "start", gutter: 24 }
                  },
                  _vm._l(_vm.columns.base, function(item, index) {
                    return _c(
                      "el-col",
                      {
                        key: item.prop,
                        style: { marginBottom: index !== 4 ? "20px" : 0 },
                        attrs: { span: 6 }
                      },
                      [
                        _c("span", { staticClass: "bot-tis" }, [
                          _vm._v(_vm._s(item.label) + "：")
                        ]),
                        _c("span", { staticClass: "bot-tis" }, [
                          _vm._v(_vm._s(_vm.getValue(item)))
                        ])
                      ]
                    )
                  }),
                  1
                ),
            _c("quality-inspection-project", {
              ref: "qualityInspectionProject",
              attrs: {
                "inspection-entry-map":
                  _vm.inspectionProjectGroup.inspectionEntryMap,
                attachments: _vm.inspectionProjectGroup.attachments,
                "user-list": _vm.inspectionProjectGroup.userList,
                "read-only": !_vm.saveButton,
                baseFormData: _vm.baseFormData
              }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }