<template>
  <div>
    <div class="de-container">
      <FunctionHeader
        v-model="functionHeader.searchVal"
        :tags="functionHeader.tags"
        :search-title="searchTitle"
        @search="$refs.mTable.setTableData(true)"
      />
      <MTable ref="mTable" :columns="columns" :set-data-method="getTableData" :columns-key="columnsKey" :height="height">
        <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
        <div slot="action" slot-scope="{ row }">
          <el-button v-if="row.status === 0 && permission('TransferScrap')" type="text" @click="transferscrap(row)">移至废品仓</el-button>
          <el-button v-if="row.status === 1 && permission('TransferScrap')" type="text" disabled>移至废品仓</el-button>
        </div>
      </MTable>
      <div>
        <!-- 新增 -->
        <MDialog
          v-model="visible"
          :append-to-body="true"
          title="新增"
          width="90%"
          @onOk="submitunquality"
        >
          <div class="drawer-top">
            <el-button type="primary" class="e-btn" @click="selectadd">选择添加物料</el-button>
            <el-input
              v-model="searchForm.Batchcode"
              placeholder="请扫/请输入批次码"
              class="e-ipt"
              @change="searchBatch"
            />
          </div>
          <div class="container">
            <template>
              <el-table :data="addunquality" style="width: 100%">
                <el-table-column label="序号" prop="id" width="80" />
                <el-table-column prop="name" label="物料名称" width="150" />
                <el-table-column label="规格" prop="specifications" width="120" />
                <el-table-column label="批次码">
                  <template slot-scope="scope">
                    <div class="all-tips">
                      <span v-if="scope.row.isBatchManager === 1" class="coltip">*</span>
                      <el-select
                        v-model="scope.row.batchNumber"
                        style="width:180px"
                        placeholder="请选择"
                        filterable
                        @focus="getBatchNumber(scope.row.id)"
                      >
                        <el-option
                          v-for="item in numberList"
                          :key="item.batchNumber"
                          :label="item.batchNumber"
                          :value="item.batchNumber"
                        />
                      </el-select>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="单位" prop="mainUnitName" />
                <el-table-column label="数量" width="120">
                  <template slot-scope="scope">
                    <div class="all-tips">
                      <span class="coltip">*</span>
                      <el-input v-model="scope.row.quantity" />
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="质检方案" width="150">
                  <template slot-scope="scope">
                    <div class="all-tips">
                      <el-select
                        v-model="scope.row.qualityInspectionPlanId"
                        style="width:180px"
                        placeholder="请选择"
                      >
                        <el-option
                          v-for="item in PlanList"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        />
                      </el-select>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope"> <el-button type="text" style="color: red;" @click="handleDetele(scope.row.id)">删除</el-button></template>
                </el-table-column>
              </el-table>
            </template>
          </div>
        </MDialog>
        <el-dialog
          title="请输入物料名称"
          :visible.sync="state.materianamestate"
          width="30%"
          :before-close="handleClose"
        >
          <el-input v-model="materianames" />
          <span slot="footer" class="dialog-footer">
            <el-button @click="cancle">取 消</el-button>
            <el-button type="primary" @click="sure">确 定</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
    <select-materials :visible.sync="showMaterials" :select-materials="selectMaterialList" @submitForm="submitMaterials" />
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import { qualityUnit } from './columns'
import serve from '@/api/quality/unquality'
import api from '@/api/information/print-label/batch-number'
import SelectMaterials from '@/components/SelectMaterials/SelectMaterials.vue'
import { getUUid } from '@/utils/index'
import { Encrypt } from '@/utils/sercet'
export default {
  name: 'Unquality',
  components: {
    SelectMaterials,
    FunctionHeader
  },
  data() {
    return {
      selectMaterialList: [],
      numberList: [],
      materialId: '',
      visible: false,
      functionHeader: {
        searchVal: '',
        tags: [
          {
            hidden: !this.permission('Add'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: this.createTemplate
            }
          }
        ]
      },
      searchTitle: '请输入批次码',
      columns: qualityUnit,
      pagination: {
        total: 1,
        limit: 10,
        page: 1
      },
      materianames: '',
      materialsName: '',
      state: {
        materianamestate: false,
        drawer: false
      },
      changelsit: {
        qualityInspectionPlanType: ''
      },
      direction: 'rtl',
      searchForm: {
        searchVal: '',
        Batchcode: '',
        note: '',
        reBatchcode: '',
        num: 1
      },
      tableData: [],
      unquality: [],
      addunquality: [],
      showMaterials: false,
      PlanList: [],
      unitLlist: []
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 192
    },
    columnsKey() {
      return this.$route.name + 'quality'
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    }
  },
  methods: {
    async getBatchNumber(id) {
      const search = []
      search.push({
        id: getUUid(),
        fieldName: 'materialsId',
        fieldType: 'number',
        fieldValue: id,
        operator: 'eq'
      })
      const res = await api.getBatchNumberList({
        searchVal: Encrypt(JSON.stringify([...search]))
      })
      if (res) {
        this.numberList = res
      }
    },
    createTemplate() {
      this.visible = true
      this.searchForm.Batchcode = ''
      this.addunquality = []
      this.selectMaterialList = []
    },
    selectadd() {
      this.addunquality = []
      this.showMaterials = true
      serve.getQualityPlan().then(res => {
        console.log(res.records)
        this.PlanList = res.filter(item => {
          return item.objectType === 2
        })
        console.log(this.PlanList)
      })
      serve.getMaterialsUnit(this.pagination).then(res => {
        console.log(res)
        this.unitLlist.pushres
      })
    },
    change(val) {
      console.log('val', val)
    },
    // 取消
    cancle() {
      this.state.materianamestate = false
      this.addunquality = []
    },
    // 新增物料确认
    sure() {
      this.materialsName = this.materianames
      this.materianames = ''
      this.addunquality.push({
        materialsName: this.materialsName,
        materialsUnitvalue: ''
      })
      this.state.materianamestate = false
    },
    // 提交新增不合格品
    submitunquality(done) {
      done()
      const ISFIRST = []
      this.addunquality.map(item => {
        if (item.isBatchManager === 1 && !item.batchNumber) {
          ISFIRST.push('1')
        } else {
          ISFIRST.push('2')
        }
      })
      const BATCHNUM = ISFIRST.every(item => item === '2')
      const QUALITY = this.addunquality.every(item => item.quantity)
      // const QUALITYID = this.addunquality.every(item => item.qualityInspectionPlanId)
      if (QUALITY && BATCHNUM) {
        const resetData = this.addunquality.map(item => ({
          materialsId: item.id,
          batchNumber: item.batchNumber,
          materialsCode: item.code,
          materialsName: item.name,
          materialsSpecifications: item.specifications,
          materialsUnit: item.mainUnitName,
          quantity: Number(item.quantity),
          qualityInspectionPlanId: item.qualityInspectionPlanId ? item.qualityInspectionPlanId : null,
          qualityInspectionPlanName: item.qualityInspectionPlanId ? this.PlanList.find(p => p.id === item.qualityInspectionPlanId).name : null,
          qualityInspectionPlanObjectType: 2,
          qualityInspectionPlanType: item.qualityInspectionPlanId ? this.PlanList.find(p => p.id === item.qualityInspectionPlanId).type : null
        }))
        serve.addUnQualityList(resetData).then(res => {
          this.visible = false
          this.addunquality = []
          this.$refs.mTable.setTableData()
          this.$message.success('添加成功')
        })
      } else {
        this.$message.error('请完善表格数据')
      }
    },
    searchBatch() {
      serve.getMaterialsBatch(this.searchForm.Batchcode).then(res => {
        this.addunquality.push(res)
        this.searchForm.Batchcode = ''
      })
    },
    handleDetele(id) {
      var res = this.addunquality.filter(item => item.id !== id)
      this.addunquality = res
    },
    async getTableData(condition, callback) {
      const { page, search, order } = condition
      if (this.functionHeader.searchVal) {
        search.push({
          id: getUUid(),
          fieldName: 'batchNumber',
          fieldType: 'string',
          fieldValue: this.functionHeader.searchVal,
          operator: 'like'
        })
      }
      const res = await serve.getUnQuality({
        page: page.currentPage,
        limit: page.pageSize,
        sort: order,
        searchVal: Encrypt(JSON.stringify(search))
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    handelChange() {
      console.log(this.options.materialsUnitvalue)
    },
    transferscrap(row) {
      serve.moveJunkBank(row.id).then(res => {
        this.$refs.mTable.setTableData()
        this.$message.success('已移入废品仓')
      })
    },
    handleClose(done) {
      done()
    },
    submitMaterials(val) {
      console.log(val)
      if (val.length > 0) {
        this.addunquality = val
        this.selectMaterialList = val
      }
      this.showMaterials = false
    }
  }
}
</script>

<style lang="scss" scoped>
.de-container{
  padding: 0 10px 10px 10px;
}
.drawer-top{
  display: flex;
  justify-content: space-between;
  .e-ipt{
    width: 250px;
  }
}
.conter {
  .add {
    width: 140px;
    background: rgb(24, 144, 255);
  }
}
.search {
  float: right;
  margin-right: 20px;
  input {
    width: 140px;
    height: 32px;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #a4b3c6;
    letter-spacing: 0;
    text-align: justify;
    background: #ffffff;
    border: 1px solid #dde3eb;
    border-radius: 4px;
    outline: none;
  }
  .search-btn {
    width: 80px;
    height: 32px;
    background: #607fff;
    border-radius: 0px;
    color: #ffffff;
    outline: none;
    border: none;
  }
}
.form {
  .operation {
    a {
      color: #607fff;
      margin-right: 5px;
    }
  }
}
.coltip{
  color:#f00;
  margin-right: 5px;
}
.all-tips{
  display: flex;
  justify-content: flex-start;
}
</style>
