<template>
  <div id="apps">
    <!-- 左中 -->
    <div id="containers-left" ref="myImg" :class="!fullscreen ? 'app-left-full' : showTip ? 'app-left' : 'app-width'">
      <header class="top-bar">
        <el-form :inline="true" size="small" class="gatter-form">
          <div class="form-li">
            <div class="common-date">
              <el-form-item label="">
              <el-select
                v-model="scale"
                style="width: 250px"
                size="small"
                @change="selectScale"
              >
                <el-option
                  v-for="item in scaleList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
              <div class="today" @click="nowMonth">今天</div>
              <img src="@/assets/plans/left-arro.png" @click="addMonth(2)">
              <div class="date-tit">{{ fixTime(commonDate) }}</div>
              <img src="@/assets/plans/right-arro.png" @click="addMonth(1)">
              <el-form-item label="">
              <el-select
                v-model="planTypeId"
                style="width: 250px"
                size="small"
                @change="choosePlanType"
                placeholder="请选择计划类型"
                filterable
                clearable
                multiple
              >
                <el-option
                  v-for="item in planTypeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-select
                v-model="planId"
                style="width: 250px"
                size="small"
                @change="choosePlanName"
                placeholder="请选择计划"
                filterable
                clearable
                multiple
              >
                <el-option
                  v-for="item in planList"
                  :key="item.planName"
                  :label="item.planName"
                  :value="item.planName"
                />
              </el-select>
            </el-form-item>
            </div>
          </div>
        </el-form>
      </header>
      <div ref="container" class="containers">
        <v-gantt-chart
        :startTime="times[0]"
        :endTime="times[1]"
        :cellWidth="cellWidth"
        :cellHeight="cellHeight"
        :timeLines="timeLines"
        :titleHeight="titleHeight"
        :scale="scale"
        :titleWidth="titleWidth"
        showCurrentTime
        :hideHeader="hideHeader"
        :dataKey="dataKey"
        :arrayKeys="arrayKeys"
        :scrollToPostion="positionA"
        @scrollLeft="scrollLeftA"
        :datas="datasA"
        v-loading="loading"
        :scrollToTime="scrollToTime"
        >
          <template v-slot:block="{ data, item , getPositonOffset, getWidthAbout2Times }">
            <Test
            :data="data"
            :updateTimeLines="updateTimeLines"
            :cellHeight="cellHeight"
            :currentTime="currentTime"
            :item="item"
            @modifys="modify"
            :getPositonOffset="getPositonOffset"
            :getWidthAbout2Times="getWidthAbout2Times"
              @viewWorkReport="viewWorkReport"
            />
          </template>

          <template v-slot:title>
            <div class="common-header">
              <div class="header-le">
                <el-popover
                  ref="popover"
                  v-model="showPopover"
                  :width="291"
                  :offset="20"
                  style="padding: 12p 12px 0"
                  @hide="show = true"
                >
                  <div class="popover-content-top">
                    <el-button @click="cancel">取消</el-button>
                    <el-button @click="saveColumns">确定</el-button>
                  </div>
                  <div class="popover-content-center">
                    <el-checkbox v-model="allChecked" @change="selectAllColumns">全选</el-checkbox>
                    <el-button class="invert" type="text" @click="invertSelectColumns">
                      <img src="@/assets/table_filter_images/fanxuan@2x.png" alt="反选" width="13px">
                      <span>反选</span>
                    </el-button>
                  </div>
                  <div class="popover-content-footer" :style="{maxHeight: popoverContentMaxHeight + 'px'}">
                    <el-checkbox-group v-model="checkedList" class="check-wrapper">
                      <el-checkbox v-for="(item, index) in settingColumns" :key="index" :label="item.id">{{ item.projectName }}</el-checkbox>
                    </el-checkbox-group>
                  </div>
                </el-popover>
                <el-button ref="columnsSettingBtn" type="text" style="padding: 0">
                  <div class="showMore">
                    <span>项目</span>
                  </div>
                </el-button>
              </div>

              <div class="header-ri" @click="toggleFullScreen">
                <el-tooltip v-if="fullscreen" class="item" effect="dark" content="放大" placement="top">
                  <img class="ri-img" src="@/assets/narrow-amplification/amplification.png" alt="">
                </el-tooltip>
                <el-tooltip v-if="!fullscreen" class="item" effect="dark" content="缩小" placement="top">
                  <img class="ri-img" src="@/assets/narrow-amplification/narrow.png">
                </el-tooltip>
              </div>
            </div>
          </template>
          <template v-slot:left="{ data }">
            <TestLeft :data="data" @selectLine="selectLine" @viewAbnormalList="viewAbnormalList" @viewECNList="viewECNList" @viewMaterialsDiffList="viewMaterialsDiffList" />
          </template>
        </v-gantt-chart>
        <div class="tip-img" @click="selectImg">
          <img v-if="showTip" src="@/assets/plans/spread.png">
          <img v-else src="@/assets/plans/retract.png" alt="">
        </div>
      </div>

    </div>
    <!-- 右边栏 -->
    <div class="app-right">
      <schedule-right v-if="showTip" ref="schedules" :area-id="areaId" :area-name="areaName" @modify="modify" :planTypeIds="planTypeIds" :planNames="planNames"/>
    </div>
    <!-- 修改计划节点 -->
    <MDialog
      v-model="dialogVisible"
      title="修改计划节点"
      width="80%"
      @onOk="dialogSubmit"
      :show-footer="permission('Modify')"
    >
      <div class="edit-form">
        <div class="form-top">
          <base-data :detail-data="dialogFormData"/>
        </div>
        <div style="margin: 10px 0">
        <el-radio-group v-model="modifyType" class="m-radio-group right-radio" @change="radioModifyChange">
      <!-- 0是固定 -->
      <el-radio-button label="adjustTime" v-if="dialogFormData.nodeAttribute === 1 || dialogFormData.startTimeAttribute===1 || dialogFormData.finishTimeAttribute ===1">调整</el-radio-button>
      <el-radio-button label="modifyTime">修改</el-radio-button>
    </el-radio-group>
        </div>
        <MFormBuilder
          ref="dialogFormBuild"
          :form-data="dialogFormData"
          :form-list="modifyType === 'adjustTime' ? adjustFormList : haveDialogFormList"
          :custom-rules="rules"
        >
        <div slot="startTime">
        <el-date-picker v-show="showStartTime"
            v-model="params.planStartTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
            style="width:100%"
            placeholder="开始时间"
            :disabled="isChooseStartAttr"
          />
      <div style="display:flex" v-show="!showStartTime">
        <el-select v-model="params.startPlanId" style="width:100%" placeholder="请选择" @change="changeStartTimePlan" clearable filterable>
            <el-option
              v-for="item in planNodeTimeList"
              :key="item.id"
              :label="item.fullName"
              :value="item.id"
            />
          </el-select>
          <el-input-number style="margin-left:10px;"
            v-model="params.startTimeBasis"
            controls-position="right"
            class="min-input"
          />天
      </div>
      </div>
      <div slot="finishTime">
        <el-date-picker  v-show="showFinishTime"
            v-model="params.planEndTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
            style="width:100%"
            defaultTime='23:59:59'
            placeholder="完成时间"
            :disabled="isChooseFinishAttr"
          />
          <div style="display:flex" v-show="!showFinishTime">
        <el-select v-model="params.finishPlanId" style="width:100%" placeholder="请选择" @change="changeFinishTimePlan" clearable filterable>
            <el-option
              v-for="item in planNodeTimeList"
              :key="item.id"
              :label="item.fullName"
              :value="item.id"
            />
          </el-select>
          <el-input-number style="margin-left:10px;"
            v-model="params.finishTimeBasis"
            controls-position="right"
            class="min-input"
          />天
      </div>
      </div>
        </MFormBuilder>
      </div>
    </MDialog>
  <!-- 生产进度 -->
  <MDialog
    v-model="visibleProgress"
    title="报工进度详情"
    :show-footer="false"
    width="870px"
    @onOk="processVisible = false"
  >
    <div class="detail-box">
      <div v-for="(item,idx) in progressDetail" :key="idx" class="item-box">
        <div v-for="i in item" :key="i.procedureLocationCode" class="item" :class="['item-wait','item-running','item-finished'][i.productionStatus]">
          <img v-if="i.productionStatus===0" src="@/assets/plans/progress-0.png" class="sign">
          <img v-if="i.productionStatus===1" src="@/assets/plans/progress-1.png" class="sign">
          <img v-if="i.productionStatus===2" src="@/assets/plans/progress-2.png" class="sign">
          <el-tooltip class="item" effect="dark" :content="i.procedureName" placement="top">
            <div>{{ i.procedureName }}</div>
          </el-tooltip>
          <div class="progress-bg" style="bottom: 5px !important;">
            <div class="progress-bar" :style="{width:i.productionTempo+'%'}" />
          </div>
          <div  class="progress-bg">
            <div class="apply-bar" :style="{width:i.materialsRequisitionTempo+'%'}" />
          </div>
        </div>
      </div>
    </div>
  </MDialog>
      <!-- 修改项目所有计划节点 -->
      <MDialog
      v-model="projectNodesVisible"
      title="修改计划节点"
      width="80%"
      @onOk="dialogBatchSubmit"
      :show-footer="permission('Modify')"
    >
      <div class="edit-form">
        <div class="form-top">
          <all-base-data :detail-data="projectNodesFormData" />
        </div>
        <div style="margin: 10px 0">
        <el-radio-group v-model="projectType" class="m-radio-group right-radio" @change="radioProjectChange">
      <!-- 0是固定 -->
      <el-radio-button label="adjustTime">调整</el-radio-button>
      <el-radio-button label="modifyTime">修改</el-radio-button>
    </el-radio-group>
        </div>
        <div class="list">
          <MTable ref="mTable" :columns="modifyColumns" height="400" :data="tableData" :show-page="false">
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          <div slot="startTimeAttribute" slot-scope="{ row }" >
            <el-select v-model="row.startTimeAttribute" placeholder="" clearable @change="(val) => startTimeProjectAttrChange(row,val)">
              <el-option
                v-for="item in startFinishTimeAttribute"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>
          <div slot="finishTimeAttribute" slot-scope="{ row }" >
            <el-select v-model="row.finishTimeAttribute" placeholder="" clearable @change="(val) => finishTimeProjectAttrChange(row,val)">
              <el-option
                v-for="item in startFinishTimeAttribute"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
      </div>
          <div slot="startTime" slot-scope="{row }">
          <el-date-picker
          v-show="row.showStartTime"
                v-model="row.startTime"
                type="datetime"
                placeholder="开始时间"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                :default-time="'00:00:00'"
                :disabled="row.startTimeAttribute !=1 && projectType === 'adjustTime'"
                clearable
                style="width:100%"
              />
              <div style="display:flex;align-items:center" v-show="!row.showStartTime">
        <el-select v-model="row.startPlanId" style="width:100%" placeholder="请选择" @change="(val) => changeProjectStartTimePlan(row,val)" clearable filterable>
            <el-option
              v-for="item in row.planNodeTimeList"
              :key="item.id"
              :label="item.fullName"
              :value="item.id"
            />
          </el-select>
          <el-input-number style="margin-left:10px;"
            v-model="row.startTimeBasis"
            controls-position="right"
            class="min-input"
          />天
      </div>
          </div>
          <div slot="finishTime" slot-scope="{row}">
          <el-date-picker v-show="row.showFinishTime"
                v-model="row.finishTime"
                type="datetime"
                placeholder="完成时间"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                :default-time="'00:00:00'"
                :disabled="row.isChooseFinishAttr || (row.finishTimeAttribute !=1 && projectType === 'adjustTime')"
                clearable
                style="width:100%"
              />
              <div style="display:flex;align-items:center" v-show="!row.showFinishTime">
        <el-select v-model="row.finishPlanId" style="width:100%" placeholder="请选择" @change="(val) => changeProjectFinishTimePlan(row,val)" clearable filterable>
            <el-option
              v-for="item in row.planNodeTimeList"
              :key="item.id"
              :label="item.fullName"
              :value="item.id"
            />
          </el-select>
          <el-input-number style="margin-left:10px;"
            v-model="row.finishTimeBasis"
            controls-position="right"
            class="min-input"
          />天
      </div>
          </div>

          <div slot="startAdjustTime" slot-scope="{row }">
          <el-date-picker
                v-model="row.startTime"
                type="datetime"
                placeholder="开始时间"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                :default-time="'00:00:00'"
                :disabled="row.startTimeAttribute !=1 && projectType === 'adjustTime'"
                clearable
                style="width:100%"
              />
          </div>
          <div slot="finishAdjustTime" slot-scope="{row}">
          <el-date-picker
                v-model="row.finishTime"
                type="datetime"
                placeholder="完成时间"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                :default-time="'00:00:00'"
                :disabled="(row.finishTimeAttribute !=1 && projectType === 'adjustTime')"
                clearable
                style="width:100%"
              />
          </div>
          <div slot="adjustReason" slot-scope="{row}">
            <el-input
                v-model="row.adjustReason"
                placeholder=""
              />
          </div>
        </MTable>
        </div>
      </div>
    </MDialog>

    <MDialog v-model="viewAbnormalVisible" @fullScreen="fullScreenChange" title="异常处理" :show-footer="false">
      <MTable
      ref="abnormalTable"
        :columns="projectAbnormalColumns"
        :height="dialogTableHeight"
        :set-data-method="getAbnormalTableData"
        :columns-setting="false"
      >
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        <div slot="majorType" slot-scope="{row }">{{ row.majorType|majorTypeOptions }}</div>
        <div slot="status" slot-scope="{row }">{{ handleStatusMap[row.status] }}</div>
        <div slot="judgeResult" slot-scope="{row }">{{ judgeResultMap[row.judgeResult] }}</div>
        <div slot="finalResult" slot-scope="{row }">{{ finalResultMap[row.finalResult] }}</div>
      </MTable>
    </MDialog>
    <MDialog v-model="viewECNVisible"  @fullScreen="fullScreenChange" title="变更列表" :show-footer="false">
      <MTable
      ref="ecnTable"
        :columns="projectECNColumns"
        :height="dialogTableHeight"
        :set-data-method="getECNTableData"
        :columns-setting="false"
      >
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        <div slot="needOperate" slot-scope="{ row }" >
            <span>{{ row.needOperate ? '不需要':'需要' }}</span>
          </div>
          <div slot="changeStatus" slot-scope="{ row }" >
            <span>{{ changeStatus[row.status].name }}</span>
          </div>
          <div slot="changeType" slot-scope="{ row }" >
            <span>{{ changeType[row.changeType].name }}</span>
          </div>
      </MTable>
    </MDialog>
    <MDialog v-model="viewMaterialsDiffVisible"  @fullScreen="fullScreenChange" title="物料差异表" :show-footer="false">
      <MTable
      ref="materialsDiffTable"
        :columns="projectMaterialsDiffColumns"
        :height="dialogTableHeight"
        :set-data-method="getMaterialsDiffTableData"
        :columns-setting="false"
      >
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
      </MTable>
    </MDialog>
  </div>
</template>

<script>
import Test from './components/test.vue'
import TestLeft from './components/test-left.vue'
import TestTimeline from './components/test-timeline.vue'
import ScheduleRight from './components/schedule-right.vue'
import BaseData from './components/base-data.vue'
import AllBaseData from './components/all-base-data.vue'
import dayjs from 'dayjs'
import apis from '@/api/production/plans'
import { dialogFormList, haveDialogFormList,adjustFormList } from './components/form-list'
import papi from '@/api/projectManagement/project'
import { getFunctionList,projectNodesColumns,projectNodesAdjustColumns,projectAbnormalColumns,projectECNColumns,projectMaterialsDiffColumns } from "./components/columns"
import wapi from '@/api/production/prod-plan'
import { startFinishTimeAttribute,majorTypeOptions,changeStatus,changeType } from '@/config/options.config'
import { handleStatusMap, judgeResultMap,finalResultMap } from '@/views/call/search/data'
const scaleList = `1440, 10080, 43200, 525600`
  .split(',')
  .map(n => {
    const value = parseInt(n)
    let label
    if (value < 60) {
      label = value + '分钟'
    } else if (value >= 60 && value < 1440) {
      label = '小时视图'
    } else if (value >= 1440 && value < 10080) {
      label = '日视图'
    } else if (value >= 10080 && value < 43200) {
      label = '周视图'
    } else if (value >= 43200 && value < 525600) {
      label = '月视图'
    } else {
      label = '年视图'
    }
    return {
      value,
      label
    }
  })
export default {
  name: 'PlanSchedules',
  components: { Test, TestLeft, TestTimeline, ScheduleRight, BaseData, AllBaseData },
  filters: {
    majorTypeOptions(val) {
      if (val == 0 || val) return majorTypeOptions.find((item) => item.id === val).name
    }
  },
  data() {
    return {
      commonDate: dayjs().format('YYYY-MM'),
      scrollTop: 0,
      dialogFormList,
      haveDialogFormList,
      adjustFormList,
      loading: false,
      processIdAndVersionId: [],
      startFinishTimeAttribute,
      majorTypeOptions,
      handleStatusMap,
      judgeResultMap,
      finalResultMap,
      changeStatus,
      changeType,
      fullDialogScreen:false,
      params: {
        processFlowName: '',
        processFlowId: undefined,
        processFlowVersionId: undefined,
        planStartTime:'',
        planEndTime:'',
        startPlanId:'',
        finishPlanId:'',
        startTimeBasis:'',
        finishTimeBasis:'',
      },
      isStatus: true,
      dialogVisible: false,
      isType: undefined,
      columnsBase: [],
      dialogFormData: {},
      projectNodesFormData:{},
      formDisabled: false,
      projectNodesColumns,
      projectNodesAdjustColumns,
      rules: {
        processFlowId: [
          { required: true, message: '请选择工艺', trigger: 'change' }
        ],
        areaId: [
          { required: true, message: '请选择产线', trigger: 'change' }
        ],
        deliveryTime: [
          { required: true, message: '请选择交期', trigger: 'change' }
        ]
      },
      fullscreen: true,
      styleObject: {
        height: this.height
      },
      param: 'NoSchedule',
      areaId: null,
      areaName: '',
      scheduleList: [],
      showPopover: false,
      allChecked: false,
      popoverContentMaxHeight: 350,
      settingColumns: [],
      checkedList: [],
      showBtn: true,
      show: true,
      timeLines: [],
      currentTime: dayjs(),
      cellWidth: 100,
      cellHeight:60,
      titleHeight: 40,
      titleWidth: 250,
      scale: 1440,
      times: [
        dayjs().startOf('month').hour(0).minute(0)
          .toString(),
        dayjs().endOf('month').hour(23).minute(59)
          .toString()
      ],
      rowNum: 100,
      colNum: 10,
      datasA: [],
      datasB: [],
      dataKey: 'id',
      scaleList: scaleList,
      scrollToTime: dayjs().hour(0).minute(0).toString(),
      scrollToPostion: { x: 10000, y: 10000 },
      hideHeader: false,
      hideSecondGantt: false,
      arrayKeys: ['gtArray'],
      scrollToY: 0,
      positionB: {},
      positionA: {},
      lineData: [],
      showTip: true,
      formOptions: {
        flowerOptions: []
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(dayjs().set('hour', 8).minute(0))
              end.setTime(dayjs().set('hour', 20).minute(0))
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      blockWidth: this.$store.state.app.contentWidth - 265 - 247 - 366 - 39,
      currentDate: '',
      isHave: null,
      proceType: 0,
      planList:[],
      planTypeList:[],
      planTypeId:'',
      planId:'',
      modifyType:'modifyTime',
      projectType:'adjustTime',
      showFinishTime:true,
      showStartTime:true,
      isChooseFinishAttr:true,
      isChooseStartAttr:true,
      planNodeTimeList:[],
      newLineData:[],
      progressDetail:[],
      visibleProgress:false,
      startTimeRelationPlanId:'',
      finishTimeRelationPlanId:'',
      startTimeRelationTimeType:'',
      finishTimeRelationTimeType:'',
      planStartDateTime:'',
      planFinishDateTime:'',
      choosePlanNameList:[],
      choosePlanTypeList:[],
      planTypeIds:[],
      planNames:[],
      projectNodesVisible:false,
      modifyColumns:[],
      adjustTableData:[],
      modifyTableData:[],
      tableData:[],
      viewAbnormalVisible:false,
      viewAdjustRecordList:[],
      projectAbnormalColumns,
      projectECNColumns,
      projectMaterialsDiffColumns,
      projectId:'',
      viewECNVisible:false,
      viewMaterialsDiffVisible:false
    }
  },
  computed: {
    width() {
      return this.$store.state.app.contentWidth
    },
    height() {
      return this.$store.state.app.contentHeight - 289 - 48 - 192
    },
    priorityStatus() {
      return this.$store.state.user.priorityList
    },
    currentSelectIdx() {
      return this.$store.state.user.currentSelectIdx
    },
    dialogTableHeight() {
      return this.fullDialogScreen ? this.$store.state.app.contentHeight - 160 - 83 - 57 + 100 : this.$store.state.app.contentHeight - 289 - 160 - 10 + 100
    },
  },
  watch: {
    colNum: 'getLineData',
    times: 'getLineData',
    scrollToY(val) {
      // this.positionA = { x: val }
    },
    showTip(val) {
      if (val) {
        this.blockWidth = this.width - 265 - 247 - 366 - 39
      } else {
        this.blockWidth = this.width - 265 - 247 - 39
      }
      this.getScale()
    },
    fullscreen(val) {
      if (val) {
        this.blockWidth = this.width - 265 - 247 - 366 - 39
        this.showTip = true
      } else {
        this.blockWidth = this.width - 291 - 27
      }
      this.getScale()
    }
  },
  beforeMount() {
    getFunctionList({
    startTimeAttributeChange: this.startTimeAttributeChange,
    finishTimeAttributeChange: this.finishTimeAttributeChange
  })
  },
  created() {
    this.$store.dispatch('user/currentSelectIdx', {})
    this.modifyColumns = this.projectNodesAdjustColumns
  },
  mounted() {
    this.currentDate = dayjs().format('YYYY-MM-DD HH:mm:ss')
    // 获取计划名称数据
    this.getPlanNameList()
    // 获取计划类型
    this.getPlanTypeList()
  },
  methods: {
    fullScreenChange(val) {
            this.fullDialogScreen = val
        },
    startTimeAttributeChange(val){
    if(val || val === 0){
      this.isChooseStartAttr = false
      this.showStartTime = val === 2 ? false : true
    }else{
        this.isChooseStartAttr = true
        this.showStartTime = true
      }
  },
    finishTimeAttributeChange(val){
      if(val || val === 0){
        this.isChooseFinishAttr = false
        this.showFinishTime = val === 2 ? false : true
      }else{
        this.isChooseFinishAttr = true
        this.showFinishTime = true
      }

    },
    startTimeProjectAttrChange(row,val){
      if(val || val === 0){
        this.$set(row, "isChooseStartAttr", false)
        this.$set(row, "showStartTime",  val === 2 ? false : true)
    }else{
      this.$set(row, "isChooseStartAttr", true)
        this.$set(row, "showStartTime",  true)

      }
    },
    finishTimeProjectAttrChange(row,val){
      if(val || val === 0){
        this.$set(row, "isChooseFinishAttr", false)
        this.$set(row, "showFinishTime",  val === 2 ? false : true)
      }else{
        this.$set(row, "isChooseFinishAttr", true)
        this.$set(row, "showFinishTime", true)
      }
   },
    getScale() {
      const list = new Map([
        [60, 70],
        [1440, 70],
        [10080, parseInt(this.blockWidth / 5) < 120 ? 120 : parseInt(this.blockWidth / 5)],
        [43200, parseInt(this.blockWidth / 3)],
        [525600, this.blockWidth]
      ])
      this.cellWidth = list.get(this.scale)
    },
    async selectScale() {
      await this.getScale()
      const totalTime = new Map([
        [60, [
          dayjs(this.commonDate).startOf('month').hour(0).minute(0).toString(),
          dayjs(this.commonDate).endOf('month').hour(23).minute(0).toString()
        ]
        ],
        [1440, [
          dayjs(this.commonDate).startOf('month').hour(0).minute(0).toString(),
          dayjs(this.commonDate).endOf('month').hour(23).minute(59).toString()
        ]
        ],
        [10080, [
          dayjs(this.commonDate).startOf('week').hour(0).minute(0).toString(),
          dayjs(this.commonDate).endOf('month').hour(23).minute(59).toString()
        ]
        ],
        [43200, [
          dayjs(this.commonDate).startOf('month').hour(0).minute(0).toString(),
          dayjs(this.commonDate).add(2, 'month').endOf('month').hour(23).minute(59).toString()
        ]
        ],
        [525600, [
          dayjs(this.commonDate).startOf('year').hour(0).minute(0).toString(),
          dayjs(this.commonDate).endOf('year').hour(23).minute(59).toString()
        ]
        ]
      ])
      this.times = totalTime.get(this.scale)
    },
    choosePlanType(val){
      this.planTypeIds = val
    },
    choosePlanName(val){
      this.planNames = val
    },
    getMonths() {
      this.times = [
        dayjs(this.commonDate).startOf('month').hour(0).minute(0).toString(),
        dayjs(this.commonDate).add(2, 'month').endOf('month').hour(23).minute(59).toString()
      ]
    },
    getYear() {
      this.times = [
        dayjs(this.commonDate).startOf('year').hour(0).minute(0).toString(),
        dayjs(this.commonDate).endOf('year').hour(23).minute(59).toString()
      ]
    },
    getweek() {
      this.times = [
        dayjs(this.commonDate).startOf('week').hour(0).minute(0).toString(),
        dayjs(this.commonDate).endOf('month').hour(23).minute(59).toString()
      ]
    },
    getDays() {
      this.times = [
        dayjs(this.commonDate).startOf('month').hour(0).minute(0).toString(),
        dayjs(this.commonDate).endOf('month').hour(23).minute(59).toString()
      ]
    },
    getCommon() {
      if (this.scale === 525600) {
        this.getYear()
        return false
      }
      if (this.scale === 43200) {
        this.getMonths()
        return false
      }
      if (this.scale === 10080) {
        this.getweek()
        return false
      }
      if (this.scale === 1440) {
        this.getDays()
        return false
      }
      this.getDays()
    },
    addMonth(type) {
      if (type === 1) {
        this.commonDate = dayjs(this.commonDate).add(1, 'month').format('YYYY-MM')
      } else {
        this.commonDate = dayjs(this.commonDate).subtract(1, 'month').format('YYYY-MM')
      }
      this.getCommon()
    },
    nowMonth() {
      this.commonDate = dayjs().format('YYYY-MM')
      this.getCommon()
      this.scrollToTime = this.scale === 60 ? dayjs().subtract(3, 'hour').minute(0).toString() : dayjs().hour(0).minute(0).toString()
    },
    fixTime(time) {
      return time.substring(0, 4) + '年' + time.substring(5, 7) + '月'
    },
    compareDate(d1, d2) {
      if (d1 && d2) {
        return ((new Date(d1.replace(/\-/g, '\/'))) >= (new Date(d2.replace(/\-/g, '\/'))))
      }
      return false
    },
    async getPlanNameList(){
       const res = await papi.getPlanNameList()
       if(res){
         this.planList = res
       }
    },
    async getPlanTypeList(){
      const res = await papi.getPlanType()
       if(res){
         this.planTypeList = res
       }
    },
    radioModifyChange() {
   },
   radioProjectChange(val) {
    this.modifyColumns = val === 'modifyTime' ? this.projectNodesColumns : this.projectNodesAdjustColumns
    this.tableData = val === 'modifyTime' ? this.modifyTableData:this.adjustTableData
   },
  changeStartTimePlan(val){
    this.$forceUpdate()
    const currentRow = this.planNodeTimeList.find(item => item.id === val)
    if(currentRow){
      this.startTimeRelationPlanId = currentRow.planId
      this.startTimeRelationTimeType = currentRow.timeType
      this.planStartDateTime = currentRow.dateTime
    }else{
        this.startTimeRelationPlanId = ''
        this.startTimeRelationTimeType = ''
        this.planStartDateTime = ''
      }
  },
    changeFinishTimePlan(val){
      this.$forceUpdate()
      const currentRow = this.planNodeTimeList.find(item => item.id === val)
      if(currentRow){
        this.finishTimeRelationPlanId = currentRow.planId
        this.finishTimeRelationTimeType = currentRow.timeType
        this.planFinishDateTime = currentRow.dateTime
      }else{
        this.finishTimeRelationPlanId = ''
        this.finishTimeRelationTimeType = ''
        this.planFinishDateTime = ''
      }
   },
   changeProjectStartTimePlan(row,val){
    this.$forceUpdate()
    const currentRow = row.planNodeTimeList.find(item => item.id === val)
    if(currentRow){
      this.$set(row, "startTimeRelationPlanId", currentRow.planId)
      this.$set(row, "startTimeRelationTimeType", currentRow.timeType)
      this.$set(row, "planStartDateTime", currentRow.dateTime)

      // this.planStartDateTime = currentRow.dateTime
    }else{
      this.$set(row, "startTimeRelationPlanId", '')
      this.$set(row, "startTimeRelationTimeType", '')
      this.$set(row, "planStartDateTime", '')
      }
  },
    changeProjectFinishTimePlan(row,val){
      this.$forceUpdate()
      const currentRow = row.planNodeTimeList.find(item => item.id === val)
      if(currentRow){
        this.$set(row, "finishTimeRelationPlanId", currentRow.planId)
        this.$set(row, "finishTimeRelationTimeType", currentRow.timeType)
        this.$set(row, "planFinishDateTime", currentRow.dateTime)
        // this.$set(row, "planStartDateTime", currentRow.dateTime)
        // this.planFinishDateTime = currentRow.dateTime
      }else{
        this.$set(row, "finishTimeRelationPlanId", '')
        this.$set(row, "finishTimeRelationTimeType", '')
        this.$set(row, "planFinishDateTime", '')
        // this.finishTimeRelationPlanId = ''
        // this.finishTimeRelationTimeType = ''
        // this.planFinishDateTime = ''
      }
   },
    selectImg() {
      if (!this.showTip && this.$refs.schedules !== undefined) {
        this.$refs.schedules.getScheduleList()
      }
      this.showTip = !this.showTip
    },
    async selectLine(val) {
      await this.getAllPlanNodeTimeList(val)
      const datas = this._.cloneDeep(val)
      this.modifyColumns = this.projectNodesAdjustColumns
      this.projectType = 'adjustTime'
      this.projectNodesFormData = datas
      this.tableData = datas.producePlanList
      this.adjustTableData = datas.producePlanList
      let arr = datas.producePlanList.map(item =>{
        return {
          ...item,
          showStartTime:item.startTimeAttribute === 2 ? false :true,
          showFinishTime : item.finishTimeAttribute === 2 ? false :true,
          isChooseFinishAttr : item.finishTimeAttribute || item.finishTimeAttribute === 0 ? false :true,
          isChooseStartAttr : item.startTimeAttribute || item.startTimeAttribute === 0 ? false :true,
          planNodeTimeList:this.getPlanNodesTimeList(item.id),
          startPlanId : item.startTimeRelationPlanId ? item.startTimeRelationPlanId+'-'+item.startTimeRelationTimeType:'',
          finishPlanId : item.finishTimeRelationPlanId? item.finishTimeRelationPlanId+'-'+item.finishTimeRelationTimeType:'',
        }
      })
      this.modifyTableData = arr.map((item) => {
        return {
          ...item,
          planStartDateTime: item.planNodeTimeList.find(items => items.id === item.startPlanId) ? item.planNodeTimeList.find(items => items.id === item.startPlanId).dateTime :'',
          planFinishDateTime : item.planNodeTimeList.find(item => item.id === item.finishPlanId) ? item.planNodeTimeList.find(item => item.id === item.finishPlanId).dateTime :''
        }
      })
      this.projectNodesVisible = true
    },
   viewAbnormalList(val){
      this.projectId= val
        this.viewAbnormalVisible =true
        this.$nextTick(() => {
        this.$refs.abnormalTable.setTableData()
        })
    },
    async getAbnormalTableData(condition, callback) {
      const { page, search, order } = condition
      const res = await papi.getProjectAbnormalListPage({
        page: page.currentPage,
        size: page.pageSize,
        projectId:this.projectId,
        statusList: [0,1,2,3,4]
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    viewECNList(val){
      this.projectId= val
        this.viewECNVisible =true
        this.$nextTick(() => {
        this.$refs.ecnTable.setTableData()
        })
    },
    async getECNTableData(condition, callback) {
      const { page, search, order } = condition
      const res = await papi.getProjectECNListPage({
        page: page.currentPage,
        size: page.pageSize,
        projectId:this.projectId,
        needOperate: 0,
        statusList:[0,1,2,3,4]
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    viewMaterialsDiffList(val){
      this.projectId= val
        this.viewMaterialsDiffVisible =true
        this.$nextTick(() => {
        this.$refs.materialsDiffTable.setTableData()
        })
    },
    async getMaterialsDiffTableData(condition, callback) {
      const { page, search, order } = condition
      const res = await papi.getProjectMaterialsDifferenceListPage({
        page: page.currentPage,
        size: page.pageSize,
        projectId:this.projectId
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    cancel() {
      this.showPopover = false
      this.allChecked = false
      this.show = false
      setTimeout(this.resetSettingFields, 500)
    },
    async saveColumns() {
      await this.getLineData()
      this.showPopover = false
      this.allChecked = false
      this.show = false
    },
    selectAllColumns(val) {
      this.checkedList = val ? this.settingColumns.map(item => item.id) : []
    },
    invertSelectColumns() {
      this.checkedList = this.settingColumns
        .filter(item => this.checkedList.indexOf(item.id) === -1)
        .map(item => item.id)
    },
    changeMore() {
      this.show = !this.show
    },
    changeSpread() {
      this.showBtn = !this.showBtn
      if (this.showBtn) {
        this.checkedList = []
        this.getData()
        return false
      }
      this.getLineData()
    },
    updateTimeLines(timeA, timeB) {
      this.timeLines = [
        {
          time: timeA,
          text: '自定义'
        },
        {
          time: timeB,
          text: '测试',
          color: '#747e80'
        }
      ]
    },
    scrollLeftA(val) {
      this.positionB = { x: val }
    },
    scrollLeftB(val) {
      this.positionA = { x: val }
    },
    // 获取全部数据
    async getData(val) {
      this.lineData = val
      this.settingColumns = val
      this.newLineData = val
      this.getLineLists()
    },
    async getLineData() {
        this.getLineLists()
    },
  // 根据产线返回计划
  async getLineLists() {
    this.loading = true
    const obj = this.settingColumns.length > 0 ? this.settingColumns.map(item => item.id) : []
    const data = this.checkedList.length > 0 ? this.checkedList : obj

    const params = {
      beginTime: dayjs(`${this.times[0]}`).format('YYYY-MM-DD HH:00'),
      endTime: dayjs(`${this.times[1]}`).format('YYYY-MM-DD HH:59')
    }
    const res = await apis.getLineList(data, params)
    if (res) {
      const datas = this._.cloneDeep(this.lineData)
      const data = []
      if (datas.length === 0) {
        this.loading = false
        this.datasA = []
        return false
      }
      datas.map(item => {
        res.map(items => {
          if (item.id === items.areaId) {
            data.push({
              ...item,
              id: items.areaId,
              name: item.projectNumber + ' / '+item.projectName,
              producePlanList: item.planNodeList? item.planNodeList.map(obj=>{
                return {
                  ...obj,
                  planStartTime:this.fixStartDate(obj.startTime,obj.finishTime),
                  planEndTime:this.fixEndDate(obj.startTime,obj.finishTime)
                }
              }):[]
            })
          }
        })
      })
      let str = []
      const strs = data.map(item => {
        if (item.producePlanList.length > 0) {
          str = item.producePlanList.map(items => {
            return {
              start: new Date(items.planStartTime).toString(),
              end: new Date(items.planEndTime).toString(),
              ...items
            }
          })
        } else {
          str = []
        }
        return {
          ...item,
          gtArray: str
        }
      })
      let currentAry = []
      const start = dayjs(`${this.times[0]}`).format('YYYY-MM-DD HH:00')
      const end = dayjs(`${this.times[1]}`).format('YYYY-MM-DD HH:59')
      const timeData = strs.map(item => {
        if (item.gtArray.length > 0) {
          currentAry = this.getTimes(item.gtArray).filter(items => this.compareDate(items.minTime, start) && !this.compareDate(items.minTime, end))
        } else {
          currentAry = []
        }
        return {
          ...item,
          producePlanList: item.producePlanList,
          gtArray: currentAry
        }
      })
      if (!this.showBtn) {
        this.datasA = timeData.filter(item => item.gtArray.length !== 0)
        this.checkedList = this.datasA.length > 0 ? this.datasA.map(item => item.id) : []
      } else {
        this.datasA = timeData
      }
      if (this.scale === 60) {
        this.scrollToTime = dayjs().subtract(3, 'hour').minute(0).toString()
      } else {
        this.scrollToTime = dayjs().hour(0).minute(0).toString()
      }
      this.loading = false
      // const dataIdx = this.datasA.length > 0 ? this.datasA.filter(item => parseInt(item.id) === parseInt(this.currentSelectIdx.id)) : []
      // const index = dataIdx.length > 0 ? this.currentSelectIdx : { id: timeData[0].id, name: timeData[0].name }
      // this.selectLine(index)
    }
  },
  fixEndDate(start,end){
     if(start && !end){
      return dayjs(new Date(new Date(start).setHours(23, 59, 59))).format('YYYY-MM-DD HH:mm:ss')
     }else {
         return end
     }
  },
  fixStartDate(start,end){
     if(!start && end){
      return dayjs(new Date(new Date(end).setHours(0, 0, 0))).format('YYYY-MM-DD HH:mm:ss')
     }else {
         return start
     }
  },
    compare(key) {
      return function(obj1, obj2) {
        if (obj1[key] < obj2[key]) {
          return -1
        } else if (obj1[key] === obj2[key]) {
          return 0
        } else {
          return 1
        }
      }
    },
    getTimes(data) {
      let minTimes
      let maxTimes
      const newGtArray = data.map(item => {
        if (item.actualStartTime) {
          // 实际开始时间小于计划开始时间 && 计划开始时间和计划结束时间都大于当前时间
          if (this.compareDate(item.planStartTime, item.actualStartTime) &&
            this.compareDate(item.planStartTime, this.currentDate) &&
            this.compareDate(item.planEndTime, this.currentDate)
          ) {
            minTimes = item.actualStartTime
            maxTimes = item.planEndTime
          }
          // 实际开始时间小于计划开始时间 && 计划开始时间小于当前时间 && 计划结束时间大于当前时间
          if (this.compareDate(item.planStartTime, item.actualStartTime) &&
            !this.compareDate(item.planStartTime, this.currentDate) &&
            this.compareDate(item.planEndTime, this.currentDate)
          ) {
            minTimes = item.actualStartTime
            maxTimes = item.planEndTime
          }
          // 实际开始时间小于计划开始时间 && 计划结束时间和计划开始时间都小于当前时间
          if (this.compareDate(item.planStartTime, item.actualStartTime) &&
            !this.compareDate(item.planStartTime, this.currentDate) &&
            this.compareDate(this.currentDate, item.planEndTime)
          ) {
            minTimes = item.actualStartTime
            maxTimes = this.currentDate
          }
          // 计划结束时间大于当前时间 && 实际开始时间大于计划开始时间
          if (this.compareDate(item.actualStartTime, item.planStartTime) &&
            this.compareDate(item.planEndTime, this.currentDate)
          ) {
            minTimes = item.planStartTime
            maxTimes = item.planEndTime
          }
          // 计划结束时间小于当前时间  && 实际开始时间大于计划开始时间
          if (!this.compareDate(item.actualStartTime, item.planEndTime) &&
            this.compareDate(item.actualStartTime, item.planStartTime) &&
            !this.compareDate(item.planEndTime, this.currentDate)
          ) {
            minTimes = item.planStartTime
            maxTimes = this.currentDate
          }
          // 实际开始时间大于计划结束时间和开始时间
          if (this.compareDate(item.actualStartTime, item.planEndTime) &&
            !this.compareDate(item.planEndTime, this.currentDate) &&
            !this.compareDate(item.planStartTime, this.currentDate)
          ) {
            minTimes = item.planStartTime
            maxTimes = this.currentDate
          }
        }
        if (!item.actualStartTime) {
          minTimes = item.planStartTime ? item.planStartTime : ''
          maxTimes = item.planEndTime ? item.planEndTime : ''
        }
        return {
          ...item,
          minTime: minTimes,
          maxTime: maxTimes
        }
      })
      newGtArray.sort(this.compare('minTime'))
      return newGtArray
    },
    // 原生js全屏
    screen() {
      // let element = document.documentElement;//设置后就是我们平时的整个页面全屏效果
      const element = document.getElementById('containers-left')// 设置后就是   id==con_lf_top_div 的容器全屏
      if (!this.fullscreen) {
        if (document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.mozFullScreenElement ||
          document.msExitFullscreenElement
        ) {
          document.exitFullscreen()
        }
        if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        }
        if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        }
        if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      }
      if (this.fullscreen) {
        if (element.requestFullscreen) {
          element.requestFullscreen()
        }
        if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen()
        }
        if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen()
        }
        if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen()
        }
      }
      this.fullscreen = false
    },
    // 全屏
    toggleFullScreen() {
      this.fullscreen = !this.fullscreen
    },
    // 修改计划详情
    async modify(row) {
        this.dialogFormData = this._.cloneDeep(row)
        await this.getPlanNodeTimeList(row)
        if(this.dialogFormData.nodeAttribute === 1 || this.dialogFormData.startTimeAttribute===1 || this.dialogFormData.finishTimeAttribute ===1){
          this.modifyType = 'adjustTime'
        }else{
          this.modifyType = 'modifyTime'
        }
        this.params = this._.cloneDeep(row)
        Object.assign(this.params, {
          planStartTime: row.startTime ? row.startTime: '',
          planEndTime: row.finishTime ? row.finishTime:'',
           startPlanId : row.startTimeRelationPlanId ? row.startTimeRelationPlanId+'-'+row.startTimeRelationTimeType:'',
           finishPlanId : row.finishTimeRelationPlanId? row.finishTimeRelationPlanId+'-'+row.finishTimeRelationTimeType:''
        })
        this.showStartTime = row.startTimeAttribute === 2 ? false :true
        this.showFinishTime = row.finishTimeAttribute === 2 ? false :true
        this.isChooseFinishAttr = row.finishTimeAttribute || row.finishTimeAttribute === 0 ? false :true
        this.isChooseStartAttr = row.startTimeAttribute || row.startTimeAttribute === 0 ? false :true
        this.planStartDateTime = this.planNodeTimeList.find(item => item.id === this.params.startPlanId) ? this.planNodeTimeList.find(item => item.id === this.params.startPlanId).dateTime :''
        this.planFinishDateTime = this.planNodeTimeList.find(item => item.id === this.params.finishPlanId) ? this.planNodeTimeList.find(item => item.id === this.params.finishPlanId).dateTime :''
        this.startTimeRelationPlanId = row.startTimeRelationPlanId
        this.startTimeRelationTimeType = row.startTimeRelationTimeType
        this.finishTimeRelationPlanId = row.finishTimeRelationPlanId
        this.finishTimeRelationTimeType = row.finishTimeRelationTimeType
        if(row.startTimeAttribute!=1){
        this.adjustFormList[0].props.disabled = true
      }else{
        this.adjustFormList[0].props.disabled = false
      }
      if(row.finishTimeAttribute!=1){
        this.adjustFormList[1].props.disabled = true
      }else{
        this.adjustFormList[1].props.disabled = false
      }
        this.dialogVisible = true
    },
      async getPlanNodeTimeList(row){
        const res = await papi.getPlanNodeTimeList({ projectId: row.projectId,planId:row.id })
        if (res) {
          this.planNodeTimeList = res
      }
    },
    async getAllPlanNodeTimeList(row){
        const res = await papi.getPlanNodeTimeList({ projectId: row.id })
        if (res) {
          this.planNodeTimeList = res
      }
    },
     getPlanNodesTimeList(row){
        let arr =  this.planNodeTimeList.filter(item=>item.planId!=row)
        return arr
    },
      // 查看报工进度
    async viewWorkReport(row) {
        const res = await wapi.productionTempoDetail({ id:row.productionPlanId,tempoType:0 })
        if (res) {
          this.visibleProgress = true
          this.progressDetail = res
        }
    },
    // 工艺
    async processFlowList() {
      const res = await apis.getFlowers({
        isGetAreaList: 1,
        isGetVersionList: 1
      })
      if (res) {
        this.formOptions.flowerOptions = res
      }
    },
    // 修改保存
    dialogSubmit(callback) {
      callback()
      const { form } = this.$refs.dialogFormBuild
      Object.assign(this.params, {
        startTimeAttribute : form.startTimeAttribute,
        finishTimeAttribute:form.finishTimeAttribute
      })
      this.$refs.dialogFormBuild.formRefs().validate(async(valid) => {
        if (valid) {
          let api
          api = this.modifyType !== 'modifyTime' ? papi.adjustPlan: papi.modifyPlanNode
          const res = await api(this.modifyType !== 'modifyTime' ? this.createAdjustData(form) : this.createResetData(this.params))
          if (res) {
            this.$message.success('修改成功')
            this.dialogVisible = false
            this.save()
          }
        }
      })
    },
    // 批量修改保存
    async dialogBatchSubmit(callback) {
      callback()
      let arr = this.projectType === 'modifyTime' ? this.modifyTableData:this.adjustTableData
          let api
          api = this.projectType === 'modifyTime' ? papi.batchModifyPlanNode : papi.batchAdjustPlanNode
          const res = await api(this.projectType === 'modifyTime' ? this.createBatchResetData(arr) : this.createBatchAdjustData(arr))
          if (res) {
            this.$message.success('修改成功')
            this.projectNodesVisible = false
            this.save()
          }
    },
   save() {
       this.$refs.schedules.$refs.mTable.setTableData(true)
    },
    createSetData(raw) {
        return {
        projectId: raw.projectId,
        planName: raw.planName,
        planTypeId: raw.planTypeId,
        planObjectId: raw.planObjectId,
        startTimeAttribute: raw.startTimeAttribute,
        finishTimeAttribute: raw.finishTimeAttribute,
        startTime: raw.startTimeAttribute === 2 ? this.planStartDateTime: this.params.planStartTime,
        finishTime: raw.finishTimeAttribute === 2 ? this.planFinishDateTime:this.params.planEndTime,
        principalId:raw.principalId,
        remark: raw.remark,
        startTimeRelationPlanId:raw.startTimeAttribute === 2 ?this.startTimeRelationPlanId :'',
        startTimeRelationTimeType:raw.startTimeAttribute === 2 ?this.startTimeRelationTimeType :'',
        startTimeBasis:raw.startTimeAttribute === 2 ? this.params.startTimeBasis :'',
        finishTimeRelationPlanId:raw.finishTimeAttribute === 2 ?this.finishTimeRelationPlanId :'',
        finishTimeRelationTimeType:raw.finishTimeAttribute === 2 ?this.finishTimeRelationTimeType :'',
        finishTimeBasis:raw.finishTimeAttribute === 2 ? this.params.finishTimeBasis :''
      }
    },
    createAdjustData(raw) {
        return {
          planNodeId:raw.id,
          startTime:raw.startTime,
          finishTime:raw.finishTime,
          adjustReason:raw.adjustReason
        }
      },
      createBatchResetData(arr) {
        return {
          list:arr.map(item =>{
            return {
              id: item.id,
              projectId: item.projectId,
              planName: item.planName,
              planTypeId: item.planTypeId,
              planObjectId: item.planObjectId,
              startTimeAttribute: item.startTimeAttribute,
              finishTimeAttribute: item.finishTimeAttribute,
              startTime: item.startTimeAttribute === 2 ? item.planStartDateTime:item.startTime,
              finishTime: item.finishTimeAttribute === 2 ? item.planFinishDateTime:item.finishTime,
              principalId:item.principalId,
              remark: item.remark,
              startTimeRelationPlanId:item.startTimeAttribute === 2 ?item.startTimeRelationPlanId :'',
              startTimeRelationTimeType:item.startTimeAttribute === 2 ?item.startTimeRelationTimeType :'',
              startTimeBasis:item.startTimeAttribute === 2 ? item.startTimeBasis :'',
              finishTimeRelationPlanId:item.finishTimeAttribute === 2 ?item.finishTimeRelationPlanId :'',
              finishTimeRelationTimeType:item.finishTimeAttribute === 2 ?item.finishTimeRelationTimeType :'',
              finishTimeBasis:item.finishTimeAttribute === 2 ?item.finishTimeBasis :''
            }
          })
        }
      },
    createBatchAdjustData(arr){
      return {
          list:arr.map(item=>{
            return {
              planNodeId:item.id,
              startTime:item.startTime,
              finishTime:item.finishTime,
              adjustReason:item.adjustReason
            }
          })

        }
    },
    createResetData(row) {
      const obj = {
        id: row.id
      }
      return Object.assign(this.createSetData(row), obj)
    },
    // 下发至工单
    async fixWorkOrder(row) {
      const ids = []
      ids.push(row.id)
      const res = await apis.getPage(ids)
      if (res) {
        this.isStatus = false
        this.$message.success('下发成功')
        this.$refs.schedules.getScheduleList()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.form-li {
  display: flex;
  justify-content: flex-end;
}
.schedule-title {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 700;
  color: #393D60;
  line-height: 33px;
}
.common-date {
  display: flex;
  justify-content: center;
  flex-wrap:wrap;
  .today {
    width: 60px;
    height: 31px;
    border-radius: 4px;
    border: 1px solid #A4B3C6;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393D60;
    line-height: 31px;
    text-align: center;
    margin-right: 6px;
    cursor: pointer;
  }
  img {
    width: 32px;
    height: 33px;
    display: block;
    margin: 0 12px;
    cursor: pointer;
  }
  .date-tit {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393D60;
    line-height: 33px;
    width: 110px;
    text-align: center;
  }
}
body {
  font: 12px;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
#apps {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  height: 100%;
  background: #fff;
  position: relative;
}
.top-bar {
  padding-left: 10px;
}
label {
  margin-left: 10px;
}
input {
  width: 40px;
  height: 20px;
  vertical-align: middle;
}
input[type="range"] {
  width: 100px;
}
::v-deep {
  .gantt-header-title {
    width: 291px!important;
  }
  .gantt-leftbar-wrapper {
    width: 290px!important;
  }
  .gantt-leftbar-item {
    border-bottom: 1px solid #ececec;
    min-height:60px;
  }
  .gantt-block{
    background-size: 100px auto !important;
    min-height:60px;
  }
  .gantt-block-top-space{
    min-height:0;
  }
  .gantt-timeline{
    margin-left: 0!important;
  }
  .gantt-scroll-x {
    margin-left: 291px!important;
    width: calc(100% - 291px)!important;
  }
  .gantt-markline-area {
    margin-left: 291px!important;
  }
  .el-checkbox__label {
    font-size: 12px;
  }
  .el-popover, .el-popper {
    padding: 12px 12px 0;
  }
  .m-radio-group .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    width: 90%;
  }
}
// 修改计划
.edit-form {
  .form-btn {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    .li-btn {
      width: 88px;
      height: 32px;
      background: #FFFFFF;
      border-radius: 4px;
      border: 1px solid #607FFF;
      cursor: pointer;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #607FFF;
      line-height: 32px;
      text-align: center;
    }
  }
  .form-top {
    padding: 20px 20px 0;
    background: #F7F9FC;
    border-radius: 4px;
    display: flex;
    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #4A4F76;
    }
  }
}
// 左边头部
.common-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-right: 1px solid #DBE1E7;
  position: relative;
  .header-le {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 12px;
    .le-img {
      width: 30px;
      height: 30px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        width: 15px;
        height: 15px;
        display: block;
      }
      &:hover {
        background:#F8F8F8;
      }
    }
  }
  .header-ri {
    margin-right: 10px;
    cursor: pointer;
    position: absolute;
    right: 0;
    img {
      width: 15px;
      height: 15px;
      display: block;
    }
  }
}
.showMore {
  padding: 0 10px;
  height: 40px;
  border-radius: 4px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #607FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
  .icons {
    color: #DDE3EB;
  }
  &:hover {
    background:#F8F8F8;
    i {
      color: #607FFF;
    }
  }
}
// 筛选产线
.popover-content-top {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  button {
    padding: 8px 20px;
    border-radius: 4px;
    border-color: #DEDEEE;
    color: #8A8CA5;
    &:last-child {
      border-color: #607FFF;
      color: #607FFF;
    }
  }
}
.popover-content-center {
  width: calc(100% + 24px);
  position: relative;
  left: -12px;
  margin-top: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  background: #F8F8FB;
  height: 30px;
  button.invert {
    span {
      color: #8A8CA5;
    }
    img {
      position: relative;
      top: 2px;
      right: 10px;
    }
  }
}
.popover-content-footer {
  width: calc(100% + 24px);
  position: relative;
  left: -12px;
  max-height: 400px;
  overflow-y: scroll;
  .el-checkbox-group {
    // overflow: hidden;
    .el-checkbox {
      // width: 100%;
      padding: 8px 8px 8px 0px;
      cursor: pointer;
      // &:hover {
      //   // background: #ECF0F4;
      // }
    }
  }
}
.containers {
  height: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 10px;
  position: relative;
  .tip-img {
    width: 10px;
    height: 38px;
    display: block;
    position: absolute;
    top: calc(50%);
    right: -10px;
    cursor: pointer;
    img {
      width: 10px;
      height: 38px;
      display: block;
    }
  }
}
.app-left {
  width: calc(100% - 366px);
  background: #fff;
}
.app-width {
  width: 100%;
}
.app-left-full {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
  background: #fff;
  width: 100%;
  z-index: 2000;
}
// 右边栏样式
.app-right {
  width: 366px;
  background: #F0F2F5;
  height: 100%;
  // height: calc(100% + 20px);
  overflow-y: hidden;

}
.el-slider {
  width: 100px;
}
/* 样式 */
.gatter-form{
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}
.detail-box {
width: 100%;
display: flex;
flex-wrap: nowrap;
overflow-x: scroll;
.item-box {
  margin-right: 14px;
  margin-bottom: 20px;
  .item {
    width: 121px;
    height: 38px;
    border-radius: 4px;
    font-size: 12px;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 35px;
    position: relative;
    margin-bottom: 10px;
    padding: 0 5px;
  }
  .sign{
    position: absolute;
    top: 0;
    left: 0;
    width: 17px;
    height: 17px;
  }
  .progress-bg {
    width: 100%;
    height: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #a2b3e2;
    border-radius: 2px;
    .progress-bar {
      height: 4px;
      background: linear-gradient(90deg, #356c90 0%, #54aaff 100%);
      border-radius: 2px;
    }
    .apply-bar{
      height: 4px;
      background: linear-gradient(90deg, #44ec52 0%, #98e191 100%);
      border-radius: 2px;
    }
  }
  .item-finished {
    color: #4b8358;
    background: #eafbeb;
    border: 1px solid #a5dab2;
  }
  .item-running {
    color: #5B7FAF;
    background: #ebf4ff;
    border: 1px solid #a4c9ed;
  }
  .item-wait {
    color: #868686;
    border: 1px solid #c4c4c4;
    background: #eeeeee;
  }
}
}
</style>
