<template>
  <div class="role-wrap">
    <Split :offset.sync="split.offset" :height="splitHeight">
      <template slot="left">
        <div style="padding:10px">
          <el-input
            v-model="searchCode"
            placeholder="搜索计划编号"
            class="input-with-select"
            clearable
            @input="onInput"
          />
          <div
            v-for="item in demandList"
            :key="item.id"
            class="side-item"
            :style="{color:actItem.id===item.id?'#8099ff':'#606266'}"
            @click="onSideClick(item)"
          >
            {{ item.producePlanNumber }}</div>
        </div>
      </template>
      <template slot="right">
        <div class="orders">
          <SearchForm
            ref="searchForm"
            :form-list-extend="searchFormList"
            :form-data="searchFormData"
            style="padding: 0"
            @update:formData="data=>searchFormData=data"
            @search="reSearch"
          />
          <FunctionHeader
            ref="functionHeader"
            v-model="functionHeader.searchVal"
            search-title="请输入物料编号/名称"
            style="padding: 10px 0 10px 0;"
            class="func-header"
            :tags="functionHeader.tags"
            @search="$refs.mTable.setTableData(true)"
          />
          <MTable
            ref="mTable"
            class="table-left"
            :columns="columns"
            :height="height"
            highlight-current-row
            :set-data-method="getTableData"
            @current-change="handleCurrentChange"
          >
            <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
            <NewPageOpen
              slot="materialsCode"
              slot-scope="{ row }"
              :path="{ name: 'materialsDetail', query: {id: row.materialsId}}"
              :text="row.materialsCode"
            />
            <NewPageOpen
              slot="procedureCode"
              slot-scope="{ row }"
              :path="{ name: 'procedureDetail', query: {id: row.procedureId}}"
              :text="row.procedureCode"
            />
            <div slot="lockedStock" slot-scope="{ row }">{{ row.lockedStock }}<i
              class="el-icon-edit"
              style="color:#607FFF"
              @click="editStock(row)"
            /></div>
          </MTable>

          <el-dialog title="编辑" :visible.sync="editDialogVisible" width="500px">
            <div style="width:350px;margin:auto">
              <div>当前库存：{{ editRow.currentStock }}</div>
              <div style="margin:10px 0;">可用库存：{{ editRow.availableStock }}</div>
              <div>占用库存：
                <el-input-number
                  v-model="editRow.lockedStock"
                  controls-position="right"
                  :min="0"
                  :max="editRow.availableStock||0"
                  :step="0.001"
                  @blur="e=>onBlur(e,editRow,'lockedStock')"
                />
              </div>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="cancelEdit">取 消</el-button>
              <el-button type="primary" @click="saveStock">确 定</el-button>
            </span>
          </el-dialog>
          <WaitDemand ref="waitDemand" @refresh="initPage" />
        </div>
      </template>
    </Split>

  </div>
</template>
<script>
import api from '@/api/production/project'
import FunctionHeader from '@/components/FunctionHeader'
import SearchForm from '@/components/SearchForm/SearchForm'
import Split from '@/components/Split/Split'
import { columnsAll, searchFormList } from './data'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
import WaitDemand from './waitDemand.vue'
export default {
  name: 'MaterialsProduction',
  components: { NewPageOpen, FunctionHeader, SearchForm, Split, WaitDemand },
  data() {
    return {
      split: {
        offset: 14
      },
      allProdList: [],
      demandList: [],
      actId: '',
      editRow: {},
      editDialogVisible: false,
      searchCode: '',
      searchFormList,
      searchFormData: {},
      functionHeader: {
        searchVal: '',
        tags: [
          {
            hidden: !this.permission('Scheduling'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            innerText: '自动排程',
            on: {
              click: this.automaticScheduling
            }
          },
          {
            hidden: !this.permission('CreateTask'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small', disabled: null },
            innerText: '创建生产任务',
            on: {
              click: this.createTask
            }
          },
          {
            hidden: !this.permission('WaitDemand'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small', disabled: null },
            innerText: '待定需求',
            on: {
              click: this.createWaitDemand
            }
          }
        ]
      },
      columns: columnsAll,
      searchData: {},
      currentRow: false
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 192 - 65
    },
    splitHeight() {
      return this.$store.state.app.contentHeight - 235 + 90
    }
  },

  watch: {
    currentRow(val) {
      this.functionHeader.tags[1].props.disabled = !val
    }
  },

  created() {
    this.initPage()
  },
  mounted() {
    console.log(this.allProdList)
    console.log(this.$route.query.id)
  },

  methods: {
    createWaitDemand() {
      this.$refs.waitDemand.init(this.actItem.producePlanId)
    },
    automaticScheduling() {
      const query = { ...this.additional, productionRequirementId: this.actItem.id }
      if (this.additional.isSchedule) {
        this.$confirm('注意！由于已经对部分生产需求排程了，会导致自动排程结果偏差较大，是否继续？', '提示', {
          confirmButtonText: '继续',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          this.$router.push({ path: 'automatic-scheduling', query })
        })
      } else {
        this.$router.push({ path: 'automatic-scheduling', query })
      }
    },
    createTask() {
      this.$router.push({ path: 'add-production-task', query: { row: JSON.stringify(this.currentRow) }})
    },
    handleCurrentChange(val) {
      this.currentRow = val
    },
    onBlur(e, row, key, num = 3) {
      const valStr = e.target.value + ''
      const decimalLength = valStr.split('.')[1]?.length
      if (decimalLength > num) {
        e.target.value = valStr.slice(0, valStr.length - (decimalLength - num)) * 1
        row[key] = e.target.value
      }
    },
    cancelEdit() {
      this.editRow.lockedStock = this.currentStock
      this.editDialogVisible = false
    },
    editStock(editRow) {
      this.editRow = editRow
      this.currentStock = editRow.lockedStock
      this.editDialogVisible = true
    },
    async saveStock() {
      const { id: itemId, lockedStock } = this.editRow
      const res = await api.editLockedStock({ itemId, lockedStock })
      if (res) {
        this.$message.success('操作成功')
        this.onSideClick(this.actItem)
        this.editDialogVisible = false
      }
    },
    onSideClick(actItem) {
      this.actItem = actItem
      console.log(this.actItem)
      this.$refs.mTable.tableRefs().clearSelection()
      this.$refs.searchForm.reset()
    },
    onInput() {
      this.demandList = this.allProdList.filter(item => item.producePlanNumber.toLowerCase().includes(this.searchCode.toLowerCase()) || item.id?.toString().includes(this.searchCode))
    },
    reSearch(data, val) {
      if (val === 1) {
        this.searchFormData = {}
      }
      this.$refs.mTable.setTableData(true)
    },
    initPage() {
      // 获取计划
      this.getUserOptions()
    },

    // 获取计划
    async getUserOptions() {
      const res = await api.prodCodeList()
      if (res) {
        this.allProdList = res
        this.demandList = this.allProdList
        const id = this.$route.query.id
        if(!id){
          this.allProdList[0] && this.onSideClick(this.allProdList[0])
        }else{
          this.onSideClick({id})
        }
      }
    },

    // 右侧列表
    async getTableData(condition, callback) {
      this.currentRow = null
      this.$refs?.mTable.tableRefs()?.setCurrentRow()
      if (!this.actItem) {
        return callback({
          total: 0,
          content: []
        })
      }
      const { page } = condition
      const { procedure, specifications } = this.searchFormData
      const params = {
        materials: this.functionHeader.searchVal,
        planId: this.actItem.id,
        procedure,
        specifications
      }
      const res = await api.prodDetail({
        page: page.currentPage,
        size: page.pageSize,
        ...params
      })
      if (res) {
        this.additional = res.additional
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    }
  }
}
</script>

<style scoped lang="scss">
.side-item {
  height: 34px;
  padding: 10px;
  color: #606266;
  white-space: nowrap;
  &:hover {
    background-color: #f4f7fa;
  }
}
::v-deep .el-table {
  tr {
    height: 33px;
  }
}
.table-box {
  ::v-deep .horizontal {
    .trigger-con {
      .split-line {
        height: 87%;
      }
    }
  }
}
.table-left {
  ::v-deep .el-table__body-wrapper {
    &::-webkit-scrollbar {
      width: 0px;
    }
  }
}
.orders {
  padding: 10px;
}
.empty-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30%;
}
</style>
