
export const qualityUnit = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    label: '批次号',
    prop: 'batchNumber',
    hiddenSearch: true,
    sortable: false,
    width: 150
  },
  {
    prop: 'materialsName',
    label: '物料',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsSpecifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true,
    width: 220
  },
  {
    label: '单位',
    prop: 'materialsUnit',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'quantity',
    label: '数量',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'qualityInspectionPlanName',
    label: '质检方案',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'creator',
    label: '操作人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'createTime',
    label: '操作时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 150
  }
]

