var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "package-wrap" },
    [
      _c("FunctionHeader", {
        attrs: {
          "search-title": "请输入质检方案名称",
          tags: _vm.functionHeader.tags
        },
        on: {
          search: function($event) {
            return _vm.$refs.mTable.setTableData(true)
          }
        },
        model: {
          value: _vm.functionHeader.searchVal,
          callback: function($$v) {
            _vm.$set(_vm.functionHeader, "searchVal", $$v)
          },
          expression: "functionHeader.searchVal"
        }
      }),
      _c("MTable", {
        ref: "mTable",
        attrs: {
          columns: _vm.columns,
          height: _vm.height,
          "set-data-method": _vm.getTableData
        },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function(ref) {
              var $index = ref.$index
              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
            }
          },
          {
            key: "name",
            fn: function(ref) {
              var row = ref.row
              return _c(
                "div",
                {},
                [
                  _vm.isShowChild
                    ? _c("NewPageOpen", {
                        attrs: {
                          path: {
                            name: "QualityPlanDetail",
                            query: { id: row.id, type: "view" }
                          },
                          text: row.name
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            }
          },
          {
            key: "isEnabled",
            fn: function(ref) {
              var row = ref.row
              return _c("div", { staticClass: "is-enabled" }, [
                _c("div", {
                  style: { background: row.isEnabled ? "#24CAD7" : "#FFCC78" }
                }),
                _c("div", [_vm._v(_vm._s(row.isEnabled ? "启用" : "停用"))])
              ])
            }
          },
          {
            key: "type",
            fn: function(ref) {
              var row = ref.row
              return _c("div", {}, [
                _vm._v(" " + _vm._s(_vm.inspectionTypeNames[row.type]) + " ")
              ])
            }
          },
          {
            key: "action",
            fn: function(ref) {
              var row = ref.row
              return _c(
                "div",
                {},
                [
                  _vm.permission("Copy")
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.modifyOrCopy(row, "copy")
                            }
                          }
                        },
                        [_vm._v("复制")]
                      )
                    : _vm._e(),
                  _vm.permission("Copy")
                    ? _c("el-divider", { attrs: { direction: "vertical" } })
                    : _vm._e(),
                  _vm.permission("Status")
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.switchStatus(row, _vm.params.radio)
                            }
                          }
                        },
                        [_vm._v(_vm._s(row.isEnabled ? "停用" : "启用"))]
                      )
                    : _vm._e(),
                  _vm.permission("Status")
                    ? _c("el-divider", { attrs: { direction: "vertical" } })
                    : _vm._e(),
                  _c(
                    "el-dropdown",
                    {
                      on: {
                        command: function(command) {
                          _vm.more(row, command)
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "el-dropdown-link" }, [
                        _vm._v(" 更多"),
                        _c("i", {
                          staticClass: "el-icon-arrow-down el-icon--right"
                        })
                      ]),
                      _c(
                        "el-dropdown-menu",
                        {
                          staticClass: "td-dropdown-menu",
                          attrs: { slot: "dropdown" },
                          slot: "dropdown"
                        },
                        [
                          _vm.permission("Modify")
                            ? _c(
                                "el-dropdown-item",
                                { attrs: { command: "modify" } },
                                [_vm._v("修改")]
                              )
                            : _vm._e(),
                          _vm.permission("Del")
                            ? _c(
                                "el-dropdown-item",
                                { attrs: { command: "del" } },
                                [_vm._v("删除")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }
          }
        ])
      }),
      _c("TableDialog", {
        attrs: {
          title: "查看检验条目",
          show: _vm.inspectionItemsVisible,
          columns: _vm.inspectionItemsColumns,
          "table-data": _vm.inspectionItems
        },
        on: {
          "update:show": function($event) {
            _vm.inspectionItemsVisible = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }