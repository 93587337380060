<template>
  <div class="inspection-record">
    <div class="table-wrap">
      <table class="left-table">
        <tr>
          <td class="sticky" colspan="3">检验项目组</td>
          <td v-for="(item, index) in inspectionList" :key="index" :colspan="item.inspectionItems.length">{{ item.inspectionProjectName }}</td>
        </tr>
        <tr v-for="(item, i) in keys" :key="item">
          <td class="sticky" colspan="3">{{ keysName[i] }}</td>
          <template v-for="(inspection, insIndex) in inspectionList">
            <td v-for="(child, childIndex) in inspection.inspectionItems" :key="childIndex + '_' + insIndex">
              <div :class="{'common-item': item === 'inspectionEntryName'}">{{ child[item] || '' }}</div>
            </td>
          </template>
        </tr>
        <template v-if="baseFormData.status !== 0">
          <template v-for="(item, index) in inspectionRecordList">
            <tr v-for="(key, keyIndex) in keys2" :key="index + '_1' + keyIndex">
              <td v-if="index === 0 && keyIndex === 0" class="sticky" :rowspan="inspectionRecordList.length * keys2.length">检验数据</td>
              <td v-if="keyIndex === 0" class="sticky sticky-2" :rowspan="keys2.length">第{{ item.index + 1 }}件</td>
              <td class="sticky sticky-3">{{ keys2Name[keyIndex] }}</td>
              <td v-for="(child, childIndex) in item.inspectionItems" :key="index + '_' + keyIndex + '_' + childIndex">
                <div v-if="key === 'result'">{{ child[key] }}</div>
                <div v-if="key === 'resultCheck'">
                  <span v-if="child[key] === 0" style="color: #D0021B">次</span>
                  <span v-if="child[key] === 1" style="color: #00AB29">良</span>
                </div>
                <div v-if="key === 'picture'">
                  <el-image
                    v-if="child.picture && child.picture !== '[]'"
                    :src="fixImg(child.picture)[0]"
                    style="height: 30px"
                    :preview-src-list="fixImg(child.picture)"
                  />
                </div>
                <div v-if="key === 'defectItemList'">
                  <el-tag
                    v-for="defect in child[key]"
                    :key="defect.id"
                    style="color: #D0021B;background: rgba(255, 156, 168, 0.2);border: 1px solid rgba(255, 156, 168, 0.2)"
                  >
                    {{ defect.name }}
                  </el-tag>
                </div>
                <div v-if="key === 'defectReasonList'">
                  <div v-for="reason in child[key]" :key="reason.id">{{ reason.name }}</div>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </table>
    </div>

  </div>
</template>

<script>
import inspectionPlanApi from '@/api/quality/inspection-plan'
import ColumnSetting from '@/views/statistics-board/board-manage/components/column-setting/column-setting'
import userApi from '@/api/sets/user/user'
export default {
  name: 'InspectionRecord',
  components: { ColumnSetting },
  props: {
    baseFormData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      inspectionList: [],
      inspectionRecordList: [],
      keys: ['inspectionEntryName', 'inspectionMethod', 'inspectionStandard', 'standardValue', 'upperLimit', 'lowerLimit'],
      keysName: ['检验项', '检验方法', '检验标准', '标准值', '上限', '下限'],
      keys2: ['result', 'resultCheck', 'picture', 'defectItemList', 'defectReasonList'],
      keys2Name: ['检验值', '结论', '图片', '缺陷项', '缺陷原因'],
      projectNames: [],
      inspectionRecords: [],
      params: {
        projectName: -1,
        index: -1,
        result: -1
      },
      columnsSettings: [
        {
          prop: 'result',
          label: '检验值'
        },
        {
          prop: 'resultCheck',
          label: '结论'
        },
        {
          prop: 'picture',
          label: '图片'
        },
        {
          prop: 'defectItemList',
          label: '缺陷项'
        },
        {
          prop: 'defectReasonList',
          label: '缺陷原因'
        }
      ]
    }
  },
  watch: {
    baseFormData(val) {
      if (val.inspectionSheetMap) {
        this.initInspectionRecordList()
      }
    }
  },
  beforeMount() {
    this.initInspectionRecordList()
  },
  methods: {
    initInspectionRecordList() {
      let arr = []
      let arr2 = []
      if (!this.baseFormData.inspectionSheetMap) return false
      let inspectionEntry = Object.values(this.baseFormData.inspectionSheetMap)
      if (this.params.result !== -1) {
        inspectionEntry = inspectionEntry.filter(f => this.params.result ? f.every(item => item.resultCheck !== 0) : f.some(item => item.resultCheck === this.params.result))
      }
      this.inspectionRecords = inspectionEntry
      inspectionEntry.forEach((item, index) => {
        let inspectionItems = item
        if (this.params.projectName !== -1) {
          inspectionItems = inspectionItems.filter(f => f.inspectionProjectName === this.params.projectName)
        }
        arr.push({
          index,
          inspectionItems
        })
        if (index === 0) {
          const inspectionProjectNames = Array.from(new Set(item.map(item => item.inspectionProjectName)))
          this.projectNames = inspectionProjectNames
          inspectionProjectNames.forEach(name => {
            arr2.push({
              inspectionProjectName: name,
              inspectionItems: item.filter(i => i.inspectionProjectName === name)
            })
          })
        }
      })
      if (this.params.projectName !== -1) {
        arr2 = arr2.filter(item => item.inspectionProjectName === this.params.projectName)
      }
      if (this.params.index !== -1) {
        arr = arr.filter((item, index) => index === this.params.index)
      }
      this.inspectionList = arr2
      this.inspectionRecordList = arr
    },
    fixImg(img) {
      return JSON.parse(img).map(item => item.url)
    }
  }
}
</script>

<style scoped lang="scss">
.inspection-record {
  width: 100%;
  overflow-y: auto;
}
.table-wrap {
  height: 550px;
  overflow-y: scroll;
  position: relative;
}
table {
  border-collapse: collapse;
  border:none;
  position: relative;
  tr:nth-child(1), tr:nth-child(2), tr:nth-child(3), tr:nth-child(4), tr:nth-child(5), tr:nth-child(6), tr:nth-child(7) {
    position: -webkit-sticky;
    position: sticky;
    background: #fff;
    z-index: 100;
    width: 100%;
    //border-bottom: solid #dde3eb 1px;
    box-shadow: 0 -1px #dde3eb;
  }
  tr:nth-child(1) {
    top: 0;
  }
  tr:nth-child(2) {
    top: 38px;
  }
  tr:nth-child(3) {
    top: 93px;
  }
   tr:nth-child(4) {
    top: 130px;
  }
  tr:nth-child(5) {
    top: 167px;
  }
  tr:nth-child(6) {
    top: 204px;
  }
  tr:nth-child(7) {
    top: 241px;
    box-shadow: 0 -0.5px #dde3eb!important;
  }
  td {
    border:solid rgba(221, 227, 235, 1) 1px;
    white-space: nowrap;
    color: #393D60;
    padding: 10px 15px;
  }
}
.sticky {
  position: sticky;
  left: 0;
  z-index: 2;
  background: #ffffff;
  &:before {
    content: ' ';
    position: absolute;
    left: -1px;
    top: 0;
    bottom: 0;
    display: inline-block;
    width: 1px;
    background: rgba(221, 227, 235, 1);
  }
  &:after {
    content: ' ';
    position: absolute;
    right: -1px;
    top: 0;
    bottom: 0;
    display: inline-block;
    width: 1px;
    height: 100%;
    background: rgba(221, 227, 235, 1);
  }
}
.sticky-2 {
  left: 87px;
}
.sticky-3 {
  left: 153.8px;
}
.common-item {
  height: 34px;
  background: #E7F5FF;
  border-radius: 4px;
  padding: 0 36px;
  display: inline-block;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #0091FF;
  line-height: 34px;
}
::v-deep {
  .columns-setting {
    top: 0 !important;
    margin: 0 !important;
    width: auto !important;
    img {
      width: 18px;
    }
  }
}
</style>
