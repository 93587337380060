var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "inspection-record" }, [
    _c(
      "div",
      { staticStyle: { margin: "10px 0", position: "sticky", left: "0" } },
      [
        _c(
          "div",
          [
            _c(
              "el-select",
              {
                attrs: { size: "small" },
                on: { change: _vm.paramsChange },
                model: {
                  value: _vm.params.projectName,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "projectName", $$v)
                  },
                  expression: "params.projectName"
                }
              },
              [
                _c("el-option", { attrs: { value: -1, label: "全部项目组" } }),
                _vm._l(_vm.projectNames, function(name, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: name, value: name }
                  })
                })
              ],
              2
            ),
            _vm.baseFormData.status !== 0
              ? _c(
                  "el-select",
                  {
                    staticStyle: { margin: "0 10px" },
                    attrs: { size: "small" },
                    on: { change: _vm.paramsChange },
                    model: {
                      value: _vm.params.index,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "index", $$v)
                      },
                      expression: "params.index"
                    }
                  },
                  [
                    _c("el-option", {
                      attrs: { value: -1, label: "全部数据" }
                    }),
                    _vm._l(_vm.inspectionRecords, function(name, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          label: "第" + (index + 1) + "件",
                          value: index
                        }
                      })
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm.baseFormData.status !== 0
              ? _c(
                  "el-select",
                  {
                    attrs: { size: "small" },
                    on: { change: _vm.paramsChange },
                    model: {
                      value: _vm.params.result,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "result", $$v)
                      },
                      expression: "params.result"
                    }
                  },
                  [
                    _c("el-option", {
                      attrs: { value: -1, label: "全部结果" }
                    }),
                    _c("el-option", { attrs: { value: 1, label: "只看合格" } }),
                    _c("el-option", {
                      attrs: { value: 0, label: "只看不合格" }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        ),
        _c("column-setting", {
          ref: "columnSetting",
          attrs: { columns: _vm.columnsSettings },
          on: { updateColumns: _vm.updateColumns }
        })
      ],
      1
    ),
    _c("div", { staticClass: "table-wrap" }, [
      _c(
        "table",
        { staticClass: "left-table" },
        [
          _c(
            "tr",
            [
              _c("td", { staticClass: "sticky", attrs: { colspan: "3" } }, [
                _vm._v("检验项目组")
              ]),
              _vm._l(_vm.inspectionList, function(item, index) {
                return _c(
                  "td",
                  {
                    key: index,
                    attrs: { colspan: item.inspectionItems.length }
                  },
                  [_vm._v(_vm._s(item.inspectionProjectName))]
                )
              })
            ],
            2
          ),
          _vm._l(_vm.keys, function(item, i) {
            return _c(
              "tr",
              { key: item },
              [
                _c("td", { staticClass: "sticky", attrs: { colspan: "3" } }, [
                  _vm._v(_vm._s(_vm.keysName[i]))
                ]),
                _vm._l(_vm.inspectionList, function(inspection, insIndex) {
                  return _vm._l(inspection.inspectionItems, function(
                    child,
                    childIndex
                  ) {
                    return _c("td", { key: childIndex + "_" + insIndex }, [
                      _c(
                        "div",
                        {
                          class: {
                            "common-item": item === "inspectionEntryName"
                          }
                        },
                        [_vm._v(_vm._s(child[item] || ""))]
                      )
                    ])
                  })
                })
              ],
              2
            )
          }),
          _vm.baseFormData.status !== 0
            ? [
                _vm._l(_vm.inspectionRecordList, function(item, index) {
                  return _vm._l(_vm.keys2, function(key, keyIndex) {
                    return _c(
                      "tr",
                      { key: index + "_1" + keyIndex },
                      [
                        index === 0 && keyIndex === 0
                          ? _c(
                              "td",
                              {
                                staticClass: "sticky",
                                attrs: {
                                  rowspan:
                                    _vm.inspectionRecordList.length *
                                    _vm.keys2.length
                                }
                              },
                              [_vm._v("检验数据")]
                            )
                          : _vm._e(),
                        keyIndex === 0
                          ? _c(
                              "td",
                              {
                                staticClass: "sticky sticky-2",
                                attrs: { rowspan: _vm.keys2.length }
                              },
                              [_vm._v("第" + _vm._s(item.index + 1) + "件")]
                            )
                          : _vm._e(),
                        _c("td", { staticClass: "sticky sticky-3" }, [
                          _vm._v(_vm._s(_vm.keys2Name[keyIndex]))
                        ]),
                        _vm._l(item.inspectionItems, function(
                          child,
                          childIndex
                        ) {
                          return _c(
                            "td",
                            { key: index + "_" + keyIndex + "_" + childIndex },
                            [
                              key === "result"
                                ? _c("div", [_vm._v(_vm._s(child[key]))])
                                : _vm._e(),
                              key === "resultCheck"
                                ? _c("div", [
                                    child[key] === 0
                                      ? _c(
                                          "span",
                                          { staticStyle: { color: "#D0021B" } },
                                          [_vm._v("次")]
                                        )
                                      : _vm._e(),
                                    child[key] === 1
                                      ? _c(
                                          "span",
                                          { staticStyle: { color: "#00AB29" } },
                                          [_vm._v("良")]
                                        )
                                      : _vm._e()
                                  ])
                                : _vm._e(),
                              key === "picture"
                                ? _c(
                                    "div",
                                    [
                                      child.picture && child.picture !== "[]"
                                        ? _c("el-image", {
                                            staticStyle: { height: "30px" },
                                            attrs: {
                                              src: _vm.fixImg(child.picture)[0],
                                              "preview-src-list": _vm.fixImg(
                                                child.picture
                                              )
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              key === "defectItemList"
                                ? _c(
                                    "div",
                                    _vm._l(child[key], function(defect) {
                                      return _c(
                                        "el-tag",
                                        {
                                          key: defect.id,
                                          staticStyle: {
                                            color: "#D0021B",
                                            background:
                                              "rgba(255, 156, 168, 0.2)",
                                            border:
                                              "1px solid rgba(255, 156, 168, 0.2)"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(defect.name) + " "
                                          )
                                        ]
                                      )
                                    }),
                                    1
                                  )
                                : _vm._e(),
                              key === "defectReasonList"
                                ? _c(
                                    "div",
                                    _vm._l(child[key], function(reason) {
                                      return _c("div", { key: reason.id }, [
                                        _vm._v(_vm._s(reason.name))
                                      ])
                                    }),
                                    0
                                  )
                                : _vm._e()
                            ]
                          )
                        })
                      ],
                      2
                    )
                  })
                })
              ]
            : _vm._e()
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }