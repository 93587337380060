import _ from 'lodash'
import { startFinishTimeAttribute } from '@/config/options.config'
let functionList = {}
export const getFunctionList = val => {
  functionList = val
}

export const formList = [
    {
      colSpan: 12,
      key: 'planName',
      tagName: 'el-input',
      label: '节点名称',
      required: true
    },
    {
        colSpan: 12,
        key: 'planTypeId',
        tagName: "div",
        label: '节点类型',
        required: true,
        domProps: { className: 'module-content' },
        children: [
          {
            attrs:{class:'module-content'},
            customSlots: ["processFlowClassName"],
          }
        ]
      },
      {
        colSpan: 12,
        key: 'principalDepartId',
        tagName: 'el-select',
        label: '负责部门',
        props: {
          multiple: true
        },
        children: [
          {
            tagName: 'el-option',
            props: {
              label: 'name',
              value: 'id'
            },
            options: []
          }
        ]
      },
  {
    colSpan: 12,
    key: 'principalId',
    tagName: 'el-select',
    label: '负责人',
    required: true,
    props: {
        multiple: true
      },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'userFullName',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 12,
    key: 'startTimeAttribute',
    tagName: 'el-select',
    label: '开始时间属性',
    required: true,
    on: { change: (val) => { functionList.startTimeAttributeChange(val) } },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: startFinishTimeAttribute
      }
    ]
  },
  {
    colSpan: 12,
    key: 'startTime',
    tagName: "div",
    label: '开始时间',
    required: true,
    children: [
      {
        customSlots: ["startTime"],
      }
    ]
  },
  {
    colSpan: 12,
    key: 'finishTimeAttribute',
    tagName: 'el-select',
    label: '完成时间属性',
    required: true,
    on: { change: (val) => { functionList.finishTimeAttributeChange(val) } },
    children: [
      {
        tagName: 'el-option',
        options: startFinishTimeAttribute,
        props: {
            label: 'name',
            value: 'id'
          }
      }
    ]
  },
  {
    colSpan: 12,
    key: 'finishTime',
    tagName: "div",
    label: '完成时间',
    required: true,
    children: [
      {
        customSlots: ["finishTime"],
      }
    ]
  },
  
  {
    colSpan: 12,
    label: '是否任务节点',
    key: 'isTaskNode',
    tagName: 'el-select',
    required: true,
    props: {
      disabled: true
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: [{id:1,name:'是'},{id:0,name:'否'}]
      }
    ]
  },
    {
        colSpan: 12,
        label: '备注',
        key: 'remark',
        tagName: 'el-input',
        props: { type: 'textarea' }
      }
  ]
  export const templateFormList = [
    {
      colSpan: 12,
      key: 'planName',
      tagName: 'el-input',
      label: '节点名称',
      required: true
    },
    {
        colSpan: 12,
        key: 'planTypeId',
        tagName: "div",
        label: '节点类型',
        required: true,
        domProps: { className: 'module-content' },
        children: [
          {
            attrs:{class:'module-content'},
            customSlots: ["processFlowClassName"],
          }
        ]
      },
      {
        colSpan: 12,
        key: 'principalDepartId',
        tagName: 'el-select',
        label: '负责部门',
        props: {
          multiple: true
        },
        children: [
          {
            tagName: 'el-option',
            props: {
              label: 'name',
              value: 'id'
            },
            options: []
          }
        ]
      },
  {
    colSpan: 12,
    key: 'principalId',
    tagName: 'el-select',
    label: '负责人',
    required: true,
    props: {
        multiple: true
      },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'userFullName',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 12,
    key: 'startTimeAttribute',
    tagName: 'el-select',
    label: '开始时间属性',
    required: true,
    on: { change: (val) => { functionList.startTimeAttributeChange(val) } },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: startFinishTimeAttribute
      }
    ]
  },
  {
    colSpan: 12,
    key: 'finishTimeAttribute',
    tagName: 'el-select',
    label: '完成时间属性',
    required: true,
    on: { change: (val) => { functionList.finishTimeAttributeChange(val) } },
    children: [
      {
        tagName: 'el-option',
        options: startFinishTimeAttribute,
        props: {
            label: 'name',
            value: 'id'
          }
      }
    ]
  },
  {
    colSpan: 12,
    label: '是否任务节点',
    key: 'isTaskNode',
    tagName: 'el-select',
    defaultValue: 1,
    required: true,
    props: {
      disabled: false
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: [{id:1,name:'是'},{id:0,name:'否'}]
      }
    ]
  },
    {
        colSpan: 12,
        label: '备注',
        key: 'remark',
        tagName: 'el-input',
        props: { type: 'textarea' }
      }
  ]
