<template>
  <div class="unit-wrap common-tab">
    <el-radio-group v-model="params.radio" class="m-radio-group" @change="radioChange">
      <el-radio-button label="TaskDetail">任务详情</el-radio-button>
      <el-radio-button label="ReportRecord">汇报记录</el-radio-button>
      <div
        style="padding: 8px 10px;position: absolute;right: 42px;border-radius: 4px;"
        :style="{
								  color: statusOptionsMap[baseFormData.status].color,
								  background:  statusOptionsMap[baseFormData.status].background}"
          >
              <span style="font-size: 14px"
              >状态: {{statusOptionsMap[baseFormData.status].label}}
              </span>
      </div>
    </el-radio-group>
    <div v-show="params.radio === 'TaskDetail'">
      <div class="top" style="padding: 10px">
      <div class="detail-wrappers">
        <div class="detail-body">
          <div class="detail-card">
            <div class="card-tit">任务基本信息</div>
            <div class="card-bot">
              <MFormBuilder
                ref="baseForm"
                :form-list="baseFormDetailList"
                :form-data="baseFormData"
                label-position="left"
              >
                <div v-for="item in baseFormDetailList" :key="item.key" :slot="item.key">
                  <span v-if="item.key === 'planName'">{{ baseFormData.planName }}</span>
                  <span v-if="item.key === 'planTypeName'">{{ baseFormData.planTypeName }}</span>
                  <span v-if="item.key === 'projectNumber'">{{ baseFormData.projectNumber }}</span>
                  <span v-if="item.key === 'projectName'">{{ baseFormData.projectName }}</span>
                  <span v-if="item.key === 'startTime'">{{ baseFormData.startTime }}</span>
                  <span v-if="item.key === 'finishTime'">{{ baseFormData.finishTime }}</span>
                  <span v-if="item.key === 'actualStartTime'">{{ baseFormData.actualStartTime }}</span>
                  <span v-if="item.key === 'actualFinishTime'">{{ baseFormData.actualFinishTime }}</span>
                  <span v-if="item.key === 'lastUpdateTime'">{{ baseFormData.lastUpdateTime }}</span>
                  <span v-if="item.key === 'createTime'">{{ baseFormData.createTime }}</span>
                  <span v-if="item.key === 'principalDepartName'">{{ baseFormData.principalDepartName }}</span>
                  <span v-if="item.key === 'principalName'">{{ baseFormData.principalName }}
                  </span>
                  <span v-if="item.key === 'processPercent'" slot="processPercent">
                     <el-progress :percentage="baseFormData.processPercent"/>
                  </span>
                  <span v-if="item.key === 'workHours'" class="el-progress">{{ baseFormData.workHours }}</span>
                  <span v-if="item.key === 'reportRemark'" class="el-progress">{{ baseFormData.reportRemark }}</span>
                  <span v-if="item.key === 'relatedFiles'" class="el-progress">{{ baseFormData.relatedFiles }}</span>
                  <div v-else-if="item.key === 'attachments'">
                      <el-link v-for="item in attachments" :key="item.id" :href="item.url" target="_blank" style="padding-left:10px;">
                        {{ item.name }}
                      </el-link>
                  </div>
                </div>
              </MFormBuilder>
            </div>
          </div>
          <div class="all-btns">
            <el-button size="medium" @click="closeIn">返回</el-button>
            <el-button size="medium" type="primary" v-if="permission('Start') && allowOperation === true && status === 1" @click="handle(baseFormData)">开始任务</el-button>
            <el-button size="medium" type="primary" v-if="permission('AddReport') && allowOperation === true && status === 2" @click="report(baseFormData, 'addReport')">汇报</el-button>
            <el-button size="medium" type="primary" v-if="permission('Finish') && allowOperation === true && status === 2" @click="handle(baseFormData)">完成任务</el-button>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div v-show="params.radio === 'ReportRecord'">
      <div class="top">
        <SearchForm
          ref="searchForm"
          :form-list-extend="reportSearchFormList"
          :form-data="searchFormData"
          style="padding: 10px"
          :default-form="defaultForm"
          @update:formData="data=>searchFormData=data"
          @search="reSearch"
        />
        <MTable
          ref="mTable"
          :columns="columns[params.radio]"
          :height="height"
          :columns-key="columnsKey"
          :set-data-method="getTableData"
          :columns-setting="true"
        >
          <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
          <div slot="processPercent" slot-scope="{ row }">
            <el-progress :percentage="row.processPercent"/>
          </div>
          <div slot="relatedFiles" slot-scope="{row }">
            <el-tooltip effect="dark" :content="row.relatedFiles"  placement="top-end">
              <span class="mr-10">{{ row.relatedFiles }}</span>
            </el-tooltip>
          </div>
          <div slot="attachments" slot-scope="{row }">
            <el-link v-for="item in JSON.parse(row.attachments) || []" :key="item.id" :href="item.url" target="_blank" style="padding-left:10px;">
              {{ item.name }}
            </el-link>
          </div>
          <div slot="action" slot-scope="{ row }">
            <template>
              <el-button v-if="permission('ReportDel')" type="text" @click="del(row.id)">删除</el-button>
            </template>
          </div>
        </MTable>
      </div>
    </div>
    <MDialog v-model="visible" :title="titles[currentType]" @onOk="submitForm">
      <MFormBuilder ref="formBuild" :form-data="reportFormData" :form-list="reportFormList">
        <el-upload slot="drawing" :file-list="attachments2" v-bind="uploadProps">
          <el-button type="primary" size="small">点击上传</el-button>
        </el-upload>
      </MFormBuilder>
    </MDialog>
  </div>

</template>

<script>
  import DelPopover from '@/components/DelPopover/index'
  import FunctionHeader from '@/components/FunctionHeader/index'
  import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
  import SearchForm from '@/components/SearchForm/SearchForm'
  import { taskDetailColumns, reportRecordColumns } from './columns'
  import { reportFormList, reportSearchFormList} from './form-list'
  import api from '@/api/projectManagement/project'
  import conventionMixin from '@/mixin/conventional-page'
  import { getToken } from '@/utils/auth'

  export default {
    name: "ProjectTaskDetail",
    components: { NewPageOpen, DelPopover, FunctionHeader, SearchForm },
    mixins: [conventionMixin],
    filters: {},
    props: {},
    data() {
      const statusOptions = [
        {
          value: 1,
          label: '待开始',
          color: '#FFFFFF',
          background: '#2b6ec2'
        },
        {
          value: 2,
          label: '进行中',
          color: '#FFFFFF',
          background: '#67C23A'
        },
        {
          value: 3,
          label: '已完成',
          color: '#FFFFFF',
          background: 'rgba(121, 123, 143,0.2)'
        },
      ]
      const statusOptionsMap = statusOptions.reduce((pre, cur) => ({ ...pre, [cur.value]: cur }), {})
      return {
        reportFormList,
        reportSearchFormList,
        searchFormData: {},
        baseFormData: {},
        reportFormData: {},
        attachments: [],
        attachments2: [],
        params: {
          radio: 'TaskDetail'
        },
        repairIndex: 0,
        loading: false,
        permissionList: [],
        status: null,
        allowOperation: false,
        title:'',
        titles: {
          addReport: '汇报',
        },
        columns: {
          TaskDetail: taskDetailColumns,
          ReportRecord: reportRecordColumns
        },
        visible: false,
        currentType: 'addReport',
        functionHeader: {
          searchVal: ''
        },
        uploadProps: {
          action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
          headers: { token: getToken() },
          beforeUpload: this.beforeUpload,
          onSuccess: this.fileUploadChange,
          onError: this.fileUploadChange,
          onRemove: this.removeFile
        },
        formData: {},
        dialogFormList: [],
        isPageLoaded:false,
        defaultForm: {},
        statusOptionsMap,
        routeParams:false
      };
    },
    beforeMount() {},
    computed: {
      baseFormDetailList() {
        let a = this._.cloneDeep(taskDetailColumns);
        return a.map((f) => ({
          ...f,
          children: [
            {
              customSlots: [f.key],
            },
          ],
          required: false,
          tagName: "div",
        }))
      },
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        console.log(from.name);
        if (from.name === 'layout') {
          vm.routeParams = true;
        } else {
          vm.routeParams = false;
        }
      });
    },
    watch: {},
    created() {},
    mounted() {
      this.getDetail()
    },
    methods: {
      // 详情
      async getDetail() {
        const res = await api.getProjectTaskDetail({ id: this.$route.query.id })
        if(res){
          this.attachments = JSON.parse(res.attachments) || []
          this.baseFormData = res;
          this.allowOperation = res.allowOperation;
          this.status = res.status;
          this.attachments2 = this.attachments
        }
      },

      // 获取汇报列表
      async getTableData(condition, callback) {
        const { page } = condition
        const {  reportTime, ...data } = this.searchFormData
        const params = {
          planNodeId: this.$route.query.id,
          reportTimePre: reportTime ? reportTime[0] : null,
          reportTimeSub: reportTime ? reportTime[1] : null,
          ...data
        }
        const res = await api.getPlanNodeReportListPage({
          page: page.currentPage,
          size: page.pageSize,
          ...params
        })
        if (res) {
          callback({
            total: res.total,
            content: res.records
          })
        }
        callback()
      },

      // 汇报删除
      del(id){
        this.$confirm('是否删除该汇报记录？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          const res = await api.delPlanNodeReport(id)
          if (res) {
            this.$message.success('删除成功')
            this.$refs.mTable.tableRefs().clearSelection()
            this.$refs.mTable.setTableData()
          }
        })
      },

      // 任务汇报
      report(row, command) {
        this.reportFormData = this._.cloneDeep(row)
        this.reportFormData.workHours = 0
        this.reportFormData.remark = row.reportRemark
        this.currentType = command
        this.visible = true
      },

      // 开始任务/结束任务
      handle(row) {
        let message = row.status === 1 ? "开始" : "完成";
        this.$confirm('是否' + message+ '该任务？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          const res = await api.projectTaskChangeStatus({ status: row.status + 1, id: row.id })
          if (res) {
            this.$message.success('操作成功')
            this.getDetail()
          }
        })
      },
      createSetData(raw) {
        return {
          processPercent: raw.processPercent,
          workHours: raw.workHours,
          relatedFiles: raw.relatedFiles,
          remark: raw.remark,
          attachments: JSON.stringify(this.attachments2),
          planNodeId: raw.id
        }
      },
      createResetData(raw) {
        const obj = {
          id: raw.id
        }
        return Object.assign(this.createSetData(raw), obj)
      },
      async submitForm(callback) {
        const { form } = this.$refs.formBuild
        this.$refs.formBuild.formRefs().validate(async(valid) => {
          if (valid) {
            const res = await api.addPlanNodeReport(this.createResetData(form))
            if (res) {
              this.visible = false
              this.getDetail();
            }
            callback(true)
          } else {
            callback()
          }
        })
      },

      // 获取汇报人列表
      async getReportUserList() {
        const res = await api.getReportUserList()
        if (res) {
          this.reportSearchFormList[0].children[0].options = res
        }
      },
      reSearch(data, val) {
        if (val === 1) {
          this.searchFormData = {}
        }
        this.$refs.mTable.setTableData(true)
      },

      beforeUpload(file) {
        const isLt20M = file.size / 1024 / 1024 < 20
        if (!isLt20M) {
          this.$message.error('上传文件大小不能超过 20M!')
        }
        return isLt20M
      },
      fileUploadChange(res, file) {
        if (res.errorCode === 0) {
          this.$message.success('上传成功')
          this.attachments2.push({
            id: file.uid,
            name: file.name,
            url: res.result
          })
        } else {
          this.$message.error(res.result)
        }
      },
      removeFile(file) {
        this.attachments2 = this.attachments2.filter(item => item.uid !== file.uid)
      },

      fixImg(img) {
        return img
      },
      closeIn() {
        if(this.routeParams){
        this.$router.push({ name: 'layout',params:{activeName:'personalWorkbench'} })
        }else{
          this.$router.go(-1)
        }
      },
      radioChange() {
        if(this.params.radio === 'TaskDetail'){
          this.getDetail()
        } else {
          this.getReportUserList()
          this.$refs.mTable.setTableData(true)
        }
      },

    }
  }
</script>

<style lang="scss" scoped>
  .detail-wrappers {
    height: 100%;
    position: relative;

    ::v-deep {
      .el-form-item__error {
        padding-top: 2px;
      }
      .el-form-item {
        display: flex;
        margin: 0 20px 18px 20px !important;
        height: 100%;

        &__label {
          position: relative;
          display: flex;
          align-items: center;
          width: 80px;
          white-space: nowrap;
          padding: 0;
          box-sizing: border-box;
          margin-left: 0 !important;

          &::before {
            position: absolute;
            top: 2px;
            left: -8px;
          }

          .el-form-item__label {
            color: #393d60;
          }
        }

        &__content {
          margin-left: 0 !important;
          flex: 1;
        }
      }
      .flex-dialog {
        .el-row {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        }
      }
    }
    .drawing-img {
      margin-right:10px;
      width: 50px;
      height: 48px;
      display: block;
      cursor: pointer;
      color: #fff;
    }
    .card-bot {
      ::v-deep {
        .el-form-item__error {
          padding-top: 2px;
          top: 16px;
          right: 60px;
          text-align: right;
        }

        .el-col {
          padding: 0 !important;
        }

        .el-row {
          display: flex;
          flex-wrap: wrap;
          margin: 0 !important;
        }

        .el-form {
          border: 1px solid #dcdfe6;
          border-left: none;
          border-bottom: none;
        }
        .el-form-item {
          display: flex;
          margin: 0 !important;
          border: 1px solid #dcdfe6;
          border-top: none;
          border-right: none;

          &__label-wrap {
            display: flex;
            align-items: center;
            width: 150px;
            padding: 0 15px;
            box-sizing: border-box;
            margin-left: 0 !important;
            background: #f2f2f7;
            border-right: 1px solid #dcdfe6;

            .el-form-item__label {
              color: #393d60;
            }
          }

          &__content {
            padding: 4px 10px;
            margin-left: 0 !important;
            flex: 1;
          }
        }
      }

      .gray {
        color: #999;
      }

      .spare {
        margin: 25px 0;
      }
    }

    .detail-body {
      height: calc(100% - 60px);
      overflow-y: auto;

      .detail-card {
        margin-bottom: 20px;
        background-color: #fff;
        border-radius: 5px;

        .card-tit {
          display: flex;
          align-items: center;
          height: 48px;
          border-radius: 2px 2px 0px 0px;
          line-height: 48px;
          font-size: 16px;
          font-weight: bold;
          color: #393d60;
          padding: 0 20px;
        }

        .card-title-desc {
          display: flex;
          padding: 0 20px;

          .del {
            color: #409eff;
            font-size: 15px;
            margin-left: auto;
            cursor: pointer;
          }
        }
        .card-bot {
          padding: 10px 20px;
        }
      }
    }

    .upload-view {
      ::v-deep.el-upload {
        &--text {
          display: none;
        }
      }
    }

    .all-btns {
      display: flex;
      justify-content: end;
      padding-top: 12px;
    }

    .el-progress {
      position: relative;
      line-height: 4;
    }

  }
</style>
