export const taskPlanNodeStatus = [
  { name: '待开始', id: 1 },
  { name: '进行中', id: 2 },
  { name: '已完成', id: 3 }
]

// 今天待处理任务
export const todayWaitingTaskColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 60,
    hiddenSearch: true
  },
  {
    prop: 'planName',
    label: '任务名称',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'projectName',
    label: '所属项目',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'lastUpdateTime',
    label: '任务更新时间',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'startTime',
    label: '计划开始时间',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'finishTime',
    label: '计划结束时间',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'remark',
    label: '备注',
    hiddenSearch: true,
    sortable: false,
    width: 200
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 60
  }
]

// 本周新增任务
export const thisWeekAddTaskColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 60,
    hiddenSearch: true
  },
  {
    prop: 'planName',
    label: '任务名称',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'projectName',
    label: '所属项目',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'lastUpdateTime',
    label: '任务更新时间',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'startTime',
    label: '计划开始时间',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'finishTime',
    label: '计划结束时间',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'remark',
    label: '备注',
    hiddenSearch: true,
    sortable: false,
    width: 200
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 60
  }
]


// 全部待处理任务
export const allWaitingTaskColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 60,
    hiddenSearch: true
  },
  {
    prop: 'planName',
    label: '任务名称',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'planTypeName',
    label: '任务类别',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'projectNumber',
    label: '所属项目编号',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'projectName',
    label: '所属项目名称',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'startTime',
    label: '计划开始时间',
    hiddenSearch: true,
    sortable: false,
    width: 150
  },
  {
    prop: 'finishTime',
    label: '计划结束时间',
    hiddenSearch: true,
    sortable: false,
    width: 150
  },
  {
    prop: 'actualStartTime',
    label: '实际开始时间',
    hiddenSearch: true,
    sortable: false,
    width: 150
  },
  {
    prop: 'actualFinishTime',
    label: '实际结束时间',
    hiddenSearch: true,
    sortable: false,
    width: 150
  },
  {
    prop: 'principalName',
    label: '负责人',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'lastUpdateTime',
    label: '任务更新时间',
    hiddenSearch: true,
    sortable: false,
    width: 150
  },
  {
    prop: 'statusName',
    label: '状态',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'remark',
    label: '备注',
    hiddenSearch: true,
    sortable: false,
    // width: 200
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 60
  }
]
