var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "inspection-plan-detail-wrapper" }, [
    _c("div", { staticClass: "inspection-plan-detail-header flex-sbc" }, [
      _c(
        "div",
        {
          staticClass: "left",
          on: {
            click: function($event) {
              return _vm.$router.back()
            }
          }
        },
        [
          _c("img", {
            staticStyle: { width: "8px" },
            attrs: {
              src: require("@/assets/information/procedure/左滑@2x.png")
            }
          }),
          _c(
            "span",
            { staticStyle: { "padding-left": "10px", cursor: "pointer" } },
            [_vm._v("新增生产任务")]
          )
        ]
      )
    ]),
    _c("div", { staticClass: "inspection-plan-detail-body" }, [
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _c("MFormBuilder", {
              ref: "baseForm",
              attrs: { "form-list": _vm.addTaskForm, "form-data": _vm.detail }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "detail-card table-box" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("列表信息")]),
        _c(
          "div",
          { staticClass: "card-bot " },
          [
            _c("MTable", {
              attrs: {
                height: 100,
                columns: _vm.addTaskColumn,
                data: _vm.tableData,
                "show-page": false
              },
              scopedSlots: _vm._u([
                {
                  key: "materialsCode",
                  fn: function(ref) {
                    var row = ref.row
                    return _c("NewPageOpen", {
                      attrs: {
                        path: {
                          name: "materialsDetail",
                          query: { id: row.materialsId }
                        },
                        text: row.materialsCode
                      }
                    })
                  }
                },
                {
                  key: "planOutputQuantity",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _c("el-input-number", {
                        style: {
                          border: row.isWarningPlanCount
                            ? "1px solid #F56C6C"
                            : "",
                          borderRadius: "4px",
                          width: "110px",
                          height: "fit-content"
                        },
                        attrs: {
                          max: row.netRequireQuantity,
                          controls: false,
                          min: 1,
                          step: 1,
                          "step-strictly": true
                        },
                        model: {
                          value: row.planOutputQuantity,
                          callback: function($$v) {
                            _vm.$set(row, "planOutputQuantity", $$v)
                          },
                          expression: "row.planOutputQuantity"
                        }
                      })
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "order-footer" }, [
      _c("div"),
      _c(
        "div",
        { staticClass: "footer-ri" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.$router.back()
                }
              }
            },
            [_vm._v("取 消 ")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onSave } },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }