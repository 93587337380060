<template>
  <div class="procedure-detail-wrapper">
    <div class="procedure-detail-header flex-sbc">
      <div class="left" @click="$router.push({ name: 'QualityPlanList' })">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px">
        <span style="padding-left: 10px;cursor: pointer">{{ messages[params.type + 'O'] }}</span>
      </div>
      <div class="right">
        <!-- <el-button v-if="permission('qualityInspectionPlanListSynchronous', false) && !saveButton" type="primary" :loading="buttonLoading" @click="synchronousInspectionPlan">
          同步至质检单
        </el-button> -->
        <el-button v-if="permission('qualityInspectionPlanListModify', false) || saveButton" type="primary" :loading="buttonLoading" @click="switchOrSubmit">
          {{ saveButton ? '保存' : '编辑' }}
        </el-button>
      </div>
    </div>

    <div class="procedure-detail-body">
      <div class="detail-card">
        <div class="card-tit">基本信息</div>
        <div class="card-bot">
          <MFormBuilder v-if="saveButton" ref="baseForm" :form-list="baseFormList" :form-data="baseFormData" />
          <el-row v-else type="flex" justify="start" style="flex-flow:row wrap" class="view-list" :gutter="24">
            <!--            暂时这么处理距离问题-->
            <el-col
              v-for="(item, index) in columns.base"
              :key="item.prop"
              :span="6"
              :style="{marginBottom: index !== 4 ? '20px' : 0 }"
            >
              <span class="bot-tis">{{ item.label }}：</span>
              <span class="bot-tis">{{ getValue(item) }}</span>
            </el-col>
          </el-row>
          <quality-inspection-project
            ref="qualityInspectionProject"
            :inspection-entry-map="inspectionProjectGroup.inspectionEntryMap"
            :attachments="inspectionProjectGroup.attachments"
            :user-list="inspectionProjectGroup.userList"
            :read-only="!saveButton"
            :baseFormData="baseFormData"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { base } from './columns'
import baseFormList from './form-list'
import api from '@/api/quality/inspection-plan'
import QualityInspectionProject from '@/views/quality/inspection-plan/components/quality-inspection-project'

export default {
  name: 'QualityPlanDetail',
  components: { QualityInspectionProject },

  data() {
    return {
      params: {},
      baseFormList,
      baseFormData: {},
      saveButton: false,
      buttonLoading: false,
      columns: {
        base
      },
      messages: {
        add: '新增成功',
        modify: '修改成功',
        copy: '复制成功',
        viewO: '质检方案详情',
        addO: '新增质检方案',
        modifyO: '修改质检方案',
        copyO: '复制质检方案'
      },
      inspectionProjectGroup: {},
      inspectionTypeNames: ['首检', '过程检', '来料检', '出货检'],
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == 'QualityPlanList') {
      this.$store.dispatch('tagsView/delCachedView', to)
    }
    next()
  },
  mounted() {
    this.params = this.$route.query
    this.saveButton = this.params.type !== 'view'
    if (this.params.type !== 'add') {
      this.getInspectionPlanDetailById(this.params.id)
    }
  },
  methods: {
    async getInspectionPlanDetailById(id) {
      const res = await api.getInspectionPlanDetailById({ id })
      if (res) {
        this.baseFormData = res
        this.inspectionProjectGroup = this._.cloneDeep(res)
      }
    },
    getValue(row) {
      const result = this.baseFormData[row.prop]
      if (row.form && row.form.options) {
        const current = row.form.options.find(item => item.id === result)
        return current && current.name
      }
      if(row.prop == 'type'){
        return this.inspectionTypeNames[result]
      }
      return result
    },
    switchOrSubmit() {
      if (this.saveButton) {
        this.submitForm()
      } else {
        this.params.type = 'modify'
        this.saveButton = true
      }
    },
    async synchronousInspectionPlan() {
      const res = await api.synchronousInspectionPlan({ inspectionSchemeId: this.$route.query.id })
      if (res) {
        this.$message.success('同步成功')
      }
    },
    submitForm() {
      this.buttonLoading = true
      const projectObject = this.$refs.qualityInspectionProject.transferDataToStandard()
      if (!projectObject) {
        this.buttonLoading = false
        return false
      }
      this.$refs.baseForm.formRefs().validate(async(valid) => {
        if (valid) {
          let res
          const { form } = this.$refs.baseForm
          if (['add', 'copy'].indexOf(this.params.type) !== -1) {
            res = await api.addInspectionPlan(this.createSetData({ ...form, ...projectObject }))
          }
          if (this.params.type === 'modify') {
            res = await api.modifyInspectionPlan(this.createResetData({ ...form, ...projectObject }))
          }
          if (res) {
            this.saveButton = false
            this.$message.success(this.messages[this.params.type])
            if (this.params.type !== 'modify') {
              this.$router.push({ query: { type: 'view', id: res }})
              this.params = { type: 'view', id: res }
            }
            await this.getInspectionPlanDetailById(this.params.id)
          }
          this.buttonLoading = false
        } else {
          this.buttonLoading = false
        }
      })
    },
    createSetData(raw) {
      return {
        name: raw.name,
        type:raw.type,
        isEnabled: !raw.isEnabled ? 0 : 1,
        inspectionEntryMap: raw.inspectionEntryMap,
        inspectorList: raw.inspectorList,
        userList: raw.userList,
        attachments: raw.attachments,
        remark: raw.remark,
        isCheck: raw.isCheck,  //0关闭  1开启审核
        checkedIDList: raw.checkedIDList,   //审核人员id
        isApproved: raw.isApproved,    ///0关闭  1开启审批
        approverIdList: raw.approverIdList   //审批人员id
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    }
  }
}
</script>

<style scoped lang="scss">
.procedure-detail-wrapper {
  height: 100%;

  .procedure-detail-header {
    width: 100%;
    padding: 10px 0;
  }

  .procedure-detail-body {
    height: calc(100% - 60px);
    overflow-y: auto;

    .detail-card {
      margin-bottom: 20px;
    }
  }
}
</style>
