<template>
    <MDialog v-model="modalShow" width="1000px" :append-to-body="true" title="需求更新" @onOk="submitForm" @fullScreen="fullScreenChange">
      <Split :offset.sync="split.offset" :height="splitHeight">
        <template slot="left">
            <RequirementsClass @nodeClick="setMaterialsClassId" :view="true"/>
        </template>
        <template slot="right">
            <div class="materials-info">
              <FunctionHeader
                ref="functionHeader"
                v-model="functionHeader.searchVal"
                search-title="单据编码"
                :tags="(materialsClassId === 0 || materialsClassId === 1) ? functionHeader.tags : []"
                @search="$refs.mTable.setTableData(true)"
              >
              <div slot="all">
                <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
                  已选 
                  <span style="color:#607FFF">{{  getSelectionDataLength }}</span>
                  / {{ total }}项
                </span>
        <el-button type="text" size="small" @click="resetData" style="margin-top:-4px;">清空</el-button>
            </div>
              </FunctionHeader>
              <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData" :onlyKey="'requirementCode'" @selection-change="selectionChange">
                <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
                <div slot="requirementType" slot-scope="{ row }">
                  {{ row.requirementType ? row.requirementType === 1 ? '备库需求':'预测需求':'销售订单' }}
                </div>
                <div slot="deliveryType" slot-scope="{ row }">
                  {{ row.deliveryType ? '分批交付':'统一交付' }}
                </div>
              </MTable>
            </div>
          </template>
      </Split>
    </MDialog>
  </template>
  
  <script>
  import RequirementsClass from './RequirementsClass'
  import Split from '@/components/Split/Split'
  import SearchForm from '@/components/SearchForm/SearchForm'
  import FunctionHeader from '@/components/FunctionHeader'
  import { columns } from './columns'
  import api from '@/api/projectManagement/project'
  
  export default {
    name: 'SelectRequirements',
    components: { RequirementsClass, Split, SearchForm, FunctionHeader },
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      selectMaterials: {
        type: Array,
        default: () => ([])
      },
      title: {
        type: String,
        default: '选择物料'
      },
      projectId: {
        type: Number,
        default: undefined
      }
    },
    data() {
      return {
        modalShow: false,
        split: {
          offset: 20
        },
        columns,
        total:0,
        functionHeader: {
          searchVal: '',
          tags:[{
              tagName: 'el-button',
              props: { type: 'primary', size: 'small' },
              style: { width: 'auto', background: '#607FFF' },
              innerText: '新增',
              on: {
                click: this.add
              }
            }]
        },
        searchFormList: [],
        searchFormData: {},
        searchFormatData: [],
        formOptions: {
          materialsClassList: []
        },
        selectionData: [],
        materialsClassId: '',
        fullScreen: false
      }
    },
    computed: {
      height() {
        return this.fullScreen ? this.$store.state.app.contentHeight - 160 - 83 - 57 : this.$store.state.app.contentHeight - 289 - 160 - 10
      },
      splitHeight() {
        return this.fullScreen ? this.$store.state.app.contentHeight - 160 - 10 : this.$store.state.app.contentHeight - 289 - 45
      },
      getSelectionDataLength() {
        const selectionDataNoDisabled = this.selectionData.filter((item) => !item.selectable)
        return selectionDataNoDisabled.length
      }
    },
    watch: {
      visible(val) {
        this.modalShow = val
        if (val) {
          this.echoData()
        }
      },
      modalShow(val) {
        if (!val) {
          this.searchFormData = {}
          this.searchFormatData = []
          this.functionHeader.searchVal = ''
          this.$emit('update:visible', false)
        }
      }
    },
    mounted() {
      this.modalShow = this.visible
      if (this.modalShow) {
        this.echoData()
      }
    },
    methods: {
      resetData() {
        this.$refs.mTable.tableRefs().clearSelection()
      },
      echoData() {
        console.log(this.selectMaterials)
        this.$nextTick(() => {
          // 数据回显
          this.materialsClassId = ''
          this.selectionData = this._.cloneDeep(this.selectMaterials)
            this.selectionData.forEach(item => {
              this.$refs.mTable.tableRefs().toggleRowSelection(item, true)
            })
        })
      },
      updateTable() {
        this.$refs.mTable.setTableData()
      },
      // 新增
      async add() {
        if(this.materialsClassId === 0){
        localStorage.setItem('salesOrderAddType', 1)
         this.$router.push({ name: 'salesOrderAdd', query: { type:'add'}})
      }else{
        localStorage.setItem('reserveStockOrderAddType', 1)
        this.$router.push({ name: 'reserveStockOrderAdd', query: { type:'add'}})
      }
      },
      // 选中行复选框也会选中
      handleRowClick(row) {
        if (!row.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(row)
        }
      },
      fullScreenChange(val) {
        this.fullScreen = val
      },
      selectionChange(data) {
        if (Array.isArray(data)) {
          this.selectionData = data
          this.exportSelectionIds = data.map(item => item.id)
        } else if (!data.selectable) {
          this.selectionData = []
          this.selectionData.push(data)
        }
      },
      setMaterialsClassId(id) {
        this.materialsClassId = id
        // this.selectionData = []
        this.$refs.mTable.setTableData(true)
      },
      // 获取物料数据
      async getTableData(condition, callback) {
        const { page } = condition
        const searchCondition = {
          requirementType: this.materialsClassId,
          search: this.functionHeader.searchVal,
        }
        this.functionHeader.exportParams = searchCondition
        const res = await api.getProjectRelateRequirementListPage({
          page: page.currentPage,
          size: page.pageSize,
          projectId: this.projectId,
          ...searchCondition
        })
        if (res) {
          callback({
            total: res.total,
            content: res.records
          })
          this.total = res.total
        }
        callback()
      },
      submitForm(callback) {
        // const a = this.selectionData.filter(item => !item.selectable)
        // if (a.length === 0) {
        //   this.$message.info('请至少选择一条物料')
        //   callback()
        //   return
        // }
        this.$emit('submitForm', this.selectionData)
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .materials-info {
    padding: 10px;
  }
  .materials-head{
    padding:0 0 10px 0;
  }
  </style>
  