<template>
  <div class="fault-wrap">
    <div class="top">
      <SearchForms ref="searchForms" @resetData="resetData" @searchData="searchTable" />
    </div>
    <el-divider class="e-divider" />
    <FunctionHeader
      v-model="functionHeader.searchVal"
      search-title="请输入质检单号"
      style="padding: 10px 0 10px 0"
      @search="$refs.mTable.setTableData(true)"
    />
    <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData">
      <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
      <div slot="planList" slot-scope="{ row }">
        {{ fixData(row.planList) }}
      </div>
      <div slot="status" slot-scope="{ row }">
        <div v-if="row.status !== 2" class="faultStatuss" :style="{ color: restStatus[row.status] ? restStatus[row.status].color : '', background: restStatus[row.status] ? restStatus[row.status].background : ''}">{{ restStatus[row.status].name }}</div>
      </div>
      <div slot="action" slot-scope="{ row }">
        <a v-if="permission('create') && row.planStatus !== 2 && row.status === 0" class="li-btn" @click="getRepair(row)">创建计划</a>
        <a v-else class="li-btn color-btns">创建计划</a>
        <el-divider v-if="permission('create')" direction="vertical" />
        <a v-if="permission('check') && row.status === 0" class="li-btn" @click="del(row)">移至废品仓</a>
        <a v-else class="li-btn color-btns">移至废品仓</a>
      </div>
    </MTable>
    <!-- 创建计划 -->
    <repair-model
      :visible.sync="repairVisible"
      :repair-record-id="repairRecordId"
      :old-process-flow-id="oldProcessFlowId"
      :produce-work-order-id="produceWorkOrderId"
      :base-form-data="baseFormData"
      @loadWord="loadWords"
    />
  </div>
</template>
<script>
import FunctionHeader from '@/components/FunctionHeader'
import SearchForms from './search-forms'
import { restStatus, faultStatus } from '@/config/options.config'
import api from '@/api/quality/report-waste-record'
import sapi from '@/api/equipment/equipment'
import uapi from '@/api/sets/user/user'
import fapi from '@/api/quality/inspection-plan'
import bapi from '@/api/information/print-label/batch-number'
import { columnsAll } from './columns'
import { Encrypt } from '@/utils/sercet'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
import baseSetting from '@/api/sets/base-setting'
import DelPopover from '@/components/DelPopover/index'
import { getUUid } from '@/utils'
import RepairModel from './components/repair-model/repair-model'
import planApi from '@/api/production/plans'

export default {
  name: 'ReportWasteRecord',
  components: { RepairModel, DelPopover, NewPageOpen, FunctionHeader, SearchForms },
  data() {
    return {
      restStatus,
      produceWorkOrderId: undefined,
      oldProcessFlowId: undefined,
      repairRecordId: undefined,
      repairVisible: false,
      baseFormData: {},
      isIndeterminate: false,
      faultStatus,
      searchFormData: [],
      searchFormDatas: {},
      keyword: '',
      selectKeyword: '',
      functionHeader: {
        searchVal: ''
      },
      columns: columnsAll,
      searchDatas: [],
      currentId: undefined,
      formOptions: {
        reporterList: [],
        procedureOptions: [],
        inspectionOptions: [],
        batchNumberOptions: []
      },
      isFaultCheck: undefined
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 12
    }
  },
  mounted() {
    this.getUser()
    this.getSetting()
  },
  methods: {
    fixData(val) {
      if (val) return val.map(item => item.producePlanNumber).join()
    },
    async getProcessId(id) {
      const res = await planApi.getPlanDetail(id)
      if (res) {
        this.oldProcessFlowId = res.processFlowId
      }
    },
    // 创建计划
    async getRepair(row) {
      await this.getProcessId(row.producePlanId)
      this.baseFormData = row
      this.produceWorkOrderId = row.produceWorkOrderId
      this.repairRecordId = row.id
      this.repairVisible = true
    },
    loadWords() {
      this.repairVisible = false
      this.$refs.mTable.setTableData(true)
    },
    // 方案
    async getSetting() {
      const res = await fapi.getInspectionPlanList()
      if (res) {
        this.formOptions.inspectionOptions = res
        if (this.$refs.searchForms !== undefined) {
          this.$refs.searchForms.inspectionSchemeList = res
        }
      }
    },
    // 列表
    async getTableData(condition, callback) {
      const { page, search, order } = condition
      const res = await api.getScrapOrderListPage({
        page: page.currentPage,
        limit: page.pageSize,
        sort: order,
        materialsSearchType: this.selectKeyword,
        materialsSearchValue: this.keyword,
        searchKeyword: this.functionHeader.searchVal,
        searchVal: Encrypt(JSON.stringify([...this.searchDatas]))
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    // 用户
    async getUser() {
      const res = await uapi.getUserList()
      if (res) {
        this.formOptions.reporterList = res
        if (this.$refs.searchForms !== undefined) {
          this.$refs.searchForms.reporterList = res
        }
      }
    },
    async del(row) {
      this.$confirm('您确定要移至废品仓吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.delScrapOrder(row.id)
        if (res) {
          this.$message.success('移至废品仓成功')
          this.$refs.mTable.setTableData()
        }
      })
    },
    // 查询
    searchTable(data, val, keyword) {
      this.searchDatas = data
      this.keyword = keyword
      this.selectKeyword = val
      if (this.$refs.mTable !== undefined) {
        this.$refs.mTable.setTableData(true)
      }
    },
    // 重置
    resetData() {
      this.searchDatas = []
      this.selectKeyword = ''
      this.keyword = ''
      this.$refs.mTable.setTableData(true)
    }
  }
}
</script>

<style scoped lang="scss">
 .fault-wrap{
    padding: 10px;
    .faultStatuss {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      height: 22px;
      line-height: 22px;
      border-radius: 4px;
      padding: 0 5px;
      text-align: center!important;
      width: 60px;
    }
    .all-select {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .checkAll {
        margin-right: 20px;
      }
      .all-li {
        width: 80px;
        height: 26px;
        background: #FFFFFF;
        border-radius: 16px;
        border: 1px solid #DDE3EB;
        margin-right: 15px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #A4B3C6;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .active {
        color: #607FFF;
        border-color: #607FFF;
        background: #EFF2FF;
      }
    }
    .li-btn{
      // margin-right:10px;
      color:#607FFF;
      font-weight: 500;
    }
    .color-btns{
      color:#c4c4c4;
    }
    .searchs{
      padding-left: 0;
      padding-top: 0;
    }
    .input-select{
      width: 100px;
      display: block;
      }
    .input-with-select{
      width: 100%;
    }
    .all-operator{
      height: 32px;
      margin-left: 10px;
      ::v-deep{
        .el-button-group .el-button{
          height: 32px;
        }
      }
    }
 }
 ::v-deep .customer-content div {
   width: 100%;
 }

 .tip-tit {
   height: 18px;
   line-height: 18px;
 }
 div.tooltip {
  display: inline-block;
  padding: 3px 5px;
  background: #303133;
  position: relative;
  border-radius: 3px;
  font-size: 16px;
  color: #ffffff;
  box-shadow: 2px 2px 2px 0 #c1c1c1;
  margin-left: 10px;
}

div.tooltip:before, div.tooltip:after {
  content: ' ';
  height: 0;
  position: absolute;
  width: 0;
  border: 3px solid transparent;
}

div.tooltip:before {
  border-right-color: #303133;
  position: absolute;
  left: -6px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

div.tooltip:after {
  border-right-color: #303133;
  position: absolute;
  left: -6px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.faultStatus {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  height: 22px;
  line-height: 22px;
  border-radius: 4px;
  width: 50px;
  text-align: center;
}
.drawing-img {
  width: 50px;
  height: 48px;
  display: block;
  cursor: pointer;
  color: #fff;
}
.faultDesc {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 180px;
}
::v-deep{
  .e-divider{
    margin: 0px 0 0 0;
  }
}
</style>
