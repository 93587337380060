const inspectionItemColumns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left'
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'inspectionEntryName',
    label: '检验项名称',
    slotName: 'inspectionEntryName',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'inspectionMethod',
    label: '检验方法',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'inspectionStandard',
    label: '检验标准',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'parameterType',
    label: '检验值类型',
    slotName: 'parameterType',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'defaultValue',
    label: '默认值',
    slotName: 'defaultValue',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'remark',
    label: '备注',
    hiddenSearch: true,
    sortable: false
  }
]

export default inspectionItemColumns
