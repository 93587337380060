<template>
  <div class="fault-wrap common-tab">
    <el-radio-group v-model="params.radio" class="m-radio-group" style="margin-bottom: 10px;display:none;" @change="radioChange">
      <el-radio-button label="process">过程检</el-radio-button>
      <el-radio-button label="incomming">{{memberCode==='dmdz'?'放行单':'来料检'}}</el-radio-button>
      <el-radio-button label="outProduct">出货检</el-radio-button>
    </el-radio-group>
    <div class="bottom">
      <div class="top">
      <SearchForms ref="searchForms" :show-procedure="params.radio === 'process'" :form-option="formOptions" @resetData="resetData" @searchData="searchTable" />
    </div>
    <el-divider class="e-divider" />
    <FunctionHeader
      v-model="functionHeader.searchVal"
      search-title="请输入质检单号"
      :tags="functionHeader.tags"
      style="padding: 10px 0 10px 0"
      @search="$refs.mTable.setTableData(true)"
    >
      <div slot="all" class="all-select">
        <div class="checkAll">
          <el-checkbox v-model="checkeds" :disabled="checkeds" :indeterminate="isIndeterminate" @change="selectCheck">全选</el-checkbox>
        </div>
        <div v-for="(item, index) in allStatus" :key="item.value" class="all-li" :class="item.checked ? 'active' : ''" @click="selectTab(item, index)">{{ item.label }}</div>
      </div>
    </FunctionHeader>
    <MTable ref="mTable" :columns="params.radio === 'process' ? columnsAll : incomming" :height="height" :set-data-method="getTableData">
      <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
      <div slot="number" slot-scope="{ row }">
        <NewPageOpen v-if="isShowChild" :path="{ name: 'qualityInspectionOrderDetail', query: {id: row.id, isView: 'view'}}" :text="row.number" />
      </div>
      <div slot="inspectionType" slot-scope="{ row }">{{ inspectionTypeNames[row.inspectionType] }}</div>
      <div slot="passRate" slot-scope="{ row }">{{ row.passRate }}%</div>
      <div slot="passAndUnPass" slot-scope="{ row }">{{ row.okCount }}/{{ row.ngCount }}</div>
      <div slot="status" slot-scope="{ row }">
        <div class="faultStatusBox">
          <div class="faultStatus " :style="{ background: qualityStatus[row.status].background, color: qualityStatus[row.status].color }">{{ qualityStatus[row.status].name }}</div>
        <div class="faultStatus checkClass" v-if="row.showCheck" >待审核</div>
        <div class="faultStatus approveClass" v-if="row.showApprove">待审批</div>

        </div>


      </div>
      <div slot="allCount" slot-scope="{ row }">
        <span>{{ row.okCount }}/{{ row.ngCount }}</span>
      </div>
      <div slot="result" slot-scope="{ row }">{{ row.result === 1 ? '合格' : row.result === 0 ? '不合格' : '' }}</div>
      <div slot="reports" slot-scope="{ row }">
        <a class="li-btn" @click="views(row)">查看</a>
      </div>
      <div slot="action" slot-scope="{ row }" style="z-index: 100">
        <a v-if="permission('print')" class="li-btn" @click="printOrder(row)">打印检验报告</a>
        <el-divider v-if="permission('print')" direction="vertical" />
        <DelPopover v-if="permission('del') && (row.status === 0||row.deleteAuth )" class="li-btn" @onOk="(callback) => del(row, callback)" />
        <a v-if="permission('del') && row.status !== 0&&(!row.deleteAuth) " class="color-btns">删除</a>
        <el-divider v-if="permission('del')" direction="vertical" />
        <a v-if="permission('inspector') && row.status === 0" class="li-btn" @click="editInspector(row)">质检员</a>
        <a v-else class="color-btns">质检员</a>
      </div>
    </MTable>
    </div>
    <PrintResult ref="printCodes" />
    <view-result ref="viewResult" :visible.sync="visibles" :common-id="'commonId'" />
    <MDialog v-model="showInspector" title="编辑质检人员" @onOk="submitInspector">
      <div class="item-wrapper">
        <div class="label">质检人员：</div>
        <div style="width: 100%">
          <el-select
            v-model="inspectionSchemeInspector"
            placeholder="请选择"
            filterable
            multiple
            clearable
            style="width: 100%"
          >
            <el-option
              v-for="item in userList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </div>
      </div>
    </MDialog>
  </div>
</template>
<script>
import FunctionHeader from '@/components/FunctionHeader'
import SearchForms from './search-forms'
import { qualityStatus } from '@/config/options.config'
import api from '@/api/quality/inspection-orders'
import wapi from '@/api/production/work-order'
import fapi from '@/api/quality/inspection-plan'
import uapi from '@/api/sets/user/user'
import bapi from '@/api/information/print-label/batch-number'
import { columnsAll, incomming } from './columns'
import { Encrypt } from '@/utils/sercet'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
import DelPopover from '@/components/DelPopover/index'
import { getUUid } from '@/utils'
import PrintResult from '@/project-custom/reagold/print-code/printResult'
import ViewResult from './components/viewResult'
import fixCommonPage from '@/mixin/fix-common-page'

export default {
  name: 'QualityInspectionOrderList',
  components: { ViewResult, PrintResult, DelPopover, NewPageOpen, FunctionHeader, SearchForms },
  mixins: [fixCommonPage],
  data() {
    return {
      showInspector: false,
      visibles: false,
      params: {
        radio: 'process'
      },
      info: {},
      taskData: [],
      isIndeterminate: true,
      qualityStatus,
      searchFormData: [],
      searchFormDatas: {},
      keyword: '',
      selectKeyword: '1',
      functionHeader: {
        searchVal: ''
      },
      columnsAll,
      incomming,
      searchDatas: [],
      defaultSearchs: [{
        id: getUUid(),
        fieldName: 'inspectionType',
        fieldType: 'number',
        fieldValue: '1',
        operator: 'eq'
      }],
      currentId: undefined,
      formOptions: {
        reporterList: [],
        procedureOptions: [],
        inspectionOptions: [],
        batchNumberOptions: []
      },
      isFaultCheck: undefined,
      allStatus: [
        {
          value: 0,
          label: '待质检',
          checked: true
        },
        {
          value: 1,
          label: '质检中',
          checked: true
        },
        {
          value: 2,
          label: '已质检',
          checked: false
        },
        {
          value: 3,
          label: '已审核',
          checked: false
        },
        {
          value: 4,
          label: '已审批',
          checked: false
        }
      ],
      checkeds: false,
      currentIdxs: [0, 1],
      currentIdx: false,
      defaultSearch: {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }]
        ))
      },
      inspectionTypeNames: ['首检', '过程检', '来料检', '出货检'],
      userList: [],
      inspectionSchemeInspector: [],
      inspectionSchemeInspectorId: undefined,
      memberCode:null
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 12 - 50
    }
  },
  created(){
    this.params.radio = this.$route.meta.nameFlage ? this.$route.meta.nameFlage : 'process'
  },
  watch: {
    checkeds(val) {
      if (val) {
        this.currentIdxs = []
        this.isIndeterminate = false
        this.allStatus = this.allStatus.map(item => {
          return {
            ...item,
            checked: false
          }
        })
      } else {
        this.isIndeterminate = true
      }
    }
  },
  mounted() {
    this.memberCode=sessionStorage.getItem('memberCode')

    if(this.params.radio === 'process'){
      this.radioChange('process')
    }else if(this.params.radio === 'incomming'){
      this.radioChange('incomming')
    }else if(this.params.radio === 'outProduct'){
      this.radioChange('outProduct')
    }
    this.getUser()
    this.getSetting()
    this.batchNumberList()
  },
  activated() {
    // console.log('activated')
    this.$refs.mTable.setTableData(true)
  },
  onShow(){


  },
  methods: {
    async submitInspector(done) {
      done()
      const res = await api.modifyInspectionUser({
        id: this.inspectionSchemeInspectorId,
        inspectionSchemeInspectorList: this.inspectionSchemeInspector
      })
      if (res) {
        this.showInspector = false
        this.$message.success('修改成功')
        this.$refs.mTable.setTableData()
      }
    },
    async editInspector(row) {
      const user = row.inspectionSchemeInspector ? row.inspectionSchemeInspector.split('^') : []
      const users = user.filter(item => item)
      this.inspectionSchemeInspector = await users.map(item => Number(item))
      this.inspectionSchemeInspectorId = row.id
      this.showInspector = true
    },
    async views(row) {
      const res = await api.getInspectionOrderDetailById(row.id)
      if (res) {
        this.$refs.viewResult.view(res)
        this.visibles = true
      }
    },
    radioChange(label) {
      const types = [0, 'process', 'incomming', 'outProduct']
      this.defaultSearchs = [
        {
          id: getUUid(),
          fieldName: 'inspectionType',
          fieldType: 'number',
          fieldValue: types.indexOf(label),
          operator: 'eq'
        }
      ]
      this.searchDatas = []
      this.$refs.searchForms.searchFormData = {}
      this.keyword = ''
      this.selectKeyword = ''
      this.isShow = true
      this.$refs.mTable.getCheckedColumns()
      this.$refs.mTable.setTableData(true)
    },
    fixArr(val) {
      if (val) {
        const data = val.map(item => item.name)
        return data.split(',')
      }
    },
    checkResult(row) {
      this.$router.push({ name: 'qualityInspectionOrderDetail', query: { id: row.id }})
    },
    // 打印检验报告
    async printOrder(row) {
      const res = await api.getInspectionOrderDetailById(row.id)
      if (res) {
        this.$refs.printCodes.printResult(res)
      }
    },
    fixTime(val) {
      if (val) return Math.floor(val / 60) + '分钟'
    },
    selectTab(val, index) {
      // 判断是否是最后一个checked
      const isCheck = this.allStatus.filter(item => item.checked)
      const isAll = this.allStatus.filter(item => !item.checked)
      // console.log('zhuantqwai=>', isCheck, isAll)
      this.allStatus[index].checked = !this.allStatus[index].checked
      if (this.currentIdxs.includes(val.value)) {
        this.currentIdxs = this.currentIdxs.filter(item => item !== val.value)
        if (isCheck.length === 1) {
          this.checkeds = true
        } else {
          this.checkeds = false
        }
      } else {
        if (isAll.length === 1) {
          this.checkeds = true
        } else {
          this.checkeds = false
        }
        this.currentIdxs.push(val.value)
      }
      this.$refs.mTable.setTableData(true)
    },
    selectCheck() {
      if (this.checkeds) {
        this.currentIdxs = []
        this.$refs.mTable.setTableData(true)
      }
    },
    fixSinImg(img) {
      const imgs = img.split(',')
      return imgs[0]
    },
    fixImg(img) {
      const imgs = img.split(',')
      return imgs
    },
    // 列表
    async getTableData(condition, callback) {
      const { page, order } = condition
      const searchVal = []
      if (this.currentIdxs.length > 0) {
        searchVal.push({
          id: getUUid(),
          fieldName: 'status',
          fieldType: 'number',
          fieldValue: this.currentIdxs.join('^'),
          operator: 'in'
        })
      }
      // console.log(searchVal, this.searchDatas, this.defaultSearchs)
      const res = await api.getInspectionOrderListPage({
        page: page.currentPage,
        limit: page.pageSize,
        sort: order,
        searchKeyword: this.functionHeader.searchVal,
        materialsSearchType: this.selectKeyword,
        materialsSearchValue: this.keyword,
        searchVal: Encrypt(JSON.stringify([...searchVal, ...this.searchDatas, ...this.defaultSearchs]))
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    // 方案
    async getSetting() {
      const res = await fapi.getInspectionPlanList()
      if (res) {
        this.formOptions.inspectionOptions = res
        if (this.$refs.searchForms !== undefined) {
          this.$refs.searchForms.inspectionSchemeList = res
        }
      }
    },
    // 工序
    async getOperatorPages() {
      const res = await wapi.getOperatorPage()
      if (res) {
        this.formOptions.procedureOptions = res
        if (this.$refs.searchForms !== undefined) {
          this.$refs.searchForms.getCurrentProcedureList = res
        }
      }
    },
    // 用户
    async getUser() {
      const res = await uapi.getUserList()
      if (res) {
        this.userList = res
        this.formOptions.reporterList = res
        if (this.$refs.searchForms !== undefined) {
          this.$refs.searchForms.reporterList = res
        }
      }
    },
    // 批次码
    async batchNumberList() {
      const res = await bapi.getBatchNumberList(this.defaultSearch)
      if (res) {
        this.formOptions.batchNumberOptions = res
        if (this.$refs.searchForms !== undefined) {
          this.$refs.searchForms.batchNumberList = res
        }
      }
    },
    async del(row) {
      const that = this
      const res = await api.delInspectionOrder(row.id)
      if (res) {
        that.$message.success('删除成功')
        that.$refs.mTable.setTableData()
      }
    },
    // 查询d
    searchTable(data, val, keyword) {
      this.searchDatas = data
      this.keyword = keyword
      this.selectKeyword = val
      if (this.$refs.mTable !== undefined) {
        this.$refs.mTable.setTableData(true)
      }
    },
    // 重置
    resetData() {
      this.searchDatas = []
      this.selectKeyword = ''
      this.keyword = ''
      this.$refs.mTable.setTableData(true)
    }
  }
}
</script>

<style scoped lang="scss">
 .fault-wrap{
    .bottom {
      width: 100%;
      height: 100%;
      padding: 10px;
    }
    .item-wrapper {
      > div {
        display: inline-flex;
        padding: 10px 5px 0 5px;
        align-items: center;
        font-size: 14px;

        .label {
          min-width: 115px;
        }

      }
    }
    .all-select {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .checkAll {
        margin-right: 20px;
      }
      .all-li {
        width: 80px;
        height: 26px;
        background: #FFFFFF;
        border-radius: 16px;
        border: 1px solid #DDE3EB;
        margin-right: 15px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #A4B3C6;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .active {
        color: #607FFF;
        border-color: #607FFF;
        background: #EFF2FF;
      }
    }
    .li-btn{
      // margin-right:10px;
      color:#607FFF;
      font-weight: 500;
    }
    .color-btns{
      color:#c4c4c4;
    }
    .searchs{
      padding-left: 0;
      padding-top: 0;
    }
    .input-select{
      width: 100px;
      display: block;
      }
    .input-with-select{
      width: 100%;
    }
    .all-operator{
      height: 32px;
      margin-left: 10px;
      ::v-deep{
        .el-button-group .el-button{
          height: 32px;
        }
      }
    }
 }
 ::v-deep .customer-content div {
   width: 100%;
 }

 .tip-tit {
   height: 18px;
   line-height: 18px;
 }
 div.tooltip {
  display: inline-block;
  padding: 3px 5px;
  background: #303133;
  position: relative;
  border-radius: 3px;
  font-size: 16px;
  color: #ffffff;
  box-shadow: 2px 2px 2px 0 #c1c1c1;
  margin-left: 10px;
}

div.tooltip:before, div.tooltip:after {
  content: ' ';
  height: 0;
  position: absolute;
  width: 0;
  border: 3px solid transparent;
}

div.tooltip:before {
  border-right-color: #303133;
  position: absolute;
  left: -6px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

div.tooltip:after {
  border-right-color: #303133;
  position: absolute;
  left: -6px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.faultStatusBox{
  display: flex;
  align-items: center;
  .checkClass{
    margin-left: 4px;
    background-color: #778ff3;
    color: #fff;
  }
  .approveClass{
    margin-left: 4px;
    background-color: #909399;
    color: #fff;
  }

}
.faultStatus {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  height: 22px;
  line-height: 22px;
  border-radius: 4px;
  width: 50px;
  text-align: center !important;
}
.drawing-img {
  width: 50px;
  height: 48px;
  display: block;
  cursor: pointer;
  color: #fff;
}
.faultDesc {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 180px;
}
::v-deep{
  .e-divider{
    margin: 0px 0 0 0;
  }
}
</style>
